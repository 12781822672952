import { Multimedia } from "src/app/common/models/multimedia/multimedia";
import { Event } from './event';
import { EventSourceViewModel } from '../../source/models/eventSourceViewModel';
import { Witness } from '../../witness/models/witness';
import { ReferenceType } from 'src/app/common/enums/enums';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';

// api/events : GET
// This class will include all the properties belongs to the Event. (Can be used for Add/ Update Events)
export class EventComponentModel extends Event {
    referenceId : number;
    referenceType : ReferenceType;
    multimedia: Multimedia[];   
    sourceCitations: SourceCitationViewModel[];
    witness: Witness[];
}
