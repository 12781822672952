import { Component, OnInit } from '@angular/core';
import { DropMenuAction, DropMenuType } from 'src/app/common/enums/enums';
import { DropMenu } from '../../common/drop-menu/models/DropMenu';

@Component({
  selector: 'app-tree-manager-main',
  templateUrl: './tree-manager-main.component.html',
  styleUrls: ['./tree-manager-main.component.scss']
})
export class TreeManagerMainComponent implements OnInit {

  constructor() { }
  dropMenu : any;

  ngOnInit(): void {

    this.dropMenu = new DropMenu();
    this.dropMenu.type = DropMenuType.Inverted;
    this.dropMenu.menuItems = [ { action:DropMenuAction.Edit, isEnabled:true,isVisible:true },{ action:DropMenuAction.Delete, isEnabled:true,isVisible:true }  ]
  }

}
