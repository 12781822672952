import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Preference } from 'src/app/common/models/Preference';
import { PreferencesApiService } from 'src/app/services/API/preferences-api.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { PreferenceType } from 'src/app/common/enums/enums';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';

@Component({
  selector: 'app-preferences-option',
  templateUrl: './preferences-option.component.html',
  styleUrls: ['./preferences-option.component.scss']
})
export class PreferencesOptionComponent extends DialogBaseComponent implements OnInit {
  @Input() data;
  settingsTitle:string;
  preferences: Preference;
  formattedName: string = "";
  formattedDate: string = "";
  isFormDirty = false;

  projectId: string;
  preferenceType: string;
  projectName:string;

  constructor(private preferencesService: PreferencesApiService,
    private loadingService: LoadingService,
    dialogService: DialogService,
    private translateHandler: TranslateHandler,
    private messageDialogService: MessageDialogService,
    ref: ChangeDetectorRef) {
    super(dialogService, ref);
  }

  ngOnInit(): void {
    this.projectId = this.data.FamilyProjectId;
    this.preferenceType = PreferenceType.Project;
    this.projectName = this.data.ProjectName;
    this.preferences = new Preference();
    this.fillPreferences();
    this.setTitle();
    this.changePreview();
  }

  setTitle(){
    //if(this.preferenceType==PreferenceType.Global){
      this.settingsTitle = this.translateHandler.translate("lbl_tree_settings_title");
    //}else if(this.preferenceType==PreferenceType.Project){
    //  this.settingsTitle = this.translateHandler.translate("lbl_tree_settings_title") +" "+this.translateHandler.translate("lbl_for")+" "+ this.projectName;
    //}
  }

  fillPreferences() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.preferencesService.getPreferences(this.preferenceType, this.projectId).subscribe((response: ResponseModel<Preference>) => {
      this.preferences = response.data;
      this.changePreview();
      this.isFormDirty = false;
    }, (err) => {
      throw new CustomError("projectPreferences => getpreferences() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  updatePreferences() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.preferencesService.updatePreferences(this.preferenceType, this.projectId, this.preferences).subscribe((response) => {
      this.dialogSerivce.complete({ data: "success" });
    }, (err) => {
      throw new CustomError("projectPreferences => updatePreferences() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  changePreview() {
    this.formattedName = "";
    this.formattedDate = "";

    this.formattedName = this.setNameOrder(this.preferences);
    this.formattedDate = this.setDateOrder(this.preferences);
    this.isFormDirty = true;
  }

  setNameOrder(preferences: Preference) {
    var sampleName = {
      GivenName: "Stein Inge",
      MiddleName: "Bakke",
      Patronomic: "Patron",
      Prefix: "Pref",
      Sufix: "Suff",
      Surname: "Haaland"
    };

    if (preferences.showCapitalSurname) {
      sampleName.Surname = sampleName.Surname.toUpperCase();
    }

    if (preferences.includeMiddleName) {
      sampleName.GivenName = `${sampleName.GivenName} ${sampleName.MiddleName}`;
    }

    if (preferences.includePatronomic) {
      sampleName.GivenName = `${sampleName.GivenName} ${sampleName.Patronomic}`;
    }

    if (preferences.includePrefixSuffix) {
      if (preferences.nameOrder == "firstLast") {
        sampleName.GivenName = `${sampleName.Prefix} ${sampleName.GivenName}`;
        sampleName.Surname = `${sampleName.Surname} ${sampleName.Sufix}`;
      } else if (preferences.nameOrder == "lastFirst" || preferences.nameOrder == "lastCommaFirst") {
        sampleName.GivenName = `${sampleName.GivenName} ${sampleName.Prefix} ${sampleName.Sufix}`;
      }
    }

    switch (preferences.nameOrder) {
      case "firstLast": return `${sampleName.GivenName} ${sampleName.Surname}`;
      case "lastFirst": return `${sampleName.Surname} ${sampleName.GivenName}`;
      case "lastCommaFirst": return `${sampleName.Surname}, ${sampleName.GivenName}`;
      default: return "";
    }
  }

  setDateOrder(preferences: Preference) {
    var sampleDate = {
      Day: "5",
      Month: "april",
      Year: "1991",
      NumericMonth: "4",
    }
    var previewDay = sampleDate.Day;
    var previewMonth = sampleDate.Month;
    var previewDate = "";

    if (preferences.dateType == "long") {
      previewMonth = sampleDate.Month;
    } else if (preferences.dateType == "short") {
      previewMonth = sampleDate.Month.substr(0, 3);
    } else if (preferences.dateType == "numeric") {
      previewMonth = sampleDate.NumericMonth;
    } else if (preferences.dateType == "norwegian") { 
      preferences.dateSeperater = " "                   // sample norwegian date = 5. april 1991
      previewDay = `${sampleDate.Day}.`;
    }

    if (preferences.showCapitalDate && preferences.dateType != "numeric") {
      previewMonth = previewMonth.toUpperCase();
    }

    if (preferences.twoDigitNumeric) {
      if (preferences.dateType == "numeric") {
        previewMonth = `0${previewMonth}`;
        previewDay = `0${previewDay}`;
      } else {
        previewDay = `0${previewDay}`;
      }
    }

    if (preferences.monthFirst) {
      if (preferences.dateType != "numeric") {
        previewDate = `${previewMonth}_${previewDay}_${sampleDate.Year}`;
      } else {
        previewDate = `${previewDay}_${previewMonth}_${sampleDate.Year}`;
      }
    } else {
      previewDate = `${previewDay}_${previewMonth}_${sampleDate.Year}`;
    }

    return previewDate.replace(/_/g, preferences.dateSeperater);
  }

  cancel() {
    this.dialogSerivce.complete({ data: null });
  }

  save() {
    if (this.preferenceType == PreferenceType.All) {
      this.messageDialogService.openInfo(this.translateHandler.translate("lbl_override_project_settings_header"), this.translateHandler.translate("cnf_override_project_settings_body"), this.translateHandler.translate("cnf_override_project_settings_confirm")).toPromise().then((response: any) => {
        if (response) {
          this.preferenceType = PreferenceType.All
          this.showAppRestartMessage();
        }
      })
    } else {
      this.showAppRestartMessage();
    }
  }

  showAppRestartMessage(){
    this.messageDialogService.openDialog(this.translateHandler.translate("lbl_info"),this.translateHandler.translate("cnf_application_restart_body"),this.translateHandler.translate("cnf_application_restart_confirm"),this.translateHandler.translate("lbl_ok"),"").subscribe(res=>{
      if(res) this.updatePreferences();
    })
  }

}
