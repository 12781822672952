import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { MainleftpanelviewComponent } from './mainleftpanelview/mainleftpanelview.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonComponentModule } from '../common/common.module';


@NgModule({
  exports: [MainleftpanelviewComponent],
  declarations: [MainleftpanelviewComponent],
  imports: [
    CommonModule,
    NgbModule,
    CommonComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})

export class LeftpanelModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
