import { Component, OnInit } from '@angular/core';
import { NotifierEvents } from 'src/app/common/enums/enums';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { HistoryService } from 'src/app/services/history.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { ComponentBase } from '../../common/base/component-base';
import { RootFamily } from 'src/app/common/models/RootFamily';

@Component({
  selector: 'app-family-breadcrumb',
  templateUrl: './family-breadcrumb.component.html',
  styleUrls: ['./family-breadcrumb.component.scss']
})
export class FamilyBreadcrumbComponent extends ComponentBase implements OnInit {

  // listing updates
  expectedUpdates = [ NotifierEvents.RootMemberChanged, 
                      NotifierEvents.RootMemberUpdated,

                      NotifierEvents.RootFamilyChanged,
                      NotifierEvents.RootFamilyUpdated,
                      NotifierEvents.DefaultFamilyChanged
                    ];

  selectedFamily :RootFamily;
  isBothDisplayNamesNull = false;

  constructor(private historyService: HistoryService,
              notifierService: NotifierV2Service,
              private translateHandler: TranslateHandler) {
    super(notifierService);
  }

  ngOnInit(): void {
    this.fill();
  }

  notify() {
    this.fill();
  }

  fill() {
    this.selectedFamily = new RootFamily();
    this.selectedFamily = this.historyService.getSelectedFamily();
    if(this.selectedFamily){
      this.selectedFamily.displayName = this.formatDisplayName();
    }
  }

  formatDisplayName() {
    this.isBothDisplayNamesNull = false;
    //hide undefined/null values and format the display name
    const husbandName   = this.selectedFamily?.husband?.displayName?.trim();
    const wifeName      = this.selectedFamily?.wife?.displayName?.trim();
    const lblAnd        = this.translateHandler.translate("lbl_and")
    const lblFamily     = this.translateHandler.translate("lbl_family").toLocaleLowerCase();
    
    if (!husbandName && !wifeName) {
      this.isBothDisplayNamesNull = true
      return null;
    }
    return husbandName && wifeName ? `${husbandName} ${lblAnd} ${wifeName} ${lblFamily}` : `${husbandName || wifeName} ${lblFamily}`;
  }
}
