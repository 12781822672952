import { Injectable } from '@angular/core';
import { PreferencesService } from 'src/app/services/preferences.service';
import { EventBase } from '../../models/event/event-base';
import { FTDate } from '../../models/FTDate';
import { DateHelper } from '../date-helper';
import { TranslateHandler } from '../translate-handler';

@Injectable({
    providedIn: 'root'
  })
  
export class AgeCalculator {
    constructor(private preferenceService: PreferencesService, private translateHandler: TranslateHandler) {
        this.dateHelper =  new DateHelper(preferenceService);
      }
    age: string;
    dateHelper: DateHelper;

    calculate(event: EventBase, referenceDate: FTDate) {
        try{
            if(event.date.genealogyDate && referenceDate.genealogyDate){
                let formattedEventDate = this.getFormattedFtDate(event.date);
                let formattedReferenceDateDate = this.getFormattedFtDate(referenceDate);
    
                let eventDate = this.convertToDate(formattedEventDate);
                let eventReferencedDate = this.convertToDate(formattedReferenceDateDate);
    
                let timeDiff = Math.abs(eventDate.getTime() - eventReferencedDate.getTime());
                this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365).toString();
                return this.age as any;
            }
            else{
                return null;
            }
        }
        catch{
            return null;
        }
    }

    private getFormattedFtDate(date: FTDate){
        let dateString = (date.genealogyDate ? date.genealogyDate.originalStr : date.genealogyDateRange? date.genealogyDateRange.originalStr:null);
        return this.dateHelper.validateAndFormat(dateString);
    }

    private convertToDate(date: FTDate){
        let day = date.genealogyDate.day.toString();
        let month = date.genealogyDate.month.toString();
        let year = date.genealogyDate.year.toString();

        // Month | Date |Year
        return new Date(month+" "+ day+" "+ year);
    }


    
}