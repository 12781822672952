import { Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation} from "@angular/core";
import { MapBoundary, MapReportConfigModel } from "src/app/common/models/report/mapreport/map-report-config-model";
import * as mapboxgl from "mapbox-gl";
import { Observable, Subscription } from "rxjs";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { CustomError } from "src/app/common/helpers/custom-error";
import { MessageDialogService } from "src/app/components/common/message-dialog/services/message-dialog.service";
import { MapReportService } from "../services/map-report-service.service";
import { MapIndividualDetails, MapIndividualsViewModel } from "src/app/common/models/report/mapreport/map-individuals-view-model";
import { HttpErrorResponse } from "@angular/common/http";
import { LoadingService } from "src/app/services/UI/loading.service";
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { NotifierV2Service } from "src/app/services/notifier-v2.service";
import { DialogService } from "src/app/services/UI/dialog.service";
import { BaseComponent } from "../../BaseReport/base/base.component";
import { AliveStatus, NotifierEvents, EditorMode, ReportType } from "src/app/common/enums/enums";
import { ProjectRefService } from "src/app/services/project-ref.service";
import { StringLengthLimiter } from 'src/app/common/helpers/string-length-limiter';
import { MapReportPublishModel } from "src/app/common/models/report/mapreport/mapThemeExe/map-report-publish-model";
import { ReportEngineService } from "src/app/components/reportengine/services/report-engine-service.service";
import { ResponseModel } from "src/app/common/models/responseModel";
import domtoimage from 'dom-to-image';
import { Router } from "@angular/router";
import { AppConstatnts } from "src/app/common/constants/app-contstants";

@Component({
  selector: "app-map-container",
  templateUrl: "./map-container.component.html",
  styleUrls: ["./map-container.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MapContainerComponent extends BaseComponent implements OnInit,  DoCheck, OnDestroy {
  @Input() mapReportConfigData: MapReportConfigModel;

  // A reference to the custom HTML element in the component's HTML template
  @ViewChild("customHtmlElement", { static: true })
  customHtmlElement: ElementRef;

  public theme                   : any;
  public currentYear             : number;
  public selectedYear            : number;
  public content                 : any;
  public aliveIndividualsCount   : number;
  private map                    : mapboxgl.Map;
  private mapIndividualsList     : MapIndividualsViewModel[] = [];
  private mapPublishData           : Subscription;
  private isMapIndividualsLoaded = false; 
  private isProjectInvalid       = false;
  private markerList             : mapboxgl.Marker[] = []
  expectedUpdates                = [NotifierEvents.RootFamilyChanged];
  private themeSubscription           : Subscription
  private zoomModeSubscription        : Subscription
  private mapIndividualsSubscription  : Subscription
  private selectedYearSubscription    : Subscription
  private mapBoundary                 : MapBoundary;

  constructor(
    private mapReportService        : MapReportService,
    private renderer                : Renderer2,
    private loadingService          : LoadingService,
    private projectRefService       : ProjectRefService,
    public notifierService          : NotifierV2Service,
    public dialogService            : DialogService,
    public mapContainerHostElement  : ElementRef,
    public stringLimit              : StringLengthLimiter,
    private ReportEngineService     : ReportEngineService,
    private router                  : Router,
    protected messageDialogService? : MessageDialogService,
    protected translateHandler?     : TranslateHandler,
   ) {
    super(mapContainerHostElement, dialogService, notifierService,translateHandler,messageDialogService); 
    this.subscribeToPublishData();    
    this.mapBoundary = new MapBoundary();
  }
  
  ngOnInit(): void {
    this.subscribeToThemeData();
    this. zoomModeSubscription = this.mapReportService.zoomMode$.subscribe((zoomMode)=>{
      this.updateZoomLevel(zoomMode);
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.zoomModeSubscription.unsubscribe();
    this.mapIndividualsSubscription.unsubscribe();
    this.selectedYearSubscription.unsubscribe();
    this.mapPublishData.unsubscribe();
  }

  /**
   * Check whether the selected project containes an error.
   * If contain an errr, call notify method to reinitialze the project with defalt configurations.
   */
  ngDoCheck(): void {
    const isInvalid = this.projectRefService.isCurrentProjectInvalid1();   
    (this.isProjectInvalid !== isInvalid) ? 
    (this.isProjectInvalid = isInvalid, isInvalid && this.notify())
    : null;
  }

  /**
   * Reset the map data and settings, when family tree changed.
   * isMapIndividualsLoaded set to false so when loading indivuals form initMapIndividuals() method, It will
   * load new individuals related to the project
   */
  notify(): void {
    this.isMapIndividualsLoaded = false;
    this.mapReportService.changeTheme(this.mapReportConfigData.mapThemeConfig.defaultId);
  }

  /**
   * Initializes the Mapbox map with the specified configuration data and theme.
   * @returns {void}
   */
  initMap(): void {
    try {
      // Extract the necessary configuration data from the `mapReportConfigData` object
      const {
        accessToken,
        container,
        zoomLevel,
        mapBoundary,
        projection,
        dragRotate,
        attributionControl
      } = this.mapReportConfigData;
      // Set the Mapbox access token
      mapboxgl!.accessToken = accessToken;
      this.map = new mapboxgl.Map({
        container,
        zoom: zoomLevel.default,
        center: [mapBoundary.centerLat, mapBoundary.centerLng],
        dragRotate: dragRotate,
        attributionControl: attributionControl
      });
      this.map.setProjection(projection);

      this.selectedYearSubscription = this.mapReportService.selectedYear$.subscribe((year) => {
        if (year) {
          this.selectedYear = year;
          this.updateMarkers();
        }
      });

      // Apply map properties which are unique to each themes
      this.onThemeChange();

      // If there is an error with the server, show an error message and return to the dashboard
      this.map.on("error", (response) => {
        this.showError(
          "lbl_error_heading",
          "mapReport.err_no_map_reports",
          "mapReport.err_generating_map_report_message"
        ).subscribe(() => {
          // Return back to dashboard
          window.location.href = "#0";
          return;
        });
      });
    } catch (error) {
      throw new CustomError(error.message, 404, false);
    }
  }

  /**
   * Changes the theme of the map by setting its style and updating its custom HTML element and layers.
   * @returns {void}
   */
  onThemeChange(): void {
    // Clear the custom HTML element's contents
    this.renderer.setProperty(
      this.customHtmlElement.nativeElement,
      "innerHTML",
      ""
    );
    this.removePopups();

    // Remove the existing markers from the map
    for (const marker of this.markerList) {
      marker.remove();
    }

    // Set the map's new style
    this.map.setStyle(this.theme.themeData.style);

    // Update the custom HTML element with new external components
    this.theme.themeExe.setCustomHtml(
      this.theme.themeData.customHtmlConfig,
      this.customHtmlElement
    );
    // Initialize individual
    this.initMapIndividuals();

    // When the new style is loaded, update the map's custom layers based on the new theme
    this.map.once("styledata", () => {
      this.theme.themeExe.setCustomMap(
        this.theme.themeData.customMapConfig,
        this.map
      );
    });

    this.mapReportService.selectedYear$.subscribe((year) => {
      if (year) {
        this.selectedYear = year;
        this.updateMarkers();
      }
    });
  }
//remove all popoups currently rendered in dom
removePopups():void{
  const popupList: HTMLElement[] = this.mapContainerHostElement.nativeElement.querySelectorAll('.mapboxgl-popup');
  popupList.forEach(element => {
    this.renderer.removeChild(element.parentNode, element);
  });
}

  updateZoomLevel(zoomMode : string){
    switch(zoomMode){
      case "ZOOM_IN"    : this.map.setZoom(this.map.getZoom() + this.mapReportConfigData.zoomLevel.stepSize); break;
      case "ZOOM_OUT"   : this.map.setZoom(this.map.getZoom() - this.mapReportConfigData.zoomLevel.stepSize); break;
      case "ZOOM_FIT"   : this.setBoundary(); break;
      case "ZOOM_CENTER": this.map.setCenter([this.mapBoundary.centerLng, this.mapBoundary.centerLat]);
                          break;
      default           : // do nothing;
    }
  }

  async publishMapReport(data: any): Promise<void> {  
    await this.captureViewAsSvg();
    const dimensions                  = this.getSvgImageHeightWidth(this.content);
    let mapReportPublishModel         = new MapReportPublishModel();
    mapReportPublishModel.reportName  = data.reportTitle;
    mapReportPublishModel.template    = data.templateName;
    mapReportPublishModel.content     = this.content;
    mapReportPublishModel.type        = ReportType.Mapreport;
    mapReportPublishModel.height      = dimensions.height + this.mapReportConfigData.svgConfig.svgExtraHeight;
    mapReportPublishModel.width       = dimensions.width;

    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.ReportEngineService.publishReport(mapReportPublishModel)
    .subscribe((response: ResponseModel<string>) => {
    if(response){
      this.router.navigate(['/reportmanager'])
    }
    this.loadingService.hide(processId);
    }, (err) => {
      throw new CustomError("MainReportEngineView => publishReport() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    }); 
  }  

  captureViewAsSvg(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const svgNamespaceURL       = 'http://www.w3.org/2000/svg';
      const xlinkNamespaceURL     = 'http://www.w3.org/1999/xlink';
      const mapContainer          = document.getElementById('currentView');
      const containerClientWidth  = mapContainer.clientWidth - this.mapReportConfigData.svgConfig.mapContainerClientWidth;
      const containerClientHeight = this.mapReportConfigData.svgConfig.containerClientHeight;
      const containerHeight       = this.mapReportConfigData.svgConfig.containerHeight;

      const customHtmlElements    = mapContainer.querySelectorAll('.custom-html-elements');
      const canvasContainers      = mapContainer.querySelectorAll('.mapboxgl-canvas-container');
      const lastCanvasContainer   = canvasContainers[canvasContainers.length - 1] as HTMLElement;

      lastCanvasContainer.style.width     = containerClientWidth + 'px';
      lastCanvasContainer.style.height    = containerClientHeight + 'px';
      lastCanvasContainer.style.position  = this.mapReportConfigData.svgConfig.canvasContainer.canvasContainerPosition;
      lastCanvasContainer.style.top       = this.mapReportConfigData.svgConfig.canvasContainer.top;
      lastCanvasContainer.style.left      = this.mapReportConfigData.svgConfig.canvasContainer.left;
      lastCanvasContainer.style.overflow  = this.mapReportConfigData.svgConfig.canvasContainer.overflow;

      customHtmlElements.forEach(element => {
        const clonedElement          = element.cloneNode(true) as HTMLElement;
        clonedElement.style.position = this.mapReportConfigData.svgConfig.customHtmlElementsPosition; 
        lastCanvasContainer.appendChild(clonedElement);
      });
  
      this.map.once('render', () => {
        const mapTiles = this.map.getCanvas().toDataURL();
        const img = new Image();
        img.onload = () => {
          const containerSvgElement = document.createElementNS(svgNamespaceURL, 'svg');
          containerSvgElement.setAttribute('xmlns', svgNamespaceURL);
          containerSvgElement.setAttribute('width', containerClientWidth.toString());
          containerSvgElement.setAttribute('height', containerHeight.toString());
    
          const svgElement = document.createElementNS(svgNamespaceURL, 'svg');
          svgElement.setAttribute('xmlns', svgNamespaceURL);
          svgElement.setAttribute('width', containerClientWidth.toString());
          svgElement.setAttribute('height', containerClientHeight.toString());
  
          const svgImage = document.createElementNS(svgNamespaceURL, 'image');
          svgImage.setAttributeNS(xlinkNamespaceURL, 'xlink:href', mapTiles);
          svgImage.setAttribute('width', containerClientWidth.toString());
          svgImage.setAttribute('height', containerClientHeight.toString());
          svgElement.appendChild(svgImage);
  
          containerSvgElement.appendChild(svgElement);
  
          const textSvgElement = document.createElementNS(svgNamespaceURL, 'svg');
          textSvgElement.setAttribute('xmlns', svgNamespaceURL);
          textSvgElement.setAttribute('width', containerClientWidth.toString());
          textSvgElement.setAttribute('height', containerHeight.toString());
  
          const textElement = document.createElementNS(svgNamespaceURL, 'text');
          const textX       = containerClientWidth / 2;
          textElement.setAttribute('x', textX.toString());
          textElement.setAttribute('y', this.mapReportConfigData.svgConfig.textElement.y);
          textElement.setAttribute('text-anchor', this.mapReportConfigData.svgConfig.textElement.textAnchor);
          textElement.setAttribute('fill', this.mapReportConfigData.svgConfig.textElement.fill);
          textElement.setAttribute('font-size', this.mapReportConfigData.svgConfig.textElement.fontSize);
          textElement.setAttribute('font-weight', this.mapReportConfigData.svgConfig.textElement.fontWeight);
          textElement.setAttribute('visibility', this.mapReportConfigData.svgConfig.textElement.visibilityHidden);
  
          if (this.aliveIndividualsCount === 1) {
            textElement.textContent = this.translateHandler.translate("mapReport.lbl_alive_Individual_count",
              [this.aliveIndividualsCount.toString(), this.selectedYear.toString()]);
          } else {
            textElement.textContent = this.translateHandler.translate("mapReport.lbl_alive_Individuals_count",
              [this.aliveIndividualsCount.toString(), this.selectedYear.toString()]);
          }
          textSvgElement.appendChild(textElement);
  
          containerSvgElement.appendChild(textSvgElement);
  
          domtoimage
            .toSvg(lastCanvasContainer)
            .then((svgString) => {
              const tempContainer = document.createElement('div');
              tempContainer.innerHTML = svgString;
  
              const capturedSvg = tempContainer.querySelector('svg');
              
              const styleElement = document.createElementNS(svgNamespaceURL, 'style');
              styleElement.textContent = this.mapReportConfigData.svgConfig.svgBodyMargin;
              capturedSvg.appendChild(styleElement);      
              
              svgElement.appendChild(capturedSvg);
              containerSvgElement.appendChild(lastCanvasContainer);

              textElement.setAttribute('visibility', this.mapReportConfigData.svgConfig.textElement.visibilityVisible);
  
              const serializer = new XMLSerializer();
              let validSvgString = serializer.serializeToString(containerSvgElement);
              validSvgString = validSvgString.replace(/<a\b[^>]*>(.*?)<\/a>/g, '');
              this.content = validSvgString;
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        };
        img.src = mapTiles;
      });
      this.map.triggerRepaint();
    });
  }
  
  // This method is responsible for retrieving the width and height of an SVG string.
  getSvgImageHeightWidth(imageString: any): { width: number, height: number } {
    const parser      = new DOMParser();
    const doc         = parser.parseFromString(imageString, "image/svg+xml");
    const svgElement  = doc.documentElement;
    const width       = parseInt(svgElement.getAttribute("width"));
    const height      = parseInt(svgElement.getAttribute("height"));
    return { width, height };
  }
  
  calculateMapBoundaries(mapIndividualsViewModel : MapIndividualsViewModel[]):void{
    let swLat = Number.POSITIVE_INFINITY;
    let swLng = Number.POSITIVE_INFINITY;
    let neLat = Number.NEGATIVE_INFINITY;
    let neLng = Number.NEGATIVE_INFINITY;
    const padding = 10;

    mapIndividualsViewModel.forEach(markerPoint =>{
      swLat = Math.min(swLat, markerPoint.latitude)
      swLng = Math.min(swLng, markerPoint.longitude)
      neLat = Math.max(neLat, markerPoint.latitude)
      neLng = Math.max(neLng, markerPoint.longitude)
    })
    if(swLat === neLat){
      swLat -= padding;
      neLat += padding;
    }
    if(swLng === neLng){
      swLng -= padding;
      neLng += padding;
    }
    this.mapBoundary = {
      swLat: swLat,
      swLng: swLng,
      neLat: neLat,
      neLng: neLng,
      centerLat: (neLat + swLat) / 2,
      centerLng: (neLng + swLng) / 2,
    };
  }

  setBoundary(): void{
    this.map.fitBounds([
      [this.mapBoundary.swLng, this.mapBoundary.swLat,],  // southwestern corner of the bounds
      [this.mapBoundary.neLng, this.mapBoundary.neLat,]   // northeastern corner of the bounds
      ],
      {
          padding: { top:50, bottom: 200, left: 50, right: 50 },
          animate: false
        }
    );
  }

  /**
   * Get Individuals List
   * If mapIndivduals data already exist then call initMarkers() otherwice get data from server
   */
  initMapIndividuals(): void {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    if (this.isMapIndividualsLoaded) {
      this.initMarkers();
      this.loadingService.hide(processId);
      return;
    }
    try {
      this.mapIndividualsSubscription = this.mapReportService.getAllMapIndividuals().subscribe(
        (mapIndividuals) => {
          this.mapIndividualsList = mapIndividuals;
          this.isMapIndividualsLoaded = true;
          this.selectedYear = this.mapReportService.getHighestYear(this.mapIndividualsList);
          this.calculateMapBoundaries(mapIndividuals);
          this.initMarkers();
        },
        (error: HttpErrorResponse) => {
          this.messageDialogService.openInfo(
            this.translateHandler.translate('mapReport.lbl_heading_missing_info'), 
            this.translateHandler.translate('mapReport.lbl_missing_info'), 
            this.translateHandler.translate('mapReport.lbl_navigate_to_member_editor')).subscribe((res: boolean) => {
              if (res) {
                let data = {
                  id : this.notifierService.getCurrentRootMemberId(),
                  editorMode: 0,
                  isUpdateHistory: true
                }
                this.notifierService.setCurrentEditor(AppConstatnts.routerModes[EditorMode[0]], data, null);
              }
          });
        }
      ); 
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      this.loadingService.hide(processId);
    }   
  }

  initMarkers(): void {
    this.markerList = [];
    for (const individual of this.mapIndividualsList) {
      const markerElement = document.createElement('div');
      const marker        = new mapboxgl.Marker(markerElement);
      const popup         = this.getPopUps(individual);  
      let imageUrl        = this.getMarkerBackground(individual.mapIndividualDetails);
      
      markerElement.classList.add('marker');
      markerElement.style.backgroundImage = `url(${imageUrl})`;
      marker.setLngLat([individual.longitude, individual.latitude]).addTo(this.map);
      
      marker.getElement().addEventListener('click', () => {
          marker.setPopup(popup);
          marker.togglePopup();  
      });
      this.markerList.push(marker);
    }
    this.setBoundary();
    this.updateMarkers();
  }

  getPopUps(mapIndividuals: MapIndividualsViewModel): mapboxgl.Popup {  
    let popupList:string[]  = [];
    let type                = 'single';
    
    for (const individual of mapIndividuals.mapIndividualDetails) {
      const profileImageUrl = individual.profileImageUrl ? individual.profileImageUrl : `assets/report/mapreport/images/${individual.gender}.png`;
      const alivePeriod = individual.deathYear ? `${individual.birthYear} - ${individual.deathYear}` : individual.birthYear;
      
      let popup = `
        <div class="popup-box ${type}-${individual.gender}">
            <img class="map-individual-profile-image" src="${profileImageUrl}">
            <div class="map-individual-details">
              <span class="map-individual-display-name">${individual.displayName}<span>
              <span class="map-individual-alive-period">${alivePeriod}</span>
            </div>
        </div>`;
      type = 'multi'
      popupList.push(popup);
    }

    return  new mapboxgl.Popup({ closeButton: false, closeOnClick:true, anchor: 'bottom'})
      .setHTML(`<div class = "popup-container">${popupList.join("")}</div>`);
  }

 /**
  * This method triggerd when selected year changed in time bar.
  * Then update the markers according to selcted year
  */
  updateMarkers():void {
    this.countAliveIndividuals(this.selectedYear, this.mapIndividualsList);
    this.mapIndividualsList.forEach((mapIndividuals, index) =>{

      if(mapIndividuals.mapIndividualDetails.length == 1) {
        if((mapIndividuals.mapIndividualDetails[0].birthYear - 1) >= this.selectedYear) {
          mapIndividuals.mapIndividualDetails[0].currentAliveStatus = AliveStatus.Unknown
        }
        else if(mapIndividuals.mapIndividualDetails[0].deathYear == undefined){

          if(mapIndividuals.mapIndividualDetails[0].aliveStatus == AliveStatus.Alive && 
              mapIndividuals.mapIndividualDetails[0].birthYear - 1 >= this.selectedYear) {
              mapIndividuals.mapIndividualDetails[0].currentAliveStatus = AliveStatus.Alive

          } else if(mapIndividuals.mapIndividualDetails[0].birthYear + 
              this.mapReportConfigData.defaultMapIndividualLifeTime >= this.selectedYear){
              mapIndividuals.mapIndividualDetails[0].currentAliveStatus = AliveStatus.Alive

          } else {
            mapIndividuals.mapIndividualDetails[0].currentAliveStatus = AliveStatus.Unknown
          }
        }
        else if(mapIndividuals.mapIndividualDetails[0].deathYear - 1 >= this.selectedYear) {
          mapIndividuals.mapIndividualDetails[0].currentAliveStatus = AliveStatus.Alive  
        } else {
          mapIndividuals.mapIndividualDetails[0].currentAliveStatus = AliveStatus.Dead
        }
      }
    let imageUrl = this.getMarkerBackground(mapIndividuals.mapIndividualDetails);
    this.markerList[index].getElement().style.backgroundImage = `url(${imageUrl})`
    })
  }

  getMarkerBackground(mapIndividualsList:MapIndividualDetails[]) : string {
    let imageUrl = `assets/report/mapreport/themes/${this.theme.themeData.type.toLowerCase()}/images/markers/`;
      if (mapIndividualsList.length > 1) {
        imageUrl = `${imageUrl}multi.png`;
      } else if(mapIndividualsList[0].currentAliveStatus == AliveStatus.Unknown) {
        imageUrl = `${imageUrl}default.png`;
      } else if(mapIndividualsList[0].currentAliveStatus == AliveStatus.Dead) {
        imageUrl = `${imageUrl}death.png`;
      } else {
        const gender = mapIndividualsList[0].gender;
        imageUrl = `${imageUrl}${gender}.png`;
      }
    return imageUrl;
  }

  /**
   * This method calculate the alive map individuals count according to selected year
   */
  countAliveIndividuals(year: number, mapIndividualsList: MapIndividualsViewModel[]) :void{
    let aliveIndividualsCount = 0;
    mapIndividualsList.forEach((individualList) => {
      individualList.mapIndividualDetails.forEach((individual)=>{
        if( individual.birthYear <= year ){
         if(individual.deathYear === undefined){
            if( individual.birthYear + this.mapReportConfigData.defaultMapIndividualLifeTime >= year){
              aliveIndividualsCount++;
            }
          }
          else if(individual.deathYear >= year 
            || individual.aliveStatus === AliveStatus.Alive){
            aliveIndividualsCount++;
          }
        }
      })
    });
    this.aliveIndividualsCount = aliveIndividualsCount;
    this.mapReportService.setFamilyStatus({ count: aliveIndividualsCount, year: this.selectedYear });
  }

  private showError(title: string, info: string, prompt: string): Observable<any> {
    return this.messageDialogService.openError(
      this.translateHandler.translate(title),
      this.translateHandler.translate(info),
      prompt == null ? null : this.translateHandler.translate(prompt)
    );
  }

  // Notify the theme change and call onThemeChange method
  private subscribeToThemeData(): void {
    this.themeSubscription = this.mapReportService.themeData$.subscribe((data) => {
      if (data) {
        this.theme = data;
        this.initMap();
      }
    });
  }

  private subscribeToPublishData(): void {
    this.mapPublishData = this.mapReportService.currentPublishData$.subscribe((data) => {
      if (data) {
        this.publishMapReport(data);
        this.mapReportService.clearPublishData();
      }
    });
  }
}

