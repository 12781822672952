export class PlaceBase {
    id: number;
    rawData: string;
    note : string;
    description : string;
    type: string;
    detail: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    country: string;
    mediaUrl  : string;
    lat: number;
    lng: number;
}