export class IndividualBaseViewModel {
    id: number;
    name: string;
    gender: string;
    displayIndex: number;
    Image: string;
    fatherId: number;
    motherId: number;
    x: number;
    y: number;
    expanded: boolean;
    status: string;
    firstName: string;
    lastName: string;
    displayName: string;
    childId: number;
    profileImage: string;
    profileImageId: number;
    dateRangeStr: string;
    parentId: number;
    parentFamilyId: number;
    spouseFamilyId: number;
    childDisplayName: string;
}

export class IndividualViewModel extends IndividualBaseViewModel {
    Children: IndividualBaseViewModel[];
    Spouces: IndividualBaseViewModel[];
    Siblings: IndividualBaseViewModel[];
}
