import { SunChartUtil } from './sun-chart-util';
import { ReportUtil } from '../../common/reportutil';
import { ReportFont } from '../../common/reportfont';

export class SunChartGraphic {

  constructor() {
  }

  // Normal text
  public static getTextNormal(text, nooflines, textConfig, font: ReportFont) {
    if (ReportUtil.isEmptyObject(textConfig)) {
      return null;
    }
    if (text == null) {
      return null;
    }
    var textWords = font.getString(text, textConfig.fontSize, textConfig.length, nooflines, true);
    return { "x": textConfig.x, "y": textConfig.y, "style": textConfig.style, "text": textWords[0], "textRemaining": textWords.slice(1) };
  }

  // Curve text graphics 
  public static getCurveText(id, graphicConfig, font: ReportFont) {

    if (ReportUtil.isEmptyObject(graphicConfig.textConfig)) {
      return null;
    }

    if (graphicConfig.text == null) {
      return null;
    }

    var startAngle = 0;
    var stopAngle = 0;
    var sectorConfig = graphicConfig.textConfig.sectors;
    var sectorAlignment = "";
    var orientation = 1;

    if (sectorConfig && sectorConfig.length > 0) {
      var secmul = graphicConfig.levelCount / sectorConfig.length;
      startAngle = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].startAngle;
      stopAngle = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].stopAngle;
      sectorAlignment = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].alignment;

      var orientTemp = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].textOrientation;
      if (orientTemp) {
        orientation = parseInt(orientTemp);
      }
    }

    // Top - normal orientation text - wTop - radius
    var textR2Top = graphicConfig.r + graphicConfig.textConfig.wTop;

    // calculate text
    var textLength = (Math.PI * textR2Top * (graphicConfig.angle - startAngle - stopAngle)) / 180;
    var textWords = font.getString(graphicConfig.text, graphicConfig.textConfig.fontSize, textLength, graphicConfig.nooflines, true);

    var textStartP = SunChartUtil.getSectorPoint(textR2Top, startAngle);
    var textEndP = SunChartUtil.getSectorPoint(textR2Top, graphicConfig.angle - stopAngle);
    var pathTextStrUp = `M ${textStartP.x} ${textStartP.y} A${textR2Top} ${textR2Top}, 0, 0 1,${textEndP.x} ${textEndP.y}`;

    var textR2Bot = graphicConfig.r + graphicConfig.textConfig.wBot;
    var btextEndP = SunChartUtil.getSectorPoint(textR2Bot, startAngle);
    var btextStartP = SunChartUtil.getSectorPoint(textR2Bot, graphicConfig.angle - stopAngle); //sectorAngleAdj);
    var pathTextStrDown = `M ${btextStartP.x} ${btextStartP.y} A${textR2Bot} ${textR2Bot}, 0, 0 0,${btextEndP.x} ${btextEndP.y}`;

    // Normal text orientation
    var retTextPath = pathTextStrUp;//pathTextStrDown;

    if (orientation == -1) {
      retTextPath = pathTextStrDown;
    }

    // id example = ctext1_1
    return { "id": id, "path": retTextPath, "text": textWords[0], "textRemaining": textWords.slice(1), "alignment": SunChartUtil.getAlignment(sectorAlignment) };
  }

  // Radial text
  public static getRadialText(id, graphicConfig, font: ReportFont) {
    if (ReportUtil.isEmptyObject(graphicConfig.textConfig)) {
      return null;
    }

    if (graphicConfig.text == null) {
      return null;
    }

    var sectorAngleAdj = 0;
    var sectorDirection = 1;
    var sectorConfig = graphicConfig.textConfig.sectors;
    var sectorRAdj = 0;
    var sectorAlignment = "";

    if (sectorConfig && sectorConfig.length > 0) {
      var secmul = graphicConfig.levelCount / sectorConfig.length;
      sectorAngleAdj = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].adjAngle;
      sectorDirection = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].direction;
      sectorRAdj = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].adjr;
      sectorAlignment = sectorConfig[Math.trunc(graphicConfig.sectorId / secmul)].alignment;
    }

    var pathPoints = SunChartUtil.getSectorLine(graphicConfig.r + sectorRAdj, graphicConfig.r + sectorRAdj + graphicConfig.textConfig.w, sectorAngleAdj);
    var pathTextLine = `M ${pathPoints.p1.x} ${pathPoints.p1.y} L ${pathPoints.p2.x} ${pathPoints.p2.y}`;
    if (sectorDirection == -1) {
      pathTextLine = `M ${pathPoints.p2.x} ${pathPoints.p2.y} L ${pathPoints.p1.x} ${pathPoints.p1.y}`;
    }

    var textPathLength = Math.sqrt(Math.pow((pathPoints.p1.x - pathPoints.p2.x), 2) + Math.pow((pathPoints.p1.y - pathPoints.p2.y), 2));
    var textWords = font.getString(graphicConfig.text, graphicConfig.textConfig.fontSize, textPathLength, graphicConfig.nooflines, true);

    return { "id": id, "path": pathTextLine, "text": textWords[0], "textRemaining": textWords.slice(1), "alignment": SunChartUtil.getAlignment(sectorAlignment) };
  }

  // Sector lines - debugging
  public static getSectorLine(id, sectorConfig) {
    if (ReportUtil.isEmptyObject(sectorConfig)) {
      return null;
    }

    // Sector lines
    var r1 = SunChartUtil.sumPRev(sectorConfig.levels, sectorConfig.levelId - 1) + sectorConfig.levels[sectorConfig.levelId].levelMargin;
    var r2 = SunChartUtil.sumPRev(sectorConfig.levels, sectorConfig.levelId) + sectorConfig.levels[sectorConfig.levelId].levelMargin;;
    var startLine = SunChartUtil.getSectorLine(r1, r2, sectorConfig.levels[sectorConfig.levelId].sectorMargin);
    var arcRadius = r2; //this.config.levels[x-1].w + this.config.levels[x].w;
    var endLine = SunChartUtil.getSectorLine(r2, r1, sectorConfig.angle - sectorConfig.levels[sectorConfig.levelId].sectorMargin);

    // string formatting es6 -" ` " different quote character
    var pathStr = `M ${startLine.p1.x} ${startLine.p1.y} L${startLine.p2.x} ${startLine.p2.y} A${arcRadius} ${arcRadius}, 0, 0 1,${endLine.p1.x} ${endLine.p1.y} L${endLine.p2.x} ${endLine.p2.y}`;
    return { "id": id, "path": pathStr };
  }

  // Sector image 
  public static getSectorImage(profilePicUrl, radius, sectorAngle, axisAdjustment, levelCount, sectorIndex, imageConfig) {
    if (ReportUtil.isEmptyObject(imageConfig)) {
      return null;
    }

    if (profilePicUrl == null) {
      return null;
    }

    var sectorAngleAdj = 0;
    var sectorConfig = imageConfig.sectors;
    var r = 0;

    if (sectorConfig && sectorConfig.length > 0) {
      var secmul = levelCount / sectorConfig.length;
      sectorAngleAdj = sectorConfig[Math.trunc(sectorIndex / secmul)].adjAngle;
      r = sectorConfig[Math.trunc(sectorIndex / secmul)].r;
    } else {
      sectorAngleAdj = 0;
      r = 0;
    }

    var imgCenter = SunChartUtil.getSectorPoint(radius + r, axisAdjustment + sectorAngle * sectorIndex + sectorAngleAdj);
    return { "center": imgCenter, "r": imageConfig.r, "offsetX": imageConfig.offsetX - imageConfig.r, "offsetY": imageConfig.offsetY - imageConfig.r, imgurl: profilePicUrl };
  }

  public static getGraphics(graphicConfig, assetUrl) {

    // Check if its empty
    if (!graphicConfig || ReportUtil.isEmptyObject(graphicConfig)) {
      return;
    }

    return {
      "x": (0 - graphicConfig.w / 2 + graphicConfig.offsetX),
      "y": (0 - graphicConfig.h / 2 + graphicConfig.offsetY),
      "url": assetUrl + graphicConfig.url,
      "w": graphicConfig.w,
      "h": graphicConfig.h
    };
  }
}