import { PlaceBase } from '../../models/place/place-base';

export class PlaceAddressFormatter {

    //To take fulladdress(RawData).     
    public static getFullAddress(place: any) {
        let fulladdress = new Array<String>();

        if (place.detail)
            fulladdress.push(place.detail);
        if (place.line2)
            fulladdress.push(place.line2);
        if (place.line3)
            fulladdress.push(place.line3);
        if (place.line4)
            fulladdress.push(place.line4);
        if (place.line5)
            fulladdress.push(place.line5);
        if (place.country)
            fulladdress.push(place.country);

        return fulladdress.join(",");
    }

    public static addressSpliter(fulladdress : string) : any{
        let placeObj = new  PlaceBase();

        if(fulladdress && fulladdress != ""){
            let addressArray = fulladdress.split(',');
            placeObj.detail = addressArray[0] ?? null;
            placeObj.line2 = addressArray[1] ?? null;
            placeObj.line3 = addressArray[2] ?? null;
            placeObj.line4 = addressArray[3] ?? null;
            placeObj.line5 = addressArray[4] ?? null;
            if(addressArray.length > 5){
                addressArray.splice(0,5);
                let lastaddress = addressArray.join(",");
                placeObj.country = lastaddress;
            }else{
                placeObj.country = addressArray[5] ?? null;
            }           
        }       

        return placeObj;

    }
}