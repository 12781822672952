import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction, DropMenuType } from 'src/app/common/enums/enums';
import { DropMenu } from './models/DropMenu';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { first, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss']
})
export class DropMenuComponent implements OnInit {

  // Member variables
  isVisible: boolean = false;
  @Input() menu = new DropMenu();
  @Output() action = new EventEmitter<{ action: DropMenuAction, reference: any }>();

  toggleEvent: any;

  outsideClickSubscription: Subscription;

  // Listeners
  //@HostListener('document:click', ['$event']) !DONT USE - CANNOT NOT UNSUBSCRIBE

  constructor() { }

  ngOnInit(): void {

  }

  getMenuName(index) {
    return DropMenuAction[index];
  }

  toggle($event) {
    if (this.menu.type != DropMenuType.Active && this.menu.menuItems != null) {
      this.isVisible ? this.hide() : this.show();
      $event.stopPropagation();   // Important : to stop bubbling events
      $event.preventDefault();
    }
  }

  show() {
    document.body.click();
    this.isVisible = true;
    this.outsideClickSubscription = fromEvent(document, 'click').pipe(first()).subscribe(event => {
      this.hide();
    })
  }

  hide() {
    this.isVisible = false;
    this.outsideClickSubscription.unsubscribe();
  }

  actionCLick($event, action: DropMenuAction) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hide();
    this.action.emit({ action: action, reference: this.menu.ref });
  }
}
