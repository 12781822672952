import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TooltipService } from 'src/app/services/UI/tooltip.service';
import { TooltipHeadPosition } from '../models/tooltip-head-position';
import { TooltipContentBaseComponent } from '../tooltip-content-base/tooltip-content-base.component';

@Component({
  selector: 'app-tooltip-main',
  templateUrl: './tooltip-main.component.html',
  styleUrls: ['./tooltip-main.component.scss']
})

export class TooltipMainComponent implements OnInit, OnDestroy {

  @ViewChild('content', { read: ViewContainerRef, static: false}) public content: ViewContainerRef;

  left        :number;  // attrib binding
  top         :number;   // attrib binding   
  color       :string;

  isHidden    :boolean = true;
  
  tooltipArrowWidth:number = 10;

  tooltipPosition : TooltipHeadPosition = TooltipHeadPosition.Bottom;
  
  outsideClickSubscription:Subscription;

  constructor(private elementRef: ElementRef, 
              private resolver: ComponentFactoryResolver, 
              private changeDetector : ChangeDetectorRef,
              private tooltipService:TooltipService) { 
    
  }
 
  ngOnInit(): void {
    
    this.left = 0;
    this.top = 0;
    
    this.tooltipService.onVisibilityChange.subscribe((tipInfo) => {      
      
      this.content.clear();

      if ( tipInfo == null ){
        this.hide();
        return;
      }

      this.tooltipPosition  = tipInfo.position;
      this.color            = tipInfo.color;

      const factory         = this.resolver.resolveComponentFactory<TooltipContentBaseComponent>(tipInfo.component);    
      const componentRef    = this.content.createComponent<TooltipContentBaseComponent>(factory);    
      // set parameters    
      componentRef.instance.data = tipInfo.data;
      
      //this.changeDetector.detectChanges();
      // load content and then check
      setTimeout( ()=>
      {
        var adjX, adjY;    
        var sourceBound = tipInfo.sourceEvent.target ? tipInfo.sourceEvent.target.getBoundingClientRect() : tipInfo.sourceEvent;
        var tooltipBoound = this.elementRef.nativeElement.firstChild.getBoundingClientRect();

        if ( this.isTop() ){          
          adjY = sourceBound.height + this.tooltipArrowWidth ;
          adjX = sourceBound.width/2 - tooltipBoound.width /2;
        }
        if ( this.isBottom() ){          
          adjY = 0 - tooltipBoound.height - this.tooltipArrowWidth ;
          adjX = sourceBound.width/2 - tooltipBoound.width /2;          
        }
        if ( this.isLeft() ){          
          adjX = sourceBound.width + this.tooltipArrowWidth ;
          adjY = sourceBound.height/2 - tooltipBoound.height /2;          
        }
        if ( this.isRight() ){          
          adjX = 0 - tooltipBoound.width - this.tooltipArrowWidth;
          adjY = sourceBound.height/2 - tooltipBoound.height/2;          
        }

        this.top = sourceBound.y + adjY;
        this.left = sourceBound.x + adjX;
        // Show at last!
        this.show();
      }, 200);
      
      tipInfo.sourceEvent.target ? tipInfo.sourceEvent.stopPropagation() : null;      
    }); // end subscription
  }

  getInvertColorCode(){
    return this.color == "white" ? 0 : 1;
  }

  isTop(){
    return this.tooltipPosition == TooltipHeadPosition.Top;
  }
  isLeft(){
    return this.tooltipPosition == TooltipHeadPosition.Left;
  }
  isRight(){
    return this.tooltipPosition == TooltipHeadPosition.Right;
  }
  isBottom(){
    return this.tooltipPosition == TooltipHeadPosition.Bottom;
  }

  show(){
    this.isHidden = false;
    this.outsideClickSubscription = fromEvent(document,'click').pipe(first()).subscribe( event =>{
     this.hide();
    })        
  }

  hide(){
    this.isHidden = true;
    this.outsideClickSubscription.unsubscribe();
  }
  stopPropagation($event){
    $event.stopPropagation();
  }
  ngOnDestroy(): void {
    console.log("destroy");
  }
}
