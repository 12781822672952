export default class BookReport {
    id: string;
    lang: string;
    pageCount: number;
    name: string;
    isCompleted: boolean;
    constructor(id: string, pageCount: number = 0, isCompleted: boolean = false, name?: string, lang?: string) {
        this.id = id;
        this.lang = lang;
        this.pageCount = pageCount
        this.name = name;
        this.isCompleted = isCompleted;
    }
}