import { TreeNode } from './tree-node';

export class PedigreeNode {
  id: number;
  parentId: number;

  constructor(id, parentId) {
    this.id = id;
    this.parentId = parentId;
  }

  static getSampleData() {

    var sampleData: PedigreeNode[] = [];
    // Root Node
    sampleData.push(new PedigreeNode(1, 0));

    // 1st Level
    sampleData.push(new PedigreeNode(2, 1));
    sampleData.push(new PedigreeNode(3, 1));

    // 2nd Level
    sampleData.push(new PedigreeNode(4, 2));
    sampleData.push(new PedigreeNode(5, 2));

    sampleData.push(new PedigreeNode(6, 3));
    sampleData.push(new PedigreeNode(7, 3));

    // 3rd Level
    sampleData.push(new PedigreeNode(8, 4));
    sampleData.push(new PedigreeNode(9, 4));

    sampleData.push(new PedigreeNode(10, 5));
    sampleData.push(new PedigreeNode(11, 5));

    sampleData.push(new PedigreeNode(12, 6));
    sampleData.push(new PedigreeNode(13, 6));

    sampleData.push(new PedigreeNode(14, 7));
    sampleData.push(new PedigreeNode(14, 7));

    sampleData.push(new PedigreeNode(15, 8));
    sampleData.push(new PedigreeNode(16, 8));

    sampleData.push(new PedigreeNode(17, 15));
    sampleData.push(new PedigreeNode(18, 15));

    var root = sampleData.find(e => e.parentId == 0);
    var rootTreeNode = new TreeNode<PedigreeNode>(root, null);
    // add tree node children recursively
    rootTreeNode.children = this.getChildNodes(sampleData, rootTreeNode);
    return rootTreeNode;
  }

  static getSampleData1() {
    var sampleData: PedigreeNode[] = [];

    // Root Node
    sampleData.push(new PedigreeNode(1, 0));

    // 1st Level
    sampleData.push(new PedigreeNode(2, 1));
    sampleData.push(new PedigreeNode(3, 1));
    sampleData.push(new PedigreeNode(4, 1));

    // 2nd Level
    sampleData.push(new PedigreeNode(5, 2));
    sampleData.push(new PedigreeNode(6, 2));
    sampleData.push(new PedigreeNode(7, 4));
    sampleData.push(new PedigreeNode(8, 4));

    // 3rd Level
    sampleData.push(new PedigreeNode(9, 6));
    sampleData.push(new PedigreeNode(10, 6));

    sampleData.push(new PedigreeNode(11, 8));
    sampleData.push(new PedigreeNode(12, 8));
    sampleData.push(new PedigreeNode(13, 8));
    sampleData.push(new PedigreeNode(14, 8));
    sampleData.push(new PedigreeNode(15, 8));


    var root = sampleData.find(e => e.parentId == 0);
    var rootTreeNode = new TreeNode<PedigreeNode>(root, null);
    // add tree node children recursively
    rootTreeNode.children = this.getChildNodes(sampleData, rootTreeNode);
    return rootTreeNode;
  }

  static generateSampleTree(data) {
      return this.generateTreeNodeFromList(data);
  }

  // Unique id generation for binary tree
  private static id: number = 999999;

  static getNextId() {
    PedigreeNode.id++;
    return PedigreeNode.id;
  }
  // Generator binary tree for parents
  static generateBinaryTree(noOfGenerations: number) {

    var sampleData: PedigreeNode[] = [];
    sampleData.push(new PedigreeDataNode(1, 0, { "name": "Stein Inge Haaland", "place": "Oslo, Norway", "period": "1966 -1996", "gender": "M", "profileURL": "profilepic.png" }));
    var lastId = 1;
    for (var x = 1; x <= Math.pow(2, noOfGenerations) - 1; x++) {
      lastId++;
      sampleData.push(new PedigreeDataNode(lastId, x, { "name": "Stein Inge Haaland", "place": "Oslo, Norway", "period": "1966 -1996", "gender": "M", "profileURL": "profilepic.png" }));
      lastId++;
      sampleData.push(new PedigreeDataNode(lastId, x, { "name": "Stein Inge Haaland", "place": "Oslo, Norway", "period": "1966 -1996", "gender": "M", "profileURL": "profilepic.png" }));
    }
    return this.generateTreeNodeFromList(sampleData);
  }

  static generateTreeNodeFromList(list) {
    var root = list.find(e => e.parentId == 0);
    var rootTreeNode = new TreeNode<PedigreeNode>(root, null);
    // add tree node children recursively
    rootTreeNode.children = this.getChildNodes(list, rootTreeNode);
    return rootTreeNode;
  }

  static getChildFunctionNodes(node: any) {

    var nodeFn = node.item as PedigreeFnNode;
    // dont process function nodes
    if (nodeFn.fnType) return null;

    if (node.isLeaf()) {
      var children = [];
      children.push(new TreeNode<PedigreeNode>(new PedigreeFnNode(PedigreeNode.getNextId(), node.item.id, 0), node));
      children.push(new TreeNode<PedigreeNode>(new PedigreeFnNode(PedigreeNode.getNextId(), node.item.id, 1), node));
      return children;
    }

    if (node.children && node.children.length == 1) {

      const currentChild = node.children.find(child => child.item.data.gender === "F" || child.item.data.gender === "M");
      const nextGender = currentChild.item.data.gender === "F" ? "M" : "F";
      node.children = [];
      
      // Create the empty TreeNode based on nextGender
      const emptyNode = new TreeNode<PedigreeNode>(new PedigreeFnNode(PedigreeNode.getNextId(), node.item.id, nextGender), node);
      
      // Insert nodes based on currentChild's gender
      if (currentChild.item.data.gender === "F") {
        node.children.push(emptyNode, currentChild);
        node.children[1].children = PedigreeNode.getChildFunctionNodes(node.children[1]);
      } else {
        node.children.push(currentChild, emptyNode);
        node.children[0].children = PedigreeNode.getChildFunctionNodes(node.children[0]);
      }
      return node.children;
    }

    for (var x = 0; x < node.children.length; x++) {
      node.children[x].children = PedigreeNode.getChildFunctionNodes(node.children[x]);
    }
    return node.children;
  }

  static getChildBinaryNodes(node: TreeNode<PedigreeNode>, generation: number) {
    // exit if geneation exceeded
    // TODO: Report option - take it as a constant
    if (generation == 6) {
      return [];
    }

    if (node.children.length == 0) {
      node.children.push(new TreeNode<PedigreeNode>(new PedigreeNode(PedigreeNode.getNextId(), 0), node));
      node.children.push(new TreeNode<PedigreeNode>(new PedigreeNode(PedigreeNode.getNextId(), 0), node));
    }
    generation++;
    for (var x = 0; x < node.children.length; x++) {
      node.children[x].children = PedigreeNode.getChildBinaryNodes(node.children[x], generation);
    }
    return node.children;
  }

  // Generate child trees
  static getChildNodes(data: PedigreeNode[], parent: TreeNode<PedigreeNode>) {
    var childrenNodes: TreeNode<PedigreeNode>[] = [];
    //console.log("parent id " + parent.item.id);
    var children = data.filter(e => e.parentId == parent.item.id);
    for (var x = 0; x < children.length; x++) {
      var treeNode = new TreeNode<PedigreeNode>(children[x], parent);
      treeNode.children = this.getChildNodes(data, treeNode);
      childrenNodes.push(treeNode);
    }
    return childrenNodes;
  }

  static addChildren(data: PedigreeNode[], parentId: number, generation: number) {
    generation++;

    if (generation == 3) {
      return;
    }

    this.id++;
    var ChildId1 = this.id;
    data.push(new PedigreeNode(ChildId1, parentId));

    this.id++;
    var ChildId2 = this.id;
    data.push(new PedigreeNode(ChildId2, parentId));

    this.addChildren(data, ChildId1, generation);
    this.addChildren(data, ChildId2, generation);


  }
}

export class PedigreeDataNode extends PedigreeNode {
  data: any;
  constructor(id, parentId, data) {
    super(id, parentId);
    this.data = data;
  }
}

export class PedigreeFnNode extends PedigreeNode {
  fnType: number;
  constructor(id, parent, fnType) {
    super(id, parent);
    this.fnType = fnType;
  }
}
