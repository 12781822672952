import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RootMemberResponse } from '../common/models/RootMember';
import { RootFamily } from '../common/models/RootFamily';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  individualList: RootMemberResponse[] = [];
  familyList: RootFamily[] = [];
  listLength: number = environment.HISTORY_LIST_SIZE;

  constructor() { }

  clear(){
    this.individualList = [];
    this.familyList = [];
  }
  /**
   * Add an individual to the individualList
   * if the individual is already in the list, update it with new data
   */
  addSelectedIndividual(individual: any) {
    try {
      if (individual !== null) {
        // Todo: from one place id and another place Id is received.
        // adding to individual list
        if (this.individualList.length > 0) {
          var itemIndex = this.individualList.findIndex(item => individual.Id == item.Id);
          if (itemIndex > -1) {
            this.individualList.splice(itemIndex, 1);
            this.individualList.push(individual);
          } else {
            this.individualList.push(individual);
          }
          //Limiting the breadcrumb shwoing elements
          if (this.individualList.length > this.listLength) {
            this.individualList.shift();
          }
        } else {
          this.individualList.push(individual);
        }
      }
    } catch (error) {
      console.error('Individual list is empty ' + error);
      throw error;
    }
  }

  /**
   * Add a family to the familyList
   * if the family is already in the list, update it with new data
   */
  async addSelectedFamily(family: any) {
    try {
      if (family !== null) {
        if (this.familyList.length > 0) {
          var itemIndex = this.familyList.findIndex(item => family.id == item.id);
          if (itemIndex > -1) {
            this.familyList.splice(itemIndex, 1);
            this.familyList.push(family);
          } else {
            this.familyList.push(family);
          }
          //Limiting the breadcrumb shwoing elements
          if (this.familyList.length > this.listLength) {
            this.familyList.shift();
          }
        } else {
          this.familyList.push(family);
        }
      }
    } catch (error) {
      console.error('Individual list is empty ' + error);
      throw error;
    } finally{
      return true
    }
  }

  removeIndividual(individualId: any) {
    let index = this.individualList.findIndex(s => s.Id === individualId);
    this.individualList.splice(index, 1);
  }

  getIndividualList(count: number = null) {
    let slicedList = [...this.individualList];
    if (slicedList.length >= count && count !== null) {
      slicedList = slicedList.slice(slicedList.length - count);
      return slicedList;
    }
    return slicedList
  }

  getFamilyList(count: number = null) {
    let slicedList = [...this.familyList];
    if (slicedList.length >= count && count !== null) {
      slicedList = slicedList.slice(slicedList.length - count);
      return slicedList;
    }
    return slicedList
  }

  getSelectedIndividual() {
    let lastIndex = this.individualList.length - 1;
    if (lastIndex > -1) {
      return this.individualList[lastIndex];
    }

    return null;
  }

  getSelectedFamily() {
    let lastIndex = this.familyList.length - 1;
    if (lastIndex > -1) {
      return this.familyList[lastIndex];
    }

    return null;
  }


  ngOnDestroy() {
    this.individualList.length = 0;
  }
}
