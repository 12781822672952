<!-- Change theme stylesheet here! Eg. theme1, theme2..etc -->
<link rel="stylesheet" [href]="commonUrl + 'themes/common.css' | safe" />
<link rel="stylesheet" [href]="dynamicThemeUrl | safe" />
<app-nodeOptionMenu [hidden]="hideToolTip" [commonUrl]="commonUrl" 
    (rootMemberChange)="changeRootMember()"
    (openIndividualEditor)="openIndividualEditor()">
</app-nodeOptionMenu>

<svg id="svgViewableArea" preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute" [attr.height]="heightVal" [attr.width]="widthtVal" class="svgfull reportCommon" *ngIf="showReport">
  <g [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'" id="reportArea"
    [attr.transform]="reportTranslationObj.translateReportAttribute">

    <g transform="translate(2262 2262)">     

      <!-- Theme filters since this cannot be declared outside https://stackoverflow.com/questions/23696556/referencing-an-svg-filter-in-an-external-file-not-working 
         TODO : This code have to be with theme / should not be here / will effect all variations of sun-chart now :( 
    -->
      <defs>
        <filter id="themeFilter1">
          <feColorMatrix in="SourceGraphic" type="hueRotate" values="90" result="A" />
        </filter>
        <filter id="themeFilter2">
          <feColorMatrix in="SourceGraphic" type="hueRotate" values="180" result="A" />
        </filter>
        <filter id="themeFilter3">
          <feColorMatrix in="SourceGraphic" type="hueRotate" values="270" result="A" />
        </filter>
        <filter id="themeFilter4">
          <feColorMatrix in="SourceGraphic" type="hueRotate" values="0" result="A" />
        </filter>
        <filter id="grayscale">
          <feColorMatrix type="saturate" values="0.10" />
        </filter>
      </defs>

      <!-- Base grapics images in the report -->
      <image class="theme" *ngFor="let chartGraphic of chartGraphics; index as i" [attr.href]="chartGraphic.url"
        [attr.x]="chartGraphic.x" [attr.y]="chartGraphic.y" [attr.height]="chartGraphic.h"
        [attr.width]="chartGraphic.w" />
      
      <!-- Title image -->
      <image [attr.x]="config.base.reportTitleImg.offsetX" [attr.y]="config.base.reportTitleImg.offsetY+heightFix"
        [attr.width]="config.base.reportTitleImg.w" [attr.height]="config.base.reportTitleImg.h" 
        [attr.href]="chartBasics.assetUrl + config.base.reportTitleImg.url"/>
        
      <!-- Report title --> 
      <text [attr.x]="config.base.reportTitle.x" [attr.y]="config.base.reportTitle.y+heightFix"  [attr.class]="config.base.reportTitle.style" text-anchor="middle">
        {{reportTitle}}
      </text>     

      <!-- Level 0 -->
      <g id="level0">
        <text *ngFor="let level of chartLevel0.text; index as i" [attr.x]="level.x" [attr.y]="level.y"
          [attr.class]="level.style" text-anchor="middle">
          {{ level.text }}
        </text>

        <clipPath id="level0_imageclip">
          <circle shape-rendering="optimizeSpeed" [attr.cx]="chartLevel0.x"
            [attr.cy]="chartLevel0.y + chartLevel0.image.offsetY" [attr.r]="chartLevel0.image.r" stroke="grey"
            stroke-width="1" fill="none" />
        </clipPath>
        <image class="profilePicture" style="cursor: pointer;" (click)="addImage(chartLevel0.id,chartLevel0.profileImageId, true, 0)"
          clip-path="url(#level0_imageclip)" [attr.x]="chartLevel0.x - chartLevel0.image.r" [attr.y]="
            chartLevel0.y - chartLevel0.image.r + chartLevel0.image.offsetY
          " [attr.width]="chartLevel0.image.r * 2" [attr.height]="chartLevel0.image.r * 2"
          [attr.href]="chartLevel0.image.imgurl" />
      </g>
      <!-- End level 0 -->
      <!-- Text and profile images -->
      <!-- Sector definition -->
      <g *ngFor="let level of chartLevels; index as i" [attr.id]="'Sector' + level.levelId + '_' + level.sectorId">
        <!-- Debug lines -->
        <g style="cursor: pointer;">
          <path shape-rendering="optimizeSpeed" *ngIf="isShowBaseLines" [attr.transform]="level.transform" fill="none"
            [attr.d]="level.path" style="stroke: black; stroke-width: 1" />
          <path shape-rendering="optimizeSpeed" *ngFor="let text of isShowBaseLines ? level.texts : []; index as j"
            [attr.transform]="level.transform" fill="none" [attr.d]="text.path" style="stroke: red; stroke-width: 1" />

          <!-- Text section -->
          <defs>
            <path shape-rendering="optimizeSpeed" *ngFor="let text of level.texts; index as j"
              [attr.id]="'textPath_' + j + '_' + i" [attr.transform]="level.transform" fill="none" [attr.d]="text.path">
            </path>
          </defs>
          <text *ngFor="let text of level.texts; index as j" transform="scale(1,1)" [attr.class]="text.id"
            (click)="clickNode($event.pageX,$event.pageY,level.id,level.parentId,level.sectorId,level.levelId)">
            <textPath *ngIf="level.fnimage == null"  [attr.xlink:href]="'#textPath_' + j + '_' + i" [attr.startOffset]="text.alignment.startOffset"
              [attr.text-anchor]="text.alignment.anchor">
              {{ text.text}}
            </textPath>    

            <textPath *ngIf="level.fnimage != null" class="ignoreReport" [attr.xlink:href]="'#textPath_' + j + '_' + i" [attr.startOffset]="text.alignment.startOffset"
            [attr.text-anchor]="text.alignment.anchor">
            {{ text.text}}
            </textPath>              
          </text>
          
          <!-- End text section -->

          <!-- function image section -->
          <image *ngIf="level.fnimage != null" style="cursor: pointer;"  class="ignoreReport profilePicture" (click)="clickNode($event.pageX,$event.pageY,level.id,level.parentId,level.sectorId,level.levelId)"    [attr.clip-path]="'url(#imageclip_' + i + ')'"
          [attr.x]="level.fnimage.center.x + level.fnimage.offsetX" [attr.y]="level.fnimage.center.y + level.fnimage.offsetY"
          [attr.width]="level.fnimage.r * 2" [attr.height]="level.fnimage.r * 2" [attr.href]="level.fnimage.imgurl" />

          <!-- Profile image section -->
          <clipPath *ngIf="level.image != null" [attr.id]="'imageclip_' + i">
            <circle shape-rendering="optimizeSpeed" [attr.cx]="level.image.center.x" [attr.cy]="level.image.center.y"
              [attr.r]="level.image.r" stroke="grey" stroke-width="1" fill="none" />
          </clipPath>
          <image *ngIf="level.image != null" style="cursor: pointer;"  class="profilePicture" (click)="addImage(level.id, level.profileImageId, false,level.sectorId)"    [attr.clip-path]="'url(#imageclip_' + i + ')'"
            [attr.x]="level.image.center.x + level.image.offsetX" [attr.y]="level.image.center.y + level.image.offsetY"
            [attr.width]="level.image.r * 2" [attr.height]="level.image.r * 2" [attr.href]="level.image.imgurl" />
        </g>
      </g>
    </g>
  </g>
  <!-- end center transform -->
</svg>
<!-- </svg> -->