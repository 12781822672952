import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { PopupOpenMode } from 'src/app/common/enums/enums';

@Component({
  selector: 'app-reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.scss']
})
export class ReportsMenuComponent extends DialogBaseComponent implements OnInit  {

  public data: any;
  viewMode: number = 1;
  constructor(private ds: DialogService, private ref1: ChangeDetectorRef,) {
    super(ds, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();
    let options = PopupOptions.options.find(t => t.mode == this.data.mode) || PopupOptions.options[0];
    this.data.options = options;
    this.data.options.primaryTabName = (this.data.mode == PopupOpenMode.List) ? "lbl_all_reports" : "lbl_published_reports";
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {
    this.data.viewMode = mode;
    if (mode == 1)
      this.data.reference = { Id: 0, rawData: "" }
  }
}
