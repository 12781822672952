import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editor',
  templateUrl: './editor-template.component.html',
  styleUrls: ['./editor-template.component.scss']
})
export class EditorTemplateComponent implements OnInit {

  showMemberEditor: boolean = false;
  showFamilyEditor: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.showMemberEditor = this.router.url.toString().includes("membereditor");
    this.showFamilyEditor = this.router.url.toString().includes("familyeditor");
  }

}
