import { BranchHelper } from "../../common/reportAlgorithm/branch-helper";
import { CircularHelper } from "../../common/reportAlgorithm/circular-helper";
import { TreeHelper } from "../../common/reportAlgorithm/tree-helper";
import { TreeNode } from "../../common/reportAlgorithm/tree-node";
import { ArtisticGraphics } from "./artistic-graphics";
import { ArtisticTreeBase } from "./artistic-tree-base";
import { ArtisticTreeNode } from "./artistic-tree-node";
import { CircularRange } from "./circular-range";


export class ArtisticCircular extends ArtisticTreeBase{
    
    randomImpl;
    constructor(rootNode, implConfig, reportBase, commonUrl, randomImpl){
        super(rootNode, implConfig, reportBase, commonUrl);
        this.randomImpl = randomImpl;
    }

    getNodes(){
        let nodeList  = []  
        this.generatePedigreeTree(this.rootNode)
        this.createNodeList(nodeList,this.rootNode)
        this.generateCircularTree(nodeList, this.rootNode)
        // randomizing tree nodes
        if (this.randomImpl == 'circularRange'){
            let circularRandom = new CircularRange()
            circularRandom.init(this.implConfig.randomization.circularRange)
            circularRandom.generateRandomNodes(nodeList, this.rootNode)
        }
        let uiNodeList = this.getUiNodes(nodeList)
        return uiNodeList;
    }

    getBranches(){
        let branchList = []
        let branchUrl = this.commonUrl + "images/" + this.implConfig.base.assetUrl + "branches"
        let branchHelper = new BranchHelper(branchUrl, this.implConfig.branches)
        branchHelper.calculateBranches(this.rootNode, branchList)
        return branchList;
    }

    //crreate graphic nodes
    getUiNodes(nodeList) {
        let graphicNodes = []
        let graphicHelper =  new ArtisticGraphics(this.commonUrl, this.implConfig)
        for (let node of nodeList){
          let element = graphicHelper.getChartData(node);
          graphicNodes.push(element);
        }
        return graphicNodes;
    }

    // pedigree nodes creation
    private generatePedigreeTree(rootNode:TreeNode<ArtisticTreeNode>){
        TreeHelper.init(this.implConfig.base.levels);
        TreeHelper.initializeNodes(rootNode, 0);
        TreeHelper.calculateInitalX(rootNode);
        TreeHelper.checkAllChildrenOnScreen(rootNode);
        TreeHelper.calculateFinalPositions(rootNode, 0);

        this.centerPedigreeTree(rootNode, this.reportBase.svg.w/2 - rootNode.x)
    }

    // recursive function to get the nodeList when root node is given
    private createNodeList(nodeList, rootNode){
        for (let i = 0; i < rootNode.children.length; i++) {
            this.createNodeList(nodeList,rootNode.children[i]);
        }
        nodeList.push(rootNode)
    }

    // circular calculations
    private generateCircularTree(nodelist, rootNode){
        let maxMinValues = this.getMaxMinSpan(nodelist) 
        this.centerNodes(nodelist, (maxMinValues[0] - maxMinValues[1])/2)
        let mappingFactor = this.getMappingFactor(nodelist);           // use to control mapping when low nb of nodes are available
        
        let circularHelper:CircularHelper = new CircularHelper(this.implConfig, this.reportBase.svg);
        circularHelper.transform(nodelist, rootNode, mappingFactor*(maxMinValues[0]+ maxMinValues[1]));
    }

    // center the pedigree chart to center of the report when rootNode x value is given
    private centerPedigreeTree(node, value){
        for (let i = 0; i < node.children.length; i++) {
            this.centerPedigreeTree(node.children[i], value);
        }
        node.x += value
        node.y = -(node.y)
    }
    // get max and min values of pedigree chart
    private getMaxMinSpan(nodelist){
        let maxSpan = 0
        let minSpan = 0 
        for (let node of nodelist) {
            if(node.x > this.implConfig.base.rootX){
                maxSpan = Math.max( maxSpan, node.x - this.implConfig.base.rootX)
            }else{
                minSpan = Math.max( minSpan,  this.implConfig.base.rootX - node.x)
            }      
        } 
        if (minSpan == 0 && maxSpan ==0){
            return [1, 1]
        }
        return [minSpan, maxSpan]
    }

    /**
    * Determine factor for maimum mapping length when there are low amount of nodes 
    * (upto 8 max nb of nodes are considered)
    */
    private getMappingFactor(nodeList){
        let nbOfNodes = [0,0,0,0,0,0]
        for(let node of nodeList){
            if (nbOfNodes[node.level]>8){
                return 1;
            } 
            nbOfNodes[node.level] += 1 
        }
        let maxNbOfNodes = Math.max(...nbOfNodes)
        if (maxNbOfNodes == 1){
            return 1;
        }
        return 8/(maxNbOfNodes-1)
    }

    // center nodes as balanced
    private centerNodes(nodeList, centerValue){  
        for (let i=0; i<nodeList.length-1; i++) {
            nodeList[i].x += centerValue
        }}
    
}
