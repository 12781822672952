import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapReportMainComponent } from "./map-report-main/map-report-main.component";
import { MapContainerComponent } from "./map-container/map-container.component";
import { SubheaderModule } from "../../subheader/subheader.module";
import { MapReportService} from "./services/map-report-service.service";
import { MapReportToolBarComponent } from "./map-report-tool-bar/map-report-tool-bar.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SafePipe } from "../pedigreechart/pedigree/safe.pipe";
import { TimeBarComponent } from "./time-bar/time-bar.component";


@NgModule({
  declarations: [
    MapReportMainComponent,
    MapContainerComponent,
    MapReportToolBarComponent,
    TimeBarComponent,
    SafePipe,
  ],
  imports: [
    CommonModule,
    SubheaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [MapReportService],
})
export class MapReportModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
