import { ReportFont } from '../../common/reportfont';
import { ReportUtil } from '../../common/reportutil';
// import { Font } from './Font';
// import { Util } from './util';

/*
* All graphic elements related to the pedigree charts
*/
export class PedigreeGraphics {

 
  constructor(){
     }

    public static getText(baseX, baseY, text, nooflines, font:ReportFont, opacity, textConfig){

        if ( ReportUtil.isEmptyObject(textConfig)){        
      return null;
    }
    if (text == null) {
      return null;
    }
    // TODO textconfig -  rotation angle
    var textWords = font.getString(text, textConfig.fontSize, textConfig.length, nooflines, true);
    var posX = baseX + textConfig.offsetx;
    var posY = baseY + textConfig.offsety;
    var rotateAng = textConfig.rotate;

    var transform = `translate(${posX},${posY}) rotate(${rotateAng})`;
    return { "transform": transform, "style": textConfig.style, "text": textWords[0], "textRemaining": textWords.slice(1), "opacity": opacity };
  }

    public static getBoxImage(baseX, baseY, baseURL, opacity, boxConfig){
      if ( ReportUtil.isEmptyObject(boxConfig) ){
      return null;
    }
    var offsetx = boxConfig.offsetx ? boxConfig.offsetx : 0;
    var offsety = boxConfig.offsety ? boxConfig.offsety : 0;
    var w = boxConfig.w ? boxConfig.w : 0;
    var h = boxConfig.h ? boxConfig.h : 0;
    var size = null;
    if (w == 0 || h == 0) {
      return { "x": baseX + offsetx, "y": baseY + offsety, "url": baseURL + "/images/" + boxConfig.image, "opacity": opacity }
    }
    return { "x": baseX + offsetx, "y": baseY + offsety, "url": baseURL + "/images/" + boxConfig.image, "opacity": opacity, "w": w, "h": h }
  }

    public static getCircleMaskImage(baseX, baseY, url, gender, imageConfig,baseURL){     
      if ( ReportUtil.isEmptyObject(imageConfig) ){
      return null;
    }
    var imageURL = url;
    if (!imageURL || imageURL == null) {
      imageURL = baseURL + "/images/" + gender + ".png";
    }
    return { "center": { "x": baseX + imageConfig.offsetx, "y": baseY + imageConfig.offsety }, "r": imageConfig.r, "url": imageURL };
  }

    public static getConnector(level, parentPosition, mode, childIndex, opacity ){
    var modeAngles = [90, 0, -90, 180];
    var scaleIndex = childIndex == 0 ? -1 : 1;
    return { "level": level, "transform": "translate(" + parentPosition.x + " " + parentPosition.y + ") rotate(" + modeAngles[mode] + " 0 0) scale(1," + scaleIndex + ")", "opacity": opacity };
  }
}