<div class="treeeditor-container">
    <div class="mainComp">
        <div class="container-fluid height-adjust-95" [ngStyle]="{'background-color': expandColor}">
            <!-- tab - desktop view toggle button -->
            <img *ngIf="!switchToIpadView"
                [ngClass]="{ 'btn-toggle' : !isMobileDevice , 'btn-toggle-mobile' : isMobileDevice, 'element-fade' : isExpandingWheelOn }"
                class="element-over-hand" src="../../../../assets/tree-editor/btn_toggle/btn_toggle_tab.svg"
                (click)="toggleView()" />
            <img *ngIf="switchToIpadView"
                [ngClass]="{ 'btn-toggle' : !isMobileDevice , 'btn-toggle-mobile' : isMobileDevice, 'element-fade' : isExpandingWheelOn }"
                class="element-over-hand" src="../../../../assets/tree-editor/btn_toggle/btn_toggle_desktop.svg"
                (click)="toggleView()" />

            <div class="row">
                <div [hidden]="!showView" class="svg-container">
                    <!-- <svg viewBox="0 5 1700 880" preserveAspectRatio="xMidYMid meet"  xmlns="http://www.w3.org/2000/svg" version="1.1" id="svg-content"> -->
                    <svg viewBox="0 180 2150 560" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
                        version="1.1" id="svg-content">

                        <g
                            [attr.transform]="'translate('+(treeEditorXMove)+' ,'+(treeEditorYMove)+') scale('+(viewScale)+')'">
                            <rect class="rect" width="100vw" height="100vh" fill="#f4f9e7" />
                            <g *ngFor="let item of repeatTimes; let i = index">
                                <!-- iPad view Continuous buttons after level 3 -->
                                <g *ngIf="switchToIpadView">
                                    <g *ngIf="renderLabels[i+3].motherId > -1 && renderLabels[i+3].fatherId > -1">
                                        <image x="870" [attr.y]="100 + i*220" class="button-continuous"
                                            href="assets/tree-editor/level_6/btn_continue.svg" />
                                    </g>
                                </g>
                            </g>

                            <!-- ====6 =========== -->
                            <g *ngFor="let label of renderLabels.slice(31,63).reverse(); let i = index">
                                <symbol>
                                    <!---------------hides in the ipad view----------------->
                                    <g *ngIf="!switchToIpadView">
                                        <!-- Level 6 -->
                                        <g app-level6labels appEllipsis [i]="i" (openInputFormBoxWithData)="addParent($event)" [label]="label" [rootMember]="renderLabels[0]"></g>
                                    </g>
                                    <!------------------------------------------------------>
                                </symbol>
                                <use [attr.x]="label.x " [attr.y]="label.y"
                                    [attr.xlink:href]="'#' + label.displayIndex" />
                            </g>

                            <g *ngFor="let label of renderLabels.slice(15,31).reverse(); let i = index">
                                <symbol>
                                    <!---------------hides in the ipad view----------------->
                                    <g *ngIf="!switchToIpadView">

                                        <!-- Level 5 -->
                                        <g app-level5labels appEllipsis [i]="i" (openInputFormBoxWithData)="addParent($event)" [label]="label" [rootMember]="renderLabels[0]"></g>
                                    </g>
                                    <!------------------------------------------------------>
                                </symbol>
                                <use [attr.x]="label.x " [attr.y]="label.y"
                                    [attr.xlink:href]="'#' + label.displayIndex" />
                            </g>

                            <g *ngFor="let label of renderLabels.slice(7,15).reverse(); let i = index">
                                <symbol>
                                    <!---------------hides in the ipad view----------------->
                                    <g *ngIf="!switchToIpadView">
                                        <!-- Level 4 -->
                                        <g app-level4labels appEllipsis [i]="i" (openInputFormBoxWithData)="addParent($event)" [label]="label" [rootMember]="renderLabels[0]"></g>
                                    </g>
                                </symbol>
                                <use [attr.x]="label.x " [attr.y]="label.y"
                                    [attr.xlink:href]="'#' + label.displayIndex" />
                            </g>

                            <g *ngFor="let label of renderLabels.slice(0,7).reverse(); let i = index">
                                <symbol>
                                    <!-- Level 1,2,3 labels     -->
                                    <g app-mainlabels appEllipsis [i]="i" (childFunction)="expandWheel(label)"
                                        (openInputFormBoxWithData)="addParent($event)" (refreshParent)="refreshTree($event)" [editedLabelImage]="editedLabelImage" [label]="label" [rootMember]="renderLabels[0]"></g>                            
                                </symbol>            
                                <use [attr.x]="label.x " [attr.y]="label.y" [attr.xlink:href]="'#' + label.displayIndex" />
                            </g>

                            <!-- family icon -->
                            <image class="clickButton" x="428" y="425" height="35" width="35" href="assets/tree-editor/icon_family.svg"
                                (click)="showFamily(0)" />
                            <image class="clickButton" *ngIf="renderLabels[0] && renderLabels[0].fatherId > -1" x="642" y="210" height="35"
                                width="35" (click)="showFamily(1)"
                                href="assets/tree-editor/icon_family.svg" />
                            <image class="clickButton" *ngIf="renderLabels[0] && renderLabels[0].motherId> -1" x="642" y="638" height="35"
                                width="35" (click)="showFamily(2)"
                                href="assets/tree-editor/icon_family.svg" />

                            <!-- Expanding circle     -->
                            <g *ngFor="let label of renderLabels; let i = index">
                                <rect class="rectangle" width="100vw" height="100vh" transform="translate(0, 0)" x="0"
                                    y="0" class="black-background" *ngIf="label.expanded"
                                    (click)="expandWheel(label)" />
                                <symbol>
                                    <g class="element-over-hand" [attr.id]="label.id + 's'" *ngIf="label.expanded">
                                        <!-- <image (click)="expandWheel(label)" x="21" y="418" height="50" width="50" href="assets/tree-editor/lbl_exapnding_circle_top.svg" />                     -->
                                        <image x="15" y="340" height="208" width="208" href="assets/tree-editor/lbl_expanding.svg" (click)="expandWheel(label)"/>   
                                        <polygon transform="translate(111, 363)" (click)="addSibling('Sister', label)" fill-opacity="0.0" points="0,0 15,40 50,40 67,0 33,-8"/>
                                        <text (click)="addSibling('Sister', label)" transform="translate(118, 370)" fill="white" font-size="11">
                                            <tspan dy="1.3em" x="25" text-anchor="middle">{{"lbl_sister" | translate}}</tspan>
                                        </text>
                                        <polygon transform="translate(52, 361)" (click)="addSibling('Brother', label)" fill-opacity="0.0" points="56,1 26,24 10,44 52,61 73,42"/>
                                        <text (click)="addSibling('Brother', label)" transform="translate(73, 386)" fill="white" font-size="11">
                                            <tspan dy="1.3em" x="25" text-anchor="middle">{{"lbl_brother" | translate}}</tspan>
                                        </text>
                                        <polygon transform="translate(46, 405)" (click)="addSpouse(label)" fill-opacity="0.0" points="16,0 8,34 12,68 57,52 60,19"/>
                                        <text (click)="addSpouse(label)" transform="translate(55, 432)" fill="white" font-size="11">
                                            <tspan dy="1.3em" x="25" text-anchor="middle">{{"lbl_spouse" | translate}}</tspan>
                                        </text>
                                        <polygon transform="translate(57, 468)" (click)="addChild('Son', label)" fill-opacity="0.0" points="1,6 20,31 42,52 63,13 48,-10"/>
                                        <text (click)="addChild('Son', label)" transform="translate(69, 475)" fill="white" font-size="11">
                                            <tspan dy="1.3em" x="25" text-anchor="middle">{{"lbl_son" | translate}}</tspan>
                                        </text>
                                        <polygon transform="translate(87, 482)" (click)="addChild('Daughter', label)" fill-opacity="0.0" points="35,0 16,40 52,49 87,42 70,0"/>
                                        <text (click)="addChild('Daughter', label)" transform="translate(115, 495)" fill="white" font-size="11">
                                            <tspan dy="1.3em" x="25" text-anchor="middle">{{"lbl_daughter" | translate}}</tspan>                   
                                        </text>
                                    </g>
                                </symbol>
                                <use [attr.x]="label.expandingWheelX" [attr.y]="label.expandingWheelY"
                                    [attr.xlink:href]="'#' + label.id + 's'" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>