import { Component, OnInit } from '@angular/core';
import { V10ApiService } from '../../../services/v10-api.service';
import { SessionDataMapper } from '../../../common/mappers/SessionDataMapper';
import { TruthyCheck } from '../../../common/helpers/truthy-check';
import { EventsummaryComponent } from '../eventsummary/eventsummary.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Individual } from 'src/app/common/models/Individual';
import { ErrorData } from 'src/app/common/models/ErrorData';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';


@Component({
  selector: 'app-maintimelineview',
  templateUrl: './maintimelineview.component.html',
  styleUrls: ['./maintimelineview.component.scss']
})


export class MaintimelineviewComponent implements OnInit {
  public loading = false;
  private subscribeTimeLineSvgLoad;
  private subscribeInitialPageLoad;

  constructor(
    public v10restApi: V10ApiService,
    private truthyCheck: TruthyCheck,
    private modalService: NgbModal,
    private parentCommunicator: ParentCommunicatorService,
    private sessionDataMapper: SessionDataMapper,
    private loadingService: LoadingService
  ) {
    this.subscribeMethods();
  }

  subscribeMethods() {
    this.subscribeInitialPageLoad = this.parentCommunicator.receive(ParentMessageType.StartApplication, (pageloadData) => {
      let parentObj = this.sessionDataMapper.map(pageloadData);
      if (parentObj.rootMemberId > 0) {
        this.mainDomain = parentObj.mainDomain;
      }
    });
    this.subscribeTimeLineSvgLoad = this.parentCommunicator.receive(ParentMessageType.PublishReportClicked, () => {
      let timelineReport = document.getElementById('timeLineView');
      this.parentCommunicator.send(ParentMessageType.ReportContentCopied, timelineReport.innerHTML);
    });
  }

  timeGapProp: any;
  startYear: number = 2000; // Default year
  rulerTimeGaps: number[] = [];
  numberOfGenerations: number = 14;
  eventName: string[] = [];
  eventDate: any[] = [];
  eventPlace: string[] = [];
  eventsList = [];
  startX: any[] = [];
  parentEventData = [];
  decadeElementWidth: any = 136;
  rulerLevel: any = 27.507;
  centerStartYear: any;
  verticalPosition: number = 0;
  invisibleY: number = 10000;
  displayedEvents: any[] = [];
  yearGapLenght: number = 13.6;
  showTreeLeaves: boolean = false;


  // Label
  labelInfo: any[] = []; // Backend sorted label details
  yAxisLevels: any[] = []; // Y axis vertically plotting levels
  labelLineHeight: any[] = []; // Includes generated labels line heights according to the yAxis Levels
  labelMaxLength: number = 280; // Label maximum length that is not overlapping 
  imagePath: any = 'assets/images/no-images.jpg'; // Temporary added a default image path
  treeLeavesPath: any = 'assets/css/report/TimeLine/timeline-leaves.svg';
  mainDomain: string = '';
  labelInfoDisplay: any[] = [];
  expandingSingleLabels: any = [];
  expandingYearEvents: any[] = [];
  errData: ErrorData = new ErrorData();

  ngOnInit(): void {
    this.timeGapProp = {
      timeGapWidth: 136,
      timeGapPeriod: 10,
      DefaultTimeGapPeriod: 10, // should be %10==0
      timeGapHeight: 11.924,
      numOfTinyGaps: 10,    // number of small divisions in a time gap
      getTimeGapColor1: '#F4F9E4',
      getTimeGapColor2: '#EDF3DB'
    };
    this.parentCommunicator.receive(ParentMessageType.RootMemberChanged, rootMember => {
      if (rootMember)
        this.getTimelineIndividualEvents(rootMember.Id);
    });
    this.rulerElementsXPositions();
  }

  moveUp(): void {
    if (this.labelInfoDisplay.filter(s => s.y + this.verticalPosition < 0).length > 0) {
      this.verticalPosition = this.verticalPosition + 100;
    }
  }

  moveDown(): void {
    if (this.verticalPosition > 0) {
      this.verticalPosition = this.verticalPosition - 100;
    }
  }

  ngOnChange(): void {
    this.parentCommunicator.receive(ParentMessageType.RootMemberChanged, rootMember => {
      if (rootMember)
        this.getTimelineIndividualEvents(rootMember.Id);
    });
  }

  getTimelineIndividualEvents(rootMemberId) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.v10restApi.getTimelineEvents(rootMemberId)
      .subscribe(
        (data: Individual) => this.processEventsData(data)
      )
      .add(() => this.loadingService.hide(processId));
  }

  processEventsData(data: any) {
    let timeGaps = [];
    let parentObj = data;
    let domain = this.mainDomain;
    this.eventsList = [];
    if (this.truthyCheck.isTruthy(parentObj.Data && parentObj.Data.ShowingEvents)) {
      for (let i = 0; i < parentObj.Data.ShowingEvents.length; i++) {
        if (parentObj.Data.ShowingEvents[i].EventDate) {
          var year = new Date(parentObj.Data.ShowingEvents[i].EventDate);
          let event = parentObj.Data.ShowingEvents[i].EventDescription;
          let img = parentObj.Data.ShowingEvents[i].EventLabelImageUrl
          let eventChunks = event.match(/.{1,27}(\s|$)/g);
          let eventDate = parentObj.Data.ShowingEvents[i].EventDateString;
          let location = parentObj.Data.ShowingEvents[i].EventPlace;
          var obj = {
            EventName: this.titleCase(parentObj.Data.ShowingEvents[i].EventName),
            Year: year.getFullYear(),
            EventDateString: this.truthyCheck.isTruthy(eventDate) ? eventDate : year.getFullYear(),
            EventID: parentObj.Data.ShowingEvents[i].EventID,
            EventImageUrl: this.truthyCheck.isTruthy(img) ? domain + '/Client/' + img : this.imagePath,
            Event: event,
            Place: this.truthyCheck.isTruthy(location) ? location : "",
            // EventForLabel: event.length > 28 ? event.slice(0, 28) + "..." : event
            EventForLabel1: eventChunks[0],
            EventForLabel2: eventChunks[1],
            EventForLabel3: eventChunks[2] && eventChunks[2].length > 28 ? eventChunks[2].slice(0, 27) + '...' : eventChunks[2]
          };
          this.eventsList.push(obj);
        }
      }
    }
    timeGaps = (this.eventsList.filter(x => x.Year !== undefined && x.EventName !== 'PHOTO')).sort((a, b) => (a.Year - b.Year));
    this.generateYAxisLevels(timeGaps);
    // self.labelInfo = self.generateEvents(timeGaps);
    this.labelInfo = timeGaps;
    this.startYear = this.getRulerStartYear(timeGaps);
    this.generateRulerYears(timeGaps);
    this.generateLabelObject(timeGaps);
  }


  generateYAxisLevels(timeGaps) {
    let labelVerticalGap = 85;
    for (let i = 0; i < timeGaps.length + labelVerticalGap; i++) {
      this.yAxisLevels[i] = 580 - (labelVerticalGap * i);
      this.labelLineHeight[i] = 120 + (labelVerticalGap * i);
    }
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }

  generateRulerYears(timeGapsArray) {
    let centerStartYear;
    try {
      if (timeGapsArray.length !== 0) {
        this.centerStartYear = this.startYear; // Calculate the floor value of the timeline first event year
        this.setRulerValues();
      } else {
        this.centerStartYear = 2000;
        this.setRulerValues();
        throw console.error('TimeGaps data is empty');
      }
    } catch (error) {
      console.error('Ruler years generation is unsuccessful.')
    }
  }
  setRulerValues() {
    for (let i = 0; i < this.numberOfGenerations; i++) { // Create the ruler years
      this.rulerTimeGaps[i] = this.centerStartYear + 10;
      this.centerStartYear = this.centerStartYear + 10;
    }
  }

  // Label Component
  generateLabelObject(labelInfo) {
    try {
      if (labelInfo.length > 0) {
        labelInfo.map((obj, index) => (obj.x = this.horizontalMove(index)));
        for (let i = 0; i < labelInfo.length; i++) {
          let conflictingItems: Array<any> = labelInfo.filter(item => labelInfo[i].x < (item.x + this.labelMaxLength) && (item.x) <= labelInfo[i].x);
          let filteredYAxis = this.yAxisLevels;
          conflictingItems.forEach((element) => { filteredYAxis = filteredYAxis.filter(item => item !== element.y) }
          )
          labelInfo[i].y = filteredYAxis[0];
          //Generate label line heights
          let yAxisIndex = this.yAxisLevels.indexOf(filteredYAxis[0]);
          labelInfo[i].lineheight = this.labelLineHeight[yAxisIndex];
        }
        this.displayedEvents = labelInfo;
        this.labelInfoDisplay = labelInfo.reverse();
        // this.labelInfoDisplay.map((obj, i) => (obj.isSelected = "false"));
        // this.generateExpandingLabelYearHorizonalMove();
        this.showTreeLeaves = true;
      } else {
        throw console.error('Label info empty');
      }
    } catch (error) {
      console.error('Generate label object is failed');
    }
  }

  generateExpandingLabelYearHorizonalMove() {
    this.expandingSingleLabels.map((obj, index) => (obj.x = this.singlehorizontalMove(index)));
    this.expandingSingleLabels.map((obj, i) => (obj.y = this.invisibleY));
  }

  horizontalMove(index) { // Generated label horizontal plotting along x axis
    let startYear = this.startYear;
    return ((this.labelInfo[index].Year - startYear) * this.yearGapLenght);
  }

  singlehorizontalMove(index) { //Generated label horizontal plotting along x axis
    let startYear = this.startYear;
    return ((this.expandingSingleLabels[index].Year - startYear) * 13.6); //13.6 = Pixel gap between a year
  }

  rulerElementsXPositions() {
    for (let i = 0; i < this.numberOfGenerations; i++) {
      this.startX[i] = 136 * i;
    }
  }

  getRulerStartYear(labelInfo) {
    try {
      if (labelInfo.length > 0) {
        let startYear = Math.floor(labelInfo[0].Year / 10) * 10;
        let finalYear = Math.ceil(labelInfo[(labelInfo.length - 1)].Year / 10) * 10;
        let middleDecadeCount = (finalYear - startYear) / 10;
        if (middleDecadeCount > 11) {
          return startYear;
        } else {
          let edgeDecadeCount = Math.floor(this.numberOfGenerations - middleDecadeCount);
          return (Math.floor(labelInfo[0].Year / 10) * 10) - Math.floor(edgeDecadeCount / 2) * 10;
        }
      } else {
        throw console.error("Label center generation data is empty");
      }
    } catch (error) {
      console.error('Label center generation is failed: ' + error);
      throw error;
    }
  }

  seeEventDetails(content) {
    const modalRef = this.modalService.open(EventsummaryComponent);
    modalRef.componentInstance.event = content;
  }

  generateEvents(labelInfo) {
    // Get duplicate event IDs
    let duplicateEventIds = labelInfo
      .map(e => e['Year'])
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter(obj => labelInfo[obj])
      .map(e => labelInfo[e]["Year"])

    //Get duplicate events using duplicate event IDs 
    const duplicateEvents = labelInfo.filter(obj => duplicateEventIds.includes(obj.Year));
    duplicateEvents.map((obj, i) => (obj.expandId = i));
    this.expandingSingleLabels = JSON.parse(JSON.stringify(duplicateEvents));

    //Get grouped events using duplicate Events
    let groupedObjects = duplicateEvents
      .map(e => e['Year'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(obj => duplicateEvents[obj])
      .map(e => duplicateEvents[e]);

    let groupedEvents: any = [];
    for (let i = 0; i < groupedObjects.length; i++) {
      let arr: any = [];
      arr.Year = groupedObjects[i].Year;
      arr.isExpanded = "false";
      arr.EventID = groupedObjects[i].Year + "g";
      groupedEvents.push(arr);
    }

    //Get all label events as single events accroding to years
    let alllabelEvents: Array<any> = [...new Map(labelInfo.map(item => [item['Year'], item])).values()]

    // Add a groupId element to grouped element object to generate the gropued label svg
    groupedEvents.map((obj, i) => (obj.groupId = i));

    // Get only single label events
    let singleEvents = alllabelEvents.filter(({ Year: id1 }) => !groupedEvents.some(({ Year: id2 }) => id2 === id1));

    // Add a signleId element to single element object to generate the single label svg
    singleEvents.map((obj, i) => (obj.singleId = i));

    //Concatinate group and single labels both to an individual array
    var concat = groupedEvents.concat(singleEvents);

    // Return sorted concatinated array 
    return concat.sort((a, b) => a.Year < b.Year ? -1 : a.Year > b.Year ? 1 : 0);
  }

  updatePosition(event) {
    try {
      if (this.labelInfoDisplay.length > 0) {

        //Get clicked label info
        var id = event.target.id;
        var timeLineLabelId = document.getElementById(id);
        var selectedLabel = this.labelInfoDisplay.find(item => "#" + item.EventID == id);

        var isClicked = selectedLabel.isSelected;
        let clickedLabelYear = selectedLabel.Year;

        //Exapand grouped labels
        if (selectedLabel.hasOwnProperty("groupId") && isClicked == false) {
          this.generateExpandingLabels(clickedLabelYear, selectedLabel.EventID);

        }

        //Shrink grouped labels    
        if (selectedLabel.hasOwnProperty("groupId") && isClicked == true) {
          this.shrinkExpandedLabels(clickedLabelYear, selectedLabel.EventID);
        }
        else {

          // Implementation of label popup
        }
        isClicked = !isClicked;
        selectedLabel.isSelected = isClicked;
      }
      else {
        throw console.error('Grouped label event details empty');
      }
    }
    catch (error) {
      console.error('Grouped label exception error: ' + error);
      throw error;
    }
  }


  generateExpandingLabels(labelYear, groupHeaderId) {
    let labelsToBeExpanded = this.expandingSingleLabels.filter(item => item.Year == labelYear);
    let arr = this.displayedEvents;
    let yAxis;
    for (let i = 0; i < labelsToBeExpanded.length; i++) {
      let conflictingItems: Array<any> = arr.filter(item => (labelsToBeExpanded[i].x <= item.x) && ((labelsToBeExpanded[i].x + this.labelMaxLength) >= item.x) ||
        (((item.x + this.labelMaxLength) >= labelsToBeExpanded[i].x) && (labelsToBeExpanded[i].x >= item.x)));
      yAxis = this.yAxisLevels;
      conflictingItems.forEach((element) => { yAxis = yAxis.filter(item => item !== element.y && conflictingItems[0].y > item) });
      labelsToBeExpanded[i].y = yAxis[0];
      this.displayedEvents.push(labelsToBeExpanded[i]);
    }
    let groupHeader = this.displayedEvents.find(item => item.EventID == groupHeaderId);
    groupHeader.previousY = groupHeader.y;
    groupHeader.previousLineHeight = groupHeader.lineheight;
    groupHeader.y = yAxis[1];
    groupHeader.isClicked = true;
    let yAxisIndex = this.yAxisLevels.indexOf(yAxis[1]);
    groupHeader.lineheight = this.labelLineHeight[yAxisIndex];
  }

  shrinkExpandedLabels(labelYear, groupHeaderId) {
    let labelsExpanded = this.expandingSingleLabels.filter(item => item.Year == labelYear);
    for (let i = 0; i < labelsExpanded.length; i++) {
      labelsExpanded[i].y = this.invisibleY;
      this.displayedEvents.pop();
    }
    let groupHeader = this.displayedEvents.find(item => item.EventID == groupHeaderId);
    groupHeader.y = groupHeader.previousY;
    groupHeader.isClicked = false;
    groupHeader.lineheight = groupHeader.previousLineHeight;
  }

  ngOnDestroy() {
    this.parentCommunicator.close(ParentMessageType.RootMemberChanged, this.subscribeTimeLineSvgLoad);
    this.parentCommunicator.close(ParentMessageType.StartApplication, this.subscribeInitialPageLoad);
  }

}