<div class="ft-form" style="background-color: #f4f9e7;">
    <div class="content">
        <div class="ft-form-row" style="margin-bottom: 0px;">
            <div class="ft-form-row col1-v100" style="justify-content: space-between; margin-bottom: 0px;">                     
              <span class="title">
                {{"lbl_published_reports" | translate}}
                </span>
              <div class="ft-form-input">
                <div class="icon-input-container">
                  <input class ="icon-input" placeholder="{{'ph_search_reports' | translate}}"
                    type="text" [(ngModel)]="searchPhrase">
                  <div class="icon-input-wrap">                      
                    <img src="/assets/images/icons/search.svg"  width= "30px" height="30px">                       
                  </div>                  
                </div>
              </div>
            </div>
          </div>     
          <div class="list-gallery">
            <app-media-gallery-list
            [items]="mediaListItems" 
            [searchPhrase] = "searchPhrase"
            (action)="dropMenuAction($event)"
            ></app-media-gallery-list>  
          </div>         
    </div>
    <div class="footer">
        <button class = "ft-btn" [ngClass]="'ft-btn-active'"  (click)="showReportList()">{{"lbl_updatePage" | translate}}</button>
    </div>
</div>