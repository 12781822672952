import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart/chart.component';
import { ReportengineModule } from "../../reportengine/reportengine.module";


@NgModule({
    declarations: [ChartComponent],
    entryComponents: [ChartComponent],
    imports: [
        CommonModule,
        ReportengineModule
    ]
})
export class DescendantchartModule { }
