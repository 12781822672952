<div class="ft-form">
    <div class="about-us-container">
        <div class="about-us">
            <div class="logo"></div>
            <h4 class="title">{{"about_us.about_embla.lbl_app_title" | translate}}</h4>
            <h5>{{"lbl_version" | translate}} <span>{{appVersion}}</span></h5>
            <h5 class="copyright">{{"about_us.about_embla.lbl_copyright" | translate}}</h5>
            <span class="support">{{"about_us.about_embla.lbl_support" | translate}} : <a href="mailto:hei@embla.no">hei@embla.no</a></span>
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn ft-btn-active-not-selected" (click)="ok()">{{"lbl_ok" | translate}}</button>
    </div>
</div>