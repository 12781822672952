import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Renderer2,
  Inject,
  SimpleChanges,
} from '@angular/core';
import { catchError } from 'rxjs/operators';
import { PageState, PagePosition } from 'src/app/common/enums/enums';
import BookReport from 'src/app/common/models/BookReport';
import BookReportConfig, { BookReportPage } from 'src/app/common/models/BookReportConfig'
import { BookReportApiService } from 'src/app/services/API/book-report-api.service';
import { BookReportEngineService } from '../services/book-report-engine-service.service';

declare let book: any;

@Component({
  selector: 'app-flipbookcontainer',
  templateUrl: './flip-book-container.component.html',
  styleUrls: ['./flip-book-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FlipBookContainerComponent implements OnInit {

  @Input() bookReport: BookReport;
  @Input() bookReportConfig: BookReportConfig;
  @Output() onFlipBookUILoaded = new EventEmitter<boolean>();
  @Output() onPageFlipped = new EventEmitter<Number[]>();

  coverImagePath: string;
  scriptList: HTMLScriptElement[] = [];
  pages: PageState[] = [];
  bookReportPages :BookReportPage[] = [];
  basePath = '';

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2,
    private bookReportEngineService: BookReportEngineService,
    private bookReportApiService: BookReportApiService) {}

  ngOnInit(): void {
    this.loadScripts(() => { this.loadFlipbookUI() });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['bookReport'] && changes['bookReport']?.currentValue?.isCompleted) {
      this.fillBookReportPages();
      this.updateReport();

      //if there are no cover pages, load the first page from the bookReportPages
      if(this.bookReportPages.filter(page=>page.pagePosition == PagePosition.First || page.pagePosition == PagePosition.Last).length==0){
        this.loadPages(this,{page:0,next:1})
      }
    }
  }

  fillBookReportPages(){
    let totalPageCount =this.bookReport.pageCount + this.bookReportConfig.staticPages.length;
    this.bookReportPages = new Array<BookReportPage>(totalPageCount);

    //populate array with static pages from config
    for(let i = 0; i < this.bookReportConfig.staticPages.length; i++) {
      if(this.bookReportConfig.staticPages[i].pagePosition==PagePosition.First) {
        this.bookReportPages[this.bookReportConfig.staticPages[i].pageOffset] = this.bookReportConfig.staticPages[i];
      }

      else if(this.bookReportConfig.staticPages[i].pagePosition==PagePosition.Center) {
        let centerPageNumber = totalPageCount/2;
        this.bookReportPages[centerPageNumber+this.bookReportConfig.staticPages[i].pageOffset] = this.bookReportConfig.staticPages[i];
      }

      else if(this.bookReportConfig.staticPages[i].pagePosition==PagePosition.Last) {
        let lastPageNumber = totalPageCount - this.bookReportConfig.staticPages.filter(page => page.pagePosition == PagePosition.Last).length;
        this.bookReportPages[lastPageNumber + this.bookReportConfig.staticPages[i].pageOffset] = this.bookReportConfig.staticPages[i];
      }
    }

    //populate array with dynamic pages
    let j = 1;
    for(let i = 0; i < this.bookReportPages.length; i++) {
      if(this.bookReportPages[i] === undefined) {
        let newPage = new BookReportPage();
        newPage.path = j.toString();
        newPage.isDynamicPage = true;
        this.bookReportPages[i] = newPage;
        j++;
      }
    }
  }
  public loadFlipbookUI(): void {
    this.basePath = this.bookReportEngineService.getBasePath(this.bookReport);
    let imgPath = this.bookReportEngineService.getImagePath(this.bookReport, this.bookReportConfig);
    book.initConfigs(this.basePath, imgPath, this.bookReport.lang, this.bookReportConfig.getMinimumZoomLevel(), this.bookReportConfig.getMaximumZoomLevel(), this.bookReportConfig.getZoomStepLevel(),this.bookReportConfig.bookWidth,this.bookReportConfig.bookHeight);
    // remove dummy page which added to init the flipbook
    book.removePage(1);

    // load first and last pages when initializing to aviod the UI issues
    const fetchPromises = [];
    this.bookReportConfig.staticPages.forEach(page => {
      if(page.pagePosition == PagePosition.First || page.pagePosition == PagePosition.Last) {
        page.path = page.path.replace("{basePath}", this.basePath).replace("{lang}", this.bookReport.lang);
        fetchPromises.push(fetch(page.path).then(response => response.text()));
      }
    });
  
    Promise.all(fetchPromises).then(htmls => {
      this.bookReportConfig.staticPages.forEach((page, index) => {
        if(page.pagePosition == PagePosition.First || page.pagePosition == PagePosition.Last) {
          book.addPage(book.getPageCount()+1, htmls[index], page.isCoverPage);
          page.pageState = PageState.Content;
        }
      });
      
      this.onFlipBookUILoaded.emit(true);
      this.addEventListeners();
    });
  }

  private addEventListeners() {
    book.addEventListnerToTurned(this, this.onPageTurned);
    book.addEventListnerToStart(this, this.loadPages);
    book.addEventListenerToOnClick(this,this.onButtonCliked);
  }

  onButtonCliked(container,$event){
    let bookFunctionAttribute = $event.target.getAttribute("bookFunction");
    if(!bookFunctionAttribute) return;
    // Ex : "goToPage,1"
    let bookFunctionWithParams = bookFunctionAttribute.split(",");
    let bookFunction = bookFunctionWithParams[0] ??  "";
    
    if(bookFunction.length==0) return;
    
    let bookFunctionParams = bookFunctionWithParams.slice(1) ?? [];

    if(bookFunctionWithParams.length>0 && container[bookFunction]) {
      container[bookFunction](...bookFunctionParams);
    }
  }

  goToPage(pageNumber:number) {
    book.flipToPage(this.getMappedPageNumber(pageNumber));
  }

  getMappedPageNumber(pageNumber:number) {
    let mappedPageNumber = 0;
    mappedPageNumber = this.bookReportPages.findIndex(page=>page.path==pageNumber.toString()) + 1;
    if(mappedPageNumber==0) mappedPageNumber = 1;
    
    return mappedPageNumber;
  }
  //load pages when page turned
  public loadPages(container, pageObject) {
    let currentPage = pageObject.next
    //if turn to left/previous page
    if( pageObject.next < pageObject.page) currentPage = Math.max(1,currentPage-1);

    for (let i = currentPage; i <= pageObject.next + 2; i++) {
      if (i > container.bookReportPages.length) break;
      let page = book.getPage(i);
      let bookReportPage = container.bookReportPages[i - 1];
      if (bookReportPage.pageState == PageState.Loading) {
        if (bookReportPage && !bookReportPage.isDynamicPage) {
          fetch(
            bookReportPage.path
              .replace("{basePath}", container.basePath)
              .replace("{lang}", container.bookReport.lang)
          )
            .then((response) => response.text())
            .then((htmlPage) => {
              book.updatePage(page, htmlPage);
              container.bookReportPages[i - 1].pageState = PageState.Content;
            });
        } else {
          container.bookReportApiService
            .getPage(container.bookReport.id, bookReportPage.path)
            .subscribe((htmlPage) => {
              book.updatePage(page, htmlPage);
              container.bookReportPages[i - 1].pageState = PageState.Content;
            });
        }
      }
    }
  }

  private updateReport() {
    if (this.bookReport.isCompleted) {
      for(let i = 0; i<this.bookReportPages.length; i++) {
        if(this.bookReportPages[i].pageState!=PageState.Content){
          this.bookReportPages[i].pageState = PageState.Loading;
          this.addLoading(i+1);
        }
      }

      //if there are any static page and more than one dynamic page, add last page
      if(this.bookReportConfig.staticPages.length>0 && this.bookReport.pageCount>1){
        this.addLastPage();
      }
    }
  }

  private addLastPage(){
    let pageCount = this.bookReportPages.length
    if (pageCount % 2 != 0) {
      let lastPageIndex = pageCount - this.bookReportPages.filter(page => page.pagePosition == PagePosition.Last).length;
      let lastPage = new BookReportPage();
      lastPage.path = this.basePath + "static/" + "empty_page.html";
      lastPage.pageState = PageState.Content;
      this.bookReportPages.splice(lastPageIndex, 0, lastPage);
      fetch(lastPage.path).then(response => response.text()).then(html => {
        book.addPage(lastPageIndex+1, html, false);
      });
    }
  }

  private loadScripts(afterLoading: Function) {
    let scrList = ['/assets/turnJS/jquery.min.1.7.js', '/assets/turnJS/lib/turn.min.js', '/assets/turnJS/flipBook.js'];
    this.loadScript(this.renderer, scrList, afterLoading);
  }

  private loadScript(renderer: Renderer2, srcList: string[], afterLoading: Function) {
    if (srcList.length == 0) {
      afterLoading();
      return;
    }
    const script: HTMLScriptElement = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = srcList[0];
    renderer.appendChild(this.document.body, script);
    script.onload = () => {
      srcList.shift();
      this.loadScript(this.renderer, srcList, afterLoading);
    }
    this.scriptList.push(script);
  }

  ngOnDestroy(): void {
    this.scriptList.forEach(script => {
      this.renderer.removeChild(this.document.body, script);
    });
  }

  private addLoading(pageNumber: number) {
    book.addLoadingPage(pageNumber);
  }

  public flipForward(): void {
    book.flipForward();
  }

  public flipBackward(): void {
    book.flipBackward();
  }

  public flipFirstContentPage(): void {
    book.flipFirstContentPage();
  }

  public flipLastContentPage(): void {
    book.flipLastContentPage();
  }

  public getCurrentPage(): Number[] {
    // return int array, first index for left page and second for right page. 0 value for no pages 
    return book.getCurrentPage();
  }
  public getCurrentZoomLevel():Number{
		return book.getCurrentZoomLevel();
	}

  public getLastPage(): number { 
    return book.getLastPage(); //return last page, page number
  }

  public onPageTurned(container: FlipBookContainerComponent, view: Number[]): void {
    container.onPageFlipped.emit(view);
  }

  public zoomIn(): void {
    book.zoomIn();
  }

  public zoomOut(): void {
    book.zoomOut();
  }
}
