export class CitationQualityHelper {
    static citationQualities = [
        {
            id: 0,
            name: "lbl_unknown" // Need translation
        },
        {
            id: 1,
            name: "lbl_low_reliability" // Need translation
        },
        {
            id: 2,
            name: "lbl_secondary_source" // Need translation
        },
        {
            id: 3,
            name: "lbl_primary_source" // Need translation
        },
    ]
}