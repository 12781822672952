import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { BaseComponent } from "../../BaseReport/base/base.component";
import { LoadingService } from "src/app/services/UI/loading.service";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { NotifierV2Service } from "src/app/services/notifier-v2.service";
import { EditorMode, NotifierEvents} from "src/app/common/enums/enums";
import { DialogService } from "src/app/services/UI/dialog.service";
import { MessageDialogService } from "src/app/components/common/message-dialog/services/message-dialog.service";
import { ReportOptions } from "../../common/reportOptions/reportOptions";
import { ReportFieldOption } from "../../common/reportOptions/reportFieldOption";
import { Timeline } from "./timelinereport-timeline";
import { TimelineModel } from "src/app/common/models/report/timelinereport/timeline-model";
import { Observable } from "rxjs";
import { TimelineEventsByYearDataModel } from "src/app/common/models/report/timelinereport/timeline-events-by-year-data-model";
import { TimelineReportApiService } from "src/app/services/API/timeline-report-api.service";
import { StringLengthLimiter } from "src/app/common/helpers/string-length-limiter";
import { ResponseModel } from "src/app/common/models/responseModel";
import { EditorReferenceInfo } from "src/app/common/models/editors/editor-reference-info";

@Component({
  selector: "app-timelinereport",
  templateUrl: "./timelinereport.component.html",
  styleUrls: ["./timelinereport.component.scss"],
})
export class TimelineReportComponent extends BaseComponent implements OnInit {
  expectedUpdates = [NotifierEvents.RootMemberChanged];

  defaultHeight = 0; // compulsory to center the report (bug fix)

  gridLines = []; // x,y positions of grid
  gridShow = false; // enable , disable grid

  reportOptions; // report options
  reportURL; // report url

  timeline: TimelineModel; // timeline

  tooltip: HTMLElement; // tooltip component
  tooltipBox: HTMLElement; // tooltip box
  tooltipArrow: HTMLElement; // tooltip arrow
  hideTooltipTimeout: number | null = null; // tooltip hide timeout

  @ViewChild('mediaScroller') mediaScroller: ElementRef;  // media carousel element reference


  constructor(
    private loadingService              : LoadingService,
    protected timeLineReportHostElement : ElementRef,
    protected translateHandler          : TranslateHandler,
    private timelineReportApiService    : TimelineReportApiService,
    protected messageDialogService      : MessageDialogService,
    protected notifierService           : NotifierV2Service,
    protected dialogService             : DialogService,
    public stringLimit: StringLengthLimiter
  ) {
    super(timeLineReportHostElement, dialogService, notifierService,translateHandler,messageDialogService);
  }

  ngOnInit(): void {
    this.initReport();
    this.initTooltip();
  }

  notify() {
    this.initReport();
  }

  ngOnDestroy() {
    this.remove(this);
  }

  initReport() {
    this.reportURL = this.commonUrl;
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.reportTitle = this.config.options.title = this.translateHandler.translate("timeline_report.lbl_title",[this.rootMember.DisplayName]);
    this.initReportOptions();
    this.initTimelineReportData();
  }

  // initialize report options by creating reportOptions object with relaxant ReportFieldOption objects.
  initReportOptions() {
    this.reportOptions = new ReportOptions();
    this.reportOptions.reportName = "timeline_report.lbl_name";
    this.reportOptions.fieldOptions.push(
      new ReportFieldOption(
        "title",
        "title",
        "input",
        [],
        this.config.options.title
      )
    );
  }

  // Getting backend data
  initTimelineReportData(customTitle = null) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);

    this.timelineReportApiService
      .getIndividualAllEvents(Number(this.rootMember.Id))
      .subscribe((response: ResponseModel<TimelineEventsByYearDataModel[]>) => {
        if (response.data !== null || response.data !== undefined || response.data.length > 0) {
          this.reportTitle = customTitle == null ? this.translateHandler.translate("timeline_report.lbl_title",[this.rootMember.DisplayName]) : customTitle;
          this.initializeTimeline(response.data);
          this.reportZoomToExtend();
        }
      }, (error) => {
        if (error.status == 404) {
          return this.showError(
            "lbl_error_heading",
            "timelineReport.err_no_timeline_reports",
            "timelineReport.err_no_events_timeline_report_message"
          )         
          .subscribe(() => {
            // Return back to tree editor
            this.openTreeEditor();
          });
        } 
        else{
          return this.showError(
            "lbl_error_heading",
            "timelineReport.err_load_timeline_report",
            "timelineReport.err_generating_timeline_report_message"
          )
          .subscribe(() => {
            // Return back to tree editor
            this.openTreeEditor();
          });  
        }
      })
      .add(() => {
        this.loadingService.hide(processId);
      });

  }


  // create grid that help to Position the timeline
  // gridH is the height of svg, calculated by last position of timeline event
  configGrid(gridH: number) {
    let gapX = this.config.gridXAxisGap;
    let gapY = this.config.gridYAxisGap;
    let arr = [];
    // create horizonatal grid lines
    for (var y = 0; y <= gridH; y += gapY) {
      arr.push({ x1: 0, x2: this.config.gridW, y1: y, y2: y });
    }
    // create vertical grid lines
    for (var x = 0; x <= this.config.gridW; x += gapX) {
      arr.push({ x1: x, x2: x, y1: 0, y2: gridH });
    }
    this.gridLines = arr;
  }

  initializeTimeline(data: TimelineEventsByYearDataModel[]) {
    let timelineGraphics = Timeline.get(data, this.config, this.translateHandler);
 
    if (timelineGraphics !== null && timelineGraphics !== undefined) {

      // assign positions to each unit
      Timeline.assignUnitPositions(timelineGraphics.units, this.config.startXPosition, this.config.startYPosition,
        this.config.event.overlapPercentage, this.config.year.height, this.config.year.width, this.config.event.hConnector);

      // assign positions to vConnector elements
      Timeline.assignVConnectorPositions(timelineGraphics.vConnector, timelineGraphics.units, this.config.startXPosition,
        this.config.startYPosition, this.config.vConnector.width, this.config.vConnector.height, this.config.year.height,
        this.config.event.hConnector.height);

      this.timeline = timelineGraphics;

      // get last position of timeline to calculate grid height based on timeline height
      let calculateSvgHeight = Timeline.getLastPosition(this.timeline.units);
      this.configGrid(calculateSvgHeight);
      this.showReport = true;
    } else {
      this.showError(
        "lbl_error_heading",
        "timelineReport.err_load_timeline_report",
        "timelineReport.err_generating_timeline_report_message"
      ).subscribe(() => {
        // Return back to tree editor
        this.openTreeEditor();
      });
    }
  }

  breakText = function (text: string, limitLength: number,) {
    if (text == null || text == undefined) {
      return "";
    }
    return this.stringLimit.breakText(text, 0, limitLength);
  }
  showError(title: string, info: string, prompt: string): Observable<any> {
    return this.messageDialogService.openError(
      this.translateHandler.translate(title),
      this.translateHandler.translate(info),
      prompt == null ? null : this.translateHandler.translate(prompt)
    );
  }
    //show tooltip by setting positions and text content if text length is greater than maxCharacterLength
    showTooltip(
      text: string,
      maxCharacterLength: number,
      textType: 'description' | 'header',
      unitIndex: number,
      eventIndex: number,
      headerTextIndex?: number
    ) {
      if (text && text.length > maxCharacterLength) {
        const eventBoxIndex = headerTextIndex ? `${unitIndex}-${eventIndex}-${headerTextIndex}` : `${unitIndex}-${eventIndex}`;
        const textElement = (textType === "description")
          ? document.querySelector(`.description-${eventBoxIndex}`) as HTMLElement
          : document.querySelector(`.header-text-${eventBoxIndex}`) as HTMLElement;
    
        if (!textElement) return;

        textElement.addEventListener("wheel", (event) => {
          this.hideReportTooltip();
        });
    
        this.tooltipBox.textContent = text;
    
        const tooltipHeight = this.tooltip.offsetHeight;
        const viewportHeight = window.innerHeight;
    
        const tooltipTop = this.calculateTooltipTop(textElement, tooltipHeight);
        this.positionTooltip(textElement, this.tooltip, this.tooltipArrow, tooltipTop, viewportHeight);
        this.setupTooltipEventListeners(textElement, this.tooltip);
      }
    }
    // Initialize tooltip elements
    initTooltip() {
      this.tooltipBox = document.querySelector(".tooltip-box") as HTMLElement;
      this.tooltipArrow = document.querySelector(".arrow-left") as HTMLElement;
      this.tooltip = document.querySelector(".svg-tooltip") as HTMLElement;

      this.tooltipBox.addEventListener("wheel", (event) => {
        event.preventDefault();
        this.hideReportTooltip();
      });
    }

    // Calculate tooltip top position
    calculateTooltipTop(textElement: HTMLElement, tooltipHeight: number): number {
      const topPosition = textElement.getBoundingClientRect().top;
      const textHeight = textElement.getBoundingClientRect().height;
      const tooltipTop = topPosition + (textHeight / 2) - (tooltipHeight / 2);
      return tooltipTop;
    }

    // Position tooltip based on viewport height
    positionTooltip(
      textElement: HTMLElement,
      tooltip: HTMLElement,
      tooltipArrow: HTMLElement,
      tooltipTop: number,
      viewportHeight: number
    ) {
      const tooltipHeight = tooltip.offsetHeight;
      const tooltipTopOffset = this.config.tooltip.topOffset;

      tooltipArrow.style.top = this.config.tooltip.arrowTop;
      const currentArrowTopStyle = window.getComputedStyle(tooltipArrow).getPropertyValue('top');
      const currentArrowTopValue = parseFloat(currentArrowTopStyle);
    
      if (tooltipTop + tooltipHeight > viewportHeight) {
        const spaceFromTooltipToBottom = tooltipTop + tooltipHeight - viewportHeight;
        tooltipArrow.style.top = `${currentArrowTopValue + spaceFromTooltipToBottom}px`;
        tooltip.style.top = `${viewportHeight - tooltipHeight}px`;
      } else if (tooltipTop <  tooltipTopOffset) {
        tooltip.style.top = `${tooltipTopOffset}px`;
        tooltipArrow.style.top = `${Math.max(currentArrowTopValue - (tooltipTopOffset - tooltipTop), 10)}px`;
      } else {
        tooltip.style.top = `${tooltipTop}px`;
      }
      tooltip.style.left = `${textElement.getBoundingClientRect().right + this.config.tooltip.rightOffset}px`;
      tooltip.style.visibility = 'visible';
    }
    
    // Setup tooltip event listeners
    setupTooltipEventListeners(textElement: HTMLElement, tooltip: HTMLElement) {
      let mouseLeftDescription = false;
      let mouseInsideTooltip = false;
    
      const hideTooltip = () => {
        if (this.hideTooltipTimeout) {
          clearTimeout(this.hideTooltipTimeout);
          this.hideTooltipTimeout = null;
        }
        if (mouseLeftDescription && !mouseInsideTooltip) {
          this.hideReportTooltip();
        }
      };
    
      const debouncedHideTooltip = () => {
        this.cancelHideTooltip();
        this.hideTooltipTimeout = window.setTimeout(hideTooltip, 500);
      };
    
      textElement.addEventListener("mouseleave", () => {
        mouseLeftDescription = true;
        debouncedHideTooltip();
      });
    
      textElement.addEventListener("mouseenter", () => {
        mouseLeftDescription = false;
        this.cancelHideTooltip();
      });
    
      tooltip.addEventListener("mouseenter", () => {
        mouseInsideTooltip = true;
        this.cancelHideTooltip();
      });
    
      tooltip.addEventListener("mouseleave", () => {
        mouseInsideTooltip = false;
        debouncedHideTooltip();
      });

      const svgViewableArea = document.querySelector("#svgViewableArea") as HTMLElement;
      svgViewableArea.addEventListener("click", (event) => {
        this.hideReportTooltip();
      });
      this.cancelHideTooltip();
    }

    // Cancel tooltip hide timeout
    cancelHideTooltip () {
        clearTimeout(this.hideTooltipTimeout);
        this.hideTooltipTimeout = null;
    }

    // Hide tooltip
    hideReportTooltip() {
      this.tooltipBox.scrollTop = 0;
      this.tooltip.style.visibility = "hidden";      
    }

    //open tree editor
    openTreeEditor(){
      let ref:EditorReferenceInfo = {
        id : this.notifierService.getCurrentRootMemberId(),      
        isBreadcrumbUpdate: true
      }
      this.notifierService.openEditor(EditorMode.TreeEditor, ref);
    }
  
    // Scroll media carousel
    scrollMM(unitIndex: number, eventIndex: number, direction: 'left' | 'right') {
      const carouselIndex = `${unitIndex}-${eventIndex}`;
      const eventCarousel = document.querySelector(`.media-${carouselIndex}`) as HTMLElement;
      const singleMediaWidth = this.config.event.eventBox.media.singleMediaWidth;
      const singleMediaGap = this.config.event.eventBox.media.singleMediaGap;
      const totalWidth = singleMediaWidth + singleMediaGap;
      const scrollAmount = direction === 'left' ? -totalWidth : totalWidth;
      eventCarousel.scrollBy(scrollAmount, 0);
      this.updateArrowVisibility(eventCarousel, carouselIndex, singleMediaGap);
    }

    // Update arrow visibility based on carousel scroll position
    updateArrowVisibility(eventCarousel: HTMLElement, carouselIndex: string, singleMediaGap: number) {
      const leftArrow = document.getElementById(`carousel-left-arrow-${carouselIndex}`);
      const rightArrow = document.getElementById(`carousel-right-arrow-${carouselIndex}`);

      const isAtStart = eventCarousel.scrollLeft === 0;
      const isAtEnd = eventCarousel.scrollLeft + eventCarousel.clientWidth >= eventCarousel.scrollWidth - singleMediaGap;

      leftArrow.style.visibility = isAtStart ? 'hidden' : 'visible';
      rightArrow.style.visibility = isAtEnd ? 'hidden' : 'visible';
    }

    onOptionsValueChanged(changedData:ReportOptions){
      this.mapConfig(changedData);
      this.initTimelineReportData(this.config.options.title);
    }
}
