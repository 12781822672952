<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_options" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">            
        <div class="tabs">
            <span>{{popupTitle}}</span>               
        </div>
        <div class="tab-forms app-light-bg">                
            <div class="ft-form">
                <div class="content">                    
                    <div *ngFor="let option of data.fieldOptions" class="ft-form-row col1-v100">
                        <!-- input box -->
                        <div *ngIf="option.type === 'input'" class="ft-form-input">
                            <label>{{ "reportOptions.fieldOptions."+option.fieldName + ".fieldName" | translate}}</label>
                            <input type="text" [(ngModel)]="option.value">
                        </div> 
                        <!-- combo box -->
                        <div *ngIf="option.type === 'combobox'" class="ft-form-input">
                            <label>{{ "reportOptions.fieldOptions."+option.fieldName + ".fieldName" | translate}}</label>
                            <select class="form-control dropdown-list" [(ngModel)]="option.value" (change)='onChange($event, option.id)'>
                                <option *ngFor='let data of option.data' [value]="data">{{"reportOptions.fieldOptions."+option.fieldName + ".data." + data | translate}}</option>
                            </select>
                        </div>
                    </div> 
                </div>  
                <div class="footer">
                    <button type="button" class="ft-btn ft-btn-inactive active" (click)="save()">{{"lbl_save" | translate}}</button>
                    <button type="button" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
                </div>
            </div>  
        </div>            
    </div>
</div>
