import { Component, OnInit } from '@angular/core';
import { TooltipContentBaseComponent } from '../../../tooltip/tooltip-content-base/tooltip-content-base.component';

@Component({
  selector: 'app-event-detail-note',
  templateUrl: './event-detail-note.component.html',
  styleUrls: ['./event-detail-note.component.scss','../../events-main/events-main.component.scss']
})
export class EventDetailNoteComponent extends TooltipContentBaseComponent implements OnInit {

  public data: any;
  ngOnInit(): void {
  }

}
