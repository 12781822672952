import { Component, OnInit, OnChanges, SimpleChanges, Input, DoCheck, KeyValueDiffers, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { DropMenu } from '../../common/drop-menu/models/DropMenu';
import { AliveStatus, DropMenuAction, EditorMode, MultimediaTypes, NotifierEvents, ReferenceType } from 'src/app/common/enums/enums';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { IndividualComponentModel } from '../models/individual-component-model';
import { IndividualComponentViewModel } from '../models/individual-component-view-model';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { PreferencesService } from 'src/app/services/preferences.service';
import { FTDate } from 'src/app/common/models/FTDate';
import { PlaceBase } from 'src/app/common/models/place/place-base';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { KeyValueDiffer } from '@angular/core';
import { DropMenuItem } from '../../common/drop-menu/models/DropMenuItem';
import { ComponentBase } from '../../common/base/component-base';
import { MediaItem } from '../../common/media-carousel/models/media-item';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { Subject } from 'rxjs';
import { Validate } from 'src/app/common/helpers/validate';
import { GenealogyDate } from 'src/app/common/helpers/date/genealogy-date';

@Component({
  selector: 'app-individual-editor',
  templateUrl: './individual-editor.component.html',
  styleUrls: ['./individual-editor.component.scss']
})
export class IndividualEditorComponent extends ComponentBase implements OnInit, DoCheck, OnDestroy {
  @Input() data: any;
  @Output() changeData = new EventEmitter();
  @Output() setTitle = new EventEmitter();
  componentName = 'IndividualEditor';
  @Output() public showMain: EventEmitter<boolean> = new EventEmitter<boolean>();

  errorItems: any;
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";
  defaultImage: string = "assets/images/no-image.jpg";
  defaultCarouselImage: string = "";

  dropMenu: DropMenu;
  individualId: number;

  // Template based model and properties
  marriageAgeText: string;
  individualModel: IndividualComponentModel;
  individualViewModel: IndividualComponentViewModel;
  genealogyDate: GenealogyDate;
  dayRangeValid: boolean = true

  //Dirty check
  canChangeRequestSubscription: any;
  individualDiffer: KeyValueDiffer<string, any>;
  ngDocheckCount: number = 0;
  isDirty: boolean;

  inImages: Array<Multimedia> = [];
  mediaItems: MediaItem[];

  settings = [{ option: 1, prefix: false, givenName: true, middleName: false, patName: false, lastName: true, suffix: false, isLastFirst: false },
  { option: 2, prefix: true, givenName: true, middleName: true, patName: true, lastName: true, suffix: true, isLastFirst: false },
  { option: 3, prefix: true, givenName: true, middleName: true, patName: false, lastName: true, suffix: true, isLastFirst: false },
  { option: 4, prefix: true, givenName: true, middleName: false, patName: true, lastName: true, suffix: true, isLastFirst: false },
  { option: 5, prefix: true, givenName: true, middleName: false, patName: false, lastName: true, suffix: true, isLastFirst: false },
  { option: 6, prefix: false, givenName: true, middleName: true, patName: true, lastName: true, suffix: false, isLastFirst: false },
  { option: 7, prefix: false, givenName: true, middleName: true, patName: false, lastName: true, suffix: false, isLastFirst: false },
  { option: 8, prefix: false, givenName: true, middleName: false, patName: true, lastName: true, suffix: false, isLastFirst: false }];

  nameDisplaySetting: any;
  defaultView: boolean = false;
  isFieldDisabled: boolean;
  isMediaCarouselOpen: boolean = true;
  disableForm: boolean =  false;

  isScrolled: Subject<boolean> = new Subject<boolean>();

  constructor(private translateHandler: TranslateHandler,
    private individualApiService: IndividualApiService,
    private dialogService: DialogService,
    private preferencesService: PreferencesService,
    private parentCommunicatorService: ParentCommunicatorService,
    private loadingService: LoadingService,
    private messageDialogService: MessageDialogService,
    private differs: KeyValueDiffers,
    notifierService: NotifierV2Service,
    private validate: Validate
  ) {
    super(notifierService);
    this.individualViewModel = new IndividualComponentViewModel();
    this.nameDisplaySetting = new Object() as any;
    this.validate = new Validate();
  }

  ngOnInit(): void {
    this.defaultView = false;
    this.mediaItems = new Array<MediaItem>();
    this.setNameSettingPreference();
    this.getIndividualInfo();
    this.data.rootMemberId = this.data.id //2023/01/04 - To detect the breadcrumb change
    this.data.editorMode = EditorMode.IndividualEditor
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes['data'];
    if (change) {
      if(change.previousValue?.rootMemberId !== change.currentValue?.rootMemberId){
        this.data.id = this.notifierService.getCurrentRootMemberId()
      }
      this.getIndividualInfo();
    }
  }

  ngDoCheck(): void {
    this.disableForm = this.data.isEventDirty;
    if (this.individualDiffer) {
      let changes = this.individualDiffer.diff(this.individualModel);
      if (changes) {
        if (this.ngDocheckCount > 0) {
          this.isDirty = true;
          this.data.isEditorDirty = true;
        }
        this.ngDocheckCount++
      }
    }
  }

  setNameSettingPreference() {
    let preference = this.preferencesService.get();    
    if (preference) {
      
      this.nameDisplaySetting = this.settings.find(t => 
              t.prefix      == preference.includePrefixSuffix
           && t.middleName  == preference.includeMiddleName 
           && t.patName     == preference.includePatronomic 
           && t.suffix      == preference.includePrefixSuffix);

        //Defaults to option 1 if settings not available... should not happen
        if( !this.nameDisplaySetting ) this.nameDisplaySetting = this.settings[0];
        this.nameDisplaySetting.isLastFirst = (preference.nameOrder === "lastFirst" || preference.nameOrder === "lastCommaFirst") ? true : false;
    }
  }

  //Get individual informations
  getIndividualInfo(loadCurrentFamily: boolean = false) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    if (!loadCurrentFamily)
      this.individualId = this.data.id;
    this.individualApiService.getIndividualById(this.individualId).subscribe((response: ResponseModel<IndividualComponentModel>) => {
      this.individualModel = { ...new IndividualComponentModel(), ...response.data };
      this.defaultView = false;
      this.isDirty = false;
      this.data.isEditorDirty = false;
      this.mapUI(this.individualModel);
      this.setTitle.emit(this.individualModel?.givenName);
      this.setAliveStatusBasedOnDeathDate();
    },
      (error) => {
        this.defaultView = true;
        this.individualModel = { ...new IndividualComponentModel(), ...new ResponseModel<IndividualComponentModel>() };
        this.isDirty = false;
        this.data.isEditorDirty = false;
        this.mapUI(this.individualModel);
      }
    ).add(()=>{
      this.loadingService.hide(processId)
    });
  }

  // mapping datamodel to view model
  mapUI(individualModel: IndividualComponentModel) {
    this.individualViewModel = individualModel as IndividualComponentViewModel;
    this.defaultCarouselImage = this.individualViewModel.gender == "M" ? "assets/images/Male.png" : this.individualViewModel.gender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg";

    let isPrimaryImage = this.individualViewModel.multimedia?.find(t => t.isPrimary && !t.isNonProfile);
    if (!isPrimaryImage && this.individualViewModel.multimedia.length > 0) {
      !this.individualViewModel.multimedia[0].isNonProfile ? this.individualViewModel.multimedia[0].isPrimary = true : null
    }

    this.individualViewModel.multimedia?.forEach(el => {
      if (el.mediaType != MultimediaTypes.PHOTO && !el.isNonProfile) {
        el.isNonProfile = true;
      }
      if (el.mediaType == MultimediaTypes.PHOTO) {
        el.mediaUrl = (el.mediaUrl != null) ? el.mediaUrl + '&v=' + performance.now() : el.mediaUrl;
      }
    })

    this.setInMedia();

    //Set alive status for front radio button and to maintain existing data
    if (this.individualViewModel.isAlive == true) {
      this.individualViewModel.aliveStatus = AliveStatus.Alive;
      this.isFieldDisabled = true;
    }
    else if (this.individualViewModel.isAlive == false && this.individualViewModel.aliveStatus == AliveStatus.Unknown) {
      this.individualViewModel.aliveStatus = AliveStatus.Unknown;
      this.isFieldDisabled = true;
    }
    else {
      this.individualViewModel.aliveStatus = AliveStatus.Dead;
      this.isFieldDisabled = false;
    }

    if (individualModel?.id) {
      this.ngDocheckCount = 0;
      this.individualDiffer = this.differs.find(this.individualViewModel).create();
    }
  }


  public canChange(): boolean {
    return !this.isDirty;
  }

  setGender(newGender: string) {
    this.individualViewModel.gender = newGender;
    this.setProfileImageAsGender(newGender);
  }

  setProfileImageAsGender(newGender: string){
    var isProfileImage = this.individualViewModel?.multimedia?.filter(t => t.mediaType == MultimediaTypes.PHOTO && t.isNonProfile == false).length > 0;

    if(!isProfileImage){
      this.defaultCarouselImage = newGender == "M" ? "assets/images/Male.png" : newGender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg";
    }  
  }

  // Setting alive status 
  setAliveStatus(status: number) {
    if (status == AliveStatus.Unknown) {
      this.individualViewModel.isAlive = false;
      this.individualViewModel.aliveStatus = AliveStatus.Unknown;

      this.individualViewModel.deathEvent.date = null;
      this.individualViewModel.deathEvent.place = new PlaceBase();

      // Disable death date and place inputs
      this.isFieldDisabled = true;
    }
    if (status == AliveStatus.Alive) {
      this.individualViewModel.isAlive = true;
      this.individualViewModel.aliveStatus = AliveStatus.Alive;

      // Clear date and input values
      this.individualViewModel.deathEvent.date = null;
      this.individualViewModel.deathEvent.place = new PlaceBase();

      // Disable death date and place inputs
      this.isFieldDisabled = true;
    }
    if (status == AliveStatus.Dead) {
      this.individualViewModel.isAlive = false;
      this.individualViewModel.aliveStatus = AliveStatus.Dead;

      // Enable death date and place inputs
      this.isFieldDisabled = false;
    }
  }

  carouselActionClicked($event) {
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if (action == DropMenuAction.Upload) {
      // Set view mode as 1 (Gallery View)
      this.addImage(0, 2);
    }
    if (action == DropMenuAction.Gallery) {
      // Set view mode as 2 (Edit View)
      this.addImage(0, 1);
    }
    if (action == DropMenuAction.Edit) {
      // Set view mode as 2 (Edit View)
      this.addImage(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          this.unlinkImage(selectedMultimedia);
        }
      });
    }
    if (action == DropMenuAction.SetAsPrimary) {
      this.setImageAsPrimary(selectedMultimedia);
    }
  }

  setImageAsPrimary(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      if (!selectedMultimedia.isPrimary) {
        this.individualViewModel.multimedia.map(m => m.isPrimary = m.id === selectedMultimedia.id);
        this.individualViewModel.multimedia = [...this.individualViewModel.multimedia];
      }
    }
  }

  unlinkImage(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      let index = this.individualViewModel.multimedia.findIndex(i => i.id === selectedMultimedia.id);
      this.individualViewModel.multimedia.splice(index, 1);
      this.individualViewModel.multimedia = [...this.individualViewModel.multimedia];

      this.setNextImageAsPrimary(index);
    }
  }

  //Adding Image
  addImage(mediaId: any, viewMode: number) {
    this.dialogService.setHalf().open("Multimedia", {
      // Set id to 0 if StandAlone
      id: this.individualViewModel.multimedia.find(t => t.isPrimary)?.id ?? 0,
      reference: {
        id: this.individualViewModel.id, // Individual Id
        type: ReferenceType.Individual // Individual type
      },
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_pictures").toLowerCase(),
      this.individualViewModel.givenName]) ?? "",
      mediaType: [MultimediaTypes.PHOTO],
      viewMode: viewMode, // Gallery or editor view
      editMediaId: mediaId, // MediaId which supposes to be edited
      isStandAlone: false
    }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.individualViewModel.multimedia.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.individualViewModel.multimedia.splice(index, 1);
              this.setNextImageAsPrimary(index);
            }
          });

          if (response.data.newMedia) {
            response.data.newMedia.isNonProfile = false;
            response.data.newMedia.isNewlyAdded = true;
            response.data.newMedia.is
            let mediaIndex = this.individualViewModel.multimedia.findIndex(t => t.id == response.data.newMedia.id);
            if (mediaIndex < 0)
              this.individualViewModel.multimedia.push(response.data.newMedia);
            else {
              this.individualViewModel.multimedia[mediaIndex] = response.data.newMedia;
            }
            // When adding new image to image carsoul , set this as primary
            this.individualViewModel.multimedia.map(m => m.isPrimary = m.id === response.data.newMedia.id);
            this.individualViewModel.multimedia = [... this.individualViewModel.multimedia];
          }
          this.setInMedia();
        }
      })
  }

  // To get the place pop title to place input component
  getPlacePopupTitle(eventName: string) {
    return this.translateHandler.translate("lbl_link_place", [this.translateHandler.translate(eventName).toLowerCase(),
    this.individualViewModel?.givenName]) ?? ""
  }

  getEventPopupTitle() {
    return this.individualViewModel?.givenName;
  }

  setFormatedBirthDate($event) {
    this.individualViewModel.birthEvent.date ? this.individualViewModel.birthEvent.date : this.individualViewModel.birthEvent.date = new FTDate();
    this.validate.isGenealogyDateSet(this.individualViewModel.birthEvent.date, $event);

    this.validateBirthAndDeathDates(this.individualViewModel.birthEvent.date, this.individualViewModel.deathEvent.date);
  }

  setFormatedDeathDate($event) {
    this.individualViewModel.deathEvent.date ? this.individualViewModel.deathEvent.date : this.individualViewModel.deathEvent.date = new FTDate();
    this.validate.isGenealogyDateSet(this.individualViewModel.deathEvent.date, $event);

    this.validateBirthAndDeathDates(this.individualViewModel.birthEvent.date, this.individualViewModel.deathEvent.date);
  }

  validateBirthAndDeathDates(birthDate: FTDate, deathDate: FTDate) {
    const getDateToCompare = (date: FTDate) => {
      return date.genealogyDate ?? date.genealogyDateRange?.to ?? null;
    };

    this.dayRangeValid = GenealogyDate.isDateLaterOrUncomparable(getDateToCompare(birthDate), getDateToCompare(deathDate));

  }
  

  setAliveStatusBasedOnDeathDate() {
    if (typeof this.individualViewModel.deathEvent.date?.genealogyDate !== "undefined") {
      if (this.individualViewModel.deathEvent.date.genealogyDate.originalStr.length > 0 || this.individualViewModel.deathEvent.date.genealogyDateRange.originalStr.length > 0) {
        this.setAliveStatus(2);
      }
    } 
  }

  setPlaceForEvent($event) {
    this.individualModel.birthEvent = { ...this.individualViewModel.birthEvent };
    this.individualModel.deathEvent = { ...this.individualViewModel.deathEvent };
  }

  showNotImplemented() {
    this.parentCommunicatorService.send(
      ParentMessageType.ShowInformation,
      "not_implemented"
    );
  }

  updateIndividual() {
    let individualObj = { ...this.individualViewModel }
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);

    let individualName = individualObj.givenName + " " + individualObj.surname;
    individualName = individualName.trim();

    this.individualApiService.checkMemberExist(individualName, this.individualId).subscribe((response: any) => {
      this.loadingService.hide(processId);
      if (response.data) {
        let existingNameMessage = this.translateHandler.translate("val_member_exist");
        this.messageDialogService.openExistingNameConfirmation(existingNameMessage).subscribe((res: boolean) => {
          if (res) {
            this.updateIndividualAPI(individualObj);
          }
        });
      } else {
        this.updateIndividualAPI(individualObj);
      }
    }, (err) => {
      this.loadingService.hide(processId);
      throw new CustomError("IndividualEditorComponent => updateIndividual() : " + err, 911, false);
    });

  }

  updateIndividualAPI(individualObj: any) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.individualApiService.updateIndividual(individualObj).subscribe((response: any) => {
      this.loadingService.hide(processId);
      this.isDirty = false;
      this.data.isEditorDirty = false;
      this.data.id = individualObj.id;
      this.data.isSameId = true;
      this.changeData.next(this.data);
      this.notifierService.updateEvent(NotifierEvents.RootMemberUpdated, response.data)
    }, (err) => {
      this.loadingService.hide(processId);
      throw new CustomError("IndividualEditorComponent => updateIndividual() : " + err, 911, false);
    });
  }

  toggleMediaCarousel() {
    this.isMediaCarouselOpen = !this.isMediaCarouselOpen;
  }

  // Todo: Keep for future reference, currently not in use
  getInMedia() {
    return this.individualViewModel?.multimedia?.filter(t => t.isNonProfile == true).
      map(item => {
        item.mediaUrl = (item.mediaType != MultimediaTypes.PHOTO) ? "assets/nonimage-preview/Large-Icons/media_type_" + item.mediaType + ".svg" : item.mediaUrl
        return item
      }) ?? [];
  }

  setInMedia() {
    this.mediaItems = this.individualViewModel?.multimedia?.filter(t => t.isNonProfile == true).
      map(item => {
        item.mediaUrl = (item.mediaType != MultimediaTypes.PHOTO) ? "assets/nonimage-preview/Large-Icons/media_type_" + item.mediaType + ".svg" : item.mediaUrl
        let mediaItem = new MediaItem(item.id, (item.caption ? item.caption : item.fileName), item.mediaUrl, this.createDropMenu(item));
        return mediaItem
      }) ?? [];
  }

  createDropMenu(ref: Multimedia) {
    let dropMenu = new DropMenu();
    dropMenu.ref = ref;
    dropMenu.menuItems = [
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: !this.disableForm, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: !this.disableForm, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Download, isEnabled: !this.disableForm, isVisible: true }
    ];
    return dropMenu;
  }

  getInImages() {
    return this.individualViewModel?.multimedia?.filter(t => t.mediaType == MultimediaTypes.PHOTO && t.isNonProfile == false) ?? [];
  }

  mediaCarouselActionClicked($event) {
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if (action == DropMenuAction.Edit) {
      // Set view mode as 2 (Edit View)
      this.addMedia(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          this.unlinkMedia(selectedMultimedia);
        }
      });
    }
  }

  unlinkMedia(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      let index = this.individualViewModel.multimedia.findIndex(i => i.id === selectedMultimedia.id);
      this.individualViewModel.multimedia.splice(index, 1);
      this.individualViewModel.multimedia = [...this.individualViewModel.multimedia];
      this.setInMedia();
    }
  }

  //Adding all media
  addMedia(mediaId: any, viewMode: number) {
    this.dialogService.setHalf().open("Multimedia",
      {
        id: 0,
        reference: {
          id: this.individualViewModel.id, // individual ID
          type: ReferenceType.Individual // individual type
        },
        title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_media").toLowerCase(),
        this.individualViewModel.givenName]) ?? "",
        mediaType: [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO],
        viewMode: viewMode,
        editMediaId: mediaId, // MediaId which supposes to be edited
        isStandAlone: false
      }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.individualViewModel.multimedia.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.individualViewModel.multimedia.splice(index, 1);
            }
          });

          if (response.data.newMedia) {
            let newMedia = response.data.newMedia;
            newMedia.isNonProfile = true;
            newMedia.isNewlyAdded = true;
            newMedia.mediaUrl = this.getImageMediaUrl(newMedia);
            let mediaIndex = this.individualViewModel.multimedia.findIndex(t => t.id == newMedia.id);
            if (mediaIndex < 0)
              this.individualViewModel.multimedia.push(newMedia);
            else {
              this.individualViewModel.multimedia[mediaIndex] = newMedia;
            }
            this.individualViewModel.multimedia = [... this.individualViewModel.multimedia];
          }
          this.setInMedia();
        }
      })
  }

  getImageMediaUrl(media: Multimedia) {
    let timeStamp = performance.now();
    let url = (media.mediaUrl != null) ? media.mediaUrl + '&v=' + timeStamp : media.mediaUrl;
    return media.mediaType === MultimediaTypes.PHOTO ? url ?? "/assets/images/Male.png" : "assets/nonimage-preview/Large-Icons/media_type_" + media.mediaType + ".svg"
  }

  //Set next image when primary image unlinked
  setNextImageAsPrimary(imageIndex: number) {
    let isPrimaryImage = this.individualViewModel.multimedia?.find(t => t.isPrimary == true) ?? null;
    if (!isPrimaryImage && this.individualViewModel.multimedia.length > 0) {
      if (this.individualViewModel.multimedia[imageIndex > 0 ? (imageIndex - 1) : 0].isNonProfile) {
        this.individualViewModel.multimedia.find(t => t.mediaType == MultimediaTypes.PHOTO && !t.isNonProfile).isPrimary = true;
      } else {
        this.individualViewModel.multimedia[imageIndex > 0 ? (imageIndex - 1) : 0].isPrimary = true;
      }
    }
  }

  cancel() {
    this.getIndividualInfo(true);
  }
  getMultimediaDropMenu() {
    return [
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Download, isEnabled: true, isVisible: true }
    ];
  }

  delete() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.individualApiService.getFirstPerson().subscribe((responseMember: any) => {
      if (this.individualId == Number(responseMember.data.id)) {
        this.loadingService.hide(processId);
        this.messageDialogService.openError(this.translateHandler.translate("val_warning"), this.translateHandler.translate("val_member_first_person_delete"), null).
          subscribe((res: boolean) => { })
      } else {
        this.individualApiService.getReferenceCount(this.individualId).subscribe((responseCount: ResponseModel<number>) => {
          this.loadingService.hide(processId);
          var message = this.translateHandler.translate("err_delete_individual_confirmation_message") + " " + this.translateHandler.translate("lnk_references", [responseCount.data.toString()])
          this.messageDialogService.openDeleteConfirmation(message).subscribe((res: boolean) => {
            if (res) {
              let nextProcessId = GuidGenerator.generate();
              this.loadingService.show(nextProcessId);
              this.individualApiService.deleteIndividual(this.individualId).subscribe(async (responseDelete: any) => {
                if (responseDelete.data) {
                  this.isDirty = false;
                  this.data.isEditorDirty = false;
                  let result = await this.notifierService.updateEvent(NotifierEvents.RootMemberDeleted, Number(this.individualId));
                  this.notifierService.setCurrentEditor(result,{id:this.notifierService.getCurrentRootMemberId()},null)
                }
              }, (err) => {
                throw new CustomError("IndividualEditorComponent => delete() => deleteIndividualAPI() : " + err, 911, false);
              }).add(() => {
                this.loadingService.hide(nextProcessId);
              })
            }
          }).add(() => {
            this.loadingService.hide(processId);
          })
        }, (err) => {
          throw new CustomError("IndividualEditorComponent => delete() => getReferenceCount() : " + err, 911, false);
        }).add(() => {
          this.loadingService.hide(processId);
        });
      }
    })
  }


  onContainerScroll($event : any) {
    this.isScrolled.next(true);
  }
}
