<div class = "place-input">
    <input style="flex: auto;" type="text" [formControl]="typeahead" (change)='onModelChange($event)'
    placeholder="{{'ph_place' | translate}}, {{'ph_farm' | translate}}, {{'ph_parish_DIS' | translate}}, {{'ph_municipality' | translate}}, {{'ph_county' | translate}}, {{'ph_country' | translate}}"
    (input)="suggestPlaces()" value="{{getPlace()}}" (click)="toggle($event)">               
    <img src="/assets/images/icons/add.svg" class="btn-addPlace" [class.btn-inactive] = "isPlaceBtnEnabled" style="cursor: pointer;" (click)="addPlace()">

  <div class = "drop-down" *ngIf="suggestions.length > 0 && isVisible" (click)="toggle($event)">
    <div *ngFor="let s of suggestions" (click)="actionCLick($event)">
      <div (click)="selectPlace(s.id)" class = "drop-down-item">
        <div *ngIf = "s.rawData">{{s.rawData}}</div>
      </div>
    </div>
  </div>
</div>