import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MessagePromptComponent } from '../message-prompt/message-prompt.component';
import { MessageDialogService } from '../services/message-dialog.service';

@Component({
  template: '<div></div>'  
})
export class MessageDialogBaseComponent implements OnInit {

  @Input() request: any;
  
  constructor(private messageDialogService:MessageDialogService, private ref:ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  public complete(response:any){
    this.messageDialogService.complete(response);
  }

  public close(){
    this.messageDialogService.close();
  }
  
  ngOnDestroy() {
    this.ref.detach();
  }

  isShowOK(){
    
  }

  isShowCancel(){
   
  }

  

  getCancelText(){

  }
}
