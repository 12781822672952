import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintreeeditorviewComponent } from './maintreeeditorview/maintreeeditorview.component';
import { TreeeditorviewComponent } from './treeeditorview/treeeditorview.component';
import { ParentNodeService } from '../treeeditor/services/parent-node.service';
import { TreeEditorApiService } from './services/treeeditorapi.service';
import { MainlabelsComponent } from './mainlabels/mainlabels.component';
import { Level4labelsComponent } from './level4labels/level4labels.component';
import { Level5labelsComponent } from './level5labels/level5labels.component';
import { Level6labelsComponent } from './level6labels/level6labels.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { EllipsisDirective } from 'src/app/common/directives/ellipsis.directive';


@NgModule({
  declarations: [MaintreeeditorviewComponent,
    TreeeditorviewComponent,
    MainlabelsComponent,
    Level4labelsComponent,
    Level5labelsComponent,
    Level6labelsComponent,
    EllipsisDirective],
    exports: [MaintreeeditorviewComponent],
  imports: [
    SharedModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [ParentNodeService, TreeEditorApiService],
})
export class TreeeditorModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}