<p>events-temp works!</p>
<div [ngClass]="isLeftPanelHide ? 'maincomp-expand' : 'maincomp'"  >
    <div class="container-fluid">
        <div class="row ">
            <div class="col-6"  >
                <div class="temp-event-border">
                    Member Editor/ Family Editor Area
                </div>
            </div>
            <div class="col-6"  >
                <!-- <div style="background-color: yellowgreen !important;">
                    Event Area
                </div> -->

                <!-- <Temp event border> -->
                <div id="event-wrap">
                    <!--<app-events-main></app-events-main>-->
                </div>
            </div>
        </div>
</div>
</div>