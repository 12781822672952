import { ReportTemplate } from "../../enums/enums";

export class ReportHelper {
    static templates = [
        {
            id: 1,
            name: "reportTemplateOptions.lbl_content_only",
            reportTemplate: ReportTemplate.ContentOnly
        },
        {
            id: 2,
            name: "reportTemplateOptions.lbl_with_header",
            reportTemplate: ReportTemplate.WithHeader
        }
    ]
}