import { SunChartSectorData, SunChartFunSector } from './sun-chart-sector-data';
import { SunChartGraphic } from './sun-chart-graphic';
import { ReportFont } from '../../common/reportfont';

/**
 * Sectors of sun chart contains the following components / some have different combinations 
 *  - Curve text1
 *  - Curve text2
 *  - Radial text1
 *  - Radial text2
 *  - Radial text3
 *  - Image
 *  - more shapes and images to come
 * 
 *  Dependant SunChartFont, SunChartSectorData
 */
export class SunChartSector {

    private levelId: number;
    private levelCount: number;
    private sectorId: number;
    private nodeType: boolean;
    private radius: number;
    private angle: number;
    private axisAdjustment: number;
    private configs: any;
    private reportfont: any;
    private data: SunChartSectorData;



    constructor(levelId: number, levelCount: number, sectorId: number,
        radius: number, angle: number, axisAdjustment: number,
        configs, data: SunChartSectorData, font: ReportFont, nodeType) {
        this.levelId = levelId;
        this.levelCount = levelCount;
        this.sectorId = sectorId;
        this.radius = radius;
        this.angle = angle;
        this.data = data
        this.axisAdjustment = axisAdjustment;
        this.configs = configs;
        this.reportfont = font,
            this.nodeType = nodeType
    }

    // Return a list of sectors
    generate() {
        var ctext1 = null;
        var ctext2 = null;
        var rtext1 = null;
        var rtext2 = null;
        var rtext3 = null;

        let img = { imageData: null, imageFn: null }

        if (this.data instanceof SunChartSectorData && !this.nodeType) {

            //<------ Text Section------->
            if (this.configs.text) {
                ctext1 = SunChartGraphic.getCurveText("cText1_" + this.levelId, { text: this.data.displayName, nooflines: 1, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.text }, this.reportfont);
            }

            if (this.configs.text2) {
                ctext2 = SunChartGraphic.getCurveText("cText2_" + this.levelId, { text: this.data.dateRangeStr, nooflines: 1, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.text2 }, this.reportfont);
            }

            if (this.configs.rText1) {
                rtext1 = SunChartGraphic.getRadialText("rText1_" + this.levelId, { text: this.data.displayName, nooflines: this.isEmptyObject(this.configs.rText1) ? 1 : 2, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.rText1 }, this.reportfont);
            }
            // Connected with radialText1 remaining text
            if (this.configs.rText2) {
                rtext2 = SunChartGraphic.getRadialText("rText2_" + this.levelId, { text: rtext1 && rtext1.textRemaining ? rtext1.textRemaining[0] : null, nooflines: 1, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.rText2 }, this.reportfont);
            }

            if (this.configs.rText3) {
                rtext3 = SunChartGraphic.getRadialText("rText3_" + this.levelId, { text: this.data.dateRangeStr, nooflines: 1, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.rText3 }, this.reportfont);
            }

            //<------ Image Section------->
            var tmp = SunChartGraphic.getSectorImage(this.data.profileImage, this.radius, this.angle,
                this.axisAdjustment, this.levelCount, this.sectorId, this.configs.image);

            img.imageData = tmp;
        }

        if (this.data instanceof SunChartFunSector && this.nodeType) {
            
            //<------ Text Section------->
            //Curve Text
            if (this.configs.function.text) {
                ctext1 = SunChartGraphic.getCurveText("cText1_" + this.levelId, { text: this.data.displayName, nooflines: 1, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.function.text }, this.reportfont);
            }

            //Radial Text
            if (this.configs.function.rText1) {
                rtext1 = SunChartGraphic.getRadialText("rText1_" + this.levelId, { text: this.data.displayName, nooflines: this.isEmptyObject(this.configs.leadNodeRadialText1) ? 1 : 2, r: this.radius, angle: this.angle, levelCount: this.levelCount, sectorId: this.sectorId, textConfig: this.configs.function.rText1 }, this.reportfont);
            }

            //<------ Image Section------->
            var tmp = SunChartGraphic.getSectorImage(this.data.profileImage, this.radius, this.angle,
                this.axisAdjustment, this.levelCount, this.sectorId, this.configs.function.image);

            img.imageFn = tmp;

        }

        var textObjects = [];

        if (ctext1 != null) {
            textObjects.push(ctext1);
        }
        if (ctext2 != null) {
            textObjects.push(ctext2);
        }
        if (rtext1 != null) {
            textObjects.push(rtext1);
        }
        if (rtext2 != null) {
            textObjects.push(rtext2);
        }
        if (rtext3 != null) {
            textObjects.push(rtext3);
        }

        return {
            "texts": textObjects,
            "imageData": img.imageData,
            "imageFn": img.imageFn,
        }
    }

    isEmptyObject(obj) {
        return JSON.stringify(obj) === '{}';
    }

}