/**
 * This Class is to aggregate the truthy check for Javascript objects
 *
 * This is influenced by following articles:
 *      - https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
 *      - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/null
 *      - https://stackoverflow.com/questions/4361585/how-to-check-if-a-variable-is-not-null
 *      - https://stackoverflow.com/questions/6003884/how-do-i-check-for-null-values-in-javascript
 *
 */

import { Injectable } from '@angular/core';

let undefinedVar = 'undefined';

@Injectable({
  providedIn: 'root'
})

export class TruthyCheck {
  isTruthy(value) {
    try {
      let check = true;
      if (value === null) { check = false; }
      if (value === undefined) { check = false; }
      // tslint:disable-next-line: use-isnan
      if (value === NaN) { check = false; }
      if (value === undefinedVar) { check = false; }
      if (typeof value === undefinedVar) { check = false; }
      if (value === false) { check = value; }
      if (value === 'false') { check = false; }
      if (value === '') { check = false; }

      return check;
    } catch {
      return false;
    }
  }

  isEmptyList(value) {
    return !this.isTruthy(value) || value.length === 0;
  }

  isTruthyAllProperties(object) {
    let isAnyPropertyNullInObject = true;
    if (object) {
      for (let property in object) {
        if (object.hasOwnProperty(property)) {
          let result = this.isTruthy(object[property]);
          if (!result) {
            isAnyPropertyNullInObject = false;
            return isAnyPropertyNullInObject;
          }
        }
      }
      return isAnyPropertyNullInObject;
    }
    return false;
  }

  isEmptyObject(object) {
    for (let key in object) {
      if (object.hasOwnProperty(key)) { return false; }
    }
    return true;
  }

  isUndefined(value) {
    let check = true;
    if (value === undefined) { check = false; }
    if (value === undefinedVar) { check = false; }
    if (typeof value === undefinedVar) { check = false; }

    return check;
  }
}
