<div class="event-carousel-content" style="width: 225px;">
    <!-- side bar -->
    <div *ngIf="eventMedia.length > 1">
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-left.png" style="display: block;cursor: pointer;" (click) = "slideLeft()" />
        
    </div>
    <!-- content -->
    <div style="width: 100%;display: flex;flex-direction: column;padding: 0px 5px 0px 5px;max-width: 170px;gap:5px">

        <div style="display: flex;flex-direction: row;">
            <div>               
                <img class="primary-image" [src]="getMediaUrl()">                
            </div>
            <div class="flexrow" style="margin-left: 10px; margin-top: 5px;">                
                <span class="tooltip-heading-text">{{"lbl_picture_caption" | translate}}</span>      
                <span class="tooltip-content-text text-ellipsis-2" style="margin-top: -2px;-webkit-line-clamp: 2 !important;">{{!currentMediaRecord.caption?'-':currentMediaRecord.caption}}</span>    
            </div>                    
        </div>

        <div class="tooltip-col col2-v50v50">
            <div class="tooltip-row">
                <span class="tooltip-heading-text">{{"lbl_date"|translate}}</span>                
                <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px">{{!currentMediaRecord?.date?.genealogyDate?.originalStr  ? '-': currentMediaRecord?.date?.genealogyDate?.originalStr }}</span>     
            </div>
            <div class="tooltip-row">
                <span class="tooltip-heading-text">{{"lbl_place"|translate}}</span>                
                <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px">{{!currentMediaRecord?.place?'-':currentMediaRecord.place}}</span>     
            </div>
        </div>
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_description"|translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px; -webkit-line-clamp: 3 !important;"> {{!currentMediaRecord?.description ?'-':currentMediaRecord.description}}
            </span>     
        </div>
    </div>
    <!-- side bar -->
    <div *ngIf="eventMedia.length > 1">
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-right.png" style="display: block;cursor: pointer;" (click) = "slideRight()" />
    </div>
</div>