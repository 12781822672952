import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class ArrayHelper {

    groupBy(list, keySelector) {
        const map = new Map();
        list.forEach((item) => {
            const key = keySelector(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
}