import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { DropMenuModule } from '../drop-menu/drop-menu.module';
import { MediaGalleryListComponent } from './media-gallery-list/media-gallery-list.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FamilyProjectListComponent } from './family-project-list/family-project-list.component';


@NgModule({
  declarations: [MediaGalleryComponent, MediaGalleryListComponent, FamilyProjectListComponent],
  imports: [
    CommonModule,
    DropMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })        
  ],
  exports : [MediaGalleryComponent, MediaGalleryListComponent, FamilyProjectListComponent]
})
export class MediaGalleryModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
