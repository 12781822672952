import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, OnChanges,SimpleChanges } from '@angular/core';
import { MultimediaTypes } from 'src/app/common/enums/enums';
import { MultiMediaFilter } from 'src/app/common/helpers/filter';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import {decode, decodeImage, toRGBA8} from 'utif';

@Component({
  selector: 'app-media-input',
  templateUrl: './media-input.component.html',
  styleUrls: ['./media-input.component.scss']
})
export class MediaInputComponent implements OnInit,OnChanges {
  
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('dropzone') dropZone : ElementRef; //For dropzone div element
  
  @Input() mediaInputData : any;
  @Output() mediaFileInfoOut: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private translateHandler: TranslateHandler
    ) { }
    
  mode : number = 1;    // 1 = Upload, 2= EditImage, 3-EditDoucment ..etc
  
  cropAreaWidth = 720;
  cropAreaHeight = 300;
  cropAreaSize = 200;   // 200X200 - radius - 100

  priviewImageRect:any        = {x:0, y:0, w:0, h:0 };
  previewImageOrginalPos:any  = {x:0 ,y:0, ratio:1 };
  previewImageURL = null;
 
  previewImageZoomMax = 3.6;
  priviewImageZoom : number  = 1;
  
  isDrag: boolean = false;
  startX : number = 0;
  startY : number = 0;
  offsetX :number = 0 ;
  offsetY :number = 0 ;
  
  isError : boolean = false;
  errorMessage : string = "";
  
  mediaFile : any = null;
  nonImagePath : string;
  currentMediaType : number;
  // Todo : change the following string according to the accepting media
  uploadExtensionTxt : string = "";
  acceptMediaExtension: string;

  extensionMap = new Map<string, number>(
    [ 
      // Accepting photo extensions
      ["jpg", MultimediaTypes.PHOTO] ,
      ["jpeg", MultimediaTypes.PHOTO] ,
      ["bmp", MultimediaTypes.PHOTO] ,
      ["tiff", MultimediaTypes.PHOTO] ,
      ["png", MultimediaTypes.PHOTO] ,
  
      // Accepting AUDIO extensions
      ["wav", MultimediaTypes.AUDIO] ,
      ["mp3", MultimediaTypes.AUDIO] ,
      ["mpeg", MultimediaTypes.AUDIO] ,
  
      // Accepting VIDEO extensions
      ["mp4", MultimediaTypes.VIDEO] ,
      ["avi", MultimediaTypes.VIDEO] ,
  
      // Accepting DOC extensions
      ["msword", MultimediaTypes.DOC] ,
      ["rtf", MultimediaTypes.DOC] ,
      ["txt", MultimediaTypes.DOC] ,
      ["pdf", MultimediaTypes.DOC] ,
      ["plain", MultimediaTypes.DOC] ,
      ["doc", MultimediaTypes.DOC] ,
      ["docx", MultimediaTypes.DOC]
    ]
    );
     
  ngOnInit(): void {
    this.setAcceptedMediaExtensionTxt();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    let change = changes['mediaInputData'];
    if(change){
      // Required to get context change
      let self = this;
      if(this.mediaInputData.editMedia.id > 0){       
        // Convert media url to file (blob)
        this.dataUrl(this.mediaInputData.editMedia.originalMediaUrl, function (dataUrl) {
          var byteString = atob(dataUrl.split(',')[1]);
          // separate out the mime component
          var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];    
          // write the bytes of the string to an ArrayBuffer
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }    
          let multimediaFile = new File([new Blob([ab], { type: mimeString })], self.mediaInputData.editMedia.fileName, { type: mimeString });
          //Preview image to be edited
          self.generatePreview(multimediaFile);
         });  
      }  
      
    }
  }
 
  initPreview(){
    this.priviewImageRect       = {x:0, y:0, w:0, h:0 };
    this.previewImageOrginalPos = {x:0 ,y:0, ratio:1 };
    this.priviewImageZoom       = 1;
    this.isDrag   = false;
    this.startX   = 0;
    this.startY   = 0;
    this.offsetX  = 0 ;
    this.offsetY  = 0 ;   
    this.isError  = false;     
    this.errorMessage = "";
    this.mediaFile = null;
  }

  changeMode(m){
    this.mode = m;
  }

  // Load via input button
  clickFileInput() {
    this.fileInput.nativeElement.click();
  }

  // Stop default behaviour - load on browser
  dragOver($event){
    // change background color
    $event.preventDefault();
    $event.stopPropagation();
  }

  // Load via drag and drop
  filesDropped($event){
    $event.preventDefault();
    $event.stopPropagation();
     // Drag drop
    if ($event.dataTransfer && $event.dataTransfer.files) {
      var filesToUpload = $event.dataTransfer.files;
      this.generatePreview(filesToUpload[0]);
    }    
  }
  
  // Load via openDialog box
  fileUploaded($event){
    //File upload
    if ($event.target && $event.target.files) {
      var filesToUpload = $event.target.files;
      
      this.generatePreview(filesToUpload[0]);
    }
  }

  getFileType(fileName){    
    var lastDotIndex = fileName.lastIndexOf('.')
    if ( lastDotIndex < 0 ){
      return null;
    }
    return fileName.substring(lastDotIndex + 1).toLowerCase();
  }
  
  getAcceptedMediaTypeText(){
    let filterTypes = MultiMediaFilter.getFilters();
    let filterNames =  filterTypes.filter(t=> this.mediaInputData.acceptedMediaTypes.some(y=>y == t.id)).map(x=>{
       return this.translateHandler.translate("lbl_"+x.type);
    })

   return filterNames.join(',');
  }

  setAcceptedMediaExtensionTxt(){
    let filesizeMap = [
      {size:5, type :MultimediaTypes.PHOTO},
      {size:5, type :MultimediaTypes.DOC},
      {size:5, type :MultimediaTypes.AUDIO},
      {size:15, type :MultimediaTypes.VIDEO},
    ]

    let mediaTypesBy5MB = filesizeMap.filter(el => el.size == 5 && this.mediaInputData.acceptedMediaTypes.some(y=>y == el.type));
    let mediaTypesBy15MB = filesizeMap.filter(el => el.size == 15 && this.mediaInputData.acceptedMediaTypes.some(y=>y == el.type));
   
    let keys5MB = [...this.extensionMap.entries()]
    .filter(({ 1: v }) => mediaTypesBy5MB.some(t=>t.type == v))
    .map(([k]) => k);

    let keys15MB = [...this.extensionMap.entries()]
    .filter(({ 1: v }) => mediaTypesBy15MB.some(t=>t.type == v))
    .map(([k]) => k);  

    let uploadExtensionTxt5mb = this.translateHandler.translate("ph_drag_and_drop_image_type_5MB",[keys5MB.join(', ')]);
    let uploadExtensionTxt15mb = this.translateHandler.translate("ph_drag_and_drop_image_type_15MB",[keys15MB.join(', ')]);
    
    this.uploadExtensionTxt = keys15MB.length > 0 ? uploadExtensionTxt5mb + " " +uploadExtensionTxt15mb: uploadExtensionTxt5mb;
    this.acceptMediaExtension = '.'+keys5MB.join(', .') + ", ." + keys15MB.join(', .') ;
  }

  dataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();    
  }
 
  async generatePreview(multimediaFile){   
    this.isError = false;
    this.errorMessage = "";   
    var uploadedFile = multimediaFile;

    // Get only the first media file    
    var ext = this.getFileType(uploadedFile.name);
    if (ext == null) {
      this.isError = true;
      this.errorMessage = this.translateHandler.translate("val_unsupported_file_extension");
      // show error
      return;
    }
    // validate 
    var mediaType = this.extensionMap.get(ext);
    this.currentMediaType = this.mediaInputData.editMedia.id > 0 ? this.mediaInputData.editMedia.mediaType : mediaType;
    
    // Allow replacing same media type with current media type
    if (this.currentMediaType != mediaType) {
      this.isError = true;
      this.errorMessage = this.translateHandler.translate("val_file_replace_warning");
      return;
    }
    
    if ( !mediaType){
      this.isError = true;
      this.errorMessage = this.translateHandler.translate("val_unsupported_file_type");
      // show error
      return;
    }
    
    // Check for accepted media type
    var isAcceptedMedia = this.mediaInputData.acceptedMediaTypes.find(m => m == mediaType);
    if (!isAcceptedMedia){
      this.isError = true;
      this.errorMessage = this.translateHandler.translate('val_invalid_file_type', [this.getAcceptedMediaTypeText()]);      
      return;
    }

    // check size photo audio > 5
    if ((mediaType == MultimediaTypes.AUDIO || mediaType == MultimediaTypes.PHOTO || mediaType == MultimediaTypes.DOC) && uploadedFile.size > 5 * 1024 * 1024) {
      this.isError = true;
      this.errorMessage = this.translateHandler.translate("val_file_size_5mb");
      return
    }

    // check size video > 15
    if ((mediaType == MultimediaTypes.VIDEO) && uploadedFile.size > 15 * 1024 * 1024) {
      this.isError = true;
      this.errorMessage = this.translateHandler.translate("val_file_size_15mb");
      return
    }

    if (ext.toLowerCase() === 'tiff') {
      try {
          // Replace uploadedFile with the converted PNG
          uploadedFile = await this.convertTiffToPng(uploadedFile); 
      }
      catch (error) {
          this.isError = true;
          this.errorMessage = this.translateHandler.translate("val_unsupported_file_type");
          return;
      }
    }
  
    this.mediaFile = uploadedFile;
    // set mode
    if ((mediaType == MultimediaTypes.PHOTO)) {
      this.mode = 2;
    } else {
      this.mode = 3;
      this.mediaFileInfoOut.emit(this.getOrginalRect());
    }

    const URL = window.URL || window.webkitURL;
    const Img = new Image();
    Img.src = URL.createObjectURL(uploadedFile);

    Img.onload = (e: any) => {
      // const height = e.path[0].height; //2023.02.02 - removed due to e.path == undefined
      // const width = e.path[0].width;
      const height = Img.height;
      const width = Img.width;

      // Adding new dimension crop width and height
      this.cropAreaWidth = this.dropZone.nativeElement.offsetWidth;
      this.cropAreaHeight = this.dropZone.nativeElement.offsetHeight;

      var ratio = 1;
      if (height > width) {
        this.priviewImageRect.w = this.cropAreaSize;
        this.priviewImageRect.h = (this.cropAreaSize / width) * height;
        ratio = (this.cropAreaSize / width);
      } else {
        this.priviewImageRect.h = this.cropAreaSize;
        this.priviewImageRect.w = (this.cropAreaSize / height) * width;
        ratio = (this.cropAreaSize / height);
      }

      this.priviewImageRect.x = (this.cropAreaWidth - this.priviewImageRect.w) / 2;
      this.priviewImageRect.y = (this.cropAreaHeight - this.priviewImageRect.h) / 2;
      this.previewImageOrginalPos = { x: this.priviewImageRect.x, y: this.priviewImageRect.y, ratio: ratio };

      this.previewImageURL = Img.src;
      this.mode = 2;
      this.mediaFileInfoOut.emit(this.getOrginalRect());
    }
  }

  convertTiffToPng(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const arrayBuffer = reader.result as ArrayBuffer;
          
          // Decoding TIFF and image data, then converting it to RGBA
          const ifds = decode(arrayBuffer); 
          decodeImage(arrayBuffer, ifds[0]);  
          const rgba = toRGBA8(ifds[0]);  
          
          const canvas = this.createCanvas(ifds[0].width, ifds[0].height, rgba);
          
          this.canvasToPngFile(canvas, file.name)
            .then(resolve)
            .catch(reject);
        } catch (error) {
          reject(error);
        }
      };
  
      reader.readAsArrayBuffer(file);
    });
  }
  
  private createCanvas(width: number, height: number, rgba: Uint8Array): HTMLCanvasElement {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    
    const imageData = ctx.createImageData(width, height);
    imageData.data.set(rgba);
    ctx.putImageData(imageData, 0, 0);
  
    return canvas;  
  }

  // Method to convert canvas to a PNG file
  private canvasToPngFile(canvas: HTMLCanvasElement, fileName: string): Promise<File> {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
          const pngFile = new File([blob], fileName.replace(/\.[^/.]+$/, ".png"), { type: "image/png" });
          resolve(pngFile);
      }, 'image/png');
    });
  }
  
  
  //@HostListener("mousedown", ["$event"])
  dragStart($event) {
    if ( this.mode == 1 ) return;
    this.isDrag = true;
    
    this.startX = $event.clientX ;
    this.startY = $event.clientY;
  }

  //@HostListener("mouseup", ["$event"])
  dragEnd($event) {
    if ( this.mode == 1 ) return;    
    this.isDrag = false;
    this.startX = 0;
    this.startY = 0;
  }
  //@HostListener("mousemove", ["$event"])
  drag($event) {
    if ( this.isDrag ){

      this.offsetX = $event.clientX - this.startX;
      this.offsetY = $event.clientY - this.startY;

      this.priviewImageRect.x += this.offsetX;
      this.priviewImageRect.y += this.offsetY;

      var rect = this.getZoomRect();
      if ( (rect.x <= 0 || rect.x2 >= this.priviewImageRect.w) ){
        this.priviewImageRect.x -= this.offsetX;        
      }
      
      if ( (rect.y <= 0 || rect.y2 >=this.priviewImageRect.h) ){        
        this.priviewImageRect.y -= this.offsetY;        
      }

      this.startX = $event.clientX;
      this.startY = $event.clientY;
      // Update parent
      this.mediaFileInfoOut.emit(this.getOrginalRect());
    }    
  }

  zoom($event){
    if ( $event.wheelDelta ){
      if ( $event.wheelDelta > 0 ){
        this.priviewImageZoom += 0.1;
        if ( this.priviewImageZoom >= 4 ) this.priviewImageZoom = 4;
      }else{
        this.priviewImageZoom -= 0.1;
        if ( this.priviewImageZoom <= 1 ) this.priviewImageZoom = 1;
      }
    }
    if ( $event.target.value ){
      this.priviewImageZoom = $event.target.value
    }
    if ( this.priviewImageZoom >= this.previewImageZoomMax ){
      this.priviewImageZoom = this.previewImageZoomMax;
    }   
    
    // check if the image is going out of crop area - reset image
    var rect = this.getZoomRect();
    if ( rect.x <= 0 ){
      this.priviewImageRect.x = (this.cropAreaWidth - this.priviewImageRect.w )/2;        
    }

    if ( rect.y <= 0 ){        
      this.priviewImageRect.y = (this.cropAreaHeight - this.priviewImageRect.h )/2;        
    }

    // Update parent
    this.mediaFileInfoOut.emit(this.getOrginalRect());
  }

  getImageZoom(){
    return `scale(${this.priviewImageZoom})`;
  }

  getCropZoom(){
    return `scale(${1/this.priviewImageZoom})`;
  }

  getZoomRect(){
    let centerX = (this.previewImageOrginalPos.x - this.priviewImageRect.x) +  this.priviewImageRect.w / 2;
    let centerY = (this.previewImageOrginalPos.y - this.priviewImageRect.y) +  this.priviewImageRect.h / 2;

    return {x:centerX -100/this.priviewImageZoom, 
                      y:centerY -100/this.priviewImageZoom, 
                      x2:centerX+100/this.priviewImageZoom, 
                      y2:centerY+100/this.priviewImageZoom};
  }

  getOrginalRect(){

    let rectZoomed = this.getZoomRect();    
    var rectOrg = { x: Math.round(rectZoomed.x/ this.previewImageOrginalPos.ratio), 
                    y: Math.round(rectZoomed.y/ this.previewImageOrginalPos.ratio), 
                    x2: Math.round(rectZoomed.x2/ this.previewImageOrginalPos.ratio), 
                    y2: Math.round(rectZoomed.y2/ this.previewImageOrginalPos.ratio),
                    width : Math.round(rectZoomed.x2/ this.previewImageOrginalPos.ratio) - Math.round(rectZoomed.x/ this.previewImageOrginalPos.ratio),
                    height :Math.round(rectZoomed.x2/ this.previewImageOrginalPos.ratio) - Math.round(rectZoomed.x/ this.previewImageOrginalPos.ratio),
                    file :this.mediaFile,
                    mediaType : this.currentMediaType};
    return rectOrg;
  }


  closePreview(){
    this.initPreview();
    this.mode = 1;
    this.mediaFileInfoOut.emit(null);
  }
}
