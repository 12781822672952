import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MessageDialogBaseComponent } from './message-dialog-base/message-dialog-base.component';
import { MessagePromptComponent } from './message-prompt/message-prompt.component';
import { MessagePromptStripeComponent } from './message-prompt-stripe/message-prompt-stripe.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [MessageDialogComponent, MessageDialogBaseComponent, MessagePromptComponent, MessagePromptStripeComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule
  ],
  exports:[MessageDialogComponent]
})

export class MessageDialogModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}