import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable, throwError } from 'rxjs';
import { MapIndividualsViewModel } from 'src/app/common/models/report/mapreport/map-individuals-view-model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapReportApiService {

  constructor(
    private apiBaseService: ApiBaseService,
    ) { }

  // Get all individuals data
  getAllMapIndividuals(): Observable<MapIndividualsViewModel[]> {
    return this.apiBaseService.get<any>("/api/mapreport/mapindividuals", null, null, null).pipe(
      map((response)=>{
        return response.data;
      }),catchError((error)=>{
        return throwError(error);
      })
  )
  }
}
