import { PreferencesService } from 'src/app/services/preferences.service';
import { Injectable } from '@angular/core';
import { TranslateHandler } from './translate-handler';

@Injectable({
    providedIn: 'root'
})

export class TimeHelper {
    private preference: any;

    constructor(private preferenceService: PreferencesService,
                private translateHandler: TranslateHandler) {
    }

    getDuration(date: Date) {
        date = new Date(date.toString() + ' UTC');
        let days = Math.round((new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24));
        if (days <= 1) {
          let minutes = Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 60));
          if (minutes < 1)
            return this.translateHandler.translate("lbl_just_now");
          else if (minutes >= 1 && minutes < 60) // Minutes
            return `${minutes} ` + this.translateHandler.translate("lbl_minutes_ago"); 
          else if (minutes >= 60) // Years
            return `${Math.floor(minutes / 60)} ${this.translateHandler.translate("lbl_hours_ago")}`; 
        } else if (days < 30) { // Days
          return `${days} ` + this.translateHandler.translate("lbl_days_ago");
        } else if (days >= 30 && days < 365) { // Months
          return `${Math.floor(days / 30)} ` + this.translateHandler.translate("lbl_months_ago");
        } else if (days >= 365) { // Years      
          return `${Math.floor(days / 365)} ` + this.translateHandler.translate("lbl_years_ago");
        }
    
      }

      formatTime(timeString: string){
        //input type => hh:mm:ss AM/PM
        timeString = timeString.trim();
        var timeSplit = timeString.split(" ");
        var AMPM = timeSplit[1];
        var time = timeSplit[0].split(":");

        var hours = time[0];
        var minutes = time[1];

        if(AMPM=="AM" && hours == "12"){
            hours="00"
        }

        if(AMPM == "PM" && hours != "12"){
            hours = (+hours + 12).toString();
        }
        return hours+":"+minutes;
    }

}