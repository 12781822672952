import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';
import { CustomApiParameters } from 'src/app/common/helpers/custom-api-parameters';
import { PreferenceType } from 'src/app/common/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class PreferencesApiService {

  constructor(private apiBaseService: ApiBaseService,
    private customApiParameters: CustomApiParameters) { }

  getPreferences<T>(preferenceType: string, projectId: string): Observable<T> {
    this.customApiParameters.clearAllParams();
    if(preferenceType == PreferenceType.Project){
      var customParaList = this.customApiParameters.addCustomParameter("ProjectId", projectId).getCustomParaList();
    } else {
      customParaList = null;
    }
    return this.apiBaseService.get("/api/preference/" + preferenceType, customParaList, null);
  }

  updatePreferences(preferenceType: string, projectId: string, data:any): Observable<any>{
    this.customApiParameters.clearAllParams();
    if(preferenceType == PreferenceType.Project){
      var customParaList = this.customApiParameters.addCustomParameter("ProjectId", projectId).getCustomParaList();
    } else {
      customParaList = null;
    }
    return this.apiBaseService.update("/api/preference/" + preferenceType, customParaList, data);
  }
}
