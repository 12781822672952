import { Injectable } from '@angular/core';
import { Preference } from '../common/models/Preference';
import { ApiBaseService } from './api-base.service';
import { ResponseModel } from '../common/models/responseModel';
import { CustomError } from '../common/helpers/custom-error';

@Injectable()

// Project Preferences and Culture will be handled here.
export class PreferencesService {
  private preference: any;
  private culture: any;
  //TODO: Duplicated in the server side also
  private static defaultPreference: Preference = {
    "nameOrder": "firstLast",
    "showCapitalSurname": false,
    "includeMiddleName": false,
    "includePrefixSuffix": false,
    "includePatronomic": false,
    "duplicateNameCheck": false,
    "autoCapitalName": false,
    "dateSeperater": "-",
    "dateType": "long",
    "monthFirst": false,
    "showCapitalDate": false,
    "twoDigitNumeric": false,
    "showPrepositions": true,
    "lastViewedProjectName": "default",
    "isLocal": false
  };

  constructor(private apiBaseService: ApiBaseService) {
    this.preference = PreferencesService.defaultPreference;
    this.culture = "no";
  }

  /**
  * @deprecated The method should not be used
  */
  load() {
    // During the project change load preferences
    if (!this.preference) {
      this.apiBaseService.get<ResponseModel<Preference>>('/api/preference', null, null).
        subscribe((response: ResponseModel<Preference>) => {
          this.preference = response.data;
        },
          (err) => {
            this.preference = PreferencesService.defaultPreference; 
            throw new CustomError("ProjectPreferencesService => ngOnInit() : " + err, 911, false);
          });
    }
  }

  setPreferences(pref:any){
    this.preference = pref;
  }

  public setCulture(culture: string) {
    this.culture = culture;
  }

  public getCulture() {
    return this.culture;
  }

  public get() {
    return this.preference;
  }
}
