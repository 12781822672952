<div class="media-gallert-wrap">
  <div class="media-gallert-container">
      <div class="item" [class.item-selected]="item.isSelected" (click)="selectItem(item)" *ngFor="let item of getSortedMediaItems()">
        <div style="position: relative;">
        <img class="item-image" [src]="item.url" loading="lazy" (error)='onItemError($event,item)'>
          <img class="item-selected-icon" src="assets/images/icons/photo-selected.svg">
        </div>
        <span class="label-m-grey text-ellipsis-2"> {{item.caption}} </span>  
        <app-drop-menu *ngIf="item.dropMenu != null && (isStandAlone || item.isEditEnable)" [menu]="item.dropMenu" (action)="clickedAction($event)"></app-drop-menu>            
      </div>
  </div>
</div>