import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EditorMode } from 'src/app/common/enums/enums';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { UserModel } from 'src/app/common/models/UserModel';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private projectRefService     : ProjectRefService,
              private authenticationService : AuthenticationService,
              private translateHandler      : TranslateHandler,
              private notifierService       : NotifierV2Service,
              private dialogService         : DialogService) { }

  user : UserModel;
  supportUrl: string = environment.FT_SUPPORT_URL;

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
  }

  getToday(){
    return formatDate(Date.now(), 'dd MMM yyyy', 'en-US');
  }

  getProjectCount(){
    return this.projectRefService.getProjectList().length;
  }
  getCurrentProjectName(){
    return this.projectRefService.getCurrentProject()?.projectName;
  }

  getUsername(){    
    return this.user.FirstName;
  }

  getWelcomeMessage(){    
    if ( this.user.IsFirstTime ){
      return this.translateHandler.translate("dashboard.lbl_welcome", [this.user.FirstName]);
    }else{
      return this.translateHandler.translate("dashboard.lbl_welcome_back", [this.user.FirstName])
    }
  }
  
  openTreeEditor(){
    let ref:EditorReferenceInfo = {
      id : this.notifierService.getCurrentRootMemberId(),      
      isBreadcrumbUpdate: true
    }
    this.notifierService.openEditor(EditorMode.TreeEditor, ref);
  }

  openCreateImportPanel(mode) {
    let popupInput = {
      viewMode: mode,
      showMode: [mode],
      selectedProjectId: null,
      FamilyProjectList: this.projectRefService.getProjectList(),
    }
    this.dialogService.setFull().open("AddNewProjectMain", popupInput).subscribe(response => {
      if (response?.data != null) { //data = null when the user cancel the popup without any change
        this.projectRefService.load().then(() => {
          if (response?.data == "success" || response?.data == "import_success") { // New tree or Import success
            this.openTreeEditor()
          }
        })
      }
    });
  }

  getMessageState() {

    if (this.projectRefService.getProjectList().length == 0) return 0;

    // Project editable = valid and not importing
    if (this.projectRefService.getCurrentProject()?.isValid && !this.projectRefService.getCurrentProject()?.isImporting) return 1;

    if (this.projectRefService.getCurrentProject()?.isImporting) return 2;

    if (!this.projectRefService.getCurrentProject()?.isValid) return 3;

  }

}



/*

if (this.projectRefService.getProjectList().length == 0 ){
  // Show new project dialog
    await this.dialogService.setFull().open("AddNewProjectMain", 
                                      {
                                        viewMode: 1,
                                        FamilyProjectList: this.projectRefService.getProjectList(),
                                      }
                                    ).toPromise();
  // Reload project list
    var data = await this.projectRefService.load().catch(()=>{
      const res = this.messageDialogService.openError("Error", "Project load error","The project list is not loaded, Contact support").subscribe( ()=> {
        window.open("http://www.embla.no", "_top");
        return;
      });
    });      
  }
  
  // For the first project, show import page
  if  ( this.projectRefService.getProjectList().length == 0 ){
    const res = this.messageDialogService.openError("Error", "Project load error","The project list is not loaded, Contact support").subscribe( ()=> {
      window.open("http://www.embla.no", "_top");
      return;
    });
    return;              
  }
  */
