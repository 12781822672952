import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FactsheetComponent } from './factsheet/factsheet.component';
@NgModule({
  declarations: [FactsheetComponent],
  imports: [
    CommonModule
  ],
  entryComponents:[FactsheetComponent]
})
export class FactSheetModule { }