import { MultimediaPopupOpenMode, MultimediaTypes, CropFrameMode, PopupOpenMode } from '../enums/enums';
import { ConnectionInfo } from './ConnectionInfo';
import { MultiMediaBasicInfo } from './MultiMediaBasicInfo';
import { RootMember } from './RootMember';

export class PopupInputInfo {
    visibility: boolean;
    level: number;
    popWidth: string;
    ConnectionInfo: ConnectionInfo[] = new Array();

    SetConnectionInfo(resourceType: string, resourceName: string): PopupInputInfo {
        let info = new ConnectionInfo();
        info.ResourceType = resourceType;
        info.ResourceName = resourceName;

        this.ConnectionInfo.push(info);
        return this;
    }
}

export class MultimediaPopupInputInfo extends PopupInputInfo {
    Title: string;
    TitleBelongsTo: string;
    Mode: MultimediaPopupOpenMode;
    FilterMode: number[];
    SelectedImageIds: number[] = new Array();
    CropFrameMode: CropFrameMode;
    AcceptedMultimediaTypes: number[];
    PopUpTitle: string;
    IsFullScreenPopUp: boolean;
    PopupWidth: string;
    MediaToEdit: MultiMediaBasicInfo;

    SetImageIds(imageId: number) {
        this.SelectedImageIds.push(imageId);
        return this.SelectedImageIds;
    }
}

export class EventPopupInputInfo extends PopupInputInfo {
    EventData: any;
}

export class SourcesPopupInputInfo extends PopupInputInfo {
    SourcesData: any;
}

export class MemberEditorPopupInputInfo extends PopupInputInfo {
    Title: string;
    TitleBelongsTo: string;
}

export class MembershipPopupInputInfo extends PopupInputInfo {
    TitleOption: string;
    memberShipUpgradeTemplate: string;
    rootMember: RootMember;

    constructor(titleOption, level, popWidth, memberShipUpgradeTemplate, rootMember) {
        super();
        this.TitleOption = titleOption;
        this.level = level;
        this.popWidth = popWidth;
        this.memberShipUpgradeTemplate = memberShipUpgradeTemplate;
        this.rootMember = rootMember;
    }

}

export class SunChartOptionPopupInputInfo extends PopupInputInfo {
    TitleOption: string;
    Genaration: number;
    ReportTitle: string;

    constructor(tileOption, genaration, reportTitle, level, popWidth) {
        super();
        this.TitleOption = tileOption;
        this.Genaration = genaration;
        this.ReportTitle = reportTitle;
        this.popWidth = popWidth;
        this.level = level;
    }
}

export class ReportPublishOptionPopupInputInfo extends PopupInputInfo {
    Title: string;
    PopUpTitle: string;
    
    constructor(title, popWidth) {
        super();        
        this.Title = title;
        this.popWidth = popWidth;
    }
}

export class PedigreeOptionPopupInputInfo extends PopupInputInfo {
    TitleOption: string;
    Genaration: number;
    ReportTitle: string;

    constructor(tileOption, genaration, reportTitle, level, popWidth) {
        super();
        this.TitleOption = tileOption;
        this.Genaration = genaration;
        this.ReportTitle = reportTitle;
        this.popWidth = popWidth;
        this.level = level;
    }
}

export class PlacePopupInputInfo extends PopupInputInfo {
    Title: string;
    TitleBelongsTo: string;
    SelectedPlaceIds: number[] = new Array();
    Mode: PopupOpenMode;


    constructor(title,popWidth,titlebelongsto,level,SelectedPlaceIds,Mode) {
        super();
         this.Title = title;
         this.popWidth = popWidth;
         this.TitleBelongsTo = titlebelongsto;
         this.level = level;
         this.SelectedPlaceIds = SelectedPlaceIds;
         this.Mode = Mode;
       
    }
}
