import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from '../../common/base/component-base';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';

@Component({
  selector: 'app-individual-editor-main',
  templateUrl: './individual-editor-main.component.html',
  styleUrls: ['./individual-editor-main.component.scss']
})
export class IndividualEditorMainComponent implements OnInit {
  showComponent: boolean = false;

  @Input() data: any;
  eventTitle: string;
  constructor(private location: Location, 
              private activateRouter: ActivatedRoute,
              private notifierService: NotifierV2Service) { }

  ngOnInit(): void {
    //change @EM-10562
    let paramId = this.activateRouter.snapshot.paramMap.get('id') as any;
    let paramIsBreadcrumbUpdate = this.activateRouter.snapshot.paramMap.get('isBreadcrumbUpdate') as any;

    this.data = {
      id: paramId,
      isBreadcrumbUpdate: paramIsBreadcrumbUpdate
    }
  }

  changeData(updatedData) {
    this.data = {
      id: updatedData.id,
      editorMode: updatedData.editorMode,
      rootMemberId: updatedData?.isSameId ? updatedData.rootMemberId : this.notifierService.getCurrentRootMemberId() //2023/01/04 - To detect the breadcrumb change
    }
  }

  public showMain(value): void {
    this.showComponent = value;
  }

  setTitle(eventTitle) {
    this.eventTitle = eventTitle;
  }
}
