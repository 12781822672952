import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MessageDialogBaseComponent } from '../message-dialog-base/message-dialog-base.component';
import { MessagePromptComponent } from '../message-prompt/message-prompt.component';
import { MessageDialogService } from '../services/message-dialog.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  @ViewChild('messageContent', { read: ViewContainerRef, static: false }) public messageContentHost: ViewContainerRef;
  
  constructor(private messageDialogSerivce:MessageDialogService, private resolver: ComponentFactoryResolver) { }
  
  isModalVisible : boolean = false;
  isStripePopup: boolean = false;

  ngOnInit(): void {
    this.messageDialogSerivce.onOpen.subscribe(openRequest => {
      //openRequest.class
      //openRequest.data
      let messageDialogClass = openRequest.class;
      let messageDialogData = openRequest.data;
      this.isStripePopup =  openRequest.data.isStripePopup
      console.log("data", openRequest.data);

      this.messageContentHost.clear();
      const factory     = this.resolver.resolveComponentFactory<MessageDialogBaseComponent>(messageDialogClass);
      let componentRef  = this.messageContentHost.createComponent<MessageDialogBaseComponent>(factory);
      componentRef.instance.request = messageDialogData;
      this.isModalVisible = true; 

    });

    this.messageDialogSerivce.onClose.subscribe(closeRequest => {  
      this.isModalVisible = false;
    });
  }

  close(){ 
    this.messageDialogSerivce.close();
  }
}
