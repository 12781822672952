import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { PopupOpenMode, PopupViewMode } from 'src/app/common/enums/enums';

@Component({
  selector: 'app-source-main',
  templateUrl: './source-main.component.html',
  styleUrls: ['./source-main.component.scss']
})
export class SourceMainComponent extends DialogBaseComponent implements OnInit {

  public data: any;
  viewMode : number = 1;
  constructor(private ds:DialogService, private ref1:ChangeDetectorRef,
    private parentCommunicatorService: ParentCommunicatorService) {
    super(ds, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();
    let options = PopupOptions.options.find(t => t.mode == this.data.mode) || PopupOptions.options[0];
    this.data.options = options;
    this.data.options.primaryTabName = (this.data.mode == PopupOpenMode.List) ? "lbl_source_list" : "lbl_select_from_sources";
  
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {   
    this.data.viewMode = mode;
    if (mode == PopupViewMode.GalleryView)
      this.data.editSourceId = 0;
  }
}
