import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PlaceAddressFormatter } from 'src/app/common/helpers/place/place-address-formatter';
import { PlaceBase } from 'src/app/common/models/place/place-base';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { PlaceType, PopupViewMode } from 'src/app/common/enums/enums';
import { fromEvent, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { PlaceInputService } from './place-input.service';
@Component({
  selector: 'app-place-input',
  templateUrl: './place-input.component.html',
  styleUrls: ['./place-input.component.scss']
})
export class PlaceInputComponent implements OnInit {
  isPlaceBtnEnabled: boolean = false;
  @Input() inPlace: PlaceBase;
  @Input() title: any;
  @Output() placeChange = new EventEmitter<PlaceBase>();
  @Input()
  set isFieldEnabled(value: boolean) {
    this.isPlaceBtnEnabled = value;
    if (value) {
      this.typeahead.disable()
    } else {
      this.typeahead.enable()
    }
  }

  // Emit the selected place from the popup

  isVisible: boolean = false;
  outsideClickSubscription: Subscription;
  placeList: any;
  inputFieldText: string;
  numberOfSuggestions: number = 3;
  typeahead: FormControl = new FormControl();
  suggestions: any = "";

  constructor(
    private dialogService: DialogService,
    private placeInputService: PlaceInputService) {
  }

  ngOnInit(): void {
    this.getPlaceList();
    this.inputFieldText = this.inPlace?.rawData ?? "";
  }

  getPlace() {
    this.inputFieldText = this.inPlace?.rawData ?? "";
    return this.inputFieldText;
  }

  suggestPlaces() {
    if (this.typeahead.value.length > 3) {
      this.placeList = this.placeInputService.getPlaces();
      this.suggestions = this.placeList
        .filter(c => c.rawData.toLowerCase().startsWith(this.typeahead.value.toLowerCase()))
        .slice(0, this.numberOfSuggestions ?? 5);
      this.isVisible == true;
      this.show();
      let splitedPlace = PlaceAddressFormatter.addressSpliter(this.typeahead.value);
      splitedPlace.id = 0;
      splitedPlace.rawData = this.typeahead.value;
      splitedPlace.type = PlaceType.Official_Dansk; //Set ad default place type
      this.configurePlace(splitedPlace);
    }
    else {
      this.hide();
    }
  }

  getPlaceList() {
    this.placeInputService.loadPlaceList();
  }

  selectPlace(id) {
    this.placeList = this.placeInputService.getPlaces();
    let selectedPlace = this.placeList.find(x => x.id === id);
    this.inputFieldText = selectedPlace.rawData;

    this.configurePlace(selectedPlace);
  }

  addPlace() {
    this.dialogService.setHalf().open("Place",
      {
        id: this.inPlace.id,
        title: this.title, // marriage place for + <name>
        viewMode: (this.inPlace.id <= 0 && (typeof this.inPlace.rawData != 'undefined' && this.inPlace.rawData)) ? PopupViewMode.EditorView : PopupViewMode.GalleryView, // on new place input, open viewMode 2
        reference: {
          Id: this.inPlace.id, // pass it, if 0 no place.
          rawData: this.inPlace.id > 0 ? "" : this.inPlace.rawData // string entered by the input field
        }
      }
    ).subscribe(response => {
      //Check no response when click cancel 
      this.getPlaceList();
      if (response.data) {
        this.configurePlace(response.data);
        this.inputFieldText = this.inPlace.rawData;
        this.placeChange.emit(this.inPlace)
      }
      this.suggestions = "";
    })

    this.isVisible ? this.hide() : this.show();
  }

  onModelChange(event: any) {
    this.placeChange.emit(this.inPlace)
  }

  configurePlace(selectedPlace) {
    this.inPlace.id = selectedPlace?.id ?? null;
    this.inPlace.rawData = selectedPlace?.rawData ?? null;
    this.inPlace.type = selectedPlace?.type ?? null;
    this.inPlace.detail = selectedPlace?.detail ?? null;
    this.inPlace.line2 = selectedPlace?.line2 ?? null;
    this.inPlace.line3 = selectedPlace?.line3 ?? null;
    this.inPlace.line4 = selectedPlace?.line4 ?? null;
    this.inPlace.line5 = selectedPlace?.line5 ?? null;
    this.inPlace.country = selectedPlace?.country ?? null;
    this.inPlace.mediaUrl = selectedPlace?.mediaUrl ?? null;
  }

  toggle($event) {
    this.isVisible ? this.hide() : this.show();
    $event.stopPropagation();   // Important : to stop bubbling events
    $event.preventDefault();
  }

  show() {
    this.isVisible = true;
    this.outsideClickSubscription = fromEvent(document, 'click').pipe(first()).subscribe(event => {
      this.hide();
    })
  }

  hide() {
    this.isVisible = false;
    this.outsideClickSubscription.unsubscribe();
  }

  actionCLick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hide();
  }

  ngOnDestroy(): void {
    if (this.outsideClickSubscription) {
      this.outsideClickSubscription.unsubscribe();
    }
  }
}