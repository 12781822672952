import { FTDate } from '../FTDate';
import { PlaceBase } from "../place/place-base";

export class EventBase {
    id: number;
    type: number;
    name: string;
    date: FTDate;
    placeId: number;

    // Related
    place: PlaceBase;
}