import { PopupOpenMode } from "../../enums/enums";

export class PopupOptions {
    static options = [
        {
            mode: PopupOpenMode.Popup,      
            primaryTabName: "",
            isAddBtn: true,
            isCloseBtn: true,
            isSwitchToParent: true,
        },
        {
            mode: PopupOpenMode.List,              
            primaryTabName: "",
            isAddBtn: false,
            isCloseBtn: true,
            isSwitchToParent: false,
        },

    ]
}