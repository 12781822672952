import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {

  constructor(private apiBaseService: ApiBaseService) { }

  getReports<T>(): Observable<T> {
    return this.apiBaseService.get("/api/report", [], null);
  } 

  createReport(data: any): Observable<any> {   
    return this.apiBaseService.create('/api/report', null, data);
  }

  deleteReport(reportId: string): Observable<any> {
    return this.apiBaseService.delete('/api/report/' + reportId, null, null);
  }

}
