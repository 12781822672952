import { IndividualBaseViewModel } from './../../../../common/models/IndividualViewModel';
import { Individual } from './../../../../common/models/Individual';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { MultiMediaBasicInfo } from 'src/app/common/models/MultiMediaBasicInfo';
import { NestedPopupService } from 'src/app/services/UI/nested-popup.service';
import { MultimediaApiService } from '../../../../services/API/multimedia-api.service';

@Injectable()

export class MultimediamanagementService extends NestedPopupService {

  constructor(private multimediaApiService: MultimediaApiService, private parentCommunicator: ParentCommunicatorService) {
    super();
  }

  getIndividualMultiMediaData<T>(data: any): Observable<T> {
    return this.multimediaApiService.getIndividualMultiMediaData(data);
  }

  uploadIndividualMultiMediaData<T>(data: any): Observable<T> {
    return this.multimediaApiService.uploadIndividualMultiMediaData(data);
  }

  editIndividualMultiMediaData<T>(data: any, id: number): Observable<T> {
    return this.multimediaApiService.editIndividualMultiMediaData(data, id);
  }

  deleteIndividualMultiMediaData(data: any): Observable<any> {
    return this.multimediaApiService.deleteIndividualMultiMediaData(data);
  }

  getMediaReferences<T>(data): Observable<T> {
    return this.multimediaApiService.getMediaReferences(data);
  }
}
