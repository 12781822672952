import { ConnectorType } from "src/app/common/enums/enums";
import { Connector, LineConnector, RectangularConnector } from "src/app/common/models/report/descendantchart/connector";

export class ConnectorFactory {
    static createConnector(type: string, startX: number, startY: number, points: { x: number, y: number }[]): Connector {
        switch (type) {
            case ConnectorType.Line:
                return new LineConnector(startX, startY, points);
            case ConnectorType.Rectangular:
                return new RectangularConnector(startX, startY, points);
            default:
                return new LineConnector(startX, startY, points);
        }
    }
}
