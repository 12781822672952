import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { MapIndividualDetails, MapIndividualsViewModel } from "src/app/common/models/report/mapreport/map-individuals-view-model";
import { MapReportConfigModel } from "src/app/common/models/report/mapreport/map-report-config-model";
import { MapThemeFactory } from "./map-theme-factory";
import { DateHelper } from "src/app/common/helpers/date-helper";
import { map } from "rxjs/operators";
import { MapReportApiService } from "src/app/services/API/map-report-api.service";
import { environment } from "src/environments/environment";
import { AliveStatus } from "src/app/common/enums/enums";

@Injectable({
  providedIn: "root",
})
export class MapReportService {
  
  private themeDataSubject = new BehaviorSubject<any>(null);
  themeData$ = this.themeDataSubject.asObservable();

  private currentThemeIdSubject = new BehaviorSubject<number>(null);
  currentThemeId$ = this.currentThemeIdSubject.asObservable();

  private currentFamilyStatus = new BehaviorSubject<{ count: number, year: number }>(null);
  currentStatus$ = this.currentFamilyStatus.asObservable();

  private selectedYear = new BehaviorSubject<any>(null);
  selectedYear$ = this.selectedYear.asObservable();

  private publishData = new BehaviorSubject<any>(null);
  currentPublishData$ = this.publishData.asObservable();
  
  private zoomModeSubject = new BehaviorSubject<string>(null);
  zoomMode$ = this.zoomModeSubject.asObservable();

  private mapThemeFactory: MapThemeFactory;
  private mapConfig: MapReportConfigModel;

  constructor(
    private httpClient: HttpClient,
    private dateHelper: DateHelper,
    private mapReportApiService:MapReportApiService
  ) {
    this.getMapConfig().subscribe((config) => {
      this.mapConfig = config;
      this.mapConfig.accessToken =environment.GEOCODE.mapboxAccessToken
      this.mapThemeFactory = new MapThemeFactory(this.httpClient, config);
      if(this.mapConfig.mapThemeConfig.defaultId != null)
      {
        this.currentThemeIdSubject.next(this.mapConfig.mapThemeConfig.defaultId);
        this.changeTheme(this.mapConfig.mapThemeConfig.defaultId);
      }
    });
  }

  /**
   * Get themeId from the toolbar component and passing themeId to 
     getTheme method to genarate a theme 
   */
  changeTheme(themeId: number) {
    this.currentThemeIdSubject.next(themeId);
    this.mapThemeFactory.getTheme(themeId).subscribe((data) => {
      this.themeDataSubject.next(data);
    });
  }

  setZoomMode(zoomMode: string){
    this.zoomModeSubject.next(zoomMode) 
  }

  // Read map report config data from mapReportConfig.json file
  getMapConfig(): Observable<MapReportConfigModel> {
    let mapConfigData = `assets/report/mapreport/config/mapReportConfig.json`;
    return this.httpClient.get<MapReportConfigModel>(mapConfigData);
  }

  // Get all individuals data from API and validate mapIndividuals birthdate
  getAllMapIndividuals(): Observable<MapIndividualsViewModel[]> {
    return this.mapReportApiService.getAllMapIndividuals()
      .pipe(
      map((mapIndividualsViewModel) => {
        return this.validateMapIndividuals(mapIndividualsViewModel);
      })
    );
  }

    // Filter the valid map individuals bas on the birth date
  validateMapIndividuals(mapIndividualsViewModel: MapIndividualsViewModel[]): MapIndividualsViewModel[] {
    const validatedMapIndividuals: MapIndividualsViewModel[] = [];
  
    mapIndividualsViewModel.forEach((mapIndividual) => {
      const validatedMapIndividual: MapIndividualsViewModel = { ...mapIndividual };
      validatedMapIndividual.mapIndividualDetails = mapIndividual.mapIndividualDetails.filter((mapIndividualDetail) => {
        const birthDateValid = this.dateHelper.validateAndFormat(mapIndividualDetail.birthDate);
        const deathDateValid = this.dateHelper.validateAndFormat(mapIndividualDetail.deathDate);
        mapIndividualDetail.currentAliveStatus = AliveStatus.Unknown;

        if (deathDateValid.genealogyDate?.year != null && deathDateValid.genealogyDate?.year != undefined) {
          mapIndividualDetail.deathYear = deathDateValid.genealogyDate.year;
        }
        if (deathDateValid.genealogyDateRange?.from.year != null && deathDateValid.genealogyDateRange?.from.year != undefined) {
          mapIndividualDetail.deathYear = deathDateValid.genealogyDateRange.from.year;
        }
        if (birthDateValid.genealogyDate?.year != null && birthDateValid.genealogyDate?.year != undefined) {
          mapIndividualDetail.birthYear = birthDateValid.genealogyDate.year;
          return birthDateValid.genealogyDate.isValidDate = true;
        }
        if (birthDateValid.genealogyDateRange?.from.year != null && birthDateValid.genealogyDateRange?.from.year != undefined) {
          mapIndividualDetail.birthYear = birthDateValid.genealogyDateRange.from.year;
          return birthDateValid.genealogyDateRange.from.isValidDate = true;
        }
      });

      if (validatedMapIndividual.mapIndividualDetails.length > 0) {
        validatedMapIndividuals.push(validatedMapIndividual);
      }
    });
    return validatedMapIndividuals;
  }

  /**
   * Get start year for time bar. 
   * TODO: Getting hard-coded year for now
   */ 
  getStartYear(): Promise<number> {
    const configUrl = 'assets/report/mapreport/config/mapReportConfig.json';
    return this.httpClient.get<MapReportConfigModel>(configUrl)
    .pipe(map(configData => configData.startYear)).toPromise();
  }

  // Returns the year with the highest number of births from the given list of individuals 
  getHighestYear(mapIndividualsList: MapIndividualsViewModel[]): number {
    const birthYearCounts: { [year: number]: number } = {};
  
    // Count the number of births for each year
    mapIndividualsList.forEach((mapIndividuals: MapIndividualsViewModel) => {
      mapIndividuals.mapIndividualDetails.forEach((individual: MapIndividualDetails) => {
        if (individual.birthYear) {
          birthYearCounts[individual.birthYear] = (birthYearCounts[individual.birthYear] || 0) + 1;
        }
      });
    });

    // Find the year with the highest number of births
    let highestYear: number | undefined;
    let highestCount = 0;

    for (const year in birthYearCounts) {
      if (birthYearCounts.hasOwnProperty(year)) {
        const count = birthYearCounts[year];
        if (count > highestCount) {
          highestYear = parseInt(year, 10);
          highestCount = count;
        }
      }
    }
    return highestYear || 0;
  }

  setFamilyStatus(status: { count: number, year: number }): void {
    this.currentFamilyStatus.next(status);
  }

  setSelectedYear(year: number): void {
    this.selectedYear.next(year);
  }

  setPublishData(data: string): void { 
    this.publishData.next(data);
  }

  clearPublishData(): void {
    this.publishData.next(null);
  }
}