import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction, DropMenuType } from 'src/app/common/enums/enums';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { MediaGalleryItem } from '../models/media-gallery-item';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';

@Component({
  selector: 'app-media-gallery',  
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit {
  
  @Input() items        : MediaGalleryItem[] = [];
  @Input() searchPhrase : string = "";
  @Input() isStandAlone: boolean;
  @Output() action = new EventEmitter<{action: DropMenuAction, reference: any}>();
  
  isMultiSelect : boolean = false;
  selectedItem : MediaGalleryItem = new MediaGalleryItem();
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";
  
  constructor() {
    
   }

  ngOnInit(): void {
   
  }
  
  getSortedMediaItems(){ 
    // Todo: commented sorting function by PO request
    // return this.items.filter( i => i.caption.toLowerCase().includes(this.searchPhrase.toLowerCase())).sort(this.compareSelected);
    //Implement search tag function (optional use if require)
    if ( !this.items ) return [];
    return this.items.filter( i => i?.caption?.toLowerCase().includes(this.searchPhrase.toLowerCase()) || (i?.searchTag?.toLowerCase() ?? "").includes(this.searchPhrase.toLowerCase())).sort((a,b)=> b.caption.localeCompare(a.caption));
  }

  compareSelected(a:MediaGalleryItem, b:MediaGalleryItem){
    if ( a.isSelected && !b.isSelected ) return -1;
    if ( !a.isSelected && b.isSelected ) return 1;
    return 0;
  }


  selectItem(item:MediaGalleryItem){
    if (this.selectedItem.reference != item.reference){
      //clear selection
      this.items.map( i => i.isSelected = false);
    }
    this.selectedItem = item;
    item.isSelected = !item.isSelected;    
  }
  
  clickedAction($event){
    console.log($event);
    this.action.emit($event);
  }

  //use when item loading error
  onItemError(event: any, selected: any) {
    event.target.src = this.errorImg;
    selected.dropMenu.menuItems.forEach(element => {      
      if (element.action == DropMenuAction.Colorize)
        element.isEnabled = false;
    });
  }
  
}
