<svg>
    <!-- This label shows when there is data -->
    <g *ngIf="label.status == 'active'" [attr.id]="label.displayIndex" transform="translate(0,-75)">

            <!-- Silent renewal -->
        <g *ngIf="i%2 == 0" transform="translate(-45,50) scale(0.45 0.45)" class="custom-cursor-context-menu no-pointer-events">  
                <image height="115" width="115" href="assets/tree-editor/level_6/leave_6_bottom_color.svg" />
        </g> 

        <g *ngIf="i%2 == 1" transform="translate(-45,70) scale(0.45 0.45)" class="custom-cursor-context-menu no-pointer-events">  
                <image height="115" width="115" href="assets/tree-editor/level_6/leave_6_top_color.svg" />
        </g> 

        <image transform="scale(1,0.8)" *ngIf="label.gender == 'M'" x="0" y="86" height="36" width="210" href="assets/tree-editor/level_6/lbl_level_6_male.svg" />
        <image transform="scale(1,0.8)" *ngIf="label.gender == 'F'" x="0" y="86" height="36" width="210" href="assets/tree-editor/level_6/lbl_level_6_female.svg" />
        <image transform="scale(1,0.8)" *ngIf="label.gender == 'U'" x="0" y="86" height="36" width="210" href="assets/tree-editor/level_6/lbl_level_6_unknown.svg" />
        <text *ngIf="label.gender == 'M'" transform="translate(20,88)"
        class='element-over-hand text-male male-color' (click)=changeRootPerson(label)>
          {{splitDisplayName[0]?.name}}</text>
        <text *ngIf="label.gender == 'F'" transform="translate(20,88)"
        class='element-over-hand text-female female-color' (click)=changeRootPerson(label)>
         {{splitDisplayName[0]?.name}}</text>
        <text *ngIf="label.gender == 'U'" transform="translate(20,88)"
        class='element-over-hand text-unknown unknown-color' (click)=changeRootPerson(label)>
         {{splitDisplayName[0]?.name}}</text>
        <!-- If there are more data in the branch continuous button shows -->
        <g *ngIf="label.motherId > -1 || label.fatherId > -1">
            <image x="220" y="75" height="18" width="18" href="assets/tree-editor/level_6/btn_continue.svg" />          
        </g>
    </g>

    <!-- This label shows when there is no data(Grey out label) -->     
    <g *ngIf="label.status === 'disable'" [attr.id]="label.displayIndex" transform="translate(0,-75)" class="element-over-hand" (click)="addParent(i, label)">
         <!-- Disabled Leaves -->
         <g *ngIf="i%2 == 0" transform="translate(-45,50) scale(0.45 0.45)" class="custom-cursor-context-menu no-pointer-events">  
            <image height="115" width="115" href="assets/tree-editor/level_6/leave_6_bottom_grey.svg" />
        </g> 

        <g *ngIf="i%2 == 1" transform="translate(-45,70) scale(0.45 0.45)" class="custom-cursor-context-menu no-pointer-events">  
            <image height="115" width="115" href="assets/tree-editor/level_6/leave_6_top_grey.svg" />
        </g> 
        <image transform="scale(1,0.8)" x="0" y="86" height="36" width="210" href="assets/tree-editor/level_6/lbl_level_6_disable.svg" class="custom-cursor-context-menu no-pointer-events"/>
        <g>
        <text transform="translate(20,88)" fill="#c1c2bd" font-family="Arial" font-size="14">+</text>                                    
        <text *ngIf="i%2 == 0" class="text-disable" transform="translate(40,88)">{{"lbl_add_mother" | translate}}</text>
        <text *ngIf="i%2 !== 0" class="text-disable" transform="translate(40,88)">{{"lbl_add_father" | translate}}</text>
        </g>
    </g>  
</svg>>
