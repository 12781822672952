<div class="event-carousel-content" style="width: 225px;">
    <!-- side bar -->
    <div>
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-left.png" style="display: block;cursor: pointer;" (click) = "slideLeft()"/>
    </div>  
    <div style="width: 100%;display: flex;flex-direction: column;padding: 0px 5px 0px 5px;max-width: 160px;gap:5px">
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"name" | translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px!important;"> {{!currentWitnessRecord.name ? '-': currentWitnessRecord.name}}</span>     
        </div>
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_address" | translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important;"> {{!currentWitnessRecord.address? '-': currentWitnessRecord.address}}</span>     
        </div>
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_note"| translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1"  style="margin-top: 2px !important; -webkit-line-clamp: 3 !important;"> {{!currentWitnessRecord.note? '-': currentWitnessRecord.note}}</span>     
        </div>
    </div>
     <!-- side bar -->
     <div>
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-right.png" style="display: block;cursor: pointer;" (click) = "slideRight()"/>
    </div>
</div>
