import { Injectable } from '@angular/core';
import { IndividualViewBase } from './individual/individual-view-base';

@Injectable({
    providedIn: 'root'
})

export class RootFamily {
    public id : number; 
    public husband : IndividualViewBase; 
    public wife : IndividualViewBase; 
    public husbandId : number; 
    public wifeId : number; 
    public displayName : string;
}