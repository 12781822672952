import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyProjectMainComponent } from './family-project-main/family-project-main.component';
import { FormsModule } from '@angular/forms';
import { MediaGalleryModule } from '../common/media-gallery/media-gallery.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FamilyProjectOptionMainComponent } from './family-project-option-main/family-project-option-main.component';
import { FamilyProjectExportOptionComponent } from './family-project-export-option/family-project-export-option.component';
import { ViewProjectDetailsOptionComponent } from './view-project-details-option/view-project-details-option.component';
import { AddNewFamilyProjectComponent } from './add-new-family-project/add-new-family-project.component';
import { AddNewProjectMainComponent } from './add-new-project-main/add-new-project-main.component';
import { ArchiveLogListComponent } from './archive-log-list/archive-log-list.component';
import { FamilyProjectImportOptionComponent } from './family-project-import-option/family-project-import-option.component';

@NgModule({
  declarations: [FamilyProjectMainComponent,FamilyProjectOptionMainComponent, FamilyProjectExportOptionComponent, ViewProjectDetailsOptionComponent, ArchiveLogListComponent, AddNewFamilyProjectComponent, AddNewProjectMainComponent, FamilyProjectImportOptionComponent],
  imports: [
    CommonModule,
    FormsModule,
    MediaGalleryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })    
  ],
  exports:[ FamilyProjectMainComponent,FamilyProjectOptionMainComponent,AddNewProjectMainComponent ],
  entryComponents:[FamilyProjectOptionMainComponent,AddNewProjectMainComponent]
})
export class FamilyprojectmanagerModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}