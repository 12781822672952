import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubheaderModule } from '../subheader/subheader.module';
import { TranslateModule } from '@ngx-translate/core';
import { ExpireMessageComponent } from './expire-message/expire-message.component';



@NgModule({
  declarations: [DashboardComponent, ExpireMessageComponent],
  imports: [
    CommonModule,
    SubheaderModule,
    TranslateModule.forRoot()
  ],
  exports : [DashboardComponent]
})
export class DashboardModule { }
