import { Component, OnInit } from '@angular/core';
import { TooltipContentBaseComponent } from '../../../tooltip/tooltip-content-base/tooltip-content-base.component';

@Component({
  selector: 'app-date-tooltip',
  templateUrl: './date-tooltip.component.html',
  styleUrls: ['./date-tooltip.component.scss']
})
export class DateTooltipComponent extends TooltipContentBaseComponent implements OnInit {
  
  public data: any;
  ngOnInit(): void {
  }

}
