import { Injectable } from '@angular/core';
import Dexie from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  private readonly dbName = 'EmblaFTDatabase';
  private db: Dexie;

  constructor() {
    this.db = new Dexie(this.dbName);
    this.db.version(1);
    this.openConnection();
  }

  private async openConnection(): Promise<void> {
    const exists = await Dexie.exists(this.dbName);
    if (!exists) {
      await this.db.open();
    } 
  }
}
