export class BasicNode{
    id: number;
    parentId: number;
  
    constructor(id, parentId) {
        this.id       = id;
        this.parentId = parentId;
    }

}

