import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LinkRequest } from 'src/app/common/models/LinkRequest';
import { ApiBaseService } from '../api-base.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceAPIService {

  constructor(private apiBaseService: ApiBaseService) { }

  getPlaceList<T>(size: number, page: number): Observable<T> {
    return this.apiBaseService.get("/api/place", [], null);
  }

  // Todo: this is the old implementation, refer to the new one below. 
  // Need to remove after implementing the new version. (Renamed the place name)
  getPlaceById<T>(placeId: number): Observable<T> {
    return this.apiBaseService.get("/api/place/"+placeId, [], null);
  }

  getPlaceReferences<T>(placeId: number): Observable<T> {
    return this.apiBaseService.get("/api/place/" + placeId + "/references", [], null);
  }

  getReferenceToPlace(placeId: number): Observable<any> {
    return this.apiBaseService.get("/api/place/" + placeId + "/events", [], null);
  }

  // Todo: this is the old implementation, refer to the new one below. 
  // Need to remove after implementing the new version. (Renamed the place name)
  deletePlaceData(placeId: number): Observable<any> {
    return this.apiBaseService.delete('/api/place/' + placeId, null, placeId);
  }

  deletePlace(placeId: number): Observable<any> {
    return this.apiBaseService.delete('/api/place/' + placeId, null, placeId);
  }

  updatePlaceData(placeId: number, data: any): Observable<any> {
    return this.apiBaseService.update('/api/place/' + placeId, null, data);

  }

  // New update place api, need to remove updatePlaceData
  updatePlace(data: any): Observable<any> {
    return this.apiBaseService.update('/api/place/', null, data);
  }

  // Todo: this is the old implementation, refer to the new one below. 
  // Need to remove after implementing the new version. (Renamed the place name)
  createplaceData(data: any): Observable<any> {
    return this.apiBaseService.create('/api/place', null, data);
  }

  createPlace(data: any): Observable<any> {
    return this.apiBaseService.create('/api/place', null, data);
  }


  unlinkePlaceImage(placeId: number, imageId: number): Observable<any> {
    return this.apiBaseService.delete('/api/place/' + placeId + '/' + imageId, null, imageId);
  }

  public updatePlaceMultimediaLink<T>(linkRequest: LinkRequest, placeId: number): Observable<T> {
    return this.apiBaseService.update('/api/place/' + placeId + '/multimedia', null, linkRequest); // add url to config file
  }
}
