<div  [class.opacity-hidden]="isHidden" 
      [class.tooltip-top]="isTop()" 
      [class.tooltip-left]="isLeft()" 
      [class.tooltip-right]="isRight()" 
      [class.tooltip-bottom]="isBottom()" 
      [style.top.px]="top" 
      [style.left.px]="left" 
      id="tooltip" 
      class="tooltip-base" [ngClass]="'tooltip-'+color" (click)="stopPropagation($event)">  
    <div class="content" >        
      <template #content>
        
      </template>
    </div>
    <div class="close"> 
      <img (click)="hide()" [src]="'/assets/images/icons/close-'+getInvertColorCode()+'.svg'" width="15" height="15" />
    </div>   
  </div>  