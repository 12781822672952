<div style="transition: z-index 0.3s linear ;position: absolute;width: 100%;height:100vh;"
    [style.z-index]="isModalVisible ? 1500 : -10">
    <div style="position: absolute; width: 100%;height:100%;background-color:grey;transition: opacity 0.3s linear ;"
        [style.opacity]="isModalVisible ? 0.5 : 0" (click)="closePopup()">        
    </div>
    
    <!-- load popup components -->
    <div class="dialog-container first-dialog" style="z-index: 1501;"        
        [class.active-dialog]=" getActiveLevel() == 0 "
        [style.width]="initialWidth"
        [style.right]="levelVisibility[0] ? '0%' : '-100%'">
        <template #content1></template>
    </div>
    <div class="dialog-container" style="z-index: 1502"
        [class.active-dialog]=" getActiveLevel() == 1 "
        [style.width]="'calc('+initialWidth+ ' - 40px)' "
        [style.right]="levelVisibility[1] ? '0%' : '-100%'">
        <template #content2></template>
    </div>
    <div class="dialog-container" style="z-index: 1503"
        [class.active-dialog]=" getActiveLevel() == 2 "
        [style.width]="'calc('+initialWidth+ ' - 80px)' "
        [style.right]="levelVisibility[2] ? '0%' : '-100%'">
        <template #content3></template>
    </div>
    <div class="dialog-container" style="z-index: 1504"
        [class.active-dialog]=" getActiveLevel() == 3 "
        [style.width]="'calc('+initialWidth+ ' - 120px)' "
        [style.right]="levelVisibility[3]? '0%' : '-100%'">
        <template #content4></template>
    </div>
    <div class="dialog-container" style="z-index: 1505"
        [class.active-dialog]=" getActiveLevel() == 4 "
        [style.width]="'calc('+initialWidth+ ' - 160px)' "
        [style.right]="levelVisibility[4] ? '0%' : '-100%'">
        <template #content5></template>
    </div>
</div>
