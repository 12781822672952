<div class="ft-form">
    <div class="content">
                    <div class="ft-form-row">
                        <span class="title">{{ title }}</span>
                    </div>
                    <div style="padding-right: 0">
                        <div class="ft-form-row col1-v100">
                            <div class="ft-form-input">
                                <label>{{ "lbl_given_name" | translate }}</label>
                                <input type="text" [(ngModel)]="individualModel.firstName"
                                    (ngModelChange)="validateForm()" name="GivenName" #GivenName="ngModel" />
                            </div>
                        </div>
                        <div class="ft-form-row col1-v100">
                            <div class="ft-form-input">
                                <label>{{ "lbl_last_name" | translate }}</label>
                                <input type="text" [(ngModel)]="individualModel.lastName"
                                    (ngModelChange)="validateForm()" name="LastName" #LastName="ngModel" />
                            </div>
                        </div>
                    </div>
                    <div class="ft-form-row col1-v100" style="margin-top: 10px; margin-bottom: 20px">
                        <div class="ft-form-input">
                            <label>{{ "lbl_gender" | translate }}</label>
                            <div style="display: flex; flex-direction: row; gap: 10px">
                                <div class="input-radio-container">
                                    <label>{{ "lbl_male" | translate }}</label>
                                    <input type="radio" name="gender" (change)="setGenderAndProfileImage('M')" />
                                    <span class="radiomark"></span>
                                </div>
                                <div class="input-radio-container">
                                    <label>{{ "lbl_female" | translate }}</label>
                                    <input type="radio" name="gender" (change)="setGenderAndProfileImage('F')" />
                                    <span class="radiomark"></span>
                                </div>
                                <div class="input-radio-container">
                                    <label>{{ "lbl_unknown" | translate }}</label>
                                    <input type="radio" name="gender" (change)="setGenderAndProfileImage('U')"
                                        checked />
                                    <span class="radiomark"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button type="button" [ngClass]="isFormValid? 'ft-btn-inactive active': 'ft-btn-active-not-selected'"
                        [disabled]="!isFormValid" class="ft-btn" (click)="addNewChildToFamily()">{{ "lbl_save" | translate }}</button>
                    <button type="button" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{ "lbl_cancel" | translate }}</button>
                </div>
            </div>