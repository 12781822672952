<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{data.title}}</span>
        </div>
        <div class="ft-form-row col2-vf">
            <div class="border-outline">
                <img [src]="citation?.sourceBase?.mediaUrl ? citation?.sourceBase?.mediaUrl : '/assets/images/icons/source.svg'" style = "width: 125px;" />
            </div>            
            <div class="ft-form-row col1-v100" style="align-items: center;">
                <div class="ft-form-input">
                    <label>{{citation?.sourceBase?.title}}</label>
                </div>
           </div>     
        </div>

        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_quality" | translate}}</label>
                <!-- <input type="text" [(ngModel)]="citation.type" #type="ngModel" name="type"> -->
                <select [(ngModel)]="selectedQuality">
                    <option *ngFor="let item of citationQualities" [ngValue]="item">
                        {{item.name | translate}}</option>
                </select>
            </div>                      
        </div>        
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_book_page" | translate}}</label>
                <input type="text" [(ngModel)]="citation.volume" #volume="ngModel" name="volume">
            </div>                      
        </div>        
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_url" | translate}}</label>
                <input type="text" [(ngModel)]="citation.url" #url="ngModel" name="url">
            </div>                      
        </div>        
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_comment" | translate}}</label>
                <input type="text" [(ngModel)]="citation.comment" #comment="ngModel" name="comment">
            </div>                      
        </div>        
    </div>
    <div class="footer">
        <button class="ft-btn ft-btn-inactive active" (click)="save()">{{"lbl_save" | translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>