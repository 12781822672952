import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintimelineviewComponent } from './maintimelineview/maintimelineview.component';
import { EventsummaryComponent } from '../timeline/eventsummary/eventsummary.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [MaintimelineviewComponent, EventsummaryComponent],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  exports: [
  ],
  entryComponents : [EventsummaryComponent]
})
export class TimelineModule {
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
