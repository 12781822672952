<div class="menu-container">
    <div class="menu-edit-icon-wrap" (click)="toggle($event)">
        <img *ngIf="menu.type <= 1" [src]="'/assets/images/icons/edit-pen-'+ menu.type +'.svg'" [class]="'menu-edit-icon-'+ menu.type" >   
        <img *ngIf="menu.type > 1" [src]="'/assets/images/icons/photo-selected.svg'" [class]="'menu-active-icon-'+ menu.type">   
    </div>
    <div *ngIf="isVisible" [class]="'menu-items-base menu_items-'+ menu.type">
        <div *ngFor="let item of menu.menuItems" >
            <div *ngIf="item.isVisible" class="menu-item" (click)="actionCLick($event, item.action)"  [class.mouse-disabled]="!item.isEnabled">
                <!-- Set disable icons -->
                <img src="/assets/images/drop-menu/action-{{item.action}}.svg" width="25" height="25">
                <div [ngClass]="{'text-disable' : !item.isEnabled}" class="menu_items-text">{{'lbl_' + getMenuName(item.action) | lowercase | translate}}</div>
            </div>
        </div>
    </div>
</div>