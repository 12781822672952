import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }

  dialogs : string[] = [];
  onComplete: Subject<any>[] = [];

  startWidth : string ="43%";
  
  onOpen : Subject<any> = new Subject<any>();
  onClose: Subject<any> = new Subject<any>();

  setHalf(){
    this.startWidth = "43%";
    return this;
  }

  setFull(){
    this.startWidth = "100%";
    return this;
  }

  getWidth(){
    return this.startWidth;
  }
  
  open(name:string, data:any){
    
    var ret = new Subject<any>();    
    this.dialogs.push( name );
    this.onComplete.push( ret );

    this.onOpen.next( {name:name, level:this.dialogs.length - 1, data:data},  );

    return ret;    
  }

  complete(responseInfo:any){

    if ( this.dialogs.length > 0 ){
      
      var name = this.dialogs.pop();
      console.log("Closing ..."+name);
      // Send close request 1st beofre completing !important - Synch issue
      this.onClose.next( {name:name, level:this.dialogs.length });
      // send complete
      var complete = this.onComplete.pop();      
      complete.next(responseInfo);
      complete.complete();
      complete.unsubscribe();      
    }
  }

  close(responseInfo){
    this.complete(responseInfo);    
  }
}
