export class UserModel
{
    UserName: string;
    Password: string;
    EncryptedEmail: string;
    OnlineId: string;
    FileName: string;
    FirstName: string;
    LastName: string;
    CustomerId: string;
    IsFirstTime: boolean;
}