import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageDialogBaseComponent } from '../message-dialog-base/message-dialog-base.component';
import { MessageDialogService } from '../services/message-dialog.service';
import { StripeMessageActions } from 'src/app/common/enums/enums';

@Component({
  selector: 'app-message-prompt',
  templateUrl: './message-prompt.component.html',
  styleUrls: ['./message-prompt.component.scss']
})
export class MessagePromptComponent extends MessageDialogBaseComponent implements OnInit {

  constructor(messageDialogService:MessageDialogService, ref:ChangeDetectorRef) {
    super(messageDialogService, ref);
  }
  
  ngOnInit(): void {
  }

  confirm(result:boolean){      
    this.complete(result);
  }

  getStyle(){   
    if ( this.request.okStyle ){
      return this.request.okStyle;
    }
    return "active"
  }
}
