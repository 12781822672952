import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapReportService } from '../services';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-time-bar',
  templateUrl: './time-bar.component.html',
  styleUrls: ['./time-bar.component.scss']
})
export class TimeBarComponent implements OnInit , OnDestroy{
  public rangeValue                    : number;
  public currentYear                   : number;
  public startYear                     : number;
  public aliveIndividualsCount         : number;
  public aliveIndividualsCountWithYear : string;
  private currentStatusSubscription    : Subscription;
  private rangeElement                 : HTMLInputElement;
  private rangeOutputElement           : HTMLElement;

  constructor(
    private mapReportService : MapReportService,
    private translateHandler : TranslateHandler) {
      this.aliveIndividualsCount = 0;
      this.aliveIndividualsCountWithYear = "";
    }

  ngOnInit() {
    this.currentYear  = new Date().getFullYear();
    this.rangeElement = document.getElementById("range") as HTMLInputElement;
    this.rangeOutputElement = document.querySelector(".range-value");

    fromEvent(this.rangeElement, 'input')
      .pipe(debounceTime(10))
      .subscribe((event: Event) => {
        this.onRangeChange(event);
      });
    
    this.setStartYear();
    this.subscribeToFamilyData();
  }

  ngOnDestroy(): void {
    this.currentStatusSubscription.unsubscribe();
  }

  onRangeChange(event: Event) {
    this.rangeValue = +(event.target as HTMLInputElement).value;
    this.updateRangeValue();
    this.selectedYear(this.rangeValue);
  }

  private updateRangeValue() {
    if(this.aliveIndividualsCount > 1)
    {
      this.aliveIndividualsCountWithYear = this.translateHandler.translate("mapReport.lbl_alive_Individuals_count", 
      [this.aliveIndividualsCount.toString(), this.rangeValue.toString()]);
    } else {
      this.aliveIndividualsCountWithYear = this.translateHandler.translate("mapReport.lbl_alive_Individual_count", 
      [this.aliveIndividualsCount.toString(), this.rangeValue.toString()]);
    }
    const precentage = (this.rangeValue - +this.rangeElement.min) / (+this.rangeElement.max - +this.rangeElement.min);
    const newPosition = precentage * (this.rangeElement.offsetWidth - 20);  // reduse the width of scroller thumb
    this.rangeElement.style.setProperty("--track-color", `linear-gradient(to right, rgb(210,210,210) 0% ${100*precentage}%, rgb(232,232,232) 0%)`);

    // Calculate the minimum and maximum range values based on the desired range
    const minRangeValue = this.startYear + 15;
    const maxRangeValue = this.currentYear - 15;

    //Check if the range value is within the desired range
    if (this.rangeValue >= minRangeValue && this.rangeValue <= maxRangeValue) {
      this.rangeOutputElement.innerHTML = `${this.rangeValue}`;
      this.rangeOutputElement.style.left = `${newPosition + 24 }px`;  // add a margin
    } else {
      // Hide the range value if it's outside the desired range
      this.rangeOutputElement.innerHTML = '';
    }
  }
  
  private subscribeToFamilyData(): void {
    this.currentStatusSubscription = this.mapReportService.currentStatus$.subscribe((data) => {
      if (data) {
        this.aliveIndividualsCount = data.count;
        this.rangeValue = data.year;
        this.updateRangeValue();
      }
    });
  }

  private selectedYear(year: number): void {
    this.mapReportService.setSelectedYear(year);
  }

  // Set start year in timebar
  private setStartYear(): void {
    this.mapReportService.getStartYear().then(year => {
      this.startYear = year;
    });
  }
}