import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../api-base.service';

@Injectable({
  providedIn: 'root'
})
export class MembershipApiService {

  constructor(private apiBaseService:ApiBaseService) { }

  public getMembershipExpiration(customerId:string):Observable<any>{
    return this.apiBaseService.create("/api/membership/"+customerId+"/status", [], null);
  }
}
