import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WitnessMainComponent } from './witness-main/witness-main.component';
import { WitnessEditorComponent } from './witness-editor/witness-editor.component';
import { WitnessesComponent } from './witnesses/witnesses.component';
import { FormsModule } from '@angular/forms';
import { CommonComponentModule } from '../common.module';
import { MediaGalleryModule } from '../media-gallery/media-gallery.module';



@NgModule({
  declarations: [WitnessMainComponent, WitnessEditorComponent, WitnessesComponent],
  imports: [
    CommonModule,
    FormsModule,             
    CommonComponentModule,     
    MediaGalleryModule, 
  ]
})
export class WitnessModule { }
