import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { element } from 'protractor';
import { OriginalSourceType } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { FamilyProjectBaseModel } from 'src/app/common/models/familyproject/family-project-base-model';
import { FamilyProjectRequestModel } from 'src/app/common/models/familyproject/family-project-request-model';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { LoadingService } from 'src/app/services/UI/loading.service';

@Component({
  selector: 'app-add-new-family-project',
  templateUrl: './add-new-family-project.component.html',
  styleUrls: ['./add-new-family-project.component.scss']
})
export class AddNewFamilyProjectComponent implements OnInit {

  @Input() data;
  defaultImage: string;
  familyProject: FamilyProjectRequestModel;
  gender:string;
  isProjectNameValid:boolean=false;
  isNameInvalid: boolean=false;
  isAlreadyExistName: boolean = false;
  isNameEmpty: boolean;
  isFormValid: boolean = false;
  familyProjectList: FamilyProjectBaseModel[] = [];
  IMG_BASE_URL: string = "assets/images/defaults";
 

  constructor(private familyProjectService : FamilyProjectApiService, 
              private loadingService: LoadingService,
              private dialogService: DialogService,
              private projectRefService : ProjectRefService)
  {
    this.defaultImage = this.IMG_BASE_URL+"/unknown.svg";
  }

  ngOnInit(): void {
    this.familyProject= new FamilyProjectRequestModel();
    this.familyProject.individualGender ="U";
  }

  setGenderAndProfileImage(newGender: string) {
    this.familyProject.individualGender=newGender;
    this.defaultImage = newGender == "M" ? this.IMG_BASE_URL+"/male.svg" : newGender == "F" ? this.IMG_BASE_URL+"/female.svg" : this.IMG_BASE_URL+"/unknown.svg";
  }

  validateProjectName(projectName: string){
    this.isAlreadyExistName=false;
    this.isProjectNameValid = false;
    let regex = /^[A-Za-zÆØÅæøå\s0-9]+$/gm
    var regexMatched = projectName.match(regex);
    if(regexMatched){
      this.isNameInvalid=false;
      this.isProjectNameValid = true;
    }
    else{
      this.isNameInvalid=true;
      this.isProjectNameValid = false;
    }

   if(this.data.FamilyProjectList.find(element=>element.projectName==this.familyProject.projectName.trim())){
    this.isAlreadyExistName=true;
    this.isProjectNameValid = false;
   }

   if(!this.isNameInvalid && !this.isAlreadyExistName){
        this.isProjectNameValid = true;
   }
   this.validateForm();
  }

  validateForm(){
    this.isFormValid = false;
    if(this.isProjectNameValid && this.familyProject.individualGivenName != "" && this.familyProject.individualLastName != "" && this.familyProject.individualGivenName != null && this.familyProject.individualLastName != null){
      this.isFormValid = true;

    }
  }

  // Create project from scratch
  addNewFamilyProject(){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    
    this.familyProject.projectName          = this.familyProject.projectName.trim();
    this.familyProject.individualGivenName  = this.familyProject.individualGivenName.trim();
    this.familyProject.individualLastName   = this.familyProject.individualLastName.trim();
    this.familyProject.individualGender     = this.familyProject.individualGender.trim();
    //TODO: individual gender test
    this.familyProject.originalSourceType   = OriginalSourceType.FT10;
    
    this.familyProjectService.createFamilyProject(this.familyProject).subscribe((res:any)=>{      
    }, (err) => {
      throw new CustomError("add new family project error" + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
      this.complete({data : "success"});
      return;
    });
  }

  cancel(){
    this.dialogService.complete({data : null});
    return;
  }

  public complete(data:any){
    this.projectRefService.load().then(()=>{
      this.dialogService.complete(data);
    });
  }
}
