import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentBase } from '../../common/base/component-base';

@Component({
  selector: 'app-maintreeeditorview',
  templateUrl: './maintreeeditorview.component.html',
  styleUrls: ['./maintreeeditorview.component.scss']
})
export class MaintreeeditorviewComponent implements OnInit {
  @Input() editedLabelImage : any;  
  isExpandingWheelOn: boolean = false;
  showComponent: boolean = false;
  
  
  constructor() { 
  }

  ngOnInit(): void {
  }

  public isExpandingWheelActive(value): void {
    this.isExpandingWheelOn = value;
  }

  showMain($event)
  {
    this.showComponent = $event;
  }
}
