import { Component, OnInit } from '@angular/core';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { RootMember } from 'src/app/common/models/RootMember';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { ChildRelationshipType, EditorMode, RootMemberRelation, DropMenuAction, DropMenuType, NotifierEvents } from 'src/app/common/enums/enums';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
import { LeftPanelViewModel } from 'src/app/common/models/left-panel/left-panel-view-model';
import { FamilyViewModel } from 'src/app/common/models/family/family-view-model';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { Subscription } from 'rxjs';
import { DropMenu } from '../../common/drop-menu/models/DropMenu';
import { DropMenuItem } from '../../common/drop-menu/models/DropMenuItem';
import { ArrayHelper } from 'src/app/common/helpers/array-helper';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { DefaultFamilyRequest } from '../../familyeditor/models/family-component-model';
import { ComponentBase } from '../../common/base/component-base';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';

@Component({
    selector: 'app-mainleftpanelview',
    templateUrl: './mainleftpanelview.component.html',
    styleUrls: ['./mainleftpanelview.component.scss']
})
export class MainleftpanelviewComponent extends ComponentBase implements OnInit {

    //Variable Declarations 
    isleftPanelVisibale: boolean = true;
    isSpousePanelExpanded: boolean = false;
    isParentPanelExpanded: boolean = false;
    title: string;
    expectedUpdates = [NotifierEvents.RootMemberChanged,
    NotifierEvents.RootMemberUpdated,
    NotifierEvents.RootMemberDeleted,

    NotifierEvents.RootFamilyUpdated,
    NotifierEvents.DefaultFamilyChanged];

    //Objects Declarations 
    leftPanelData: FamilyViewModel;
    marriedFamilies: FamilyViewModel[] = [];
    parentFamilies: FamilyViewModel[] = [];
    dropMenu: DropMenu;

    rootMemberId: string;

    //Subscriptions Declarations 
    rootMemberSubscription = new Subscription();
    familyIdChangeSubscription = new Subscription();

    constructor(
        public individualApiService: IndividualApiService,
        private loadingService: LoadingService,
        private familyApiService: FamilyApiService,
        private translateHandler: TranslateHandler,
        private messageDialogService: MessageDialogService,
        private arrayHelper: ArrayHelper,
        notifierService: NotifierV2Service) {
        super(notifierService);
    }

    ngOnInit(): void {
        this.expandLeftPanel();
        this.getFamilyWithRelations(this.notifierService.getCurrentRootMemberId());
        this.setTitle(this.notifierService.getCurrentRootMember().DisplayName);
    }

    expandLeftPanel() {
        this.isSpousePanelExpanded = this.marriedFamilies.length > 0;
        this.isParentPanelExpanded = this.parentFamilies.length > 0;
    }

    getFamilyWithRelations(memberID) {
        this.familyApiService.getFamiltyWithRelationsById(memberID).subscribe(
            (response: ResponseModel<LeftPanelViewModel>) => {
                this.marriedFamilies = this.mapMarriedFamilyUI(response.data.marriedFamilies);
                this.parentFamilies = this.mapParentUI(response.data.parentFamilies, memberID);
                this.expandLeftPanel();
            }
        )
    }

    mapParentUI(families: FamilyViewModel[], memberId): FamilyViewModel[] {
        families.forEach(parentFamily => {
            parentFamily.individual.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, ((parentFamily.individual.gender == "F" || parentFamily.spouse.gender == "M") ? RootMemberRelation.Mother : RootMemberRelation.Father));
            parentFamily.spouse.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, ((parentFamily.spouse.gender == "M" || parentFamily.individual.gender == "F") ? RootMemberRelation.Father : RootMemberRelation.Mother));
            //Skip same sibling in the data due to maultiple marriages
            parentFamily.children = parentFamily.children.filter((value, index, self) => self.map(x => x.individual.id).indexOf(value.individual.id) == index);
            parentFamily.children.forEach(siblings => {
                if (siblings.individual.id == memberId) {
                    siblings.individual.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, RootMemberRelation.Self);
                }
                else {
                    siblings.individual.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, (siblings.individual.gender == "M" ? RootMemberRelation.Brother : RootMemberRelation.Sister));
                }
                if (siblings.spouse && siblings.spouse.gender)
                    siblings.spouse.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, (siblings.spouse.gender == "F" ? RootMemberRelation.Sister : RootMemberRelation.Brother));
                if (siblings.relationshipType >= 1) //This property will show only for non bloodline siblings
                    siblings.individual.childRelation = this.translateHandler.translateEnum(ChildRelationshipType, siblings.relationshipType);
            });
        });
        return families;
    }

    mapMarriedFamilyUI(families: FamilyViewModel[]): FamilyViewModel[] {
        families.forEach(marriedFamily => {
            this.setTitle(marriedFamily.individual.displayName);
            marriedFamily.spouse.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, ((marriedFamily.individual.gender == "M" || marriedFamily.spouse.gender == "F") ? RootMemberRelation.Wife : RootMemberRelation.Husband));
            marriedFamily.children.forEach(children => {
                children.individual.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, (children.individual.gender == "M" ? RootMemberRelation.Son : RootMemberRelation.Daughter));
                if (children.spouse && children.spouse.gender)
                    children.spouse.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, ((children.individual.gender == "M" || children.spouse.gender == "F") ? RootMemberRelation.DaughterInLaw : RootMemberRelation.SonInLaw));
                if (children.children && children.children.length > 0) {
                    //Skip same grand children in the data due to maultiple marriages
                    children.children = children.children.filter((value, index, self) => self.map(x => x.individual.id).indexOf(value.individual.id) == index);
                    children.children.forEach(grandChildren => {
                        grandChildren.individual.relationToRootMember = this.translateHandler.translateEnum(RootMemberRelation, (grandChildren.individual.gender == "M" ? RootMemberRelation.GrandSon : RootMemberRelation.GrandDaughter));
                    })
                }
            });

            // Merge and re-arrange the children spouse families under the child if married multiple.
            marriedFamily.children = this.mergeChildrenSpouseFamilies(marriedFamily.children);

        });

        return families;
    }

    mergeChildrenSpouseFamilies(marriedFamily: any) {
        let groupedFamilies = this.arrayHelper.groupBy(marriedFamily, child => child.individual.id);

        //convert map object to array
        let groupedMultipleFamilies = [...groupedFamilies]
        let allMultipleFamilies = [];
        groupedMultipleFamilies.forEach(childFamilyList => {
            let reversed;
            //Re-order families as priority order, in each time a new member comes with a family list 
            let defaultFamily = childFamilyList[1].filter(x => x.isDefault)[0];
            reversed = childFamilyList[1].filter(x => !x.isDefault).reverse();
            if (defaultFamily)
                reversed.unshift(defaultFamily);

            reversed[0].individual.show = true; //Only one member node show when that person has multiple families

            allMultipleFamilies = [...allMultipleFamilies, ...reversed];
        })
        return allMultipleFamilies;
    }

    toggle() {
        this.isleftPanelVisibale = !this.isleftPanelVisibale;
        // Set css varible to fix scroll of main content when side panel on/off
        document.documentElement.style.setProperty('--is-left-panel-on', this.isleftPanelVisibale ? "1" : "0");
    }

    getVisible() {
        return this.isleftPanelVisibale ? 'block' : 'none';
    }
    //change function for bug fix:EM-13800
    setTitle(displayName: string) {
        this.title = displayName;
    }

    getFamilyMemberDetail() {
        let processId = GuidGenerator.generate();
        this.loadingService.show(processId);
        this.individualApiService.getFamilyMemberDetail().
            subscribe(
                (response: ResponseModel<FamilyViewModel>) => {
                    this.leftPanelData = response.data;
                    this.loadingService.hide(processId);
                },
                (err) => {
                    this.loadingService.hide(processId);
                    throw new CustomError("MainleftpanelviewComponent => getFamilyMemberDetail() : " + err, 911, false);
                }
            )
            .add(() => this.loadingService.hide(processId));
    }

    // Change root person
    changeRootMember(member) {
        this.setTitle(member.displayName);
        var rootMember = <RootMember>{ Id: member.id + "", DisplayName: member.displayName, Gender: member.gender };
        this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, rootMember);
    }

    // Change root family
    changeRootFamily(family) {
        //This is commented to avoid using root service
        //this.rootService.setRootFamilyId(family.familyId);        
        let data = {
            id: family.familyId,
            editorMode: EditorMode.FamilyEditor,
            isUpdateHistory: true
        }
        this.notifierService.updateEvent(NotifierEvents.RootFamilyChanged, family)
        this.notifierService.setCurrentEditor("/editor/familyeditor", data, null);
    }

    ngOnDestroy(): void {
        this.rootMemberSubscription.unsubscribe();
        this.familyIdChangeSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    getSpouseFamilyDropMenu(reference: any) {
        this.dropMenu = new DropMenu();
        this.dropMenu.ref = reference.familyId;
        this.dropMenu.type = reference.isDefault ? DropMenuType.Active : DropMenuType.Inactive;
        this.dropMenu.menuItems = !reference.isDefault ? [
            <DropMenuItem>{ action: DropMenuAction.SetAsDefault, isEnabled: true, isVisible: true },
        ] : null;

        return this.dropMenu;
    }

    getParentFamilyDropMenu(reference: any) {
        this.dropMenu = new DropMenu();
        this.dropMenu.ref = reference.familyId;
        this.dropMenu.type = reference.isChildDefault ? DropMenuType.Active : DropMenuType.Inactive;
        this.dropMenu.menuItems = null;
        return this.dropMenu;
    }

    setAsDefultfamily(selectedFamily: FamilyViewModel) {
        this.familyApiService.getExistingDefaultFamilies(selectedFamily.familyId).subscribe(
            (response: any) => {

                if (response.data.husbandHasDefaultFamily || response.data.wifeHasDefaultFamily) {

                    let husband = selectedFamily.individual.gender == "M" ? selectedFamily.individual : selectedFamily.spouse;
                    let wife = selectedFamily.individual.gender == "M" ? selectedFamily.spouse : selectedFamily.individual;
                    let message = "";

                    if (response.data.husbandHasDefaultFamily && response.data.wifeHasDefaultFamily) {
                        message = this.translateHandler.translate('lbl_husband_and_wife_family_already_has_default_family', [husband.displayName, wife.displayName]);
                    }
                    else if (response.data.husbandHasDefaultFamily) {
                        message = this.translateHandler.translate('lbl_husband_or_wife_family_already_has_default_family', [husband.displayName]);
                    }
                    else {
                        message = this.translateHandler.translate('lbl_husband_or_wife_family_already_has_default_family', [wife.displayName]);
                    }

                    this.messageDialogService.openInfo(this.translateHandler.translate("lbl_default_family_exists"), message, "").subscribe((response: any) => {
                        if (response) {
                            this.updateDefaultFamily(selectedFamily.familyId);
                        }
                    },
                        (err) => {
                            throw new CustomError("MainleftpanelviewComponent => setAsDefultfamily() : " + err, 911, false)
                        })
                }
                else {
                    this.updateDefaultFamily(selectedFamily.familyId);
                }

            },
            err => {
                throw new CustomError("MainleftpanelviewComponent => setAsDefultfamily(family: any) : " + err, 911, false);
            }
        )
    }

    updateDefaultFamily(familyId) {
        let processId = GuidGenerator.generate();
        this.loadingService.show(processId);
        let defaultFamilyRequest = new DefaultFamilyRequest();
        defaultFamilyRequest.familyId = familyId;
        this.familyApiService.setAsDefaultFamily(defaultFamilyRequest).subscribe(
            () => {
                this.marriedFamilies.forEach(family => {
                    if (family.familyId === familyId)
                        family.isDefault = true;
                    else
                        family.isDefault = false;
                })
                this.notifierService.updateEvent(NotifierEvents.DefaultFamilyChanged, { id: familyId });
                this.getFamilyWithRelations(this.notifierService.getCurrentRootMemberId());
            },
            (err) => {
                throw new CustomError("MainleftpanelviewComponent => setAsDefultfamily(family: any) : " + err, 911, false);
            }
        )
            .add(() => {
                this.loadingService.hide(processId);
            });
    }

    notify() {
        this.setTitle(this.notifierService.getCurrentRootMember().DisplayName);
        this.getFamilyWithRelations(this.notifierService.getCurrentRootMemberId());
    }
}
