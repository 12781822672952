import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopupOpenMode, PopupViewMode } from 'src/app/common/enums/enums';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';

@Component({
  selector: 'app-multimedia-main',
  templateUrl: './multimedia-main.component.html',
  styleUrls: ['./multimedia-main.component.scss']
})
export class MultimediaMainComponent extends DialogBaseComponent implements OnInit {

  
  //public data: any;   // Removed same name is already there in the parent , why use it here ??
  viewMode : number = 1;
  constructor(private ds:DialogService,
     private ref1:ChangeDetectorRef,
     private parentCommunicatorService: ParentCommunicatorService,
     private trasn : TranslateHandler,
     private notifierService: NotifierV2Service,
     private loadingService: LoadingService,
     private translateHandler: TranslateHandler,
     private messageDialogService: MessageDialogService,) {
    super(ds, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();          
      let options = PopupOptions.options.find(t => t.mode == this.data.mode) || PopupOptions.options[0];
      this.data.options = options;
      this.data.options.primaryTabName = (this.data.mode == PopupOpenMode.List) ?  "lbl_media_list" : "lbl_select_from_gallery";    
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {
    if (this.data.viewMode == 3 && !this.notifierService.canChange()) { //dirty check when change view mode from colorizer tab
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      var message = this.translateHandler.translate("cnf_cancel_without_saving_colorized_photo");
      this.loadingService.hide(processId);
      this.messageDialogService.openUnSavedChangeConfirmation(message).subscribe((res: boolean) => {
        if (res) {
          this.data.viewMode = mode;
          if (mode == 1)
            this.data.editMediaId = 0;
        }
      });
    } else {
      this.data.viewMode = mode;
      if (mode == 1)
        this.data.editMediaId = 0;
    }
  }
}