import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputComponnentComponent } from './input/date-input-componnent/date-input-componnent.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FormsModule } from '@angular/forms';
import { TypeAHeadComponent } from './type-a-head/type-a-head.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { EventDetailNoteComponent } from './events/event-tips/event-detail-note/event-detail-note.component';
import { EventDetailSourceComponent } from './events/event-tips/event-detail-source/event-detail-source.component';
import { EventDetailMediaComponent } from './events/event-tips/event-detail-media/event-detail-media.component';
import { EventDetailWitnessComponent } from './events/event-tips/event-detail-witness/event-detail-witness.component';
import { ImageCarouselTempComponent } from './image-carousel-temp/image-carousel-temp.component';
import { DropMenuComponent } from './drop-menu/drop-menu.component';
import { TooltipMainComponent } from './tooltip/tooltip-main/tooltip-main.component';
import { TooltipTestContentComponent } from './tooltip/tooltip-test-content/tooltip-test-content.component';
import { TooltipContentBaseComponent } from './tooltip/tooltip-content-base/tooltip-content-base.component';
import { DateInputTempComponent } from './input/date-input-temp/date-input-temp.component';
import { DateTooltipComponent } from './input/date-input-temp/date-tooltip/date-tooltip.component';
import { IndividualModule } from './individual/individual.module';
import { DialogModule } from './dialog/dialog.module';
import { DropMenuModule } from './drop-menu/drop-menu.module';
import { PlaceInputComponent } from './input/place-input/place-input.component';
import { ImageCarouselTempModule } from './image-carousel-temp/image-carousel-temp.module';
import { MediaCarouselModule } from './media-carousel/media-carousel.module';
import { HomeComponent } from './home/home.component';
import { ReportPublishOptionComponent } from './report-publish-option/report-publish-option.component';
import { LoadingComponent } from './loading/loading.component';


@NgModule({
    declarations: [
      DateInputComponnentComponent, 
      TypeAHeadComponent, 
      ImageCarouselComponent,
      TooltipMainComponent, 
      TooltipTestContentComponent,
      TooltipComponent, 
      TooltipContentBaseComponent, 
      TooltipMainComponent, 
      DateInputTempComponent, 
      DateTooltipComponent,
      PlaceInputComponent,
      HomeComponent,
      ReportPublishOptionComponent,
      LoadingComponent],
  exports: [
      DateInputComponnentComponent, 
      TypeAHeadComponent, 
      ImageCarouselComponent,              
      TooltipComponent, 
      TooltipMainComponent, 
      TooltipContentBaseComponent, 
      DateInputTempComponent,        
      DateTooltipComponent,
      PlaceInputComponent,      
      HomeComponent,
      ReportPublishOptionComponent,
      IndividualModule,
      DialogModule,
      DropMenuModule,
      LoadingComponent
  ],
  imports: [ReactiveFormsModule,
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    IndividualModule,
    DialogModule,
    DropMenuModule, 
    ImageCarouselTempModule,
    MediaCarouselModule   
  ],
  entryComponents: [
    ReportPublishOptionComponent
  ]
})
export class CommonComponentModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}