import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArtistictreeComponent } from "./artistictree/artistictree.component";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import {ReportengineModule} from '../../reportengine/reportengine.module'
import { ReportOptionsPopupComponent } from "../common/reportOptions/reportOptionsPopup/report-options-popup.component";



@NgModule({
  declarations: [ArtistictreeComponent ],
  imports: [
    ReportengineModule,
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })],
    exports:[],
    entryComponents:[ArtistictreeComponent,ReportOptionsPopupComponent]
})
export class ArtisticTreeModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
