import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainmultimediaComponent } from './mainmultimedia/mainmultimedia.component';
import { MultimediauploadComponent } from './multimediaupload/multimediaupload.component';
import { LazyloadingModule } from '../lazyloading/lazyloading.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragDirective } from 'src/app/common/directives/dnd/dragDrop.directive';
import { ImageCropComponentComponent } from './image-crop-component/image-crop-component.component';
import { CommonComponentModule } from '../common.module';
import { MultimediaMainComponent } from './multimedia-main/multimedia-main.component';
import { MultimediasComponent } from './multimedias/multimedias.component';
import { MultimediaEditorComponent } from './multimedia-editor/multimedia-editor.component';
import { MediaGalleryModule } from '../media-gallery/media-gallery.module';
import { MediaInputComponent } from './media-input/media-input.component';
import { ImageColorizerComponent } from './image-colorizer/image-colorizer.component';


@NgModule({
  exports:[MainmultimediaComponent],
    declarations: [MainmultimediaComponent, MultimediauploadComponent, DragDirective, ImageCropComponentComponent, MultimediaMainComponent, MultimediasComponent, MultimediaEditorComponent, MediaInputComponent, ImageColorizerComponent],
  imports: [
    CommonModule, 
    LazyloadingModule, 
    FormsModule, 
    CommonComponentModule, 
    MediaGalleryModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class MultiMediaModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
