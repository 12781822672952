import { Injectable, EventEmitter } from '@angular/core';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { CustomApiParameters } from 'src/app/common/helpers/custom-api-parameters';
import { Observable, Subject } from 'rxjs';
import { FamilyMember } from 'src/app/common/models/FamilyMember';
import { ResponseModel } from 'src/app/common/models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class IndividualmanagementService {
  openInputBox = new EventEmitter();

  constructor(private apiBaseService: ApiBaseService, private customApiParameters: CustomApiParameters) { }

  private individualSubject = new Subject<any>();

  sendMessage(CreatedIndividual) {
    this.individualSubject.next(CreatedIndividual);
  }

  clearMessages() {
    this.individualSubject.next();
  }

  onMessage(): Observable<any> {
    return this.individualSubject.asObservable();
  }

  createIndividualFamilyMember<T>(newMember: any): Observable<T> {
    return this.apiBaseService.create('/api/individual', null, newMember); // add url to config file
  }

  updateIndividualFamilyMember<T>(updatedMember: any): Observable<T> {
    return this.apiBaseService.update('/api/individual', null, updatedMember); // add url to config file
  }

}
