import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nodeOptionMenu',
  templateUrl: './nodeoptionmenu.component.html',
  styleUrls: ['./nodeoptionmenu.component.scss']
})
export class NodeOptionMenuComponent implements OnInit {

  @Input() commonUrl: string;
  @Output() rootMemberChange = new EventEmitter<boolean>();
  @Output() openIndividualEditor = new EventEmitter<boolean>();
  
  changeRMUrl: string;
  editUrl: string;

  ngOnInit(): void { 
    this.changeRMUrl = this.commonUrl + 'images/startfromhere.svg';
    this.editUrl = this.commonUrl + 'images/edit.svg';
  }

  rootMemberChangeBtnClicked() {
    this.rootMemberChange.emit(true);
  }

  openIndividualEditorBtnClicked() {
    this.openIndividualEditor.emit(true);
  }

}
