import { ReportFont } from "src/app/components/reports/common/reportfont";
import { ChartGraphics } from "src/app/components/reports/descendantchart/chart/chart-graphics";


export class ChartBox {
    private level: number;
    private baseURL: string;
    private nodeX: number;
    private nodeY: number;
    private parentPos: any;
    private font: ReportFont;
    private configs: any;

    constructor(level, baseURL, nodeX, nodeY, parentPos, font, configs) {
        this.level = level;
        this.baseURL = baseURL;
        this.nodeX = nodeX;
        this.nodeY = nodeY;
        this.parentPos = parentPos;
        this.font = font;
        this.configs = configs;
    }

    generate(item) {

        var id = item.id;
        var parentId = item.parentId;
        var parentFamilyId = item?.data?.parentFamilyId;
        var box = null;
        var nameText = null;
        var placeText = null;
        var periodText = null;
        var image = null;

        box = ChartGraphics.getBoxImage(this.nodeX, this.nodeY, this.baseURL, 1, this.configs.data.box);
        nameText = ChartGraphics.getText(this.nodeX, this.nodeY, item.data.name, 1, this.font, 1, this.configs.data.nameText);
        placeText = ChartGraphics.getText(this.nodeX, this.nodeY, item.data.place, 1, this.font, 1, this.configs.data.placeText);
        periodText = ChartGraphics.getText(this.nodeX, this.nodeY, item.data.period, 1, this.font, 1, this.configs.data.periodText);
        image = ChartGraphics.getCircleMaskImage(this.nodeX, this.nodeY, item.data.profileURL, item.data.gender, this.configs.data.image, this.baseURL);

        return {
            "id": id,
            "parentId": parentId,
            "parentFamilyId": parentFamilyId,
            "box": box,
            "nameText": nameText,
            "placeText": placeText,
            "periodText": periodText,
            "image": image
        }
    }
}