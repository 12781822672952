import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiRequest, CustomApiParams } from "../common/models/ApiRequest";
import { ParentCommunicatorService } from "./parent-communicator.service";
import { ParentMessageType } from "../common/models/ParentMessage";
import { SessionDataMapper } from "../common/mappers/SessionDataMapper";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ApiBaseService {
  //Constant variables
  private apiDomain = environment.API_URL_CORE;
  private headersObject = {};

  //Subscription variables
  private subscribeInitialPageLoad;
  private subscriptionRootMemberId;

  //member variables
  private onlineId;
  private fileName;
  private rootMemberId;
  private token = "";
  //private ProjectId: '' //to be used

  //subjects to subscribe in report specific services
  sessionDataSubject = new BehaviorSubject("");

  constructor(
    protected httpClient: HttpClient,
    private parentCommunicatorService: ParentCommunicatorService,
    private sessionDataMapper: SessionDataMapper,
    private authenticationService: AuthenticationService
  ) {
    this.subscribeMethods();
  }

  subscribeMethods() {
    this.subscribeInitialPageLoad = this.parentCommunicatorService.receive(
      ParentMessageType.StartApplication,
      (pageloadData) => {
        let parentObj = this.sessionDataMapper.map(pageloadData);
        if (parentObj.rootMemberId > 0) {
          this.fileName = parentObj.fileName;
          this.onlineId = parentObj.OnlineId;
          this.token = parentObj.token;
          this.rootMemberId = parentObj.rootMemberId;

          // send session data
          this.sessionDataSubject.next(
            JSON.stringify({
              firstName: parentObj.FirstName,
              lastName: parentObj.LastName,
              fileName: this.fileName,
              onlineId: this.onlineId,
              rootMemberId: this.rootMemberId,
            })
          );
        }
      }
    );
    this.subscriptionRootMemberId = this.parentCommunicatorService.receive(
      ParentMessageType.RootMemberChanged,
      (rootMember) => {
        if (rootMember) {
          this.rootMemberId = rootMember.Id;
        }
      }
    );
  }

  private getHttpParams<T>(apiRequest: T): HttpParams {
    const params = Object.getOwnPropertyNames(apiRequest).reduce(
      (p, key) => p.set(key, apiRequest[key]),
      new HttpParams()
    );
    return params;
  }

  private getApiRequestparams<T>(
    customApiParams: CustomApiParams[]
  ): ApiRequest {
    let apiRequest = {} as ApiRequest;

    if (customApiParams) {
      customApiParams.forEach((element) => {
        apiRequest[element.Type] = element.Data;
      });
    }
    return apiRequest;
  }

  private getHttpOptions(customParams: CustomApiParams[]) {
    this.headersObject = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return {
      params: this.getHttpParams(this.getApiRequestparams(customParams)),
      headers: this.headersObject,
    };
  }

  public create<T>(
    url: string,
    customApiParams: CustomApiParams[],
    requestModel: any
  ): Observable<T> {
    let options = this.getHttpOptions(customApiParams);
    return this.httpClient.post<T>(this.apiDomain + url, requestModel, options);
  }

  public update<T>(
    url: string,
    customApiParams: CustomApiParams[],
    requestModel: any
  ): Observable<T> {
    let options = this.getHttpOptions(customApiParams);
    return this.httpClient.put<T>(this.apiDomain + url, requestModel, options);
  }

  public get<T>(
    url: string,
    customApiParams: CustomApiParams[],
    memberID: number,
    httpOptions?: object
  ): Observable<T> {
    if (memberID) this.rootMemberId = memberID; //Member ID will replace tbe RootMemberId if memberId passed
    if (httpOptions == undefined) {
      let options = this.getHttpOptions(customApiParams);
      return this.httpClient.get<T>(this.apiDomain + url, options);
    }
    return this.httpClient.get<T>(this.apiDomain + url, httpOptions);
  }

  // Todo: This has to be removed once the root service is implemented;
  public getRootMemberId() {
    return this.rootMemberId;
  }

  public delete(url: string, customApiParams: CustomApiParams[], id: number) {
    let options = this.getHttpOptions(customApiParams);
    return this.httpClient.delete(this.apiDomain + url, options);
  }

  ngOnDestroy(): void {
    this.parentCommunicatorService.close(
      ParentMessageType.RootMemberChanged,
      this.subscriptionRootMemberId
    );
    this.parentCommunicatorService.close(
      ParentMessageType.StartApplication,
      this.subscribeInitialPageLoad
    );
  }
}
