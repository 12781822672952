import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FamilyProjectArchiveBaseModel } from 'src/app/common/models/familyProjectArchive/family-project-archive-base-model';
import { FamilyProjectArchiveApiService } from 'src/app/services/API/family-project-archive-api.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';
import { ChangeDetectorRef } from '@angular/core';
import { FamilyProjectBaseModel } from 'src/app/common/models/familyproject/family-project-base-model';
import { FamilyArchiveActions, FamilyArchiveStatus } from 'src/app/common/enums/enums';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';


@Component({
  selector: 'app-family-project-export-option',
  templateUrl: './family-project-export-option.component.html',
  styleUrls: ['./family-project-export-option.component.scss']
})
export class FamilyProjectExportOptionComponent implements OnInit {

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  projectName: String;
  hasFormValueChanged: boolean;
  familyArchive: FamilyProjectArchiveBaseModel;
  familyArchiveLog: FamilyProjectArchiveBaseModel;
  selectedExportType: string = "";
  selectedFamilyProject: FamilyProjectBaseModel;
  familyProjectList: FamilyProjectBaseModel[] = [];
  isProjectListEnabled = false;
  logTitle: string = "";
  isFormDirty = false;

  constructor(  private archiveService: FamilyProjectArchiveApiService,
                private loadingService: LoadingService,
                private translateHandler: TranslateHandler,
                private dialogService:DialogService
              ) {}

  ngOnInit(): void {
    this.selectedFamilyProject = this.data.FamilyProject;
    this.familyProjectList = this.data.FamilyProjectList;
    this.isProjectListEnabled = this.data.isProjectListEnabled;
    this.logTitle = this.translateHandler.translate("lbl_last_export_log_title");
  }

  fillLastArchive(){
    this.isFormDirty = false;
    this.familyArchiveLog = new FamilyProjectArchiveBaseModel();
    this.logTitle = this.translateHandler.translate("lbl_last_export_log_title");
    if (this.selectedFamilyProject.familyProjectId != "" && this.selectedExportType != "") {  
      this.familyArchiveLog = this.selectedFamilyProject.familyProjectArchiveExport ?? new FamilyProjectArchiveBaseModel();
      this.logTitle = this.translateHandler.translate("lbl_last_export_log_" + this.selectedExportType.toLowerCase());
      this.isFormDirty = true;
    }
  }

  export() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);

    this.familyArchive = new FamilyProjectArchiveBaseModel();
    this.familyArchive.familyProjectId = this.data.FamilyProject.familyProjectId; 
    this.familyArchive.type = this.selectedExportType;
    this.familyArchive.fileName = this.data.FamilyProject.projectName;
    this.familyArchive.action = FamilyArchiveActions.Export;
    this.familyArchive.projectLog = null;
    this.familyArchive.status = FamilyArchiveStatus.Pending;

    let formData = new FormData();
    formData.append('uploadedFile', new Blob(), null);
    formData.append('fileArchiveInfo', JSON.stringify(this.familyArchive));
   

    this.archiveService.createFamilyProjectArchive(formData).subscribe((res: any) => {
    }, (err) => {
      throw new CustomError("family project exporting error" + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
      this.dialogService.complete({ data: "success" });
    });
  }

  cancel() {
    this.dialogService.complete({ data: null });
  }
}
