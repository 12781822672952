import { TreeNode } from '../../common/reportAlgorithm/tree-node';
import { ArtisticTreeNode } from './artistic-tree-node';
/**
 * Base artistic tree implementation interface
 */
export abstract class ArtisticTreeBase{
    implConfig;
    reportBase;
    rootNode;
    commonUrl;
    constructor(rootNode:TreeNode<ArtisticTreeNode>, config, reportBase, commonUrl){
        this.rootNode = rootNode;
        this.implConfig = config;
        this.reportBase  = reportBase;
        this.commonUrl = commonUrl;
    }

    // Execute and return a node list
    abstract getNodes() 

    // method to get UI Nodes
    abstract getUiNodes(nodeList)

    // method to get UI Branches
    abstract getBranches()
}