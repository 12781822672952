<div class="map-report-main-container">
  <app-subheader-main></app-subheader-main>
  <div class="map-report-sub-container">
    <div *ngIf="mapReportConfigModel" class="map-report-toolbar">
      <app-map-report-tool-bar [mapReportConfigData]="mapReportConfigModel"></app-map-report-tool-bar>
    </div>
    <div *ngIf="mapReportConfigModel" class="map-container">
      <app-map-container [mapReportConfigData]="mapReportConfigModel"></app-map-container>
    </div>
    <div *ngIf="mapReportConfigModel" class="map-time-bar">
      <app-time-bar></app-time-bar>
    </div>
  </div>
</div>
