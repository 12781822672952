import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SourceCitationAPIService {
    constructor(private apiBaseService: ApiBaseService) { }

    getSourceCitationById<T>(sourceCitationId: number): Observable<T> {
        return this.apiBaseService.get("/api/sourcecitation/"+sourceCitationId, [], null);
    }
    
}