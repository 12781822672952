import { IndividualViewModel } from 'src/app/common/models/IndividualViewModel';
import { IndividualReportViewModel } from '../../models/individual-report-view-model';

export class SunChartSectorData {
  id: number;
  firstName : string;
  lastName : string;
  gender : string;
  displayName: string;
  dateRangeStr: string;
  profileImage: string;
  profileImageId: number;
  parentFamilyId : number;

  constructor(individualMember : IndividualReportViewModel) {
    this.id = individualMember.id;
    this.firstName = individualMember.givenName;
    this.lastName = individualMember.surname;
    this.displayName = individualMember.displayName;
    this.dateRangeStr = individualMember.dateRangeStr;
    this.profileImageId = individualMember.profileImageId;
    this.profileImage = individualMember.profileImageUrl;
    this.gender = individualMember.gender;  
    this.parentFamilyId = individualMember.parentFamilyId;
  }

  // acctual data is set by the report
  static data = [];



  //Keep this structure to understand data pattern
  // static data = [ [new SunChartSectorData("Stein Inge Haaland", "1896 - 1977", "profilepic.png")],
  //                 [new SunChartSectorData("Stein Inge Haaland", "1896 - 1977", "profilepic.png"), new SunChartSectorData("Stein Inge Haaland 1,1", "1896 - 1977", "profilepic.png")],
  //    [new SunChartSectorData("Stein Inge Haaland", "1896 - 1977", "profilepic.png"), null, new SunChartSectorData("Stein Inge Haaland 2,2", "1896 - 1977", "profilepic.png"), new SunChartSectorData("Stein Inge Haaland 2,3", "1896 - 1977", "profilepic.png") ],
  // ]          // [new SunChartSectorData("Stein Inge Haaland", "1896 - 1977", "profilepic.png"), null,null,null,null,null,null,null ] ,
  // [new SunChartSectorData("Stein Inge Haaland", "1896 - 1977", "profilepic.png"), null,null,null,null,null,null,null, null,null,null,null,null,null,null, null ],
  // [new SunChartSectorData("Stein Inge Haaland", "1896 - 1977", "profilepic.png"),null,null,null,null,null,null, null,null,null,null,null,null,null, null, null,null,null,null,null,null,null,null, null,null,null,null,null,null,null, null, null]   ]

  static get(levelId, sectorId) {
    if (!SunChartSectorData.data[levelId]) {
      return null;
    }
    return SunChartSectorData.data[levelId][sectorId];
  }


  static isLevelEmpty(levelId) {
    var levelData = SunChartSectorData.data[levelId];
    if (levelData == null) return true;

    var nullCount = 0;
    for (var x = 0; x < levelData.length; x++) {
      if (levelData[x] == null) nullCount++;
    }
    if (nullCount == levelData.length) return true;
    return false;
  }
// return true if the node is a leaf 
  static isLeafNode(x, y) {

    // if (SunChartSectorData.get(x, y)) {
    // current node is null
    if (SunChartSectorData.get(x, y).displayName === undefined) {

      // check if parent node has a valid id , and not a function node
      if (SunChartSectorData.get(x - 1, Math.floor(y / 2)).id === -1 || SunChartSectorData.get(x - 1, Math.floor(y / 2)).id === 0) {
          // if it is a function node return false        
        return false
      } else {
        // if it has a valid id return true        
        return true
      }

    }
    else {      
      return false
    }
    // }

  }

}

export class SunChartFunSector extends SunChartSectorData {
  fnType: number;
  constructor(individualMember : IndividualReportViewModel, fnType) {
    super(individualMember);
    this.fnType = fnType;
  }
}
