
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PedigreeDataNode, PedigreeNode } from './predigree-node';
import { TreeNode } from './tree-node';
import { TreeHelper } from './tree-helper';
import { PedigreeBox } from './pedigree-box';
import { BaseComponent } from '../../BaseReport/base/base.component';
import { PedigreeService } from './pedigree.service';
import { ReportFont } from '../../common/reportfont';
import { MultimediaPopupInputInfo } from 'src/app/common/models/PopupInputInfo';
import { first } from 'rxjs/operators';
import { MultimediaPopupResponse } from 'src/app/common/models/PopupResponse';
import { CropFrameMode, EditorMode, MultimediaPopupOpenMode, MultimediaTypes, NotifierEvents, PopupResponseEvents } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { RootMember } from 'src/app/common/models/RootMember';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { LinkRequest } from 'src/app/common/models/LinkRequest';
import { MultimediamanagementService } from 'src/app/components/common/multimedia/services/multimediamanagement.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { RootMemberMapper } from 'src/app/common/mappers/RootMemberMapper';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { ReportOptions } from '../../common/reportOptions/reportOptions';
import { ReportFieldOption } from '../../common/reportOptions/reportFieldOption';
import { Subscription } from 'rxjs';
import { ChildBase } from 'src/app/common/models/child/child-base';
import { MessageDialogService } from 'src/app/components/common/message-dialog/services/message-dialog.service';


@Component({
  selector: 'app-pedigree',
  templateUrl: './pedigree.component.html',
  styleUrls: ['./pedigree.component.scss']
})
export class PedigreeComponent extends BaseComponent implements OnInit, OnDestroy {
  timeTag: string = "";
  refreshParent: any;
  multimediaSubscription: any;
  reportOptions: ReportOptions;

  expectedUpdates = [NotifierEvents.RootMemberChanged];
  private changeRootMemberSubscription: Subscription;

  constructor(
    private pedigreeHostElement         : ElementRef,
    private pedigreeService             : PedigreeService,
    private loadingService              : LoadingService,
    private multimediaManagementService : MultimediamanagementService,
    protected translateHandler          : TranslateHandler,
    private rootMemberMapper            : RootMemberMapper,
    protected notifierService           : NotifierV2Service,
    protected individualApiService      : IndividualApiService,
    protected messageDalogService       : MessageDialogService,
    protected dialogService             : DialogService,
  ) {
    super(pedigreeHostElement, dialogService, notifierService,translateHandler,messageDalogService,individualApiService);
  }

  gridUnit;
  gridXCount;
  gridYCount;

  chartData;
  chartConnectorBase;
  chartConnectors;
  chartboxes;
  chartText;

  isDebug = false;
  isGrid = false;
  font;

  reportURL;
  defaultAncestorLevels = 6;
  list = []
  pedigreeTitle1: string;
  pedigreeTitle2: string;
  defaultHeight = 0;

  titleSegments;
  reportTitleX: number;
  reportTitleY: number;
  reportTitleAnchor: string;
  reportTitleAlignmentBaseline: string;

  ngOnInit() {
    if (this.config) {
      this.chartBasics = {
        name: this.config.name,
        assetUrl: this.commonUrl,
        title1: "",
        title2: "",
      };
      // subscription will run when the session data is passed to the iframe
      this.rootMember = this.notifierService.getCurrentRootMember();
      this.initialzePedigreeReportData(this.defaultAncestorLevels, null);

      this.changeRootMemberSubscription = this.rootMemberChanges$.subscribe((result: boolean) => {
        if (result) {
          this.initialzePedigreeReportData(this.defaultAncestorLevels);
        }
      });
    }
  }

  notify() {
    this.hideTooltip();
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.initialzePedigreeReportData(this.defaultAncestorLevels, null);
  }

  initReportOptions() {
    this.reportOptions = new ReportOptions();
    this.reportOptions.reportName = "lbl_pedigree";
    this.reportOptions.fieldOptions.push(new ReportFieldOption("title", "title", "input", [], this.reportTitle));
    this.reportOptions.fieldOptions.push(new ReportFieldOption("generations", "algorithm", "combobox", ["1", "2", "3", "4", "5", "6", "7"], this.defaultAncestorLevels));
  }

  onOptionsValueChanged(changedData: ReportOptions) {
    this.mapConfig(changedData);
    this.defaultAncestorLevels = this.config.options.generations;
    this.initialzePedigreeReportData(this.defaultAncestorLevels, this.config.options.title);
  }

  ngOnDestroy(): void {
    this.hideTooltip();
    if (this.changeRootMemberSubscription) {
      this.changeRootMemberSubscription.unsubscribe();
    }
  }

  initialzePedigreeReportData(genarations, customTitle = null) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    
    this.individualApiService
    .getAllAscendantsList(genarations, this.rootMember.Id)
    // this.pedigreeservice
    //   .getAscendantsList(genarations, this.rootMember.Id)
      .subscribe(
        (response: any) => {
        
          var listData = []
          this.list = response.data
          listData.push(new PedigreeDataNode(this.list[0].id, 0, { "name": this.list[0].displayName, "place": this.list[0].birthPlace, "period": this.list[0].dateRangeStr, "gender": this.list[0].gender, "profileURL": this.list[0].profileImage,"parentFamilyId":this.list[0].parentFamilyId }));
          for (let index = 1; index < this.list.length; index++) {
            const element = this.list[index];            
            listData.push(new PedigreeDataNode(element.id, element.parentId, { "name": element.displayName, "place": element.birthPlace, "period": element.dateRangeStr, "gender": element.gender, "profileURL": element.profileImage,"parentFamilyId":element.parentFamilyId }));
          }
          this.reportTitle = customTitle == null ? this.translateHandler.translate("pedigree_report.lbl_title", [this.rootMember.DisplayName]) : customTitle;
          this.defaultHeight = this.config.levels[this.defaultAncestorLevels - 1].reportHeight;
          this.reportInit(listData);
          this.reportZoomToExtend();
          this.initReportOptions();
        }).add(() => {
          this.loadingService.hide(processId);
        })
  }

  reportInit(data) {
    //TODO : Change to report URL from engine
    this.reportURL = this.commonUrl//`/assets/${this.config.name}`;

    this.chartData = [];
    this.chartConnectorBase = [];
    this.chartConnectors = [];
    this.chartboxes = [];
    this.chartText = [];

    TreeHelper.init(this.config.levels);

    this.font = new ReportFont();

    this.font.init(this.config.font);

    this.drawTree(data);
    this.showReport = true
  }


  drawTree(data) {
    var tdata = PedigreeNode.generateSampleTree(data);

    tdata.children = PedigreeNode.getChildFunctionNodes(tdata);
    tdata.children = PedigreeNode.getChildBinaryNodes(tdata, 0);

    var data;
    var rootNode;
    // Fixed tree
    if (this.config.treeType === "FixedBinary") {
      // generate for the whole tree
      rootNode = PedigreeNode.generateBinaryTree(6);
      // fill data based on availability
    }

    rootNode = tdata;
    // Fill 

    // Node calculations
    TreeHelper.initializeNodes(rootNode, 0);
    TreeHelper.calculateInitalX(rootNode);
    TreeHelper.checkAllChildrenOnScreen(rootNode);
    TreeHelper.calculateFinalPositions(rootNode, 0);

    var nodeList = [];
    this.createNodeList(nodeList, rootNode, null, 0);


    var mode = this.config.mode;
    this.setupSvg(mode, nodeList);
    this.formatReportTitle();
    this.transformChartData(mode, nodeList);

    //this.createChartData(mode, nodeList);
    this.createBaseData(nodeList);
    this.createDebugConnectorData(nodeList, mode);
  }

  createNodeList(nodeList, node: TreeNode<PedigreeNode>, parent, childIndex) {
    var me = { "id": node.item.id, "x": node.x, "y": node.y, "item": node.item, "parent": parent, "childIndex": childIndex };
    node.children.forEach((child, index) => {
      this.createNodeList(nodeList, child, me, index);
    });
    nodeList.push(me);
  }

  setupSvg(mode, nodeList) {
    var minX, minY;
    var maxX, maxY;
    var xvalues = nodeList.map(i => i.x);
    var yvalues = nodeList.map(i => i.y);
    minX = Math.min(...xvalues);
    minY = Math.min(...yvalues);
    maxX = Math.max(...xvalues);
    maxY = Math.max(...yvalues);
    var stats = { "minX": minX, "minY": minY, "maxX": maxX, "maxY": maxY };

    switch (mode) {
      case 2: // flip y
      case 0: // normal x and y
        this.config.svg.w = stats.maxX + this.config.svg.moveX * 2;
        //this.config.svg.h = stats.maxY*this.config.levelDistance + this.config.svg.moveY*2;
        this.config.svg.h = this.config.levels[stats.maxY].levelDistance + this.config.svg.moveY * 2;
        break;
      case 3: // flip x        
      case 1: // normal x and y
        this.config.svg.h = stats.maxX + this.config.svg.moveY * 2;
        this.config.svg.w = this.config.levels[stats.maxY].levelDistance + this.config.svg.moveX * 2;
        break;
    }

    this.gridUnit = 10;
    this.gridXCount = Math.floor(this.config.svg.h / this.gridUnit);
    this.gridYCount = Math.floor(this.config.svg.w / this.gridUnit);

  }

  transformChartData(mode, nodeList) {
    var x, y, w, h = 0;
    nodeList.forEach(node => {
      switch (mode) {
        case 0: // normal x and y
          x = node.x;
          //y = node.y*this.config.levelDistance;
          y = this.config.levels[node.y].levelDistance;
          break;
        case 1: //  x and y interchanged
          y = node.x;
          //x = node.y*this.config.levelDistance;          
          x = this.config.levels[node.y].levelDistance;
          break;
        case 2: // normal x and y
          x = (node.x);
          y = this.config.svg.h - (this.config.levels[node.y].levelDistance) - this.config.svg.moveY * 2;
          break;
        case 3: // normal x and y
          y = node.x;
          x = this.config.svg.w - (this.config.levels[node.y].levelDistance) - this.config.svg.moveX * 2;
          break;
      }
      node.w = this.config.levels[node.y].w;
      node.h = this.config.levels[node.y].h;
      node.tx = x;
      node.ty = y;
    });
  }



  createBaseData(nodeList) {
    nodeList.forEach(node => {
      this.chartData.push({ "x": node.tx, "y": node.ty, "w": node.w, "h": node.h });

      var parentPos = {};
      if (node.parent) {
        parentPos = { x: node.parent.tx, y: node.parent.ty };
      }
      var element = new PedigreeBox(this.config.mode, node.y, this.reportURL, node.childIndex, node.tx, node.ty, parentPos, this.font, this.config.levels[node.y],this.translateHandler);
      var graphicElement = element.generate(node.item);
      if (graphicElement != null) {
        this.chartboxes.push(graphicElement);
      }
    });
    this.chartboxes = this.chartboxes.filter(item => !item.connector || item.connector.level < this.defaultAncestorLevels);
  }

  createDebugConnectorData(nodeList, mode) {
    var modeAngles = [90, 0, -90, 180];

    nodeList.forEach(node => {
      if (node.parent) {
        if (node.data instanceof PedigreeDataNode) {
          this.chartConnectorBase.push({ "x1": node.tx, "y1": node.ty, "x2": node.parent.tx, "y2": node.parent.ty });
          //this.chartConnectors.push({"level" : node.y, "transform": "translate("+node.parent.tx+" "+node.parent.ty+") rotate("+modeAngles[mode]+" 0 0)"});
        }
      }
    });
  }

  createRootMember(data: any): RootMember {
    data.id = data.id ?? data.rootMemberId
    let newRootMember = this.rootMemberMapper.map(data);
    return newRootMember;
  }

  async clickNode(eventXPosition: number, eventYPosition: number, id:number, childId:number) {
    if (id > 999999) {
      const child = this.chartboxes.find(data => data.id === childId);
      const parent = this.chartboxes.find(data => data.id === id);
      const parentGender = parent.text1.text === this.translateHandler.translate("lbl_add_mother") ? "F" : "M";
      let childObj = new ChildBase();
      childObj = {
        id:child.id,
        name:child.text1.text,
        familyId:child.parentFamilyId
      };
      try {
        const response = await this.addParent(parentGender, childObj);
        if (response) {
          if (response?.isMemberNavigate) {
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, response.parent);
            this.openEditor(response.parent.id, EditorMode.IndividualEditor);
          } else {
            this.initialzePedigreeReportData(this.defaultAncestorLevels, null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      let selectedMember = this.list.find(t => t.id == id);
      this.rootMember = this.createRootMember(selectedMember);
      this.openToolTip(eventXPosition, eventYPosition, id);
    }
  }

  /**
  *  Open multimedia panel for change profile image
  */
  openMultiMedia(id) {
    let memberInfo = this.list.find((data) => data.id == id)

    let popupInputData = new MultimediaPopupInputInfo();
    popupInputData.level = 0;
    popupInputData.Title = this.translateHandler.translate('lbl_profile_picture_of', [memberInfo.displayName]);
    popupInputData.Mode = MultimediaPopupOpenMode.List;
    popupInputData.FilterMode = [MultimediaTypes.PHOTO];
    popupInputData.CropFrameMode = CropFrameMode.Circle;
    popupInputData.SelectedImageIds = popupInputData.SetImageIds(memberInfo.profileImageId);
    popupInputData.PopUpTitle = "my_media";
    popupInputData.AcceptedMultimediaTypes = [MultimediaTypes.PHOTO];

    // Opens the multimedia panels and selection of the media or cancel steps
    this.multimediaSubscription = this.multimediaManagementService.openPopup(popupInputData)
      .pipe(first())
      .subscribe((selectedImages: MultimediaPopupResponse) => {

        if (selectedImages.Status == PopupResponseEvents.Error) {
          throw new CustomError("Image url is invalid", 611, false);
        }
        //Update user profile image if new imaege "selected"
        if ((selectedImages.Status == PopupResponseEvents.Selected ||
          selectedImages.Status == PopupResponseEvents.DeSelected) && selectedImages.Data) {
          if (selectedImages.Status == PopupResponseEvents.DeSelected) {
            selectedImages.Data[0].mediaUrl = null;
            selectedImages.Data[0].id = null;
          }
          let linkRequest = new LinkRequest;
          linkRequest.TargetId = selectedImages.Status === PopupResponseEvents.Selected ? selectedImages.Data[0].id : null;

          let processId = GuidGenerator.generate();
          this.loadingService.show(processId);
          this.pedigreeService.updateMultimediaLink(linkRequest, memberInfo.id).subscribe(
            () => {
              this.timeTag = "&timeTag=" + new Date().getTime(); // used to avoid caching
              memberInfo.profileImage = selectedImages.Data[0].mediaUrl;
              memberInfo.profileImageId = selectedImages.Data[0].id;
              this.initialzePedigreeReportData(this.defaultAncestorLevels, null);
              // if (selectedImages.reload) {
              //   this.refreshParent.emit(selectedImages.reload);
              // }

              this.loadingService.hide(processId);
            },
            (err) => {
              this.loadingService.hide(processId);
              throw new CustomError("MainlabelsComponent => OpenMultiMedia() : " + err, 911, false);
            }
          )
        }
        else {
          if (selectedImages.Status === PopupResponseEvents.Cancelled && selectedImages.reload) {
            this.timeTag = "&timeTag=" + new Date().getTime(); // used to avoid caching
            this.refreshParent.emit(selectedImages.reload);
          }
        }
      },
        (err) => {
          throw new CustomError("MainlabelsComponent => OpenMemberEditor() : " + err, 911, false);
        }
      )
  }

  formatReportTitle() {
    // Align the title
    const POSITION = {
      CENTER: "center",
      LEFT: "left",
      RIGHT: "right",
      MIDDLE: "middle",
      TOP: "top",
      BOTTOM: "bottom",
      START: "start",
      END: "end",
      HANGING: "hanging",
      BASELINE: "baseline",
    };

    switch (this.config.reportTitle.horizontalPosition) {
      case POSITION.CENTER:
        this.reportTitleAnchor = POSITION.MIDDLE;
        this.reportTitleX =
          this.config.svg.w / 2 + this.config.reportTitle.offsetx;
        break;
      case POSITION.LEFT:
        this.reportTitleAnchor = POSITION.START;
        this.reportTitleX = this.config.reportTitle.offsetx;
        break;
      case POSITION.RIGHT:
        this.reportTitleAnchor = POSITION.END;
        this.reportTitleX = this.config.svg.w + this.config.reportTitle.offsetx;
        break;
      default:
        this.reportTitleAnchor = POSITION.START;
        this.reportTitleX = this.config.reportTitle.offsetx;
        break;
    }

    switch (this.config.reportTitle.verticalPosition) {
      case POSITION.MIDDLE:
        this.reportTitleY =
          this.config.svg.h / 2 + this.config.reportTitle.offsety;
        this.reportTitleAlignmentBaseline = POSITION.MIDDLE;
        break;
      case POSITION.TOP:
        this.reportTitleY = this.config.reportTitle.offsety;
        this.reportTitleAlignmentBaseline = POSITION.HANGING;
        break;
      case POSITION.BOTTOM:
        this.reportTitleY = this.config.svg.h + this.config.reportTitle.offsety;
        this.reportTitleAlignmentBaseline = POSITION.BASELINE;
        break;
      default:
        this.reportTitleY = this.config.reportTitle.offsety;
        this.reportTitleAlignmentBaseline = POSITION.HANGING;
        break;
    }

    // Split the title into segments  
    let prevYPosition = this.reportTitleY;
    this.titleSegments = this.font
      .getString(
        this.reportTitle,
        this.config.reportTitle.fontSize,
        this.config.reportTitle.maxLength,
        this.config.reportTitle.maxLines,
        true
      )
      .map((segment) => {
        let element = {
          x: this.reportTitleX,
          y: prevYPosition,
          text: segment,
        };
        prevYPosition += this.config.reportTitle.lineSpacing;
        return element;
      });
  }

}
