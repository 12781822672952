enum BranchType{
    "middle",
    "left",
    "right"  
}

export class BranchHelper {
    branchUrl;
    branchConfig;
    constructor(branchUrl, branchConfig){
        this.branchUrl = branchUrl;
        this.branchConfig = branchConfig; 
    }

    calculateBranches(node, branchList){
        for (let i = 0; i < node.children.length; i++) 
        {
            let branchType     = this.getBranchType(node,i)      
            let branchHeight   = this.getBranchHeight(node.x, node.y, node.children[i].x, node.children[i].y)
            let branchWidth    = this.getBranchWidth(this.branchConfig.defaultBranchWidth, node.level)
            let branchAngle    = this.getAngle(node.x, node.y, node.children[i].x, node.children[i].y, branchType)
            let branchImageUrl = this.getBranchImage(node.level, BranchType[branchType]);
            branchList.push({ "x": node.children[i].x -  branchWidth/2 , "y": node.children[i].y, "angle": branchAngle, "image": branchImageUrl , "height":branchHeight, "width":branchWidth})
            this.calculateBranches(node.children[i], branchList);
        }
    }

    // return type of the branch (left, right or middle)
    private  getBranchType(node , currentIndex){
        if(node.children.length%2==1){
            if(currentIndex == Math.floor(node.children.length/2)){
                return BranchType.middle 
            }
        }
        if (currentIndex< node.children.length/2){
            return BranchType.left
        }
        else if(currentIndex >= node.children.length/2){
             return BranchType.right
        }
    }

    // get distance between two points
    private getBranchHeight(x1,y1,x2,y2){
        let distance = Math.sqrt((x1-x2)**2 + (y1-y2)**2)
        return distance
    }

    // get branch width acording to the levels
    private getBranchWidth(defaultBranchWidth, level){
        let width = defaultBranchWidth * (0.9)**level
        return width
    }

    // get the inverted angle when the two points and branch type is given
    private  getAngle(x1,y1,x2,y2, type){
        let branchAngle;
        if ((x1 -x2) ==0){
            if(y2-y1>0){
                branchAngle =180
            }else{
                branchAngle =0
            }
        }
        else{
            branchAngle = Math.atan((y1 -y2)/(x1 -x2)) * 180 / Math.PI

            if(type == BranchType.middle || type ==BranchType.left){
                if (x2 > x1){
                    branchAngle = branchAngle +90}
               else{
                    branchAngle = branchAngle -90
                } 
            }
            else if (type == BranchType.right){
                if(x1 > x2){
                    branchAngle = branchAngle -90
                }
                else{
                    branchAngle =branchAngle + 90
                }
            }
        }
        return {"angle":branchAngle, "x": x2,  "y":y2}   
    }

    //Return branch images according to the levels and branchType
    private getBranchImage(level, branchType) 
    {
        let branchesImageList = this.branchConfig.levels[level][branchType]; 
        let image = branchesImageList[Math.floor(Math.random()*branchesImageList.length)];
        return this.branchUrl  + `/level${level}/${branchType}/${image}` ;
    }

}

