import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropMenuModule } from '../drop-menu/drop-menu.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MediaCarouselComponent } from './media-carousel/media-carousel.component';

@NgModule({
  declarations: [MediaCarouselComponent],
  imports: [
    CommonModule,
    DropMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })  
  ],
  exports:[MediaCarouselComponent]
})
export class MediaCarouselModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}