import { Injectable } from '@angular/core';
import { ParentMessage, ParentMessageType } from '../common/models/ParentMessage';
import { ErrorData } from '../common/models/ErrorData';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParentCommunicatorService {

  public errData = new ErrorData();
  private events: any = {};
  private isLeftPanelHide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    /*
    let self = this;// Self reference required 
    self.bindEvent(window, 'message', function (e) {
      if (e.data.type) {
        self.publish(e.data.type, e.data.data);//Publish whatever receive from Parent   
      }
    });
    */
  }

  private bindEvent(element, eventName, eventHandler) {
    /*
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent('on' + eventName, eventHandler);
    }
    */
  }

  public receive(eventName: ParentMessageType, fn) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
    return this.events[eventName]
  }

  public close(eventName: ParentMessageType, fn) {
    if (this.events[eventName]) {
      for (var i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      };
    }
  }

  private publish(eventName: ParentMessageType, data) {
    /*
    if (this.events[eventName]) {
      this.events[eventName].forEach(function (fn) {
        fn(data);
      });
    }
    */
  }

  public send(type: ParentMessageType, data) {
    let parentMessage = new ParentMessage();
    parentMessage.Type = type;
    parentMessage.Data = data;
    // console.log('ParentMessage', parentMessage);
    //window.parent.postMessage(parentMessage, '*');
  }

  public leftPanelHide(value: boolean) {
    this.isLeftPanelHide.next(value);
  }

  get getLeftPanelHideObs(): Observable<boolean> {
    return this.isLeftPanelHide.asObservable();
  }
}
