import { IndividualBase } from './individual-base';
import { EventBase } from 'src/app/common/models/event/event-base';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';

export class IndividualComponentModel extends IndividualBase  {
    birthEvent: EventBase;
    deathEvent: EventBase;
    multimedia: Multimedia[];
}
    
