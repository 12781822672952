<!-- <div class="progress-loader" [hidden]="!isloading"> -->
<div class="progress-loader" [hidden]="true">
    <div class="loading-spinner">
        <div class="ajax-spinner-bars">
            <div class="bar-1"></div>
            <div class="bar-2"></div>
            <div class="bar-3"></div>
            <div class="bar-4"></div>
            <div class="bar-5"></div>
            <div class="bar-6"></div>
            <div class="bar-7"></div>
            <div class="bar-8"></div>
            <div class="bar-9"></div>
            <div class="bar-10"></div>
            <div class="bar-11"></div>
            <div class="bar-12"></div>
            <div class="bar-13"></div>
            <div class="bar-14"></div>
            <div class="bar-15"></div>
            <div class="bar-16"></div>
        </div>
    </div>
</div>