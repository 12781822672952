import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  HostListener
} from "@angular/core";

import { DynamicReportDirective } from "src/app/components/reportengine/directives/dynamic-report.directive";
import { ReportEngineService } from "../services/report-engine-service.service";
import { ReportTranslation } from "src/app/common/models/ReportTranslation";
import { ReportBase } from "src/app/common/models/ReportBase";
import { ActivatedRoute, Router } from "@angular/router";
import { AlignmentType } from "src/app/common/enums/alignmentType";
import Theme from "src/app/common/models/Theme";
import ReportConfig from "src/app/common/models/ReportConfig";
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GlobalerrorService } from 'src/app/services/globalerror.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { DialogService } from "src/app/services/UI/dialog.service";
import { ReportPublishOptions, ReportType } from 'src/app/common/enums/enums';
import { MessageDialogService } from "../../common/message-dialog/services/message-dialog.service";

@Component({
  selector: "app-mainreportengineview",
  viewProviders: [DynamicReportDirective],
  templateUrl: "./mainreportengineview.component.html",
  styleUrls: ["./mainreportengineview.component.scss"],
})
export class MainreportengineviewComponent implements OnInit {
  //select ng-template from this directive
  @ViewChild(DynamicReportDirective, { static: true })
  dynamicReportView: DynamicReportDirective;

  //Variable Declaration
  private currentZoomPercentage: number;
  currentZoomLevel: any;
  centerButtonPrefix: string;
  zoomButtonPrefix: string;
  svgViewableArea: any;
  reportArea: any;
  reportName: string;
  isCenterEnabled: boolean;
  isZoomToExtended: boolean;
  isFullReport: boolean;
  themeContentlist: Array<Theme>;
  updatedViewBoxX = 0;
  updatedViewBoxY = 0;
  isFull : boolean = false;
  private originPointerX = 0;
  private originPointerY = 0;
  private translatex = 0;
  private translateY = 0;
  private mousePointerx = 0;
  private mousePointery = 0;
  private dx = 0
  private dy = 0
  isZoomToExtendedflag = false;
  zoomlevel = false;
  
  //Object Declaration
  private reportData: ReportBase;
  private reportTranslationObj: ReportTranslation;
  tempviewBoxReportAttribute : string;
  titleoption: any;



  constructor(
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private ReportEngineService: ReportEngineService,
    private globalErrorService: GlobalerrorService,
    private hostElement: ElementRef,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private router: Router,
    private messageDialogService:MessageDialogService
  ) {
    this.reportTranslationObj = new ReportTranslation();
    this.reportTranslationObj.viewBoxX = 0;
    this.reportTranslationObj.viewBoxY = 0;
    this.centerButtonPrefix = "Disable-";
    this.zoomButtonPrefix = "Disable-";
    this.currentZoomLevel = 0;
    this.isZoomToExtended = false;
  }


  ngOnInit(): void {
    this.initiateReport();
  }



  //report initialization functionality
  initiateReport() {
    try {
        this.activatedRoute.params.subscribe(params => {
          this.reportName = params['name'];

          if (this.reportName) {
            let processId = GuidGenerator.generate();
            this.loadingService.show(processId);

            // getting meta information from json
            this.ReportEngineService.getMetaData(this.reportName).subscribe(
              (reportMetaData: ReportConfig) => {
                    this.loadingService.hide(processId);
                // create report object with it's metadata
                this.reportData = new ReportBase(
                  this.reportName,
                  reportMetaData,
                  this.componentFactoryResolver,
                  this.dynamicReportView,
                  this.ReportEngineService
                );

                this.isCenterEnabled = this.reportData.reportconfig.getCenterEnability();
                this.currentZoomPercentage = this.reportData.reportconfig.getDefaultZoomLevel();
                this.currentZoomLevel = Math.round(
                  100 / this.currentZoomPercentage
                );

                //get report themelist and bind to frontend
                this.themeContentlist = this.reportData.getThemeList();

                // report dynamic intialization
                this.reportData.loadReport();
                this.reportData.setAfterViewInitialization(
                  this.reportAfterViewInitialization
                );
                this.reportData.setZoomToExtend(() => { this.reset(); this.zoomToExtend() })
              },
              (error) => {
                console.log(error);
                throw new CustomError("Report not Found", 1602, false);
              }
            );
          }
        },
        (error) => {
          console.log(error);
          throw new CustomError("Report not Found", 1602, false);
        }
      );
    } catch (error) {
      console.log(error)
      this.globalErrorService.showErrorPopup("1611");
    }


  }

  reportAfterViewInitialization = () => {

    try {
      if (!this.reportTranslationObj.reportBoundry) {
        this.reportTranslationObj.hide = false;
        this.translate(this.reportData.reportconfig.getDefaultAlingment());

        this.reportData.setCommonUrl();

        //get default theme
        let themeId = this.reportData.getdefaultThemeId();
        this.selectTheme(themeId);

        // this.reportData.setTranslation(this.reportTranslationObj);

        this.zoomButtonPrefix = "";

        this.reportData.reportconfig.getCenterEnability()
          ? (this.centerButtonPrefix = "")
          : (this.centerButtonPrefix = "Disable-");
        this.reportData.setZoomToExtend(() => { this.reset(); this.zoomToExtend() })
        // detect changes after updating
        this.changeDetectorRef.detectChanges();
        //this.zoomToExtend();
      }
    } catch (error) {
      console.log(error)
      this.globalErrorService.showErrorPopup("1611");
    }

    //When report boundary available , initial report translation happens
    //This is used to prevent contintious call from ngAfterContentview from specific report as hack

  };

  // Event get fired when window is resized
  @HostListener("orientationchange", ["$event"])
  orientationChange($event: any) {    
    // if (this.isZoomToExtended) {
    //   return;
    // }
    // //this.isFullReport = false;
    // this.translate(
    //   this.reportData.reportconfig.getDefaultAlingment(),
    //   this.reportTranslationObj.viewBoxX,
    //   this.reportTranslationObj.viewBoxY
    // );
  }

  // ----------- Event Implementation Block ----------->
  //<------Start------>
  //Event get fired when window is resized
  @HostListener("window:resize", ["$event"])
  resizeViewBox($event: any) {
    if (this.isZoomToExtended) {
      return;
    }
   
    this.reportData.hideTooltip()
    //this.isFullReport = false;
    this.translate(
      this.reportData.reportconfig.getDefaultAlingment(),
      this.reportTranslationObj.viewBoxX,
      this.reportTranslationObj.viewBoxY
    );
  }
  @HostListener("pointerdown", ["$event"])
  onPointerDown($event: any) {
    // this.reportData.hideTooltip()
    
    this.reportTranslationObj.isPointerDown = true;

    var pointerPosition = this.getPointFromEvent($event);

    //console.log("start pan " + JSON.stringify(this.reportTranslationObj));
    this.originPointerX = pointerPosition.x;
    this.originPointerY = pointerPosition.y;
    this.mousePointerx = pointerPosition.x;
    this.mousePointery = pointerPosition.y;
  }

  @HostListener("pointermove", ["$event"])
  onPointerMove($event: any) {
    $event.preventDefault();
    this.pan($event);
  }

  @HostListener("pointerup", ["$event"])
  onPointerUp($event: any) {
    this.reportData.hideTooltip()
    this.reportTranslationObj.isPointerDown = false;
    this.reportTranslationObj.viewBoxX = this.updatedViewBoxX;
    this.reportTranslationObj.viewBoxY = this.updatedViewBoxY;
    var pointerPosition = this.getPointFromEvent($event);
    //console.log("done pan " + JSON.stringify(this.reportTranslationObj));
    // this.originPointerX = pointerPosition.x;
    // this.originPointerY = pointerPosition.y;
    // this.mousePointerx = pointerPosition.x;
    // this.mousePointery = pointerPosition.y;
  }

  @HostListener("pointerleave", ["$event"])
  onPointerLeave($event: any) {
    this.reportTranslationObj.isPointerDown = false;
    this.reportTranslationObj.viewBoxX = this.updatedViewBoxX;
    this.reportTranslationObj.viewBoxY = this.updatedViewBoxY;
  }

  @HostListener("touchstart", ["$event"])
  onTouchDown($event: any) {
    this.reportData.hideTooltip()
    this.reportTranslationObj.isPointerDown = true;
    var pointerPosition = this.getPointFromEvent($event);
    //console.log(pointerPosition);
    //console.log("start pan " + JSON.stringify(this.reportTranslationObj));
    this.originPointerX = pointerPosition.x;
    this.originPointerY = pointerPosition.y;
    this.mousePointerx = pointerPosition.x;
    this.mousePointery = pointerPosition.y;
  }

  @HostListener("touchmove", ["$event"])
  onTouchMove($event: any) {
    $event.preventDefault();
    if ($event.targetTouches.length === 1 && $event.changedTouches.length === 1) {
      this.pan($event);
    }
  }


  @HostListener("pinchin", ["$event"])
  pinchin($event: any) {
    //console.log('pinch in')
    //console.log($event)

    if (this.isZoomToExtendedflag) {

     // console.log(this.isZoomToExtendedflag)
      //console.log('zoom to extended')
      this.isZoomToExtendedflag = false
      this.zoom(false)
      return
    }

    this.zoom(false, true, $event.center.x, $event.center.y)
  }

  @HostListener("pinchout", ["$event"])
  pinchout($event: any) {
    //console.log('pinch out')
    //console.log($event)


    if (this.isZoomToExtendedflag) {

      //console.log(this.isZoomToExtendedflag)
      //console.log('zoom to extended')
      this.isZoomToExtendedflag = false
      this.zoom(true)
      return
    }

    this.zoom(true, true, $event.center.x, $event.center.y)

  }

  @HostListener("touchend", ["$event"])
  onTouchUp($event: any) {
    this.reportTranslationObj.isPointerDown = false;
    this.reportTranslationObj.viewBoxX = this.updatedViewBoxX;
    this.reportTranslationObj.viewBoxY = this.updatedViewBoxY;
    var pointerPosition = this.getPointFromEvent($event);
    //console.log("done pan " + JSON.stringify(this.reportTranslationObj));
  }

  @HostListener("wheel", ["$event"])
  onWheel1(event: any) {
    this.reportData.hideTooltip()
    let direction = event.wheelDelta > 0 ? true : false
    if (this.isZoomToExtendedflag) {
      //console.log(this.isZoomToExtendedflag)
      //console.log('zoom to extended')
      this.isZoomToExtendedflag = false
      this.zoom(direction)
      return
    }
    this.zoom(direction, true, event.clientX, event.clientY)

  }
  //<------End------>

  //center button functionality
  center() {
    if (this.isZoomToExtended) {
      return;
    }
    try {
      this.isFullReport = false;
      this.reportTranslationObj.viewBoxX = 0;
      this.reportTranslationObj.viewBoxY = 0;
      this.updatedViewBoxX = 0
      this.updatedViewBoxY = 0
      this.translate(AlignmentType.center);
    }
    catch (error) {
      console.log(error)
      this.globalErrorService.showErrorPopup("1611");
    }
  }


  /**
   * Zoom report based on mouse pointer location or report's default alingment
   * @param {boolean} [isZoomIn] - true to zoom in , false to zoom out
   * @param {boolean} [wheel=false] - true if zoom using mouse wheel , false if zoom using button
   * @param {number} [mouseX =0] - mouse pointer X coordinate
   * @param {number} [mouseY =0] - mouse pointer Y coordinate
   */
  zoom(isZoomIn: boolean, wheel = false, mouseX = 0, mouseY = 0) {
    if (this.isZoomToExtended) {
      return;
    }
    this.isFullReport = false;
    //this.isZoomToExtended = false;
    try {
      let preZoom = this.currentZoomPercentage
      isZoomIn
        ? (this.currentZoomPercentage -= this.reportData.reportconfig.getZoomStepLevel())
        : (this.currentZoomPercentage += this.reportData.reportconfig.getZoomStepLevel());

      this.currentZoomLevel = Math.round(100 / this.currentZoomPercentage);
      this.currentZoomPercentage =
        Math.ceil(
          this.currentZoomPercentage /
          this.reportData.reportconfig.getZoomStepLevel()
        ) * this.reportData.reportconfig.getZoomStepLevel();

      this.currentZoomPercentage = Math.round(this.currentZoomPercentage * 100) / 100;
      if (
        this.currentZoomPercentage >=
        this.reportData.reportconfig.getMaximumZoomLevel() &&
        this.currentZoomPercentage <=
        this.reportData.reportconfig.getMinimumZoomLevel()
      ) {

        if (!wheel) {   
            let viewableAreaDimesion = this.getViewableAreaDimesion();
            let middleY = (viewableAreaDimesion.viewableAreaHeight)/2;
            let middleX = (viewableAreaDimesion.viewableAreaWidth)/2;
            this.translate(AlignmentType.mousePoint, this.updatedViewBoxX, this.updatedViewBoxY, null, null, middleX, middleY, preZoom)
        } else {
          this.translate(AlignmentType.mousePoint, this.updatedViewBoxX, this.updatedViewBoxY, null, null, mouseX, mouseY, preZoom)

        }

      } else {
        isZoomIn
          ? (this.currentZoomPercentage = this.reportData.reportconfig.getMaximumZoomLevel())
          : (this.currentZoomPercentage = this.reportData.reportconfig.getMinimumZoomLevel());
        this.currentZoomLevel = Math.round(100 / this.currentZoomPercentage);

      }
    } catch (error) {
      // Pass errorcode to open errorPop
      this.globalErrorService.showErrorPopup("1611");
    }


  }
  /**
   * Set report to Zoom to Extended state
   */
  zoomToExtend() {
    let dimensions = null;
    try {
      this.isZoomToExtendedflag = true
      this.isFullReport = true;
      let reportAreaDimesions = this.getReportAreaDimesion();
      //console.log(reportAreaDimesions);
      this.currentZoomPercentage = 1;
      this.updatedViewBoxX = 0
      this.updatedViewBoxY = 0
      let viewPortDimensions = this.getViewableAreaDimesion();     

      this.translateZoomToExtend(
        this.reportData.reportconfig.getDefaultAlingment(),
        0,
        0,
        reportAreaDimesions.width + 180,
        reportAreaDimesions.height + 180
      );
     
      //console.log(reportAreaDimesions.height + 180)
      //console.log(parseFloat(reportAreaDimesions.height) / viewPortDimensions.viewableAreaHeight)

      let zoomwidth =
        parseFloat(reportAreaDimesions.height + 180) / viewPortDimensions.viewableAreaHeight;

      let zoomheight =
        parseFloat(reportAreaDimesions.width + 180) / viewPortDimensions.viewableAreaWidth;

      //console.log("req zoom level  height = " + zoomheight);
      //console.log("req zoom level  width = " + zoomwidth);

      if (zoomheight > zoomwidth) {

        this.currentZoomPercentage = zoomheight;
      } else {
        this.currentZoomPercentage = zoomwidth;
      }
      // this.currentZoomPercentage =
      //   Math.ceil(
      //     this.currentZoomPercentage /
      //       this.reportData.reportconfig.getZoomStepLevel()
      //   ) * this.reportData.reportconfig.getZoomStepLevel();

      this.currentZoomPercentage = this.currentZoomPercentage =
        Math.round(this.currentZoomPercentage * 100) / 100;

      //console.log(this.currentZoomPercentage);
      this.currentZoomLevel = Math.round(100 / this.currentZoomPercentage);
      if (
        this.currentZoomPercentage >=
        this.reportData.reportconfig.getMaximumZoomLevel() &&
        this.currentZoomPercentage <=
        this.reportData.reportconfig.getMinimumZoomLevel()
      ) {
        this.reportTranslationObj.viewBoxX = 0;
        this.reportTranslationObj.viewBoxY = 0;
        //this.translate(this.reportData.reportconfig.getDefaultAlingment(),0,0, reportAreaDimesions.width, reportAreaDimesions.height);
      }
      this.isFullReport = true;
    }

    catch (error) {
      console.log(error)
      console.log("zoom to extend error");
      this.globalErrorService.showErrorPopup("1611");
    }

    dimensions = this.getViewableAreaDimesion();
    this.getCenterTranslation(
      this.reportTranslationObj.reportBoundry,
      Math.round(dimensions.viewableAreaWidth) * this.currentZoomPercentage,
      Math.round(dimensions.viewableAreaHeight) * this.currentZoomPercentage
    );
  }

  pan(event: any) {
    if (this.isZoomToExtended) {
      return;
    }

    if (!this.reportTranslationObj.isPointerDown) {
      return;
    }

    try {

      this.isFullReport = false;

      let reportArea = this.getReportAreaDimesion();
      let viewableArea = this.getViewableAreaDimesion();
      let horizontalpan = false;
      let verticalpan = false;

      if (
        reportArea.width * (1 / this.currentZoomPercentage) <
        viewableArea.viewableAreaWidth &&
        reportArea.height * (1 / this.currentZoomPercentage) <
        viewableArea.viewableAreaHeight
      ) {
        //console.log("small report");

        return;
      }

      if (
        reportArea.width * (1 / this.currentZoomPercentage) >
        viewableArea.viewableAreaWidth
      ) {
        //console.log("horizontal pan");

        horizontalpan = true;
      }

      if (
        reportArea.height * (1 / this.currentZoomPercentage) >
        viewableArea.viewableAreaHeight
      ) {
        //console.log("vertical pan");
        verticalpan = true;
      }
      let pointerPosition = this.getPointFromEvent(event);
      let directions = "";
      //console.log(this.mousePointerx - pointerPosition.x);
      if (this.mousePointerx - pointerPosition.x <= 0) {
        //console.log("right");
        directions = "right";
      } else {
        directions = "left";
        //console.log("left");
      }
      this.mousePointerx = pointerPosition.x;

      //console.log(this.mousePointery - pointerPosition.y);
      let directionsVertical = "";

      if (this.mousePointery - pointerPosition.y <= 0) {
        //console.log("bottom");
        directionsVertical = "bottom";
      } else {
        directionsVertical = "top";
        //console.log("top");
      }

      this.mousePointery = pointerPosition.y;
      /************************************************************************* */
      this.svgViewableArea = this.hostElement.nativeElement.querySelector(
        "#svgViewableArea"
      );

      this.reportArea = this.hostElement.nativeElement.querySelector(
        "#reportArea"
      );

      let svgViewableAreaBoundryBox = this.svgViewableArea.getBoundingClientRect();
      let reportAreaBoundryBox = this.reportArea.getBoundingClientRect();

      //console.log(svgViewableAreaBoundryBox);
      //console.log(reportAreaBoundryBox);

      //console.log("********************pan right**************************");
      /*console.log(
        svgViewableAreaBoundryBox.left -
        (pointerPosition.x - this.originPointerX),
        reportAreaBoundryBox.left
      );
      console.log(
        svgViewableAreaBoundryBox.left -
        (pointerPosition.x - this.originPointerX) <
        reportAreaBoundryBox.left
      );
      console.log(pointerPosition.x - this.originPointerX);

      console.log(svgViewableAreaBoundryBox);
      console.log(reportAreaBoundryBox);

      console.log("*********************END*************************");
        */
      if (directions === "right") {
        if (
          svgViewableAreaBoundryBox.left -
          (pointerPosition.x - this.originPointerX) <
          reportAreaBoundryBox.left
        ) {
          //console.log("left limit execccdd");

          this.originPointerX = pointerPosition.x;
          this.reportTranslationObj.viewBoxX = this.updatedViewBoxX;
          // this.mousePointerx = pointerPosition.x;

          horizontalpan = false;


        }
      } else if (directions === "left") {
        if (
          svgViewableAreaBoundryBox.right >
          reportAreaBoundryBox.right + (pointerPosition.x - this.originPointerX)
        ) {
          //console.log("right limit execccdd");
          this.reportTranslationObj.viewBoxX = this.updatedViewBoxX;
          this.originPointerX = pointerPosition.x;
          // this.mousePointerx = pointerPosition.x;

          horizontalpan = false;

        }
      }

      if (directionsVertical === "bottom") {
        if (svgViewableAreaBoundryBox.top < reportAreaBoundryBox.top) {
          //console.log("top limit execccdd");
          this.originPointerY = pointerPosition.y;
          // this.reportTranslationObj.viewBoxX = this.updatedViewBoxX;
          this.reportTranslationObj.viewBoxY = this.updatedViewBoxY;
          // this.mousePointery = pointerPosition.y;
          verticalpan = false;

        }
      } else if (directionsVertical === "top") {
        if (
          svgViewableAreaBoundryBox.bottom -
          (pointerPosition.y - this.originPointerY) >
          reportAreaBoundryBox.bottom + (pointerPosition.y - this.originPointerY)
        ) {
          //console.log("bottom limit execccdd");
          this.originPointerY = pointerPosition.y;
          this.reportTranslationObj.viewBoxY = this.updatedViewBoxY;
          // this.mousePointery = pointerPosition.y;
          verticalpan = false;

        }
      }

      
      //console.log(horizontalpan, verticalpan);
      this.updatedViewBoxX = horizontalpan
        ? this.reportTranslationObj.viewBoxX -
        (pointerPosition.x - this.originPointerX) * this.currentZoomPercentage
        : // *         this.currentZoomPercentage
        this.updatedViewBoxX;

      this.updatedViewBoxY = verticalpan
        ? this.reportTranslationObj.viewBoxY -
        (pointerPosition.y - this.originPointerY) * this.currentZoomPercentage
        : // *        this.currentZoomPercentage
        this.updatedViewBoxY;

      //console.log(this.updatedViewBoxX, this.updatedViewBoxY);

      this.translate(null, this.updatedViewBoxX, this.updatedViewBoxY);
    }
    catch (error) {
      console.log("Pan function error")
      this.globalErrorService.showErrorPopup("1611");
    }

    // this.reportTranslationObj.translateReportAttribute = `translate (${-this
    //   .updatedViewBoxX},${-this.updatedViewBoxY})`;
    // this.reportData.setTranslation(this.reportTranslationObj);
  }


  /**
   * Reset report to default state
   * @param {boolean} [resetTheme=true] -  false to reset report without changin theme to default
   */
  reset(resetTheme: boolean = true) {
    if (this.isZoomToExtended) {
      return;
    }

    try {

      this.currentZoomPercentage = this.reportData.reportconfig.getDefaultZoomLevel();
      this.currentZoomLevel = Math.round(100 / this.currentZoomPercentage);
      this.updatedViewBoxX = 0
      this.updatedViewBoxY = 0

      if (resetTheme) {
        let themeId = this.reportData.getdefaultThemeId();
        this.selectTheme(themeId);
      }

      this.translate(this.reportData.reportconfig.getDefaultAlingment(), 0, 0);
    }
    catch (error) {
      console.log("resetting error");
      this.globalErrorService.showErrorPopup("1611");
    }
  }

  /**
     * set relevant theme according to the id
     */
  selectTheme(id: number) {
    let dynamicThemeUrl =
      this.reportData.getBaseUrl() +
      "themes/" +
      this.reportData.getThemeFileNameById(id);
    this.reportData.setReportTheme(dynamicThemeUrl);

    // detect changes after updating
    this.changeDetectorRef.detectChanges();
  }  

  publishReport() {
    let popupInput = {
      popUpTitle : 'Publish Report',
      reportTitle : this.reportData.getReportInstance().reportTitle,
      optionsList : [ReportPublishOptions.Title,ReportPublishOptions.Template]
    }

    let factorVal = 1;
    
    this.dialogService.setHalf().open("ReportPublishOption", popupInput).subscribe(response => {  
      if (response.data) {  
        let requestData = {
          content: this.getReportContent(),
          template:response.data.templateName,
          type: ReportType[this.reportName[0].toUpperCase() + this.reportName.substr(1).toLowerCase()],
          reportName: response.data.reportTitle,        
          width  : this.isFull ? ((this.reportTranslationObj.viewableSVGAreaWidth)) : ((this.reportTranslationObj.viewableSVGAreaWidth * this.currentZoomPercentage)),
          height : this.isFull ? ((this.reportTranslationObj.viewableSVGAreaHeight - this.reportData.getReportReduceHeight())) * factorVal : ((this.reportTranslationObj.viewableSVGAreaHeight * this.currentZoomPercentage)),    
          isFullReport : this.isFull,
          level : this.reportData.getLevel()  
        }
        if (this.reportName === ReportType[ReportType.Timelinereport].toLowerCase() && this.isFull === true) {
          requestData.height += this.reportData.reportconfig.reportProperty.fullReportHeightCorrection;
        } else if (this.reportName === ReportType[ReportType.Timelinereport].toLowerCase() && this.isFull === false) {
          requestData.width  = this.reportData.reportconfig.reportProperty.widthCorrection;
          requestData.height +=  this.reportData.reportconfig.reportProperty.heightCorrection;
        } else {
          requestData.height += this.reportData.reportconfig.reportProperty.heightCorrection;
        }
        let processId = GuidGenerator.generate();
        this.loadingService.show(processId);
        this.ReportEngineService.publishReport(requestData).subscribe((response: ResponseModel<string>) => {
          if(response){
            this.router.navigate(['/reportmanager'])
          }
        }, (err) => {
          throw new CustomError("MainReportEngineView => publishReport() : " + err, 911, false);
        }).add(() => {
          this.loadingService.hide(processId);
        });
      }
    })   
  }

  openReportPopup() {
      const popupName = this.reportData.reportconfig.reportProperty.options.defaultOptionPopupName ?? "ReportOptionPopup";
      this.dialogService.setHalf().open(popupName, this.reportData.getReportInstance().reportOptions).subscribe((res) => {
        if (res.data) {
          this.reportData.getReportInstance().onOptionsValueChanged(res.data);
        }
      });
  }

  /**
   * Get DOM elements of the report
   */
  getReportContent() {
    let reportDomElement = this.hostElement.nativeElement.querySelector("#domReport");
    let tempval = reportDomElement.innerHTML as string;
   
    if (this.reportName === ReportType[ReportType.Timelinereport].toLowerCase() && this.isFull === false) {
      // Extract 2nd and 4th value from viewBox
      const viewBoxMatch              = tempval.match(/viewBox="[^"]+"/);
      const viewBoxValues             = viewBoxMatch && viewBoxMatch[0].split(" ");
      const dynamicViewBoxSecondValue = viewBoxValues && viewBoxValues[1];
      const dynamicViewBoxFourthValue = viewBoxValues && viewBoxValues[3];
  
      // Extract 2nd value from transform
      const transformMatch        = tempval.match(/transform="[^"]+"/);
      const transformValues       = transformMatch && transformMatch[0].match(/[-\d]+/g);
      const dynamicTranslateValue = transformValues && transformValues.length >= 2 ? transformValues[1] : "";
  
      // Replace values
      tempval = tempval.replace(/viewBox="[^"]+"/, `viewBox="0 ${dynamicViewBoxSecondValue} 2786 ${dynamicViewBoxFourthValue}"`);
      tempval = tempval.replace(/transform="[^"]+"/, `transform="translate(143, ${dynamicTranslateValue})"`); 
    }
    return tempval.replace(/width="[\w\s\-()]+"/, "").replace(/height="[\w\s\-()]+"/, "");
  }

  // get viewable area of the viewport
  getViewableAreaDimesion() {
    this.svgViewableArea = this.hostElement.nativeElement.querySelector(
      "#svgViewableArea"
    );

    let boundryBox = this.svgViewableArea.getBoundingClientRect();
    let viewableAreaHeight = boundryBox.height;
    let viewableAreaWidth = boundryBox.width;
    return { viewableAreaHeight, viewableAreaWidth };
  }
  
  // get viewable report area dimesions
  getReportAreaDimesion() {
    this.reportArea = this.hostElement.nativeElement.querySelector(
      "#reportArea"
    );
    let areaDimension = this.reportArea.getBBox();
    return areaDimension;
  }

  /**
   * Set report alignment based on alignment type
   * @param {AlignmentType} [alignment=null] -  AlignmentType
   */
  getReportAlingmentTranslation(alingment: AlignmentType, mouseX: number = 0, mouseY: number = 0, prezoom: number = 0) {
    let translationAttribute = "";


    if (alingment === AlignmentType.center) {
      //console.log("center align report");
      translationAttribute = this.getCenterTranslation(
        this.reportTranslationObj.reportBoundry,
        this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage,
        this.reportTranslationObj.viewableSVGAreaHeight *
        this.currentZoomPercentage
      );
    } else if (alingment === AlignmentType.left) {
      //console.log("left align report");
      translationAttribute = this.getLeftTranslation(
        this.reportTranslationObj.reportBoundry,
        this.reportTranslationObj.viewableSVGAreaHeight *
        this.currentZoomPercentage
      );
    } else if (alingment === AlignmentType.right) {
      //console.log("right align report");
      translationAttribute = this.getRightTranslation(
        this.reportTranslationObj.reportBoundry,
        this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage,
        this.reportTranslationObj.viewableSVGAreaHeight *
        this.currentZoomPercentage
      );
    } else if (alingment === AlignmentType.top) {
      //console.log("top align report");
      translationAttribute = this.getTopTranslation(
        this.reportTranslationObj.reportBoundry,
        this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage
      );
    } else if (alingment === AlignmentType.bottom) {
      //console.log("Bottom align report");
      translationAttribute = this.getBottomTranslation(
        this.reportTranslationObj.reportBoundry,
        this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage,
        this.reportTranslationObj.viewableSVGAreaHeight *
        this.currentZoomPercentage
      );
    } else if (alingment === AlignmentType.mousePoint) {
      //console.log("mouse point  align report");
      translationAttribute = this.getMousePointTranslation(
        mouseX, mouseY, prezoom
      );
    }

    return translationAttribute;
  }

  /**
   * get report left translation
   * @param reportBoundryBox
   * @param viewableAreaHeight
   */
  getLeftTranslation(reportBoundryBox: any, viewableAreaHeight): string {
    reportBoundryBox = reportBoundryBox;
    // let reportCenterX = reportBoundryBox.width / 2 + reportBoundryBox.x;
    let reportCenterY = reportBoundryBox.height / 2 + reportBoundryBox.y;

    let differanceX = Math.round(-reportBoundryBox.x); //viewableAreaWidth / 2 - reportCenterX;
    let differanceY = Math.round(viewableAreaHeight / 2 - reportCenterY);

    this.translatex = differanceX;
    this.translateY = differanceY;

    return `translate (${differanceX},${differanceY})`;
  }

  /**
   * get report center translation
   * @param reportBoundryBox
   * @param viewableAreaWidth
   * @param viewableAreaHeight
   */
  getCenterTranslation(
    reportBoundryBox: any,
    viewableAreaWidth: number,
    viewableAreaHeight: number
  ): string {
    reportBoundryBox = reportBoundryBox;

    let reportCenterX = reportBoundryBox.width / 2 + reportBoundryBox.x;
    let reportCenterY = reportBoundryBox.height / 2 + reportBoundryBox.y;

    let differanceX = Math.round(viewableAreaWidth / 2 - reportCenterX);
    let differanceY = Math.round(viewableAreaHeight / 2 - reportCenterY);

    this.translatex = differanceX;
    this.translateY = differanceY;

    return `translate (${differanceX},${differanceY})`;
  }

  /**
   * get report right translation
   * @param reportBoundryBox
   * @param viewableAreaWidth
   * @param viewableAreaHeight
   */
  getRightTranslation(
    reportBoundryBox: any,
    viewableAreaWidth: number,
    viewableAreaHeight: number
  ): string {
    reportBoundryBox = reportBoundryBox;

    // let reportCenterX = reportBoundryBox.width / 2 + reportBoundryBox.x;
    let reportCenterY = reportBoundryBox.height / 2 + reportBoundryBox.y;

    let differanceX =
      Math.round(viewableAreaWidth - reportBoundryBox.width - reportBoundryBox.x); // / 2 - reportCenterX;
    let differanceY = Math.round(viewableAreaHeight / 2 - reportCenterY); // viewableAreaHeight - reportBoundryBox.height; // viewableAreaHeight / 2 - reportCenterY;

    this.translatex = differanceX;
    this.translateY = differanceY;

    return `translate (${differanceX},${differanceY})`;
  }

  //top translation functionality
  getTopTranslation(reportBoundryBox: any, viewableAreaWidth: number): string {
    reportBoundryBox = reportBoundryBox;

    let reportCenterX = reportBoundryBox.width / 2 + reportBoundryBox.x;
    let reportCenterY = reportBoundryBox.y;

    let differanceX = Math.round(viewableAreaWidth / 2 - reportCenterX); // / 2 - reportCenterX;
    let differanceY = Math.round(reportCenterY); //

    this.translatex = differanceX;
    this.translateY = differanceY;

    return `translate (${differanceX},${differanceY})`;
  }

  //bottom translation functionality
  getBottomTranslation(
    reportBoundryBox: any,
    viewableAreaWidth: number,
    viewableAreaHeight: number
  ): string {
    reportBoundryBox = reportBoundryBox;
    let reportCenterX = reportBoundryBox.width / 2 + reportBoundryBox.x;

    let differanceX = viewableAreaWidth / 2 - reportCenterX;
    let differanceY =
      viewableAreaHeight - reportBoundryBox.height - reportBoundryBox.y;

    this.translatex = differanceX;
    this.translateY = differanceY;

    return `translate (${differanceX},${differanceY})`;
  }

  getMousePointTranslation(
    mouseX: number, mouseY: number, prezoom: number
  ): string {


    let differanceX = mouseX * (prezoom - this.currentZoomPercentage)

    let differanceY = mouseY * (prezoom - this.currentZoomPercentage)


    this.translatex = Math.round(this.translatex - differanceX)
    this.translateY = Math.round(this.translateY - differanceY)


    return `translate (${this.translatex},${this.translateY})`;
  }


  /**
   * Calculate new viewbox attribute and set to the dynamic report referance
   * @param {AlignmentType} [alignment=null] -  AlignmentType
   * @param {number} [x=0] -  viewbox origin X coordinate
   * @param {number} [y=0] -  viewbox origin Y coordinate
   * @param {number} [width=0] -  viewbox width
   * @param {number} [height=0] -  viewbox height
   * @param {number} [mouseX =0] - mouse pointer X coordinate
   * @param {number} [mouseY =0] - mouse pointer Y coordinate
   * @param {number} [prezoom =0] - previous zoom percentage value
   */
  translate(
    alignment: AlignmentType = null,
    x: number = 0,
    y: number = 0,
    width: number = 0,
    height: number = 0,
    mouseX: number = 0,
    mouseY: number = 0,
    prezoom: number = 0,
  ): void {
    this.isFull = false;
    try {
      this.reportTranslationObj.reportBoundry = this.getReportAreaDimesion();

      let dimesions = this.getViewableAreaDimesion();

      this.reportTranslationObj.viewableSVGAreaHeight = height
        ? Math.round(height)
        : Math.round(dimesions.viewableAreaHeight);
      this.reportTranslationObj.viewableSVGAreaWidth = width
        ? Math.round(width)
        : Math.round(dimesions.viewableAreaWidth);
      this.reportTranslationObj.viewBoxReportAttribute = `${Math.round(x)} ${Math.round(y)} ${Math.round(this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage)
        } ${Math.round(this.reportTranslationObj.viewableSVGAreaHeight *
          this.currentZoomPercentage)
        }`;

      this.tempviewBoxReportAttribute = `${Math.round(x)} ${Math.round(y)} ${Math.round(this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage)
        } ${Math.round((this.reportTranslationObj.viewableSVGAreaHeight - this.reportData.getReportReduceHeight()) *
          this.currentZoomPercentage)
        }`;


      this.reportTranslationObj.translateReportAttribute = alignment
        ? this.getReportAlingmentTranslation(alignment, Math.round(mouseX), Math.round(mouseY), prezoom)
        : this.reportTranslationObj.translateReportAttribute;

 

      this.reportData.setTranslation(this.reportTranslationObj);
    } catch (error) {
      console.log("report transalte error")
      this.globalErrorService.showErrorPopup("1611");
    }

  }

  translateZoomToExtend(
    alignment: AlignmentType = null,
    x: number = 0,
    y: number = 0,
    width: number = 0,
    height: number = 0,
    mouseX: number = 0,
    mouseY: number = 0,
    prezoom: number = 0,
  ): void {
    this.isFull = true;  
    try {
      this.reportTranslationObj.reportBoundry = this.getReportAreaDimesion();

      let dimesions = this.getViewableAreaDimesion();

      this.reportTranslationObj.viewableSVGAreaHeight = height
        ? Math.round(height)
        : Math.round(dimesions.viewableAreaHeight);
      this.reportTranslationObj.viewableSVGAreaWidth = width
        ? Math.round(width)
        : Math.round(dimesions.viewableAreaWidth);
      this.reportTranslationObj.viewBoxReportAttribute = `${Math.round(x)} ${Math.round(y)} ${Math.round(this.reportTranslationObj.viewableSVGAreaWidth *
        this.currentZoomPercentage)
        } ${Math.round((this.reportTranslationObj.viewableSVGAreaHeight -this.reportData.getReportReduceHeight()) *
          this.currentZoomPercentage)
        }`;
     
      this.reportTranslationObj.translateReportAttribute = alignment
        ? this.getReportAlingmentTranslation(alignment, Math.round(mouseX), Math.round(mouseY), prezoom)
        : this.reportTranslationObj.translateReportAttribute;



      this.reportData.setTranslation(this.reportTranslationObj);
    } catch (error) {
      console.log("report transalte error")
      this.globalErrorService.showErrorPopup("1611");
    }

  }



  /**
   * Get ponter location x,y coordinate from mouse events
   * @param event
   */
  getPointFromEvent(event: any) {
    var point = { x: 0, y: 0 };
    // If event is triggered by a touch event, we get the position of the first finger
    if (event.targetTouches) {
      point.x = event.targetTouches[0].clientX;
      point.y = event.targetTouches[0].clientY;
    } else {
      point.x = event.clientX;
      point.y = event.clientY;
    }    
    return point;
  }

  checkReportTemplate() {
    let reportAreaDimesions = this.getReportAreaDimesion();
    if (
      reportAreaDimesions.height + 180 ==
      this.reportTranslationObj.viewableSVGAreaHeight &&
      reportAreaDimesions.width + 180 ==
      this.reportTranslationObj.viewableSVGAreaWidth
    ) {
      return true;
    } else {
      return false;
    }
  }
  
  //todo : remove this popup after refactoring the report publishing
  showNotImplementedMessage(){
    this.messageDialogService.openWarn("Informajon", "Dette verktøyet er fortsatt under utvikling. Sjekk tilbake noe senere da vi oppgraderer programmet med jevne mellomrom.", null);
  }
}
