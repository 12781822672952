import { ReportFont } from '../../common/reportfont';
import { SunChartGraphic } from './sun-chart-graphic';

export class SunChartText {

    private configs: any;
    private text: string;
    private nooflines: number;

    private reportfont: ReportFont;

    constructor(text, noflines, configs, font) {
        this.configs = configs;
        this.text = text;
        this.nooflines = noflines;
        this.reportfont = font;


    }

    generate(postfixText?) {
        var text = [];
        var text1;

        if (this.configs.normalText1) {
            text1 = SunChartGraphic.getTextNormal(this.text, this.nooflines, this.configs.normalText1, this.reportfont);
            if (text1) text.push(text1);
        }

        if (text1 && this.configs.normalText2) {
            var temp = SunChartGraphic.getTextNormal(text1.textRemaining, this.nooflines, this.configs.normalText2, this.reportfont);
            if (temp) {
                temp.text = postfixText ? temp.text : temp.text;
                text.push(temp);
            }
        }
        return text;
    }
}