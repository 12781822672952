<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
        <div class="dialog-title-text">{{'menu.profile.lbl_about_us' | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">
        <div class="tabs">
            <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{"about_us.lbl_about_embla" |
                translate}}</a>
            <!-- <a [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">{{"about_us.lbl_contributors" |
                translate}}</a> -->
        </div>
        <div class="tab-forms app-light-bg">
            <app-aboutus-embla *ngIf="getViewMode() === 1"></app-aboutus-embla>
            <!-- <app-aboutus-contributors *ngIf="getViewMode() === 2"></app-aboutus-contributors> -->
        </div>
    </div>
</div>
