import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from "@angular/core";
import { EventApiService } from "src/app/services/API/event-api.service";
import { ResponseModel } from "src/app/common/models/responseModel";
import { CustomError } from "src/app/common/helpers/custom-error";
import { DateHelper } from "src/app/common/helpers/date-helper";
import { PreferencesService } from "src/app/services/preferences.service";
import { map } from "rxjs/operators";
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { EventViewBase } from '../models/event-view-base';
import { EditorMode, NotifierEvents, ReferenceType } from 'src/app/common/enums/enums';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { ComponentBase } from "../../base/component-base";
import { NotifierV2Service } from "src/app/services/notifier-v2.service";

@Component({
  selector: "app-events-main",
  templateUrl: "./events-main.component.html",
  styleUrls: ["./events-main.component.scss"],
})
export class EventsMainComponent extends ComponentBase implements OnInit, OnDestroy{

  @Input() data: any;
  @Input() title: any;
  eventConfig:any;  
  @Output() changeData = new EventEmitter();
  expectedUpdates = [ NotifierEvents.RootMemberUpdated,
                      NotifierEvents.RootFamilyUpdated, 
                      NotifierEvents.RootFamilyChanged];
  timeStamp: any;

  eventList: EventViewBase[];
  viewMode: number = 1;

  //Dirty check
  isDirty: boolean = false;

  constructor(
    private eventApiService : EventApiService, 
    private changeDetectorRef: ChangeDetectorRef,
    private preferenceService: PreferencesService, private loadingService: LoadingService,
    private parentCommunicatorService: ParentCommunicatorService, private translateHandler : TranslateHandler,
    private dialogService : MessageDialogService,
    notifierService: NotifierV2Service
  ) {
    super(notifierService);
    this.eventList = new Array<EventViewBase>();
  }

  public canChange(): boolean {
    return !this.data.isEventDirty;
  }

  ngOnInit(): void {
    this.getViewMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes['data'];
    if (change) {
      if (change.previousValue != change.currentValue) {
        this.changeViewMode(1);
      }
    }

    if (this.data && this.data.id) {
      var referenceType = (this.data.editorMode == EditorMode.IndividualEditor) ? ReferenceType.Individual : (this.data.editorMode == EditorMode.FamilyEditor)?ReferenceType.Family:ReferenceType.None;
      this.fillEventList(this.data.id, referenceType);
      this.eventConfig = {
        message : this.translateHandler.translate('err_no_event_for_resource', [this.translateHandler.translate(referenceType === 1 ? 'lbl_person' : 'lbl_family').toLowerCase()]),
        referenceType : referenceType
      }
    }
  }

  getViewMode(): number {
    return this.viewMode;
  }

  changeViewMode(mode) {
    this.data.isEventDirty = false;
    if (mode == 2) {
      if(this.data.id === 0) {
        this.dialogService.openWarn(this.translateHandler.translate('lbl_info'),this.translateHandler.translate('inf_save_family_before_add_event'),'');       
      }
      else {        
        this.viewMode = mode;
        this.data.eventId = 0;
      }
    }
    else
      this.viewMode = mode;
  }

  refreshEventList(mode) {
    this.viewMode = mode;
    var referenceType = (this.data.editorMode == EditorMode.IndividualEditor) ? ReferenceType.Individual : ReferenceType.Family;
    this.fillEventList(this.data.id, referenceType)
    this.changeData.next(this.data);

  }

  openEditorView(event) {
    this.viewMode = 2;
    this.data.eventId = event.id;
    this.data.isCustomEvent = event.isCustomEvent;
    this.changeDetectorRef.detectChanges();
  }

  fillEventList(referanceId: number, referenceType: number) {
  this.timeStamp = performance.now();  
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.eventApiService.getEvents(referanceId, referenceType).pipe(map((response: ResponseModel<EventViewBase[]>) => {
      return this.orderEventList(response.data);
    }))
      .subscribe((orderedEventList) => {
        this.eventList = orderedEventList;
      }, (err) => {
        throw new CustomError("EventMain => fillEventList() : " + err, 911, false);
      })
      .add(() => {
        this.loadingService.hide(processId);
      });
  }

  orderEventList(eventArray) {
    let dateHelper = new DateHelper(this.preferenceService);
    if (eventArray && eventArray.length > 0) {
      eventArray.forEach(event => {
        if (event?.date) {
          if(event.date.genealogyDate != null){
            event.date = dateHelper.validateAndFormat(event.date.genealogyDate.originalStr);
            if (event?.date?.isValidDate && event?.date?.genealogyDate) {
              event.date.mappedDate = dateHelper.mapToDate(event.date.genealogyDate.year, event.date.genealogyDate.month, event.date.genealogyDate.day)
            } else if (event?.date?.genealogyDate?.originalStr) {
              event.date.invalidMappedDate = event.date.genealogyDate.originalStr;
            }
            else
              event.date.mappedDate = "";
          }
        }
      });
      eventArray.sort((n1, n2) => {
        if (!n1.date || n1.date.mappedDate == "") return -1;
        if (!n2.date || n2.date.mappedDate == "") return 0;
        if (!n1.date || n1.date.invalidMappedDate) return -1;
        if (!n2.date || n2.date.invalidMappedDate) return 0;

        if (n1.date.mappedDate > n2.date.mappedDate) {
          return 1;
        }
        if (n1.date.mappedDate < n2.date.mappedDate) {
          return -1;
        }
        return 0;
      });
      return eventArray;
    }
  }

  showNotImplemented() {
    this.parentCommunicatorService.send(ParentMessageType.ShowInformation, "not_implemented");
  }


  notify() {
    this.refreshEventList(1);
  }
}

