import { Component, EventEmitter, Input, IterableDiffers, OnInit, Output, DoCheck } from '@angular/core';
import { DropMenuAction } from 'src/app/common/enums/enums';
import { Multimedia } from '../../../common/models/multimedia/multimedia';
import { DropMenu } from '../drop-menu/models/DropMenu';
import { DropMenuItem } from '../drop-menu/models/DropMenuItem';

@Component({
  selector: 'app-image-carousel-temp',
  templateUrl: './image-carousel-temp.component.html',
  styleUrls: ['./image-carousel-temp.component.scss']
})
export class ImageCarouselTempComponent implements OnInit, DoCheck{
  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }
  
  @Input() inImages : Array<Multimedia> = null;
  @Input() defaultImage : string;
  @Output() action = new EventEmitter<{action: DropMenuAction, reference: Multimedia}>();
  
  currentIndex  : number = 0;
  dropMenu: DropMenu;
  iterableDiffer: any;
  currentAction : DropMenuAction;
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";

  inViewImage: Array<Multimedia> = null;
  
  ngOnInit(): void {
    // Todo: Set onError() image
    this.dropMenu = new DropMenu();
    this.dropMenu.type = 1;
    this.dropMenu.ref = null;
    this.dropMenu.menuItems = [ <DropMenuItem>{ action:DropMenuAction.Edit, isEnabled:true, isVisible:true},
                                <DropMenuItem>{ action:DropMenuAction.Upload, isEnabled:true, isVisible:true},
                                <DropMenuItem>{ action:DropMenuAction.SetAsPrimary, isEnabled:true, isVisible:true},
                                <DropMenuItem>{ action:DropMenuAction.Unlink, isEnabled:true, isVisible:true},
                                <DropMenuItem>{ action:DropMenuAction.Gallery, isEnabled:true, isVisible:true}
                              ];
    this.setDefaultImages(this.inViewImage, this.defaultImage);
  }
 
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.inImages);    
    if (changes) {     
      this.inViewImage = [...this.inImages ?? []];        
      // To remove duplicate
      this.inViewImage = this.inViewImage.reduce(function(a,b){
        if (a.indexOf(b) < 0 ) a.push(b);
        return a;
      },[]);
      this.setDefaultImages(this.inViewImage, this.defaultImage);

      // Use this condition to display current added image on crasoul    
      if (this.currentAction == DropMenuAction.Upload || this.currentAction == DropMenuAction.Edit || this.currentAction == DropMenuAction.Gallery) {
        let index = this.inViewImage.findIndex(t => t.isNewlyAdded == true);
        this.currentIndex = index >= 0 ? index : this.currentIndex;
        this.inViewImage.map(t => {
          t.isNewlyAdded = false;
        })
        this.currentAction = null;
      }
      
    }
  }

  getCurrentImage() {  
    
    if (this.defaultImage && this.inViewImage) {
      let defaultImg = this.inViewImage.find(e => e.id == 0);
      if (defaultImg)
        this.inViewImage.find(e => e.id == 0).mediaUrl = this.defaultImage;
    }

    if(this.inViewImage && this.currentIndex >= 0) {     
      let isDefaultImage =   this.inViewImage && this.inViewImage[this.currentIndex].id === 0;
      this.dropMenu.menuItems.find(a=>a.action === DropMenuAction.SetAsPrimary).isEnabled = !isDefaultImage;
      this.dropMenu.menuItems.find(a=>a.action === DropMenuAction.Unlink).isEnabled = !isDefaultImage;     
      this.dropMenu.menuItems.find(a=>a.action === DropMenuAction.Edit).isEnabled = !isDefaultImage;
      var url = this.inViewImage[this.currentIndex].mediaUrl;
      return url;
    }
    return this.defaultImage;
  }

  // Get family default image to display
  setDefaultImages(inViewImage: Array<Multimedia>,  defaultImage: string){    
    if((!inViewImage || inViewImage.length === 0)){
      this.inViewImage = [];
      this.inViewImage.push(<Multimedia>{mediaUrl:defaultImage,id : 0, isPrimary: true});
      this.currentIndex = 0;
    }else{
      this.removeDefaultImage();
      this.currentIndex = this.inViewImage.findIndex((x) => x.isPrimary == true);
    }
    
  }

  left(){
    this.currentIndex--;
    if ( this.currentIndex < 0 ){
      this.currentIndex = this.inViewImage.length -1;
    }    
  }

  right(){
    this.currentIndex++;
    if ( this.currentIndex >= this.inViewImage.length ){
      this.currentIndex = 0;
    }    
  }

  removeDefaultImage(){
    let indexOfDefaultImage = this.inViewImage.findIndex(d=>d.id === 0);
    if(indexOfDefaultImage != -1){
      this.inViewImage.splice(indexOfDefaultImage,1);      
    }
  }

  clickedAction($event) {  
    this.currentAction = $event.action;       
    this.action.emit({ action: this.currentAction, reference: this.inViewImage[this.currentIndex] });
  }

  //use when item loading error
  onItemError(event: any) {
    event.target.src = this.errorImg;
  }
}