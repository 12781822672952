import { Injectable, Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eventsummary',
  templateUrl: './eventsummary.component.html',
  styleUrls: ['./eventsummary.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class EventsummaryComponent {
  @Input() event;

  constructor(public activeModal: NgbActiveModal) {
  }
}

// export class EventsummaryComponent implements OnInit {

//   // constructor(public activeModal: NgbActiveModal) { }
//   ngOnInit(): void {

//   }

// }
