import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndividualEditorMainComponent } from './individual-editor-main/individual-editor-main.component';
import { EventsModule } from '../common/events/events.module';
import { IndividualEditorComponent } from './individual-editor/individual-editor.component';
import { ImageCarouselTempModule } from '../common/image-carousel-temp/image-carousel-temp.module';
import { CommonComponentModule } from '../common/common.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IndividualPromptComponent } from './individual-prompt/individual-prompt.component';
import { MediaCarouselModule } from '../common/media-carousel/media-carousel.module';



@NgModule({
  declarations: [IndividualEditorMainComponent, IndividualEditorComponent, IndividualPromptComponent],
  imports: [
    CommonModule,
    EventsModule,
    ImageCarouselTempModule,
    MediaCarouselModule,
    CommonComponentModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })    
  ],
  exports:[IndividualEditorMainComponent,IndividualPromptComponent]
})
export class IndividualeditorModule { }


export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
