import { Injectable } from '@angular/core';
import { ReportFont } from 'src/app/components/reports/common/reportfont';
import { HttpClient } from '@angular/common/http';
import { TreeLevel } from '../enums/enums';

@Injectable({
  providedIn: 'root'
})

export class StringLimiter {
  //configData: any;
  /**
   *
   */
  constructor(
    private reportFont : ReportFont,
    private httpClient: HttpClient
  ) {
    console.log("at construtor", this.configData);
    /*
    this.httpClient.get("assets/tree-editor/config/treeEditorConfig.json").subscribe(data =>{
      this.configData =  data;
    })
    */
   /// Hardcoded moved to end of the file, since not loaded synchronously

   //TODO : Move the config data to a service

  }
    update() {
        let elements = document.getElementsByClassName('ellipsis');

        for(let i = 0; i < elements.length; i++)
        {
          let element = elements[0];
          let textBoxLines = Number.parseInt(element.attributes.getNamedItem('ellipsisLines').value, 0);
          let textBoxWidth = Number.parseInt(element.attributes.getNamedItem('ellipsisWidth').value, 0);
          let textContent = element.textContent.trim();
          let fontHeight = element.getBoundingClientRect().height;
    
          // Reset text - after text and height measured !Important
          element.textContent = '';
    
          // starting points
          let x = element.getBoundingClientRect().x;
          let y = element.getBoundingClientRect().y;
          let elementHeight = parseInt(element.parentElement.getAttribute('height')) / 16; // px => em scale (divided by 16)
          let elementClientWidth = element.clientWidth;
    
          let textCharacters = Array.from(textContent);
          let textCharacterPos = 0;
          let tspan;
    
          // dynamic var
          let lineCount         = 0;      // line counter
          let isNewLine         = true;   // Move to new line
          let lastSpacePos: number;     // last space pos in sentence
          let lastSpacePosTspan = '';     // last space pos in current line
    
          while ( textCharacterPos < textCharacters.length) {
            if ( isNewLine ) {
              // Cannot create svg elements with normal createElement
              tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
              tspan.setAttribute('x', 0 + 'px');
              tspan.setAttribute('y',
                this.calculateY(elementHeight, textBoxLines, lineCount, elementClientWidth, textBoxWidth, fontHeight) + 'px');
              element.appendChild(tspan);
              isNewLine = false;
              lineCount++;
            }
    
            // Check the last space pos
            if ( textCharacters[textCharacterPos] == ' ') {
              lastSpacePos = textCharacterPos;
              lastSpacePosTspan = tspan.textContent.length;
            }
    
            tspan.textContent += textCharacters[textCharacterPos];
            textCharacterPos++;
    
            // Check the width of the box
            if ( tspan.getComputedTextLength() >= textBoxWidth ) {
              if ( lineCount >= textBoxLines){
                // put ellipsis and exit
                tspan.textContent = tspan.textContent.slice(0, tspan.textContent.length - 2) + '...';
                element.classList.add('ellipsis-checked');
                element.classList.remove('ellipsis');
                return;
              }

            // last space position is 0 on the tempstr mean the length cannot be statisfied return current
            if (Number(lastSpacePosTspan) == 0){
              tspan.textContent = tspan.textContent.slice(0, tspan.textContent.length-2) + "..";
              return;
            }

              // reset back to the last space
              textCharacterPos = lastSpacePos + 1;
              tspan.textContent = tspan.textContent.slice(0, lastSpacePosTspan);
              lastSpacePosTspan = '0';
              isNewLine = true;
            } // end width check
          }  // end while

          element.classList.add('ellipsis-checked');
          element.classList.remove('ellipsis');
        }
      }

      calculateY (elementHeight: number,
        numberOfLines: number,
        currentLine: number,
        clientWidth: number,
        textBoxWidth: number,
        fontHeight: number): number
        {
            if (clientWidth < textBoxWidth) {
                numberOfLines = 1;
            } else {
                numberOfLines = (clientWidth / textBoxWidth) > numberOfLines ? numberOfLines : Math.ceil(clientWidth / textBoxWidth);
            }

            let gapForLines = elementHeight / (numberOfLines);

            if (elementHeight > (fontHeight * numberOfLines) && numberOfLines > 1)
            {
                return (-gapForLines * 4 / 3) + (gapForLines * 3 * currentLine);
            } 
            else if (elementHeight < (fontHeight * numberOfLines))
            {
                return (-fontHeight * 4 / 3) + (fontHeight * 3 * currentLine);
            }

            return 0;
    }

    splitString(displayName: string, treeLevel: TreeLevel){
      if(displayName){
        let splitLabels = new Array<{name :string , spany : number}>();        
        let levelConfig = this.configData.levels.find(el=>el.level == treeLevel);
        this.reportFont.init(this.configData.font);
        // Load parameters from the treeEditorConfig
        let chartboxes = this.reportFont.getString(displayName, levelConfig.fontSize, levelConfig.txtWidth, levelConfig.txtLines, true);
        if(chartboxes.length == 1){
          splitLabels.push({
            "name" : chartboxes[0],
            "spany" : levelConfig.singleLine_y
          })
          return splitLabels;
        }
        else{
          splitLabels.push({
            "name" : chartboxes[0],
            "spany" : levelConfig.tSpan1_y
          })
          splitLabels.push({
            "name" : chartboxes[1],
            "spany" : levelConfig.tSpan2_y
          })
          return splitLabels;
        }
      }
    }

    configData:any = {
      "levels" : [{
          "level" : 0,
          "tSpan1_y": -21.337575276692707,
          "tSpan2_y": 26.671969095865887,
          "singleLine_y": 0,
          "fontSize" : 16,
          "txtWidth" : 120,
          "txtLines" : 2
      },
      {
          "level" : 1,
          "tSpan1_y": 0,
          "tSpan2_y": 0,
          "singleLine_y": 0,
          "fontSize" : 16,
          "txtWidth" : 130,
          "txtLines" : 1
      },
      {
          "level" : 2,
          "tSpan1_y": 0,
          "tSpan2_y": 0,
          "singleLine_y": 0,
          "fontSize" : 16,
          "txtWidth" : 130,
          "txtLines" : 1
      },
      {
          "level" : 3,
          "tSpan1_y": 0,
          "tSpan2_y": 0,
          "singleLine_y": 0,
          "fontSize" : 16,
          "txtWidth" : 130,
          "txtLines" : 1
      }
                ],
      "font": {
          "10": [
              -9.1,
              3.3,
              3.3,
              4.1,
              5,
              5,
              8.3,
              7.8,
              1.8,
              3.3,
              3.3,
              5,
              5.6,
              2.5,
              3.3,
              2.5,
              2.8,
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              5,
              2.8,
              2.8,
              5.6,
              5.6,
              5.6,
              4.4,
              9.2,
              7.2,
              6.7,
              6.7,
              6.7,
              4.9,
              4.9,
              6.5,
              6.5,
              2.4,
              3.7,
              5.4,
              4.7,
              8,
              6.6,
              6.9,
              5.3,
              6.9,
              5.4,
              4.9,
              5,
              6.5,
              5.6,
              8.5,
              5.7,
              5.4,
              5.5,
              2.8,
              3.4,
              2.8,
              6,
              5,
              3,
              4.8,
              5.7,
              4.5,
              5.6,
              5,
              2.9,
              5.6,
              5.5,
              2.3,
              2.4,
              4.7,
              2.4,
              8.3,
              5.5,
              5.5,
              5.7,
              5.6,
              3.3,
              4,
              3.3,
              5.5
          ],
          "16": [
              8.35,
              3.81,
              4.19,
              6.74,
              5.87,
              9.26,
              8.29,
              2.18,
              3.7,
              3.66,
              3.54,
              6.08,
              2.91,
              4.13,
              2.78,
              5.42,
              7.04,
              4.69,
              6.16,
              5.52,
              6.42,
              5.57,
              6.75,
              6,
              6.8,
              6.58,
              2.78,
              2.91,
              6.08,
              6.08,
              6.08,
              5.63,
              8.98,
              6.8,
              6.27,
              4.96,
              6.34,
              5.79,
              5.57,
              6,
              7.5,
              3.87,
              4.46,
              6.69,
              5.31,
              7.89,
              7.46,
              6.66,
              5.98,
              6.69,
              6.54,
              5.04,
              5.57,
              7.44,
              6.88,
              8.14,
              6.64,
              6.4,
              5.78,
              3.9,
              5.42,
              3.86,
              7.33,
              8,
              3.07,
              6.8,
              6.27,
              4.96,
              6.34,
              5.79,
              5.57,
              6,
              7.5,
              3.87,
              4.46,
              6.69,
              5.31,
              7.89,
              7.46,
              6.66,
              5.98,
              6.69,
              6.54,
              5.04,
              5.57,
              7.44,
              6.88,
              8.14,
              6.64,
              6.4,
              5.78,
              3.95,
              3.71,
              3.98,
              7.33,
              8,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              7.33,
              3.28,
              3.81,
              5.62,
              5.73,
              7.33,
              6.43,
              3.71,
              7.33,
              4.26,
              7.33,
              7.33,
              6.74,
              6.08,
              0,
              7.33,
              10.03,
              3.39,
              6.08,
              7.33,
              7.33,
              3.2,
              7.33,
              7.33,
              2.78,
              2.54,
              7.33,
              7.33,
              6.74,
              7.33,
              7.33,
              7.33,
              5.63,
              6.8,
              6.8,
              6.8,
              6.8,
              6.8,
              6.8,
              8.72,
              4.96,
              5.79,
              5.79,
              5.79,
              5.79,
              3.87,
              3.87,
              3.87,
              3.87,
              6.34,
              7.46,
              6.66,
              6.66,
              6.66,
              6.66,
              6.66,
              6.08,
              6.66,
              7.44,
              7.44,
              7.44,
              7.44,
              6.4,
              5.81,
              6.75,
              6.8,
              6.8,
              6.8,
              6.8,
              6.8,
              6.8,
              8.72,
              4.96,
              5.79,
              5.79,
              5.79,
              5.79,
              3.87,
              3.87,
              3.87,
              3.87,
              6.34,
              7.46,
              6.66,
              6.66,
              6.66,
              6.66,
              6.66,
              6.08,
              6.66,
              7.44,
              7.44,
              7.44,
              7.44,
              6.4
          ],
          "25": [
              4.5,
              7.2,
              7.2,
              12,
              12,
              12,
              13.4,
              22.2,
              8,
              11,
              11,
              6.9,
              13.1,
              5.6,
              8.9,
              5.6,
              13.2,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              5.6,
              5.6,
              13.1,
              13.1,
              13.1,
              10.9,
              17,
              22.7,
              23.2,
              19.9,
              21.2,
              21,
              16.5,
              18.3,
              21.6,
              16,
              14.1,
              22,
              19,
              24.5,
              21.8,
              19.8,
              19.2,
              17.6,
              23.1,
              17.7,
              14.7,
              22.5,
              18.7,
              23.1,
              25.1,
              23.3,
              16.3,
              11,
              13.2,
              11,
              12.5,
              12.5,
              12.5,
              8.6,
              6.6,
              6.1,
              8.6,
              6.2,
              3.3,
              8.4,
              7.8,
              4.2,
              3.8,
              7.5,
              4,
              13.6,
              9.8,
              7.3,
              6.6,
              7.2,
              6.9,
              4.9,
              4.1,
              7.8,
              6.8,
              10.6,
              8.7,
              7.7,
              7,
              11,
              12.5,
              11,
              16.7,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              12.5,
              4.4,
              7.2,
              12,
              12,
              12.7,
              12,
              12.5,
              12,
              12.5,
              19.2,
              8.6,
              8.9,
              15,
              0,
              19.2,
              12.5,
              12,
              13.1,
              7.9,
              7.9,
              12.5,
              8.7,
              20.7,
              5.8,
              12.5,
              7.9,
              8.6,
              8.9,
              16.7,
              16.3,
              16.5,
              10.9,
              22.7,
              22.7,
              22.7,
              22.7,
              22.7,
              22.7,
              32.3,
              19.9,
              21,
              21,
              21,
              21,
              16,
              16,
              16,
              16,
              21.2,
              21.8,
              19.8,
              19.8,
              19.8,
              19.8,
              19.8,
              13.1,
              19.8,
              22.5,
              22.5,
              22.5,
              22.5,
              23.3,
              18.7,
              7.3,
              8.6,
              8.6,
              8.6,
              8.6,
              8.6,
              8.6,
              11,
              6.1,
              6.2,
              6.2,
              6.2,
              6.2,
              4.2,
              4.2,
              4.2,
              4.2,
              7.3,
              9.8,
              7.3,
              7.3,
              7.3,
              7.3,
              7.3,
              13.1,
              7.3,
              7.8,
              7.8,
              7.8,
              7.8,
              7.7,
              6.5
          ],
          "29": [
              5.2,
              8.3,
              8.3,
              13.9,
              13.9,
              13.9,
              15.5,
              25.8,
              9.3,
              12.7,
              12.7,
              8.1,
              15.2,
              6.5,
              10.4,
              6.5,
              15.3,
              13.9,
              13.9,
              13.9,
              13.9,
              13.9,
              13.9,
              13.9,
              13.9,
              13.9,
              13.9,
              6.5,
              6.5,
              15.2,
              15.2,
              15.2,
              12.6,
              19.7,
              26.3,
              26.9,
              23.1,
              24.6,
              24.4,
              19.1,
              21.3,
              25,
              18.5,
              16.3,
              25.5,
              22,
              28.4,
              25.3,
              23,
              22.3,
              20.4,
              26.8,
              20.5,
              17,
              26.1,
              21.7,
              26.8,
              29.1,
              27,
              18.9,
              12.7,
              15.3,
              12.7,
              14.5,
              14.5,
              14.5,
              10,
              7.6,
              7,
              10,
              7.1,
              3.9,
              9.7,
              9.1,
              4.9,
              4.4,
              8.7,
              4.6,
              15.8,
              11.3,
              8.4,
              7.7,
              8.4,
              8.1,
              5.6,
              4.8,
              9.1,
              7.9,
              12.3,
              10.1,
              8.9,
              8.1,
              12.7,
              14.5,
              12.7,
              19.3,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              5.1,
              8.3,
              13.9,
              13.9,
              14.7,
              13.9,
              14.5,
              13.9,
              14.5,
              22.3,
              10,
              10.4,
              17.4,
              0,
              22.3,
              14.5,
              13.9,
              15.2,
              9.2,
              9.2,
              14.5,
              10.1,
              24,
              6.7,
              14.5,
              9.2,
              10,
              10.4,
              19.4,
              18.9,
              19.1,
              12.6,
              26.3,
              26.3,
              26.3,
              26.3,
              26.3,
              26.3,
              37.4,
              23.1,
              24.4,
              24.4,
              24.4,
              24.4,
              18.5,
              18.5,
              18.5,
              18.5,
              24.6,
              25.3,
              23,
              23,
              23,
              23,
              23,
              15.2,
              23,
              26.1,
              26.1,
              26.1,
              26.1,
              27,
              21.7,
              8.4,
              10,
              10,
              10,
              10,
              10,
              10,
              12.8,
              7,
              7.1,
              7.1,
              7.1,
              7.1,
              4.9,
              4.9,
              4.9,
              4.9,
              8.4,
              11.3,
              8.4,
              8.4,
              8.4,
              8.4,
              8.4,
              15.2,
              8.4,
              9.1,
              9.1,
              9.1,
              9.1,
              8.9,
              7.6
          ],
          "34": [
              6,
              9.8,
              9.8,
              16.3,
              16.3,
              16.3,
              18.2,
              30.2,
              10.9,
              14.9,
              14.9,
              9.4,
              17.8,
              7.6,
              12.2,
              7.6,
              18,
              16.3,
              16.3,
              16.3,
              16.3,
              16.3,
              16.3,
              16.3,
              16.3,
              16.3,
              16.3,
              7.6,
              7.6,
              17.8,
              17.8,
              17.8,
              14.8,
              23.1,
              30.9,
              31.6,
              27.1,
              28.8,
              28.6,
              22.4,
              25,
              29.3,
              21.7,
              19.1,
              29.9,
              25.8,
              33.3,
              29.6,
              27,
              26.1,
              23.9,
              31.4,
              24,
              20,
              30.6,
              25.5,
              31.4,
              34.1,
              31.7,
              22.2,
              14.9,
              18,
              14.9,
              17,
              17,
              17,
              11.7,
              8.9,
              8.2,
              11.7,
              8.4,
              4.5,
              11.4,
              10.6,
              5.7,
              5.1,
              10.2,
              5.4,
              18.5,
              13.3,
              9.9,
              9,
              9.8,
              9.4,
              6.6,
              5.6,
              10.6,
              9.3,
              14.4,
              11.8,
              10.5,
              9.5,
              14.9,
              17,
              14.9,
              22.7,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              17,
              6,
              9.8,
              16.3,
              16.3,
              17.3,
              16.3,
              17,
              16.3,
              17,
              26.1,
              11.7,
              12.2,
              20.4,
              0,
              26.1,
              17,
              16.3,
              17.8,
              10.8,
              10.8,
              17,
              11.8,
              28.1,
              7.9,
              17,
              10.8,
              11.7,
              12.2,
              22.7,
              22.1,
              22.4,
              14.8,
              30.9,
              30.9,
              30.9,
              30.9,
              30.9,
              30.9,
              43.9,
              27.1,
              28.6,
              28.6,
              28.6,
              28.6,
              21.7,
              21.7,
              21.7,
              21.7,
              28.8,
              29.6,
              27,
              27,
              27,
              27,
              27,
              17.8,
              27,
              30.6,
              30.6,
              30.6,
              30.6,
              31.7,
              25.4,
              9.9,
              11.7,
              11.7,
              11.7,
              11.7,
              11.7,
              11.7,
              15,
              8.2,
              8.4,
              8.4,
              8.4,
              8.4,
              5.7,
              5.7,
              5.7,
              5.7,
              9.9,
              13.3,
              9.9,
              9.9,
              9.9,
              9.9,
              9.9,
              17.8,
              9.9,
              10.6,
              10.6,
              10.6,
              10.6,
              10.5,
              8.9
          ],
          "38": [
              6.7,
              10.9,
              10.9,
              18.2,
              18.2,
              18.2,
              20.3,
              33.8,
              12.2,
              16.7,
              16.7,
              10.6,
              19.9,
              8.5,
              13.6,
              8.5,
              20.1,
              18.2,
              18.2,
              18.2,
              18.2,
              18.2,
              18.2,
              18.2,
              18.2,
              18.2,
              18.2,
              8.5,
              8.5,
              19.9,
              19.9,
              19.9,
              16.6,
              25.8,
              34.5,
              35.3,
              30.3,
              32.2,
              32,
              25.1,
              27.9,
              32.8,
              24.3,
              21.4,
              33.5,
              28.8,
              37.2,
              33.1,
              30.1,
              29.2,
              26.7,
              35.1,
              26.9,
              22.3,
              34.2,
              28.5,
              35.1,
              38.1,
              35.4,
              24.8,
              16.7,
              20.1,
              16.7,
              19,
              19,
              19,
              13.1,
              10,
              9.2,
              13.1,
              9.4,
              5,
              12.7,
              11.9,
              6.4,
              5.7,
              11.4,
              6.1,
              20.7,
              14.9,
              11,
              10.1,
              11,
              10.6,
              7.4,
              6.3,
              11.9,
              10.4,
              16.1,
              13.2,
              11.7,
              10.6,
              16.7,
              19,
              16.7,
              25.3,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              19,
              6.7,
              10.9,
              18.2,
              18.2,
              19.3,
              18.2,
              19,
              18.2,
              19,
              29.2,
              13.1,
              13.6,
              22.8,
              0,
              29.2,
              19,
              18.2,
              19.9,
              12.1,
              12.1,
              19,
              13.2,
              31.4,
              8.8,
              19,
              12.1,
              13.1,
              13.6,
              25.4,
              24.7,
              25,
              16.6,
              34.5,
              34.5,
              34.5,
              34.5,
              34.5,
              34.5,
              49.1,
              30.3,
              32,
              32,
              32,
              32,
              24.3,
              24.3,
              24.3,
              24.3,
              32.2,
              33.1,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              19.9,
              30.1,
              34.2,
              34.2,
              34.2,
              34.2,
              35.4,
              28.4,
              11.1,
              13.1,
              13.1,
              13.1,
              13.1,
              13.1,
              13.1,
              16.7,
              9.2,
              9.4,
              9.4,
              9.4,
              9.4,
              6.4,
              6.4,
              6.4,
              6.4,
              11,
              14.9,
              11,
              11,
              11,
              11,
              11,
              19.9,
              11,
              11.9,
              11.9,
              11.9,
              11.9,
              11.7,
              9.9
          ],
          "45": [
              7.9,
              12.9,
              12.9,
              21.5,
              21.5,
              21.5,
              24.1,
              40,
              14.4,
              19.8,
              19.8,
              12.5,
              23.5,
              10,
              16.1,
              10,
              23.8,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              10,
              10,
              23.5,
              23.5,
              23.5,
              19.6,
              30.6,
              40.9,
              41.8,
              35.9,
              38.1,
              37.8,
              29.7,
              33,
              38.8,
              28.7,
              25.3,
              39.6,
              34.1,
              44,
              39.2,
              35.7,
              34.6,
              31.6,
              41.6,
              31.8,
              26.4,
              40.5,
              33.7,
              41.6,
              45.2,
              41.9,
              29.4,
              19.8,
              23.8,
              19.8,
              22.5,
              22.5,
              22.5,
              15.5,
              11.8,
              10.9,
              15.5,
              11.1,
              6,
              15.1,
              14.1,
              7.6,
              6.8,
              13.5,
              7.2,
              24.5,
              17.6,
              13.1,
              11.9,
              13,
              12.5,
              8.8,
              7.4,
              14.1,
              12.3,
              19.1,
              15.7,
              13.9,
              12.5,
              19.8,
              22.5,
              19.8,
              30,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              8,
              12.9,
              21.5,
              21.5,
              22.9,
              21.5,
              22.5,
              21.5,
              22.5,
              34.6,
              15.5,
              16.1,
              27,
              0,
              34.6,
              22.5,
              21.5,
              23.5,
              14.3,
              14.3,
              22.5,
              15.7,
              37.2,
              10.4,
              22.5,
              14.3,
              15.5,
              16.1,
              30,
              29.3,
              29.6,
              19.6,
              40.9,
              40.9,
              40.9,
              40.9,
              40.9,
              40.9,
              58.1,
              35.9,
              37.8,
              37.8,
              37.8,
              37.8,
              28.7,
              28.7,
              28.7,
              28.7,
              38.1,
              39.2,
              35.7,
              35.7,
              35.7,
              35.7,
              35.7,
              23.5,
              35.7,
              40.5,
              40.5,
              40.5,
              40.5,
              41.9,
              33.7,
              13.1,
              15.5,
              15.5,
              15.5,
              15.5,
              15.5,
              15.5,
              19.8,
              10.9,
              11.1,
              11.1,
              11.1,
              11.1,
              7.6,
              7.6,
              7.6,
              7.6,
              13.1,
              17.6,
              13.1,
              13.1,
              13.1,
              13.1,
              13.1,
              23.5,
              13.1,
              14.1,
              14.1,
              14.1,
              14.1,
              13.9,
              11.8
          ],
          "46": [
              8.2,
              13.2,
              13.2,
              22,
              22,
              22,
              24.6,
              40.9,
              14.8,
              20.2,
              20.2,
              12.8,
              24.1,
              10.3,
              16.5,
              10.3,
              24.3,
              22,
              22,
              22,
              22,
              22,
              22,
              22,
              22,
              22,
              22,
              10.3,
              10.3,
              24.1,
              24.1,
              24.1,
              20.1,
              31.3,
              41.8,
              42.7,
              36.7,
              39,
              38.7,
              30.4,
              33.8,
              39.7,
              29.4,
              25.9,
              40.5,
              34.9,
              45,
              40.1,
              36.5,
              35.4,
              32.3,
              42.5,
              32.5,
              27,
              41.4,
              34.5,
              42.5,
              46.2,
              42.8,
              30,
              20.2,
              24.3,
              20.2,
              23,
              23,
              23,
              15.8,
              12.1,
              11.1,
              15.8,
              11.3,
              6.1,
              15.4,
              14.4,
              7.7,
              6.9,
              13.8,
              7.4,
              25,
              18,
              13.3,
              12.2,
              13.3,
              12.8,
              9,
              7.6,
              14.4,
              12.6,
              19.5,
              16,
              14.2,
              12.8,
              20.2,
              23,
              20.2,
              30.7,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              23,
              8.1,
              13.2,
              22,
              22,
              23.4,
              22,
              23,
              22,
              23,
              35.3,
              15.8,
              16.5,
              27.6,
              0,
              35.3,
              23,
              22,
              24.1,
              14.6,
              14.6,
              23,
              16,
              38,
              10.6,
              23,
              14.6,
              15.8,
              16.5,
              30.7,
              29.9,
              30.3,
              20.1,
              41.8,
              41.8,
              41.8,
              41.8,
              41.8,
              41.8,
              59.4,
              36.7,
              38.7,
              38.7,
              38.7,
              38.7,
              29.4,
              29.4,
              29.4,
              29.4,
              39,
              40.1,
              36.5,
              36.5,
              36.5,
              36.5,
              36.5,
              24.1,
              36.5,
              41.4,
              41.4,
              41.4,
              41.4,
              42.8,
              34.4,
              13.4,
              15.8,
              15.8,
              15.8,
              15.8,
              15.8,
              15.8,
              20.2,
              11.1,
              11.3,
              11.3,
              11.3,
              11.3,
              7.7,
              7.7,
              7.7,
              7.7,
              13.3,
              18,
              13.3,
              13.3,
              13.3,
              13.3,
              13.3,
              24.1,
              13.3,
              14.4,
              14.4,
              14.4,
              14.4,
              14.2,
              12
          ],
          "47": [
              8.3,
              13.5,
              13.5,
              22.5,
              22.5,
              22.5,
              25.2,
              41.8,
              15.1,
              20.6,
              20.6,
              13.1,
              24.6,
              10.5,
              16.8,
              10.5,
              24.9,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              22.5,
              10.5,
              10.5,
              24.6,
              24.6,
              24.6,
              20.5,
              32,
              42.7,
              43.7,
              37.5,
              39.8,
              39.5,
              31,
              34.5,
              40.6,
              30,
              26.5,
              41.4,
              35.7,
              46,
              40.9,
              37.3,
              36.1,
              33,
              43.5,
              33.2,
              27.6,
              42.3,
              35.2,
              43.4,
              47.2,
              43.8,
              30.7,
              20.6,
              24.9,
              20.6,
              23.5,
              23.5,
              23.5,
              16.2,
              12.4,
              11.4,
              16.2,
              11.6,
              6.2,
              15.7,
              14.7,
              7.9,
              7.1,
              14.1,
              7.5,
              25.6,
              18.4,
              13.6,
              12.5,
              13.6,
              13.1,
              9.2,
              7.8,
              14.7,
              12.8,
              19.9,
              16.4,
              14.5,
              13.1,
              20.6,
              23.5,
              20.6,
              31.3,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              23.5,
              8.3,
              13.5,
              22.5,
              22.5,
              23.9,
              22.5,
              23.5,
              22.5,
              23.5,
              36.1,
              16.2,
              16.8,
              28.3,
              0,
              36.1,
              23.5,
              22.5,
              24.6,
              14.9,
              14.9,
              23.5,
              16.4,
              38.9,
              10.9,
              23.5,
              14.9,
              16.2,
              16.8,
              31.4,
              30.6,
              31,
              20.5,
              42.7,
              42.7,
              42.7,
              42.7,
              42.7,
              42.7,
              60.7,
              37.5,
              39.5,
              39.5,
              39.5,
              39.5,
              30,
              30,
              30,
              30,
              39.8,
              40.9,
              37.3,
              37.3,
              37.3,
              37.3,
              37.3,
              24.6,
              37.3,
              42.3,
              42.3,
              42.3,
              42.3,
              43.8,
              35.2,
              13.7,
              16.2,
              16.2,
              16.2,
              16.2,
              16.2,
              16.2,
              20.7,
              11.4,
              11.6,
              11.6,
              11.6,
              11.6,
              7.9,
              7.9,
              7.9,
              7.9,
              13.6,
              18.4,
              13.6,
              13.6,
              13.6,
              13.6,
              13.6,
              24.6,
              13.6,
              14.7,
              14.7,
              14.7,
              14.7,
              14.5,
              12.3
          ],
          "50": [
              8.8,
              14.4,
              14.4,
              23.9,
              23.9,
              23.9,
              26.8,
              44.5,
              16,
              21.9,
              21.9,
              13.9,
              26.1,
              11.2,
              17.9,
              11.2,
              26.4,
              23.9,
              23.9,
              23.9,
              23.9,
              23.9,
              23.9,
              23.9,
              23.9,
              23.9,
              23.9,
              11.2,
              11.2,
              26.1,
              26.1,
              26.1,
              21.8,
              34,
              45.4,
              46.5,
              39.8,
              42.4,
              42,
              33,
              36.7,
              43.1,
              31.9,
              28.1,
              44,
              37.9,
              48.9,
              43.6,
              39.6,
              38.5,
              35.1,
              46.2,
              35.4,
              29.3,
              45,
              37.5,
              46.2,
              50.2,
              46.6,
              32.6,
              21.9,
              26.4,
              21.9,
              25,
              25,
              25,
              17.2,
              13.2,
              12.1,
              17.2,
              12.3,
              6.6,
              16.7,
              15.6,
              8.4,
              7.5,
              15,
              8,
              27.2,
              19.6,
              14.5,
              13.3,
              14.5,
              13.9,
              9.7,
              8.3,
              15.6,
              13.6,
              21.2,
              17.4,
              15.4,
              13.9,
              21.9,
              25,
              21.9,
              33.3,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              25,
              8.8,
              14.4,
              23.9,
              23.9,
              25.4,
              23.9,
              25,
              23.9,
              25,
              38.4,
              17.2,
              17.9,
              30.1,
              0,
              38.4,
              25,
              23.9,
              26.1,
              15.9,
              15.9,
              25,
              17.4,
              41.4,
              11.5,
              25,
              15.9,
              17.2,
              17.9,
              33.4,
              32.5,
              32.9,
              21.8,
              45.4,
              45.4,
              45.4,
              45.4,
              45.4,
              45.4,
              64.6,
              39.8,
              42,
              42,
              42,
              42,
              31.9,
              31.9,
              31.9,
              31.9,
              42.4,
              43.6,
              39.6,
              39.6,
              39.6,
              39.6,
              39.6,
              26.1,
              39.6,
              45,
              45,
              45,
              45,
              46.6,
              37.4,
              14.6,
              17.2,
              17.2,
              17.2,
              17.2,
              17.2,
              17.2,
              22,
              12.1,
              12.3,
              12.3,
              12.3,
              12.3,
              8.4,
              8.4,
              8.4,
              8.4,
              14.5,
              19.6,
              14.5,
              14.5,
              14.5,
              14.5,
              14.5,
              26.1,
              14.5,
              15.6,
              15.6,
              15.6,
              15.6,
              15.4,
              13.1
          ],
          "59": [
              10.4,
              16.9,
              16.9,
              28.2,
              28.2,
              28.2,
              31.6,
              52.5,
              18.9,
              25.9,
              25.9,
              16.4,
              30.9,
              13.2,
              21.1,
              13.2,
              31.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              13.2,
              13.2,
              30.9,
              30.9,
              30.9,
              25.7,
              40.1,
              53.6,
              54.8,
              47,
              50,
              49.6,
              38.9,
              43.3,
              50.9,
              37.7,
              33.2,
              52,
              44.8,
              57.7,
              51.4,
              46.8,
              45.4,
              41.4,
              54.6,
              41.7,
              34.6,
              53.2,
              44.2,
              54.5,
              59.2,
              54.9,
              38.5,
              25.9,
              31.2,
              25.9,
              29.5,
              29.5,
              29.5,
              20.3,
              15.5,
              14.3,
              20.3,
              14.5,
              7.8,
              19.8,
              18.5,
              9.9,
              8.9,
              17.7,
              9.4,
              32.1,
              23.1,
              17.1,
              15.6,
              17.1,
              16.4,
              11.5,
              9.7,
              18.5,
              16.1,
              25,
              20.5,
              18.2,
              16.4,
              25.9,
              29.5,
              25.9,
              39.4,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              29.5,
              10.4,
              16.9,
              28.2,
              28.2,
              30,
              28.2,
              29.5,
              28.2,
              29.5,
              45.3,
              20.3,
              21.1,
              35.5,
              0,
              45.3,
              29.5,
              28.2,
              30.9,
              18.8,
              18.8,
              29.5,
              20.5,
              48.8,
              13.6,
              29.5,
              18.8,
              20.3,
              21.1,
              39.4,
              38.4,
              38.9,
              25.7,
              53.6,
              53.6,
              53.6,
              53.6,
              53.6,
              53.6,
              76.2,
              47,
              49.6,
              49.6,
              49.6,
              49.6,
              37.7,
              37.7,
              37.7,
              37.7,
              50,
              51.4,
              46.8,
              46.8,
              46.8,
              46.8,
              46.8,
              30.9,
              46.8,
              53.2,
              53.2,
              53.2,
              53.2,
              54.9,
              44.1,
              17.2,
              20.3,
              20.3,
              20.3,
              20.3,
              20.3,
              20.3,
              26,
              14.3,
              14.5,
              14.5,
              14.5,
              14.5,
              9.9,
              9.9,
              9.9,
              9.9,
              17.1,
              23.1,
              17.1,
              17.1,
              17.1,
              17.1,
              17.1,
              30.9,
              17.1,
              18.5,
              18.5,
              18.5,
              18.5,
              18.2,
              15.4
          ],
          "63": [
              11.2,
              18.1,
              18.1,
              30.1,
              30.1,
              30.1,
              33.7,
              56,
              20.2,
              27.7,
              27.7,
              17.5,
              32.9,
              14.1,
              22.5,
              14.1,
              33.3,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              30.1,
              14.1,
              14.1,
              32.9,
              32.9,
              32.9,
              27.5,
              42.9,
              57.2,
              58.5,
              50.2,
              53.4,
              53,
              41.6,
              46.2,
              54.4,
              40.2,
              35.5,
              55.5,
              47.8,
              61.6,
              54.9,
              50,
              48.4,
              44.2,
              58.3,
              44.5,
              37,
              56.8,
              47.2,
              58.2,
              63.2,
              58.7,
              41.1,
              27.7,
              33.3,
              27.7,
              31.5,
              31.5,
              31.5,
              21.7,
              16.6,
              15.3,
              21.7,
              15.5,
              8.4,
              21.1,
              19.7,
              10.6,
              9.5,
              18.9,
              10.1,
              34.3,
              24.6,
              18.3,
              16.7,
              18.2,
              17.5,
              12.3,
              10.4,
              19.7,
              17.2,
              26.7,
              21.9,
              19.4,
              17.6,
              27.7,
              31.5,
              27.7,
              42,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              31.5,
              11.1,
              18.1,
              30.1,
              30.1,
              32,
              30.1,
              31.5,
              30.1,
              31.5,
              48.4,
              21.7,
              22.5,
              37.9,
              0,
              48.4,
              31.5,
              30.1,
              32.9,
              20,
              20,
              31.5,
              21.9,
              52.1,
              14.6,
              31.5,
              20,
              21.7,
              22.5,
              42.1,
              41,
              41.5,
              27.5,
              57.2,
              57.2,
              57.2,
              57.2,
              57.2,
              57.2,
              81.3,
              50.2,
              53,
              53,
              53,
              53,
              40.2,
              40.2,
              40.2,
              40.2,
              53.4,
              54.9,
              50,
              50,
              50,
              50,
              50,
              32.9,
              50,
              56.8,
              56.8,
              56.8,
              56.8,
              58.7,
              47.1,
              18.3,
              21.7,
              21.7,
              21.7,
              21.7,
              21.7,
              21.7,
              27.7,
              15.3,
              15.5,
              15.5,
              15.5,
              15.5,
              10.6,
              10.6,
              10.6,
              10.6,
              18.3,
              24.6,
              18.3,
              18.3,
              18.3,
              18.3,
              18.3,
              32.9,
              18.3,
              19.7,
              19.7,
              19.7,
              19.7,
              19.4,
              16.5
          ],
          "71": [
              12.5,
              20.4,
              20.4,
              33.9,
              33.9,
              33.9,
              38,
              63.1,
              22.8,
              31.2,
              31.2,
              19.7,
              37.1,
              15.8,
              25.4,
              15.8,
              37.5,
              33.9,
              33.9,
              33.9,
              33.9,
              33.9,
              33.9,
              33.9,
              33.9,
              33.9,
              33.9,
              15.8,
              15.8,
              37.1,
              37.1,
              37.1,
              31,
              48.3,
              64.5,
              66,
              56.6,
              60.1,
              59.7,
              46.9,
              52.1,
              61.3,
              45.3,
              40,
              62.5,
              53.9,
              69.4,
              61.8,
              56.3,
              54.6,
              49.9,
              65.7,
              50.2,
              41.7,
              64,
              53.2,
              65.6,
              71.3,
              66.1,
              46.4,
              31.2,
              37.5,
              31.2,
              35.5,
              35.5,
              35.5,
              24.4,
              18.7,
              17.2,
              24.4,
              17.5,
              9.4,
              23.8,
              22.2,
              11.9,
              10.7,
              21.4,
              11.4,
              38.6,
              27.8,
              20.6,
              18.8,
              20.5,
              19.7,
              13.8,
              11.7,
              22.2,
              19.4,
              30.1,
              24.7,
              21.9,
              19.8,
              31.2,
              35.5,
              31.2,
              47.4,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              35.5,
              12.5,
              20.4,
              33.9,
              33.9,
              36.1,
              33.9,
              35.5,
              33.9,
              35.5,
              54.5,
              24.4,
              25.4,
              42.7,
              0,
              54.5,
              35.5,
              33.9,
              37.1,
              22.6,
              22.6,
              35.5,
              24.7,
              58.7,
              16.4,
              35.5,
              22.6,
              24.4,
              25.4,
              47.4,
              46.2,
              46.8,
              31,
              64.5,
              64.5,
              64.5,
              64.5,
              64.5,
              64.5,
              91.7,
              56.6,
              59.7,
              59.7,
              59.7,
              59.7,
              45.3,
              45.3,
              45.3,
              45.3,
              60.1,
              61.8,
              56.3,
              56.3,
              56.3,
              56.3,
              56.3,
              37.1,
              56.3,
              64,
              64,
              64,
              64,
              66.1,
              53.1,
              20.7,
              24.4,
              24.4,
              24.4,
              24.4,
              24.4,
              24.4,
              31.2,
              17.2,
              17.5,
              17.5,
              17.5,
              17.5,
              11.9,
              11.9,
              11.9,
              11.9,
              20.6,
              27.8,
              20.6,
              20.6,
              20.6,
              20.6,
              20.6,
              37.1,
              20.6,
              22.2,
              22.2,
              22.2,
              22.2,
              21.9,
              18.5
          ],
          "74": [
              13,
              21.2,
              21.2,
              35.4,
              35.4,
              35.4,
              39.6,
              65.8,
              23.7,
              32.5,
              32.5,
              20.6,
              38.7,
              16.5,
              26.5,
              16.5,
              39.1,
              35.4,
              35.4,
              35.4,
              35.4,
              35.4,
              35.4,
              35.4,
              35.4,
              35.4,
              35.4,
              16.5,
              16.5,
              38.7,
              38.7,
              38.7,
              32.3,
              50.3,
              67.2,
              68.8,
              59,
              62.7,
              62.2,
              48.9,
              54.3,
              63.8,
              47.2,
              41.7,
              65.2,
              56.2,
              72.4,
              64.5,
              58.7,
              56.9,
              52,
              68.4,
              52.3,
              43.4,
              66.7,
              55.4,
              68.4,
              74.3,
              68.9,
              48.3,
              32.5,
              39.1,
              32.5,
              37,
              37,
              37,
              25.5,
              19.5,
              17.9,
              25.5,
              18.2,
              9.8,
              24.8,
              23.2,
              12.4,
              11.2,
              22.3,
              11.9,
              40.3,
              28.9,
              21.5,
              19.6,
              21.4,
              20.6,
              14.4,
              12.2,
              23.2,
              20.2,
              31.4,
              25.8,
              22.8,
              20.6,
              32.5,
              37,
              32.5,
              49.4,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              37,
              13.1,
              21.2,
              35.4,
              35.4,
              37.6,
              35.4,
              37,
              35.4,
              37,
              56.8,
              25.5,
              26.5,
              44.5,
              0,
              56.8,
              37,
              35.4,
              38.7,
              23.5,
              23.5,
              37,
              25.8,
              61.2,
              17.1,
              37,
              23.5,
              25.5,
              26.5,
              49.4,
              48.1,
              48.7,
              32.3,
              67.2,
              67.2,
              67.2,
              67.2,
              67.2,
              67.2,
              95.5,
              59,
              62.2,
              62.2,
              62.2,
              62.2,
              47.2,
              47.2,
              47.2,
              47.2,
              62.7,
              64.5,
              58.7,
              58.7,
              58.7,
              58.7,
              58.7,
              38.7,
              58.7,
              66.7,
              66.7,
              66.7,
              66.7,
              68.9,
              55.4,
              21.5,
              25.5,
              25.5,
              25.5,
              25.5,
              25.5,
              25.5,
              32.6,
              17.9,
              18.2,
              18.2,
              18.2,
              18.2,
              12.4,
              12.4,
              12.4,
              12.4,
              21.5,
              28.9,
              21.5,
              21.5,
              21.5,
              21.5,
              21.5,
              38.7,
              21.5,
              23.2,
              23.2,
              23.2,
              23.2,
              22.8,
              19.3
          ],
          "82": [
              14.5,
              23.5,
              23.5,
              39.2,
              39.2,
              39.2,
              43.9,
              72.9,
              26.3,
              36,
              36,
              22.8,
              42.9,
              18.3,
              29.3,
              18.3,
              43.4,
              39.2,
              39.2,
              39.2,
              39.2,
              39.2,
              39.2,
              39.2,
              39.2,
              39.2,
              39.2,
              18.3,
              18.3,
              42.9,
              42.9,
              42.9,
              35.8,
              55.8,
              74.5,
              76.2,
              65.3,
              69.5,
              68.9,
              54.1,
              60.2,
              70.7,
              52.3,
              46.2,
              72.2,
              62.2,
              80.2,
              71.4,
              65,
              63.1,
              57.6,
              75.8,
              58,
              48.1,
              73.9,
              61.4,
              75.8,
              82.3,
              76.4,
              53.5,
              36,
              43.4,
              36,
              41,
              41,
              41,
              28.2,
              21.6,
              19.9,
              28.2,
              20.2,
              10.9,
              27.5,
              25.7,
              13.8,
              12.4,
              24.7,
              13.1,
              44.6,
              32.1,
              23.8,
              21.7,
              23.7,
              22.8,
              16,
              13.5,
              25.7,
              22.4,
              34.8,
              28.5,
              25.3,
              22.9,
              36,
              41,
              36,
              54.7,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              41,
              14.5,
              23.5,
              39.2,
              39.2,
              41.6,
              39.2,
              41,
              39.2,
              41,
              63,
              28.2,
              29.3,
              49.3,
              0,
              63,
              41,
              39.2,
              42.9,
              26.1,
              26.1,
              41,
              28.5,
              67.8,
              18.9,
              41,
              26.1,
              28.2,
              29.3,
              54.7,
              53.3,
              54,
              35.8,
              74.5,
              74.5,
              74.5,
              74.5,
              74.5,
              74.5,
              105.9,
              65.3,
              68.9,
              68.9,
              68.9,
              68.9,
              52.3,
              52.3,
              52.3,
              52.3,
              69.5,
              71.4,
              65,
              65,
              65,
              65,
              65,
              42.9,
              65,
              73.9,
              73.9,
              73.9,
              73.9,
              76.4,
              61.3,
              23.9,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              28.2,
              36.1,
              19.9,
              20.2,
              20.2,
              20.2,
              20.2,
              13.8,
              13.8,
              13.8,
              13.8,
              23.8,
              32.1,
              23.8,
              23.8,
              23.8,
              23.8,
              23.8,
              42.9,
              23.8,
              25.7,
              25.7,
              25.7,
              25.7,
              25.3,
              21.4
          ],
          "120": [
              47.7,
              77.5,
              77.5,
              129.1,
              129.1,
              129.1,
              144.5,
              240.1,
              86.6,
              118.5,
              118.5,
              75,
              141.2,
              60.2,
              96.6,
              60.2,
              142.8,
              129.1,
              129.1,
              129.1,
              129.1,
              129.1,
              129.1,
              129.1,
              129.1,
              129.1,
              129.1,
              60.2,
              60.2,
              141.2,
              141.2,
              141.2,
              117.7,
              183.6,
              245.2,
              250.9,
              215.2,
              228.7,
              227,
              178.2,
              198.1,
              233,
              172.3,
              152,
              237.8,
              204.9,
              264.1,
              235.2,
              214.1,
              207.6,
              189.6,
              249.7,
              190.9,
              158.5,
              243.2,
              202.2,
              249.4,
              271.1,
              251.4,
              176.3,
              118.5,
              142.8,
              118.5,
              135,
              135,
              135,
              92.9,
              71.1,
              65.4,
              92.9,
              66.4,
              35.9,
              90.4,
              84.5,
              45.4,
              40.7,
              81.2,
              43.2,
              146.9,
              105.6,
              78.3,
              71.6,
              78,
              75,
              52.6,
              44.6,
              84.5,
              73.7,
              114.4,
              94,
              83.2,
              75.3,
              118.5,
              135,
              118.5,
              180.1,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              135,
              47.7,
              77.5,
              129.1,
              129.1,
              137.1,
              129.1,
              135,
              129.1,
              135,
              207.4,
              92.9,
              96.6,
              162.3,
              0,
              207.4,
              135,
              129.1,
              141.2,
              85.8,
              85.8,
              135,
              94,
              223.3,
              62.4,
              135,
              85.8,
              92.9,
              96.6,
              180.2,
              175.6,
              177.8,
              117.7,
              245.2,
              245.2,
              245.2,
              245.2,
              245.2,
              245.2,
              348.6,
              215.2,
              227,
              227,
              227,
              227,
              172.3,
              172.3,
              172.3,
              172.3,
              228.7,
              235.2,
              214.1,
              214.1,
              214.1,
              214.1,
              214.1,
              141.2,
              214.1,
              243.2,
              243.2,
              243.2,
              243.2,
              251.4,
              202,
              78.6,
              92.9,
              92.9,
              92.9,
              92.9,
              92.9,
              92.9,
              118.8,
              65.4,
              66.4,
              66.4,
              66.4,
              66.4,
              45.4,
              45.4,
              45.4,
              45.4,
              78.3,
              105.6,
              78.3,
              78.3,
              78.3,
              78.3,
              78.3,
              141.2,
              78.3,
              84.5,
              84.5,
              84.5,
              84.5,
              83.2,
              70.5
          ]
              }
    }

  }