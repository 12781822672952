import { Injectable } from "@angular/core";
import { FTDate } from "../models/FTDate";
import { GenealogyDate } from "./date/genealogy-date";
import { GenealogyDateRange } from "./date/genealogy-date-range";

@Injectable({
    providedIn: 'root'
})
export class Validate {

    isValidEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isGenealogyDateSet(targetDate: FTDate, $event: { genealogyDate: GenealogyDate; genealogyDateRange: GenealogyDateRange; } ): boolean {
        if ($event.genealogyDate != null) {
          targetDate.genealogyDate = $event.genealogyDate;
          targetDate.genealogyDateRange = null;
          return true;
        } else {
          targetDate.genealogyDateRange = $event.genealogyDateRange;
          targetDate.genealogyDate = null;
          return false;
        }
    }
}