import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from '../models/ParentMessage';


@Injectable()
export class MissingTranslatehandler extends MissingTranslationHandler {

    constructor(private parentCommunicatorService: ParentCommunicatorService) {
        super();
    }

    handle(params: MissingTranslationHandlerParams) {
        // open information popup in MissingTranslation after no file loaded
        if (params.translateService.translations.no) {
            this.parentCommunicatorService.send(
                ParentMessageType.ShowInformation,
                "translation missing in " + params.key
            );
        }
        return '[MISSING TRANSLATON]' + params.key;
    }

}