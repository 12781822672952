import { Injectable, OnInit } from '@angular/core';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { NotifierEvents } from '../../../common/enums/enums';

@Injectable({
  providedIn: 'root'
})

export class ComponentBase implements OnInit {
  constructor(protected notifierService: NotifierV2Service) {
    this.register(this);
  }

  registerOn : string = 'init';
  isDirty: boolean;
  componentName: string;
  expectedUpdates: NotifierEvents[];

  public ngOnInit() {
  }

  public notify() {
  }

  public canChange(): boolean{
    return true;
  }

  public show(params: any) {

  }

  public hide() {
    
  }
  
  public register(component: ComponentBase) {
    this.notifierService.registerComponent(component);
  }

  public remove(component: ComponentBase) {
    this.notifierService.removeComponent(component);
  }

  ngOnDestroy(): void {
    this.remove(this);
  }
}
