<div class="event-carousel-content" style="width: 185px;">
    <!-- side bar -->
    <div *ngIf="eventIndividuals.length > 1">
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-left.png" style="display: block;cursor: pointer;" (click) = "slideLeft()" />
        
    </div>
    <!-- content -->
    <div style="width: 100%;display: flex;flex-direction: column;padding: 0px 5px 0px 5px;max-width: 170px;gap:5px">

        <div style="display: flex;flex-direction: row;">
            <div>               
                <img class="primary-image" [src]="getIndividualImageUrl()">                
            </div>
            <div class="flexrow" style="margin-left: 10px; margin-top: 5px;">                
                <span class="tooltip-heading-text">{{"name" | translate}}</span>      
                <span class="tooltip-content-text text-ellipsis-2" style="margin-top: -2px;-webkit-line-clamp: 2 !important;">{{currentIndividualRecord.displayName}}</span>    
            </div>                    
        </div>
    </div>
    <!-- side bar -->
    <div *ngIf="eventIndividuals.length > 1">
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-right.png" style="display: block;cursor: pointer;" (click) = "slideRight()" />
    </div>
</div>
