import { CircularRandom } from "./circular-random";

export class CircularRange extends CircularRandom{
 
    // move random nodes up and down in a range according to the circular levels. 
    generateRandomNodes(nodeList, rootNode) {
        for(let node of nodeList){
            if (node.level !== 0 ){
                let angle = Math.atan((node.y - rootNode.y)/(node.x - rootNode.x))
                let marginValues     = this.randomConfig.margins[node.level];
                let randomDistance   = Math.floor(Math.random() * (marginValues.top + marginValues.bottom) - marginValues.bottom);
                node.x = node.x + randomDistance*Math.cos(angle)
                node.y = node.y + randomDistance*Math.sin(angle)
            } 
        }
    }

}