import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';

@Component({
  selector: 'app-family-editor-main',
  templateUrl: './family-editor-main.component.html',
  styleUrls: ['./family-editor-main.component.scss']
})
export class FamilyEditorMainComponent implements OnInit {

  // Note: this won't include the events. Instead, Family-editor.component will include ;
  @Input() data: any;
    eventTitle: string;
    showComponent: boolean = false;
  constructor(private location : Location,
              private activateRouter : ActivatedRoute,
              private notifierService: NotifierV2Service) { }

  ngOnInit(): void {
    // let dataObj = this.location.getState() as any;   
    // this.data = dataObj;

    //change @EM-10562
    let paramId = this.activateRouter.snapshot.paramMap.get('id') as any;
    let paramIsBreadcrumbUpdate = this.activateRouter.snapshot.paramMap.get('isBreadcrumbUpdate') as any;

    this.data = {
      id: paramId,
      isBreadcrumbUpdate: paramIsBreadcrumbUpdate
    }
  }

  changeData(updatedData) {
    this.data = {
      id: updatedData.id,
      editorMode: updatedData.editorMode,
      rootFamilyId: this.notifierService.getCurrentRootFamilyId() //2023/01/04 - To detect the breadcrumb change
    }
  }

  setTitle(eventTitle){
    this.eventTitle = eventTitle;
  }

  public showMain(value): void {
    this.showComponent = value;
  }

}
