
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ParentCommunicatorService } from './parent-communicator.service';
import { ParentMessageType } from '../common/models/ParentMessage';
import { CustomError } from '../common/helpers/custom-error';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private parentCommunicator: ParentCommunicatorService) { }

    handleError(error) {
        if (error instanceof HttpErrorResponse)
            this.handleHttpError(error);
        else if (error instanceof CustomError)
            this.handleCustomError(error)
        else if (error instanceof EvalError)
            console.log("===========Global error handler - Eval Error ========== ", error);
        else if (error instanceof RangeError)
            console.log("===========Global error handler - Range Error ========== ", error);
        else if (error instanceof ReferenceError)
            console.log("===========Global error handler - EReference Error ========== ", error);
        else if (error instanceof SyntaxError)
            console.log("===========Global error handler - Syntax Error ========== ", error);
        else if (error instanceof TypeError)
            console.log("===========Global error handler - Type Error ========== ", error);
        else if (error instanceof URIError)
            console.log("===========Global error handler - URI Error ========== ", error);
        else {
            console.log("===========Global error handler ========== ", error);
            this.parentCommunicator.send(ParentMessageType.ErrorOccured, '911');
        }

        // your custom error handling logic
    }

    handleHttpError(error: HttpErrorResponse) {

        console.log("===========Global error handler: handleHttpError() ========== ", error);
        let errorCode = '';
        if (error instanceof ErrorEvent) {
            // Get client-side error
            errorCode = error.status.toString();
        } else {
            // Get server-side error for log
            if (error.error && error.error.errors)
                errorCode = error.error.errors[0].toString();
            else
                errorCode = error.status.toString();
        }
        this.parentCommunicator.send(ParentMessageType.ErrorOccured, errorCode);
    }

    handleCustomError(error: CustomError) {

        if (error.logOnly)
            console.log("===========Global error handler: handleCustomError() ========== ", error);
        else {
            console.log("===========Global error handler: handleCustomError() ========== ", error);
            this.parentCommunicator.send(ParentMessageType.CustomErrorOccured, error.status.toString());
        }
    }
}
