import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import {ReportengineModule} from '../../reportengine/reportengine.module'
import { ReportOptionsPopupComponent } from "../common/reportOptions/reportOptionsPopup/report-options-popup.component";
import { TimelineReportComponent } from "./timelinereport/timelinereport.component";
import { ReportInfoTooltipComponent } from './report-info-tooltip/report-info-tooltip.component';



@NgModule({
    declarations: [TimelineReportComponent, ReportInfoTooltipComponent],
    imports: [
      ReportengineModule,
      CommonModule,
      FormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient]
        }
      })],
      exports:[],
      entryComponents:[TimelineReportComponent,ReportOptionsPopupComponent]
})
export class TimelineReportModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
