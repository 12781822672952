import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../api-base.service';
import { catchError, timeout } from 'rxjs/operators';
import { CustomError } from 'src/app/common/helpers/custom-error';

@Injectable({
  providedIn: 'root'
})
export class MultimediaApiService {

  constructor(private apiBaseService: ApiBaseService,private httpClient: HttpClient) { }

  getIndividualMultiMediaData<T>(data: any): Observable<T> {
    return this.apiBaseService.get('/api/multimedia', null, data); // add url to config file
  }

  getMultimediaById<T>(mediaId: number): Observable<T> {
    return this.apiBaseService.get("/api/multimedia/"+mediaId, [], null);
  }

  //Need to be remove after new implementation (createMultimedia)
  uploadIndividualMultiMediaData<T>(data: any): Observable<T> {
    return this.apiBaseService.create('/api/multimedia', null, data); // add url to config file
  }

  createMultimedia<T>(data: any): Observable<T> {
    return this.apiBaseService.create('/api/multimedia', null, data); 
  }

  //Need to be remove after new implementation (updateMultimedia)
  editIndividualMultiMediaData<T>(data: any, id: number): Observable<T> {
    return this.apiBaseService.update('/api/multimedia/' + id, null, data); // add url to config file
  }

  updateMultiMedia<T>(data: any): Observable<T> {
    return this.apiBaseService.update('/api/multimedia/', null, data); // add url to config file
  }

  deleteIndividualMultiMediaData(data: any): Observable<any> {
    return this.apiBaseService.delete('/api/multimedia/' + data, null, data); // add url to config file
  }

  getMediaReferences<T>(data): Observable<T> {
    return this.apiBaseService.get('/api/multimedia/' + data + '/references', null, data);
  }

  getAllMultimedia<T>(size: number = null, page: number = null): Observable<T> {
    return this.apiBaseService.get("/api/multimedia", [], null);
  }

  getMyMultimedia<T>(referenceId : number , referenceType : number,size: number = null, page: number = null): Observable<T> {
    return this.apiBaseService.get('/api/multimedia/' + referenceId + '/' + referenceType, [], null);
  }
  getReferenceToMedia(mediaId: number): Observable<any> {
    return this.apiBaseService.get("/api/multimedia/" + mediaId + "/references", [], null);
  }

  deleteMedia(mediaId: number): Observable<any> {
     return this.apiBaseService.delete('/api/multimedia/' + mediaId, null, mediaId);
  }

  //TODO: Url change
  colorizeImage(data:any): Observable<any> { // colorize API
    const TIMEOUT = 25000; // in miliseconds
    return this.httpClient.post('https://deoldify.embla.no/process', data, { responseType: 'blob' }).
    pipe(
     timeout(TIMEOUT),    
     catchError(err => {
       throw new CustomError("Request timeout" + err, 408, false)
     })
   );
  }
}
