import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiBaseService } from "../api-base.service";
import { CustomerFeature } from 'src/app/common/models/MemberAccess/CustomerFeature';

@Injectable({
  providedIn: 'root'
})

export class CustomerFeaturesService {
  constructor(private apiBaseService: ApiBaseService, private httpClient: HttpClient) { }

  getFeatureAccessById<T>(featureId: number): Observable<T> {
    return this.apiBaseService.get("/api/customerfeatures/" + featureId, [], null);
  }
  create<T>(customerFeatures: CustomerFeature): Observable<T> {
    return this.apiBaseService.create('/api/customerfeatures', null, customerFeatures);
  }
  update<T>(customerFeatures: CustomerFeature): Observable<T> {
    return this.apiBaseService.update('/api/customerfeatures', null, customerFeatures);
  }

}