import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutusMainComponent } from './aboutus-main/aboutus-main.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AboutusEmblaComponent } from './aboutus-embla/aboutus-embla.component';
import { AboutusContributorsComponent } from './aboutus-contributors/aboutus-contributors.component';
import { ContributorSectionComponent } from './contributor-section/contributor-section.component';

@NgModule({
  declarations: [AboutusMainComponent, AboutusEmblaComponent, AboutusContributorsComponent, ContributorSectionComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class AboutusModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
