<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
  <!-- Side title -->
  <div class="dialog-title-container">
    <div class="dialog-title-text">{{"lbl_add_relationship" | translate}}</div>
  </div>
  <!-- Tab container -->
  <div class="tab-container app-dark-bg" style="padding:15px 0px 0px 0px">
    <div class="tabs">
      <a class="active">{{"lbl_select_relationship" | translate}}</a>
    </div>
    <div class="tab-forms app-light-bg">
      <div class="ft-form" style="padding:15px 0px 0px 0px;">
        <div class="content" style="padding:0px 15px 0px 40px">
          <div class="ft-form-row">
            <span class="title">{{title}}</span>
          </div>
          <div class="ft-form-row">
            <div style="display: flex;flex-direction: row;gap: 20px;">
              <div style="width: 125px;height: 125px;">
                <img [src]="profileImageUrl" width="125" height="125">
              </div>
              <div style="display: flex; flex-direction: column; align-items: left;justify-content: center;">
                <div class="label-xxl-grey-bold">{{displayName}}</div>
                <!-- <div class="label-xl-grey">Current adoptive parents - Stein haalan and Keren</div> -->
              </div>
            </div>
          </div>

          <div class="ft-form-row col1-v100">
            <div class="ft-form-input border-outline radio-border-select">
              <div style="display: flex;flex-direction: column; width: 100%;">
                <div class="label-l-grey-bold">{{"lbl_biological" | translate}}</div>
                <div class="label-l-grey">{{"lbl_child_biological_family"| translate}}</div>
                <div class="label-s-green" *ngIf="childRelationshipViewModel?.biologicalFamilyName?.length > 0" >{{"lbl_current_parents"| translate}} - {{childRelationshipViewModel.biologicalFamilyName}}</div>
                <label class="input-checked-container input-checkbox-large" *ngIf="showBloodlineDefaultOption">{{"lbl_set_this_family_as_default"| translate}}
                  <input type="checkbox" name="isNatural" [(ngModel)]="isDefault"><span class="checkmark"></span>
                </label>
              </div>
              <div class="input-radio-container" style="margin-right: 10px;">
                <input type="radio"  name="relationship" value="bloodline" (change)="onItemChange($event)">
                <span class="radiomark"></span>
              </div>
            </div>
          </div>

          <div class="ft-form-row col1-v100">
            <div class="ft-form-input border-outline radio-border-select">
              <div style="display: flex;flex-direction: column; width: 100%;">
                <div class="label-l-grey-bold">{{"lbl_adapted" | translate}}</div>
                <div class="label-l-grey">{{"lbl_child_adpted_family"| translate}}</div>
                <div class="label-s-grey-light" *ngIf="childRelationshipViewModel?.adoptiveFamilyName?.length > 0">{{"lbl_current_apoptive_parents"| translate}} - {{childRelationshipViewModel.adoptiveFamilyName}}</div>
                <label class="input-checked-container input-checkbox-large" *ngIf="showAdoptedDefaultOption">{{"lbl_set_this_family_as_default"| translate}}
                  <input type="checkbox" name="isNatural" [(ngModel)]="isDefault"><span class="checkmark"></span>
                </label>
              </div>
              <div class="input-radio-container" style="margin-right: 10px;">
                <input type="radio"  name="relationship" value="adapted" (change)="onItemChange($event)">
                <span class="radiomark"></span>
              </div>
            </div>
          </div>

          <div class="ft-form-row col1-v100">
            <div class="ft-form-input border-outline radio-border-select">
              <div style="display: flex;flex-direction: column; width: 100%;">
                <div class="label-l-grey-bold">{{"lbl_foster" | translate}}</div>
                <div class="label-l-grey">{{"lbl_child_foster_family"| translate}}</div>
                <div class="label-s-grey-light" *ngIf="childRelationshipViewModel?.fosterFamilyName?.length > 0">{{"lbl_current_foster_parents"| translate}} - {{childRelationshipViewModel.fosterFamilyName}}</div>
                <label class="input-checked-container input-checkbox-large" *ngIf="showFosterDefaultOption">{{"lbl_set_this_family_as_default"| translate}}
                  <input type="checkbox" name="isNatural" [(ngModel)]="isDefault"><span class="checkmark"></span>
                </label>
              </div>
              <div class="input-radio-container" style="margin-right: 10px;">
                <input type="radio" name="relationship" value="foster" (change)="onItemChange($event)">
                <span class="radiomark"></span>
              </div>
            </div>
          </div>

          <div class="ft-form-row col1-v100">
            <div class="ft-form-input border-outline radio-border-select">
              <div style="display: flex;flex-direction: column; width: 100%;">
                <div class="label-l-grey-bold">{{"lbl_special_relationship" | translate}}</div>
                <div class="label-l-grey">{{"lbl_child_special_family"| translate}}</div>
                <div class="label-s-grey-light" *ngIf="childRelationshipViewModel?.specialFamilyName?.length > 0">{{"lbl_current_special_parents"| translate}} - {{childRelationshipViewModel.specialFamilyName}}</div>
                <label class="input-checked-container input-checkbox-large" *ngIf="showSpecialDefaultOption">{{"lbl_set_this_family_as_default"| translate}}
                  <input type="checkbox" name="isNatural" [(ngModel)]="isDefault"><span class="checkmark"></span>
                </label>
              </div>
              <div class="input-radio-container" style="margin-right: 10px;">
                <input type="radio" name="relationship" value="special" (change)="onItemChange($event)" >
                <span class="radiomark"></span>
              </div>
            </div>
          </div>

        </div>
        <div class="footer app-dark-bg">
          <button class="ft-btn" [ngClass]="{'ft-btn-inactive' : defaultView, 'ft-btn-active' : !defaultView}"  (click)="selectRelationship()" [disabled]="defaultView">{{"lbl_add" | translate}}</button>
          <button class="ft-btn ft-btn-active-not-selected"  (click)="cancel()">{{"lbl_cancel" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>