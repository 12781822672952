<svg>
    <!-- This label shows when there is data -->
    <g *ngIf="label.status == 'active'" [attr.id]="label.displayIndex" transform="translate(0,-85)">
        
    <!-- Enabled Leaves -->
    <g *ngIf="i%2 == 0" transform="translate(-90,15) scale(1 1)" class="custom-cursor-context-menu no-pointer-events">  
        <image height="115" width="115" href="assets/tree-editor/level_4/leave_4_bottom_color.svg" />
    </g> 

    <g *ngIf="i%2 == 1" transform="translate(-90,106) scale(1 1)" class="custom-cursor-context-menu no-pointer-events">  
        <image height="115" width="115" href="assets/tree-editor/level_4/leave_4_top_color.svg" />
    </g> 
        <image *ngIf="label.gender == 'M'" x="0" y="68" height="72" width="210" href="assets/tree-editor/level_4/lbl_level_4_male.svg" />
        <image *ngIf="label.gender == 'F'" x="0" y="68" height="72" width="210" href="assets/tree-editor/level_4/lbl_level_4_female.svg" />
        <image *ngIf="label.gender == 'U'" x="0" y="68" height="72" width="210" href="assets/tree-editor/level_4/lbl_level_4_unknown.svg" />

        <text  *ngIf="label.gender == 'M'"
        class="element-over-hand text-male male-color" (click)=changeRootPerson(label)>
            {{splitDisplayName[0]?.name}}</text>
        <text  *ngIf="label.gender == 'F'"       
        class="element-over-hand text-female female-color" (click)=changeRootPerson(label)>
            {{splitDisplayName[0]?.name}}</text>
        <text  *ngIf="label.gender == 'U'"     
        class="element-over-hand text-unknown unknown-color" (click)=changeRootPerson(label)>
            {{splitDisplayName[0]?.name}}</text>

    </g>

    <!-- This label shows when there is no data(Grey out label) --> 
    <g *ngIf="label.status == 'disable'" [attr.id]="label.displayIndex" transform="translate(0,-90)" class="element-over-hand" (click)="addParent(i, label)">
            
    <!-- Disabled Leaves -->
    <g *ngIf="i%2 == 0" transform="translate(-90,15) scale(1 1)" class="custom-cursor-context-menu no-pointer-events">  
        <image height="115" width="115" href="assets/tree-editor/level_4/leave_4_bottom_grey.svg" />
    </g> 

    <g *ngIf="i%2 == 1" transform="translate(-90,106) scale(1 1)" class="custom-cursor-context-menu no-pointer-events">  
        <image height="115" width="115" href="assets/tree-editor/level_4/leave_4_top_grey.svg" />
    </g> 

        <image x="0" y="68" height="72" height="72" width="210" href="assets/tree-editor/level_4/lbl_level_4_disable.svg" class="custom-cursor-context-menu no-pointer-events"/>
        <g class="element-over-hand">
        <text *ngIf="i%2 == 0" class="text-disable">+  {{"lbl_add_mother" | translate}}</text>
        <text *ngIf="i%2 !== 0" class="text-disable">+  {{"lbl_add_father" | translate}}</text>
        </g>
    </g>

</svg>    
