<div class="ft-form">
  <div class="content">
    <div class="ft-form-row" style="margin-bottom: 0px;">
      <div class="ft-form-row col1-v100" style="justify-content: space-between; margin-bottom: 0px;">
        <span class="title" style="width: 100%;">{{data.title}}</span>
        <!-- Filter Drop down -->
        <div class="filter" style="max-width: 175px;">
          <div style="display: flex; flex-wrap: wrap;">
            <div style="justify-content: center; font-weight: 600;" class="filterItem" (click)="showFilterDropdown()">
              <div class="item-text filterHeader-text">{{selectfilterText}}</div>
              <div ><img class="icon" src="assets/images/icons/dropdown-arrow.svg"></div>
            </div>
          </div>            
          <div *ngIf="isFilterDropDownOpen" class="dropDown">
            <div  class="filterItem" *ngFor="let recipient of mediaFilterMap | keyvalue">
              <div class="filterItem" [class.item-selected]="recipient.key == selectedfilterType" (click)="filterMedia(recipient.key , null)">
                <div><img class="icon" src="assets/nonimage-preview/Small-Icons/small_media_type_{{'lbl_' +recipient.key}}.svg"></div>
                <div class="item-text">{{'lbl_' + recipient.key | translate}} ({{recipient.value.length}})</div>
                <div *ngIf="recipient.key == selectedfilterType"><img class="icon" src="assets/images/icons/selected-green-check.svg"></div>
              </div>
            </div>
            <div class="filterItem" style="border-bottom: 1px solid #d9dad5;">
            </div>
            <div class="filterItem" *ngFor="let mediaType of getMediaCategories()">
              <div class="filterItem" [class.item-selected]="mediaType.id == selectedMediatype" (click)="filterMedia(null ,mediaType.id)">
                <div><img class="icon" src="assets/nonimage-preview/Small-Icons/small_media_type_{{mediaType.id}}.svg"></div>
                <div class = "item-text">{{mediaType.type | translate}} ({{mediaType.count}})</div>
                <div *ngIf="mediaType.id == selectedMediatype"><img class="icon" src="assets/images/icons/selected-green-check.svg"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Filter Drop down -->
      </div>
    </div>
    <!-- Caption span -->
    <span class="label-grey-base-normal"  style="margin-bottom: 25px; position: relative;">
      <div style="position: absolute;" *ngIf="setEnablity()">{{"lbl_you_have_selected" | translate}} {{selectedMediaTitle}}</div>
    </span> 
    <div class="ft-form-row">
      <div class="ft-form-row col1-v100">
        <div class="ft-form-input">
          <div class="icon-input-container">
            <input class ="icon-input" placeholder="{{'ph_search_all_media' | translate}}"
              type="text" [(ngModel)]="searchPhrase">
            <div class="icon-input-wrap">                      
              <img src="/assets/images/icons/search.svg"  width= "30px" height="30px">                       
            </div>                  
          </div>
        </div>
    </div>
    </div>         
    <app-media-gallery style="height:calc(100% - 100px)" [items]="mediaItems" [searchPhrase]="searchPhrase"  [isStandAlone]= "isStandAlone" (action)="dropMenuAction($event)"></app-media-gallery>          
  </div>
  <div class="footer">
    <button *ngIf="data.options.isAddBtn" class = "ft-btn" [ngClass]="{'ft-btn-active':setEnablity(),'ft-btn-inactive':!setEnablity()}" [disabled]="!setEnablity()" (click)="addMedia()">{{"lbl_add" | translate}}</button>
    <button *ngIf="data.options.isCloseBtn" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    <button class="ft-btn ft-btn-active-not-selected" style="display: none;" (click)="enableColorize()">colorize on</button>
    
  </div>
</div>
<!-- Test -->