<div class="event-carousel-content" style="width: 225px;">
    <!-- side bar -->
    <div *ngIf="eventSources.length > 1">
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-left.png" style="display: block;cursor: pointer;" (click) = "slideLeft()"/>
    </div>
    <!-- content -->
    <div style="width: 100%;display: flex;flex-direction: column;padding: 0px 5px 0px 5px;max-width: 160px;gap:5px">

        <div style="display: flex;flex-direction: row;">
            <div>               
                <img class="source-image" [src]="getSourceMediaUrl()">   
            </div>
            <div class="flexrow" style="margin-left: 10px; margin-top: -5px;">                
                <span class="tooltip-heading-text">{{"lbl_title" | translate}}</span>                
                <span class="tooltip-content-text text-ellipsis-2" style="margin-top: -2px !important;-webkit-line-clamp: 2 !important;">{{!currentSourceRecord.sourceBase.title ? '-':currentSourceRecord.sourceBase.title}}</span>                
            </div>                    
        </div>

        <div class="tooltip-col col2-v50v50">
            <div class="tooltip-row">
                <span class="tooltip-heading-text">{{"lbl_period" | translate}}</span>                
                <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important">{{!currentSourceRecord.sourceBase.period ? '-' : currentSourceRecord.sourceBase.period}}</span>     
            </div>
            <div class="tooltip-row">
                <span class="tooltip-heading-text">{{"lbl_author" | translate}}</span>                
                <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important">{{!currentSourceRecord.sourceBase.author ? '-' : currentSourceRecord.sourceBase.author}}</span>     
            </div>
        </div>

        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_url" | translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important">{{!currentSourceRecord.url ? '-' : currentSourceRecord.url}}</span>     
        </div>
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_comment" | translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important">{{!currentSourceRecord.comment ? '-' : currentSourceRecord.comment}}</span>     
        </div>
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_publication" | translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important">{{!currentSourceRecord.sourceBase.publication ? '-' : currentSourceRecord.sourceBase.publication}}</span>     
        </div>
        <div class="tooltip-row">
            <span class="tooltip-heading-text">{{"lbl_repository" | translate}}</span>                
            <span class="tooltip-content-text text-ellipsis-1" style="margin-top: 2px !important">{{!currentSourceRecord.sourceBase.repository ? '-' : currentSourceRecord.sourceBase.repository}}</span>     
        </div>
    </div>
    <!-- side bar -->
    <div *ngIf="eventSources.length > 1">
        <span></span>
        <img src="/assets/images/events/event-tooltip-arrow-right.png" style="display: block;cursor: pointer;" (click) = "slideRight()" />
    </div>
</div>