import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { DateHelper } from 'src/app/common/helpers/date-helper';
import { GenealogyDateValidator } from 'src/app/common/helpers/date/genealogy-date-validator';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { TimeHelper } from 'src/app/common/helpers/time-helper';
import { FamilyProjectBaseModel } from 'src/app/common/models/familyproject/family-project-base-model';
import { FamilyProjectDetailsModel } from 'src/app/common/models/familyproject/FamilyProjectDetailsModel';
import { FTDate } from 'src/app/common/models/FTDate';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-view-project-details-option',
  templateUrl: './view-project-details-option.component.html',
  styleUrls: ['./view-project-details-option.component.scss']
})
export class ViewProjectDetailsOptionComponent implements OnInit {

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  familyProjectDetails: FamilyProjectDetailsModel;
  formattedDate : string
  formFamilyDescription:string='';
  hasUnsaved = false;

  constructor(private familyProjectService : FamilyProjectApiService,
              private loadingService: LoadingService,
              private dialogService: DialogService, 
              private dateHelper: DateHelper,
              private timeHelper:TimeHelper,
              ) {}

  ngOnInit(): void {
    this.getProjectDetails();
  }

  getProjectDetails() {
    this.familyProjectDetails = new FamilyProjectDetailsModel();
    var familyProjectId = this.data.FamilyProject.familyProjectId;
    this.formFamilyDescription = this.data.FamilyProject.projectDescription;
    this.familyProjectService.getFamilyProjectStats(familyProjectId).subscribe(res => {
      //this.familyProjectDetails = res.data; #EM-10078
      Object.assign(this.familyProjectDetails, res.data)

      var myDate = new Date(this.familyProjectDetails.lastUpdate);
      debugger;
      var strDate = myDate.getDate() + "/" + (myDate.getMonth() + 1) + "/" + myDate.getFullYear();
      var strTime = myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds()
      //var formattedTime = this.timeHelper.formatTime(this.familyProjectDetails.lastUpdate.toString().slice(-11));
      //var formattedTime =
      this.formattedDate = this.dateHelper.validateAndFormat(strDate).formattedText + " " + strTime;
      this.familyProjectDetails.treeSize = this.familyProjectDetails.getSize();
    })
  }

  checkUnsaved(){
    if(this.formFamilyDescription !=this.data.FamilyProject.projectDescription){
      this.hasUnsaved = true;
    }
  }
  save(){
    var familyProject = new FamilyProjectBaseModel();
    familyProject.customerId = this.data.FamilyProject.customerId;
    familyProject.familyProjectId = this.data.FamilyProject.familyProjectId;
    familyProject.projectName = this.data.FamilyProject.projectName;
    familyProject.projectDescription = this.formFamilyDescription;
    familyProject.isDefault = this.data.FamilyProject.isDefault;
    familyProject.lastModifiedDate = this.data.FamilyProject.lastModifiedDate;

    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    
    this.familyProjectService.updateFamilyProject(familyProject).subscribe((res:any)=>{
    }, (err) => {
      throw new CustomError("family project details update error" + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
      this.dialogService.complete({data : "success"});
    });
  }

  cancel(){
    this.dialogService.complete({data : null});
  }
  
}
