<div>                    
    <div class="info-panel dialog-title">{{request.title}}</div> 
    <div class="ft-form-row" style="flex-direction: column;padding: 10px;margin: 0px;">
        <div *ngIf="haveMultipleSpouseFamilies" class="ft-form-input" style="display: flex;flex-direction: column; align-items: center;">
            <select [(ngModel)]="selectedFamilyId" [ngModelOptions]="{standalone: true}" class="form-control">
                <option [ngValue]="undefined" hidden>{{"lbl_select_family" |translate}}</option>               
                <option *ngFor="let family of spouseFamilies" [ngValue]="family.familyId">
                 {{family.spouse.displayName}}</option>
            </select>
        </div>
        <div *ngIf="haveMultipleFamiliesWithChild" class="ft-form-input" style="display: flex;flex-direction: column; align-items: center;">
            <select [(ngModel)]="selectedFamilyId" [ngModelOptions]="{standalone: true}" class="form-control">
                <option [ngValue]="undefined" hidden>{{"lbl_select_family" |translate}}</option>
                <option [ngValue]="0" >{{"lbl_new_family" |translate}}</option>
                <option *ngFor="let family of childFamilies" [ngValue]="family.familyId">{{"lbl_with_child" |translate}} ({{family.children[0].individual.displayName}}...)</option>
            </select>
        </div>
        <div class="ft-form-input" style="display: flex;flex-direction: column; align-items: center;">
            <label>{{request.subTitle}}</label>
            <input type="text" [(ngModel)]="memberName" (ngModelChange)="setEnablity()" >
            <div *ngIf="!isValid" class="label-error-red">{{"err_person_already_registered"| translate}}</div>
        </div>        
    </div>
    <div *ngIf="!isValid" class="info-panel label-l-grey-bold">{{"cnf_add_individual_confirmation"| translate}}</div>
    <div class="button-panel">
        <button class = "ft-btn" [ngClass]="{'ft-btn-active':setEnablity(),'ft-btn-inactive':!setEnablity()}" style="width: 100px;" [disabled]="!setEnablity()" (click)="addMember()">{{"lbl_add" | translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()" style="width: 100px;">{{"lbl_cancel" | translate}}</button>
        <button class = "ft-btn" [ngClass]="{'ft-btn-active':setEnablity(),'ft-btn-inactive':!setEnablity()}" (click)="openMemberEditor()" style="width: 140px;">{{"lbl_individual_editor" | translate}}</button>
    </div>
</div>