<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_options" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">            
        <div class="tabs">
            <span>{{popupTitle| translate}}</span>               
        </div>
        <div class="tab-forms app-light-bg">                
            <div class="ft-form">
                <div class="content">                    
                    <div class="ft-form-row col1-v100">
                        <div class="ft-form-input">
                            <label>{{"lbl_title" | translate}}</label>
                            <input type="text" [(ngModel)]="reportTitle">
                        </div>                        
                    </div> 
                    <div class="ft-form-row col1-v100">
                        <div class="ft-form-input">
                            <label>{{"lbl_number_of_genarations" | translate}}</label>
                            <select class="form-control dropdown-list" [(ngModel)]="selectedGenaration" (change)='onChange($event)'>
                                <option *ngFor='let option of dropDownData' [value]="option">{{option}}</option>
                            </select>
                        </div>
                    </div>
                </div>  
                <div class="footer">
                    <button type="button" class="ft-btn ft-btn-inactive active" (click)="add()">{{"lbl_save" | translate}}</button>
                    <button type="button" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
                </div>
            </div>  
        </div>            
    </div>
</div>
