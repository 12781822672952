<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{data.title}}</span>
        </div>
        <div class="ft-form-row">
            <app-media-input style="width: 100%;" [mediaInputData] = "mediaInputData" (mediaFileInfoOut)="setMediaInfo($event)" ></app-media-input>            
        </div>

        <div class="ft-form-row col2-v50v50">                    
            <div class="ft-form-input">
                <label>{{"lbl_picture_caption" | translate}}</label>
                <input type="text" [(ngModel)]="multmediaInfo.caption" name="caption" [disabled] = "!isFileAdded">
            </div>
            <div class="ft-form-input">
                <label>{{"lbl_tag" | translate}}</label>
                <input type="text" [(ngModel)]="multmediaInfo.tag" name="tag" [disabled] = "!isFileAdded">
            </div>                    
        </div>
         <!-- event date and place -->
         <div class="ft-form-row col2-vf">
            <div class="ft-form-input">
                <label>{{"lbl_date" | translate}}</label>                
                <app-date-input-temp [isFieldEnabled] = "isFileAdded" [inDate] = "multmediaInfo.date" (outDate)=setFormatedDate($event)></app-date-input-temp>
            </div>
            <!-- TODO : Place component -->
            <div class="ft-form-input">
                <label>{{"lbl_places" | translate}}</label>
                <div style="display: flex;flex-direction: row;align-content:flex-end;">
                    <input style="flex: auto;" [(ngModel)]="multmediaInfo.place" name="place " type="text" [disabled] = "!isFileAdded">
                </div>
            </div>
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_description" | translate}}</label>
                <input type="text" [(ngModel)]="multmediaInfo.description" name="description" [disabled] = "!isFileAdded">
            </div>                      
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn" [ngClass]="(isFileAdded)? 'ft btn ft-btn-active' : 'ft btn ft-btn-inactive'" [disabled]="!isFileAdded" (click)="add()" >{{"lbl_add" | translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>