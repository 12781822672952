import { Component, OnInit } from '@angular/core';
import { TooltipContentBaseComponent } from '../../../tooltip/tooltip-content-base/tooltip-content-base.component';
import { Witness } from '../../../witness/models/witness';
// import { Witness } from 'src/app/components/familyeditor/models/family-component-model';

@Component({
  selector: 'app-event-detail-witness',
  templateUrl: './event-detail-witness.component.html',
  styleUrls: ['./event-detail-witness.component.scss','../../events-main/events-main.component.scss']
})
export class EventDetailWitnessComponent  extends TooltipContentBaseComponent implements OnInit {

  public data: any;
  eventWitness: Witness[];
  currentIndex  : number = 0;
  currentWitnessRecord: Witness;

  ngOnInit(): void {
    this.eventWitness = this.data.value;
    this.currentWitnessRecord = this.eventWitness[this.currentIndex];
  }

  slideLeft(){
    this.currentIndex--;
    if ( this.currentIndex < 0 ){
      this.currentIndex = this.eventWitness.length -1;
    }  
    this.currentWitnessRecord = this.eventWitness[this.currentIndex];  
  }

  slideRight(){
    this.currentIndex++;
    if ( this.currentIndex >= this.eventWitness.length ){
      this.currentIndex = 0;
    }    
    this.currentWitnessRecord = this.eventWitness[this.currentIndex];
  }
}
