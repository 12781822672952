import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction, DropMenuType, MultimediaTypes, PopupViewMode, ImageOptions } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { MultiMediaFilter } from 'src/app/common/helpers/filter';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { MultimediaBase } from 'src/app/common/models/multimedia/multimedia-base';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { MultimediaApiService } from 'src/app/services/API/multimedia-api.service';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';
import { MediaGalleryItem } from '../../media-gallery/models/media-gallery-item';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';

@Component({
  selector: 'app-multimedias',
  templateUrl: './multimedias.component.html',
  styleUrls: ['./multimedias.component.scss']
})
export class MultimediasComponent implements OnInit {

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();

  //Multimedia Object
  multimedias: Multimedia[];
  mediaItems: MediaGalleryItem[];

  searchPhrase: string = "";
  isFilterDropDownOpen: boolean;
  selectfilterText: string = ""; //Show select drop item from filter dropdown
  selectedMediaTitle: string = "";

  //select filter type and categroy
  selectedfilterType: string = "all_media";
  selectedMediatype = Number(MultimediaTypes.NONE);

  //Filters (All , My and other media categories)
  mediaTypes: { type: string; id: number; count: number; }[]; // E.g Images, Doc,Audio etc..    
  mediaFilterMap: Map<string, Multimedia[]> = new Map<string, Multimedia[]>();  // key ---> multimediaObj

  //Popup response model
  responseData: { newMedia: Multimedia, deleteMedias: Multimedia[], nextImage: Multimedia };

  isStandAlone: boolean;
  isColorizeEnabled = false;

  constructor(
    private multimediaApiService: MultimediaApiService,
    private loadingService: LoadingService,
    private messageDialogService: MessageDialogService,
    private translateHandler: TranslateHandler,
    private parentCommunicatorService: ParentCommunicatorService
  ) {
    this.mediaTypes = MultiMediaFilter.getFilters().map(t => {
      t.type = "lbl_" + t.type;
      return t;
    });
  }

  ngOnInit(): void {
    //Todo: Mechanism to switch between the default API call
    this.responseData = new Object() as any;
    this.responseData.deleteMedias = new Array<Multimedia>();
    this.multimedias = new Array<Multimedia>();
    this.mediaItems = new Array<MediaGalleryItem>();
    this.initialize();
  }

  initialize() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.isStandAlone = this.data.isStandAlone;
    // Get all media using the API
    this.multimediaApiService.getAllMultimedia().subscribe((response: ResponseModel<Multimedia[]>) => {
      this.multimedias = response.data.filter(x => this.data.mediaType.some(y => y === x.mediaType));
      this.mediaFilterMap.set("all_media", this.multimedias);
      this.filterMedia("all_media");
    }, (err) => {
      throw new CustomError("Multimedia => getAllMediaList() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });

    // Get my media using the API
    this.loadingService.show(processId);
    this.multimediaApiService.getMyMultimedia(this.data.reference.id, this.data.reference.type).subscribe((response: ResponseModel<Multimedia[]>) => {
      let myMedia = response.data.filter(x => this.data.mediaType.some(y => y === x.mediaType));
      this.mediaFilterMap.set("my_media", myMedia);
    }, (err) => {
      throw new CustomError("Multimedia => getMyMediaList() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  //Get mediaCategories
  getMediaCategories() {
    let mediaObj = this.mediaFilterMap.get(this.selectedfilterType);
    this.mediaTypes.map(t => {
      t.count = mediaObj.filter(m => t.id != 0 ? m.mediaType == t.id : mediaObj).length;
    });
    return this.mediaTypes;
  }

  //Select filter (All,My and mediaType - Images, doc,video etc..)
  filterMedia(key?: string, typeId?: number) {
    let selectedKey = key != null ? key : this.selectedfilterType;
    var selectedMultimedia = this.mediaFilterMap.get(selectedKey);
    this.mediaItems = selectedMultimedia.map(i => ({
      id: 0, reference: i.id, caption: (i.caption === "" ? i.fileName : i.caption),
      url: this.getImageMediaUrl(i), isSelected: (i.id == this.data.id), isEditEnable: (i.id == this.data.id), dropMenu: this.createDropMenu(i),
      mediaType: i.mediaType, searchTag: (i.fileName + "|" + i.description + "|" + i.tag + "|" + i.place)
    }))
      .filter(t => (typeId != 0 && typeId != null) ? t.mediaType == typeId : this.mediaItems);;

    this.data.isStandAlone === false ? this.mediaItems.sort(this.compareSelected) : null;
    this.selectfilterText = this.translateHandler.translate("lbl_" + selectedKey) + " (" + selectedMultimedia.length + ")";
    this.selectedfilterType = selectedKey;
    this.selectedMediatype = typeId != null ? typeId : MultimediaTypes.NONE;
  }

  addMedia() {
    let selectedMedia = this.mediaItems.find(s => s.isSelected == true);
    let media = this.multimedias?.find(p => p.id === selectedMedia?.reference ?? 0) ?? new Multimedia();
    this.responseData.newMedia = media;
    this.onCompleted.next({ data: this.responseData });
    this.onCompleted.unsubscribe();
  }

  cancel() {
    this.onCompleted.next({ data: this.responseData });
    this.onCompleted.unsubscribe();
  }

  getImageMediaUrl(media: Multimedia) {
    let timeStamp = performance.now();
    let url = (media.mediaUrl != null) ? media.mediaUrl + '&v=' + timeStamp : media.mediaUrl;
    return media.mediaType === MultimediaTypes.PHOTO ? url ?? "/assets/images/Male.png" : "assets/nonimage-preview/Large-Icons/media_type_" + media.mediaType + ".svg"
  }

  compareSelected(a: MediaGalleryItem, b: MediaGalleryItem) {
    if (a.isSelected == true) {
      return -1;
    }
    if (b.isSelected == true) {
      return 1;
    }
    return 0;
  }

  createDropMenu(ref: Multimedia) {
    let dropMenu = new DropMenu();
    dropMenu.type = 1;
    dropMenu.ref = ref;
    dropMenu.menuItems = [<DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Delete, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Download, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Colorize, isEnabled: this.checkIsEnabled(ref), isVisible: true }
    ];
    return dropMenu;
  }

  dropMenuAction(event: any) {
    if (event.action == DropMenuAction.Download) {
      window.open(event.reference.originalMediaUrl, "_blank");
    }

    // Edit and delete of a media will be done here.
    if (event.action == DropMenuAction.Delete) {
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      this.multimediaApiService.getReferenceToMedia(event.reference.id).subscribe((response: ResponseModel<number>) => {
        var message = this.translateHandler.translate("cnf_delete_media_confirmation_message") + this.translateHandler.translate("lnk_references", [response.data.toString()])
        this.messageDialogService.openDeleteConfirmation(message).subscribe((res: boolean) => {
          if (res) {
            this.deleteMedia(event.reference);
          }
        })
      }, (err) => {
        throw new CustomError("PlaceComponent => getReferenceToPlace() : " + err, 911, false);
      }).add(() => {
        this.loadingService.hide(processId);
      });
    }
    if (event.action == DropMenuAction.Edit) {
      // Do the edit functionality
      this.data.editMediaId = event.reference.id;
      this.data.viewMode = PopupViewMode.EditorView;
    }

    if (event.action == DropMenuAction.Colorize) {
      // Do the colorize functionality
      this.data.mediaInfo = event.reference;
      this.data.viewMode = PopupViewMode.ColorizerView;
    }
  }

  deleteMedia(media: Multimedia) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.multimediaApiService.deleteMedia(media.id).subscribe((response: ResponseModel<boolean>) => {
      if (response.data) {
        let deleteObj = this.mediaItems.find(s => s.reference == media.id);
        this.mediaItems.splice(this.mediaItems.indexOf(deleteObj), 1);
        this.responseData.deleteMedias.push(media);
        // Setting next image from my media 
        this.responseData.nextImage = this.mediaFilterMap.get("my_media")[0].id != media.id ? this.mediaFilterMap.get("my_media")[0] : null;
        this.data.response = this.responseData;

        this.mediaFilterMap.forEach((value, key) => {
          let deleteAllMediaObj = value.find(s => s.id == media.id);
          if (deleteAllMediaObj) {
            value.splice(value.indexOf(deleteAllMediaObj), 1);
          }
        })
      }
    }, (err) => {
      throw new CustomError("PlaceComponent => DeletePlace() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  setEnablity() {
    let isEnabled = this.mediaItems?.find(s => s.isSelected == true)?.isSelected ?? false;
    this.selectedMediaTitle = this.mediaItems?.find(s => s.isSelected === true)?.caption;
    return isEnabled;
  }

  showFilterDropdown() {
    this.isFilterDropDownOpen = !this.isFilterDropDownOpen;
  }

  checkIsEnabled(ref: any) {
    let isColorized = ref.options?.find(x => x == ImageOptions.IsColorized)
    if (ref.mediaType == MultimediaTypes.PHOTO && !isColorized) {
      return true;
    }
    return false;
  }

  enableColorize() {
    this.isColorizeEnabled = true;
    this.initialize();
  }
}