import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { ReferenceType, MultimediaTypes, PopupOpenMode, PopupViewMode } from 'src/app/common/enums/enums';
import { FilenameHelper } from 'src/app/common/helpers/filename/filename-helper';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { Subscription } from 'rxjs';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit, OnDestroy {

  dialogSubscription: Subscription;
  currentPopupName: string;

  titleMap = new Map<string, string>(
    [
      ["Multimedia", "lbl_media_list"], // Todo: need translate
      ["Place",      "lbl_place_list"],
      ["Source",     "lbl_source_list"],
      ["Individuals", "lbl_persons_list"],
    ]
  );

  testTralate : string;

  constructor(private activatedRoute   : ActivatedRoute,
              private loadingService   : LoadingService,
              private dialogService    : DialogService,
              private translateHandler : TranslateHandler,
              private filenameHelper   : FilenameHelper,
              private projectRefService:ProjectRefService,
              private apibaseservice   : ApiBaseService,
              private router: Router) { }

  
  ngOnInit(): void {
    try {
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      
      this.activatedRoute.params.subscribe(params => {       
        let name = params['name'].charAt(0).toUpperCase() + params['name'].slice(1); // (+) converts string 'id' to a number
        console.log("Show " + name);

        // The following if handles the scenario when a list is already opened and another popup is opened again.
        if (this.currentPopupName
          && this.currentPopupName.length > 0
          && this.currentPopupName !== name) {
          this.dialogService.close({});
        }

        this.currentPopupName = name;
        this.loadingService.hide(processId);
        this.openPopup(name);

        // In a real app: dispatch action to load the details here.
      }, (error) => {
        console.log(error);
        throw new CustomError("ngOnInit => Popup routing() : " + error, 911, false);
      });
    } catch (error) {
      console.log(error)
    }

  }

  ngOnDestroy() : void {
    this.dialogService.close({});
    if(this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  openPopup(name: string) {
    
    //let projectName = this.filenameHelper.getProjectName(localStorage.getItem('fileName'));
    let projectName = this.projectRefService.getCurrentProject().projectName;

    let mainTitle = this.titleMap.get(name);
    this.dialogSubscription = this.dialogService.setFull().open(name, {
      id: 0,
      reference: {
        id: 0,
        type: ReferenceType.None,
        rawData : ""
      },
      title:  this.translateHandler.translate('lbl_menu_list_title', [this.translateHandler.translate(mainTitle), projectName]) ?? "",
      mediaType: [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO],
      viewMode: PopupViewMode.GalleryView, // Gallery or editor view
      editMediaId: 0, // MediaId which supposes to be edited
      editSourceId: 0,
      isStandAlone: true,
      mode: PopupOpenMode.List // Todo: Make a enum
    }
    )
    .subscribe(response => {
      if (response.data) {
        this.router.navigate([AppConstatnts.routerModes.TreeEditor]);
        console.log(">>>>>>>>>>>>>>")
      }  
    });
  }

}
