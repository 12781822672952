import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PreferencesOptionComponent } from './preferences-option/preferences-option.component';
import { PreferenceMainComponent } from './preference-main/preference-main.component';

@NgModule({
  declarations: [PreferenceMainComponent, PreferencesOptionComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({ 
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })   
  ],
  exports: [PreferenceMainComponent], 
  entryComponents: []
})
export class PreferencesModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}