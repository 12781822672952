import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';
import BookReport from 'src/app/common/models/BookReport';
import { HttpHeaders } from '@angular/common/http';
import BookReportViewModel from 'src/app/common/models/BookReportViewModel';

@Injectable({
  providedIn: 'root'
})
export class BookReportApiService {

  constructor(private apiBaseService: ApiBaseService) { }

  getLast(reportName: string): Observable<BookReport> {
    return this.apiBaseService.get("/api/bookreport/" + reportName + "/last", [], null);
  }

  getPage(reportId: string, pageNumber: number): Observable<string> {

    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }

    return this.apiBaseService.get<string>("/api/bookreport/" + reportId + "/" + pageNumber + "/data", [], null, httpOptions);
  }

  getReport(reportId: string): Observable<BookReport> {
    return this.apiBaseService.get("/api/bookreport/" + reportId, [], null);
  }

  createReport(bookReport: BookReportViewModel): Observable<BookReport> {
    return this.apiBaseService.create("/api/bookreport/" ,null, bookReport);
  }
 

}
