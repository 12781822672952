import { GenealogyDate } from './genealogy-date';
import { GenealogyDateMode } from '../../enums/enums';
import { GenealogyDateRange } from './genealogy-date-range';

export class GenealogyDateValidator {
    static allRules: any[];
    rules: any;
    culture: string;
    // Review: Take this in to the as like all rules(Check the purpose of the usage and move with related to that)
    // months_en = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    // static initializer + auto call
    private static _initialize = (() => {
        GenealogyDateValidator.allRules = [];
        GenealogyDateValidator.allRules["en"] =
        {
            "oneYearRange":
                [
                    { "format": "1978/1979", "regex": /^(?<from>[1-9]\d{3})\/(?<to>[1-9]\d{3})$/ },
                    { "format": "1978/79", "regex": /^(?<from>[1-9]\d{3})\/(?<to>\d{2})$/ },
                    { "format": "Jan.1978/1979", "regex": /^(?<from>(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)[\.\/ ]([1-9]\d{3}))\/(?<to>[1-9]\d{3})$/i },
                    { "format": "Jan.1978/79", "regex": /^(?<from>(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)[\.\/ ]([1-9]\d{3}))\/(?<to>\d{2})$/i },
                    { "format": "1/1978/1979", "regex": /^(?<from>(\d{2}|\d{1})[\.\/ ]([1-9]\d{3}))\/(?<to>[1-9]\d{3})$/ },
                    { "format": "1/1978/79", "regex": /^(?<from>(\d{2}|\d{1})[\.\/ ]([1-9]\d{3}))\/(?<to>\d{2})$/ },
                ],
            "range":
                [
                    // only if and only one "-" is checked in rage 11 - 11 (otherwise will match as date)
                    { "format": "date - date", "regex": /^(?<from>[^-]*)-(?<to>[^-]*)$/ },
                    { "format": "from 1954 to 1958", "regex": /^(?<str>from) (?<from>.*?)to (?<to>.*)/i },
                    { "format": "Between 1933 and 1936", "regex": /^(?<str>between |bet)(?<from>.*?) and (?<to>.*)/i },
                ],
            "approx":
                [
                    { "format": "approx", "regex": /^(?<str>(assumed|assume|approximately|approx|approx\.|after|aft|before|bef|cal|ca|c|about|abt|q1|1q|q2|2q|q3|3q|q4|4q)) (?<date>.*)/i },
                ],
            "date":
                [
                    { "format": "21.11/1975", "regex": /^(?<day>\d{1,2})( +| *\. *| *\/ *)(?<month>\d{1,2})( +| *\. *| *\/ *)(?<year>\d{4})$/i },
                    { "format": "21 jan/1975", "regex": /^(?<day>\d{1,2})(\s*|\s*\.\s*|\s*\/\s*)(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)(\s*|\s*\.\s*|\s*\/\s*)(?<year>\d{4})$/i },
                    { "format": "jan 21.1975", "regex": /^(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)(\s*|\s*\.\s*|\s*\/\s*)(?<day>\d{1,2})(\s*|\s*\.\s*|\s*\/\s*)(?<year>\d{4})$/i },
                    { "format": "21 11, 1975", "regex": /^(?<day>\d{1,2})( +)(?<month>\d{1,2})( *\, *)(?<year>\d{4})$/i },
                    { "format": "Jun 11, 1975", "regex": /^(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)( +)(?<day>\d{1,2})( *\, *)(?<year>\d{4})$/i },
                    // cannot combine "-" with other variations
                    { "format": "21-11-1975", "regex": /^(?<day>\d{1,2})(\s*-\s*)(?<month>\d{1,2})(\s*-\s*)(?<year>\d{4})$/i },
                    { "format": "21-jan-1975", "regex": /^(?<day>\d{1,2})(\s*-\s*)(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)(\s*-\s*)(?<year>\d{4})$/i },
                    { "format": "jan-21-1975", "regex": /^(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)(\s*-\s*)(?<day>\d{1,2})(\s*-\s*)(?<year>\d{4})$/i }
                ],
            "partial":
                [
                    { "format": "1975", "regex": /^(?<year>[1-9]\d{3})$/ },
                    { "format": "10", "regex": /^(?<unknown>\d{1,2})$/ },    // CAN BE MONTH OR DAY??/?
                    { "format": "1/1975", "regex": /^(?<month>\d{1,2})( +| *\/ *| *\. *)(?<year>[1-9]\d{3})$/ },
                    { "format": "1/19", "regex": /^(?<month>\d{1,2})( +| *\/ *| *\. *)(?<day>\d{1,2})$/ },
                    { "format": "Jan 1975", "regex": /^(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)( +| *\/ *| *\. *)(?<year>[1-9]\d{3})$/i },
                    { "format": "Jan.01", "regex": /^(?<month>Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)( +| *\/ *| *\. *)(?<day>\d{1,2})$/i },

                ]
        }; // end en obj
        GenealogyDateValidator.allRules["no"] =
        {
            "oneYearRange":
                [
                    { "format": "1978/1979", "regex": /^(?<from>[1-9]\d{3})\/(?<to>[1-9]\d{3})$/ },
                    { "format": "1978/79", "regex": /^(?<from>[1-9]\d{3})\/(?<to>\d{2})$/ },
                    { "format": "Jan.1978/1979", "regex": /^(?<from>(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)[\.\/ ]([1-9]\d{3}))\/(?<to>[1-9]\d{3})$/i },
                    { "format": "Jan.1978/79", "regex": /^(?<from>(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)[\.\/ ]([1-9]\d{3}))\/(?<to>\d{2})$/i },
                    { "format": "1/1978/1979", "regex": /^(?<from>(\d{2}|\d{1})[\.\/ ]([1-9]\d{3}))\/(?<to>[1-9]\d{3})$/ },
                    { "format": "1/1978/79", "regex": /^(?<from>(\d{2}|\d{1})[\.\/ ]([1-9]\d{3}))\/(?<to>\d{2})$/ },
                ],
            "range":
                [
                    // only if and only one "-" is checked in rage 11 - 11 (otherwise will match as date)
                    { "format": "date - date", "regex": /^(?<from>[^-]*)-(?<to>[^-]*)$/ },
                    { "format": "fra 1954 til 1958", "regex": /^(?<str>fra) (?<from>.*?)til (?<to>.*)/i },
                    { "format": "Between 1933 and 1936", "regex": /^(?<str>mellom)(?<from>.*?) og (?<to>.*)/i },
                ],
            "approx":
                [
                    //norwegian 
                    { "format": "approx", "regex": /^(?<str>(omtrent|antatt|cirka|etter|før|kal|ca|c|q1|1q|q2|2q|q3|3q|q4|4q)) (?<date>.*)/i },
                    { "format": "approx", "regex": /^(?<str>(assumed|assume|approximately|approx|approx\.|after|aft|before|bef|cal|ca|c|about|abt|q1|q2|q3|q4)) (?<date>.*)/i },
                ],
            "date":
                [
                    { "format": "21.11/1975", "regex": /^(?<day>\d{1,2})( +| *\. *| *\/ *)(?<month>\d{1,2})( +| *\. *| *\/ *)(?<year>\d{4})$/i },
                    { "format": "21 jan/1975", "regex": /^(?<day>\d{1,2})(\s*|\s*\.\s*|\s*\/\s*)(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)(\s*|\s*\.\s*|\s*\/\s*)(?<year>\d{4})$/i },
                    { "format": "jan 21.1975", "regex": /^(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)(\s*|\s*\.\s*|\s*\/\s*)(?<day>\d{1,2})(\s*|\s*\.\s*|\s*\/\s*)(?<year>\d{4})$/i },
                    { "format": "21 11, 1975", "regex": /^(?<day>\d{1,2})( +)(?<month>\d{1,2})( *\, *)(?<year>\d{4})$/i },
                    { "format": "Jun 11, 1975", "regex": /^(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)( +)(?<day>\d{1,2})( *\, *)(?<year>\d{4})$/i },
                    // cannot combine "-" with other variations
                    { "format": "21-11-1975", "regex": /^(?<day>\d{1,2})(\s*-\s*)(?<month>\d{1,2})(\s*-\s*)(?<year>\d{4})$/i },
                    { "format": "21-jan-1975", "regex": /^(?<day>\d{1,2})(\s*-\s*)(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)(\s*-\s*)(?<year>\d{4})$/i },
                    { "format": "jan-21-1975", "regex": /^(?<month>Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)(\s*-\s*)(?<day>\d{1,2})(\s*-\s*)(?<year>\d{4})$/i }
                ],
            "partial":
                [
                    { "format": "1975", "regex": /^(?<year>[1-9]\d{3})$/ },
                    { "format": "10", "regex": /^(?<unknown>\d{1,2})$/ },    // CAN BE MONTH OR DAY??/?
                    { "format": "1/1975", "regex": /^(?<month>\d{1,2})( +| *\/ *| *\. *)(?<year>[1-9]\d{3})$/ },
                    { "format": "1/19", "regex": /^(?<month>\d{1,2})( +| *\/ *| *\. *)(?<day>\d{1,2})$/ },
                    { "format": "Jan 1975", "regex": /^(?<month>Jan(?:uary)?|Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)( +| *\/ *| *\. *)(?<year>[1-9]\d{3})$/i },
                    { "format": "Jan.01", "regex": /^(?<month>Jan(?:uary)?|Jan(?:uar)?|Feb(?:ruar)?|Mar(?:s)?|Apr(?:il)?|Mai|Jun(?:i)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Okt(?:ober)?|Nov(?:ember)?|Des(?:ember)?)( +| *\/ *| *\. *)(?<day>\d{1,2})$/i },
                    //{ "format": "Jan.01", "regex": /^(?<month>Jan(?:uary)?|Jan(?:uar)?|Feb(?:ruary)?|Feb(?:ruar)?|Mar(?:ch)?|Mar(?:s)?|Apr(?:il)?|May|Mai|Jun(?:e)?|Jun(?:i)?|Jul(?:y)?|Jul(?:i)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Okt(?:ober)?|Nov(?:ember)?|Dec(?:ember)?|Des(?:ember)?)( +| *\/ *| *\. *)(?<day>\d{1,2})$/i },

                ]
        }; // end no obj
    })();

    constructor(culture) {
        this.culture = culture;
        this.rules = GenealogyDateValidator.allRules[culture];
        if (!this.rules) {
            throw `Culture (${culture}) not found`;
        }
    }

    formatDate(date: Date) {
        if (date == null) {
            return "";
        }
        return date.toJSON();
    }

    /**
     * Date validation using different combinations
     * range/one year range/approx/full and partial dates
     * @param strDate Testing date
     */
    validateDate(strDate) {

        // clear spaces
        strDate = strDate != null ? strDate.trim() : strDate;
        // check range
        var rangeDate = this.validateRange(strDate);
        if (rangeDate != null) {
            var dateFrom = this.validateFullandPartial(rangeDate.from.trim());
            var dateTo = this.validateFullandPartial(rangeDate.to.trim());
            return new GenealogyDateRange(strDate, dateFrom, dateTo, 2, rangeDate.str);
        }
        // check one year range
        var oneYearDate = this.validateOneYearRange(strDate);
        if (oneYearDate != null) {

            var dateFrom = this.validateFullandPartial(oneYearDate.from.trim());
            var dateTo = this.validateFullandPartial(oneYearDate.to.trim());
            // check if one year apart
            var retDR = this.verifyOneYearRangeValid(dateFrom, dateTo);
            if (retDR != null) {
                return new GenealogyDateRange(strDate, dateFrom, dateTo, 1, "-");
            }
            return new GenealogyDateRange(strDate, null, null, 1, "-");
        }

        // check approx
        var approxDate = this.validateApprox(strDate);
        if (approxDate != null) {
            var dateFP = this.validateFullandPartial(strDate.trim());

            if (dateFP != null) {
                dateFP.setApprox(approxDate.str);
            } else {
                dateFP = new GenealogyDate(strDate, this.culture);
                dateFP.setApprox(approxDate.str);
            }
            return dateFP;
            // set this as approx with the sign
        }

        var dateFP = this.validateFullandPartial(strDate);
        if (dateFP != null) {
            return dateFP;
        }
        return new GenealogyDate(strDate, this.culture);
    }

    private validateFullandPartial(strDate) {
        var dateFormat = this.validateDateFormat(strDate);
        if (dateFormat != null) {
            // check date
            var d = new GenealogyDate(strDate, this.culture, dateFormat.y, dateFormat.m, dateFormat.d, GenealogyDateMode.full)
            return d;
        }

        dateFormat = this.validatePartial(strDate);
        if (dateFormat != null) {
            var d = new GenealogyDate(strDate, this.culture, dateFormat.y, dateFormat.m, dateFormat.d, GenealogyDateMode.partial, dateFormat.u)
            return d;
        }
        return null;
    }
    // Check if matching date range
    private validateRange(strDate) {
        return this.validateRangeBase(strDate, this.rules.range);
    }

    // check if matching one year range rule
    private validateOneYearRange(strDate) {
        return this.validateRangeBase(strDate, this.rules.oneYearRange);
    }

    private verifyOneYearRangeValid(from: GenealogyDate, to: GenealogyDate) {
        var today = new Date();

        if (from == null || to == null) {
            return null;
        }
        if (!from.year && !to.year) {
            return null;
        }

        // check the unknown number and set it         
        if (to.unknown && to.unknown < 100) {
            var nextYear = Math.floor(from.year / 100) * 100 + to.unknown;
            if (from.year + 1 == nextYear) {
                to.year = from.year + 1
            }
        }

        if (from.year + 1 != to.year) {
            return null;
        }
        if (from.year > today.getFullYear() || to.year > today.getFullYear()) {
            return null;
        }



        return { "from": from, "to": to };
    }
    // validate base method
    private validateRangeBase(strDate, ruleSet) {
        for (var x = 0; x < ruleSet.length; x++) {
            var validator = ruleSet[x];
            var result = validator.regex.exec(strDate);
            if (result != null) {
                return { "from": result.groups.from, "to": result.groups.to, "str": result.groups.str, "matchedTo": validator };
            }

        }
        return null;
    }

    // Approx rule validation
    private validateApprox(strDate) {
        for (var x = 0; x < this.rules.approx.length; x++) {
            var validator = this.rules.approx[x];
            var result = validator.regex.exec(strDate);
            if (result != null) {
                return { "date": result.groups.date, "str": result.groups.str, "matchedTo": validator };
            }
        }
        return null;
    }

    // Date rule validation ( only the format )
    private validateDateFormat(strDate) {
        for (var x = 0; x < this.rules.date.length; x++) {
            var validator = this.rules.date[x];
            var result = validator.regex.exec(strDate);
            if (result != null) {
                return { "y": result.groups.year, "m": result.groups.month, "d": result.groups.day, "u": undefined, "matchedTo": validator };
            }
        };
        return null;
    }

    // Partial rule validation ( only the format )
    private validatePartial(strDate) {
        for (var x = 0; x < this.rules.partial.length; x++) {
            var validator = this.rules.partial[x];
            var result = validator.regex.exec(strDate);
            if (result != null) {
                return { "y": result.groups.year, "m": result.groups.month, "d": result.groups.day, "u": result.groups.unknown, "matchedTo": validator };
            }
        };
        return null;
    }


    // TODO : Assuming all years are 4 digits 1975 NOT 75

}