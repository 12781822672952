import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { HistoryService } from './history.service';
import { NotifierV2Service } from './notifier-v2.service';

@Injectable()
export class RouteResolver implements Resolve<any>{

    constructor(private location : Location, private notifierV2Service: NotifierV2Service) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {      
        if ( route.params.data ){
            let dataObj = JSON.parse(route.params.data);   
            if(dataObj.isUpdateHistory)    
                this.notifierV2Service.addToHistory(dataObj);
            return dataObj;
        }
        return {};
    }

}