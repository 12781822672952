import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TimelineModule } from "./components/timeline/timeline.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "../app/shared/shared.module";
import { TreeeditorModule } from "./components/treeeditor/treeeditor.module";
import { HttpInterceptProviders } from "../app/http-interceptors/interceptors-index";
import { GlobalErrorHandler } from "./services/global-error-handler";
import { ApiBaseService } from "./services/api-base.service";
import { LeftpanelModule } from "./components/leftpanel/leftpanel.module";
import { CustomApiParameters } from "./common/helpers/custom-api-parameters";
import { IndividualmanagementModule } from './components/common/individualmanagement/individualmanagement.module';
import { LoadingService } from './services/UI/loading.service';
import { ReportengineModule } from "./components/reportengine/reportengine.module";
import { MultiMediaModule } from './components/common/multimedia/multimedia.module';
import { LazyloadingModule } from './components/common/lazyloading/lazyloading.module';
import { MultimediamanagementService } from './components/common/multimedia/services/multimediamanagement.service';
import { NestedPopupService } from './services/UI/nested-popup.service';
import { PopupBaseComponent } from './components/common/popup-base/popup-base.component';
import { FormsModule } from '@angular/forms';
import { BaseComponent } from './components/reports/BaseReport/base/base.component';
import { PreferencesService } from './services/preferences.service';
import { ConfirmationDialogService } from './services/UI/confirmation-dialog.service';
import { ConfirmationDialogComponent } from './components/common/confirmation/confirmation-dialog/confirmation-dialog.component';
// import { TooltipComponent } from './components/common/tooltip/tooltip.component';
import { GlobalerrorService } from './services/globalerror.service';
import { MembershipUpgradePopupComponent } from './components/common/membership/membership-upgrade-popup/membership-upgrade-popup.component';
import { PaymentOption1Component } from './components/common/membership/payment-option1/payment-option1.component';
import { PaymentOption2Component } from './components/common/membership/payment-option2/payment-option2.component';
import { DynamicPaymentOptionDirective } from './components/common/membership/directives/dynamic-payment-option.directive';
import { PedigreeoptionpopupComponent } from './components/reports/pedigreechart/pedigreeoptionpopup/pedigreeoptionpopup.component';
import { EditorModule } from './components/editor/editor.module';
import { BreadcrumbModule } from './components/common/breadcrumb/breadcrumb.module';
import { MultimedialistComponent } from './components/multimedialist/multimedialist.component';
import { RouterModule } from '@angular/router';
import { TreeEditorService } from './components/treeeditor/services/tree-editor.service';
import { PlaceModule } from './components/common/place/place.module';
import { MainplaceComponent } from './components/common/place/mainplace/mainplace.component';
import { PlaceEditorComponent } from './components/common/place/place-editor/place-editor.component';
import { EventsTempComponent } from './components/events-temp/events-temp.component';
import { CommonComponentModule } from './components/common/common.module';
import { EventDetailNoteComponent } from './components/common/events/event-tips/event-detail-note/event-detail-note.component';
import { EventDetailMediaComponent } from './components/common/events/event-tips/event-detail-media/event-detail-media.component';
import { EventDetailSourceComponent } from './components/common/events/event-tips/event-detail-source/event-detail-source.component';
import { EventDetailWitnessComponent } from './components/common/events/event-tips/event-detail-witness/event-detail-witness.component';
import { MissingTranslatehandler } from './common/helpers/missing-translate-handler';
import { ParentCommunicatorService } from "./services/parent-communicator.service";
import { TooltipService } from "./services/UI/tooltip.service";
import { TooltipTestContentComponent } from "./components/common/tooltip/tooltip-test-content/tooltip-test-content.component";
import { DateTooltipComponent } from "./components/common/input/date-input-temp/date-tooltip/date-tooltip.component";
import { DialogService } from "./services/UI/dialog.service";
import { IndividualsComponent } from "./components/common/individual/individuals/individuals.component";
import { RelationshipComponent } from "./components/common/individual/relationship/relationship.component";
import { PlaceMainComponent } from "./components/common/place/place-main/place-main.component";
import { IndividualeditorModule } from "./components/individualeditor/individualeditor.module";
import { MultimediaMainComponent } from "./components/common/multimedia/multimedia-main/multimedia-main.component";
import { SourceModule } from "./components/common/source/source.module";
import { SourceMainComponent } from "./components/common/source/source-main/source-main.component";
import { WitnessMainComponent } from "./components/common/witness/witness-main/witness-main.component";
import { WitnessModule } from "./components/common/witness/witness.module";
import { SourceCitationMainComponent } from "./components/common/source-citation/source-citation-main/source-citation-main.component";
import { SourceCitationModule } from "./components/common/source-citation/source-citation.module";
import { MessageDialogModule } from "./components/common/message-dialog/message-dialog.module";
import { MessagePromptComponent } from "./components/common/message-dialog/message-prompt/message-prompt.component";
import { IndividualPromptComponent } from "./components/individualeditor/individual-prompt/individual-prompt.component";
import { TreemanagerModule } from "./components/treemanager/treemanager.module";
import { ReportmanagerModule } from "./components/reportmanager/reportmanager.module";
import { FamilyeditorModule } from './components/familyeditor/familyeditor.module';
import { EventDetailIndividualComponent } from './components/common/events/event-tips/event-detail-individual/event-detail-individual.component';
import { V3Component } from './v3/v3.component';
import { ReportTemplateComponent } from './templates/report/report-template.component';
import { EditorTemplateComponent } from './templates/editor/editor-template.component';
import { MenuModule } from './components/menu/menu.module';
import { SunchartOptionPopupComponent } from './components/reports/sunchart/sunchart-option-popup/sunchart-option-popup.component';
import { Sunchart } from "./components/reports/sunchart/sunchart/sunchart.component";
import { ReportengineTemplateComponent } from './templates/reportengine-template/reportengine-template.component';
import { MessagePromptStripeComponent } from './components/common/message-dialog/message-prompt-stripe/message-prompt-stripe.component';
import { ReportPublishOptionComponent } from './components/common/report-publish-option/report-publish-option.component';
import {FamilyprojectmanagerModule} from './components/familyprojectmanager/familyprojectmanager.module'; 
import { FamilyProjectOptionMainComponent } from "./components/familyprojectmanager/family-project-option-main/family-project-option-main.component";
import { PreferenceMainComponent } from './components/preferences/preference-main/preference-main.component';
import { PreferencesModule } from "./components/preferences/preferences.module";
import { ProjectRefService } from "./services/project-ref.service";
import { SubheaderModule } from "./components/subheader/subheader.module";
import { MainmenuModule } from "./components/mainmenu/mainmenu.module";
import { DashboardModule } from "./components/dashboard/dashboard.module";
import { ArtisticTreeModule } from './components/reports//artistictree/artistictree.module';
import { ReportOptionsPopupComponent } from './components/reports/common/reportOptions/reportOptionsPopup/report-options-popup.component';
import { BookReportEngineModule } from "./components/bookreportengine/book-report-engine.module";
import { IndividualMainComponent } from "./components/common/individual/individual-main/individual-main.component";
import { AboutusModule } from "./components/aboutus/aboutus.module";
import { AboutusMainComponent } from "./components/aboutus/aboutus-main/aboutus-main.component";
import { MapReportModule } from "./components/reports/mapreport/map-report-module";
import { TimelineReportModule } from "./components/reports/timelinereport/timelinereport.module";
import { CookieService } from 'ngx-cookie-service';
import { DescendantchartModule } from "./components/reports/descendantchart/descendantchart.module";
import { ReportsMenuComponent } from "./components/mainmenu/reports-menu/reports-menu.component";
import { FactSheetModule } from './components/reports/factsheet/factsheet.module';
import { IndexedDbService } from "./services/indexed-db.service";

@NgModule({
  declarations: [
    AppComponent,
    PopupBaseComponent,
    ConfirmationDialogComponent,
    // TooltipComponent
    BaseComponent,
    PedigreeoptionpopupComponent,
    MultimedialistComponent,
    MembershipUpgradePopupComponent,
    PaymentOption1Component,
    PaymentOption2Component,
    DynamicPaymentOptionDirective,
    MainplaceComponent,
    PlaceEditorComponent,
    EventsTempComponent,
    V3Component,
    ReportTemplateComponent,
    EditorTemplateComponent,
    EventsTempComponent,
    SunchartOptionPopupComponent,
    ReportengineTemplateComponent,
    ReportOptionsPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReportengineModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslatehandler,
        deps: [ParentCommunicatorService]
      }
    }),
    TimelineModule, TreeeditorModule, LeftpanelModule,
    SharedModule, IndividualmanagementModule,
    BrowserAnimationsModule, MultiMediaModule, LazyloadingModule,
    FormsModule,
    EditorModule,
    BreadcrumbModule,
    RouterModule,
    PlaceModule,
    CommonComponentModule,
    IndividualeditorModule,
    FamilyeditorModule,
    SourceModule,
    WitnessModule,
    SourceCitationModule,
    MessageDialogModule,
    TreemanagerModule,
    ReportmanagerModule,
    MenuModule,
    FamilyprojectmanagerModule,
    PreferencesModule,
    SubheaderModule,
    MainmenuModule,
    DashboardModule,
    ArtisticTreeModule,
    BookReportEngineModule,
    AboutusModule,
    MapReportModule,
    TimelineReportModule,
    DescendantchartModule,
    FactSheetModule
  ],
  exports: [TranslateModule],
  providers: [NgbActiveModal, HttpInterceptProviders, { provide: ErrorHandler, useClass: GlobalErrorHandler }, ApiBaseService,
    CustomApiParameters, LoadingService, MultimediamanagementService, NestedPopupService, PreferencesService, ConfirmationDialogService,
    GlobalerrorService, TreeEditorService, TooltipService, DialogService, ProjectRefService, CookieService,IndexedDbService],
  bootstrap: [AppComponent],
  entryComponents: [
    EventDetailNoteComponent,
    EventDetailMediaComponent,
    EventDetailSourceComponent,
    EventDetailWitnessComponent,
    TooltipTestContentComponent,
    DateTooltipComponent,
    IndividualsComponent,
    RelationshipComponent,
    PlaceMainComponent,
    MultimediaMainComponent,
    SourceMainComponent,
    WitnessMainComponent,
    SourceCitationMainComponent,
    MessagePromptComponent,
    IndividualPromptComponent,
    EventDetailIndividualComponent,
    SunchartOptionPopupComponent,
    Sunchart,
    MessagePromptStripeComponent,
    ReportPublishOptionComponent,
    FamilyProjectOptionMainComponent,
    PreferenceMainComponent,
    IndividualMainComponent,
    AboutusMainComponent,
    ReportsMenuComponent],
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
