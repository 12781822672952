import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MessageDialogService } from '../message-dialog/services/message-dialog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input() isUnsupportedSettings : boolean = true;
  index: number = 0;
  messages : any[] = ["lbl_loading", "lbl_setting_up", "lbl_authenticating"];
  text: string = "";

  constructor(private messageDialogService : MessageDialogService) { }

  ngOnInit(): void {
    this.index = 0;
    this.cycle();    
  }

  cycle(){
    this.text = this.messages[this.index];
    this.index++;
    if (this.index === this.messages.length) {
      this.index = 0;
    }
    setTimeout(() => {          
      this.cycle();
    }, 3000); 
  }


}
