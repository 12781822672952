import { Injectable } from '@angular/core';
import { SessionData } from '../../common/models/SessionData';
import { FetchProperty } from '../helpers/fetch-property';

@Injectable({
    providedIn: 'root'
})

export class SessionDataMapper {

    constructor(
        public fp: FetchProperty
    ) { }

    public map(obj: Object): SessionData {
        return {
            Id: this.fp.get(obj, 'Id'),
            EmailAddress: this.fp.get(obj, 'EmailAddress'),
            FirstName: this.fp.get(obj, 'FirstName'),
            LastName: this.fp.get(obj, 'LastName'),
            OnlineId: this.fp.get(obj, 'OnlineId'),
            PurchasedProducts: this.fp.get(obj, 'PurchasedProducts'),
            fileName: this.fp.get(obj, 'fileName'),
            isAuthenticated: this.fp.get(obj, 'isAuthanticated') ?? false,
            keyMemberId: this.fp.get(obj, 'keyMemberId'),
            logOutUrl: this.fp.get(obj, 'logOutUrl'),
            redirectionUrl: this.fp.get(obj, 'redirectionUrl'),
            rootMemberId: this.fp.get(obj, 'rootMemberId'),
            token: this.fp.get(obj, 'token'),
            encEmail: this.fp.get(obj, 'encEmail'),
            mainDomain: this.fp.get(obj, 'mainDomain'),
            rootpersonFirstName: this.fp.get(obj, 'rootpersonFirstName'),
            rootpersonLastName: this.fp.get(obj, 'rootpersonLastName')
        };
    }
}
