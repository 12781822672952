import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { LinkRequest } from 'src/app/common/models/LinkRequest';
import { Individual } from '../../../common/models/Individual';
import { IndividualApiService } from '../../../services/API/individual-api.service';

@Injectable()
export class TreeEditorApiService {

  constructor(private apiBaseService: ApiBaseService,
    private individualApiService: IndividualApiService) {
  }

  getTreePedigree<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get<T>('/api/tree/' + memberId, null, null); // add url to config file
  }

  updateMultimediaLink<T>(linkRequest: LinkRequest, memberId: number): Observable<T> {
    return this.individualApiService.updateMultimediaLink(linkRequest, memberId);
  }
}
