import { ReportType } from "src/app/common/enums/enums";

export class MapReportPublishModel {
  reportName: string;
  template: string;
  content: any;
  width: number;
  height: number;
  type: ReportType;
}
