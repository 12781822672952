import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopupOpenMode } from 'src/app/common/enums/enums';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-place-main',
  templateUrl: './place-main.component.html',
  styleUrls: ['./place-main.component.scss']
})
export class PlaceMainComponent extends DialogBaseComponent implements OnInit {

  public data: any;
  viewMode: number = 1;
  constructor(private ds: DialogService, private ref1: ChangeDetectorRef,) {
    super(ds, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();
    let options = PopupOptions.options.find(t => t.mode == this.data.mode) || PopupOptions.options[0];
    this.data.options = options;
    this.data.options.primaryTabName = (this.data.mode == PopupOpenMode.List) ? "lbl_place_list" : "lbl_select_from_Places";
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {
    this.data.viewMode = mode;
    if (mode == 1)
      this.data.reference = { Id: 0, rawData: "" }
  }
}
