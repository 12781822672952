import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { ReportFont } from '../../common/reportfont';
import { ReportUtil } from '../../common/reportutil';
import { PedigreeGraphics } from './pedigree-graphics';
import { PedigreeDataNode, PedigreeFnNode, PedigreeNode } from './predigree-node';


/*
* Pedigree box - generating all related graphics ( box, text, connector..etc )
*/
export class PedigreeBox {
    private mode: number;
    private level: number;
    private baseURL: string;
    private childIndex: number;
    private nodeX: number;
    private nodeY: number;
    private parentPos: any;
    private font: ReportFont;
    private configs: any;
    

    constructor(mode, level, baseURL, childIndex, nodeX, nodeY, parentPos, font, configs, protected translateHandler : TranslateHandler) {
        this.mode = mode;
        this.level = level;
        this.baseURL = baseURL;
        this.childIndex = childIndex;
        this.nodeX = nodeX;
        this.nodeY = nodeY;
        this.parentPos = parentPos;
        this.font = font;
        this.configs = configs;
    }

    generate(item) {

        if (!this.configs.data && !this.configs.function) {
            return null;
        }

        var id = item.id;
        var parentId = item.parentId;
        var parentFamilyId = item?.data?.parentFamilyId;
        var text1 = null;
        var text2 = null;
        var text3 = null;
        var image = null;
        var fnImage = null;
        var box = null;
        var connector = null;
        if (item instanceof PedigreeNode && !(item instanceof PedigreeDataNode) && !(item instanceof PedigreeFnNode)) {
            return null;
        }
        if (item instanceof PedigreeDataNode) {
            var posX = this.nodeX - this.configs.data.box.w / 2;
            var posY = this.nodeY - this.configs.data.box.h / 2;
            box = PedigreeGraphics.getBoxImage(posX, posY, this.baseURL, 1, this.configs.data.box);
            text1 = PedigreeGraphics.getText(posX, posY, item.data.name, 1, this.font, 1, this.configs.data.text1);
            text2 = PedigreeGraphics.getText(posX, posY, item.data.place, 1, this.font, 1, this.configs.data.text2);
            text3 = PedigreeGraphics.getText(posX, posY, item.data.period, 1, this.font, 1, this.configs.data.text3);

            image = PedigreeGraphics.getCircleMaskImage(posX, posY, item.data.profileURL, item.data.gender, this.configs.data.image, this.baseURL);
            if (!ReportUtil.isEmptyObject(this.parentPos)) {
                connector = PedigreeGraphics.getConnector(this.level, this.parentPos, this.mode, this.childIndex, 1);
            }
        }
        if (item instanceof PedigreeFnNode) {
            var posX = this.nodeX - this.configs.function.box.w / 2;
            var posY = this.nodeY - this.configs.function.box.h / 2;
            box = PedigreeGraphics.getBoxImage(posX, posY, this.baseURL, 0.5, this.configs.function.box);
            if (!ReportUtil.isEmptyObject(this.parentPos)) {
                connector = PedigreeGraphics.getConnector(this.level, this.parentPos, this.mode, this.childIndex, 0.5);
                text1 = PedigreeGraphics.getText(posX, posY, this.childIndex === 0 ? this.translateHandler.translate('lbl_add_father') : this.translateHandler.translate('lbl_add_mother'), 1, this.font, 0.5, this.configs.function.text1);
                fnImage = PedigreeGraphics.getBoxImage(posX, posY, this.baseURL, 0.5, this.configs.function.fnImage);
            }
        }

        return {
            "id": id,
            "parentId": parentId,
            "parentFamilyId":parentFamilyId,
            "box": box,
            "text1": text1,
            "text2": text2,
            "text3": text3,
            "image": image,
            "fnImage": fnImage,
            "connector": connector
        }
    }
}