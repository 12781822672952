import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-witness-main',
  templateUrl: './witness-main.component.html',
  styleUrls: ['./witness-main.component.scss']
})
export class WitnessMainComponent extends DialogBaseComponent implements OnInit {

  viewMode : number = 1;
  constructor(private ds:DialogService, private ref1:ChangeDetectorRef) {
    super(ds, ref1);
  }

  ngOnInit(): void {
  }

  done($event){
    this.complete({});
  }
}
