import { FTDate } from '../FTDate';
import { Crop } from './crop';

export class MultimediaBase {
  id: number;
  caption: string;
  fileName: string;
  description: string;
  date: FTDate;
  tag: string;
  place: string; // only string not related with Place table
  mediaType: number;
  cropInfo: Crop;
  isPrimary: boolean;  
}
