import { Component, OnInit } from "@angular/core";
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { MapReportConfigModel } from "src/app/common/models/report/mapreport/map-report-config-model";
import { LoadingService } from "src/app/services/UI/loading.service";
import { MapReportService } from "../services";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-map-report-main",
  templateUrl: "./map-report-main.component.html",
  styleUrls: ["./map-report-main.component.scss"],
})
export class MapReportMainComponent implements OnInit {
  mapReportConfigModel: MapReportConfigModel;
  processId: string;

  constructor(
    private mapReportService: MapReportService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.initMapConfig();
  };

  // Get map config data from the mapReportConfig.json file.
  initMapConfig() {
    // Show spinner
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    return this.mapReportService
      .getMapConfig()
      .subscribe((configData) => {
        this.mapReportConfigModel = configData;
        this.mapReportConfigModel.accessToken = environment.GEOCODE.mapboxAccessToken
      })
      .add(() => {
        // Hide spinner
        this.loadingService.hide(processId);
      });
  };
}
