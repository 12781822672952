import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyEditorComponent } from './family-editor/family-editor.component';
import { FamilyEditorMainComponent } from './family-editor-main/family-editor-main.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonComponentModule } from '../common/common.module';
import { ImageCarouselTempModule } from '../common/image-carousel-temp/image-carousel-temp.module';
import { EventsModule } from '../common/events/events.module';
import { MediaCarouselModule } from '../common/media-carousel/media-carousel.module';

@NgModule({
  declarations: [FamilyEditorComponent, FamilyEditorMainComponent],
  imports: [
    CommonModule,
    EventsModule,
    CommonComponentModule,
    FormsModule,
    ImageCarouselTempModule,
    MediaCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports:[FamilyEditorMainComponent]

})
export class FamilyeditorModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
