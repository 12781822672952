import { Injectable } from "@angular/core";
import { ApiBaseService } from "../api-base.service";
import { Observable} from "rxjs";
import { GeocodeResponseModel } from "src/app/common/models/geocode/geocode-response-model";
import { GeocodeRequestModel } from "src/app/common/models/geocode/geocode-request-model";

@Injectable({
  providedIn: "root",
})
export class GeocodeApiService {
  constructor(
    private apiBaseService  : ApiBaseService
    ) {}

  get(address: string): Observable<GeocodeResponseModel> {
    return this.apiBaseService.get<GeocodeResponseModel>(`/api/geocode/${address}`, null, null);
  }

  create(geocodeModel: GeocodeRequestModel): void {
    this.apiBaseService.create(`/api/geocode`,  null, geocodeModel).subscribe();
  }
}
