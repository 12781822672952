import { PageState, PagePosition } from "../enums/enums";
import ThemeList from "./ThemeList";

export default class BookReportConfig{
  private zoomStepSize: number;
  public minZoomLevel: number; 
  public maxZoomLevel: number; 
  public themes:  ThemeList;
  public staticPages : Array<BookReportPage>;
  public bookWidth : number;
  public bookHeight : number;
  public publishWidthCorrection : number;
  public publishHeightCorrection : number;
  public isRegenerateEnabled: boolean;
  public isZoomInEnabled: boolean;
  public isZoomOutEnabled: boolean;
  public isFirstContentPageEnabled: boolean;
  public isBackwardEnabled: boolean;
  public isForwardEnabled: boolean;
  public isLastContentPageEnabled: boolean;

  public getMinimumZoomLevel() {
    return Number(this.minZoomLevel) || 1;
  }

  public getMaximumZoomLevel() {
    return Number(this.maxZoomLevel) || 4;
  }

  public getZoomStepLevel() {
    return Number(this.zoomStepSize) || 0.25;
  }

}

export class BookReportPage{
  path : string;
  isCoverPage : boolean;
  pagePosition : PagePosition;
  pageOffset : number;
  isDynamicPage: boolean = false;
  pageState: PageState = PageState.Loading;
}