 <!-- Selected project and project list -->
 <div class="selected-project">
    <img *ngIf="getCurrentProject() && !getCurrentProject().isValid"       src="/assets/images/icons/invalid.svg" width="20" height="20" style="margin-right:5px ;" />
    <img *ngIf="getCurrentProject()?.isImporting" src="/assets/images/icons/loading-green.svg" width="20" height="20" style="margin-right:5px ;" />
    <div class="text-ellipsis-1">        
        {{getCurrentProject()?.projectName}}
    </div>
    <i class="arrow down arrow-black" style="margin-left: 10px;margin-bottom: 3px"></i>
    <div class="project-list">
        <div class="project-item project-item-link" style="border-bottom:1px solid #dcdcdb ;" (click)="route('familyprojectmanager')">{{'lbl_manage_trees' | translate}}</div>
        <div class="project-item project-item-link" style="border-bottom:1px solid #dcdcdb ;" (click)="openCreateImportPanel()">Opprett nytt slektstre</div>        
        <div style="max-height: 169px;overflow-y: auto;">
            <div class="project-item text-ellipsis-1" [class.project-item-selected]="project.familyProjectId == getCurrentProject()?.familyProjectId"  *ngFor="let project of getFamilyProjectList(); let i = index" (click)="setDefaultProject(project.familyProjectId)" >                
                <img *ngIf="!project.isValid" style="margin-right:5px;" src="/assets/images/icons/invalid.svg" width="20" height="20" />
                <img *ngIf="project.isImporting" style="margin-right:5px;" src="/assets/images/icons/loading-green.svg" width="20" height="20" />
                <div>{{project.projectName}}</div>
                <img *ngIf="project.familyProjectId == getCurrentProject()?.familyProjectId" src="/assets/images/icons/selected-green-check.svg" width="25" height="25">
            </div>
        </div>
    </div>
</div>