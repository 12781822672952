import { FeatureBase } from './FeatureBase';

export class CustomerFeature extends FeatureBase{
    customerFeatureId : number;
    customerId: string;
    productId : string;   
    isAccessEnabled: boolean;
    options : string; //common properties

    getOptions(){
        return JSON.parse(this.options) ?? {};
    }

    setOption(dynamicObj : Object){
        this.options = JSON.stringify(dynamicObj);
    }
}