import { ElementRef } from "@angular/core";
import { CustomMapThemeExe } from "./custom-map-theme-exe";
import * as mapboxgl from "mapbox-gl";

/**
 * This class extends the `CustomMapThemeExe` class to provide custom map theme
 * execution logic for the Vintage map theme.
 */
export class VintageThemeExe extends CustomMapThemeExe {
  /**
   * Sets the custom HTML elements for the Vintage map theme.
   * @param customHtmlConfig - The json object which contains all data related to custom Html  element rendering.
   * @param customHtmlElement - The custom HTML element reference.
   */
  setCustomHtml(customHtmlConfig:any , customHtmlElement: ElementRef) {
    customHtmlElement.nativeElement.innerHTML = customHtmlConfig.compassHtmlElement;
  }

  /**
   * Sets the custom layers for the Vintage map theme.
   * @param customMapConfig - The json obj which contains all data related to custom map(mapboxGL).
   * @param map - The map(mapboxGL) object .
   */
  setCustomMap(customMapConfig:any, map: mapboxgl.Map): void {
    if (customMapConfig.sources) {
      const sources = customMapConfig.sources;
      Object.keys(sources).forEach((sourceId) => {
        const source = sources[sourceId];
        if (map) {
          map.addSource(sourceId, source);
        }
      });

      if (customMapConfig.layers) {
        customMapConfig.layers.forEach((layer) => {
          if (map) {
            map.addLayer(layer);
          }
        });
      }
    }
  }
}