<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
  <div class="dialog-title-container">
    <div class="dialog-title-text">{{data.options.primaryTabName | translate}}</div>
  </div>
  <div class="tab-container app-dark-bg">
    <div class="tabs">
      <a *ngIf="isShowMode(1)" [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{tabName | translate}}</a>
      <a *ngIf="isShowMode(2)" [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">+ {{"lbl_add_children" | translate}}</a>
    </div>
    <div class="tab-forms app-light-bg">
      <app-individuals *ngIf="getViewMode() === 1" [data]="data"></app-individuals>
      <app-individual-adder *ngIf="getViewMode() === 2" [data]="data"></app-individual-adder>
    </div>
  </div>
</div>