<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_publish" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">            
        <div class="tabs">
            <span>{{"lbl_publish_report" | translate}}</span>               
        </div>
        <div class="tab-forms app-light-bg">                
            <div class="ft-form">
                <div class="content">                    
                    <div *ngIf='isOptionVisible("FileName")' class="ft-form-row col1-v100">
                        <div class="ft-form-input">
                            <label>{{"reportPublishOptions.lbl_file_name" | translate}}</label>
                            <input type="text" [(ngModel)]="reportFileName" [maxlength]="50" id="reportTitle">
                        </div>
                    </div>
                    <div *ngIf='isOptionVisible("Title")' class="ft-form-row col1-v100">
                        <div class="ft-form-input">
                            <label>{{"lbl_title" | translate}}</label>
                            <input type="text" [(ngModel)]="reportTitle" [maxlength]="50" id="reportTitle">
                        </div>                        
                    </div> 
                    <div *ngIf='isOptionVisible("Template")' class="ft-form-row col1-v100">
                        <div class="ft-form-input">
                            <label>{{"lbl_template" | translate}}</label>                            
                            <select [(ngModel)]="reportTemplate" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let template of templates" [ngValue]="template">
                                    {{template.name | translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>  
                <div class="footer">
                    <button type="button"  class="ft-btn" [ngClass]="isPublishButtonEnabled() ? 'ft btn ft-btn-active' : 'ft btn ft-btn-inactive'" style="width: 100px;" [disabled]="!isPublishButtonEnabled()" (click)="add()">{{"lbl_publish" | translate}}</button>
                    <button type="button" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
                </div>
            </div>  
        </div>            
    </div>
</div>
