export class Filter {
    static filterTypes = [];    
    // This  method is overridden on child classes
    static getFilters() { } 
}

export class PlaceFilter extends Filter {
    static getFilters() {
        return this.filterTypes = []
    }
}

export class EventFilter extends Filter {
    static getFilters() {
        return this.filterTypes = []
    }
}

export class MultiMediaFilter extends Filter {
    static getFilters() {
        return this.filterTypes = [{
            type: "all_media",
            id: 0,
            count: 0
        }, {
            type: "pictures",
            id: 1,
            count: 0
        },
        {
            type: "sounds",
            id: 2,
            count: 0
        },
        {
            type: "videos",
            id: 3,
            count: 0
        },
        {
            type: "documents",
            id: 4,
            count: 0
        }];
    }
}