import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeManagerMainComponent } from './tree-manager-main/tree-manager-main.component';
import { DropMenuModule } from '../common/drop-menu/drop-menu.module';



@NgModule({
  declarations: [TreeManagerMainComponent],
  imports: [
    CommonModule,
    DropMenuModule    
  ],
  exports:[ TreeManagerMainComponent ]
})
export class TreemanagerModule { }
