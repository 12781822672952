export class ChartTreeNode<T> {
    public x: number;
    public y: number;
    public mod: number;
    public parent: ChartTreeNode<T>;
    public children: ChartTreeNode<T>[];
   
    public item: T;
   
    constructor(item: T, parent: ChartTreeNode<T>) {
        this.item = item;
        this.parent = parent;
        this.children = [];
    }
   
    public isLeaf(): boolean {
        return this.children.length === 0;
    }
   
    public isLeftMost(): boolean {
        if (this.parent === null)
            return true;
   
        return this.parent.children[0] === this;
    }
   
    public isRightMost(): boolean {
        if (this.parent === null)
            return true;
   
        return this.parent.children[this.parent.children.length - 1] === this;
    }
   
    public getPreviousSibling(): ChartTreeNode<T> {
        if (this.parent === null || this.isLeftMost())
            return null;
   
        return this.parent.children[this.parent.children.indexOf(this) - 1];
    }
   
    public getNextSibling(): ChartTreeNode<T> {
        if (this.parent === null || this.isRightMost())
            return null;
   
        return this.parent.children[this.parent.children.indexOf(this) + 1];
    }
   
    public getLeftMostSibling(): ChartTreeNode<T> {
        if (this.parent === null)
            return null;
   
        if (this.isLeftMost())
            return this;
   
        return this.parent.children[0];
    }
   
    public getLeftMostChild(): ChartTreeNode<T> {
        if (this.children.length === 0)
            return null;
   
        return this.children[0];
    }
   
    public getRightMostChild(): ChartTreeNode<T> {
        if (this.children.length === 0)
            return null;
   
        return this.children[this.children.length - 1];
    }
   
    public toString(): string {
        return this.item.toString();
    }
}
