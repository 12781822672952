import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbTrailComponent } from './breadcrumb-trail/breadcrumb-trail.component';
import { BreadcrumbHeaderComponent } from './breadcrumb-header/breadcrumb-header.component';
import { EditorModule } from '../../editor/editor.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [BreadcrumbHeaderComponent, BreadcrumbTrailComponent],
  imports: [
    CommonModule,
    EditorModule,
    RouterModule
  ],
  exports : [
    BreadcrumbHeaderComponent,
    BreadcrumbTrailComponent
  ]
})
export class BreadcrumbModule { }
