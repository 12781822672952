import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StringLengthLimiter {

  // By this method, achieving the scope of breaking the names in to two lines,
  // in case the names are lengthier than the allowed limit to be displayed in the labels.
  breakText = function (txtHtml, lineNumber, limitLength) {

    txtHtml = txtHtml.trim();
    // Checking whether the text is lengthy than the limitLength and if not returning it
    if (txtHtml.length <= limitLength) {
      if (lineNumber === 1 || lineNumber === 0) {
        return txtHtml;
      }
    }
    // If the text is lengthier than the limit, then proceeding with else.
    else {
      if (lineNumber === 0) {
        let limitedText = txtHtml.substr(0, limitLength) + '...';
        return limitedText;
      }

      let splittedText = txtHtml.split(' ');

      // Attending to the scenario, where the splitted first line text itself lengthier than the allowed limit.
      if (splittedText[0].length > limitLength) {
        let firstLine = splittedText[0].substr(0, limitLength);
        let secondLine = splittedText[0].substr(limitLength - 1, splittedText[0].length - limitLength);

        for (let i = 1; i < splittedText.length; i++) {
          secondLine = secondLine + ' ' + splittedText[i];
        }

        if (secondLine.length > limitLength) {
          secondLine = secondLine.substr(0, limitLength) + '...';
        }

        if (lineNumber === 1) {
          return firstLine;
        } else {
          return secondLine;
        }
      }
      // Handling the case where if the splitted first word is not lengthier than the allowed limit.
      else {
        let firstLine = '';
        let secondLine = '';
        let firstLineFinalized = false;

        for (let i = 0; i < splittedText.length; i++) {
          if (!firstLineFinalized) {
            if ((firstLine.length + splittedText[i].length) <= limitLength) {
              firstLine = firstLine.length === 0 ? splittedText[i] : firstLine + ' ' + splittedText[i];
            }
            else {
              firstLineFinalized = true;
              secondLine = splittedText[i];
            }
          }
          else {
            secondLine = secondLine + ' ' + splittedText[i];
          }
        }

        if (lineNumber === 1) {
          return firstLine;
        }
        else {
          if (secondLine.length > limitLength) {
            secondLine = secondLine.substr(0, limitLength - 3) + '...';
          }
          return secondLine;
        }
      }
    }
  }

}