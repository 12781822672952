import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class TranslateHandler {
    constructor(private translateService: TranslateService) { }

    translate(key: string, params?: string[]) {
        if (!params)
            return this.translateService.instant(key);
        else {
            var regex = /{(.+?)}/;
            let tempTranslatedText = this.translateService.instant(key);
            let placeHolderArray = new Array;
            let match = regex.exec(tempTranslatedText);
            while (match != null) {
                placeHolderArray.push(match[0].toString());
                tempTranslatedText = tempTranslatedText.replace(match[0].toString(), "");
                match = regex.exec(tempTranslatedText);
            }
            let translatedText = this.translateService.instant(key);
            let i = 0;
            placeHolderArray.forEach(element => {
                if (i < params.length)
                    translatedText = translatedText.replace(element, params[i] ? params[i] : "");
                i++
            });
            return translatedText;
        }
    }
    translateEnum(enumType: any, enumValue: any): string {
        return this.translate("enum_" + (enumType[enumValue]).toLowerCase());
    }
}