import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

/**
 * TODO : Change entity names in RootMember & RootMemberResponse to 'camelCase' and refactor all the references - EM-12852
 *      : Replace RootMemberResponse with RootMemberAndFamily
 *      : RootMemberAndFamily {rootmember:RootMember; rootFamily:RootFamily;}
 */
export class RootMember {
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public DisplayName: string;
    public Gender: string;
}

export class RootMemberResponse extends RootMember{
    public defaultFamilyId: number; // Parent or Spouse
    //store rootmember's default family husband and wife display names if available to reduce API calls to get these data
    public husbandDisplayName?: string;
    public wifeDisplayName?: string;
}