import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-addindividual-main',
  templateUrl: './individual-main.component.html',
  styleUrls: ['./individual-main.component.scss']
})

export class IndividualMainComponent extends DialogBaseComponent implements OnInit {
  familyId: number = 0;
  tabName: string = "lbl_select_from_Persons";

  constructor(dialogService: DialogService,
    ref1: ChangeDetectorRef) {
    super(dialogService, ref1);
  }

  ngOnInit(): void {
    let options = PopupOptions.options.find(t => t.mode == this.data.mode) || PopupOptions.options[0];
    this.data.options = options;
    this.data.options.primaryTabName = "lbl_add_children";
    this.getViewMode();
    this.changeViewMode(this.data.viewMode);
    this.familyId = this.data.familyId;
    this.setTabName();
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {
    this.data.viewMode = mode;
    if (mode == 1) {
      this.data.reference = { Id: 0, rawData: "" }
    }
  }

  isShowMode(mode): number {
    if (!this.data.showMode) this.data.showMode = [mode];
    return this.data.showMode.includes(mode);
  }

  setTabName() {
    if (this.data.showMode.length == 2) {
      this.tabName = "lbl_select_from_Persons";
    }
    else {
      this.tabName = "lbl_persons_list";
    }
  }
}
