import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-preference-main',
  templateUrl: './preference-main.component.html',
  styleUrls: ['./preference-main.component.scss']
})
export class PreferenceMainComponent extends DialogBaseComponent implements OnInit {
  public data: any;
  viewMode: number = 1;
  
  constructor(dialogService: DialogService,
    private ref1: ChangeDetectorRef,
    private trasn : TranslateHandler,
    private parentCommunicatorService: ParentCommunicatorService) {
   super(dialogService, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();
  }

  getViewMode(): Number{
    return this.data.viewMode;
  }

  changeViewMode(mode) {   
    this.data.viewMode = mode;
  }
}
