<link rel="stylesheet" [href]="commonUrl + 'themes/common.css' | safe" />
<link rel="stylesheet" [href]="dynamicThemeUrl | safe" />

<!-- Load report info tooltip -->
<app-report-info-tooltip></app-report-info-tooltip>

<svg id="svgViewableArea" [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute" [attr.height]="config.svg.h"
  [attr.width]="config.svg.w" *ngIf="showReport">
  <!-- vConnector line mask define -->
  <defs>
    <mask id="Mask">
      <rect [attr.x]="timeline.vConnector.xPosition" [attr.y]="timeline.vConnector.yPosition"
        [attr.width]="timeline.vConnector.maskWidth" [attr.height]="timeline.vConnector.maskHeight" fill="white" />
    </mask>
  </defs>

  <g [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'" id="reportArea"
    [attr.transform]="reportTranslationObj.translateReportAttribute">

    <defs>
      <filter id="themeFilter1">
        <feColorMatrix in="SourceGraphic" type="hueRotate" values="90" result="A" />
      </filter>
      <filter id="themeFilter2">
        <feColorMatrix in="SourceGraphic" type="hueRotate" values="180" result="A" />
      </filter>
      <filter id="themeFilter3">
        <feColorMatrix in="SourceGraphic" type="hueRotate" values="270" result="A" />
      </filter>
      <filter id="themeFilter4">
        <feColorMatrix in="SourceGraphic" type="hueRotate" values="0" result="A" />
      </filter>
      <filter id="grayscale">
        <feColorMatrix type="saturate" values="0.10" />
      </filter>
    </defs>
    <!-- Grid -->
    <g *ngIf=gridShow>
      <line *ngFor="let gridItem of gridLines" [attr.x1]="gridItem.x1" [attr.y1]="gridItem.y1" [attr.x2]="gridItem.x2"
        [attr.y2]="gridItem.y2" class="gridItem"/>
    </g>

    <!-- get last potion of Grid x and show . in svg -->
    <g >
      <rect [attr.x]="gridLines[gridLines.length-1].x2" [attr.y]="gridLines[gridLines.length-1].y2+10" width="1" height="1" fill="none"/>
    </g>

    <!-- Report Title -->
    <text [attr.x]="config.reportTitle.x" [attr.y]="config.reportTitle.y" dominant-baseline="auto" text-anchor="middle" class="reportTitle">
      {{ config.options.title }}
    </text>

    <!-- vConnector Node -->
    <g *ngFor="let i of [].constructor(timeline.vConnector.numberOfVConnectors); let index = index" mask="url(#Mask)">
      <image class="theme" attr.href="{{reportURL}}images/{{timeline.vConnector.img}}" [attr.x]="timeline.vConnector.xPosition"
        [attr.y]="timeline.vConnector.yPosition + (timeline.vConnector.height * index)" />
    </g>

    <g *ngFor="let unit of timeline.units;let unitIndex = index">
      <!-- event Node -->
      <g *ngFor="let event of unit.eventNode;  let eventIndex = index">
        <!-- event horizontal connector -->
        <image class="theme" attr.href="{{reportURL}}images/{{event.hConnector.img}}"
          [attr.x]="event.hConnector.xPosition + event.hConnector.offsetXPosition"
          [attr.y]="event.hConnector.yPosition + event.hConnector.offsetYPosition" />
        <!-- event box -->
        <image class="theme" attr.href="{{reportURL}}images/{{event.img}}" [attr.x]="event.xPosition + event.offsetXPosition"
          [attr.y]="event.yPosition + event.offsetYPosition" />
        <!-- event header -->
        <ng-container *ngFor="let text of event.header.textContent;let headerTextIndex = index">
          <text [attr.x]="text.xPosition + text.offsetXPosition + event.xPosition + event.offsetXPosition"
            [attr.y]="text.yPosition + text.offsetYPosition + event.yPosition + event.offsetYPosition"
            [attr.font-family]="text.fontFamilyValue" [attr.font-size]="text.fontSizeValue"
            [attr.font-weight]="text.fontWeightValue" [attr.text-anchor]="text.anchorValue" [attr.fill]="text.fillValue"
            ngClass="header-text-{{unitIndex}}-{{eventIndex}}-{{headerTextIndex+1}}" alignment-baseline="central" (mouseenter)="showTooltip(text.textContent, text.maxCharacterLength,'header',unitIndex,eventIndex,headerTextIndex+1)">
            {{this.breakText(text.textContent,text.maxCharacterLength)}}
          </text>
        </ng-container>
        <ng-container *ngFor="let icon of event.header.icons">
          <image class="theme" attr.href="{{reportURL}}images/{{icon.img}}"
            [attr.x]="icon.xPosition + icon.offsetXPosition + event.xPosition + event.offsetXPosition"
            [attr.y]="icon.yPosition + icon.offsetYPosition + event.yPosition + event.offsetYPosition" />
        </ng-container>
        <!-- event body -->
        <ng-container *ngFor="let text of event.body.textContent">
          <foreignObject [attr.x]="text.xPosition + text.offsetXPosition + event.xPosition + event.offsetXPosition"
            [attr.y]="text.yPosition + event.yPosition + text.offsetYPosition" [attr.width]="text.width" [attr.height]="text.height"
            [attr.font-size]="text.fontSizeValue">
            <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
              <xhtml:p xmlns:xhtml="http://www.w3.org/1999/xhtml" class="event-description description-{{unitIndex}}-{{eventIndex}}" (mouseenter)="showTooltip(text.textContent, text.maxCharacterLength,'description',unitIndex,eventIndex)">
                {{text.textContent}}
              </xhtml:p>
            </xhtml:div>
          </foreignObject>
        </ng-container>
        <!-- event media carousal -->
        <ng-container *ngIf="event.media.mediaContent.length > 0">       
          <foreignObject 
          [attr.x]="event.xPosition + event.offsetXPosition + event.media.xPosition + event.media.offsetXPosition" 
          [attr.y]="event.yPosition + event.offsetYPosition + event.media.yPosition + event.media.offsetYPosition" 
          [attr.width]="event.media.width" [attr.height]="event.media.height">   
            <xhtml:div xmlns="http://www.w3.org/1999/xhtml" class="media-carousel">
              <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                <xhtml:img xmlns="http://www.w3.org/1999/xhtml" class="carousel-left-arrow" attr.src="{{reportURL}}images/{{config.event.eventBox.media.carousel.leftArrowImg}}"
                  (click)="scrollMM(unitIndex,eventIndex,'left')" id="carousel-left-arrow-{{unitIndex}}-{{eventIndex}}"> </xhtml:img>
              </xhtml:div>
              <xhtml:div xmlns="http://www.w3.org/1999/xhtml" class="multimedia-container media-{{unitIndex}}-{{eventIndex}}">
                <xhtml:div xmlns="http://www.w3.org/1999/xhtml" class="media-item" *ngFor="let media of event.media.mediaContent">       
                  <xhtml:img xmlns="http://www.w3.org/1999/xhtml" class="multimedia" attr.src="{{media}}">
                  </xhtml:img>
                </xhtml:div>
              </xhtml:div>
              <div [ngClass]="{'carousal-arrow-hidden': event.media.mediaContent.length <= 3}">
                <xhtml:img xmlns="http://www.w3.org/1999/xhtml" class="carousel-right-arrow" attr.src="{{reportURL}}images/{{config.event.eventBox.media.carousel.rightArrowImg}}"
                  (click)="scrollMM(unitIndex,eventIndex,'right')" id="carousel-right-arrow-{{unitIndex}}-{{eventIndex}}"> </xhtml:img>
              </div>
            </xhtml:div>
          </foreignObject>
        </ng-container>
      </g>

      <!-- Year Node -->
      <g>
        <image class="theme" attr.href="{{reportURL}}images/{{unit.yearNode.img}}" [attr.x]="unit.yearNode.xPosition"
          [attr.y]="unit.yearNode.yPosition" />
        <text [attr.x]="unit.yearNode.text.xPosition + unit.yearNode.text.offsetXPosition"
          [attr.y]="unit.yearNode.text.yPosition + unit.yearNode.text.offsetYPosition"
          [attr.font-size]="unit.yearNode.text.fontSizeValue" [attr.font-family]="unit.yearNode.text.fontFamilyValue" [attr.font-weight]="unit.yearNode.text.fontWeightValue"
          [attr.text-anchor]="unit.yearNode.text.anchorValue" [attr.fill]="unit.yearNode.text.fillValue"
          alignment-baseline="central">
          {{ unit.yearNode.text.textContent}}
        </text>
      </g>
    </g>

  </g>
</svg>