<div class="base-panel"
    [style.z-index]="isModalVisible ? 1500 : -10" >
    <!-- Dialog container -->
    <div class="opacity-panel"
        [style.opacity]="isModalVisible ? 0.5 : 0">        
    </div>   
    <div class="content-panel" >                
        <div [ngClass]="{ 'content-container':!isStripePopup,'content-stripe-container':isStripePopup}">
            <div *ngIf="!isStripePopup" class="close-button" >
                <img (click)="close()" [src]="'/assets/images/icons/close-0.svg'"/>                
            </div>
            <template #messageContent></template>
        </div>
    </div>
     
</div>
