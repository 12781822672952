export class ReportTranslation {
  viewableSVGAreaWidth: number;
  viewableSVGAreaHeight: number;
  zoomedViewableSVGAreaWidth: number;
  zoomedViewableSVGAreaHeight: number;
  translateReportAttribute: string;
  viewBoxReportAttribute: string;
  reportBoundry: any;
  hide: boolean;
  isPointerDown: boolean;
  pointerX: number;
  pointerY: number;
  viewBoxX: number;
  viewBoxY: number;
}
