export class CircularHelper {

    circularConfig;
    reportSize;
    constructor(circularConfig, reportSize) {
        this.circularConfig = circularConfig;
        this.reportSize = reportSize;
    }
    
    transform(nodeList, rootNode,  maxValue){
        this.mappingNodes(nodeList , rootNode, maxValue)
    }

    //  maps the x,y nodes to a curved line
    private mappingNodes (nodelist, rootNode, maxValue){
        for (let node of nodelist) {
            let svg_width = maxValue ;
            let r =  this.circularConfig.base.levels[node.level].levelRadius
            let curve_width = ( 2 * Math.PI * r * this.circularConfig.base.treeAngle ) / 360
            let ratio = curve_width / svg_width
            let curve_s = (node.x + maxValue/2 - this.reportSize.w/2) * ratio 
            let angle;
            if (r!=0){
                angle = curve_s / r 

            }else{
                angle = 0
            }
            node.y = rootNode.y - r * Math.sin(angle)
            node.x = rootNode.x  - r * Math.cos(angle)
        }     
    }



}