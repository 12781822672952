import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';
import { EditorMode } from 'src/app/common/enums/enums';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { DialogService } from 'src/app/services/UI/dialog.service';

@Component({
  selector: 'app-family-projects',
  templateUrl: './family-projects.component.html',
  styleUrls: ['./family-projects.component.scss']
})
export class FamilyProjectsComponent implements OnInit {

  constructor(private projectRefService       : ProjectRefService, 
              private router                  : Router, 
              private familyProjectAPIService : FamilyProjectApiService,
              private dialogService           : DialogService,
              private notifierService       : NotifierV2Service) { }

  ngOnInit(): void {
    
  }

  setDefaultProject(familyProjectId){
    this.familyProjectAPIService.setDefaultFamilyProject({DefaultFamilyProjectId:familyProjectId}).subscribe( res=>{
      this.projectRefService.setCurrentProject(familyProjectId);
    });
  }
  route(routeName){
    this.router.navigate([AppConstatnts.routerModes[routeName]])
  }

  getCurrentProject(){    
    return this.projectRefService.getCurrentProject();
  }

  getFamilyProjectList(){    
    return this.projectRefService.getProjectList();
  }

  async openCreateImportPanel(){
    await this.dialogService.setFull().open("AddNewProjectMain", 
    {
      viewMode: 1,
      showMode: [1,2],
      selectedProjectId : null,      
      FamilyProjectList: this.projectRefService.getProjectList(),
    }
    ).toPromise().then(async res=>{
      if(res?.data =="success" || res?.data =="import_success" ){ // New tree or import success
        this.projectRefService.load().then(res=>{
         this.openTreeEditor();
        })
      }
    });
  }

  openTreeEditor(){
    let ref:EditorReferenceInfo = {
      id : this.notifierService.getCurrentRootMemberId(),      
      isBreadcrumbUpdate: true
    }
    this.notifierService.openEditor(EditorMode.TreeEditor, ref);
  }
}
