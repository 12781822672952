export class PlaceLabelHelper {
    static placeTypes = [
        {
            id: 1,
            description: "lbl_address",
            fields: {
                place: "lbl_street_name",
                farm: "lbl_street_number",
                village: "lbl_postal_code",
                municipility: "lbl_post_office",
                county: "lbl_county",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 2,
            description: "lbl_address_USA",
            fields: {
                place: "lbl_street",
                farm: "lbl_city",
                village: "lbl_county",
                municipility: "lbl_state",
                county: "lbl_zip",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 3,
            description: "lbl_church",
            fields: {
                place: "lbl_place",
                farm: "lbl_parish",
                village: "lbl_parish",
                municipility: "lbl_deanery",
                county: "lbl_bishopric",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 4,
            description: "lbl_dis_Norge",
            fields: {
                place: "lbl_place",
                farm: "lbl_farm",
                village: "lbl_parish",
                municipility: "lbl_municipility",
                county: "lbl_county",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 5,
            description: "lbl_geographical",
            fields: {
                place: "lbl_place",
                farm: "lbl_farm",
                village: "lbl_parish",
                municipility: "lbl_municipility",
                county: "lbl_county",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 6,
            description: "lbl_gml_dansk",
            fields: {
                place: "lbl_gade",
                farm: "lbl_sted",
                village: "lbl_sogn",
                municipility: "lbl_herred",
                county: "lbl_amt",
                country: "lbl_land",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 7,
            description: "lbl_ny_Dansk",
            fields: {
                place: "lbl_gade",
                farm: "lbl_sted",
                village: "lbl_by",
                municipility: "lbl_kommune",
                county: "lbl_region",
                country: "lbl_land",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 8,
            description: "lbl_officiel_Dansk",
            fields: {
                place: "lbl_gade",
                farm: "lbl_sted",
                village: "lbl_by",
                municipility: "lbl_kommune",
                county: "lbl_amt",
                country: "lbl_land",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 9,
            description: "lbl_rode",
            fields: {
                place: "lbl_rode_no",
                farm: "lbl_house_no",
                village: "lbl_township",
                municipility: "lbl_town",
                county: "lbl_county",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },
        {
            id: 10,
            description: "lbl_technical",
            fields: {
                place: "lbl_farm_number",
                farm: "lbl_holding_number",
                village: "lbl_place",
                municipility: "lbl_municipility",
                county: "lbl_county",
                country: "lbl_country",
                latitude: "lbl_latitude",
                longitude: "lbl_longitude"
            }
        },

    ]
}