import { GenealogyDateMode } from '../enums/enums';
import { GenealogyDate } from '../helpers/date/genealogy-date';
import { GenealogyDateRange } from '../helpers/date/genealogy-date-range';

export class FTDate {
    genealogyDate: GenealogyDate;
    genealogyDateRange: GenealogyDateRange;
    formattedText: string;
    isValidDate: boolean = true;
}

export class GenealogyDateBaseModel {
    originalStr: string;
    year: number;
    month: number;
    day: number;
    unknown: number;   // Either year month or day
    matchMode: GenealogyDateMode;
    isValidDate: boolean;
    isApprox: boolean;
    approxStr: string;
    culture: string;
}