import { TimelineConfigModel } from "src/app/common/models/report/timelinereport/timeline-config-model";
import { TimelineVConnectorModel } from "src/app/common/models/report/timelinereport/timeline-vConnector-model";

export class VConnectorGraphics {
    public static get(vConnectorConfig: TimelineConfigModel['vConnector']): TimelineVConnectorModel {
        let vConnector: TimelineVConnectorModel = {
            img: vConnectorConfig.img,
            maskHeight: 0,
            maskWidth: 0,
            numberOfVConnectors: 0,
            xPosition: 0,
            yPosition: 0,
            offsetXPosition: vConnectorConfig.offsetXPosition,
            offsetYPosition: vConnectorConfig.offsetYPosition,
            width: vConnectorConfig.width,
            height: vConnectorConfig.height
        }
        return vConnector;
    }
}