<!-- Sub header for report templates -->
<app-subheader-main></app-subheader-main>
<div class="base-content-wrapper">    
    <app-message-dialog></app-message-dialog>
    <div class="left-panel-wrapper" *ngIf="showLeftPanel">     
        <app-mainleftpanelview></app-mainleftpanelview>     
    </div>
    <div class="main-content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div> 
