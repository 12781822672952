import { NgModule } from "@angular/core";
import { Routes, RouterModule, CanDeactivate } from '@angular/router';
import { MaintimelineviewComponent } from "./components/timeline/maintimelineview/maintimelineview.component";
import { MaintreeeditorviewComponent } from "./components/treeeditor/maintreeeditorview/maintreeeditorview.component";
import { MainreportengineviewComponent } from "./components/reportengine/mainreportengineview/mainreportengineview.component";
import { Sunchart } from "./components/reports/sunchart/sunchart/sunchart.component";
import { MultimedialistComponent } from './components/multimedialist/multimedialist.component';
import { EventsTempComponent } from './components/events-temp/events-temp.component';
import { FamilyEditorMainComponent } from "./components/familyeditor/family-editor-main/family-editor-main.component";
import { IndividualEditorMainComponent } from "./components/individualeditor/individual-editor-main/individual-editor-main.component";
import { TreeManagerMainComponent } from "./components/treemanager/tree-manager-main/tree-manager-main.component";
import { RouteGuardService } from './services/route-guard.service';
import { ReportTemplateComponent } from './templates/report/report-template.component';
import { EditorTemplateComponent } from './templates/editor/editor-template.component';
import { RouteResolver } from './services/resolve-guard';
import { MenuItemComponent } from './components/menu/menu-item/menu-item.component';
import { HomeComponent } from "./components/common/home/home.component";
import { ReportengineTemplateComponent } from "./templates/reportengine-template/reportengine-template.component";
import { ReportManagerMainComponent } from './components/reportmanager/report-manager-main/report-manager-main.component';
import {FamilyProjectMainComponent} from './components/familyprojectmanager/family-project-main/family-project-main.component';
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { BookReportEngineComponent } from "./components/bookreportengine/book-report-engine/book-report-engine.component";
import { MapReportMainComponent } from "./components/reports/mapreport/map-report-main/map-report-main.component";

const routes: Routes = [
  {
    path: 'treemanager',
    component: TreeManagerMainComponent
  },
  {
    path: 'timeLine',
    component: MaintimelineviewComponent
  },
  {
    path: 'Editor',
    redirectTo: 'report/treeeditor',
    canDeactivate: [RouteGuardService]
  },
  {
    path: 'multimediaList',
    component: MultimedialistComponent
  },
  {
    path: "reportengine",
    component: ReportengineTemplateComponent,
    children: [
      { path: ":name", component: MainreportengineviewComponent, canDeactivate: [RouteGuardService] }
    ],
    canDeactivate: [RouteGuardService]
  },
  {
    path: "eventTemp",
    component: EventsTempComponent
  },

  //New routes
  {
    path: "report",
    component: ReportTemplateComponent,
    children: [
      {
        path: "treeeditor/:id/:isBreadcrumbUpdate",
        //path: "treeeditor, change @EM-10562
        component: MaintreeeditorviewComponent, canDeactivate: [RouteGuardService]
      }
    ],
    canDeactivate: [RouteGuardService]
  },
  {
    path: "editor",
    component: EditorTemplateComponent,
    children: [
      {
        path: "individualeditor/:id/:isBreadcrumbUpdate",
        //path: "individualeditor", change @EM-10562
        component: IndividualEditorMainComponent,
        canDeactivate: [RouteGuardService],
        resolve: { items: RouteResolver },
      },
      {
        path: "familyeditor/:id/:isBreadcrumbUpdate",
        //path: "familyeditor", change @EM-10562
        component: FamilyEditorMainComponent,
        canDeactivate: [RouteGuardService],
        resolve: { items: RouteResolver }
      },
    ],
    canDeactivate: [RouteGuardService]
  },
  {
    path: 'commomList/:name',
    component: MenuItemComponent
  },  
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'reportmanager',
    component: ReportManagerMainComponent
  },
  {
    path: 'familyprojectmanager',
    component: FamilyProjectMainComponent
  },
  {
    path: "dashboard",
    component: DashboardComponent    
  },
  {
    path: "bookreportengine/:name",
    component: BookReportEngineComponent,
    canDeactivate: [RouteGuardService]
  },
  {
    path: "mapreport",
    component: MapReportMainComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RouteResolver, RouteGuardService]
})
export class AppRoutingModule { }
