import { EventBase } from "src/app/common/models/event/event-base";
import { FamilyIndividual } from "./family-individual";

// Api 1: api/family/{familyId}
export class FamilyBase {
    id: number;
    husbandId: number = null; 
    wifeId: number = null; 
    address: string = null; // Need
    phone: string = null; // Need
    email: string = null; // Need
    userNote: string = null; // Need
    searchResults: boolean; // Need
    calendar: boolean; // Need
    note: string = null; // Need

    marriageEvent: EventBase; // Need
    // SeparationEvent: EventBase; // Not required now

    // PrimaryMultimediaId: number; Todo: Move into MultimediaBasicInfo
    multimediaIds: number[];

    children: FamilyIndividual[]; // Need
}