<div class="reports-dialog">
  <div class="dialog-title-container">
    <div class="dialog-title-text">{{"menu.reports.lbl_reports" | translate}}</div>
  </div>
  <div class="tab-container app-dark-bg">            
          <div class="tabs">
              <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{data.options.primaryTabName | translate}}</a>
          </div>
          <div class="tab-forms app-light-bg">   
            <app-all-reports *ngIf="getViewMode() === 1" [data]="data" (onCompleted)="complete($event)"></app-all-reports>              
          </div>            
  </div>
</div>
