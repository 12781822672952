import { ApiBaseService } from '../../services/api-base.service';
import { FTDate } from '../models/FTDate';
import { Preference } from '../models/Preference';
import { GenealogyDateFormatter } from './date/genealogy-date-formatter';
import { GenealogyDateValidator } from './date/genealogy-date-validator';
import { PreferencesService } from 'src/app/services/preferences.service';
import { Injectable } from '@angular/core';
import { GenealogyDate } from './date/genealogy-date';
import { GenealogyDateRange } from './date/genealogy-date-range';
import { ResponseModel } from '../models/responseModel';
import { CustomError } from './custom-error';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class DateHelper {
    private preference: any; // Implement class Preference and change type any to Preference

    //pass preferences
    constructor(private preferenceService: PreferencesService) {
    }

    testDate = ["21 11.1975",
        "21.11 1975",
        "21 11 1975",
        "21.11.1975",
        "21 .11.    1975",
        "21/11/1975",
        "21 11/1975",
        "21/11 1975",
        "21.11/1975",
        "21 jan 1975",
        "21.feb 1975",
        "21 mar.1975",
        "21/apr/1975",
        "21/may 1975",
        "21/jun.1975",
        "21/Jul.1975",
        "21.aug.1975",
        "21.sep.1975",
        "21.oct.1975",
        "21.nov.1975",
        "21.dec.1975",
        "21 January.1975",
        "21/February/1975",
        "21/march 1975",
        "21.April/1975",
        "41.April/1975", // error
        "21 may 1975",
        "21.June.1975",
        "21 July 1975",
        "21 august 1975",
        "21 September 1975",
        "21 october/1975",
        "21 November/1975",
        "21 december.1975",
        "21    /     december             .         1975",
        "Dec  21. 1975",
        "Dec-21-1975",
        "21-1-1975",
        "June 4, 1829", // coma seperate dates
        "Jun 4, 1879",
        "4 4, 1879",
        "4 5 , 1879",
        "4 7 ,1879",
        // Range related cases
        "21-1 1975",
        "Nov 1-1975",
        "40 12 2020",
        "Jan 33 2020",
        "test",
        "4 Dec 2020",
        "4.24.2020",
        "5th january 2020",
        "5th feb",
        "2020/1/2",
        "1852/15/4",
        "Februrary 1641",
        "2020 jan 25",
        "1989.3.4"
    ];

    testApprox = [
        "approximately 1975",
        "approx Nov 22",
        "approx. 1975",
        "after Jul 1 1971",
        "aft Jul 1 1971",
        "before 20 Nov 1970",
        "bef 20 Nov 1970",
        "ca 1975",
        "assumed 1975",
        "assume 1975",
        "cal 21/Nov.1975",
        "about 1975",
        "about 21 Nov 1975",
        "about 1.1.2001 - 2.2.2002",
        "q1 1930",
        "quarter1 1920",
        "ca1975",
        "aft1975",
    ]

    testOneYearRange = [
        "1978/1979",
        "1987/1990",
        "Jan/1975/76",
        "Jan 1751/1752",
        "January 1751/1752",
        "jan.1751/1752",
        "01 1975/1977",
        "01/1978/79",
        "01/1978/1979",
        "1.1978/1979",
        "100/101",
        "209/10",
        "1978 / 79",
        "1978/Nov 1979",
        "1978/1.1979",
        "1978/1779",
        "1978/77",
        "2020/21",
        "Jan*1751/1752",
        "21  / december . 1975",
        "Jan-1975/1976",
        "1-1978/1979",
        "1.1.1978/2.1.1979",
        "1.1.1978/1979",
        "1.1.1978/79",
        "1 Nov 1980/81",
        "1/Nov/1980/81"
    ];

    testRange = ["11 - 11",
        "11 - sdfsdfs",
        "1920    -1940",
        "1920-1940",
        "1920-1940",
        "11.11.1975 - 12.12.1976",
        "21-1 1975",
        "1 June 1965 - 2.May 1970",
        "Nov 1-1975",
        "FROM 1954 TO 1958",
        "from 21.Nov.1976 to 21.1.1976",
        "From June 4, 1829 to October 8, 1831",
        "Between 1933 and 1936",
        "Between April 1973 and September 1977",
        // error scenarios
        "11 -- 11",
        "1975 to 1976",
        "from1873to1978",
        "between 1975and 1976",
        "bet 1975 and1223",
        "21*nov-1975", // isdate 
        "aaaaa - 1975", // isdate
        "aaa - adsf"]; // isdate

    private getCulture(): string {
        return this.preferenceService.getCulture();
    }
    // private GetPreferences(): any {
    //     return this.projectPreferencesService.GetPreferences();
    // }

    validateAndFormat(dateString: string): FTDate {
        //Validate 
        let culture = this.getCulture();
        //TODO: if the projcet culture is not there return default ?? which default
        culture = !culture ? 'no' : culture;
        let validateHelper = new GenealogyDateValidator(culture);
        let validatedDate = validateHelper.validateDate(dateString);

        //Format
        let formattedDate = this.formatDate(validatedDate);
        let fTDate = new FTDate()
        fTDate.formattedText = formattedDate ? formattedDate : validatedDate.originalStr;
        fTDate.genealogyDate = (validatedDate instanceof GenealogyDate) ? validatedDate : null;
        fTDate.genealogyDateRange = (validatedDate instanceof GenealogyDateRange) ? validatedDate : null;
        fTDate.isValidDate = formattedDate ? true : false;

        return fTDate;
    }

    formatDate(ftGenealogyDate: GenealogyDate | GenealogyDateRange) {
        this.preference = this.preferenceService.get();
        let culture = this.getCulture();
        let genealogyDateFormatter = new GenealogyDateFormatter(this.preference, culture);
        return genealogyDateFormatter.format(ftGenealogyDate);
    }

    mapToDate(year: number, month: number, day: number) {
        var mappedDate = new Date("");
        if (year) {
            mappedDate.setFullYear(year);
            if (month) {
                mappedDate.setMonth(month);
                if (day)
                    mappedDate.setDate(day);
            }
        }
        return mappedDate;
    }

}