import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-witness-editor',
  templateUrl: './witness-editor.component.html',
  styleUrls: ['./witness-editor.component.scss']
})
export class WitnessEditorComponent implements OnInit {

  @Output() onCompleted = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  save(){
    this.onCompleted.next({});
    this.onCompleted.unsubscribe();
  }
  cancel(){
    this.onCompleted.next({});
    this.onCompleted.unsubscribe();
  }
}
