import { Component, OnInit } from '@angular/core';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';

@Component({
  selector: 'app-events-temp',
  templateUrl: './events-temp.component.html',
  styleUrls: ['./events-temp.component.scss']
})
export class EventsTempComponent implements OnInit {

  public isLeftPanelHide:boolean;

  constructor(private parentCommunicatorService: ParentCommunicatorService) {
    this.parentCommunicatorService.getLeftPanelHideObs.subscribe(IsExpand => {
    this.isLeftPanelHide = IsExpand;
    console.log("getLeftPanelHideObs in MemberEditorMainComponent : " + this.isLeftPanelHide);
  }); }

  ngOnInit(): void {
  }

}
