<div class="breadcrumb-main">
    <div class="breadcrumb-selected">
        <div class="member-icon"></div>
        <div class="breadcrumb-selected-name">{{breadCrumbList[breadCrumbList.length-1]?.DisplayName}}</div>
        <div class="dropdown-arrow"></div>
    </div>
    <div class="breadcrumb-list">
        <div class="breadcrumb-list-item" *ngFor="let crumb of breadCrumbList" (click)="changeRootPerson(crumb)">
            <div class="member-icon"></div>
            <div class="breadcrumb-list-item-name">{{crumb.DisplayName?.trim()}}</div>
        </div>
    </div>
</div>