import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelationshipComponent } from './relationship/relationship.component';
import { IndividualsComponent } from './individuals/individuals.component';
import { DropMenuModule } from '../drop-menu/drop-menu.module';
import { MediaGalleryModule } from '../media-gallery/media-gallery.module';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { IndividualMainComponent } from './individual-main/individual-main.component';
import { IndividualAdderComponent} from './individual-adder/individual-adder.component';


@NgModule({
  declarations: [ RelationshipComponent, IndividualsComponent, IndividualMainComponent,IndividualAdderComponent],
  imports: [
    CommonModule,
    DropMenuModule,
    MediaGalleryModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports : [ RelationshipComponent, IndividualsComponent, IndividualMainComponent, IndividualAdderComponent]
})
export class IndividualModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
