<div class="content-wrapper-col2-vf">
    <div>
        <div class="col-wrap-v">
            <app-individual-editor style="width: 100%;" [data]="data" (changeData)="changeData($event)" (setTitle)="setTitle($event)" (showMain)="showMain($event)"></app-individual-editor>
        </div>
        <div class="col-wrap-f">
            <app-events-main style="width:100%;height:100%;position:relative" [data]="data" [title]="eventTitle" (changeData)="changeData($event)"></app-events-main>
        </div>
    </div>
</div>
