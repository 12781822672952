<div class="map-report-toolbar-background"> 
  <div class="zoom-in-button button-layout" (click)="changeZoomMode('ZOOM_IN')">
    <span class="toolTip">{{"lbl_zoomin" | translate}}</span>
  </div>
  <div class="zoom-out-button button-layout" (click)="changeZoomMode('ZOOM_OUT')">
    <span class="toolTip">{{"lbl_zoomout" | translate}}</span>
  </div>
  <div class="center-button button-layout" (click)="changeZoomMode('ZOOM_CENTER')">
    <span class="toolTip">{{"lbl_center" | translate}}</span>
  </div>
  <div class="zoom-to-extend-button button-layout" (click)="changeZoomMode('ZOOM_FIT')">
    <span class="toolTip">{{"lbl_reset" | translate}}</span>
  </div>
  <div [ngClass]="translatedThemeList.length > 0 ? 'dropdown' : 'dropdown-disable'" class="button-layout" >
    <div class="dropdown-content">
      <div *ngFor="let theme of translatedThemeList" (click)="selectTheme(theme.id)">
        <a>{{ theme.name }}</a>
      </div>
    </div>
  </div> 
  <div class="publish-button button-layout" (click)="publishMapReport()">
    <span class="toolTip">{{"lbl_publish" | translate}}</span>
  </div> 

  <div class="options-button button-layout">
    <span class="toolTip">{{"lbl_options" | translate}}</span>
  </div> 

</div>