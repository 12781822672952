import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/services/UI/confirmation-dialog.service';
import { ConfirmationInputInfo } from 'src/app/common/models/ConfirmationInputInfo';
import { ConfirmationContent } from 'src/app/common/models/ConfirmationContent';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  okayButton: string = "btn-ok.png";
  closeButton: string = "btn-cancel-disable.png";
  confirmationServiceSubscription: any;
  visibility: boolean;
  confirmationHeader: string;
  confirmationBody: string;
  confirmationFooter: string;


  constructor(private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.confirmationServiceSubscription = this.confirmationDialogService.onVisibilityChanged() // OnVisibilityChanged()
      .subscribe((popupInput: ConfirmationInputInfo) => {
        this.visibility = popupInput.Visibility;
        this.confirmationHeader = popupInput.ConfirmationContent.ConfirmationDialogHeader;
        this.confirmationBody = popupInput.ConfirmationContent.ConfirmationDialogBody;
        this.confirmationFooter = popupInput.ConfirmationContent.ConfirmationDialogFooter
      })
  }

  confirm() {
    this.confirmationDialogService.onResponse(true);
  }

  cancel() {
    this.confirmationDialogService.onResponse(false);
  }

  openCloseOverlayClick(event: any) {
    if (event.target.id == "mainBlackBg") {
      this.cancel();
    }
  }

  ngOnDestroy() {
    if (this.confirmationServiceSubscription) {
      this.confirmationServiceSubscription.unsubscribe();
    }
  }



}
