
//ENUM
export enum ParentMessageType {
    ShowDataLoading = "ShowDataLoading",
    ShowDataLoadingContinue = "ShowDataLoadingContinue",
    StartApplication = "StartApplication",
    RootMemberChanged = "RootMemberChanged",
    ErrorOccured = "ErrorOccured",
    ReportContentCopied = "ReportContentCopied",
    PublishReportClicked = "PublishReportClicked",
    ShowInformation = "ShowInformation",
    CustomErrorOccured = "CustomErrorOccured",
    ReloadPage = "ReloadPage",
    AuthenticationFailed = "AuthenticationFailed",
    RouteChange = "RouteChange",
    ApplicationStarted = "ApplicationStarted",
    ShowSearchField = "ShowSearchField",
    ShowDashboard = "ShowDashboard"
}

export class ParentMessage {
    public Type: ParentMessageType;
    public Data: object;
}