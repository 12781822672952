import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportManagerMainComponent } from './report-manager-main/report-manager-main.component';
import { MediaGalleryModule } from '../common/media-gallery/media-gallery.module';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';



@NgModule({
  declarations: [ReportManagerMainComponent],
  imports: [
    CommonModule,
    FormsModule,
    MediaGalleryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })    
  ],
  exports:[ ReportManagerMainComponent ]
})
export class ReportmanagerModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
