<!-- Links for stylesheets -->
<link rel="stylesheet" [attr.href]="reportURL + '/themes/common.css'| safe " />
<app-nodeOptionMenu [hidden]="hideToolTip" [commonUrl]="commonUrl" (rootMemberChange)="changeRootMember()"
    (openIndividualEditor)="openIndividualEditor()">
</app-nodeOptionMenu>

<svg id="svgViewableArea" class="report-common svg-full" [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute"
    [attr.width]="config.svg.w" [attr.height]="config.svg.h" *ngIf="showReport" style="cursor: context-menu">

    <g id="reportArea" [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'"
        [attr.transform]="reportTranslationObj.translateReportAttribute">
        <image  [attr.href]="reportURL + '/images/background.svg'" [attr.width]="config.svg.w" [attr.height]="config.svg.h"/>
        <!-- base frame -->
        <g [attr.transform]="chartTranslate">
            <g *ngFor="let element of chartboxes; let i=index">
                <g>
                    <!-- box image -->
                    <image *ngIf="element.box != null" [attr.x]="element.box.x" [attr.y]="element.box.y" [attr.href]="element.box.url"
                        [attr.opacity]="element.box.opacity" [attr.width]="element.box.w" [attr.height]="element.box.h">
                    </image>

                    <!-- box text -->
                    <text *ngIf="element.nameText != null" style="cursor: context-menu" [attr.class]="element.nameText.style"
                        [attr.transform]="element.nameText.transform"
                        [attr.opacity]="element.nameText.opacity">{{element.nameText.text}}</text>
                    
                    <text *ngIf="element.placeText != null" style="cursor: context-menu" [attr.class]="element.placeText.style"
                        [attr.transform]="element.placeText.transform"
                        [attr.opacity]="element.placeText.opacity">{{element.placeText.text}}</text>
                    
                    <text *ngIf="element.periodText != null" style="cursor: context-menu" [attr.class]="element.periodText.style"
                        [attr.transform]="element.periodText.transform"
                        [attr.opacity]="element.periodText.opacity">{{element.periodText.text}}</text>

                    <!-- Profile image section -->
                    <image class="profilePicture" style="cursor: context-menu;"  *ngIf="element.image != null"
                        [attr.x]="element.image.center.x - element.image.r"
                        [attr.y]="element.image.center.y - element.image.r" [attr.width]="element.image.r*2"
                        [attr.height]="element.image.r*2" [attr.href]="element.image.url" /> 
                </g>
            </g>
            <!-- connectors -->
            <g *ngFor="let connector of chartConnectors">
                <g *ngFor="let path of connector.getPaths()">
                    <path [attr.d]="path" stroke="black" fill="none"></path>
                </g>
            </g>
        </g>

        <!-- Report title -->
        <text *ngFor="let titleSegment of titleSegments" class="report-title" [attr.y]="titleSegment.y"
            [attr.x]="titleSegment.x" [attr.text-anchor]="reportTitleAnchor"
            [attr.alignment-baseline]="reportTitleAlignmentBaseline">
            {{ titleSegment.text }}
        </text>
    </g>
</svg>