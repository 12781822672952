
/* tslint:disable:member-ordering */

/*

To Apply the Directive,

1. In the element that renders svg,
apply the directive as follows
<g app-mainlabels appEllipsis [i]="i" [label]="label"></g>

2. Inside the svg, apply the class and attributes as follows (attribute values may change as needed)
<text class='ellipsis' ellipsisWidth=170 ellipsisLines=1>
            {{label.displayName}}</text>

*/
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { element } from 'protractor';
import { StringLimiter } from '../helpers/string-limiter';

@Directive({
  selector: '[appEllipsis]'
})
export class EllipsisDirective {

  constructor(private el: ElementRef, private stringLimiter: StringLimiter) {
  }

  ngOnChanges(): void{
    console.log('on change triggered');
  }

  ngOnInit() {
      setTimeout(() => {
          this.stringLimiter.update();
      }, 0)   
  }
}