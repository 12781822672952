import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndividualbasicinfoComponent } from './individualbasicinfo/individualbasicinfo.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';



@NgModule({
  exports: [IndividualbasicinfoComponent],
  declarations: [IndividualbasicinfoComponent],
  imports: [
    CommonModule,FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})
export class IndividualmanagementModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

