import { Component, OnInit } from '@angular/core';
import { TooltipContentBaseComponent } from '../tooltip-content-base/tooltip-content-base.component';

@Component({
  selector: 'app-tooltip-test-content',
  templateUrl: './tooltip-test-content.component.html',
  styleUrls: ['./tooltip-test-content.component.scss']
})
export class TooltipTestContentComponent  extends TooltipContentBaseComponent implements OnInit {

  ngOnInit(): void {
  }

}
