<div *ngIf="visibility" class="confirmation-popup" >
    <div class="main" id="mainBlackBg" (click)="openCloseOverlayClick($event)">
        <div class="consent-box box-size">
            <div class="title-type">
                {{confirmationHeader | translate}}
            </div>
            <div class="error-box box-size name-input">
                {{confirmationBody}}
            </div>
            <div class="open-close-buttons box-size">
                <span>{{confirmationFooter | translate}}</span>
                <img class="btn-close element-over-hand" src="assets/input-form/{{closeButton}}" (click)="cancel()"/>
                <img class="btn-delete element-over-hand" src="assets/input-form/{{okayButton}}"  (click)="confirm()"/>                
               
            </div>
        </div>
    </div>
</div>
