import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotifierEvents } from '../common/enums/enums';
import { CustomError } from '../common/helpers/custom-error';
import { GuidGenerator } from '../common/helpers/guid-generator';
import { FamilyProjectBaseModel } from '../common/models/familyproject/family-project-base-model';
import { UserModel } from '../common/models/UserModel';
import { FamilyProjectApiService } from './API/family-project-api.service';
import { IndividualApiService } from './API/individual-api.service';
import { AuthenticationService } from './authentication.service';
import { HistoryService } from './history.service';
import { NotifierV2Service } from './notifier-v2.service';
import { PreferencesService } from './preferences.service';
import { LoadingService } from './UI/loading.service';

@Injectable()

// Project references will be maintained here/ project list, current project & project token for intercept headers
export class ProjectRefService {
    projectList         : FamilyProjectBaseModel[] = [];
    defaultProject      : FamilyProjectBaseModel;
    currentProjectToken : string = null;
    appDefaultCulture   : string;
    
    constructor(private authenticationService   : AuthenticationService, 
                private familyProjectService    : FamilyProjectApiService,
                private preferenceService       : PreferencesService,
                private individualService       : IndividualApiService,
                private notifierService         : NotifierV2Service,
                private loadingService          : LoadingService,
                private historyService          : HistoryService) {
        
        this.appDefaultCulture = environment.DEFAULT_CULTURE;
    }

    // Load/Reload project list
    async load(){
        this.historyService.clear()
        return this.familyProjectService.getFamilyProjects<any>().toPromise().then( async (projectData) =>{       
                //Object.assign(this.projectList, projectData.data);
                this.projectList = <FamilyProjectBaseModel[]>projectData.data;
                if ( this.projectList == null || this.projectList.length == 0 ){
                    // Route to project create
                    return;
                }               
                this.defaultProject = this.projectList.find( p=> p.isDefault );
                // if no default set the first project as default
                if ( !this.defaultProject ||  this.defaultProject == null ){
                    await this.setCurrentProject("");
                }else{
                    await this.setCurrentProject(this.defaultProject.familyProjectId);
                }                
            }            
        );
    }
    public async setCurrentProject(id){
        this.historyService.clear() // 2022.02.03 added due to fix a breadcrumb issue when project changes
        let processId = GuidGenerator.generate();
        try {            
            this.loadingService.show(processId);

            this.defaultProject = this.projectList.find( p=> p.familyProjectId == id );
            // if no default set the first project as default
            if ( !this.defaultProject ||  this.defaultProject == null ){
                this.defaultProject = this.projectList[0];
            } 
            this.setCurrentProjectToken(); 
            
            // set preferences
            this.preferenceService.setPreferences(this.defaultProject?.projectPreferences);
            this.preferenceService.setCulture(this.appDefaultCulture);
    
            if ( !this.defaultProject?.isValid) return;
            /** --------------------------------- Load the root person ------------------------------------ */
            // Get the first person in the project to make as root person    
            var rootPersonData = await this.individualService.getFirstPerson<any>().toPromise().catch( ()=>{
            
            });
              
            // Update root member changed
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, {
                Id          : rootPersonData.data.id,
                DisplayName : rootPersonData.data.displayName,
                FirstName   : rootPersonData.data.firstName,
                LastName    : rootPersonData.data.lastName
            });    
        } catch (error) {
            throw new CustomError("projectRefServices => setCurrentProject() : " + error, 911, false);
        }finally{
            this.loadingService.hide(processId);
        }        
    }

    private setCurrentProjectToken(){
        if ( !this.defaultProject || this.defaultProject == null ){
            return null;
        }
        
        let onlineId = "";        
        let user:UserModel = this.authenticationService.getUser();
        if ( user ){
            onlineId = user.OnlineId;
        }
        let currentProjectJson = {    'ProjectId' : this.defaultProject.familyProjectId,
                                      'OnlineId' : onlineId
                                 }
        this.currentProjectToken = btoa( encodeURIComponent(JSON.stringify(currentProjectJson) ) );
    }
    
    public getProjectList():FamilyProjectBaseModel[]{
        return this.projectList;
    }

    public getCurrentProject(): FamilyProjectBaseModel {
        return this.defaultProject;
    }

    public isCurrentProjectInvalid1(): boolean {
        //return true;
        return !(this.defaultProject && this.defaultProject.isValid);
    }

    public isCurrentProjectEditable(): boolean {
        //return true;
        return (this.defaultProject && this.defaultProject.isValid && !this.defaultProject.isImporting );
    }
    public getCurrentProjectToken() : string {
       return this.currentProjectToken;
    }
}