<!-- Links for stylesheets -->
<link rel="stylesheet" [attr.href]="reportURL + '/themes/common.css'| safe " />
<app-nodeOptionMenu [hidden]="hideToolTip" [commonUrl]="commonUrl" 
    (rootMemberChange)="changeRootMember()"
    (openIndividualEditor)="openIndividualEditor()">
</app-nodeOptionMenu>

<svg id="svgViewableArea" class="reportCommon svgfull" [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute"
    [attr.width]="config.svg.w" [attr.height]="config.svg.h" *ngIf="showReport" style="cursor: context-menu;">
    <!--
<image *ngIf="isDebug" [attr.href]="reportURL + '/images/frame.jpg' " opacity="0.1"></image>
-->
    <!-- grid -->

    <g id="reportArea" [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'"
        [attr.transform]="reportTranslationObj.translateReportAttribute">
        <!-- TODO: Check and remove grid -->
        <g *ngIf="isGrid">
            <line *ngFor="let y of [].constructor(gridXCount); let i=index" x1="0" [attr.y1]="10*i"
                [attr.x2]="config.svg.w" [attr.y2]="10*i"/>
            <line *ngFor="let x of [].constructor(gridYCount); let i=index" [attr.x1]="10*i" [attr.y1]="0"
                [attr.x2]="10*i" [attr.y2]="config.svg.h"/>
            <!-- <line x1="0" [attr.y1]="config.svg.h/2" [attr.x2]="config.svg.w" [attr.y2]="config.svg.h/2"
                style="stroke:#c3c3c3;stroke-width:1" /> -->
            <!-- <line [attr.x1]="config.svg.w/2" [attr.y1]="0" [attr.x2]="config.svg.w/2" [attr.y2]="config.svg.h"
                style="stroke:#c3c3c3;stroke-width:1" /> -->
        </g>

        <g [attr.transform]="'translate('+config.bgImage.x+' '+config.bgImage.y+')'">
            <!-- backgroud image -->
            <image [attr.href]="reportURL + '/images/bg.jpg'" opacity="0.5"></image>
        
            <!-- Report title -->
            <text *ngFor="let titleSegment of titleSegments" class="reportTitle" [attr.y]="titleSegment.y" [attr.x]="titleSegment.x" [attr.text-anchor]="reportTitleAnchor" [attr.alignment-baseline] = "reportTitleAlignmentBaseline">
                {{ titleSegment.text }}
            </text>
        </g>

        <!-- base frame -->
        <g [attr.transform]="'translate('+config.svg.moveX+' '+config.svg.moveY +')'">
            <!-- debug frame -->
            <g *ngIf="isDebug">
                <line *ngFor="let line of chartConnectorBase; let i=index" [attr.x1]="line.x1" [attr.y1]="line.y1"
                    [attr.x2]="line.x2" [attr.y2]="line.y2" style="stroke:lightblue;stroke-width:1" />
                <rect *ngFor="let data of chartData; let i=index" [attr.x]="data.x - data.w/2"
                    [attr.y]="data.y - data.h/2" [attr.width]="data.w" [attr.height]="data.h" fill="none"
                    style="stroke:lightslategray;stroke-width:1"></rect>
                <circle *ngFor="let data of chartData; let i=index" [attr.cx]="data.x" [attr.cy]="data.y" [attr.r]="2"
                    fill="green" />
            </g>

            <!-- chart graphics -->
            <g *ngFor="let element of chartboxes; let i=index">
                <!-- connectors -->
                <image *ngIf="element.connector != null" 
                    [attr.opacity]="element.connector.opacity"
                    [attr.href]="reportURL + '/images/connector'+element.connector.level+'.png'"
                    [attr.transform]="element.connector.transform"
                    [attr.class]="element.id > 999999 ? ' ignoreReport' : '' "/>
                <!-- box -->
                <g >
                    <!-- box image -->
                    <image *ngIf="element.box != null" 
                        [attr.x]="element.box.x" [attr.y]="element.box.y"
                        [attr.href]="element.box.url" 
                        [attr.opacity]="element.box.opacity"
                        [attr.class]="element.id > 999999 ? ' ignoreReport' : '' ">
                    </image>

                    <!-- text -->
                    <text *ngIf="element.text1 != null" style="cursor: pointer;" 
                        (click)="clickNode($event.pageX, $event.pageY, element.id, element.parentId)" 
                        [attr.class]="element.id > 999999 ?  element.text1.style + ' ignoreReport' : element.text1.style"
                        [attr.transform]="element.text1.transform"
                        [attr.opacity]="element.text1.opacity">{{element.text1.text}}</text>

                    <text *ngIf="element.text2 != null" style="cursor: pointer;" 
                        (click)="clickNode($event.pageX, $event.pageY, element.id, element.parentId)" 
                        [attr.class]="element.id > 999999 ? element.text2.style + ' ignoreReport' : element.text2.style"
                        [attr.transform]="element.text2.transform"
                        [attr.opacity]="element.text2.opacity">{{element.text2.text}}</text>

                    <text *ngIf="element.text3 != null" style="cursor: pointer;" 
                        (click)="clickNode($event.pageX,$event.pageY,element.id, element.parentId)" 
                        [attr.class]="element.id > 999999 ? element.text3.style + ' ignoreReport' : element.text3.style"                 
                        [attr.transform]="element.text3.transform"
                        [attr.opacity]="element.text3.opacity">{{element.text3.text}}</text>

                    <!-- function image -->
                    <image *ngIf="element.fnImage != null" class="ignoreReport clickable"  
                        (click)="clickNode($event.pageX,$event.pageY,element.id, element.parentId)" 
                        [attr.x]="element.fnImage.x" [attr.y]="element.fnImage.y"
                        [attr.width]="element.fnImage.w" [attr.height]="element.fnImage.h"
                        [attr.href]="element.fnImage.url" [attr.opacity]="element.fnImage.opacity"></image>

                    <!-- Profile image section -->
                    <clipPath *ngIf="element.image != null" [attr.id]="'imageclip_'+i">
                        <circle [attr.cx]="element.image.center.x" [attr.cy]="element.image.center.y"
                            [attr.r]="element.image.r" stroke="grey" stroke-width="1" fill="none" />
                    </clipPath>
                    <image class="profilePicture" style="cursor: context-menu;" (click)="openMultiMedia(element.id)"  *ngIf="element.image != null"
                        [attr.clip-path]="'url(#imageclip_'+i+')'" [attr.x]="element.image.center.x - element.image.r"
                        [attr.y]="element.image.center.y - element.image.r" [attr.width]="element.image.r*2"
                        [attr.height]="element.image.r*2" [attr.href]="element.image.url" />
                </g>
            </g>
        </g>
    </g>


</svg>