<!-- Family editor/member editor -->
<!-- https://codepen.io/lookininward/pen/zYOQjZM -->
<div class="ft-form" [class.form-inactive] = "disableForm">
    <div class="content" (scroll)="onContainerScroll($event)">       
      <form class="form" id="familyViewModel" #f="ngForm">
        <fieldset [disabled]="disableForm">
        <!-- Profile pic and parents -->
        <div class="ft-form-row col2-vf">
            <div class="border-outline">
                <!-- Family images carousel -->
                <app-image-carousel-temp 
                    [defaultImage] = "defaultCarouselImage" 
                    [inImages] = "getInImages()"
                    (action)="carouselActionClicked($event)">
                </app-image-carousel-temp>
            </div>

            <!-- Patents layout -->
            <div class="center" style="width: 100%;">
                <!-- Display family parents -->
                <div class="family-parent-warp">
                    <!-- No father -->
                    <div class="family-no-parents" *ngIf="familyViewModel.husband == null">
                        <label class="ft-img-label" (click)="addFather()" style="cursor: pointer;">
                            <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" class="ft-btn-round-green-base" width="22" height="22" >
                            {{"lbl_add_father" | translate}} 
                        </label>
                    </div>
                    
                    <div class="family-individual-wrap" [class.card-inactive] = "disableForm" *ngIf="familyViewModel.husband != null" [ngClass]="{'family-parent' : familyViewModel.wife == null}">
                        <div>
                            <img [src]="familyViewModel.husband.profileImageUrl" (error)='onItemError($event)' class="profile_image_small">
                        </div>
                        <div>
                            <div class="label-grey-base-normal" style="margin-bottom: -3px"> {{"lbl_father" | translate}}</div>
                            <div class="label-grey-base text-ellipsis-1" style="min-width: 150px;" title="{{familyViewModel.husband.displayName}}" >{{familyViewModel.husband.displayName}}</div>
                        </div>
                        <div class="family-individual-menu-wrap">
                            <app-drop-menu [menu]="getIndividualDropMenu(familyViewModel.husband)" (action)="parentMenuClicked($event, relationShipType.Father)"></app-drop-menu>
                        </div>
                    </div>                
                    <div class="center btn-switch">
                        <img src="assets/images/icons/family-white.svg" [class.btn-inactive] = "disableForm" class="ft-btn-round-green-base">
                    </div>
                     <!-- No Mother -->
                     <div class="family-no-parents" *ngIf="familyViewModel.wife == null">
                        <label class="ft-img-label" (click)="addMother()" style="cursor: pointer;">
                            <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" class="ft-btn-round-green-base" width="22" height="22">
                            {{"lbl_add_mother" | translate}}
                        </label>                        
                    </div>              
                  
                    <div class="family-individual-wrap" [class.card-inactive] = "disableForm" *ngIf="familyViewModel.wife != null" [ngClass]="{'family-parent' : familyViewModel.husband == null}">
                        <div>
                            <img [src]="familyViewModel.wife.profileImageUrl" (error)='onItemError($event)' class="profile_image_small">
                        </div>
                        <div>
                            <div class="label-grey-base-normal" style="margin-bottom: -3px"> {{"lbl_mother" | translate}}</div>
                            <div class="label-grey-base text-ellipsis-1" style="min-width: 150px;" title="{{familyViewModel.wife.displayName}}">{{familyViewModel.wife.displayName}}</div>
                        </div>
                        <div class="family-individual-menu-wrap">
                            <app-drop-menu [menu]="getIndividualDropMenu(familyViewModel.wife)" (action)="parentMenuClicked($event, relationShipType.Mother)"></app-drop-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- marriage date and place -->
        <div class="ft-form-row col2-vf">
            <div class="ft-form-input">
                <label> {{"lbl_marriage_date" | translate}}</label>                
                <app-date-input-temp [inDate]="familyViewModel?.marriageEvent?.date" (outDate)="setFormatedDate($event)" (keydown)="isDirty=true" [scrollUpdate]="isScrolled"></app-date-input-temp>
            </div>
            <!-- Place component -->
            <div class="ft-form-input">
                <label>{{"lbl_place" | translate}}</label>
                <app-place-input [inPlace] ="familyViewModel?.marriageEvent?.place" [isFieldEnabled]="disableForm" (placeChange)=setPlaceForEvent($event) (keydown)="isDirty=true" [title] = "getPlacePopupTitle()"></app-place-input>
            </div>
        </div>

        <div class="ft-form-row col1-v100" style="margin-top: -5px;margin-bottom: 12px;">
            <span class="label-grey-base">{{marriageAgeText}} </span>
        </div>

        <!-- Add children -->
        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label class="ft-img-label">
                    <img (click)="addChild()" src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" class="ft-btn-round-green-base" width="22" height="22" style="cursor: pointer;"> {{"lbl_add_children" | translate}}
                </label>
                <div class="family-children-outline">
                    <!-- TODO -->
                    <!-- No children box - show/hide -->
                    <div class="no-record" *ngIf="familyViewModel?.children?.length == 0 || familyViewModel?.children == null" > {{"lbl_no_children" | translate}}</div>
                    <!-- List of children -->
                    <ul class="scrollable" [ngStyle]="{'grid-template-rows': getGridTemplateRows()}" *ngIf="familyViewModel?.children?.length > 0">
                        <!-- Child -->
                        <li class="family-individual-wrap" [class.card-inactive] = "disableForm" *ngFor="let child of familyViewModel.children">
                            <div>
                                <img [src]="child.profileImageUrl" (error)='onItemError($event)' class="profile_image_small">
                            </div>
                            <div>
                                <div class="label-m-grey text-ellipsis-1" title="{{child?.displayName}}" style="margin-bottom: -3px">
                                    {{child?.displayName}}
                                </div>                         
                                <div class="label-s-grey" style="margin-bottom: -3px" *ngIf="child?.birthEvent != null">
                                    {{child?.birthEvent?.date?.genealogyDate?.originalStr}}
                                </div>
                                <div class="label-xs-grey" *ngIf="child?.relationShipType != null">
                                    {{'enum_' + child?.relationShipType | lowercase | translate}}
                                </div>
                            </div>
                            <div class="family-individual-menu-wrap">
                                <app-drop-menu [menu]="getIndividualDropMenu(child)" (action)="childMenuClicked($event)"></app-drop-menu>
                            </div>
                        </li>                      
                    </ul>
                </div>
            </div>
        </div>

        <!-- Note -->
        <div class="ft-form-row col1-v100">
            <div>
                <div class="ft-form-input">
                    <label>{{"lbl_note" | translate}}</label>                    
                    <textarea type="textarea" style="height: 75px;"  [(ngModel)]="familyViewModel.note" name="note" #note="ngModel"></textarea>
                </div>
            </div>           
        </div>

        <!-- Address -->
        <div class="ft-form-row col2-v50v50">
            <div>
              <div class="ft-form-input">
                <label>{{"lbl_address" | translate}}</label>
                <input type="text" [(ngModel)]="familyViewModel.address"   name="address" #address="ngModel">
              </div>
            </div>
            <div>
                <div class="ft-form-input">
                    <label>{{"lbl_phone" | translate}}</label>
                    <input type="text" [(ngModel)]="familyViewModel.phone" name="phone" #phone="ngModel">
                </div>
            </div>
        </div>

        <!-- flags -->
        <div class="ft-form-row col2-v50v50">
            <div>
                <div class="ft-form-input">
                    <label>{{"lbl_email" | translate}}</label>
                    <input type="text" [(ngModel)]="familyViewModel.email" name="email" #email="ngModel">
                </div>                
            </div>
            <div>
                <div class="ft-form-input">
                    <label>{{"lbl_flags" | translate}}</label>
                    <div class="family-flag-wrap">
                        <!-- label with input -->
                        <label class="input-checked-container input-checkbox-large">{{"lbl_search_results" | translate}}
                            <input type="checkbox" [(ngModel)]="familyViewModel.searchResults" [ngModelOptions]="{standalone: true}" [checked]="familyViewModel.searchResults">
                            <span class="checkmark"></span>
                        </label>
                        <!-- label with input -->
                        <label class="input-checked-container input-checkbox-large">{{"lbl_calendar" | translate}}
                            <input type="checkbox"  [(ngModel)]="familyViewModel.calendar"  [ngModelOptions]="{standalone: true}" [checked]="familyViewModel.calendar">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add other media -->
        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label class="ft-img-label">
                    <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" class="ft-btn-round-green-base" width="22" height="22" (click)="addMedia(0,1)">
                    {{"lbl_add_other_media" | translate}}
                    <img class="media-arrow"  [ngClass] = "isMediaCarouselOpen? 'media-arrow-up': ''" (click)="toggleMediaCarousel()">
                </label>
                <!-- Todo: Keep for future reference, currently not in use -->
                <!-- <app-media-carousel [hidden]="isMediaCarouselOpen" [inMedia] = "getInMedia()"  [menuItems]="getMultimediaDropMenu()" (action)="mediaCarouselActionClicked($event)"></app-media-carousel> -->
                <app-media-carousel [hidden]="!isMediaCarouselOpen" [items] = "mediaItems"  (action)="mediaCarouselActionClicked($event)"></app-media-carousel>
            </div>
        </div>
    </fieldset>
        <!-- Add other media -->
    </form>
    </div>
    <div *ngIf="familyViewModel" class="footer">
        <button class="ft-btn " (click)="saveFamily()" [disabled]="defaultView || !isDirty"  [ngClass]="{'ft-btn-active':!disableForm,'ft-btn-inactive':disableForm  || !isDirty}" [ngClass]="{'ft-btn-inactive' : defaultView, 'ft-btn-active' : !defaultView}">{{"lbl_save" | translate}}</button>
        <button class="ft-btn " (click)="cancel()" [ngClass]="{'ft-btn-active-not-selected':!disableForm,'ft-btn-inactive':disableForm}" [ngClass]="{'ft-btn-inactive' : defaultView, 'ft-btn-active-not-selected' : !defaultView}">{{"lbl_cancel" | translate}}</button>
        <!-- <button class="ft-btn"(click)="navigateToReportEngine()" >Navigate</button> -->
    </div>
</div>