import { AliveStatus } from "src/app/common/enums/enums";

export class IndividualBase {
    id: number;
    prefix: string;
    displayName : string;
    givenName: string;
    middleName: string;
    surname: string;
    patronomical: string;
    suffix: string;
    biographical: string;
    gender: string;
    isAlive: boolean;
    aliveStatus: AliveStatus;
    address: string;
    email: string;
    phone: string;
    medical: string;
    isBiographicalSupression: string;
    isMedicalSupression: string;
    isSearchSupression: string;
    isCalenderSupression: string;
}