<div style="position: relative;">
    <app-drop-menu [menu]="dropMenu" (action)="clickedAction($event)"></app-drop-menu>
    <!-- arrow -->
    <div style="position: absolute; top: 50%;height: 12px; right: 0;width: 100%; display:flex;flex-direction: row;justify-content: space-between;padding: 0px 4px 0px 4px">
        <div style="margin-top: -6px;cursor: pointer;" (click)="left()" >
            <img src="/assets/images/left_arrow.png" width="11" height="18" style="display: block;">
        </div>
        <div style="margin-top: -6px;cursor: pointer;" (click)="right()">
            <img src="/assets/images/right_arrow.png" width="11" height="18" style="display: block;">
        </div>
    </div>
    <img (error)='onItemError($event)' [src]="getCurrentImage()" width="125" height="125">
</div>
