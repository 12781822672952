import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MapReportConfigModel } from "src/app/common/models/report/mapreport/map-report-config-model";
import { CustomMapThemeExe } from "src/app/common/models/report/mapreport/mapThemeExe/custom-map-theme-exe";
import { StreetThemeExe } from "src/app/common/models/report/mapreport/mapThemeExe/street-theme-exe";
import { VintageThemeExe } from "src/app/common/models/report/mapreport/mapThemeExe/vintage-theme-exe";
@Injectable({
  providedIn: "root",
})
/**
 * This factory class is creates a MapTheme object based on the selected theme type. 
   It uses a MapReportConfigModel object to retrieve the available themes.
**/
export class MapThemeFactory {
  private config: MapReportConfigModel;

  constructor(private http: HttpClient, config: MapReportConfigModel) {
    this.config = config;
  }

  /** 
   * containing the MapTheme object and the loaded theme data. It uses the themeId to retrieve the selected theme 
   from the MapReportConfigModel object, creates a CustomMapThemeExe object based on the selected theme type,
   and loads the theme properties from the specified config file.
   * */
  getTheme(themeId: number): Observable<{ themeData: any }> {
    // Retrieve the theme object based on the themeId
    const selectedTheme = this.config.mapThemeConfig.mapThemeList.find(
      (item) => item.id === themeId
    );

    let themeExe: CustomMapThemeExe;

    switch (selectedTheme.name) {
      case "street":
        themeExe = new StreetThemeExe();
        break;
      case "vintage":
        themeExe = new VintageThemeExe();
        break;
      default:
        break;
    }

    return this.http.get(selectedTheme.config).pipe(
      map((themeData) => {
        return { themeData, themeExe };
      })
    );
  }
}
