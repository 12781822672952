import { Injectable } from '@angular/core';

export interface ApiRequest {
    OnlineId: string;
    FileName: string;
    RootMemberId: number;
    ProjectId: string;
    [key: string]: any
}

export class CustomApiParams {
    public Type: string;
    public Data: any;
}
