
export class GeneologyDatePatterns {
    static months: any[];
    static longMonths: any[];
    static month_no: any[];
    static prefix: string[];

    private static _initialize = (() => {

        GeneologyDatePatterns.months = [];
        GeneologyDatePatterns.months["en"] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        GeneologyDatePatterns.months["no"] = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];

        GeneologyDatePatterns.longMonths = [];
        GeneologyDatePatterns.longMonths["en"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        GeneologyDatePatterns.longMonths["no"] = ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"];
        GeneologyDatePatterns.month_no = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        GeneologyDatePatterns.prefix = [];
        GeneologyDatePatterns.prefix["en"] = [
            { "type": "approximately", "text": "approximately", "value": "approx" },
            { "type": "approximately", "text": "approx", "value": "approx" },
            { "type": "approximately", "text": "omtrent", "value": "approx" },

            { "type": "assumed", "text": "assumed", "value": "assume" },
            { "type": "assumed", "text": "assume", "value": "assume" },

            { "type": "after", "text": "after", "value": "aft" },
            { "type": "after", "text": "aft", "value": "aft" },
            { "type": "after", "text": "etter", "value": "aft" },

            { "type": "before", "text": "before", "value": "bef" },
            { "type": "before", "text": "bef", "value": "bef" },
            { "type": "before", "text": "før", "value": "bef" },

            { "type": "about", "text": "about", "value": "abt" },
            { "type": "about", "text": "abt", "value": "abt" },
            { "type": "about", "text": "omtrent", "value": "abt" },

            { "type": "c", "text": "c", "value": "c" },
            { "type": "ca", "text": "ca", "value": "ca" },
            { "type": "ca", "text": "cirka", "value": "ca" },
            { "type": "cal", "text": "cal", "value": "cal" },
            { "type": "cal", "text": "kal", "value": "cal" },

            { "type": "q1", "text": "q1", "value": "q1" },
            { "type": "q1", "text": "1q", "value": "q1" },
            { "type": "q1", "text": "q2", "value": "q2" },
            { "type": "q1", "text": "2q", "value": "q2" },
            { "type": "q1", "text": "q3", "value": "q3" },
            { "type": "q1", "text": "3q", "value": "q3" },
            { "type": "q1", "text": "q4", "value": "q4" },
            { "type": "q1", "text": "4q", "value": "q4" },

            { "type": "from", "text": "from", "value": "from,to" },
            { "type": "from", "text": "fra", "value": "from,to" },
            { "type": "between", "text": "between", "value": "bet,and" },
            { "type": "between", "text": "bet", "value": "bet,and" },
            { "type": "between", "text": "mellom", "value": "bet,and" }
        ];

        GeneologyDatePatterns.prefix["no"] = [
            { "type": "approximately", "text": "omtrent", "value": "omtrent" },
            { "type": "about", "text": "omtrent", "value": "omtrent" },
            { "type": "after", "text": "etter", "value": "etter" },
            { "type": "before", "text": "før", "value": "før" },

            { "type": "c", "text": "c", "value": "c" },
            { "type": "ca", "text": "ca", "value": "ca" },
            { "type": "ca", "text": "cirka", "value": "ca" },
            { "type": "cal", "text": "kal", "value": "kal" },

            { "type": "q1", "text": "q1", "value": "q1" },
            { "type": "q1", "text": "1q", "value": "q1" },
            { "type": "q1", "text": "q2", "value": "q2" },
            { "type": "q1", "text": "2q", "value": "q2" },
            { "type": "q1", "text": "q3", "value": "q3" },
            { "type": "q1", "text": "3q", "value": "q3" },
            { "type": "q1", "text": "q4", "value": "q4" },
            { "type": "q1", "text": "4q", "value": "q4" },

            { "type": "from", "text": "fra", "value": "fra,til" },
            { "type": "between", "text": "mellom", "value": "mellom,og" }
        ];
    })();

}