import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction, DropMenuType, PopupViewMode } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { MultimediaBase } from 'src/app/common/models/multimedia/multimedia-base';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { SourceBase } from 'src/app/common/models/source/source-base';
import { SourceAPIService } from 'src/app/services/API/source-api.service';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';
import { MediaGalleryItem } from '../../media-gallery/models/media-gallery-item';
import { EventSourceViewModel } from '../models/eventSourceViewModel';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  
  //Decorator Declarations 
  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();

  responseData : {sourceCitationInfo : SourceCitationViewModel , deleteSourcesIds : number[] };  

  //Variable Declarations 
  searchPhrase : string ="";
  selectedMediaTitle: string;
  title: string;
  isDeleted : boolean;
  mediaItems : MediaGalleryItem[] = [];  
  sources: SourceBase[];
  isStandAlone: boolean;  
  
  constructor(
    private dialogService:DialogService,
    private loadingService: LoadingService,
    private sourceApiService: SourceAPIService,
    private translateHandler: TranslateHandler,   
    private messageDialogService: MessageDialogService) { 
  }

  ngOnInit(): void {
    this.responseData = new Object() as any;
    this.responseData.deleteSourcesIds = new Array<number>(); 
    const itemMenu = new DropMenu();
    itemMenu.type = DropMenuType.Inverted;
    itemMenu.menuItems = [ { action:DropMenuAction.Edit, isEnabled:true,isVisible:true },{ action:DropMenuAction.Delete, isEnabled:true,isVisible:true }  ]
    var cloneDropMenu = { ...itemMenu };
    this.title = this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_source").toLowerCase(), this.data.titleText]);
    this.getSourceList();
    this.isStandAlone = this.data.isStandAlone;
  }

  getSourceList(){
    // Todo: Call getSourceList API()
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    
    this.sourceApiService.getSourceList(1, 1).subscribe((response: ResponseModel<SourceBase[]>) => {
      this.sources = response.data;
      this.mediaItems = this.sources.map(i => ({ id: 0, reference: i.id, caption: i.title, url: i?.mediaUrl ?? "/assets/images/icons/source.svg",
       isSelected: (i.id == this.data.id),isEditEnable: (i.id == this.data.id), dropMenu: this.createDropMenu(i) }));
      //Todo: Remove if item is not selected when opened
      this.mediaItems.sort(this.compareSelected);
    }, (err) => {
      throw new CustomError("SourcesComponent => getSourceList() : " + err, 911, false);
    }).add(()=>{     
      this.loadingService.hide(processId);
    });

  }

  //Todo: Remove if item is not selected when opened
  compareSelected(a: MediaGalleryItem, b: MediaGalleryItem) {
    if (a.isSelected == true) {
      return -1;
    }
    if (b.isSelected == true) {
      return 1;
    }
    return 0;
  }

  createDropMenu(ref: SourceBase) {
    let dropMenu = new DropMenu();
    dropMenu.type = 1;
    dropMenu.ref = ref;
    dropMenu.menuItems = [<DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Delete, isEnabled: true, isVisible: true }
    ];
    return dropMenu;
  }

  add(){    
    //this.onCompleted.next({});
    //this.onCompleted.unsubscribe();
    this.addSourceCitation();
  }

  addSourceCitation(){    
    let selectedSource= this.mediaItems.find(s => s.isSelected === true);
    let source = this.sources?.find(p => p.id === selectedSource.reference);
    this.responseData.sourceCitationInfo = new SourceCitationViewModel();
    this.responseData.sourceCitationInfo.sourceBase = source;
    
    if(source)
    this.dialogService.open("SourceCitation",{
      // Todo set request properties
      id: 0,
      responseData: this.responseData,
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_citation").toLowerCase(), this.data.titleText]) // Todo : Need to translate and set title
    }).subscribe( response => {  
      if(response.data){     
         response.data.eventId = this.data.reference.eventId;           
         this.onCompleted.next({data : response.data});        
      }  
    })
  }

  dropMenuAction(event: any) {  
    if(event.action === DropMenuAction.Delete){
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      this.sourceApiService.getReferenceCountToSource(event.reference.id).subscribe((response: ResponseModel<number>) => {
        var message = this.translateHandler.translate("cnf_delete_source_confirmation_message") + this.translateHandler.translate("lnk_references",[response.data.toString()])
        this.messageDialogService.openDeleteConfirmation(message).subscribe((dialogRes: boolean) => {
          if(dialogRes){
            this.deleteSource(event.reference.id);
          }
        })
      },(err => {
        throw new CustomError("SourcesComponent => getReferenceCountToSource() :" + err, 911, false);
      })).add(() => {
        this.loadingService.hide(processId);
      });
    }
    if (event.action == DropMenuAction.Edit) {
      // Do the edit functionality
      this.data.editSourceId = event.reference.id;
      this.data.viewMode = PopupViewMode.EditorView;
    }
  }

  deleteSource(sourceId: number){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.sourceApiService.deleteSource(sourceId).subscribe((response: ResponseModel<boolean>)=> {
      if(response.data){
        let deletedSourceObj = this.mediaItems.find(s=>s.reference == sourceId);
        this.mediaItems.splice(this.mediaItems.indexOf(deletedSourceObj), 1);
        this.responseData.deleteSourcesIds.push(sourceId);  
        this.data.response = this.responseData;
      }
    }, (err) => {
      throw new CustomError("SourcesComponent => DeleteSource() : "+ err, 911,false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  setInability() {
    let isEnabled = this.mediaItems?.find(s => s.isSelected == true)?.isSelected ?? false;
    this.selectedMediaTitle = this.mediaItems?.find(s => s.isSelected === true)?.caption;
    return isEnabled;
  }

  cancel(){
    this.onCompleted.next({ data: this.responseData});   
    this.onCompleted.unsubscribe();
  }
}
