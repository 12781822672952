import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { CropFrameMode, MultimediaPopupOpenMode, MultimediaTypes, PopupResponseEvents, ResourceType } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { IndividualBaseViewModel } from 'src/app/common/models/IndividualViewModel';
import { LinkRequest } from 'src/app/common/models/LinkRequest';
import { MultimediaPopupInputInfo } from 'src/app/common/models/PopupInputInfo';
import { MultimediaPopupResponse } from 'src/app/common/models/PopupResponse';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { MultimediamanagementService } from '../common/multimedia/services/multimediamanagement.service';
import { TreeEditorApiService } from '../treeeditor/services/treeeditorapi.service';
import { ParentCommunicatorService } from '../../services/parent-communicator.service';
import { ParentMessageType } from '../../common/models/ParentMessage';

@Component({
  selector: 'app-multimedialist',
  templateUrl: './multimedialist.component.html',
  styleUrls: ['./multimedialist.component.scss']
})
export class MultimedialistComponent implements OnInit {
  @Output() refreshParent = new EventEmitter();
  multimediaSubscription: any;
  timeTag: string = "";
  label: IndividualBaseViewModel = new IndividualBaseViewModel;
  subscribeInitialPageLoad: any;

  constructor(
    private multimediamanagementService: MultimediamanagementService,
    private loadingService: LoadingService,
    private treeEditorApiService: TreeEditorApiService,
    private parentCommunicatorService: ParentCommunicatorService
  ) { }

  ngOnInit(): void {
    this.subscribeInitialPageLoad = this.parentCommunicatorService.receive(
      ParentMessageType.RootMemberChanged,
      (pageloadData) => {
        this.openMultiMedia(this.label);
      }
    );
  }

  openMultiMedia(label: IndividualBaseViewModel) {
    let popupInputData = new MultimediaPopupInputInfo();
    popupInputData.level = 0;
    popupInputData.Title = "";
    popupInputData.Mode = MultimediaPopupOpenMode.List;
    popupInputData.FilterMode = [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO];
    popupInputData.CropFrameMode = CropFrameMode.Circle;
    popupInputData.SelectedImageIds = popupInputData.SetImageIds(label.profileImageId);
    popupInputData.AcceptedMultimediaTypes = [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO];
    popupInputData.PopUpTitle = "lbl_all_media";
    popupInputData.IsFullScreenPopUp = true;

    // Opens the multimedia panels and selection of the media or cancel steps
    this.multimediaSubscription = this.multimediamanagementService.openPopup(popupInputData)
      .pipe(first())
      .subscribe((selectedImages: MultimediaPopupResponse) => {

        if (selectedImages.Status == PopupResponseEvents.Error) {
          throw new CustomError("Image url is invalid", 611, false);
        }
        //Update user profile image if new imaege "selected"
        if ((selectedImages.Status == PopupResponseEvents.Selected ||
          selectedImages.Status == PopupResponseEvents.DeSelected) && selectedImages.Data) {
          if (selectedImages.Status == PopupResponseEvents.DeSelected) {
            selectedImages.Data[0].mediaUrl = null;
            selectedImages.Data[0].id = null;
          }
          let linkRequest = new LinkRequest;
          linkRequest.TargetId = selectedImages.Status === PopupResponseEvents.Selected ? selectedImages.Data[0].id : null;

          //let processId = GuidGenerator.generate();
          //this.loadingService.show(processId);
        }
        else {
          if (selectedImages.Status === PopupResponseEvents.Cancelled && selectedImages.reload) {
            this.timeTag = "&timeTag=" + new Date().getTime(); // used to avoid caching
            this.refreshParent.emit(selectedImages.reload);
          }
        }
      },
        (err) => {
          throw new CustomError("MainlabelsComponent => OpenMemberEditor() : " + err, 911, false);
        }
      )
  }

}
