import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { RootMember } from 'src/app/common/models/RootMember';

@Injectable()
export class TreeEditorService {
  treeEditorStatus: Subject<boolean> = new Subject();
  subscriptionRootMemberId: any;
  rootMemberId: number;
  rootMember: Subject<RootMember> = new Subject();

  constructor() {

  }

  showTreeEditor(visibility) {
    this.treeEditorStatus.next(visibility);
  }

  onVisibilityChanged(): Observable<boolean> {
    return this.treeEditorStatus;
  }
}
