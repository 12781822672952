import { Component, OnInit, Input } from '@angular/core';

import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';

@Component({
  selector: 'custom-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() isloading;
  constructor(private parentCommunicator: ParentCommunicatorService) {
    // add publisher at loader-intercepter to get subscribed data when use this component
    // this.parentCommunicator.Subscribe("DataLoading", (v) => {
    //   this.isloading = v;
    // });
  }
  ngOnInit(): void {
  }

}
