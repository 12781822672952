<div id="events-content" [class.form-inactive]="disableForm">
  <div class="ft-form" *ngIf="eventList?.length == 0 || eventList == null ">
    <div class="ft-form-row" style="padding-right: 0 !important;">
      <div class="no-record" style="height: 100px;">
        <span>{{eventConfig?.message}}</span>
      </div>
    </div>
  </div>
  <div id="event-list-container" #eventListContainer *ngIf="eventList?.length > 0 " (scroll)="onContainerScroll($event)"
    [ngStyle]="{'padding': (eventConfig.referenceType == 1 || eventConfig.referenceType == 2) ? '15px 0px 0px 20px': '0px'}">
    <div *ngFor="let event of eventList" class="item"
      [ngStyle]="{'width': (eventConfig.referenceType == 1 || eventConfig.referenceType == 2) ? '98%': '100%'}">
      <div class="item-image" style="background-color: #e6eed7;">
        <img [src]="getTimeStampedImageUrl(event?.eventImage?.originalMediaUrl)" (error)='onImageLoadingError($event)'>
      </div>
      <div class="item-content">
        <!-- event name  -->
        <span *ngIf="!event.isCustomEvent" class="label-m-grey text-ellipsis-1">
          <span><img [src]="getImageByRefType(event)" (error)='onImageLoadingError($event)' width="15"
              height="15"></span>
          {{"event_" + event.name | lowercase|
          translate}} <span *ngIf="event?.relatedIndividualName?.length > 0">-
            {{event?.relatedIndividualName}}</span>
        </span>
        <span *ngIf="event.isCustomEvent" class="label-m-grey">{{capitalizeFirstLetter(event.name)}}</span>
        <!-- event date + palce  -->
        <span class="label-s-grey text-ellipsis-1">
          {{ getFormattedDate(event.date?.genealogyDate?.originalStr || event.date?.genealogyDateRange?.originalStr) }}
          <span *ngIf="event.date?.genealogyDate?.originalStr && event.place?.rawData">-</span>
          <span *ngIf="event.date?.genealogyDateRange?.originalStr && !event.date?.genealogyDate?.originalStr && event.place?.rawData">-</span>
          {{ event.place?.rawData }}
        </span>
        <!-- event additional info (child relationship type)-->
        <span *ngIf="event.additionalDetails?.length > 0 && referenceType != 1" class="label-xs-grey">{{"enum_" +
          event.additionalDetails | lowercase| translate}}</span>
        <!-- Age -->
        <span *ngIf="referenceType == 1 && getAgeData(event) != null " class="label-xs-grey">{{"lbl_age"| translate}} -
          {{getAgeData(event)}}</span>
      </div>
      <div class="item-links">
        <span *ngIf="event.note?.length > 0" class="label-xs-grey"
          (click)="showToolTipNotes($event, event.note)">{{"lbl_note" | translate}}</span>
        <span *ngIf="event.noOfSources > 0" class="label-xs-grey"
          (click)="showToolTipSources($event, event.id)">{{event.noOfSources}} {{"lbl_source"| translate}}</span>
        <span *ngIf="event.noOfMultimedias > 0" class="label-xs-grey"
          (click)="showToolTipMultimedia($event, event.id)">{{event.noOfMultimedias}} {{"lbl_multimedia"|
          translate}}</span>
        <span *ngIf="event.description?.length > 0" class="label-xs-grey"
          (click)="showToolTipDescription($event, event.description)">{{"lbl_description" | translate}}</span>
        <span *ngIf="event.noOfWitnesses > 0" class="label-xs-grey"
          (click)="showToolTipWitness($event, event.id)">{{event.noOfWitnesses}} {{"lbl_witness"| translate}}</span>
        <span *ngIf="referenceType != 1 && referenceType != 2 && event.noOfIndividuals > 0" class="label-xs-grey"
          (click)="showToolTipIndividuals($event, event.id)">{{event.noOfIndividuals}} {{"name"| translate}}</span>
      </div>
      <div class="item-options" *ngIf="event.isDirectEvent && event.type == referenceType">
        <img src="/assets/images/icons/edit.svg" [class.btn-inactive]="disableForm" class="ft-btn-round-green-base"
          style="cursor: pointer;" width="23" height="23" (click)="openEventEditor(event)">
        <img src="/assets/images/icons/delete.svg" [class.btn-inactive]="disableForm" class="ft-btn-round-green-base"
          style="cursor: pointer;" width="23" height="23" (click)="delete(event)">
      </div>
    </div>
  </div>
</div>