import { DropMenu } from "../../drop-menu/models/DropMenu";

export class MediaGalleryItem{
    id        : number;
    reference : any;
    caption   : string;
    isSelected: boolean;
    url       : string;
    dropMenu  : DropMenu;  
    searchTag?: string; // Use if need to search any other fields except captions
    mediaType?: number;
    isEditEnable: boolean;
    isDisabled?: boolean;
    type? : string;
    status? : string;
    duration? : string;
    size? : string;

  }