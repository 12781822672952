export class ParentNodeService {

  parentlabelinfo: any[] = [];

  constructor() {

    this.parentlabelinfo = [
      {
        displayIndex: 0,
        x: 85,
        y: 400,
        expandingWheelX: 0,
        expandingWheelY: 0,
        expanded: false
      },


      {
        displayIndex: 1,
        x: 300,
        y: 180,
        expandingWheelX: 215,
        expandingWheelY: -220,
        expanded: false
      },
      {
        displayIndex: 1.01,
        x: 300,
        y: 620,
        expandingWheelX: 215,
        expandingWheelY: 220,
        expanded: false
      },


      {
        displayIndex: 2,
        x: 520,
        y: 70,
        expandingWheelX: 435,
        expandingWheelY: -330,
        expanded: false
      },
      {
        displayIndex: 2.01,
        x: 520,
        y: 290,
        expandingWheelX: 435,
        expandingWheelY: -110,
        expanded: false
      },
      {
        displayIndex: 2.02,
        x: 520,
        y: 510,
        expandingWheelX: 435,
        expandingWheelY: 110,
        expanded: false
      },
      {
        displayIndex: 2.03,
        x: 520,
        y: 730,
        expandingWheelX: 435,
        expandingWheelY: 330,
        expanded: false
      },


      {
        displayIndex: 3,
        x: 910,
        y: 45,
        status: 'hide'
      },
      {
        displayIndex: 3.01,
        x: 910,
        y: 135,
        status: 'hide'
      },
      {
        displayIndex: 3.02,
        x: 910,
        y: 265,
        status: 'hide'
      },
      {
        displayIndex: 3.03,
        x: 910,
        y: 355,
        status: 'hide'
      },
      {
        displayIndex: 3.04,
        x: 910,
        y: 485,
        status: 'hide'
      },
      {
        displayIndex: 3.05,
        x: 910,
        y: 575,
        status: 'hide'
      },
      {
        displayIndex: 3.06,
        x: 910,
        y: 705,
        status: 'hide'
      },
      {
        displayIndex: 3.07,
        x: 910,
        y: 795,
        status: 'hide'
      },



      {
        displayIndex: 4,
        x: 1160,
        y: 30,
        status: 'hide'
      },
      {
        displayIndex: 4.01,
        x: 1160,
        y: 70,
        status: 'hide'
      },
      {
        displayIndex: 4.02,
        x: 1160,
        y: 120,
        status: 'hide'
      },
      {
        displayIndex: 4.03,
        x: 1160,
        y: 160,
        status: 'hide'
      },
      {
        displayIndex: 4.04,
        x: 1160,
        y: 250,
        status: 'hide'
      },
      {
        displayIndex: 4.05,
        x: 1160,
        y: 290,
        status: 'hide'
      },
      {
        displayIndex: 4.06,
        x: 1160,
        y: 340,
        status: 'hide'
      },
      {
        displayIndex: 4.07,
        x: 1160,
        y: 380,
        status: 'hide'
      },
      {
        displayIndex: 4.08,
        x: 1160,
        y: 470,
        status: 'hide'
      },
      {
        displayIndex: 4.09,
        x: 1160,
        y: 510,
        status: 'hide'
      },
      {
        displayIndex: 4.10,
        x: 1160,
        y: 560,
        status: 'hide'
      },
      {
        displayIndex: 4.11,
        x: 1160,
        y: 600,
        status: 'hide'
      },
      {
        displayIndex: 4.12,
        x: 1160,
        y: 690,
        status: 'hide'
      },
      {
        displayIndex: 4.13,
        x: 1160,
        y: 730,
        status: 'hide'
      },
      {
        displayIndex: 4.14,
        x: 1160,
        y: 780,
        status: 'hide'
      },
      {
        displayIndex: 4.15,
        x: 1160,
        y: 820,
        status: 'hide'
      },
      {
        displayIndex: 5,
        x: 1400,
        y: 25,
        status: 'hide'
      },
      {
        displayIndex: 5.01,
        x: 1400,
        y: 45,
        status: 'hide'
      },
      {
        displayIndex: 5.02,
        x: 1400,
        y: 65,
        status: 'hide'
      },
      {
        displayIndex: 5.03,
        x: 1400,
        y: 85,
        status: 'hide'
      },
      {
        displayIndex: 5.04,
        x: 1400,
        y: 115,
        status: 'hide'
      },
      {
        displayIndex: 5.05,
        x: 1400,
        y: 135,
        status: 'hide'
      },
      {
        displayIndex: 5.06,
        x: 1400,
        y: 155,
        status: 'hide'
      },
      {
        displayIndex: 5.07,
        x: 1400,
        y: 175,
        status: 'hide'
      },
      {
        displayIndex: 5.08,
        x: 1400,
        y: 245,
        status: 'hide'
      },
      {
        displayIndex: 5.09,
        x: 1400,
        y: 265,
        status: 'hide'
      },
      {
        displayIndex: 5.10,
        x: 1400,
        y: 285,
        status: 'hide'
      },
      {
        displayIndex: 5.11,
        x: 1400,
        y: 305,
        status: 'hide'
      },
      {
        displayIndex: 5.12,
        x: 1400,
        y: 335,
        status: 'hide'
      },
      {
        displayIndex: 5.13,
        x: 1400,
        y: 355,
        status: 'hide'
      },
      {
        displayIndex: 5.14,
        x: 1400,
        y: 375,
        status: 'hide'
      },
      {
        displayIndex: 5.15,
        x: 1400,
        y: 395,
        status: 'hide'
      },
      {
        displayIndex: 5.16,
        x: 1400,
        y: 465,
        status: 'hide'
      },
      {
        displayIndex: 5.17,
        x: 1400,
        y: 485,
        status: 'hide'
      },
      {
        displayIndex: 5.18,
        x: 1400,
        y: 505,
        status: 'hide'
      },
      {
        displayIndex: 5.19,
        x: 1400,
        y: 525,
        status: 'hide'
      },
      {
        displayIndex: 5.20,
        x: 1400,
        y: 555,
        status: 'hide'
      },
      {
        displayIndex: 5.21,
        x: 1400,
        y: 575,
        status: 'hide'
      },
      {
        displayIndex: 5.22,
        x: 1400,
        y: 595,
        status: 'hide'
      },
      {
        displayIndex: 5.23,
        x: 1400,
        y: 615,
        status: 'hide'
      },
      {
        displayIndex: 5.24,
        x: 1400,
        y: 685,
        status: 'hide'
      },
      {
        displayIndex: 5.25,
        x: 1400,
        y: 705,
        status: 'hide'
      },
      {
        displayIndex: 5.26,
        x: 1400,
        y: 725,
        status: 'hide'
      },
      {
        displayIndex: 5.27,
        x: 1400,
        y: 745,
        status: 'hide'
      },
      {
        displayIndex: 5.28,
        x: 1400,
        y: 775,
        status: 'hide'
      },
      {
        displayIndex: 5.29,
        x: 1400,
        y: 795,
        status: 'hide'
      },
      {
        displayIndex: 5.30,
        x: 1400,
        y: 815,
        status: 'hide'
      },
      {
        displayIndex: 5.31,
        x: 1400,
        y: 835,
        status: 'hide'
      }
    ]
  }
}
