import { Injectable, Component } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentBase } from '../components/common/base/component-base';
import { MessageDialogService } from '../components/common/message-dialog/services/message-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs/operators';
import { NotifierV2Service } from './notifier-v2.service';

@Injectable({
  providedIn: 'root'
})

export class RouteGuardService {

  constructor(private notifierService: NotifierV2Service,
    private messageDialogService: MessageDialogService) {  
  }

  canDeactivate(component: Component, 
    currentRoute: ActivatedRouteSnapshot, 
    currentState: RouterStateSnapshot, 
    nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {      
    return this.canChange(nextState,currentRoute);
  }

  canChange(nextState: RouterStateSnapshot,currentRoute: ActivatedRouteSnapshot) : Observable<boolean> {
    return new Observable((observer) => {
      if (!this.notifierService.canChange()) {

      this.messageDialogService.openUnSavedChangeConfirmation('').subscribe((res: boolean) => {  
        // Here time out is used to over come the race condition on subscribtion of the onclose() in messageDialogService in dialogcomponent
        // Later: have to R&D on how to over come this. 
        setTimeout(() => {                 
          observer.next(res);
          observer.complete();          
        }, 1);
      })
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
