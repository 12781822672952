export class SunChartUtil {  
    
    // Get alignment
    public static getAlignment(alignment){
        if ( alignment == "left" ){
          return { "startOffset" : "0%" , "anchor":"start"};
        }
        if ( alignment == "right" ){
          return { "startOffset" : "100%" , "anchor":"end"};
        }
        // default and center
        return { "startOffset" : "50%" , "anchor":"middle"};
    }
      
    // Sum width of previous levels
    public static sumPRev(levels, idx){
        var tot = 0;
        for(var x=0;x<= idx;x++){
            tot += levels[x].w;
        }
        return tot;
    }
    
    // Get a point in on a arc sector axis - x (-left to right+) y ( -top to bottom+)
    public static getSectorPoint(radius, angleDeg){
        return { "x":Math.round( radius * Math.cos(angleDeg * Math.PI / 180) * 100) /100, "y":Math.round(radius * Math.sin(angleDeg * Math.PI / 180)* 100) /100 };
    }
      
    // Get a line on an arc sector axis - x (-left to right+) y ( -top to bottom+)
    public static getSectorLine(r1, r2, angleDeg){
        var p1 = SunChartUtil.getSectorPoint(r1, angleDeg );
        var p2 = SunChartUtil.getSectorPoint(r2, angleDeg );    
        return { "p1": p1 , "p2": p2 };
    }
    
    // check if object empty
    // public static isEmptyObject(obj){
    //     return JSON.stringify(obj) === '{}';
    // }
}