import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageDialogBaseComponent } from '../message-dialog-base/message-dialog-base.component';
import { MessageDialogService } from '../services/message-dialog.service';
import { StripeMessageActions } from 'src/app/common/enums/enums';

@Component({
  selector: 'app-message-prompt-stripe',
  templateUrl: './message-prompt-stripe.component.html',
  styleUrls: ['./message-prompt-stripe.component.scss']
})
export class MessagePromptStripeComponent extends MessageDialogBaseComponent implements OnInit {

  constructor(messageDialogService:MessageDialogService, ref:ChangeDetectorRef) {
    super(messageDialogService, ref);
  }
  public messageAction = StripeMessageActions;

  ngOnInit(): void {
  }

  confirm(result:any){
    this.complete(result);
  }
}
