import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberEditorMainComponent } from './member-editor-main/member-editor-main.component';
import { MemberEditorComponent } from './member-editor/member-editor.component';
import { CommonComponentModule } from '../common/common.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [MemberEditorMainComponent, MemberEditorComponent],
  exports:[MemberEditorMainComponent],
  imports: [
    CommonModule,
    CommonComponentModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class MembereditorModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
