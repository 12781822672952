import { CustomApiParams } from "../models/ApiRequest";

export class CustomApiParameters {

    private customParaList: CustomApiParams[] = [];

    public addCustomParameter(type: string, data: string): CustomApiParameters {
        let customPara = new CustomApiParams();
        customPara = { Type: type, Data: data };
        this.customParaList.push(customPara);
        return this;
    }
    public getCustomParaList() {
        return this.customParaList;
    }

    public clearAllParams() {
        this.customParaList = [];
    }
}