import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Individual } from '../common/models/Individual';
import { ParentCommunicatorService } from './parent-communicator.service';
import { SessionDataMapper } from '../common/mappers/SessionDataMapper';
import { ParentMessageType } from '../common/models/ParentMessage';

// Todo: To be removed
@Injectable({
  providedIn: 'root'
})
export class V10ApiService {
  private onlineId: string;
  private fileName: string;
  private mainDomain: string;
  private token: string;
  private rootMemberId: string;
  private subscribeInitialPageLoad: string;
  private subscriptionRootMemberId: string;

  constructor(private http: HttpClient,
    private parentCommunicatorService: ParentCommunicatorService,
    private sessionDataMapper: SessionDataMapper
  ) {
    this.subscribeMethods();
  }

  subscribeMethods() {
    this.subscribeInitialPageLoad = this.parentCommunicatorService.receive(ParentMessageType.StartApplication, (pageloadData) => {
      let parentObj = this.sessionDataMapper.map(pageloadData);
      if (parentObj.rootMemberId > 0) {
        this.fileName = parentObj.fileName;
        this.onlineId = parentObj.OnlineId;
        this.token = parentObj.token;
        this.mainDomain = parentObj.mainDomain;
      }
    });

    this.subscriptionRootMemberId = this.parentCommunicatorService.receive(ParentMessageType.RootMemberChanged, rootMember => {
      if (rootMember) {
        this.rootMemberId = rootMember.Id;
      }
    });
  }


  getTimelineEvents(individualId): Observable<Individual> {

    // this.setGlobalVariables();

    let headersObject = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'member-token': this.token
      })
    };
    let messageBody = {
      'onlineId': this.onlineId,
      'fileName': this.fileName,
      'individualId': this.rootMemberId
    }

    return this.http.post<Individual>
      (this.mainDomain + '/Client/IndividualEditRestful/GetIndividualWithEvents',
        messageBody,
        headersObject) // JSON.stringify({ "onlineId": onlineId }), headers_object)
  }

  ngOnDestroy(): void {
    this.parentCommunicatorService.close(ParentMessageType.RootMemberChanged, this.subscriptionRootMemberId);
    this.parentCommunicatorService.close(ParentMessageType.StartApplication, this.subscribeInitialPageLoad);
  }
}
