import { IndividualBaseViewModel } from 'src/app/common/models/IndividualViewModel';
import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { StringLengthLimiter } from '../../../common/helpers/string-length-limiter';
import { RootMemberResponse } from 'src/app/common/models/RootMember';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { MultimediaTypes, EditorMode, ReferenceType, TreeLevel } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { StringLimiter } from 'src/app/common/helpers/string-limiter';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { NotifierEvents } from '../../../common/enums/enums';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';

@Component({
  selector: 'g[app-mainlabels]',
  templateUrl: './mainlabels.component.html',
  styleUrls: ['./mainlabels.component.scss']
})
export class MainlabelsComponent implements OnInit {

  @Input() label: any;
  @Input() i: number;
  @Input() expandColor: any;
  @Input() rootMember: any;
  @Input() editedLabelImage: any;
  @Output() childFunction = new EventEmitter();
  @Output() openInputFormBoxWithData = new EventEmitter();
  @Output() refreshParent = new EventEmitter();
  selectedMember: any;
  multimediaSubscription: any;
  timeTag: string = "";
  splitDisplayName: any;

  constructor(
    public stringLimit: StringLengthLimiter,
    private loadingService: LoadingService,
    public stringLimiter: StringLimiter,
    private translateHandler: TranslateHandler,
    private dialogService: DialogService,
    private individualApiService: IndividualApiService,
    private notifierService: NotifierV2Service) {
  }

  breakText = function (text: String, line: number) {
    return this.stringLimit.breakText(text, line, this.limitLength);
  }

  ngOnInit(): void {
    this.setStringLimit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editedLabelImage != null) {
      this.timeTag = "&timeTag=" + new Date().getTime(); // used to avoid caching
      if (this.label.profileImageId == this.editedLabelImage.id) {
        this.label.profileImage = this.editedLabelImage.mediaUrl;
      }
    }
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let self = this;// Self reference required 

        switch (propName) {
          case 'label': {
            if (this.label) {
              self.label = this.label;
              this.label.profileImage = this.label.profileImage ? this.label.profileImage + "&timeTag=" + new Date().getTime() : "";
            }
          }
        }
      }
    }
  }

  addImage(label: IndividualBaseViewModel) {
    this.dialogService.setHalf().open("Multimedia", {
      // Set id to 0 if StandAlone
      id: label.profileImageId,
      reference: {
        id: label.id, // Individual Id
        type: ReferenceType.Individual // Individual type
      },
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_pictures").toLowerCase(),
      this.label.givenName]) ?? "",
      mediaType: [MultimediaTypes.PHOTO],
      viewMode: 1, // Gallery or editor view
      editMediaId: 0, // MediaId which supposes to be edited
      isStandAlone: false
    }
    )
      .subscribe(response => {
        if (response.data) {
          let mediaBasic: Multimedia = null;

          response.data.deleteMedias.forEach(element => {
            this.label.profileImage = "";
            this.label.profileImageId = "";
            // Next image as primary one     
            mediaBasic = response.data.nextImage;
          });

          if (response.data.newMedia) {
            mediaBasic = new Multimedia();
            mediaBasic = response.data.newMedia;
          }

          if (mediaBasic != null) {
            let processId = GuidGenerator.generate();
            this.loadingService.show(processId);
            mediaBasic.isPrimary = true;
            mediaBasic.isNonProfile = false;
            //Update profile Image
            this.individualApiService.updateProFileImage(this.label.id, mediaBasic).subscribe((dataResponse: any) => {
              this.label.profileImage = mediaBasic.mediaUrl + "&timeTag=" + new Date().getTime();
              this.label.profileImageId = mediaBasic.id;
            }, (err) => {
              throw new CustomError("MainlabelComponent => addImage() : " + err, 911, false);
            }).add(() => {
              this.loadingService.hide(processId);
            });
          }

        }
      })
  }

  expandWheel(content) {
    this.childFunction.emit(true);
  }

  editMember(label) {
    console.log("MemberEdit : MainLabels => EditMember(label) 1");
    let data = {
      id: label.id,
      editorMode: EditorMode.IndividualEditor,
      isUpdateHistory: true
    }
    let rootMember = this.selectedRootMember(label);
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, rootMember);
    //JSON.stringify(data)
    this.notifierService.setCurrentEditor(`/editor/individualeditor`, data, null);
  }

  addParent(index, label) {
    this.openInputFormBoxWithData.emit({ index, label });
  }


  changeRootPerson(label) {
    let rootMember = this.selectedRootMember(label);
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, rootMember);
  }

  selectedRootMember(label): RootMemberResponse {
    var rootMember = new RootMemberResponse();
    rootMember.Id                 = label.id;
    rootMember.FirstName          = label.firstName;
    rootMember.LastName           = label.lastName;
    rootMember.DisplayName        = label.displayName;
    rootMember.Gender             = label.gender;
    rootMember.husbandDisplayName = label.husbandDisplayName;
    rootMember.wifeDisplayName    = label.wifeDisplayName;
    rootMember.defaultFamilyId    = label.defaultFamilyId

    return rootMember;
  }

  setStringLimit() {
    this.splitDisplayName = this.stringLimiter.splitString(this.label.displayName, TreeLevel.Main)
  }

  updateFaultUrl() {
    this.label.profileImage = "";
  }

}
