<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_source_list" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">
         <div class="tabs">
            <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{data.options.primaryTabName | translate}}</a>
            <a [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">+ {{"lbl_add_source" | translate}}</a>
        </div>
        <div class="tab-forms app-light-bg">                
            <app-sources *ngIf="getViewMode() === 1" [data]="data" (onCompleted)="complete($event)"></app-sources>
            <app-source-editor *ngIf="getViewMode() === 2" [data]="data" (onCompleted)="complete($event)"></app-source-editor>                 
        </div>            
    </div>
</div>
  