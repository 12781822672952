import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';

@Component({  
  template: '<div></div>'  
})

export class DialogBaseComponent implements OnInit, OnDestroy {

  constructor(public dialogSerivce:DialogService, private ref:ChangeDetectorRef) { }
 
  @Input() data: any;
  lastResponseInfo: any;
    
  ngOnInit(): void {
  }

  public complete(responseInfo:any){
    this.dialogSerivce.complete(responseInfo);
  }

  public close(){
    this.dialogSerivce.close(this.lastResponseInfo);
  }
  
  ngOnDestroy() {
    this.ref.detach();
  }
}
