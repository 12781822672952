import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourceCitationMainComponent } from './source-citation-main/source-citation-main.component';
import { SourceCitationEditorComponent } from './source-citation-editor/source-citation-editor.component';
import { FormsModule } from '@angular/forms';
import { CommonComponentModule } from '../common.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [SourceCitationMainComponent, SourceCitationEditorComponent],
  imports: [
    CommonModule,
    FormsModule,             
    CommonComponentModule,  
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })   
  ]
})
export class SourceCitationModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}