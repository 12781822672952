<!-- Family editor/member editor -->
<!-- https://codepen.io/lookininward/pen/zYOQjZM -->
<div class="ft-form" [class.form-inactive] = "disableForm">
    <div class="content"(scroll)="onContainerScroll($event)">
        <form class="form" id="ngFormIndividual" #f="ngForm" (ngSubmit)="f.form.valid && updateIndividual()">
            <fieldset [disabled]="disableForm">
            <!-- Profile pic and parents -->
            <div class="ft-form-row col2-vf">
                <div class="border-outline">
                    <!-- Individual images carousel -->
                    <app-image-carousel-temp 
                        [defaultImage] = "defaultCarouselImage" 
                        [inImages]     = "getInImages()"
                        (action)       ="carouselActionClicked($event)">
                    </app-image-carousel-temp>
                </div>
                <div class="ft-form-row col1-v100" style="margin-bottom: 0px;">
                    <div [attr.class]="'name-container name-option-'+nameDisplaySetting.option">
                        <div *ngIf="nameDisplaySetting.prefix" class="grd-pfix">
                            <div class="ft-form-input">
                                <label>{{'lbl_prefix' | translate}}</label>
                                <input type="text" [(ngModel)]="individualViewModel.prefix" [ngModelOptions]="{standalone: true}" name="prefix">
                            </div>
                        </div>
                        <div *ngIf="nameDisplaySetting.givenName" [ngClass]="{'grd-givenname':!nameDisplaySetting.isLastFirst,'grd-lastname':nameDisplaySetting.isLastFirst}">
                            <div class="ft-form-input">
                                <label>{{'lbl_given_name' | translate}}</label>
                                <input type="text" [(ngModel)]="individualViewModel.givenName" name="givenName" #givenName="ngModel"
                                [required]="(individualViewModel.givenName || individualViewModel.surname) ? false : true"  [class.border-wrong] = "f.submitted && givenName.invalid">
                                <!-- Name validation -->
                                <div *ngIf="f.submitted && givenName.invalid" class="form-control-feedback">
                                    <div *ngIf="givenName.errors.required ">
                                        <p class="input-s-wrong" style="font-size: 12px;">
                                            {{'val_at_least_one_name_required' | translate }}</p>
                                    </div>
                                </div>                           
                            </div>
                        </div>
                        <div *ngIf="nameDisplaySetting.middleName" class="grd-middlename">
                            <div class="ft-form-input">
                                <label>{{'lbl_middle_name' | translate}}</label>
                                <input type="text" [(ngModel)]="individualViewModel.middleName" [ngModelOptions]="{standalone: true}" name="middleName">
                            </div>
                        </div>  
                        <div *ngIf="nameDisplaySetting.patName" class="grd-patname">
                            <div class="ft-form-input">
                                <label>{{'lbl_patronymic_name' | translate}}</label>
                                <input type="text" [(ngModel)]="individualViewModel.patronomical" [ngModelOptions]="{standalone: true}" name="patronomical">
                            </div>
                        </div>
                        <div *ngIf="nameDisplaySetting.lastName" [ngClass]="{'grd-givenname':nameDisplaySetting.isLastFirst,'grd-lastname':!nameDisplaySetting.isLastFirst}">
                            <div class="ft-form-input">
                                <label>{{'lbl_last_name' | translate}}</label>
                                <input type="text" [(ngModel)]="individualViewModel.surname" name="surname" #surname="ngModel"
                                [required]="(individualViewModel.givenName || individualViewModel.surname) ? false : true" [class.border-wrong] = "f.submitted && surname.invalid">
                                <!-- Name validation -->
                                <div *ngIf="f.submitted && surname.invalid" class="form-control-feedback">
                                    <div *ngIf="surname.errors.required ">
                                        <p class="input-s-wrong" style="font-size: 12px;">
                                            {{'val_at_least_one_name_required' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="nameDisplaySetting.suffix" class="grd-sfix">
                            <div class="ft-form-input">
                                <label>{{'lbl_suffix' | translate}}</label>
                                <input type="text" [(ngModel)]="individualViewModel.suffix" [ngModelOptions]="{standalone: true}" name="suffix">
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>      

            <div class="ft-form-row col1-v100" style="margin-top: 10px;margin-bottom: 20px;">
                <div>
                    <div class="ft-form-input">
                        <label>{{'lbl_gender' | translate}}</label> 
                        <div style="display: flex;flex-direction: row;gap:10px">
                            <div class="input-radio-container">
                                <label>{{'lbl_male' | translate}}</label>                        
                                <input type="radio"   name="gender"  (change)="setGender('M')" [checked]="individualViewModel.gender == 'M'">
                                <span class="radiomark"></span>
                            </div>
                            <div class="input-radio-container">
                                <label>{{'lbl_female' | translate}}</label>                        
                                <input type="radio"   name="gender"  (change)="setGender('F')" [checked]="individualViewModel.gender == 'F'">
                                <span class="radiomark"></span>
                            </div>
                            <div class="input-radio-container">
                                <label>{{'lbl_unknown' | translate}}</label>                        
                                <input type="radio"   name="gender"  (change)="setGender('U')"  [checked]="individualViewModel.gender == 'U'">
                                <span class="radiomark"></span>
                            </div>
                        </div>                   
                    </div>
                </div>           
            </div>

            <!-- birthday date and place -->
            <div class="ft-form-row col2-vf">
                <div class="ft-form-input">
                    <label> {{'lbl_birthday' | translate}} </label>                
                    <app-date-input-temp [inDate]="individualViewModel?.birthEvent?.date" (outDate)="setFormatedBirthDate($event)" (keydown)="isDirty=true"  [scrollUpdate]="isScrolled"></app-date-input-temp>
                </div>        
                <div class="ft-form-input">
                    <label>{{'lbl_birth_place' | translate}}</label>
                    <app-place-input    [inPlace]="individualViewModel?.birthEvent?.place" [isFieldEnabled]="disableForm" (placeChange)="setPlaceForEvent($event)" (keydown)="isDirty=true" [title] = "getPlacePopupTitle('event_birth')"></app-place-input>
                </div>
            </div>

            <div class="ft-form-row col1-v100" style="margin-top: 10px;margin-bottom: 20px;">
                <div>
                    <div class="ft-form-input">                    
                        <div style="display: flex;flex-direction: row;gap:10px">
                            <div class="input-radio-container">
                                <label>{{'lbl_living' | translate}}</label>                        
                                <input type="radio" [checked]="individualViewModel.aliveStatus == 1"  name="livingStatus" (click)="setAliveStatus(1)">
                                <span class="radiomark"></span>
                            </div>
                            <div class="input-radio-container">
                                <label>{{'lbl_deceased' | translate}}</label>                        
                                <input type="radio" [checked]="individualViewModel.aliveStatus == 2" name="livingStatus" (click)="setAliveStatus(2)">
                                <span class="radiomark"></span>
                            </div>
                            <div class="input-radio-container">
                                <label>{{'lbl_unknown' | translate}}</label>                        
                                <input type="radio" [checked]="individualViewModel.aliveStatus == 0" name="livingStatus" (click)="setAliveStatus(0)">
                                <span class="radiomark"></span>
                            </div>                      
                        </div>                   
                    </div>
                </div>           
            </div>
            <!-- Death date and place -->
            <div class="ft-form-row col2-vf">
                <div class="ft-form-input">
                    <label>{{'lbl_death_date' | translate}}</label>          
                    <app-date-input-temp [inDate]="individualViewModel?.deathEvent?.date" [isFieldEnabled]="!isFieldDisabled" (outDate)="setFormatedDeathDate($event)" (keydown)="isDirty=true" [scrollUpdate]="isScrolled"></app-date-input-temp>
                </div>        
                <div class="ft-form-input">
                    <label>{{'lbl_death_place' | translate}}</label>
                    <app-place-input    [inPlace]="individualViewModel?.deathEvent?.place"  [isFieldEnabled]="isFieldDisabled || disableForm" (placeChange)="setPlaceForEvent($event)" [title] = "getPlacePopupTitle('event_death')"></app-place-input>
                </div>
            </div>

            <!-- Bio and medical -->
            <div class="ft-form-row col2-v50v50" style="margin-top: 10px;">
                <div>
                    <div class="ft-form-input">
                        <div style="display: flex;flex-direction: row; justify-content: space-between;">
                            <label>{{'lbl_bio' | translate}}</label>
                            <!-- label with input -->
                            <label class="input-checked-container input-checkbox-small">{{'lbl_private' | translate}}
                                <input type="checkbox" [(ngModel)]="individualViewModel.isBiographicalSupression" [ngModelOptions]="{standalone: true}" [checked]="individualViewModel.isBiographicalSupression">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <textarea type="textarea" style="height: 75px;" [(ngModel)]="individualViewModel.biographical" [ngModelOptions]="{standalone: true}" name="biographical"></textarea>
                    </div>
                </div>
                <div>
                    <div class="ft-form-input">
                        <div style="display: flex;flex-direction: row; justify-content: space-between;">
                            <label>{{'lbl_medical' | translate}}</label>
                            <!-- label with input -->
                            <label class="input-checked-container input-checkbox-small">{{'lbl_private' | translate}}
                                <input type="checkbox"  name="medPrivate" [(ngModel)]="individualViewModel.isMedicalSupression" [ngModelOptions]="{standalone: true}" [checked]="individualViewModel.isMedicalSupression">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <textarea type="textarea" style="height: 75px;" [(ngModel)]="individualViewModel.medical" [ngModelOptions]="{standalone: true}" name="medical"></textarea>
                    </div>
                </div>
            </div>
            <!-- Address -->
            <div class="ft-form-row col2-v50v50">
                <div>
                    <div class="ft-form-input">
                        <label>{{'lbl_address' | translate}}</label>
                        <input type="text" [(ngModel)]="individualViewModel.address" [ngModelOptions]="{standalone: true}" name="address">
                    </div>
                </div>
                <div>
                    <div class="ft-form-input">
                        <label>{{'lbl_phone' | translate}}</label>
                        <input type="text" [(ngModel)]="individualViewModel.phone" [ngModelOptions]="{standalone: true}" name="phone">
                    </div>
                </div>
            </div>
            <!-- flags -->
            <div class="ft-form-row col2-v50v50">
                <div>
                    <div class="ft-form-input">
                        <label>{{'lbl_email' | translate}}</label>
                        <input type="text" [(ngModel)]="individualViewModel.email" [ngModelOptions]="{standalone: true}" name="email">
                    </div>
                </div>
                <div>
                    <div class="ft-form-input">
                        <label>{{'lbl_flags' | translate}}</label>
                        <div class="individual-flag-wrap">
                            <!-- label with input -->
                            <label class="input-checked-container input-checkbox-large">{{'lbl_search_results' | translate}}
                                <input type="checkbox" [(ngModel)]="individualViewModel.isSearchSupression" [ngModelOptions]="{standalone: true}" [checked]="individualViewModel.isSearchSupression">
                                <span class="checkmark"></span>
                            </label>
                            <!-- label with input -->
                            <label class="input-checked-container input-checkbox-large">{{'lbl_calendar' | translate}}
                                <input type="checkbox" [(ngModel)]="individualViewModel.isCalenderSupression" [ngModelOptions]="{standalone: true}" [checked]="individualViewModel.isCalenderSupression">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            </fieldset>
        </form>

        <!-- Add other media -->
        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label class="ft-img-label">
                    <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" style="cursor: pointer;" class="ft-btn-round-green-base" width="22" height="22" (click)="addMedia(0,1)">
                    {{"lbl_add_other_media" | translate}}
                    <img class="media-arrow"  [ngClass] = "isMediaCarouselOpen? 'media-arrow-up': ''" (click)="toggleMediaCarousel()">
                </label>               
                <app-media-carousel [hidden]="!isMediaCarouselOpen" [items] = "mediaItems"  (action)="mediaCarouselActionClicked($event)"></app-media-carousel>
            </div>
        </div>
        <!-- Add other media -->
    </div>
    <div class="footer">
        <button [disabled]="disableForm || !isDirty" [ngClass]="{'ft-btn-active':!disableForm,'ft-btn-inactive':disableForm || !isDirty}" class="ft-btn" type="submit" form="ngFormIndividual" [ngClass]="{'ft-btn-inactive' : defaultView, 'ft-btn-active' : !defaultView}">{{'lbl_save' | translate}}</button>
        <button [disabled]="disableForm" [ngClass]="{'active-red':!disableForm,'ft-btn-inactive':disableForm}" class="ft-btn"(click)="delete()" >{{'lbl_delete' | translate}}</button>
        <button [disabled]="disableForm" [ngClass]="{'ft-btn-active-not-selected':!disableForm,'ft-btn-inactive':disableForm}" class="ft-btn" (click)="cancel()">{{'lbl_cancel' | translate}}</button>
    </div>
</div>