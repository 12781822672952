import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { SessionDataMapper } from 'src/app/common/mappers/SessionDataMapper';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { environment } from 'src/environments/environment';
import PublishOption from 'src/app/common/models/PublishOption';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { CustomApiParameters } from 'src/app/common/helpers/custom-api-parameters';
import { AuthenticationService } from "src/app/services/authentication.service";
import { ReportGenerateRequest } from "src/app/common/models/report/report-generate-request";
import { ReportApiService } from "src/app/services/API/report-api.service";


@Injectable({
  providedIn: "root",
})
export class ReportEngineService {

  // server url
  private baseURL = environment.LEGACY_API_URL

  //member variables
  private onlineId: string;
  private fileName: string;
  private rootMemberId: string;


  //Subscription variables
  private subscribeInitialPageLoad;
  private subscriptionRootMemberId;


  constructor(private _http: HttpClient,
    private parentCommunicatorService: ParentCommunicatorService,
    private sessionDataMapper: SessionDataMapper,
    private apiBaseService: ApiBaseService, private customApiParameters: CustomApiParameters,
    private authenticationService: AuthenticationService,
    private reportApiService: ReportApiService) {
    this.subscribeMethods();
  }


  subscribeMethods() {

    this.subscribeInitialPageLoad = this.parentCommunicatorService.receive(ParentMessageType.StartApplication, (pageloadData) => {
      let parentObj = this.sessionDataMapper.map(pageloadData);
      if (parentObj.rootMemberId > 0) {
        this.fileName = parentObj.fileName;
        this.onlineId = parentObj.OnlineId;
      }
    });
    
    this.subscriptionRootMemberId = this.parentCommunicatorService.receive(ParentMessageType.RootMemberChanged, rootMember => {
      if (rootMember) {
        this.rootMemberId = rootMember.Id;
      }
    });
  }

  ngOnDestroy(): void {
    this.parentCommunicatorService.close(ParentMessageType.RootMemberChanged, this.subscriptionRootMemberId);
    this.parentCommunicatorService.close(ParentMessageType.StartApplication, this.subscribeInitialPageLoad);
  }


  getMetaData(reportName): Observable<any> {
    let configJsonURL = `assets/report/${reportName}/config/${reportName}Config.json`;
    return this._http.get(configJsonURL);
  }

  /**
  * Check whether theme file exists or not in folder structure
  */
  getThemeFileExists(url: string): Observable<boolean> {
    return this._http.get(url, { observe: 'response', responseType: 'blob' })
      .pipe(map(result => {
        return true;
      }),
        catchError(err => {
          return of(false);
        })
      );
  }

  /**
  * Convert the Report to a PDF with relevant data
  */
  publishPDF(domElements: string, reportName: string, publishOption: PublishOption): Observable<any> {
    let URL = this.baseURL + "/ReportRestful/GenerateReportContentToHtmlAsync";
    let messageBody = {
      'onlineId': this.authenticationService.getUser().OnlineId,
      //'fileName': this.authenticationService.getUser().fileName,
      'reportContent': domElements,
      'rootPerson': "",
      'reportTitle': reportName,
      'publishOption': publishOption
    };
    return this._http.post(URL, messageBody)

    // .pipe(
    //   map((response) => {
    //     console.log(response);
    //     return "Report published successsuflly";
    //   }),
    //   catchError((err) => {
    //     console.log(err);
    //     return of("Error occured");
    //   })
    // );
  }

  publishReport(reportGenerateRequest: any): Observable<any> {   
     return this.reportApiService.createReport(reportGenerateRequest); 
  }

  getReportAccessible(generations): Observable<any> {
    console.log("membership access")
    console.log(generations)
    this.customApiParameters.clearAllParams();
    let customParaList = this.customApiParameters.
      addCustomParameter("FeatureId", generations).
      getCustomParaList();

    return this.apiBaseService.get("/api/membership/memberaccess", customParaList, null);

    // let data = { "isaccesible": true }
    // let data = { isaccesible: false, template: "Demo" }
    // let data = { "isaccesible": false, template: "Basic" }




    // return new Observable(subscriber => subscriber.next(data))
  }





}

