import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-add-new-project-main',
  templateUrl: './add-new-project-main.component.html',
  styleUrls: ['./add-new-project-main.component.scss']
})
export class AddNewProjectMainComponent extends DialogBaseComponent implements OnInit {

  // data.viewMode // data.showMode []
  public data: any;

  constructor(private dialogService: DialogService,
              ref: ChangeDetectorRef) {
    super(dialogService, ref);
  }

  ngOnInit(): void {
    this.getViewMode();
  }

  isShowMode(mode): number {
    if ( !this.data.showMode ) this.data.showMode = [mode];
    return this.data.showMode.includes(mode);
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {   
    this.data.viewMode = mode;
  }

  setLastResponse(responseInfo) {
    this.lastResponseInfo = responseInfo
  }
}
