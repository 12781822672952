import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ProjectRefService } from '../services/project-ref.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authentication: AuthenticationService, private projectRefService:ProjectRefService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (request.url.includes('/api/') && this.authentication.getToken() !== null) {
      request = this.addToken(request, this.authentication.getToken(), this.projectRefService.getCurrentProjectToken() );      
    }
    return next.handle(request);
    /*
    // calling the api (proceeding the request) with certain error handling to attend only the 401 issues.
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        localStorage.removeItem('token');
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    })); */
  }
/*
  // handling the error
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authentication.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
*/
  // Adding the specified token to the specified Http request.
  private addToken(request: HttpRequest<any>, token: string, projectToken:string) {    
    let headers = {
                    setHeaders: {
                        'Authorization': `Bearer ${token}`,
                        'ProjectToken' : !projectToken || projectToken == null ? `` : projectToken,
                    }
                  }
    return request.clone(headers);
  }
}
