import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainplaceComponent } from './mainplace/mainplace.component';
import { PlaceEditorComponent } from './place-editor/place-editor.component';
import { FormsModule } from '@angular/forms';
import { PlaceMainComponent } from './place-main/place-main.component';
import { PlacesComponent } from './places/places.component';
import { DropMenuModule } from '../drop-menu/drop-menu.module';
import { MediaGalleryModule } from '../media-gallery/media-gallery.module';
import { PlacesEditorTempComponent } from './places-editor-temp/places-editor-temp.component';
import { ImageCarouselComponent } from '../image-carousel/image-carousel.component';
import { ImageCarouselTempModule } from '../image-carousel-temp/image-carousel-temp.module';
import { EventsModule } from '../events/events.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';



@NgModule({
  declarations: [PlaceMainComponent, PlacesComponent, PlacesEditorTempComponent],
  imports: [
    CommonModule,
    FormsModule,
    DropMenuModule,
    MediaGalleryModule, 
    ImageCarouselTempModule,
    EventsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class PlaceModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
