import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DialogBaseComponent } from 'src/app/components/common/dialog/dialog-base/dialog-base.component';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';

  /**
  * @deprecated This component should not be used, use ReportOptionsPopupComponent instead
  */
@Component({
  selector: 'app-sunchart-option-popup',
  templateUrl: './sunchart-option-popup.component.html',
  styleUrls: ['./sunchart-option-popup.component.scss']
})
export class SunchartOptionPopupComponent extends DialogBaseComponent implements OnInit {

  popupTitle: string;
  isSaveEnable: boolean = false;
  fanchartServiceSubscription: any
  dropDownData = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  selectedGenaration = 5
  reportTitle = ""
  transitionVal: any;
  public data: any;

  constructor( dialogSerivce: DialogService, 
    private ref1: ChangeDetectorRef,
    private translateHandler : TranslateHandler,) {
    super(dialogSerivce, ref1);
  }


  ngOnInit(): void {
    this.selectedGenaration = this.data.selectedOption;
    this.reportTitle = this.data.reportTitle;
    this.popupTitle = this.data.popupTitle;
  }

  add(){
    this.dialogSerivce.complete({data : {
      title : this.reportTitle,
      selectedGenaration : this.selectedGenaration
    }});
  }

  cancel(){
    this.dialogSerivce.complete({data : null});
  }

  onChange(event) {
    console.log(event.target.value);
    this.selectedGenaration = event.target.value
  }

}
