import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })

export class FilenameHelper {
    getProjectName(filename : string){
        let processedFileName = filename;

        if (filename.includes("IRestore_")){
            let regexNameCheck = new RegExp("IRestore_(.*)_with_multimedia_[0-9]{8}|IRestore_(.*)_[0-9]{8}");
            processedFileName = filename.match(regexNameCheck)[1].toString();
            if (processedFileName == null){
                processedFileName = processedFileName.match(regexNameCheck)[2].toString();
            }
        }
        return processedFileName;
    }
}