import ThemeList from "./ThemeList";
import { AlignmentType } from "../enums/alignmentType";

export default class ReportConfig {
  private defaultAlingment: AlignmentType;
  private defaultZoomLevel: number;
  private minZoomLevel: number;
  private maxZoomLevel: number;
  private zoomStepSize: number;
  private isCenteredEnabled: string;
  private isHeightFixed: boolean;
  private isWidthFixed: boolean;
  public themes: ThemeList;
  public reportProperty: any;

  getDefaultZoomLevel() {
    return Number(this.defaultZoomLevel) || 1;
  }

  getMinimumZoomLevel() {
    return Number(this.minZoomLevel) || 2;
  }

  getMaximumZoomLevel() {
    return Number(this.maxZoomLevel) || 0.5;
  }

  getZoomStepLevel() {
    return Number(this.zoomStepSize) || 0.25;
  }

  getDefaultAlingment() {
    return this.defaultAlingment || AlignmentType.center;
  }

  getCenterEnability() {
    return this.isCenteredEnabled === "true" ? true : false;
  }
}
