<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_citation" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">
            <div class="tabs">
                <a [class.active]="viewMode == 2" (click)="viewMode = 2">+ {{"lbl_add_source_citation" | translate}}</a>                
            </div>
            <div class="tab-forms app-light-bg">                
                <app-source-citation-editor *ngIf="viewMode === 2" [data]="data" (onCompleted)="complete($event)"></app-source-citation-editor>                
            </div>                        
    </div>
</div>
  