import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { MessageDialogBaseComponent } from '../../common/message-dialog/message-dialog-base/message-dialog-base.component';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { FamilyViewModel } from 'src/app/components/familyeditor/models/family-view-model';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
import { RelationShipType } from 'src/app/common/enums/enums';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';

@Component({
  selector: 'app-individual-prompt',
  templateUrl: './individual-prompt.component.html',
  styleUrls: ['./individual-prompt.component.scss']
})
export class IndividualPromptComponent extends MessageDialogBaseComponent implements OnInit {

  public memberName: string;
  public isValid: boolean = true;
  spouseFamilies: FamilyViewModel[]; 
  selectedFamilyId: number;
  spouseDropdownList: IndividualViewBase[]; 
  haveMultipleSpouseFamilies: boolean = false;
  haveMultipleFamiliesWithChild: boolean = false;
  childFamilies: FamilyViewModel[]; 

  constructor(
    messageDialogService: MessageDialogService,
    ref: ChangeDetectorRef,
    private individualApiService: IndividualApiService,
    private parentCommunicatorService: ParentCommunicatorService,
    private loadingService: LoadingService,   
    private familyApiService: FamilyApiService,
    private translateHandler: TranslateHandler) {
    super(messageDialogService, ref);
  }

  ngOnInit(): void {
    if(this.request.relationshipType === RelationShipType.Son || this.request.relationshipType === RelationShipType.Daughter|| this.request.relationshipType === RelationShipType.Spouse) {
      this.getSpouseFamilyInfo(this.request.memberId);
    }
    else{
      this.selectedFamilyId = 0;
    }   
  }

  getSpouseFamilyInfo(memberId: number){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.familyApiService.getSpouseFamiliesInfo(memberId).subscribe((response: ResponseModel<FamilyViewModel[]>)=> {
      this.spouseFamilies = response.data;  
      if(this.request.relationshipType === RelationShipType.Son || this.request.relationshipType === RelationShipType.Daughter){
        this.mapAddChildUI(response.data);
      }   
      else
        this.mapAddSpouseUI(response.data);      
    }, (err) => {
        throw new CustomError("IndividualPromptComponent => getSpouseFamiliesInfo() : " + err, 911, false);
    }).add(()=> {
      this.loadingService.hide(processId);
    });
  }

  mapAddChildUI(spouseFamilies: FamilyViewModel[]){ 
    if(spouseFamilies.length > 1){
      this.haveMultipleSpouseFamilies = true; 
      //set default text for spouse when spouse unavailable 
      spouseFamilies.map(p=> p.spouse.id === 0 ? p.spouse.displayName
        = "(" +(p.individual.gender === "M" ?  this.translateHandler.translate("wife") :this.translateHandler.translate("husband")) + ")"
        : p.spouse.displayName);
    }
    else {
      this.selectedFamilyId = spouseFamilies.length == 1 ? spouseFamilies[0].familyId : 0;
      this.haveMultipleSpouseFamilies = false;
    }  
  }

  mapAddSpouseUI(spouseFamilies: FamilyViewModel[]){ 
    this.childFamilies = spouseFamilies.filter(x=> x.spouse.id == 0 && x.children.length >= 1)
    if(this.childFamilies.length >= 1){
      this.haveMultipleFamiliesWithChild = true;       
    }
    else {
      this.selectedFamilyId =  0;
      this.haveMultipleFamiliesWithChild = false;
    }  
  }

  addMember() {
    let memberObj = {name: this.memberName, familyId:this.selectedFamilyId}
    this.checkMemberNameExist(memberObj);
  }

  openMemberEditor() {
    let memberObj = {name: this.memberName, familyId:this.selectedFamilyId, isMemberNavigate: true }
    this.checkMemberNameExist(memberObj);
  } 

  checkMemberNameExist(memberObj : any){
    if (this.isValid) {
      memberObj.name = memberObj.name.trim();
      this.individualApiService.checkMemberExist(memberObj.name).subscribe((response: any) => {
        if (!response.data) {
          this.isValid = true;
          this.complete(memberObj);          
        }
        else
          this.isValid = false;
      },
        err => {
          throw new CustomError("IndividualPromptComponent => checkMemberNameExist() : " + err, 911, false);
        });
    }
    else {
      this.complete(memberObj);  
    }
  }

  setEnablity() {
    let isEnabledName = (typeof(this.memberName) === 'undefined' || this.memberName === null || this.memberName === '') ? false : true;
    let isEnableFamily =    typeof(this.selectedFamilyId) === 'undefined'  ? false : true;   
    return (isEnabledName && isEnableFamily);
  } 

  cancel() {
    this.close();
  }

  showNotImplemented() {
    this.parentCommunicatorService.send(ParentMessageType.ShowInformation, "not_implemented");
  }
}
