import { Injectable } from '@angular/core';
import { type } from 'os';
import { Subject } from 'rxjs';
import { TooltipPosition } from 'src/app/common/enums/enums';
import { TooltipHeadPosition } from 'src/app/components/common/tooltip/models/tooltip-head-position';
import { TooltipInfo } from 'src/app/components/common/tooltip/models/tooltip-info';
import { TooltipTestContentComponent } from 'src/app/components/common/tooltip/tooltip-test-content/tooltip-test-content.component';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  
  onVisibilityChange: Subject<TooltipInfo> = new Subject<TooltipInfo>();
  
  constructor() { }

  show($event, tooltipPosition : TooltipHeadPosition, color:string, component: any, data: any){
    
    var tipInfo         = new TooltipInfo();
    tipInfo.isVisible   = true;
    tipInfo.position    = tooltipPosition;
    tipInfo.sourceEvent = $event;
    tipInfo.component   = component;
    tipInfo.data        = data;    
    tipInfo.color       = color;
    this.onVisibilityChange.next( tipInfo );
    
  }

  hide(){    
    this.onVisibilityChange.next(null);    
  }
}
