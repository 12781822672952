import { DropMenu } from '../../drop-menu/models/DropMenu';

export class MediaItem{
    id        : number;   
    caption   : string;   
    url       : string;
    dropMenu  : DropMenu;     
    reference? : any;  

    constructor(id, caption, url, dropMenu) {
        this.id = id;
        this.caption = caption;
        this.url = url;
        this.dropMenu = dropMenu
    }
}