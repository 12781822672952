import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';

@Component({
  selector: 'app-aboutus-main',
  templateUrl: './aboutus-main.component.html',
  styleUrls: ['./aboutus-main.component.scss']
})
export class AboutusMainComponent extends DialogBaseComponent implements OnInit {

  constructor(dialogService: DialogService,
    private ref1: ChangeDetectorRef,
    private translater: TranslateHandler) {
    super(dialogService, ref1);
  }

  ngOnInit(): void {
  }

  getViewMode(): Number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {
    this.data.viewMode = mode;
  }
}
