<div class="list-base-container">
    <!-- Menu options -->
    <div class="list-menu-contrainer" >
        <div class="list-menu">
            <!-- Add new -->
            <div>
                <img src="/assets/images/icons/add.svg" class="ft-btn-round-green-base" width="22" height="22">
                <span class="label-xxl-grey">Add new family tree</span>
            </div>
            <!-- GEDCOM -->
            <div>
                <img src="/assets/images/icons/add.svg" class="ft-btn-round-green-base" width="22" height="22">
                <span class="label-xxl-grey">Upload GECOM file</span>
            </div>
            <!-- V9 -->
            <div>
                <img src="/assets/images/icons/add.svg" class="ft-btn-round-green-base" width="22" height="22">
                <span class="label-xxl-grey">Upload V9 backup</span>
            </div>
        </div>
        <div style="width: 20%;">            
            <div class="ft-form-row" style="margin: 0;padding: 0;">
                <div class="ft-form-row col1-v100" style="margin: 0;padding: 0;">
                  <div class="ft-form-input">
                    <div class="icon-input-container">
                      <input class ="icon-input" placeholder="Search" type="text">
                      <div class="icon-input-wrap">                      
                        <img src="/assets/images/icons/search.svg"  width= "30px" height="30px">                       
                      </div>                  
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
    <!-- Listing -->
    <div class="list-lst-container" >
        <div class="list-row selected">
            <div>
                <div class="list-icon"></div>
            </div>
            <div style="width: 250px;display: flex;flex-direction: column;">
                <div class="label-m-grey">Oystein Haaland Family Tree</div>
                <div class="label-s-grey text-ellipsis-1">Gedcom</div>
                <div class="label-xs-grey">20.10.2020 21:12</div>
            </div>
            <div class="list-tag-container">
                <div class="list-tag label-xs-grey">128 People</div>
                <div class="list-tag label-xs-grey">128 Places</div>
                <div class="list-tag label-xs-grey">128 Events</div>
                <div class="list-tag label-xs-grey">128 Sources</div>
                <div class="list-tag label-xs-grey">128 Source citations</div>
                <div class="list-tag label-xs-grey">128 Multimedia</div>
                <div class="list-tag label-xs-grey">128 Families</div>
            </div>
            <div class="list-options-container">                
                <div style="position: relative;width: 23px; height: 23px;">
                    <app-drop-menu [menu]="dropMenu"></app-drop-menu>                                
                </div>
                <div><img src="assets/images/icons/photo-selected.svg" class="selected-icon"></div>
            </div>
        </div>
       
        <div class="list-row">
            <div>
                <div style="width: 48px;height: 48px;background-color:#e6eed7 ;"></div>
            </div>
            <div style="width: 250px;display: flex;flex-direction: column;">
                <div class="label-m-grey">Oystein Haaland Family Tree</div>
                <div class="label-s-grey text-ellipsis-1">Gedcom</div>
                <div class="label-xs-grey">20.10.2020 21:12</div>
            </div>
            <div class="list-tag-container">
                <div class="list-tag label-xs-grey">128 People</div>
                <div class="list-tag label-xs-grey">128 Places</div>
                <div class="list-tag label-xs-grey">128 Events</div>
                <div class="list-tag label-xs-grey">128 Sources</div>
                <div class="list-tag label-xs-grey">128 Source citations</div>
                <div class="list-tag label-xs-grey">128 Multimedia</div>
                <div class="list-tag label-xs-grey">128 Families</div>
            </div>
            <div style="width: 100px;display: flex;flex-direction: row-reverse;align-items:center;justify-content: right;gap: 5px;">                
                <div style="position: relative;width: 23px; height: 23px;">
                    <app-drop-menu [menu]="dropMenu"></app-drop-menu>                                
                </div>
                <div></div>
            </div>
        </div>

        <div class="list-row">
            <div>
                <div style="width: 48px;height: 48px;background-color:#e6eed7 ;"></div>
            </div>
            <div style="width: 250px;display: flex;flex-direction: column;">
                <div class="label-m-grey">Oystein Haaland Family Tree</div>
                <div class="label-s-grey text-ellipsis-1">Gedcom</div>
                <div class="label-xs-grey">20.10.2020 21:12</div>
            </div>
            <div class="list-tag-container">
                <div class="list-tag label-xs-grey">128 People</div>
                <div class="list-tag label-xs-grey">128 Places</div>
                <div class="list-tag label-xs-grey">128 Events</div>
                <div class="list-tag label-xs-grey">128 Sources</div>
                <div class="list-tag label-xs-grey">128 Source citations</div>
                <div class="list-tag label-xs-grey">128 Multimedia</div>
                <div class="list-tag label-xs-grey">128 Families</div>
            </div>
            <div style="width: 100px;display: flex;flex-direction: row-reverse;align-items:center;justify-content: right;gap: 5px;">                
                <div style="position: relative;width: 23px; height: 23px;">
                    <app-drop-menu [menu]="dropMenu"></app-drop-menu>                                
                </div>                
            </div>
        </div>
        
    </div>
</div>