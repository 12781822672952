import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FamilyProjectArchiveApiService {

  constructor(private apiBaseService: ApiBaseService) { }

  getFamilyProjectArchiveId<T>(projectId : string): Observable<T> {
    return this.apiBaseService.get("/api/familyProjectArchive/"+projectId, [], null);
  } 

  getFamilyProjectArchivesbyIds<T>(projectId : string): Observable<T> {
    return this.apiBaseService.get("/api/familyProjectArchive/"+projectId, [], null);
  } 

  createFamilyProjectArchive(data: any): Observable<any> {   
    return this.apiBaseService.create('/api/familyProjectArchive', null, data);
  }
}
