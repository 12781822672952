import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aboutus-embla',
  templateUrl: './aboutus-embla.component.html',
  styleUrls: ['./aboutus-embla.component.scss']
})
export class AboutusEmblaComponent implements OnInit {

  appVersion = environment.VERSION;

  constructor(
    private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  ok() {
    this.dialogService.complete({ data: null });
  }

}
