import { TimelineConfigModel } from "src/app/common/models/report/timelinereport/timeline-config-model";
import { TimelineYearModel } from "src/app/common/models/report/timelinereport/timeline-year-model";

export class YearGraphics {
  public static get(textValue: string, config: TimelineConfigModel['year']): TimelineYearModel {
    let yearGraphics: TimelineYearModel = {
      xPosition: 0,
      yPosition: 0,
      img: config.img,
      text: {
        xPosition: 0,
        yPosition: 0,
        textContent: textValue,
        fontSizeValue: config.text.fontSizeValue,
        fontFamilyValue: config.text.fontFamilyValue,
        fontWeightValue: config.text.fontWeightValue,
        fillValue: config.text.fillValue,
        offsetXPosition: config.text.offsetXPosition,
        offsetYPosition: config.text.offsetYPosition,
        anchorValue: config.text.anchorValue,
      },
      offsetXPosition: config.offsetXPosition,
      offsetYPosition: config.offsetYPosition
    };
    return yearGraphics;
  }
}
