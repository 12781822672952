export class SourceBase {
    id: number;
    title: string;
    publication : string;
    author : string;
    repository : string;
    notes : string;
    sourceType : number;
    transcript : string;
    period  : string;
    mediaUrl : string;
}