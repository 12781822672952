import { Injectable } from "@angular/core";
import {HttpClient,HttpParams,} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { map } from "rxjs/operators";
import { GeocodeResponseModel } from "../common/models/geocode/geocode-response-model";

@Injectable({
  providedIn: "root",
})
export class MapboxService {
  mapboxConfig = environment.GEOCODE;

  constructor(
    private httpClient: HttpClient
  ) {}

  /**
   * Fetch geocodes from mapbox geocoding API.
   * @param address which need to geocode.
   */
  getGeocode(address: string): Observable<GeocodeResponseModel> {
    let url = `${this.mapboxConfig.url}${address}${this.mapboxConfig.dataFormat}`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('access_token', this.mapboxConfig.mapboxAccessToken);
    return this.httpClient.get<any>(url, { params: queryParams }).pipe(
      map(response => this.mapGeocodeResponse(response))
    );
  }

  private mapGeocodeResponse(response: any): GeocodeResponseModel {
    const geocodeResponse: GeocodeResponseModel = {
      data: {
        address             : response.features?.[0]?.place_name,
        lat                 : response.features?.[0]?.center?.[1],
        lng                 : response.features?.[0]?.center?.[0],
        isMultiplePlaces    : response.features?.[0]?.context?.length > 1,
        geocodeApiResponse  : response
      }
    };
    return geocodeResponse;
  }
}
