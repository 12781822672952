import { ImageLazyLoadModule } from './../../../common/directives/image-lazy-load/image-lazy-load.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainlazyloadingComponent } from './mainlazyloading/mainlazyloading.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';



@NgModule({
  exports:[MainlazyloadingComponent],
  declarations: [MainlazyloadingComponent],
  imports: [
    CommonModule,
    FormsModule,
    ImageLazyLoadModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})
export class LazyloadingModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
