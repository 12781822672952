<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_program_setup" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">
            <div class="tabs">
                <a *ngIf="getViewMode() == 1" [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{"lbl_project_settings" | translate}}</a>
                <a *ngIf="getViewMode() == 2" [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">{{"lbl_global_settings" | translate}}</a>
            </div>
            <div class="tab-forms app-light-bg">                
                <app-preferences-option *ngIf="getViewMode() === 1" [data]="data"></app-preferences-option>
                <app-preferences-option *ngIf="getViewMode() === 2" [data]="data"></app-preferences-option>
            </div>            
    </div>
</div>