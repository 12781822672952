import { Preference } from '../../models/Preference';
import { FTDate } from '../../models/FTDate';
import { GenealogyDate } from './genealogy-date';
import { GenealogyDateRange } from './genealogy-date-range';
import { GeneologyDatePatterns } from './genealogy-date-patterns';
export class GenealogyDateFormatter {

    preference: any;
    culture: string;
    static prefix: string[];
    static months: string[];
    static month_no: string[];
    monthWithCulture: string;
    prefixWithCulture: any;

    constructor(preference: Preference, culture: string) {
        this.culture = culture;
        this.preference = preference;
        this.monthWithCulture = GeneologyDatePatterns.longMonths[culture];
        this.prefixWithCulture = GeneologyDatePatterns.prefix[culture];
    }

    public format(genDate: GenealogyDateRange | GenealogyDate): string {      
        let formattedDate: string;

        if (genDate instanceof GenealogyDateRange) {
            //formattedDate.GenealogyDateRange = genDate;
            if (genDate.from && genDate.to)
                formattedDate = this.fromatDateRange(genDate);          
        }
        if (genDate instanceof GenealogyDate) {
            if (genDate.year || genDate.month)
                formattedDate = this.fromatSingleDate(genDate);
        }

        return formattedDate;
    }

    private fromatSingleDate(genDate: GenealogyDate) {
        let formattedDate: string;
        let formattedDay: string;
        let formattedMonth: string;
        let formattedYear: string;

        if (genDate.day) {
            formattedDay = genDate.day.toString();
            if (this.preference.twoDigitNumeric)
                formattedDay = this.setTwoDigit(genDate.day);
            if (this.preference.dateType == "norwegian")
                formattedDay = formattedDay + "."; //2023.02.08 - changed due to "two digit norwegian" date format error
        }

        if (genDate.month) {
            formattedMonth = this.formatMonth(genDate);
        }

        if (genDate.year) {
            formattedYear = genDate.year.toString();
        }

        let prefix: string;
        if (genDate.approxStr)
            prefix = this.datePrefix(genDate.approxStr);


        formattedDate = this.concatDate(prefix, formattedDay, formattedMonth, formattedYear)

        return formattedDate;
    }

    // Review: Avoid If Conditions.
    // Suggestion: Get Norwegian
    // Review: Date constants get from a central place (can have the translations)
    private datePrefix(prefix) {
        prefix = prefix.toLowerCase().trim();
        let approxStrAsCulture = this.prefixWithCulture.find(item => { return (item["text"] === prefix || item["type"] === prefix) })
        return approxStrAsCulture ? approxStrAsCulture.value : "";
    }

    private concatDate(prefix, day?, month?, year?) {
        let separator = this.preference.dateSeperater;
        let txtDate = "";
        let dateArray = new Array();

        if (this.preference.dateType != "numeric" && this.preference.monthFirst && month && day) {
            dateArray.push(month);
            dateArray.push(day);
            if (year)
                dateArray.push(year);

            txtDate = dateArray.join(separator);
        }
        else {
            if (day)
                dateArray.push(day);
            if (month)
                dateArray.push(month);
            if (year)
                dateArray.push(year);

            txtDate = dateArray.join(separator);
        }

        return prefix ? prefix + " " + txtDate : txtDate;
    }

    private formatMonth(genDate) {
        let formattedMonth;
        switch (this.preference.dateType) {
            case "numeric":
                if (this.preference.twoDigitNumeric)
                    formattedMonth = this.setTwoDigit(genDate.month);
                else
                    formattedMonth = genDate.month.toString();
                break;
            case "short":
                formattedMonth = this.monthWithCulture[genDate.month - 1].slice(0, 3);
                break;
            default:
                formattedMonth = this.monthWithCulture[genDate.month - 1];
                break;
        }

        if (this.preference.showCapitalDate)
            formattedMonth = formattedMonth.toUpperCase();

        return formattedMonth;
    }

    private setTwoDigit(value) {
        return (value < 10) ? ("0" + value) : value.toString();
    }

    public fromatDateRange(genealogyDateRange: GenealogyDateRange): string {
        //renges with 
        let range: string;
        let rangeText: any;

        if (genealogyDateRange.rangeType === 1) {
            let formatFromDate = genealogyDateRange.from.month ? this.formatMonth(genealogyDateRange.from) + this.preference.dateSeperater + genealogyDateRange.from.year : genealogyDateRange.from.year;
            let formatToDate = genealogyDateRange.to.year.toString().slice(2, 4);
            range = formatFromDate + "/" + formatToDate;
        }
        if (genealogyDateRange.rangeType === 2) {
            let formatFromDate = this.fromatSingleDate(genealogyDateRange.from);
            let formatToDate = this.fromatSingleDate(genealogyDateRange.to);

            if (formatFromDate && formatToDate) {
                let prefix;
                if (genealogyDateRange.rangeStr)
                    prefix = this.datePrefix(genealogyDateRange.rangeStr);

                if (prefix) {
                    rangeText = prefix.toString().split(",");
                    range = rangeText[0] + " " + formatFromDate + " " + rangeText[1] + " " + formatToDate;
                }
                else {
                    prefix = this.datePrefix("from");
                    rangeText = prefix.toString().split(",");
                    range = rangeText[0] + " " + formatFromDate + " " + rangeText[1] + " " + formatToDate;
                }
            }
        }
        //dates with suffix
        return range;
    }


}