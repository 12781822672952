import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MessagePromptComponent } from '../message-prompt/message-prompt.component';
import { TranslateService } from '@ngx-translate/core';
import { IndividualPromptComponent } from 'src/app/components/individualeditor/individual-prompt/individual-prompt.component';
import { RelationShipType } from 'src/app/common/enums/enums';
import { MessagePromptStripeComponent } from '../message-prompt-stripe/message-prompt-stripe.component';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  //TODO: Translations
  constructor(private translateService: TranslateService) { }

  onOpen: Subject<any> = new Subject<any>();
  onClose: Subject<any> = new Subject<any>();

  open(request: any) {
    this.onOpen.next(request);
    return this.onClose.pipe(first());
  }

  openIndividualDialog(title: string, subTitle: string, familyId: number,memberId: number, relationshipType: RelationShipType) {
    return this.open(
      {
        class: IndividualPromptComponent,
        data: {
          title: title,
          subTitle: subTitle,
          familyId: familyId,
          memberId: memberId,
          relationshipType: relationshipType
        }
      });
  }

  openDialog(title: string, info: string, prompt: string, successButton: string, cancelButton: string, successButtonStyle?: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: title,
          info: info,
          prompt: prompt,
          okText: successButton,
          cancelText: cancelButton,
          okStyle: successButtonStyle
        }
      });
  }

  openDeleteConfirmation(info: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: this.translateService.instant("lbl_delete_confiramation_header"),
          info: info,
          prompt: this.translateService.instant("cnf_delete_confirmation"),
          okText: this.translateService.instant("lbl_delete"),
          cancelText: this.translateService.instant("lbl_cancel"),
          okStyle: "active-red"
        }
      });
  }

  openExistingNameConfirmation(info: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: this.translateService.instant("lbl_add_confiramation_header"),
          info: info,
          prompt: this.translateService.instant("cnf_confirmation"),
          okText: this.translateService.instant("lbl_ok"),
          cancelText: this.translateService.instant("lbl_cancel"),
          okStyle: "active-red"
        }
      });
  }

  openUnlinkfirmation(info: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: this.translateService.instant("lbl_unlink_confirmation_header"),
          info: info,
          prompt: this.translateService.instant("cnf_unlink_confirmation"),
          okText: this.translateService.instant("lbl_ok"),
          cancelText: this.translateService.instant("lbl_cancel"),
          okStyle: "active-red"
        }
      });
  }

  openInfo(title: string, info: string, prompt: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: title,
          info: info,
          prompt: prompt,
          okText: this.translateService.instant("lbl_ok"),
          cancelText: this.translateService.instant("lbl_cancel")
        }
      });
  }
  
  openMessage(title: string, info: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: title,
          info: info,
        }
      });
  }

  openWarn(title: string, info: string, prompt: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: title,
          info: info,
          prompt: prompt,
          okText: this.translateService.instant("lbl_ok"),        
        }
      });
  }

  openError(title: string, info: string, prompt: string) {
    return this.open(
      {
        class: MessagePromptComponent,
        data: {
          title: title,
          info: info,
          prompt: prompt,
          okText: this.translateService.instant("lbl_ok"),
          cancelText: null,
          okStyle: "active-red",
          titleStyle: "red"
        }
      });
  }

  openUnSavedChangeConfirmation(info: string) {
    return this.open({
      class: MessagePromptComponent,
      data: {
        title: this.translateService.instant("lbl_unsaved_changes_confirmation_header"),
        info: info,
        prompt: this.translateService.instant("lbl_unsaved_changes_confirmation_footer"),
        okText: this.translateService.instant("lbl_ok"),
        cancelText: this.translateService.instant("lbl_cancel"),
        okStyle: "active-red"
      }
    });
  }

  openUnSupportedBrowserWarning() {
    return this.open({
      class: MessagePromptStripeComponent,
      data: {         
        info: this.translateService.instant("lbl_unsupported_browser"), 
        downloadText: this.translateService.instant("lbl_download") + ' Chrome',
        dashboardText: this.translateService.instant("lbl_continue"),
        isStripePopup: true
      }
    });
  }

  openUnSupportedResolutionWarning() {
    return this.open(
      {
        class: MessagePromptStripeComponent,
        data: {         
          info: this.translateService.instant("lbl_unsupported_resolution"),     
          dashboardText: this.translateService.instant("lbl_continue"),          
          isStripePopup: true
        }
      });
  }

  complete(response: any) {
    this.onClose.next(response);
  }

  close() {
    this.onClose.next();
  }
}

