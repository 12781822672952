import { Injectable } from '@angular/core';
import { observable, Observable, of } from 'rxjs';
import { CustomApiParameters } from 'src/app/common/helpers/custom-api-parameters';
import { LinkRequest } from 'src/app/common/models/LinkRequest';
import { ApiBaseService } from '../api-base.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { IndividualRequestModel } from 'src/app/common/models/individual/individual-request-model';
import { IndividualComponentModel } from 'src/app/components/individualeditor/models/individual-component-model';
import { MultiMediaBasicInfo } from 'src/app/common/models/MultiMediaBasicInfo';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { ReferenceType } from 'src/app/common/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class IndividualApiService {

  constructor(private apiBaseService: ApiBaseService, private customApiParameters: CustomApiParameters) { }

  public getFamilyMemberDetail<T>(maxLevel?: number, IgnoreNameSettings?: boolean): Observable<T> {
    let customParaList = this.setCustomParameters(maxLevel, IgnoreNameSettings);
    return this.apiBaseService.get('/api/individual/' + this.apiBaseService.getRootMemberId() + '/family', customParaList, null); // add url to config file
  }

  // Todo: Remove the above method and use the following.
  public getRootMemberDetail<T>(memberId: number, maxLevel?: number, IgnoreNameSettings?: boolean): Observable<T> {
    let customParaList = this.setCustomParameters(maxLevel, IgnoreNameSettings);
    return this.apiBaseService.get('/api/individual/' + memberId + '/root-member', customParaList, null); // add url to config file
  }

  public updateMultimediaLink<T>(linkRequest: LinkRequest, memberId: number): Observable<T> {
    return this.apiBaseService.update('/api/individual/' + memberId + '/multimedia', null, linkRequest); // add url to config file
  }

  getAscendantsList<T>(customParaList, memberId): Observable<T> {
    return this.apiBaseService.get("/api/Individual/" + memberId + "/ancestors", customParaList, null); // add url to config file
  }
 

  getMember<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get('/api/individual/' + memberId, null, null);
  }

  getIndividualEvents(memberId: number): Observable<any[]> {
    return this.apiBaseService.get('/api/individual/' + memberId + '/events', null, null);
  }

  private setCustomParameters(maxLevel: number = 3, IgnoreNameSettings: boolean = true) {
    this.customApiParameters.clearAllParams();
    let customParaList = this.customApiParameters.addCustomParameter("MaxLevel", maxLevel.toString())
      .addCustomParameter("IgnoreNameSettings", IgnoreNameSettings.toString())
      .getCustomParaList();

    return customParaList;
  }
  getReferencedMultimedias<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get('/api/individual/' + memberId + '/multimedia', null, null);
  }

  public unlinkMultimediaLink(mediaId: number, memberId: number): Observable<any> {
    return this.apiBaseService.delete('/api/individual/' + memberId + '/multimedia/' + mediaId, null, mediaId); // add url to config file
  }

  getIndividualById<T>(individualId : number) : Observable<T> {
    return this.apiBaseService.get("/api/individual/" + individualId, null, null);
  }

  updateIndividual(individual: IndividualComponentModel): Observable<any> {
    return this.apiBaseService.update('/api/individual', null, individual);
  }

  getIndividualList<T>(size: number, page: number): Observable<T> {
    return this.apiBaseService.get("/api/individual", [], null);
  }

  getIndividualBaseInfo<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get("/api/individual/" + memberId + "/base", null, null);
  }

  getFirstPerson<T>(): Observable<T> {
    return this.apiBaseService.get("/api/individual/firstperson", [], null);
  }

  search<T>(namePattern): Observable<T> {
    return this.apiBaseService.get("/api/individual/search/"+namePattern, [], null);
  }

  getReferenceCount<T>(individualId : number): Observable<T> {
    return this.apiBaseService.get("/api/individual/" + individualId + "/references", [], null);
  }

  getAllAscendantsList<T>(ancestorLevels,memberId): Observable<T> {    
    return this.apiBaseService.get("/api/Individual/" + memberId + "/ancestors/" + ancestorLevels, null, null); // add url to config file
  }
  
  createParent<T>(data: IndividualRequestModel): Observable<T> {
    return this.apiBaseService.create('/api/individual/parent', null, data);
  }

  // Here the Endpoint gets the name from query inorder to accept the special/ reserved characters (*, %)
  // https://blogs.iis.net/nazim/use-of-special-characters-like-in-an-iis-url (In query strings the special characters aren't validated)
  checkMemberExist<T>(memberName: string, individualId: number = 0): Observable<T> {   
    return this.apiBaseService.get('/api/individual/member-exist/' + individualId + '?name=' + memberName, null, null);
  }

  createSibling(data: IndividualRequestModel): Observable<any> {
    return this.apiBaseService.create('/api/individual/sibling', null, data);
  }

  createChild(data: IndividualRequestModel): Observable<any> {
    return this.apiBaseService.create('/api/individual/child', null, data);
  }

  createSpouse(data: IndividualRequestModel): Observable<any> {
    return this.apiBaseService.create('/api/individual/spouse', null, data);
  }

  deleteIndividual(individualId : number): Observable<any> {
    return this.apiBaseService.delete('/api/individual/' + individualId, null,individualId);
  }

  updateProFileImage(individualId : number, basicInfo : Multimedia){
    return this.apiBaseService.update("/api/individual/" + individualId + "/profileImage", null, basicInfo);
  }

  getDescendantsList<T>(rootMemberId, descendantLevels): Observable<T> {    
    return this.apiBaseService.get("/api/Individual/" + rootMemberId + "/descendants/" + descendantLevels, null, null);
  }

}
