<div class="toolip-content" style="width: 345px;">
    <div class="tooltip-row">
        <div class="tooltip-column">       
            <img src="/assets/multimedia-module/icon-tooltip.png" class = "tooltip-icon">
            <span class="tooltip-heading-text">
                {{data.title | translate}}
            </span>
        </div>
        <span *ngIf="data.invalidMessage" class="tooltip-content-text" style = "padding-bottom: 3%;">
            {{data.invalidMessage | translate}}
        </span>
        <span class="tooltip-content-text">
            {{data.info | translate}}
        </span>   
    </div>
</div>