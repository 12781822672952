import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { ErrorData } from '../common/models/ErrorData';
import { error } from 'protractor';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        tap((event) => {
          if (event instanceof HttpResponse && event.status === 200 && event.body.StatusCode > 0) {
            // throwError("event.body");
            throw new HttpErrorResponse({
              error: 'CustomError',
              headers: event.headers,
              status: event.body.StatusCode,
              statusText: event.body.StatusMessage,
              url: event.url
            });
          }         
        }),
        catchError(error => {
           if(error instanceof HttpErrorResponse && error.status === 401) {
              return next.handle(request);
          }
          else{
            return throwError(error);
          }
        })
      );
  }
}
