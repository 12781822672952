import { Component, OnInit } from '@angular/core';
import { NotifierEvents } from 'src/app/common/enums/enums';
import { HistoryService } from 'src/app/services/history.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { environment } from 'src/environments/environment';
import { ComponentBase } from '../../common/base/component-base';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends ComponentBase implements OnInit {
  
  // listing updates
  expectedUpdates = [NotifierEvents.RootMemberChanged, NotifierEvents.RootMemberUpdated];
  
  breadCrumbList: any[] = [];   //DisplayName, FirstName, LastName Id 
  constructor(private historyService: HistoryService,
              notifierService: NotifierV2Service) { 
    super(notifierService);
  }

  ngOnInit(): void {
    //this.breadCrumbList = this.historyService.getIndividualList(environment.BREADCRUMB_SIZE + 1);    
    this.fill();
  }

  notify(){
    //this.breadCrumbList = this.historyService.getIndividualList(environment.BREADCRUMB_SIZE + 1);
    this.fill();
  }

  changeRootPerson(individual) {
    if ( individual.Id == -1 ) return;
    // console.log(individual);
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, individual);
  }

  fill(){
    this.breadCrumbList = [];
    let indList = this.historyService.getIndividualList(environment.BREADCRUMB_SIZE);
    indList.forEach( i =>{      
      this.breadCrumbList.push(i);
    })
  }
}
