<div class="ft-form" #eventFormContainer [class.form-inactive] = "disableForm"   style="padding-bottom: 0px !important;">
    <div class="content">
        <form class="form" id="eventModel" #f="ngForm">
            <fieldset [disabled] = "disableForm">
                <!-- form title -->
                <div class="ft-form-row">
                    <span class="title">{{formTitle}}</span>
                </div>

                <div class="ft-form-row col2-vf">
                    <div class="border-outline">
                        <!-- Event images carousel -->
                        <app-image-carousel-temp     
                            [inImages]="getInImages()" 
                            [defaultImage]="defaultCarouselImage"
                            (action)="carouselActionClicked($event)">
                        </app-image-carousel-temp>
                    </div>
                    <div>
                        <div class="ft-form-row col1-v100 event-type">
                            <!-- event type -->
                            <div class="ft-form-input">
                                <label>{{"lbl_event_type"| translate}}</label>
                                <select [attr.disabled]="data.eventId > 0 ? true : null"  [(ngModel)]="selectedEventType" [ngModelOptions]="{standalone: true}" (change)="selectEventType()">
                                    <option value="" disabled selected>{{'lbl_select_event_type'|translate}}</option>
                                    <option *ngFor="let event of eventDropdownList" [ngValue]="event">
                                        <span *ngIf="isEditorMode">{{eventNameText}}</span>
                                        <span *ngIf="!isEditorMode" >{{'event_' + event   | lowercase | translate}}</span>                                
                                    </option>         
                                </select>
                            </div>
                        </div>  
                        <div class="ft-form-row col2-vf event-place-date">
                            <div class="ft-form-input">
                                <label> {{"lbl_date" | translate}}</label>                
                                <app-date-input-temp [inDate]="eventModel?.date" (outDate)=setFormattedDate($event)></app-date-input-temp>
                            
                            </div>
                            <!--Place component -->
                            <div class="ft-form-input">
                                <label>{{"lbl_place" | translate}}</label>
                                <app-place-input [inPlace]="eventModel?.place" (placeChange)=setPlaceForEvent($event) [title] = "getPlacePopupTitle()" [isFieldEnabled]="disableForm"></app-place-input>
                            </div>
                        </div>           
                    </div>
                </div>

                <!-- event date and place -->
                <div class="ft-form-row col2-vf">
                
                </div>

                <!-- Note description -->
                <div class="ft-form-row col2-v50v50">

                    <div class="ft-form-input" >
                        <label>{{"lbl_note"| translate}}</label>
                        <textarea type="textarea" style="height: 50px;" [(ngModel)]="eventModel.note" name="note" #note="ngModel"></textarea>               
                    </div>

                    <div class="ft-form-input">
                        <label>{{"lbl_description"| translate}}</label>
                        <textarea type="textarea" style="height: 50px;"  [(ngModel)]="eventModel.description" name="description" #description="ngModel"></textarea>
                    </div>

                </div>

                <div class="ft-form-divider">
                </div>
        
                <div class="ft-form-row col2-v50v50">
                    <!-- multimedia -->
                    <div class="ft-form-input" style="width: 49%;" >
                        <label class="ft-img-label">                    
                            <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" (click)="openMediaEditor(0,1)" class="ft-btn-round-green-base" width="22" height="22" style="cursor: pointer;">
                        {{"lbl_add_multimedia" | translate}}                  
                        </label>
                        <div class="no-record" style="height:70px;" *ngIf="eventModel?.multimedia?.length == 0">{{"err_no_multimedia_added" | translate}}</div>
                        <app-media-carousel *ngIf="eventModel?.multimedia?.length > 0" [items] = "mediaItems"  (action)="mediaCarouselActionClicked($event)"></app-media-carousel>
                    </div>
                    <!-- source -->
                    <div class="ft-form-input" style="width: 49%;">
                        <label class="ft-img-label" >                    
                            <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm"(click)="addSource()" class="ft-btn-round-green-base" width="22" height="22" style="cursor: pointer;">
                            {{"lbl_add_source_citation" | translate}}                    
                        </label>
                        <div class="no-record" style="height:70px;" *ngIf="eventModel?.sourceCitations?.length == 0">{{"err_no_source_added" | translate}}</div>
                        <app-media-carousel *ngIf="eventModel?.sourceCitations?.length > 0" [items] = "sourceItems"  (action)="sourceCarouselActionClicked($event)"></app-media-carousel>
                    </div>
                </div>

                <div class="ft-form-divider"
                    style="border-bottom:1px solid #ebebea; width: 100%;height: 15px;margin-bottom: 15px;">
                </div>

                <!-- Witnesses -->
                <div class="ft-form-row col1-v100">
                    <div class="ft-form-input" >
                        <label class="ft-img-label">                    
                            <img src="/assets/images/icons/add.svg" [class.btn-inactive] = "disableForm" (click)="addWitness()" class="ft-btn-round-green-base" width="22" height="22">
                            {{"lbl_add_witness" | translate}}                
                        </label>
                        <div class="no-record" style="height:70px;">{{"err_no_witness_added" | translate}}</div>
                        <div class="scrollable-container scroll-col4" style="display: none;">
                            <div>
                                <img src="/assets/images/icons/arrow-left-grey.svg">
                            </div>
                            <div class="scrollable1xN" id="scroller">
                                <div class="item">
                                    <div class="item-container">
                                        <div class="item-content">
                                            <div>
                                                <img src="/assets/images/events/item-pic.png" width="45" height="45"
                                                    style="display: block;">
                                            </div>
                                            <div class="scroll-caption text-ellipsis-2"> Lorem ipsum dolor sit amet jksd jshjd jsk sd jslkd ksd </div>
                                        </div>
                                        <div class="item-menu">
                                            <app-drop-menu></app-drop-menu>
                                        </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="item-container">
                                        <div class="item-content">
                                            <div>
                                                <img src="/assets/images/events/item-pic.png" width="45" height="45"
                                                    style="display: block;">
                                            </div>
                                            <div class="scroll-caption"> 1 Lorem ipsum dolor sit amet</div>
                                        </div>
                                        <div class="item-menu">
                                            <app-drop-menu></app-drop-menu>
                                        </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="item-container">
                                        <div class="item-content">
                                            <div>
                                                <img src="/assets/images/events/item-pic.png" width="45" height="45"
                                                    style="display: block;">
                                            </div>
                                            <div class="scroll-caption"> 2 Lorem ipsum dolor sit amet</div>
                                        </div>
                                        <div class="item-menu">
                                            <app-drop-menu></app-drop-menu>
                                        </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="item-container">
                                        <div class="item-content">
                                            <div>
                                                <img src="/assets/images/events/item-pic.png" width="45" height="45"
                                                    style="display: block;">
                                            </div>
                                            <div class="scroll-caption"> 3 Lorem ipsum dolor sit amet</div>
                                        </div>
                                        <div class="item-menu">
                                            <app-drop-menu></app-drop-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/images/right_arrow.png">
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
    <div class="footer">
        <!-- [ngClass]="{'ft-btn-inactive' : defaultView, 'ft-btn-active' : !defaultView}" -->
        <button [disabled]="disableForm || defaultView"class="ft-btn"  [ngClass]="{'ft-btn-active':(!disableForm && !defaultView),'ft-btn-inactive':(disableForm || defaultView)}"(click)="saveEvent()">{{"lbl_save" | translate}}</button>
        <button [disabled]="disableForm" class="ft-btn" [ngClass]="{'ft-btn-active-not-selected':!disableForm,'ft-btn-inactive':disableForm}"(click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>