import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';

@Injectable()
export class LoadingService {  
  
  private loaderTimeout = environment.LOADER_TIMEOUT_MINUTES  
  private state : Subject<boolean> = new Subject<boolean>();    // "Loading" state, state = true on screen state = false hidden

  constructor() {
    
  }

  public show(processId: string) {
    if( !processId ){
      processId = GuidGenerator.generate();
    }
    this.state.next(true);
    return processId;
  }

  public hide(loadingProcessId: string) {
    this.state.next(false);
  }

  public getState() : Observable<boolean>{
    return this.state;
  }
  
  private waitForMinutes() {
    /*
    setTimeout(() => {
      if (this.loadingEnabled) {
        this.parentCommunicatorService.send(ParentMessageType.ShowDataLoadingContinue, true)
      }
    }, this.loaderTimeout * 60 * 1000);//180000 = 3min    */
  }
}
