import { ArtisticTreeNode } from '../../artistictree/artistictree/artistic-tree-node';
import { BasicNode } from './basic-node';
import { TreeNode } from './tree-node';

export class TreeHelper {
    
    //static nodeSize        = 50;
    //static treeDistance    = 50;  // have to be greater than node size // both these have to be the same if equal space at the end level
    //static siblingDistance = 50;  // have to be greater than node size

    static levelConfig;
    static init(levelConfig){
        this.levelConfig = levelConfig;
    }

    /**
     * Generate tree nodes from list of type T
     * @param list ( { parentId , id})
     */

    static generateNodesFromList<T>(cls ,list): TreeNode<T>{
        let nodeList = []
        
        if (cls = "ArtisticTreeNode"){
            nodeList = ArtisticTreeNode.getNodes(list);
        }

        var root = list.find(e => e.parentId == 0);
        var rootTreeNode = new TreeNode<T>(root, null);
        rootTreeNode.children = this.getChildNodes(nodeList, rootTreeNode);
        return rootTreeNode;
    }

   // Generate child trees
   static getChildNodes<T extends BasicNode>(data: T[], parent: TreeNode<T>) {
        var childrenNodes: TreeNode<T>[] = [];
        var children = data.filter(e => e.parentId == parent.item.id);
        for (var x = 0; x < children.length; x++) {
            var treeNode = new TreeNode<T>(children[x], parent);
            treeNode.children = this.getChildNodes(data, treeNode);
            childrenNodes.push(treeNode);
        }
        return childrenNodes;
    }


    // recusrively initialize x, y, and mod values of nodes
    static initializeNodes<T>(node: TreeNode<T> , depth:number)
    {
        node.x   = -1;
        node.y   = depth;
        node.level = depth;
        node.mod = 0;
        node.children.forEach(child=>{
            TreeHelper.initializeNodes(child, depth + 1);
        })  
    }

    static calculateInitalX<T>(node:TreeNode<T>){
        // Update for all children !Important - Children have to be updated before this node
        node.children.forEach(child =>{
            TreeHelper.calculateInitalX(child);
        })


        // For this node .....
        // if no children        
        if ( node.isLeaf() ){

            if ( node.isLeftMost() ){
                node.x = 0;
            }else{
                node.x = node.getPreviousSibling().x + TreeHelper.levelConfig[node.y].nodeSize + TreeHelper.levelConfig[node.y].siblingDistance
            }
        // If only one child
        }else if ( node.children.length == 1){
            // if this is the first node in a set, set it's X value equal to it's child's X value
            if (node.isLeftMost())
            {
                node.x = node.children[0].x;
            }
            else
            {
                node.x = node.getPreviousSibling().x + TreeHelper.levelConfig[node.y].nodeSize + TreeHelper.levelConfig[node.y].siblingDistance;
                node.mod = node.x - node.children[0].x;
            } 
        // all other cases
        }else{
            var leftChild = node.getLeftMostChild();
            var rightChild = node.getRightMostChild();
            var mid = (leftChild.x + rightChild.x) / 2;

            if (node.isLeftMost())
            {
                node.x = mid;
            }
            else
            {
                node.x = node.getPreviousSibling().x + TreeHelper.levelConfig[node.y].nodeSize + TreeHelper.levelConfig[node.y].siblingDistance;
                node.mod = node.x - mid;
            }
        }
        
        if (node.children.length > 0 && !node.isLeftMost())
            {
                // Since subtrees can overlap, check for conflicts and shift tree right if needed                
                TreeHelper.checkForConflicts(node);
            }
    }

    static checkForConflicts<T>(node :TreeNode<T>)
    {
        var minDistance = TreeHelper.levelConfig[node.y].treeDistance + TreeHelper.levelConfig[node.y].nodeSize;
        var shiftValue = 0;
 
        var nodeContour = [];
        TreeHelper.getLeftContour(node, 0, nodeContour);
 
        var sibling = node.getLeftMostSibling();
        
        while (sibling != null && sibling != node)
        {
            var siblingContour = [];
            TreeHelper.getRightContour(sibling, 0, siblingContour);
 
            var skeys = siblingContour.keys();
            var smax  = Math.max(...Array.from(skeys));
            var nkeys = nodeContour.keys();
            var nmax  = Math.max(...Array.from(nkeys));
            
            for (var level = node.y + 1; level <= Math.min( smax , nmax ); level++)
            {
                var distance = nodeContour[level] - siblingContour[level];
                if (distance + shiftValue < minDistance)
                {
                    shiftValue = minDistance - distance;
                }
            }
 
            if (shiftValue > 0)
            {
                node.x   += shiftValue;
                node.mod += shiftValue;
                TreeHelper.centerNodesBetween(node, sibling);
                shiftValue = 0;
            }
 
            sibling = sibling.getNextSibling();
        }
    }

    static centerNodesBetween<T>(leftNode: TreeNode<T>, rightNode:TreeNode<T>)
    {
        var leftIndex  = leftNode.parent.children.indexOf(rightNode);
        var rightIndex = leftNode.parent.children.indexOf(leftNode);
                    
        var numNodesBetween = (rightIndex - leftIndex) - 1;

        if (numNodesBetween > 0)
        {
            var distanceBetweenNodes = (leftNode.x - rightNode.x) / (numNodesBetween + 1);

            var count = 1;
            for (var i = leftIndex + 1; i < rightIndex; i++)
            {
                var middleNode = leftNode.parent.children[i];

                var desiredX        = rightNode.x + (distanceBetweenNodes * count);
                var offset          = desiredX - middleNode.x;
                    middleNode.x   += offset;
                    middleNode.mod += offset;

                count++;
            }
            //CheckForConflicts(leftNode);
        }
    }

    static getContour<T>(fn, node: TreeNode<T> , modSum:number, values:number[])
    {
        if (!values[node.y])
            values[node.y] = node.x + modSum;
        else
            values[node.y] = fn(values[node.y], node.x + modSum);
 
        modSum += node.mod;
        node.children.forEach(child => {
            TreeHelper.getContour(fn, child, modSum, values);
        });
    }
 
    static getLeftContour<T>(node: TreeNode<T> , modSum:number, values:number[]){
        this.getContour<T>(Math.min, node, modSum, values );
    }    

    static getRightContour<T>(node: TreeNode<T> , modSum:number, values:number[]){
        this.getContour<T>(Math.max, node, modSum, values );
    }   
    
    static checkAllChildrenOnScreen<T>(node:TreeNode<T>)
    {
        var nodeContour = [];
        TreeHelper.getLeftContour(node, 0, nodeContour);

        var shiftAmount = 0;
        var nodeKeys = Array.from(nodeContour.keys());
        for(var x; x < nodeKeys.length ;x++)
        {
            if (nodeContour[ nodeKeys[x] ] + shiftAmount < 0)
                shiftAmount = (nodeContour[nodeKeys[x]] * -1);
        }

        if (shiftAmount > 0)
        {
            node.x   += shiftAmount;
            node.mod += shiftAmount;
        }
    }

    static calculateFinalPositions<T>(node:TreeNode<T>,modSum:number)
    {
        node.x += modSum;
        modSum += node.mod;
        for(var x=0;x <node.children.length;x++){
            var child = node.children[x];
            TreeHelper.calculateFinalPositions(child, modSum);
        }
    }
}