export class ArtisticSampleData{
    static imageUrl;
    static init(imageUrl){
        this.imageUrl = imageUrl;
    }

    // Generate sample data for fix number of childern
    static generateSampleData(noOfGenerations, children) {
        var sampleData = [];
        sampleData.push({ "id" : 1,"parentId" : 0,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"});
        var lastId = 1;
        var val=0;
        for(var i=0; i<noOfGenerations; i++){
            val+=Math.pow(children, i)
        }
        for (var x = 1; x <= val ; x++) {
            for (var y = 1; y <= children; y++){
                lastId++;
                sampleData.push({ "id" : lastId,"parentId" : x,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "U"});
            }
        }
        return sampleData;
    }
    
        // Generate sample data for fix number of childern in the last level
        static generateSampleDataLastLevel(noOfGenerations, lastLevelChildren) {
            var sampleData = [];
            sampleData.push({ "id" : 1,"parentId" : 0,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"});
            var lastId = 1;

            for (var x = 1; x <= noOfGenerations ; x++) {
                lastId++;
                sampleData.push({ "id" : lastId,"parentId" : x,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "U"});
                if (x == noOfGenerations){
                    for (var y = 1; y < lastLevelChildren; y++){
                        lastId++;
                        sampleData.push({ "id" : lastId,"parentId" : x,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "U"});
                    }
                } 
            }
            return sampleData;
        }
        
    // random data with no profile image URL
    static getTestData1(){
        var sampleData =[]
        sampleData.push({ "id" : 1,"parentId" : 0,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 2,"parentId" : 1,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 3,"parentId" : 1,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
 
        sampleData.push({ "id" : 4,"parentId" : 2,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "F"})
        sampleData.push({ "id" : 5,"parentId" : 2,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 6,"parentId" : 2,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})
        sampleData.push({ "id" : 7,"parentId" : 3,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 8,"parentId" : 3,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})

        sampleData.push({ "id" : 9,"parentId" : 4,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 10,"parentId" : 4,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "U"})
        sampleData.push({ "id" : 11,"parentId" : 5,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 12,"parentId" : 5,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})
        sampleData.push({ "id" : 13,"parentId" : 6,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 14,"parentId" : 6,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "F"})
        sampleData.push({ "id" : 15,"parentId" : 7,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 16,"parentId" : 7,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})        
        sampleData.push({ "id" : 17,"parentId" : 8,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 18,"parentId" : 8,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "U"})

        sampleData.push({ "id" : 19,"parentId" : 9,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 20,"parentId" : 10,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "U"})
        sampleData.push({ "id" : 21,"parentId" : 11,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 22,"parentId" : 12,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})
        sampleData.push({ "id" : 23,"parentId" : 13,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 24,"parentId" : 14,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 25,"parentId" : 15,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 26,"parentId" : 16,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})        
        sampleData.push({ "id" : 27,"parentId" : 16,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 28,"parentId" : 17,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})
        sampleData.push({ "id" : 29,"parentId" : 17,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})
        sampleData.push({ "id" : 30,"parentId" : 18,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})
        sampleData.push({ "id" : 31,"parentId" : 18,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})

        sampleData.push({ "id" : 32,"parentId" : 9,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 33,"parentId" : 10,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "U"})
        sampleData.push({ "id" : 34,"parentId" : 11,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 35,"parentId" : 12,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})
        sampleData.push({ "id" : 36,"parentId" : 13,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 37,"parentId" : 14,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 38,"parentId" : 15,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 39,"parentId" : 16,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})        
        sampleData.push({ "id" : 40,"parentId" : 16,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "F"})
        sampleData.push({ "id" : 41,"parentId" : 17,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})
        sampleData.push({ "id" : 42,"parentId" : 17,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})
        sampleData.push({ "id" : 43,"parentId" : 18,"displayName" : "Isak Bakke","profileImageUrl" :"","gender" : "M"})
        sampleData.push({ "id" : 44,"parentId" : 18,"displayName" : "Isak Bakke","profileImageUrl" :this.imageUrl  + `images/profilepic.png`,"gender" : "M"})

        return sampleData
    }

}