import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomApiParameters } from 'src/app/common/helpers/custom-api-parameters';
import { LinkRequest } from 'src/app/common/models/LinkRequest';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { NestedPopupService } from 'src/app/services/UI/nested-popup.service';
import { Individual } from '../../../../common/models/Individual';
import { IndividualApiService } from '../../../../services/API/individual-api.service';


@Injectable({
  providedIn: 'root'
})
export class PedigreeService extends NestedPopupService {

  constructor(
    private customApiParameters: CustomApiParameters,
    private IndividualApiService: IndividualApiService) {
    super();
  }

  /**
   *  get individual list via api base service
   */
  getAscendantsList<T>(generations, memberId): Observable<T> {
    this.customApiParameters.clearAllParams();
    let customParaList = this.customApiParameters.
      addCustomParameter("GenerationCount", generations.toString()).
      getCustomParaList();

    return this.IndividualApiService.getAscendantsList(customParaList, memberId);
  }


  /**
 *  create multimedia link via api base service
 */
  updateMultimediaLink<T>(linkRequest: LinkRequest, memberId: number): Observable<T> {
    return this.IndividualApiService.updateMultimediaLink(linkRequest, memberId);
  }
}
