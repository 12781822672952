import { FTDate } from './FTDate';
import { CropInfo } from './CropInfo';
export class MultiMediaBasicInfo {
    id: number;
    caption: string;
    fileName: string;
    description: string;
    date: FTDate;
    tag: string;
    place: string;
    mediaUrl: string;
    mediaType: number;
    cropInfo: CropInfo;
    originalMediaUrl: string;
    options: number[];
    referenceId : number; 
}