<div class="media-input-container border-outline">
    <!-- Upload media -->
    <div *ngIf="mode == 1" class="upload-container" (dragover)="dragOver($event)" (drop)="filesDropped($event)" >        
        <img src="assets/multimedia-module/Drag.png" class="upload-dropzone-image">
        <span class="label-upload-bold">{{"ph_drag_and_drop" | translate}}</span>
        <span class="label-upload">{{ uploadExtensionTxt }}</span>        
        <br>
        <button class="ft-btn ft-btn-active" (click)="clickFileInput()" >{{ "lbl_browse_for_file" | translate}}</button>
        <input #fileInput type="file" [accept] = "acceptMediaExtension"
        (change)="fileUploaded($event);" style="display:none">
    </div>
    <!-- Preview image media -->
    <div *ngIf="mode == 2" class="preview-image-container" #dropzone>
        <div class="preview-image-close" (click)="closePreview()">
            <img src="/assets/images/icons/close-0.svg" style="display: block;"/>            
        </div>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 720 300" width="100%" height="100%" (mousedown)="dragStart($event)" (mousemove)="drag($event)" (mouseup)="dragEnd($event)" (mousewheel)="zoom($event)" >  
            <defs>
                <clipPath id="circleMask" >
                    <circle [attr.cx]="cropAreaWidth/2" [attr.cy]="cropAreaHeight/2" [attr.r]="cropAreaSize/2" fill="none" transform-origin="center" [attr.transform]="getCropZoom()"/>    
                </clipPath>                
            </defs>
            <g >
                <image transform-origin="center" [attr.transform]="getImageZoom()" [attr.x]="priviewImageRect.x" [attr.y]="priviewImageRect.y"  [attr.href]="previewImageURL" [attr.height]="priviewImageRect.h" [attr.width]="priviewImageRect.w" opacity="0.5" />
                <image transform-origin="center" [attr.transform]="getImageZoom()" clip-path="url(#circleMask)" [attr.x]="priviewImageRect.x" [attr.y]="priviewImageRect.y"  [attr.href]="previewImageURL" [attr.height]="priviewImageRect.h" [attr.width]="priviewImageRect.w" />            
            </g>            
        </svg>                
    </div>
    <!-- sound/vide document file preview-->
    <div *ngIf="mode == 3" class="upload-container" style="position: relative;">
        <div class="preview-image-close" (click)="closePreview()">
            <img src="/assets/images/icons/close-0.svg" style="display: block;"/>            
        </div>
        <img style = "height: 200px;" src="assets/nonimage-preview/Large-Icons/media_type_{{currentMediaType}}.svg">
        <span class="label-upload-bold" style="margin-top: 30px;">{{mediaFile.name}}</span>
    </div>
</div>
<div *ngIf="isError" class="label-error-red">
    {{errorMessage}}
</div>
<div *ngIf="mode == 2" style="width: 100%;height:40px;display: flex;flex-direction: column;align-items: center;justify-content: center;padding: 10px;">
    <input #slider type="range" min="1" max="3.6"  class="ft-slider" step="0.1" [value]="priviewImageZoom" (input)="zoom($event)" >
</div>

<!-- End preview image media -->