import { Injectable } from '@angular/core';

let undefinedVar = 'undefined';

@Injectable({
    providedIn: 'root'
})

export class FetchProperty {
    public get(obj, key) {
        return key.split('.').reduce((o, x) => {
            return (typeof o === undefinedVar || o === null) ? o : o[x];
        }, obj);
    }
}
