import { ComponentFactoryResolver } from "@angular/core";
import { DynamicReportDirective } from "src/app/components/reportengine/directives/dynamic-report.directive";
import { Sunchart } from "src/app/components/reports/sunchart/sunchart/sunchart.component";
import { ReportTranslation } from "./ReportTranslation";
import ReportConfig from "./ReportConfig";
import { ReportEngineService } from "src/app/components/reportengine/services/report-engine-service.service";
import Theme from "./Theme";
import { environment } from "src/environments/environment";
import { PedigreeComponent } from 'src/app/components/reports/pedigreechart/pedigree/pedigree.component';
import { ArtistictreeComponent } from 'src/app/components/reports/artistictree/artistictree/artistictree.component';
import { TimelineReportComponent } from "src/app/components/reports/timelinereport/timelinereport/timelinereport.component";
import { ChartComponent } from "src/app/components/reports/descendantchart/chart/chart.component";
import { FactsheetComponent } from "src/app/components/reports/factsheet/factsheet/factsheet.component";

export class ReportBase {
  reportName: string;
  reportconfig: ReportConfig;
  private _componentFactoryResolver: ComponentFactoryResolver;
  private _dynamicReportView: DynamicReportDirective;
  private _dynamicReportRef;
  private _reportEngineService: ReportEngineService;
  iframeURL = environment.IFRAME_URL;

  //reports object. every new report should be addded to this object with it's respective name in query parameter
  private reports = {
    fanchartleaves: Sunchart,
    sunchartviking: Sunchart,
    fanchart: Sunchart,
    sunchart: Sunchart,
    fanchartjs: Sunchart,
    pedigree: PedigreeComponent,
    pedigreeviking: PedigreeComponent,
    descendant: ChartComponent,
    artistictree : ArtistictreeComponent,
    timelinereport: TimelineReportComponent,
    timelinereportvine: TimelineReportComponent,
    factsheet: FactsheetComponent
  };

  constructor(
    name: string,
    reportMetaData: ReportConfig,
    componentFactoryResolver: ComponentFactoryResolver,
    dynamicReportView: DynamicReportDirective,
    reportEngineService: ReportEngineService
  ) {
    this._reportEngineService = reportEngineService;
    this.reportName = name;
    this.reportconfig = new ReportConfig();
    Object.assign(this.reportconfig, reportMetaData);
    this._componentFactoryResolver = componentFactoryResolver;
    this._dynamicReportView = dynamicReportView;
    this._reportEngineService = reportEngineService;
  }

  loadReport() {
    const reportComponent = this.reports[this.reportName];
    const factory = this._componentFactoryResolver.resolveComponentFactory(
      reportComponent
    );
    const viewContainerRef = this._dynamicReportView.viewContainerRef;

    // clear any existing components in the view container
    viewContainerRef.clear();


    // create and return the component
    this._dynamicReportRef = viewContainerRef.createComponent(factory);
    this._dynamicReportRef.instance.reportName = this.reportName;
    this._dynamicReportRef.instance.config = this.reportconfig.reportProperty;
    this._dynamicReportRef.instance.commonUrl = this.getBaseUrl();
  }

  /**
   * Return common url prefix to access report assets
   */
  getBaseUrl() {
    return this.iframeURL + "/assets/report/" + this.reportName + "/";
  }

  /**
   * Set common prefix URL to the report to get  themes, images etc
   */
  setCommonUrl() {
    this._dynamicReportRef.instance.commonUrl = this.getBaseUrl();
  }

  activateReportOptions() {
  }

  /**
   * Set theme URL to the Dynamic Report Referance
   * @param {dynamicUrl} dynamic Url
   */
  setReportTheme(dynamicUrl: any) {
    this._dynamicReportRef.instance.dynamicThemeUrl = dynamicUrl;
    //this._dynamicReportRef.instance.setTheme(dynamicUrl);
  }

  /**
   * Set translation object to the Dynamic Report Referance
   * @param {ReportTranslation} translation Translation Object
   */
  setTranslation(translation: ReportTranslation) {
    this._dynamicReportRef.instance.reportTranslationObj = translation;
  }

  /**
   * Set reports AfterViewInitialization callback to the Dynamic Report Referance
   * @param {() => void} afterViewInitialization callback function
   */
  setAfterViewInitialization(afterViewInitialization: () => void) {
    this._dynamicReportRef.instance.reportAfterViewInitialization = afterViewInitialization;
  }
  /**
   * Set reports AfterViewInitialization callback to the Dynamic Report Referance
   * @param {() => void} reportZoomToExtend callback function
   */
  setZoomToExtend(ZoomToExtend: () => void) {
    console.log(ZoomToExtend)
    this._dynamicReportRef.instance.reportZoomToExtend = ZoomToExtend;
  }

  /**
   * Get default theme
   */
  getdefaultThemeId() {
    return Number(this.reportconfig.themes.defaultId);
  }

  /**
   * get existing theme file list
   * It checks whether physical theme file exist
   */
  getThemeList() {
    let existThemeList: Array<Theme> = new Array<Theme>();
    this.reportconfig.themes.contentlist.forEach((theme) => {
      let url = this.getBaseUrl() + "themes/" + theme.filename;
      this._reportEngineService
        .getThemeFileExists(url)
        .subscribe((isFileExist: boolean) => {
          isFileExist
            ? existThemeList.push(theme)
            : console.log("File not found");
        });
    });
    return existThemeList;
  }

  getThemeFileNameById(id: number) {
    if(this.reportconfig.themes.contentlist.length > 0){
    let themeObj = this.reportconfig.themes.contentlist.find(
      (theme) => theme.id == id
    ).filename;
    
    return themeObj;
    }
    else{
      return "";
    }
  }

  hideTooltip() {
    this._dynamicReportRef.instance.hideTooltip()
  }

  getLevel(){
    return this._dynamicReportRef.instance.defaultAncestorLevels;
  }

  getReportReduceHeight(){
    return this._dynamicReportRef.instance.defaultHeight;
  }

  getReportInstance(){
    return this._dynamicReportRef.instance;
  }

}
