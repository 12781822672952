import { TooltipHeadPosition } from "./tooltip-head-position";

export class TooltipInfo {
    isVisible   : boolean;
    sourceEvent : any;
    position    : TooltipHeadPosition;
    component   : any;  
    data        : any;
    color       : string = "white"; // set default
    name        : string;
  }