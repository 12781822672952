import { TimelineConfigModel } from "src/app/common/models/report/timelinereport/timeline-config-model";
import { TimelineEventModel } from "src/app/common/models/report/timelinereport/timeline-event-model";
import { EventBoxSide, EventType } from "src/app/common/enums/enums";
import { TimelineUtils } from "src/app/components/reports/timelinereport/timelinereport/timelinereport-utills";
import { TimelineEventContentModel } from "src/app/common/models/report/timelinereport/timeline-event-content-model";
import { FTDate } from "src/app/common/models/FTDate";
import { TimelineMediaModel } from "src/app/common/models/report/timelinereport/timeline-media-model";

export class EventsGraphics {
  public static get(side: number, id: number, type: number, title: string, location: string, date: FTDate, media: any[], description: string, eventConfig: TimelineConfigModel['event']): TimelineEventModel {

    if (title === null || title === undefined || title.length === 0) {
      return null;
    }

    // eventHeight is same as event box name
    // get height type by media and description
    // media is not implemented yet. dummy data is used for now.
    let eventBoxConfig = eventConfig.eventBox;
    let eventHeight = eventBoxConfig.header.height;
    if (description && description.length > 0)
      eventHeight += eventBoxConfig.body.height;
    if (media && media.length > 0)
      eventHeight += eventBoxConfig.media.height;

    //The following select the eventBox img based on sizes of the event. 
    //The size of the event box is currently determined by height of header, body and media content defined in configs file.
    let selectedEventType = eventConfig.eventBox[eventHeight];


    if (!selectedEventType)
      return null;

    let eventGraphic: TimelineEventModel = {
      id: id,
      header: this.getHeader(title, location, date, type, eventConfig.eventBox),
      body: this.getBody(description, eventConfig.eventBox),
      media: this.getMedia(media,description, eventConfig.eventBox),
      xPosition: 0,
      yPosition: 0,
      width: selectedEventType.width,
      height: selectedEventType.height,
      img: (type == EventType.Family) ? selectedEventType.familyImg : selectedEventType.individualImg,
      position: side,
      hConnector: {
        xPosition: 0,
        yPosition: 0,
        img: (side == EventBoxSide.Left) ? eventConfig.hConnector.imgLeft : eventConfig.hConnector.imgRight,
        offsetXPosition: eventConfig.hConnector.offsetXPosition,
        offsetYPosition: eventConfig.hConnector.offsetYPosition
      },
      offsetXPosition: eventConfig.eventBox.offsetXPosition,
      offsetYPosition: eventConfig.eventBox.offsetYPosition
    };
    return eventGraphic;
  }

  /**
    * Returns the body of a timeline event as a TimelineEventContentModel object.
    * @param description The description of the event.
    * @param eventConfig The configuration for the event.
    * @returns A TimelineEventContentModel object representing the body of the event.
    */
  private static getBody(description: string, eventConfig: TimelineConfigModel['event']['eventBox'])
    : TimelineEventContentModel {
    // Get the text element for the description and set its width and height based on the event configuration.
    let descriptionText = TimelineUtils.getTextElement(description, 'body', 'description', eventConfig);
    descriptionText.width = eventConfig.body.contents.description.width;
    descriptionText.height = eventConfig.body.contents.description.height;
    // Return the text content as a TimelineEventContentModel object.
    return {
      textContent: [descriptionText]
    };
  }

  private static getHeader(title: string, location: string, date: FTDate, type: number,
    eventBoxConfig: TimelineConfigModel['event']['eventBox']): TimelineEventContentModel {
    const { year, month, day } = date.genealogyDate;
    let dateObj = null;
    let formattedDate = null;
  
    // Format the date based on the year, month, and day values.
    switch (true) {
      case year !== 0 && month !== 0 && day !== 0:
        dateObj = new Date(year, month - 1, day);
        formattedDate = `${dateObj.getDate().toString().padStart(2, '0')} ${dateObj.toLocaleString('default', { month: 'short' })} ${dateObj.getFullYear()}`;
        break;
      case year !== 0 && month !== 0 && day === 0:
        dateObj = new Date(year, month - 1, 1);
        formattedDate = `${dateObj.toLocaleString('default', { month: 'short' })} ${dateObj.getFullYear()}`;
        break;
      case year !== 0 && month === 0 && day === 0:
        dateObj = new Date(year, 1, 1);
        formattedDate = `${dateObj.getFullYear()}`;
        break;
      default:
        break;
    }

    let textContent = [
      TimelineUtils.getTextElement(title, 'header', 'title', eventBoxConfig),
      TimelineUtils.getTextElement(location, 'header', 'location', eventBoxConfig),
      TimelineUtils.getTextElement(formattedDate, 'header', 'date', eventBoxConfig)
    ];
    let icon = TimelineUtils.getIconElement(type, 'header', eventBoxConfig)

    if (!(location && location.length > 0)) {
      textContent[0].yPosition += eventBoxConfig.header.contents.title.extraYPositionForCenter;
    }

    return {
      textContent: textContent,
      icons: [icon]
    };
  }
  private static getMedia(media: string[], description: string, eventBoxConfig: TimelineConfigModel['event']['eventBox']): TimelineMediaModel {
    const { media: mediaConfig, body: bodyConfig } = eventBoxConfig;
    const { width, height, margin, xPosition, yPosition, offsetXPosition, offsetYPosition } = mediaConfig;
    const mediaHeight = height - 2 * margin;
    const mediaYPosition = yPosition + (description && description.length > 0 ? bodyConfig.height : 0);
    
    return {
      width,
      height: mediaHeight,
      xPosition,
      yPosition: mediaYPosition,
      offsetXPosition,
      offsetYPosition,
      mediaContent: media
    };
  }
}
