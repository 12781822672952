import { v4 as uuid } from 'uuid';

enum OptionType{
    "input",
    "combobox"
}

export class ReportFieldOption{
    id          :   number;
    fieldName   :   String;          // field name - uses for logic
    fieldTitle  :   String;          // field display text
    type        :   OptionType;
    data        :   [];
    value       :   String;

    constructor(fieldName,fieldTitle, type, data, value){
        this.id = uuid();
        this.fieldName  = fieldName;
        this.fieldTitle = fieldTitle;
        this.type       = type;
        this.data       = data;
        this.value      = value
    }
}