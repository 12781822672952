export class Preference {
    nameOrder: string;
    showCapitalSurname: boolean;
    includeMiddleName: boolean;
    includePrefixSuffix: boolean;
    includePatronomic: boolean;
    duplicateNameCheck: boolean;
    autoCapitalName: boolean;


    showPrepositions: boolean;
    lastViewedProjectName: string;
    isLocal: boolean;

    dateSeperater: string;
    dateType: string;
    monthFirst: boolean;
    showCapitalDate: boolean;
    twoDigitNumeric: boolean;
}