import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuMainComponent } from './menu-main/menu-main.component';
import { ProfileMainComponent } from './profile-main/profile-main.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [MenuMainComponent, ProfileMainComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot()
  ],
  exports: [
    MenuMainComponent
  ]
})
export class MainmenuModule { }
