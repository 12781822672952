import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CitationQualityHelper } from 'src/app/common/helpers/source/citation-quality-helper';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';

@Component({
  selector: 'app-source-citation-editor',
  templateUrl: './source-citation-editor.component.html',
  styleUrls: ['./source-citation-editor.component.scss']
})
export class SourceCitationEditorComponent implements OnInit {

  constructor() { }

  @Output() onCompleted = new EventEmitter<any>();
  @Input() data :any;

  
  citation: SourceCitationViewModel;
  citationQualities: any;
  selectedQuality : any;
  
  ngOnInit(): void {   
    this.citation = new SourceCitationViewModel();
    this.citationQualities = CitationQualityHelper.citationQualities;  
    this.selectedQuality = this.citationQualities.find(t => t.id == (this.data.responseData.sourceCitationInfo.quality ?? 2));
    
    this.data.id > 0 ? this.citation.id = this.data.id : this.citation.id = 0;   
    this.citation = this.data.responseData.sourceCitationInfo;   
  }

  save(){     
    this.citation.quality = this.selectedQuality.id;
    this.data.responseData.newSourceCitation = this.citation;
    this.onCompleted.next({data : this.data.responseData});
    this.onCompleted.unsubscribe();
  }
  
  cancel(){
    this.onCompleted.next({});
    this.onCompleted.unsubscribe();
  }
}
