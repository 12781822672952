
<div class="main-wrapper">
  <app-dialog></app-dialog>
  <app-message-dialog></app-message-dialog>
  <app-confirmation-dialog></app-confirmation-dialog>
  <app-tooltip-main></app-tooltip-main>
  <app-home *ngIf="!isAuthenticated" [isUnsupportedSettings] = "isUnsupportedSettings"></app-home>  
  <!-- Main menu 55px-->
  <div style="width: 100vw;height: 55px;">    
    <app-menu-main></app-menu-main>    
  </div>
  <app-loading *ngIf="loadingState"></app-loading> <!-- *ngIf="loadingState"-->
  <div style="width: 100vw;height: calc(100vh - 55px);">
    <router-outlet *ngIf="isAuthenticated"></router-outlet>
  </div>
</div>