import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembereditorModule } from '../membereditor/membereditor.module';
import { TreeeditorModule } from '../treeeditor/treeeditor.module';
import { CommonComponentModule } from '../common/common.module';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FamilyeditorModule } from '../familyeditor/familyeditor.module';
import { IndividualeditorModule } from '../individualeditor/individualeditor.module';
import { EditorMenuComponent } from './editor-menu/editor-menu.component';



@NgModule({
  declarations: [EditorMenuComponent],
  exports: [EditorMenuComponent],
  imports: [
    CommonModule,
    MembereditorModule,    
    TreeeditorModule,
    IndividualeditorModule,
    RouterModule,
    CommonComponentModule,
    FamilyeditorModule,    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class EditorModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
