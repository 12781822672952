<div class="ft-form">
    <div class="content">
        
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{ "lbl_colorizer_title" | translate }}</span>
        </div>
        <div class="main-container">
            <!-- original image -->
            <div [ngClass]="{'main-row':data.mode == 0,'small-row':data.mode == undefined}" class="border-outline">
                <img class="colorize-image" src="{{ data.mediaInfo.originalMediaUrl }}" />
            </div>
            <!-- colorized imgae -->
            <div [ngClass]="{'main-row':data.mode == 0,'small-row':data.mode == undefined}" class="border-outline">
                <img *ngIf="!isPreview" class="colorize-image" [src]=imageUrl />
                <img *ngIf="isPreview" class="preview-image" src="assets/multimedia-module/colorize-default.png" />
            </div>
            <!-- paragraph and error message -->
            <div [ngClass]="{'main-row':data.mode == 0,'small-row':data.mode == undefined}"
                style="align-items: flex-start !important; justify-content: flex-start !important;">
                <div [hidden]="data.mode == 0" class="errorMessage">{{errorMessage | translate}}</div>
                <p class="paragraph">{{"description_colorizer" | translate}}</p>
            </div>
            <div *ngIf="data.mode == 0" class="errorMessage1">{{errorMessage | translate}}</div>    
        </div>
        <!-- upgrade message -->
        <div *ngIf="!isAccessible" class="upgrade-message">
            <div class="upgrade-content">
                <div>
                    <p class="upgrade-header">{{"lbl_colorize_upgrade_title" | translate}}</p>
                    <p class="upgrade-body">{{"lbl_colorize_upgrdae_content" | translate}}</p>
                </div>
                <div class="upgrade-buttons">
                    <button class="ft-btn ft-btn-active" (click)="goToWebShop()">
                        {{ "lbl_upgrade" | translate }}
                    </button>
                    <button class="ft-btn ft-btn-active-not-selected" style="background-color: #646464;" (click)="goToGallery()">
                        {{ "lbl_go_to_multimedia" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <button [disabled]="!isAccessible" class="ft-btn" [ngClass]="(isAccessible)? 'ft-btn-inactive active' : 'ft-btn-inactive'" (click)="colorize()">
            {{ "lbl_colorize" | translate }}
        </button>
        <button class="ft-btn" [ngClass]="(isAccessible)? ((colorizeStatus)? 'ft-btn-inactive active' : 'ft-btn-inactive'):'ft-btn-inactive'"
            [disabled]="!colorizeStatus" (click)="add()">
            {{ "lbl_add" | translate }}
        </button>
        <button class="ft-btn" [ngClass]="(isSaved && isAccessible)? 'ft-btn-inactive active' : 'ft-btn-inactive'"
            [disabled]="!isSaved && !isAccessible" (click)="download()">
            {{ "lbl_download" | translate }}
        </button>
        <button [disabled]="!isAccessible" class="ft-btn" [ngClass]="(isAccessible)? 'ft-btn-active-not-selected' : 'ft-btn-inactive'" (click)="cancel()">
            {{ "lbl_cancel" | translate }}
        </button>
    </div>
</div>