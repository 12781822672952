import { EventBase } from 'src/app/common/models/event/event-base';
import { IndividualBase } from '../../individualeditor/models/individual-base';

export class IndividualReportViewModel extends IndividualBase{
    dateRangeStr: string;
    profileImageUrl : string;
    levelId : number;
    spouseFamilyId : number;
    parentFamilyId : number;
    profileImageId : number;
    birthEvent: EventBase;
    deathEvent: EventBase;
}