<div class="time-bar-main">
  <div class="family-alive-status">
    <p>{{ aliveIndividualsCountWithYear }}</p>
  </div>
  <div class="time-bar">
    <div class="year-range">
      <p class="start-year">{{ startYear }}</p>
      <p class="current-year">{{ currentYear }}</p>
    </div>
    <div class="range-wrap">
      <input
        id="range"
        type="range"
        min="{{ startYear }}"
        max="{{ currentYear }}"
        [value]="rangeValue"
      />
      <output class="range-value" id="rangeV"></output>
    </div>
  </div>
</div>
