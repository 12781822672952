<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">My Witnesses</div>
    </div>
    <div class="tab-container app-dark-bg">
            <div class="tabs">
                <a [class.active]="viewMode == 1" (click)="viewMode = 1">Witnesses</a>
                <a [class.active]="viewMode == 2" (click)="viewMode = 2">+ Add witness</a>
            </div>
            <div class="tab-forms app-light-bg">                

                <app-witnesses *ngIf="viewMode === 1" [data]="data" (onCompleted)="done($event)"></app-witnesses>
                <app-witness-editor *ngIf="viewMode === 2"  (onCompleted)="done($event)"></app-witness-editor>                 

            </div>            
    </div>
</div>
  