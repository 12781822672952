<div class="bookreport-container">
    <div class="flipbook-viewport" id="flipbook-viewport">
        <div id="flipbook-outer">
            <app-flipbookcontainer #bookContainer [bookReport]="bookReport" [bookReportConfig]="bookReportConfig"
                [hidden]="!isBookLoaded" (onFlipBookUILoaded)="onFlipBookUILoaded($event)"
                (onPageFlipped)="onPageFlipped($event)">
            </app-flipbookcontainer>
        </div>
    </div>
    <div class="bookreport-toolbar-container">
        <div class="bookreport-toolbar">
            <button [disabled]="!isRegenerateEnabled"
                [ngClass]="isRegenerateEnabled? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
                (click)="regenerate()"><img src="/assets/images/icons/bookreport-regenerate.svg" alt="">
            </button>
            <button [disabled]="!isZoomInEnabled" [ngClass]="isZoomInEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.zoomIn(); this.enableZoomOut()"><img alt="Zoom In"
                    src="/assets/images/icons/zoom-in.svg">
            </button>
            <button [disabled]="!isZoomOutEnabled" [ngClass]="isZoomOutEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.zoomOut()"><img alt="Zoom out"
                    src="/assets/images/icons/zoom-out.svg">
            </button>
            <button [disabled]="!isFirstContentPageEnabled"
                [ngClass]="isFirstContentPageEnabled? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
                (click)="this.flipBook.flipFirstContentPage()"><img
                    src="/assets/images/icons/bookreport-flipFirstPage.svg" alt="">
            </button>
            <button [disabled]="!isBackwardEnabled" [ngClass]="isBackwardEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.flipBackward()"><img
                    src="/assets/images/icons/bookreport-backward.svg" alt="">
            </button>
            <button [disabled]="!isForwardEnabled" [ngClass]="isForwardEnabled? 'tb-btn-enable' : 'tb-btn-disable'"
                class="tb-btn" (click)="this.flipBook.flipForward()"><img
                    src="/assets/images/icons/bookreport-forward.svg" alt="">
            </button>
            <button [disabled]="!isLastContentPageEnabled"
                [ngClass]="isLastContentPageEnabled? 'tb-btn-enable' : 'tb-btn-disable'" class="tb-btn"
                (click)="this.flipBook.flipLastContentPage()"><img
                    src="/assets/images/icons/bookreport-flipLastPage.svg" alt="">
            </button>
            <button class="tb-btn tb-btn-enable" (click)="publishReport()">
                <img src="/assets/images/icons/download.svg" alt="">
            </button>
        </div>
    </div>
</div>