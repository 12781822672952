import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventListComponent } from './event-list/event-list.component';
import { EventsMainComponent } from './events-main/events-main.component';
import { EventFormComponent } from './event-form/event-form.component';
import { DropMenuModule } from '../drop-menu/drop-menu.module';
import { EventDetailNoteComponent } from './event-tips/event-detail-note/event-detail-note.component';
import { EventDetailSourceComponent } from './event-tips/event-detail-source/event-detail-source.component';
import { EventDetailMediaComponent } from './event-tips/event-detail-media/event-detail-media.component';
import { EventDetailWitnessComponent } from './event-tips/event-detail-witness/event-detail-witness.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CommonComponentModule } from '../common.module';
import { FormsModule } from '@angular/forms';
import { MediaCarouselModule } from '../media-carousel/media-carousel.module';
import { ImageCarouselTempModule } from '../image-carousel-temp/image-carousel-temp.module';
import { EventDetailIndividualComponent } from './event-tips/event-detail-individual/event-detail-individual.component';



@NgModule({
  declarations: [
    EventsMainComponent,
    EventListComponent,
    EventFormComponent,
    EventDetailNoteComponent,
    EventDetailSourceComponent,
    EventDetailMediaComponent,
    EventDetailWitnessComponent,
    EventDetailIndividualComponent],
  imports: [
    CommonModule,
    DropMenuModule,
    CommonComponentModule,
    FormsModule,
    MediaCarouselModule,
    ImageCarouselTempModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [EventsMainComponent, EventListComponent, EventFormComponent]
})
export class EventsModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
