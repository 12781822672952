import { Injectable, NgModule } from "@angular/core";
import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { MainreportengineviewComponent } from "./mainreportengineview/mainreportengineview.component";
import { RouterModule } from "@angular/router";
import { DynamicReportDirective } from "src/app/components/reportengine/directives/dynamic-report.directive";
import { Sunchart } from "../reports/sunchart/sunchart/sunchart.component";
import { SafePipe } from "./mainreportengineview/safe.pipe";
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { PedigreeComponent } from '../reports/pedigreechart/pedigree/pedigree.component';
import { NodeOptionMenuComponent } from '../reports/common/reporttooltip/nodeoptionmenu/nodeoptionmenu.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { httpTranslateLoader } from 'src/app/shared/shared.module';
import { SunchartOptionPopupComponent } from '../reports/sunchart/sunchart-option-popup/sunchart-option-popup.component';



// custom configuration Hammerjs
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': {
      direction: Hammer.DIRECTION_ALL,
      enable: true
    },
  };
}
@NgModule({
  declarations: [
    MainreportengineviewComponent,
    DynamicReportDirective,
    Sunchart,    
    NodeOptionMenuComponent,
    SafePipe, PedigreeComponent,
   
    
  ],
  imports: [CommonModule, RouterModule, HammerModule, TranslateModule.forRoot()],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }, {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  entryComponents: [PedigreeComponent,NodeOptionMenuComponent, SunchartOptionPopupComponent],
  exports: [SafePipe,NodeOptionMenuComponent],
})


export class ReportengineModule {
}
