import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReportsMenuService } from '../reports-menu.service';
import { Router } from '@angular/router';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent implements OnInit {
  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  @ViewChild('imgDiv') imgDiv!: ElementRef;

  selectedContainer: HTMLElement | null = null;
  selectedReportId : number;
  reports: any[] = [];

  public isAddButtonActive: boolean;
  
  constructor(readonly reportsMenuService: ReportsMenuService, readonly router: Router) { }

    ngOnInit() {
      this.reportsMenuService.getReports().subscribe((config) => {
        this.reports = config;
      });
    }

    isNewReport(createdDate: string): boolean {
      const currentDate = new Date();
      const reportDate = new Date(createdDate);
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);
        return reportDate >= oneMonthAgo;
    } 

    onReportSelect(event: MouseEvent, container: HTMLElement, reportId: any): void {
      const isSameReportSelected = this.selectedReportId === reportId;
      this.selectedContainer?.classList.remove('selected');
      
      if (isSameReportSelected) {
        (event.target as HTMLInputElement).checked = false;
        this.selectedReportId = null;
        this.selectedContainer = null;
        this.isAddButtonActive = false;
      } else {
        container.classList.add('selected');
        this.selectedReportId = reportId;
        this.selectedContainer = container;
        this.isAddButtonActive = true;
      }
    }

    loadReport() {
      if (this.selectedReportId != null) {
        const report = this.reports.find(report => report.id === this.selectedReportId).menuName;    
        this.onCompleted.next();
        this.onCompleted.unsubscribe();
        this.router.navigate([AppConstatnts.routerModes[report]]);
      }
    }

    cancel() {
      this.onCompleted.next();
      this.onCompleted.unsubscribe();
    }
}
