<div class="ft-form" style="background-color: #f4f9e7;">
    <div class="content">
        <div class="ft-form-row" style="margin-bottom: 0px;">
            <div class="ft-form-row col1-v100" style="justify-content: space-between; margin-bottom: 0px;">                     
              <span class="title">
                {{"lbl_manage_trees"| translate}}
              </span>
            </div>
        </div>     
        <div class="list-gallery">
            <app-family-project-list
            [items]="mediaListItems"
            (action)="dropMenuAction($event)"
            ></app-family-project-list>  
        </div>     
    </div>
    <div class="footer">
        <button class = "ft-btn ft-btn-active" (click)="openAddNewProjectMain(1, [1,2])">{{"lbl_create_new_tree"|translate}}</button>                
        <button class = "ft-btn" [ngClass]="'ft-btn-active'"  (click)="showFamilyList()">{{"lbl_updatePage" | translate}}</button>
    </div>
</div>