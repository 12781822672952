<!-- The Modal -->

<div class="addIndividualPopup event-popup">
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title text-left">{{'event_' + event.EventName  | lowercase | translate}}</h4>
        <i class="fa fa-times close close-icon" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></i>
    </div>


    <!-- Modal body -->
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-3" style="padding-left: 0;">
                    <img src='{{event.EventImageUrl}}' />
                </div>
                <div class="col-md-9 text-left label-content">
                    <h5 class="text-left" [hidden]="event.Place === ''">
                        <strong>{{'lbl_place' | translate}} :</strong> {{event.Place}} </h5>
                    <h5 class="text-left">
                        <strong>{{'lbl_date' | translate}} :</strong> {{event.EventDateString}}
                    </h5>
                    <div id="wrapper">
                        <div id="content">
                            <p>{{event.Event}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer">
    </div>
</div>