<!-- Editors container-->
<div class="editors-base-container">      
    <div [class.disabled]="isDisabled()" class="editor-container" [class.editor-selected]="getCurrentEditor() == getEditorModes().TreeEditor" (click)="openEditor(getEditorModes().TreeEditor)">
        <svg class="editor-tree-icon" viewBox="0 0 20 20" ></svg>
        <span class="editor-text" >{{'menu.resources.lbl_tree_editor' | translate}}</span>
    </div>
    <div class="editor-seperator">|</div>
    <div [class.disabled]="isDisabled()" class="editor-container" [class.editor-selected]="getCurrentEditor() == getEditorModes().FamilyEditor" (click)="openEditor(getEditorModes().FamilyEditor)">
        <svg class="editor-family-icon" viewBox="0 0 20 20" ></svg>
        <span class="editor-text" >{{'menu.resources.lbl_family_editor' | translate}}</span>
    </div>            
    <div class="editor-seperator" >|</div>
    <div [class.disabled]="isDisabled()" class="editor-container" [class.editor-selected]="getCurrentEditor() == getEditorModes().IndividualEditor" (click)="openEditor(getEditorModes().IndividualEditor)">
        <svg class="editor-member-icon" viewBox="0 0 20 20" ></svg>
        <span class="editor-text" >{{'menu.resources.lbl_member_editor' | translate}}</span>
    </div>            
</div>