<custom-loading [isloading]="loading"></custom-loading>
<div id="timeLineView">
      <div class="mainComp">
            <div class="main">
                  <div class="decade">

                        <div id="panButtons" class="timeLine-Pan">
                              <div type="button" class="up-button">
                                    <img (click)="moveUp()" src="assets/images/Top.png" x="10" y="10"
                                          style="height: 30px; width: 30px" onerror="this.style.display='none'" />
                              </div>

                              <div type="button" class="down-button">
                                    <img (click)="moveDown()" src="assets/images/Bottom.png" x="10" y="10"
                                          style="height: 30px; width: 30px" onerror="this.style.display='none'" />
                              </div>
                        </div>

                        <!-- Timeline svg     -->
                        <svg fill="#000000" viewBox="0 0 1904 775">

                              <!-- Generate Decades -->
                              <g height="100%" width="7.14%" viewBox="0 0 136 780"
                                    *ngFor="let rulerTimeGap of rulerTimeGaps; let i = index">

                                    <!-- Decade Component -->
                                    <g *ngIf="i % 2 == 0">
                                          <rect [attr.x]="startX[i]" y=0 [attr.fill]="timeGapProp.getTimeGapColor1"
                                                [attr.width]="decadeElementWidth" height="100%" />
                                          <text x=-300 [attr.y]="startX[i] + 85" transform="rotate(-90 0 0)"
                                                fill="#E6EBD7" font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                font-size="50">{{ rulerTimeGaps[i] }}</text>
                                    </g>
                                    <g *ngIf="i % 2 !== 0">
                                          <rect [attr.x]="startX[i]" y=0 [attr.fill]="timeGapProp.getTimeGapColor2"
                                                [attr.width]="decadeElementWidth" height="100%" />
                                          <text x=-300 [attr.y]="startX[i] + 85" transform="rotate(-90 0 0)"
                                                fill="#E6EBD7" font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                font-size="50">{{ rulerTimeGaps[i] }}</text>
                                    </g>
                              </g>

                              <!-- Generate expanding events -->
                              <g *ngFor="let expand of expandingSingleLabels; let j = index">
                                    <symbol>
                                          <g [attr.id]="expandingSingleLabels[j].EventID">
                                                <g transform="translate(0,0)">
                                                      <g>
                                                            <g>
                                                                  <circle fill="#A2CD3A" stroke="#F4F8E7"
                                                                        stroke-miterlimit="10" cx="0" cy="41.454"
                                                                        r="4.344" />
                                                                  <g transform="translate(-10, 0)"
                                                                        (click)="seeEventDetails(expand)">
                                                                        <g>
                                                                              <path fill="#FFFFFF" stroke="#C9D5AB"
                                                                                    stroke-miterlimit="10"
                                                                                    d="M281.842, 4h-248.3c-2.209, 0-4, 1.791-4, 4v26.667l-6.749, 6.692 l6.749, 6.692v23.94c0, 2.209, 1.791, 4, 4, 4h248.3c2.209, 0,4-1.791,4-4V8C285.842,5.791,284.051,4,281.842,4z" />
                                                                              <text
                                                                                    transform="matrix(1 0 0 1 95.876 28.2554)">
                                                                                    <tspan x="0" y="0" fill="#636363"
                                                                                          font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                                          font-size="13">
                                                                                          {{expand.EventForLabel1}}
                                                                                    </tspan>
                                                                                    <tspan x="0" y="17.6" fill="#636363"
                                                                                          font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                                          font-size="13">
                                                                                          {{expand.EventForLabel2}}
                                                                                    </tspan>
                                                                                    <tspan x="0" y="35.2" fill="#636363"
                                                                                          font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                                          font-size="13">
                                                                                          {{expand.EventForLabel3}}
                                                                                    </tspan>
                                                                              </text>
                                                                        </g>
                                                                  </g>
                                                                  <g transform="translate(-10, 0)">
                                                                        <image [attr.href]="expand.EventImageUrl" x="14"
                                                                              y="-12" width="100" height="86"/>
                                                                              <clipPath id="clipping" >
                                                                                    <circle cx="65" cy="30" r="20" />
                                                                                  </clipPath>
                                                                  </g>
                                                                  <g>
                                                                        <text transform="translate(41,68)"
                                                                              fill="#919191"
                                                                              font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                              font-size="13">{{expand.Year}}</text>
                                                                  </g>
                                                            </g>
                                                      </g>
                                                </g>
                                          </g>
                                    </symbol>
                                    <use [attr.x]="expandingSingleLabels[j].x"
                                          [attr.y]="expandingSingleLabels[j].y + verticalPosition"
                                          [attr.xlink:href]="'#' + expandingSingleLabels[j].EventID" />
                              </g>

                              <!-- Label Component -->
                              <g *ngFor="let label of labelInfoDisplay; let i = index">
                                    <symbol>
                                          <!-- single timeline Events -->
                                          <!-- <g [attr.id]="label.EventID" *ngIf="label.hasOwnProperty('singleId')"> -->
                                          <g [attr.id]="label.EventID">
                                                <line x1="0" y1="50" x2="0"
                                                      [attr.y2]="label.lineheight  - verticalPosition"
                                                      style="stroke: #A2CD3A" />
                                                <circle fill="#A2CD3A" stroke="#F4F8E7" stroke-miterlimit="10" cx="0"
                                                      cy="41.454" r="4.344" />
                                                <g transform="translate(-10, 0)" (click)="seeEventDetails(label)">
                                                      <g>
                                                            <path fill="#FFFFFF" stroke="#C9D5AB" stroke-miterlimit="10"
                                                                  d="M281.842, 4h-248.3c-2.209, 0-4, 1.791-4, 4v26.667l-6.749, 6.692 l6.749, 6.692v23.94c0, 2.209, 1.791, 4, 4, 4h248.3c2.209, 0,4-1.791,4-4V8C285.842,5.791,284.051,4,281.842,4z" />
                                                            <text transform="matrix(1 0 0 1 95.876 28.2554)">
                                                                  <tspan x="0" y="0" fill="#636363"
                                                                        font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                        font-size="13">{{label.EventForLabel1}}</tspan>
                                                                  <tspan x="0" y="17.6" fill="#636363"
                                                                        font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                        font-size="13">{{label.EventForLabel2}}</tspan>
                                                                  <tspan x="0" y="35.2" fill="#636363"
                                                                        font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                                        font-size="13">{{label.EventForLabel3}}</tspan>
                                                            </text>
                                                      </g>
                                                </g>
                                                <g transform="translate(-10, 0)">
                                                      <image [attr.href]="label.EventImageUrl" class="profile-image" x="14" y="-12"
                                                            width="100" height="86"/>
                                                            <clipPath id="clipping" >
                                                                  <circle cx="65" cy="30" r="20" />
                                                                </clipPath>
                                                </g>
                                                <g>
                                                      <text transform="translate(41,68)" fill="#919191"
                                                            font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                            font-size="13">{{label.Year}}</text>
                                                </g>
                                          </g>

                                          <!--grouped timeline events-->
                                          <g [attr.id]="label.EventID" *ngIf="label.hasOwnProperty('groupId')">
                                                <line x1="0" y1="50" x2="0"
                                                      [attr.y2]="label.lineheight - verticalPosition"
                                                      style="stroke: #A2CD3A" />
                                                <circle fill="#A2CD3A" stroke="#F4F8E7" stroke-miterlimit="10" cx="0"
                                                      cy="41.454" r="4.344" />
                                                <g transform="translate(-10, 0)">
                                                      <g>
                                                            <path *ngIf="!label.isClicked" transform="translate(0, 10)"
                                                                  fill="#FFFFFF" stroke="#C9D5AB" stroke-miterlimit="10"
                                                                  d="M281.842,4h-248.3c-2.209,0-4,1.791-4,4v26.667l-6.749,6.692
    l6.749,6.691v23.941c0,2.209,1.791,4,4,4h248.3c2.209,0,4-1.791,4-4V8C285.842,5.791,284.051,4,281.842,4z" />
                                                            <path *ngIf="!label.isClicked" transform="translate(0, 5)"
                                                                  fill="#FFFFFF" stroke="#C9D5AB" stroke-miterlimit="10"
                                                                  d="M281.842,4h-248.3c-2.209,0-4,1.791-4,4v26.667l-6.749,6.692
    l6.749,6.691v23.941c0,2.209,1.791,4,4,4h248.3c2.209,0,4-1.791,4-4V8C285.842,5.791,284.051,4,281.842,4z" />
                                                      </g>
                                                      <path fill="#FFFFFF" stroke="#C9D5AB" stroke-miterlimit="10" d="M281.842,4h-248.3c-2.209,0-4,1.791-4,4v26.667l-6.749,6.692
    l6.749,6.691v23.941c0,2.209,1.791,4,4,4h248.3c2.209,0,4-1.791,4-4V8C285.842,5.791,284.051,4,281.842,4z" />
                                                      <text ng-if="(timeGapProp.timeGapPeriod/ timeGapProp.numoftinygaps) == 1"
                                                            transform="matrix(1 0 0 1 50 44.7983)" fill="#636363"
                                                            font-family="'Helvetica Neue',Helvetica,Arial,sans-serif"
                                                            font-size="13">Skjulte hendelser fra {{label.Year}}</text>
                                                      <g transform="translate(10,0)">
                                                            <circle fill="#A2CD3A" cx="251" cy="40.246" r="11.249" />
                                                            <g *ngIf="!label.isClicked"
                                                                  transform="translate(238.5,27) scale(0.8 0.8)">
                                                                  <path fill="#FFFDFD"
                                                                        d="M22.086 20.914l2.829-2.829-8.914-8.914-8.914 8.914 2.828 2.828 6.086-6.086z">
                                                                  </path>
                                                            </g>
                                                            <g *ngIf="label.isClicked"
                                                                  transform="translate(238.5,27) scale(0.8 0.8)">
                                                                  <path fill="#FFFDFD"
                                                                        d="M9.914 11.086l-2.829 2.829 8.914 8.914 8.914-8.914-2.828-2.828-6.086 6.086z">
                                                                  </path>
                                                            </g>
                                                      </g>

                                                </g>
                                          </g>
                                    </symbol>

                                    <use [attr.x]="label.x" [attr.y]="label.y + verticalPosition"
                                          [attr.xlink:href]="'#' + label.EventID" [attr.id]="'#' + label.EventID"
                                          (click)="updatePosition($event)" />
                              </g>


                              <!-- Generate ruler     -->
                              <g height="100%" width="7.14%" viewBox="0 0 136 1200"
                                    *ngFor="let rulerTimeGap of rulerTimeGaps; let i = index">

                                    <!-- Ruler Component -->
                                    <rect [attr.x]="startX[i]" [attr.width]="decadeElementWidth" height="80"
                                          fill="#ffffff" transform="translate(0, 700)" />
                                    <rect [attr.x]="startX[i]" y="700" fill="#9FC823" [attr.width]="decadeElementWidth"
                                          height="2" style="stroke: #9FC823; stroke-width:1px;"></rect>
                                    <g transform='translate(0 710)'>
                                          <line fill="none" stroke="#626262" stroke-width="2" [attr.x1]="startX[i]"
                                                y1="-0.247" [attr.x2]="startX[i]" [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262" stroke-width="2" [attr.x1]="startX[i+1]"
                                                y1="-0.247" [attr.x2]="startX[i+1]" [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(1))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(1))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(2))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(2))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(3))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(3))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(4))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(4))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(5))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(5))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(6))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(6))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(7))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(7))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(8))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(8))"
                                                [attr.y2]="rulerLevel" />
                                          <line fill="none" stroke="#626262"
                                                [attr.x1]="(startX[i]) + ((decadeElementWidth/10) *(9))"
                                                [attr.y1]="timeGapProp.timeGapHeight"
                                                [attr.x2]="(startX[i]) + ((decadeElementWidth/10) *(9))"
                                                [attr.y2]="rulerLevel" />
                                    </g>
                                    <!-- Ruler year -->
                                    <text fill="#626262" font-family="'Helvetica Neue', Helvetica,Arial,sans-serif"
                                          font-size="18px" float="right" [attr.x]="startX[i] + 90"
                                          y="765">{{ rulerTimeGap}}</text>
                              </g>



                              <g *ngFor="let label of labelInfoDisplay; let i = index">
                                    <symbol>
                                          <g
                                                *ngIf="label.hasOwnProperty('groupId') || label.hasOwnProperty('singleId')">
                                                <circle [attr.id]="'a'+i" fill="#A2CD3A" stroke="#F4F8E7"
                                                      stroke-miterlimit="10" cx="0"
                                                      [attr.cy]="label.lineheight - verticalPosition" r="6.344" />
                                          </g>
                                    </symbol>
                                    <use [attr.x]="label.x" [attr.y]="label.y + verticalPosition"
                                          [attr.xlink:href]="'#a' + i" />
                              </g>

                              <image *ngIf="showTreeLeaves" x="0" y="648" height="100" width="1900"
                                    [attr.href]="treeLeavesPath" />
                        </svg>
                  </div>
            </div>
      </div>
</div>