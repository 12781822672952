import { Component, OnInit } from '@angular/core';
import { MultiMediaBasicInfo } from 'src/app/common/models/MultiMediaBasicInfo';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { TooltipContentBaseComponent } from '../../../tooltip/tooltip-content-base/tooltip-content-base.component';

@Component({
  selector: 'app-event-detail-individual',
  templateUrl: './event-detail-individual.component.html',
  styleUrls: ['./event-detail-individual.component.scss', '../../events-main/events-main.component.scss']
})
export class EventDetailIndividualComponent extends TooltipContentBaseComponent  implements OnInit {

  public data: any;
  eventIndividuals: IndividualViewBase[];
  currentIndex  : number = 0;
  currentIndividualRecord: IndividualViewBase;

  ngOnInit(): void {
    this.eventIndividuals = new Array<IndividualViewBase>();
    this.eventIndividuals = this.data.value;
    this.currentIndividualRecord = new IndividualViewBase();   
    this.currentIndividualRecord = this.eventIndividuals[this.currentIndex];
  }

  getIndividualImageUrl(){
    let url = (this.currentIndividualRecord?.profileImageUrl != null) ? this.currentIndividualRecord?.profileImageUrl
     : (this.currentIndividualRecord.gender == "M" ? "assets/images/Male.png" : "assets/images/Female.png");
    return url;
  }
  
  slideLeft(){
    this.currentIndex--;
    if ( this.currentIndex < 0 ){
      this.currentIndex = this.eventIndividuals.length -1;
    }  
    this.currentIndividualRecord = this.eventIndividuals[this.currentIndex];  
  }
  
  slideRight(){
    this.currentIndex++;
    if ( this.currentIndex >= this.eventIndividuals.length ){
      this.currentIndex = 0;
    }    
    this.currentIndividualRecord = this.eventIndividuals[this.currentIndex];
  }
}
