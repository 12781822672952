import { EventType } from "src/app/common/enums/enums";
import { TimelineConfigModel } from "src/app/common/models/report/timelinereport/timeline-config-model";
import { TimelineIconModel } from "src/app/common/models/report/timelinereport/timeline-icon-model";
import { TimelineTextModel } from "src/app/common/models/report/timelinereport/timeline-text-model";

export class TimelineUtils {
    
    public static getTextElement(
        textValue: string,
        sectionType: string,
        textType: string,
        config: TimelineConfigModel['event']['eventBox'],
    ): TimelineTextModel {
        const contentConfig = config[sectionType].contents[textType];
        let textElement: TimelineTextModel = {
            textContent: textValue,
            fontSizeValue: contentConfig.text.fontSizeValue,
            fontFamilyValue: contentConfig.text.fontFamilyValue,
            fontWeightValue: contentConfig.text.fontWeightValue,
            fillValue: contentConfig.text.fillValue,
            anchorValue: contentConfig.text.anchorValue,
            xPosition: contentConfig.xPosition,
            yPosition: contentConfig.yPosition,
            offsetXPosition: contentConfig.text.offsetXPosition,
            offsetYPosition: contentConfig.text.offsetYPosition,
            maxCharacterLength : contentConfig.maxCharacterLength
        };
        return textElement;
    }

    public static getIconElement(
        iconType: number,
        sectionType: string,
        config: TimelineConfigModel['event']['eventBox']
    ): TimelineIconModel {
        const iconConfig = config[sectionType].contents.icon;
        let iconElement: TimelineIconModel = {
            img: iconConfig[`${EventType[iconType].toLowerCase()}ImageName`],
            xPosition: iconConfig.xPosition,
            yPosition: iconConfig.yPosition,
            offsetXPosition: iconConfig.offsetXPosition,
            offsetYPosition: iconConfig.offsetYPosition
        };
        return iconElement;
    }
}