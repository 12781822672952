export abstract class Connector {
    public startX: number;
    public startY: number;
    public points: { x: number, y: number }[];

    constructor(startX: number, startY: number, points: { x: number, y: number }[]) {
        this.startX = startX;
        this.startY = startY;
        this.points = points;
    }

    abstract getPaths(): string[];
}

export class RectangularConnector extends Connector {

    constructor(startX: number, startY: number, points: { x: number, y: number }[]) {
        super(startX, startY, points);
    }

    getPaths(): string[] {
        let paths = [];

        this.points.forEach(point => {
            paths.push(`M ${this.startX},${this.startY} L ${this.startX},${(this.startY + point.y) / 2} L ${point.x},${(this.startY + point.y) / 2} L ${point.x},${point.y}`);
        });
        return paths;
    }
}

export class LineConnector extends Connector {

    constructor(startX: number, startY: number, points: { x: number, y: number }[]) {
        super(startX, startY, points);
    }

    getPaths(): string[] {
        let paths = [];

        this.points.forEach(point => {
            paths.push(`M ${this.startX},${this.startY} L ${point.x},${point.y}`);
        });
        return paths;
    }
}
