import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../common/dialog/dialog-base/dialog-base.component';

@Component({
  selector: 'app-family-project-option-main',
  templateUrl: './family-project-option-main.component.html',
  styleUrls: ['./family-project-option-main.component.scss']
})
export class FamilyProjectOptionMainComponent extends DialogBaseComponent implements OnInit {
  public data: any;
  viewMode : number = 1;
  familyProjectDescription: string;
  popupTitle:string;

  constructor(dialogService: DialogService,
              private ref1: ChangeDetectorRef) {
    super(dialogService, ref1);
  }

  ngOnInit(): void {
    this.getViewMode();
  }

  getViewMode(): number {
    return this.data.viewMode;
  }

  changeViewMode(mode) {   
    this.data.viewMode = mode;
  }
  
}
