import { Injectable } from '@angular/core';

import { FetchProperty } from '../helpers/fetch-property';
import { RootMemberResponse } from '../models/RootMember';

@Injectable({
    providedIn: 'root'
})

export class RootMemberMapper {

    constructor(
        public fp: FetchProperty
    ) { }

    public map(obj: Object): RootMemberResponse {
        return {
            Id: this.fp.get(obj, 'id'),
            FirstName: this.fp.get(obj, 'givenName'),
            LastName: this.fp.get(obj, 'surname'),
            DisplayName: this.fp.get(obj, 'displayName'),
            Gender: this.fp.get(obj, 'gender'),
            defaultFamilyId:this.fp.get(obj,'defaultFamilyId'),
            husbandDisplayName:this.fp.get(obj,'husbandDisplayName'),
            wifeDisplayName:this.fp.get(obj,'wifeDisplayName'),

        };
    }
}
