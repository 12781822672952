import { Component, OnInit, Input, KeyValueDiffers, EventEmitter, Output, SimpleChanges, DoCheck, OnDestroy } from '@angular/core';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { AgeCalculator } from 'src/app/common/helpers/Family/age-calculator-helper';
import { FamilyComponentModel } from '../models/family-component-model';
import { FamilyIndividual } from '../models/family-individual';
import { ChildRelationshipType, DropMenuAction, EditorMode, FamilyIndividuals, MultimediaTypes, NotifierEvents, ReferenceType, RelationShipType } from 'src/app/common/enums/enums';
import { FamilyComponentViewModel } from '../models/family-component-view-model';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { DropMenu } from '../../common/drop-menu/models/DropMenu';
import { DropMenuItem } from '../../common/drop-menu/models/DropMenuItem';
import { EventBase } from 'src/app/common/models/event/event-base';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { PlaceBase } from 'src/app/common/models/place/place-base';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { TranslateService } from '@ngx-translate/core';
import { KeyValueDiffer } from '@angular/core';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { FTDate } from 'src/app/common/models/FTDate';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { IndividualComponentModel } from '../../individualeditor/models/individual-component-model';
import { ComponentBase } from '../../common/base/component-base';
import { MediaItem } from '../../common/media-carousel/models/media-item';
import { Router } from '@angular/router';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { DateHelper } from 'src/app/common/helpers/date-helper';
import { PreferencesService } from 'src/app/services/preferences.service';
import { Validate } from 'src/app/common/helpers/validate';

@Component({
  selector: 'app-family-editor',
  templateUrl: './family-editor.component.html',
  styleUrls: ['./family-editor.component.scss'],

})
export class FamilyEditorComponent extends ComponentBase implements OnInit, DoCheck, OnDestroy {

  // Variable declaration
  errorItems: any;
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";
  defaultImage: string = "assets/images/no-image.jpg";
  defaultCarouselImage: string = "assets/images/family.svg";
  private familyDiffer: KeyValueDiffer<string, any>;
  marriageAgeText: string;
  defaultView: boolean;
  familyId: number = 0;
  currentEditorMode: EditorMode;
  ngDocheckCount: number = 0;
  isDirty: boolean;
  componentName = 'FamilyEditor';
  @Output() public showMain: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Object declaration
  familyModelBeforeChange: FamilyComponentModel;
  familyModel: FamilyComponentModel;
  cacheFamilyModel: FamilyComponentModel;
  familyViewModel: FamilyComponentViewModel;
  individualModel: IndividualComponentModel;
  dropMenu: DropMenu;
  marriageEventDate: EventBase;

  inMedia: Array<Multimedia> = [];
  inImages: Array<Multimedia> = [];

  marriageEventPlace: any;
  relationShipType = RelationShipType;


  //Testing purpose
  selectedIndividual: IndividualViewBase;

  familyIdChangeSubscription = new Subscription();
  familyApiServiceSubscription = new Subscription();
  detachChildConfirmationServiceSubscription = new Subscription();
  detachParentConfirmationServiceSubscription = new Subscription();
  unlinkMultimediaConfirmationServiceSubscription = new Subscription();
  rootMemberSubscription = new Subscription();
  canChangeRequestSubscription = new Subscription();
  individualApiServiceSubscription = new Subscription();
  otherMedia: any[];
  isMediaCarouselOpen: boolean = true;
  disableForm: boolean = false;
  dateHelper: DateHelper;
  
  @Input() data: any;
  @Output() changeData = new EventEmitter();
  @Output() setTitle = new EventEmitter();
  ascendentList: any;
  decendetsList: any;

  mediaItems: MediaItem[];

  isScrolled: Subject<boolean> = new Subject<boolean>();

  constructor(
    private familyApiService: FamilyApiService,
    private ageCalculator: AgeCalculator,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private translateHandler: TranslateHandler,
    private parentCommunicatorService: ParentCommunicatorService,
    private translateService: TranslateService,
    private differs: KeyValueDiffers,
    private messageDialogService: MessageDialogService,
    private individualApiService: IndividualApiService,
    private router: Router,
    notifierService: NotifierV2Service,
    private preferenceService: PreferencesService,
    private validate: Validate) {
    super(notifierService);
    this.familyViewModel = new FamilyComponentViewModel();
    this.marriageEventDate = new EventBase();
    this.individualModel = new IndividualComponentModel();
    this.dateHelper = new DateHelper(preferenceService);
    this.validate = new Validate();
    // subscribe to familyEditorService.onLoad() : boolean
  }

  // Options to navigate to family Editor
  // 1. Tree Editor : Family Id (can be taken from tree editor)
  // 2. Left Panel: Family Id
  // 3. From Editor Header: Root Person Id

  ngOnInit(): void {
    this.defaultView = false;
    this.otherMedia = new Array<Multimedia>();
    this.mediaItems = new Array<MediaItem>();

    if (this.data.id == 0 && this.data.individualId > 0) {
      this.fillChildrenInfo(this.data.individualId);
    }
    else {
      this.data.id = this.data.id > 0 ? this.data.id : this.notifierService.getCurrentRootFamilyId();
      this.getFamilyInfo();
    }
    this.data.rootFamilyId = this.data.id
    this.data.editorMode = EditorMode.FamilyEditor
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes['data'];
    if (change) {
      if (this.data.id == 0 && this.data.individualId > 0) {
        this.fillChildrenInfo(this.data.individualId);
      }
      if (change.previousValue?.rootFamilyId != change.currentValue?.rootFamilyId) {
        this.data.id = change.currentValue.rootFamilyId
      }
      this.getFamilyInfo();
    }
  }
  getGridTemplateRows(): string {
    return `repeat(${Math.ceil(this.familyViewModel?.children?.length/2)}, auto)`;
  }
  getIndividualDropMenu(reference: any) {
    this.dropMenu = new DropMenu();
    this.dropMenu.ref = reference;
    this.dropMenu.menuItems = [
      <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
    ];
    return this.dropMenu;
  }

  getOtherMediaDropMenu(reference: any) {
    this.dropMenu = new DropMenu();
    this.dropMenu.ref = reference;
    this.dropMenu.menuItems = [
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
    ];
    return this.dropMenu;
  }

  getImageMediaUrl(media: Multimedia) {
    let timeStamp = performance.now();
    let url = (media.mediaUrl != null) ? media.mediaUrl + '&v=' + timeStamp : media.mediaUrl;
    return media.mediaType === MultimediaTypes.PHOTO ? url ?? "/assets/images/Male.png" : "assets/nonimage-preview/Large-Icons/media_type_" + media.mediaType + ".svg"
  }

  //Get family informations
  getFamilyInfo(loadCurrentFamily: boolean = false) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    if (!loadCurrentFamily)
      this.familyId = this.data.id;
    this.familyApiServiceSubscription = this.familyApiService.getBaseById(this.familyId).subscribe((response: ResponseModel<FamilyComponentModel>) => {
      this.familyModel = { ...new FamilyComponentModel(), ...response.data };
      this.isDirty = false;
      this.data.isEditorDirty = false;
      this.ngDocheckCount = 0;
      this.defaultView = false;
      this.mapUI(this.familyModel);
      this.marriageAgeText = this.translateHandler.translate('lbl_husband_and_wife_age_at_marriage', [this.familyViewModel.husbandMarriageAge, this.familyViewModel.wifeMarriageAge])
      this.setTitle.emit(this.getEventPopupTitle());
    },
      (error) => {
        this.defaultView = true;
        this.familyModel = { ...new FamilyComponentModel(), ...new ResponseModel<FamilyComponentModel>() };
        this.isDirty = false;
        this.data.isEditorDirty = false;
        this.ngDocheckCount = 0;
        this.mapUI(this.familyModel);
      }
    ).add(() => {
      this.loadingService.hide(processId)
    });
  }

  // Map FamilyComponentModel to FamilyComponentViewModel
  mapUI(familyComponentModel: FamilyComponentModel) {
    this.familyViewModel = familyComponentModel as FamilyComponentViewModel;
    if (this.familyViewModel.children) {
      //Set relationship type text
      this.familyViewModel.children.map(p => p.relationShipType != null ? p.relationShipType = ChildRelationshipType[p.relationShipType] : this.familyViewModel.children);
      this.familyViewModel.children?.forEach(child => {
        child.profileImageUrl = child.profileImageUrl === "" || child.profileImageUrl === undefined ? this.getDefaultImageString(child.gender) : child.profileImageUrl;
        this.setDateFormat(child);
      })
    }

    let isPrimaryImage = this.familyViewModel.multimedia?.find(t => t.isPrimary == true && t.isNonProfile == false) ?? null;
    if (!isPrimaryImage && this.familyViewModel.multimedia?.length > 0) {
      this.familyViewModel.multimedia[0].isPrimary = true;
    }

    this.familyViewModel.multimedia?.forEach(el => {
      if (el.mediaType != MultimediaTypes.PHOTO && !el.isNonProfile) {
        el.isNonProfile = true;
      }
      if (el.mediaType == MultimediaTypes.PHOTO) {
        el.mediaUrl = (el.mediaUrl != null) ? el.mediaUrl + '&v=' + performance.now() : el.mediaUrl;
      }
    })

    this.setInMedia();
    // Set default image if profileImageUrl is empty for parents
    if (this.familyViewModel.husband)
      this.familyViewModel.husband.profileImageUrl = this.familyViewModel.husband.profileImageUrl === "" || this.familyViewModel.husband.profileImageUrl === undefined ? this.getDefaultImageString(this.familyViewModel.husband.gender) : this.familyViewModel.husband.profileImageUrl;
    if (this.familyViewModel.wife)
      this.familyViewModel.wife.profileImageUrl = this.familyViewModel.wife.profileImageUrl === "" || this.familyViewModel.wife.profileImageUrl === undefined ? this.getDefaultImageString(this.familyViewModel.wife.gender) : this.familyViewModel.wife.profileImageUrl;

    this.setAgeData(this.familyViewModel);

    if (familyComponentModel?.id) {
      this.ngDocheckCount = 0;
      this.familyDiffer = this.differs.find(this.familyViewModel).create();
    }
    this.otherMedia = this.familyViewModel.multimedia;
  }

  setDateFormat(child: any) {
    if (child?.birthEvent?.date?.genealogyDate?.originalStr != null) {
      const originalStr = child.birthEvent.date.genealogyDate.originalStr;
      const formattedResult = this.dateHelper?.validateAndFormat?.(originalStr);

      if (formattedResult && typeof formattedResult.formattedText === 'string') {
        child.birthEvent.date.genealogyDate.originalStr = formattedResult.formattedText;
      }
    }
  
  }
  //Fill children informations
  fillChildrenInfo(individualId: number) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.individualApiServiceSubscription = this.individualApiService.getIndividualById(individualId).subscribe((response: ResponseModel<IndividualComponentModel>) => {

      this.individualModel = response.data;

      //Create new family model 
      this.familyModel = new FamilyComponentModel();
      this.familyModel.children = new Array<FamilyIndividual>();
      this.familyModel.multimedia = new Array<Multimedia>();
      this.familyModel.marriageEvent = new EventBase();
      this.familyModel.marriageEvent.place = new PlaceBase();

      //create new family children and add to familymodel
      let newChild = new FamilyIndividual();
      newChild.id = this.individualModel.id;
      newChild.displayName = this.individualModel.givenName + " " + this.individualModel?.surname;
      let profileImage = this.individualModel?.multimedia?.find(t => t.isPrimary == true && t.isNonProfile == false);
      newChild.profileImageUrl = profileImage != null ? profileImage.mediaUrl : "";
      newChild.gender = this.individualModel.gender;
      newChild.relationShipType = ChildRelationshipType.bloodline.toString();
      newChild.isDefault = true;
      newChild.birthEvent = this.individualModel.birthEvent;
      newChild.deathEvent = this.individualModel.deathEvent;

      //Add child to family model
      this.familyModel.children.push(newChild);

      this.isDirty = false;
      this.data.isEditorDirty = false;
      this.ngDocheckCount = 0;
      this.defaultView = false;
      this.mapUI(this.familyModel);
      this.marriageAgeText = this.translateHandler.translate('lbl_husband_and_wife_age_at_marriage', [this.familyViewModel.husbandMarriageAge, this.familyViewModel.wifeMarriageAge])
      this.setTitle.emit(this.getEventPopupTitle());
    },
      (error) => {
        this.defaultView = true;
        this.familyModel = { ...new FamilyComponentModel(), ...new ResponseModel<FamilyComponentModel>() };
        this.isDirty = false;
        this.data.isEditorDirty = false;
        this.ngDocheckCount = 0;
        this.mapUI(this.familyModel);
      }
    ).add(() => {
      this.loadingService.hide(processId)
    });
  }

  getInImages() {
    return this.familyViewModel?.multimedia?.filter(t => t.mediaType == MultimediaTypes.PHOTO && t.isNonProfile == false) ?? [];
  }

  //Todo: Keep for future reference, currently not in use
  getInMedia() {
    let mediaList = this.familyViewModel?.multimedia?.filter(t => t.isNonProfile == true).
      map(item => {
        item.mediaUrl = (item.mediaType != MultimediaTypes.PHOTO) ? "assets/nonimage-preview/Large-Icons/media_type_" + item.mediaType + ".svg" : item.mediaUrl
        return item
      }) ?? [];
  }

  setInMedia() {
    this.mediaItems = this.familyViewModel?.multimedia?.filter(t => t.isNonProfile == true).
      map(item => {
        item.mediaUrl = (item.mediaType != MultimediaTypes.PHOTO) ? "assets/nonimage-preview/Large-Icons/media_type_" + item.mediaType + ".svg" : item.mediaUrl
        let mediaItem = new MediaItem(item.id, (item.caption ? item.caption : item.fileName), item.mediaUrl, this.createDropMenu(item));
        return mediaItem
      }) ?? [];
  }

  createDropMenu(ref: Multimedia) {
    let dropMenu = new DropMenu();
    dropMenu.ref = ref;
    dropMenu.menuItems = [<DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Download, isEnabled: true, isVisible: true }
    ];
    return dropMenu;
  }

  // Calculate age of marriage husband and wife
  setAgeData(familyViewModel: FamilyComponentViewModel) {
    let husbandAgeAtMarriage = this.ageCalculator.calculate(familyViewModel?.marriageEvent, familyViewModel?.husband?.birthEvent?.date);
    let wifeAgeAtMarriage = this.ageCalculator.calculate(familyViewModel?.marriageEvent, familyViewModel?.wife?.birthEvent?.date);

    this.familyViewModel.husbandMarriageAge = husbandAgeAtMarriage != null ? husbandAgeAtMarriage : this.translateHandler.translate("lbl_unknown");
    this.familyViewModel.wifeMarriageAge = wifeAgeAtMarriage != null ? wifeAgeAtMarriage : this.translateHandler.translate("lbl_unknown");
  }

  // Loads the component data (ex: Family)
  onLoad() {
  }

  //use when item loading error
  onItemError(event: any) {
    event.target.src = this.errorImg;
  }

  carouselActionClicked($event) {
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if (action == DropMenuAction.Upload) {
      // Set view mode as 1 (Gallery View)
      this.addImage(0, 2);
    }
    if (action == DropMenuAction.Gallery) {
      // Set view mode as 2 (Edit View)
      this.addImage(0, 1);
    }
    if (action == DropMenuAction.Edit) {
      // Set view mode as 2 (Edit View)
      this.addImage(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          this.unlinkImage(selectedMultimedia);
        }
      });
    }
    if (action == DropMenuAction.SetAsPrimary) {
      this.setImageAsPrimary(selectedMultimedia);
    }
  }

  setImageAsPrimary(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      if (!selectedMultimedia.isPrimary) {
        this.familyModel.multimedia.map(m => m.isPrimary = m.id === selectedMultimedia.id);
      }
    }
  }

  unlinkImage(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      let index = this.familyModel.multimedia.findIndex(i => i.id === selectedMultimedia.id);
      this.familyModel.multimedia.splice(index, 1);
      this.familyModel.multimedia = [...this.familyModel.multimedia];

      let indexOfIds = this.familyModel.multimediaIds.findIndex(i => i === selectedMultimedia.id);
      this.familyModel.multimediaIds.splice(indexOfIds, 1);
      this.familyModel.multimediaIds = [...this.familyModel.multimediaIds];

      this.setNextImageAsPrimary(index);
    }
  }

  mediaCarouselActionClicked($event) {
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if (action == DropMenuAction.Edit) {
      // Set view mode as 2 (Edit View)
      this.addMedia(selectedMultimedia.id, 2);
    }
    if (action == DropMenuAction.Unlink) {
      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_confirmation_body");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          this.unlinkMedia(selectedMultimedia);
        }
      });
    }
  }
  toggleMediaCarousel() {
    this.isMediaCarouselOpen = !this.isMediaCarouselOpen;
  }

  unlinkMedia(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      let index = this.familyModel.multimedia.findIndex(i => i.id === selectedMultimedia.id);
      this.familyModel.multimedia.splice(index, 1);
      this.familyModel.multimedia = [...this.familyModel.multimedia];

      let indexOfIds = this.familyModel.multimediaIds.findIndex(i => i === selectedMultimedia.id);
      this.familyModel.multimediaIds.splice(indexOfIds, 1);
      this.familyModel.multimediaIds = [...this.familyModel.multimediaIds];

      this.setInMedia()
    }
  }

  //Adding Image
  addImage(mediaId: any, viewMode: number) {
    this.dialogService.setHalf().open("Multimedia",
      {
        // Set id to 0 if StandAlone
        id: this.familyViewModel.multimedia.find(t => t.isPrimary)?.id ?? 0,
        reference: {
          id: this.familyId, // Family ID
          type: ReferenceType.Family // Family type
        },
        title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_pictures").toLowerCase(),
        [this.familyViewModel?.husband?.displayName,
        this.familyViewModel?.wife?.displayName,
        this.translateHandler.translate("lbl_family")].join(" ")]) ?? "", // marriage place for + <name>
        mediaType: [MultimediaTypes.PHOTO],
        viewMode: viewMode, // Gallery or editor view
        editMediaId: mediaId, // MediaId which supposes to be edited
        isStandAlone: false
      }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.familyViewModel.multimedia.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.familyViewModel.multimedia.splice(index, 1);
              this.setNextImageAsPrimary(index);
            }
          });

          if (response.data.newMedia) {
            response.data.newMedia.isNonProfile = false;
            response.data.newMedia.isNewlyAdded = true;
            response.data.newMedia.is
            let mediaIndex = this.familyViewModel.multimedia.findIndex(t => t.id == response.data.newMedia.id);
            if (mediaIndex < 0)
              this.familyViewModel.multimedia.push(response.data.newMedia);
            else {
              this.familyViewModel.multimedia[mediaIndex] = response.data.newMedia;
            }
            // When adding new image to image carsoul , set this as primary
            this.familyViewModel.multimedia.map(m => m.isPrimary = m.id === response.data.newMedia.id);
          }
          this.setInMedia();
          this.isDirty = true;
        }
      })
  }

  //Adding all media
  addMedia(mediaId: any, viewMode: number) {
    this.dialogService.setHalf().open("Multimedia",
      {
        id: 0,
        reference: {
          id: this.familyId, // Family ID
          type: ReferenceType.Family // Family type
        },
        title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_media"),
        [this.familyViewModel?.husband?.displayName,
        this.familyViewModel?.wife?.displayName, this.
          translateHandler.translate("lbl_family")].join(" ")]) ?? "", // marriage place for + <name>
        mediaType: [MultimediaTypes.PHOTO, MultimediaTypes.DOC, MultimediaTypes.AUDIO, MultimediaTypes.VIDEO],
        viewMode: viewMode,
        editMediaId: mediaId, // MediaId which supposes to be edited
        isStandAlone: false
      }
    )
      .subscribe(response => {
        if (response.data) {
          response.data.deleteMedias.forEach(element => {
            let index = this.familyViewModel.multimedia.findIndex(i => i.id === element.id);
            if (index >= 0) {
              this.familyViewModel.multimedia.splice(index, 1);
            }

          });

          if (response.data.newMedia) {
            let newMedia = response.data.newMedia;
            newMedia.isNonProfile = true;
            newMedia.isNewlyAdded = true;
            newMedia.mediaUrl = this.getImageMediaUrl(newMedia);
            let mediaIndex = this.familyViewModel.multimedia.findIndex(t => t.id == newMedia.id);
            if (mediaIndex < 0)
              this.familyViewModel.multimedia.push(newMedia);
            else {
              this.familyViewModel.multimedia[mediaIndex] = newMedia;
            }
          }
          this.setInMedia();
        }
      })
  }

  //Set next image when primary image unlinked
  setNextImageAsPrimary(imageIndex: number) {
    let isPrimaryImage = this.familyViewModel.multimedia?.find(t => t.isPrimary == true) ?? null;
    if (!isPrimaryImage && this.familyViewModel.multimedia.length > 0) {
      if (this.familyViewModel.multimedia[imageIndex > 0 ? (imageIndex - 1) : 0].isNonProfile) {
        this.familyViewModel.multimedia.find(t => t.mediaType == MultimediaTypes.PHOTO && !t.isNonProfile).isPrimary = true;
      } else {
        this.familyViewModel.multimedia[imageIndex > 0 ? (imageIndex - 1) : 0].isPrimary = true;
      }
    }
  }

  parentMenuClicked($event, relationShipType) {
    if (DropMenuAction.Unlink === $event.action) {
      // Unlink parent confirmation box
      let unlinkMessage = this.translateHandler.translate("lbl_unlink_member");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          if (this.familyModel.husbandId === $event.reference.id) {
            this.familyModel.husbandId = 0;
            this.familyModel.husband = null;
          }
          if (this.familyModel.wifeId === $event.reference.id) {
            this.familyModel.wifeId = 0;
            this.familyModel.wife = null;
          }
        }
      });
    }

    if (DropMenuAction.Edit === $event.action) {
      let data = {
        id: $event.reference.id,
        editorMode: EditorMode.IndividualEditor,
        isUpdateHistory: true
      }
      if (this.familyModel.husbandId === $event.reference.id) {
        let notifierData = {
          id: this.familyModel.husband.id,
          displayName: this.familyModel.husband.displayName
        }
        this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, notifierData);
      } else if (this.familyModel.wifeId === $event.reference.id) {
        let notifierData = {
          id: this.familyModel.wife.id,
          displayName: this.familyModel.wife.displayName
        }
        this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, notifierData);
      }
      this.notifierService.setCurrentEditor(`/editor/individualeditor`, data, null);
    }
  }

  childMenuClicked($event) {
    if (DropMenuAction.Unlink === $event.action) {
      // Unlink child confirmation box
      let unlinkMessage = this.translateHandler.translate("lbl_unlink_member");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          let childIndex = this.familyModel.children.findIndex(x => x.id === $event.reference.id);
          if (childIndex >= 0) {
            this.familyModel.children.splice(childIndex, 1);
            this.familyModel.children = [...this.familyModel.children];
          }
        }
      });
    }

    if (DropMenuAction.Edit === $event.action) {
      let data = {
        id: $event.reference.id,
        editorMode: EditorMode.IndividualEditor,
        family: {},
        isUpdateHistory: true
      }
      let SelectedChild = this.familyModel.children.find(child => child.id == $event.reference.id);
      let notifierData = {
        id: SelectedChild.id,
        displayName: SelectedChild.displayName
      }
      this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, notifierData);
      this.notifierService.setCurrentEditor(`/editor/individualeditor`, data, null);
    }
  }

  addChild() {
    this.dialogService.setHalf().open("IndividualMain",
      {
        id: 0,
        title: this.translateHandler.translate('lbl_add_child_to_family', [(this.familyViewModel.husband !== null ? this.familyViewModel.husband.displayName : ""), (this.familyViewModel.wife !== null ? this.familyViewModel.wife.displayName : "")]),
        relationshipMode: FamilyIndividuals.Child,
        familyId: this.familyId,
        viewMode: 2,
        showMode: [1, 2]
      }).subscribe(response => {
        if (!response.data) {
          return null;
        }
        let newChild = new FamilyIndividual();
        newChild = response.data;
        //Check child already exist selected family
        let isChildExist = this.familyViewModel.children.find(i => i.id === response.data.id);
        if (!isChildExist) {
          if (newChild.isDefault || newChild.relationShipType == 'bloodline') {
            let processId = GuidGenerator.generate();
            this.loadingService.show(processId);
            this.getAscendentIds().subscribe((response: ResponseModel<any>) => {
              if (response.data) {
                this.ascendentList = response.data;
                let recurringId = this.ascendentList.find(id => id === newChild.id);
                if (recurringId) {
                  this.messageDialogService.openError(this.translateHandler.translate('lbl_circular_reference'), this.translateHandler.translate('lbl_circular_reference_message'), null).subscribe((res: boolean) => { })
                  return;
                }
              }
            },
              (err) => {
                throw new CustomError("FamilyEditorComponent => getAscendentIds() : " + err, 911, false);
              }).add(() => {
                this.loadingService.hide(processId);
              })
          }
          this.familyViewModel.children.push(newChild);
          this.familyApiService.updateChildRelationship(this.familyViewModel).subscribe((response) => { })
          this.defaultView = false;
        }
        else {
          this.messageDialogService.openError(this.translateHandler.translate('lbl_already_exists'), this.translateHandler.translate('val_child_already_exists'), null).subscribe((res: boolean) => { })
        }
      })
  }

  getAscendentIds(): Observable<any> {
    if (this.familyModel.id > 0)
      return this.familyApiService.getAscendantsIds(this.familyModel.id);
  }

  getDescendantIds(newParent, parentType) {
    if (this.familyViewModel.children.length > 0) {
      let member = this.familyViewModel.children[0].id;
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      this.familyApiService.getDescendantsIds(member).subscribe((response: ResponseModel<any>) => {
        if (response.data) {
          this.decendetsList = response.data;
          let recurringId = this.decendetsList.find(id => id === newParent.id);
          if (recurringId) {
            this.messageDialogService.openError(this.translateHandler.translate('lbl_circular_reference'), this.translateHandler.translate('lbl_circular_reference_message'), null).subscribe((res: boolean) => { })
            return;
          }
          if (parentType == RelationShipType.Mother) {
            this.familyViewModel.wifeId = newParent.id;
            this.familyViewModel.wife = { ...newParent };
            this.familyViewModel.wife.profileImageUrl = (newParent.profileImageUrl) ? newParent.profileImageUrl : this.getDefaultImageString(newParent.gender);
          }
          if (parentType == RelationShipType.Father) {
            this.familyViewModel.husbandId = newParent.id;
            this.familyViewModel.husband = { ...newParent };
            this.familyViewModel.husband.profileImageUrl = (newParent.profileImageUrl) ? newParent.profileImageUrl : this.getDefaultImageString(newParent.gender);
          }
          this.defaultView = false;
        }
      },
        (err) => {
          throw new CustomError("FamilyEditorComponent => getDescendantIds() : " + err, 911, false);
        }).add(() => {
          this.loadingService.hide(processId);
        })
    }
  }

  addMother() {
    this.dialogService.open("IndividualMain",
      {
        id: 0,
        title: this.translateHandler.translate('lbl_add_person_to_family', [(this.familyViewModel.husband !== null ? this.familyViewModel.husband.displayName : ""), (this.familyViewModel.wife !== null ? this.familyViewModel.wife.displayName : "")]),
        relationshipMode: FamilyIndividuals.Parent,
        viewMode: 1,
        showMode: [1]
      }).subscribe(response => {
        if (!response.data) {
          return null;
        }
        let newMother = response.data;

        if (this.familyViewModel?.husband?.id === response.data.id)
          this.messageDialogService.openError(this.translateHandler.translate('lbl_already_exists'), this.translateHandler.translate('val_parent_already_exists'), null).subscribe((res: boolean) => { })
        else
          this.getDescendantIds(newMother, RelationShipType.Mother)
      });
  }

  addFather() {
    this.dialogService.open("IndividualMain",
      {
        id: 0,
        title: this.translateHandler.translate('lbl_add_person_to_family', [(this.familyViewModel.husband !== null ? this.familyViewModel.husband.displayName : ""), (this.familyViewModel.wife !== null ? this.familyViewModel.wife.displayName : "")]),
        relationshipMode: FamilyIndividuals.Parent,
        viewMode: 1,
        showMode: [1]
      }).subscribe(response => {
        if (!response.data)
          return null;
        let newFather = response.data;

        if (this.familyViewModel?.wife?.id === response.data.id)
          this.messageDialogService.openError(this.translateHandler.translate('lbl_already_exists'), this.translateHandler.translate('val_parent_already_exists'), null).subscribe((res: boolean) => { })
        else
          this.getDescendantIds(newFather, RelationShipType.Father)
      });
  }

  setFormatedDate($event) {
    this.familyModel.marriageEvent.date ? this.familyModel.marriageEvent.date : this.familyModel.marriageEvent.date = new FTDate();
    const isGenealogyDateSet = this.validate.isGenealogyDateSet(this.familyModel.marriageEvent.date,$event);
    if (isGenealogyDateSet) {
      this.setAgeData(this.familyViewModel);
      this.marriageAgeText = this.translateHandler.translate('lbl_husband_and_wife_age_at_marriage', [this.familyViewModel.husbandMarriageAge, this.familyViewModel.wifeMarriageAge]);
    }
  }

  saveFamily() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    let familyObj = { ...this.familyModel }
    if (familyObj.id)
      this.updateFamilyInfo();
    else
      this.createNewFamily();
    this.loadingService.hide(processId);
}

  //update family
  updateFamilyInfo() {
    let familyObj = { ...this.familyModel }
    this.familyApiService.update(familyObj).subscribe((response: any) => {
      this.isDirty = false;
      this.data.isEditorDirty = false;
      this.data.id = familyObj.id;
      this.data.isSameId = true;
      this.changeData.next(this.data);
      this.notifierService.updateEvent(NotifierEvents.RootFamilyUpdated, familyObj)
    }, (err) => {
      throw new CustomError("Family Editor => updateFamilyInfo() : " + err, 911, false);
    });
  }

  //create new family
  createNewFamily() {
    let familyObj = { ...this.familyModel }
    this.familyApiService.create(familyObj).subscribe((response: any) => {
      this.isDirty = false;
      this.data.isEditorDirty = false;
      this.familyModel.id = response.data;
      this.notifierService.addFamilyHistory(this.familyModel.id);
      this.data.id = this.familyModel.id;
      this.data.isSameId = true;
      this.changeData.next(this.data);
    }, (err) => {
      throw new CustomError("Family Editor => createFamilyInfo() : " + err, 911, false);
    });
  }

  getPlacePopupTitle() {
    return this.translateHandler.translate("lbl_link_place", [this.translateHandler.translate("lbl_marriage"),
    [this.familyViewModel?.husband?.displayName, this.familyViewModel?.wife?.displayName, this.translateHandler.translate("lbl_family")].join(" ")]) ?? ""
  }

  getEventPopupTitle() {
    return this.translateHandler.translate('lbl_event_title_family', [this.familyViewModel?.husband?.displayName, this.familyViewModel?.wife?.displayName]);
  }

  cancel() {
    if (this.data.id !== 0) {
      this.getFamilyInfo(true);
    }
  }

  ngOnDestroy(): void {
    this.familyIdChangeSubscription.unsubscribe();
    this.familyApiServiceSubscription.unsubscribe();
    this.detachChildConfirmationServiceSubscription.unsubscribe();
    this.detachParentConfirmationServiceSubscription.unsubscribe();
    this.unlinkMultimediaConfirmationServiceSubscription.unsubscribe();
    this.rootMemberSubscription.unsubscribe();
    this.canChangeRequestSubscription.unsubscribe();
    this.individualApiServiceSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  showNotImplemented() {
    this.parentCommunicatorService.send(ParentMessageType.ShowInformation, "not_implemented");
  }

  ngDoCheck(): void {
    this.disableForm = this.data.isEventDirty;
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.  
    if (this.familyDiffer) {
      let changes = this.familyDiffer.diff(this.familyModel);
      if (changes) {
        if (this.ngDocheckCount > 0) {
          this.isDirty = true;
          this.data.isEditorDirty = true;
        }
        this.ngDocheckCount++
      }
    }
  }

  setPlaceForEvent(place: any) {
    if (this.familyModel && this.familyModel.marriageEvent) {
      this.familyModel.marriageEvent = { ...this.familyModel.marriageEvent };
    }
  }

  getMultimediaDropMenu() {
    return [
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Unlink, isEnabled: true, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Download, isEnabled: true, isVisible: true }
    ];
  }


  public canChange(): boolean {
    return !this.isDirty;
  }

  // A temporary solution
  public navigateToReportEngine(): void {
    this.router.navigateByUrl("/reportengine?name=pedigree");
  }

  onContainerScroll($event: any) {
    this.isScrolled.next(true);
  }

  // Function that returns a image path string according to the gender
  getDefaultImageString(gender: string): string {
    if (gender === "M")
      return "assets/images/Male.png"
    else if (gender === "F")
      return "assets/images/Female.png"
    else
      return "assets/images/unknown.svg"
  }
}
