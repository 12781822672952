<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title" style="font-weight:normal;">{{settingsTitle}}</span>
        </div>
        <div class="ft-form-row">
            <span *ngIf="data.viewMode==1" class="title" style="font-weight:300; font-size: 17px;" >{{"lbl_project_settings_message" | translate}}</span>
        </div>
        <div *ngIf="data.viewMode==2" class="ft-form-row col1-v100">
            <div>
                <div class="ft-form-input">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap;gap:10px">
                        <div class="input-radio-container">
                            <label>{{"lbl_apply_only_this_tree" | translate}}</label>
                            <input type="radio" name="preferenceType" value="Project"
                                [(ngModel)]="preferenceType" (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                        <div class="input-radio-container">
                            <label>{{"lbl_apply_all_trees" | translate}}</label>
                            <input type="radio" name="preferenceType" value="All"
                                [(ngModel)]="preferenceType" (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ft-form-row col1-v100" style="margin-top: 10px;margin-bottom: 20px;">
            <div class="ft-form-input">
                <label>{{"lbl_name_formatting" | translate}}</label>
                <input type="text" class="formatted-text" readonly [(ngModel)]="formattedName">
            </div>
        </div>

        <div class="ft-form-row col1-v100" style="margin-top: 10px;">
            <div>
                <div class="ft-form-input">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap;gap:10px">
                        <div class="input-radio-container">
                            <label>{{"lbl_firstname_lastname" | translate}}</label>
                            <input type="radio" name="nameFormat" value="firstLast"
                                [(ngModel)]="preferences.nameOrder" (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                        <div class="input-radio-container">
                            <label>{{"lbl_lastname_firstname" | translate}}</label>
                            <input type="radio" name="nameFormat" value="lastFirst"
                                [(ngModel)]="preferences.nameOrder" (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                        <div class="input-radio-container">
                            <label>{{"lbl_lastname_comma_firstname" | translate}}</label>
                            <input type="radio" name="nameFormat" value="lastCommaFirst"
                                [(ngModel)]="preferences.nameOrder" (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 10px;">
            <div>
                <div class="ft-form-input">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap;gap:10px">
                        <div class="individual-flag-wrap">
                            <label class="input-checked-container input-checkbox-large">{{"lbl_surname_caps" | translate}}
                                <input class="inputcheck" type="checkbox"
                                    [(ngModel)]="preferences.showCapitalSurname" (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="individual-flag-wrap">
                            <label class="input-checked-container input-checkbox-large">{{"lbl_include_middleName" | translate}}
                                <input class="inputcheck" type="checkbox"
                                    [(ngModel)]="preferences.includeMiddleName" (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="individual-flag-wrap">
                            <label class="input-checked-container input-checkbox-large">{{"lbl_include_patronomic" | translate}}
                                <input class="inputcheck" type="checkbox"
                                    [(ngModel)]="preferences.includePatronomic" (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="individual-flag-wrap">
                            <label class="input-checked-container input-checkbox-large">{{"lbl_include_prefix_suffix" | translate}}
                                <input class="inputcheck" type="checkbox"
                                    [(ngModel)]="preferences.includePrefixSuffix" (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ft-form-row col1-v100" style="margin-top: 20px; margin-bottom: 0px;">
            <div class="ft-form-input">
                <label>{{"lbl_date_formatting" | translate}}</label>
                <input type="text" class="formatted-text" readonly [value]="formattedDate">
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 10px; width: 17%;">
            <div class="ft-form-input">
                <label [ngClass]="preferences.dateType == 'norwegian'? 'input-disabled' : ''">{{"lbl_separator" | translate}}</label>
                <select class="formatted-text" [disabled]="preferences.dateType == 'norwegian'" [(ngModel)]="preferences.dateSeperater"
                    (ngModelChange)="changePreview()">
                    <option class="formatted-text" value=" ">{{"lbl_space" | translate}}</option>
                    <option class="formatted-text" value="-">-</option>
                    <option class="formatted-text" value=".">.</option>
                    <option class="formatted-text" value="/">/</option>
                </select>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 10px">
            <div>
                <div class="ft-form-input">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap;gap:10px">
                        <div class="input-radio-container">
                            <label>{{"lbl_long_text" | translate}}</label>
                            <input type="radio" name="dateFormat" value="long" [(ngModel)]="preferences.dateType"
                                (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                        <div class="input-radio-container">
                            <label>{{"lbl_short_text" | translate}}</label>
                            <input type="radio" name="dateFormat" value="short" [(ngModel)]="preferences.dateType"
                                (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                        <div class="input-radio-container">
                            <label>{{"lbl_numeric" | translate}}</label>
                            <input type="radio" name="dateFormat" value="numeric" [(ngModel)]="preferences.dateType"
                                (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                        <div class="input-radio-container">
                            <label>{{"lbl_norwegian" | translate}}</label>
                            <input type="radio" name="dateFormat" value="norwegian"
                                [(ngModel)]="preferences.dateType" (ngModelChange)="changePreview()">
                            <span class="radiomark"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 10px">
            <div>
                <div class="ft-form-input">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap;gap:10px">
                        <div class="individual-flag-wrap">
                            <label
                                [ngClass]="preferences.dateType == 'norwegian' || preferences.dateType == 'numeric' ? 'input-disabled' : ''"
                                class="input-checked-container input-checkbox-large">{{"lbl_month_first" | translate}}
                                <input class="inputcheck" type="checkbox"
                                    [disabled]="preferences.dateType == 'norwegian' || preferences.dateType == 'numeric'"
                                    [(ngModel)]="preferences.monthFirst" (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="individual-flag-wrap">
                            <label [ngClass]="preferences.dateType == 'numeric' ? 'input-disabled' : ''"
                                class="input-checked-container input-checkbox-large">{{"lbl_date_in_caps" | translate}}
                                <input class="inputcheck" type="checkbox"
                                    [disabled]="preferences.dateType == 'numeric'"
                                    [(ngModel)]="preferences.showCapitalDate" (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="individual-flag-wrap">
                            <label class="input-checked-container input-checkbox-large">{{"lbl_two_digit_numerics" | translate}}
                                <input class="inputcheck" type="checkbox" [(ngModel)]="preferences.twoDigitNumeric"
                                    (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 20px; margin-bottom: 0px;">
            <div class="ft-form-input">
                <label>{{"lbl_place_formatting" | translate}}</label>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 10px;">
            <div>
                <div class="ft-form-input">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap;gap:10px">
                        <div class="individual-flag-wrap">
                            <label class="input-checked-container input-checkbox-large">{{"lbl_show_prepositions" | translate}}
                                <input class="inputcheck" type="checkbox" [(ngModel)]="preferences.showPrepositions"
                                    (ngModelChange)="changePreview()">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn" [ngClass]="(isFormDirty)? 'ft-btn-active' : 'ft-btn-inactive'" [disabled]="!isFormDirty" (click) = "save()">{{"lbl_save"|translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>