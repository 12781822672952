import { FamilyProjectArchiveBaseModel } from "../familyProjectArchive/family-project-archive-base-model";
import { Preference } from "../Preference";

export class FamilyProjectBaseModel {
    familyProjectId     : string;
    customerId          : string;
    mediaPath           : string;
    projectName         : string;
    projectDescription  : string;
    isDefault           : boolean;
    projectPreferences  : Preference;
    originalSourceType  : string;       // OriginalSourceType - Enum
    lastModifiedDate    : Date;
    familyProjectArchiveImport: FamilyProjectArchiveBaseModel;
    familyProjectArchiveExport: FamilyProjectArchiveBaseModel;
    isValid             : boolean;  //2022.03.09
    isImporting         : boolean;
}