import { TimelineEventsByYearDataModel } from "src/app/common/models/report/timelinereport/timeline-events-by-year-data-model";
import { TimelineModel } from "src/app/common/models/report/timelinereport/timeline-model";
import { TimelineUnitModel } from "src/app/common/models/report/timelinereport/timeline-unit-model";
import { TimelineConfigModel } from "src/app/common/models/report/timelinereport/timeline-config-model";
import { VConnectorGraphics } from "./timelinereport-vconnector-graphics";
import { EventBoxSide } from "src/app/common/enums/enums";
import { TimelineVConnectorModel } from "src/app/common/models/report/timelinereport/timeline-vConnector-model";
import { EventsGraphics } from "./timelinereport-event-graphics";
import { YearGraphics } from "./timelinereport-year-graphics";
import { TimelineEventModel } from "src/app/common/models/report/timelinereport/timeline-event-model";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { environment } from 'src/environments/environment';

export class Timeline {

  public static get(eventsByYear: TimelineEventsByYearDataModel[], config: TimelineConfigModel, translateHandler: TranslateHandler): TimelineModel {
    let units: TimelineUnitModel[] = [];
    let isEmptyEvent: boolean = false;

    // set event position, media and translate event name
    let side = EventBoxSide.Right; // Set the initial side to the right
    for (const yearNode of eventsByYear) {
      for (const event of yearNode.events) {
        const explodedName = event.name.split("-");
        let eventName = translateHandler.translate(`event_${explodedName[0].trim().toLowerCase()}`) || explodedName[0].trim();
        if (environment.production) {
          eventName = eventName.includes("MISSING TRANSLATON") ? explodedName[0].trim() : eventName;
        }
        event.name = `${eventName}${explodedName.length > 1 ? " - " + explodedName[1].trim() : ""}`;
        event.media = event.media || [];
      }
      yearNode.events.sort((a, b) => {
        if (a.date.genealogyDate.month === b.date.genealogyDate.month &&
            a.date.genealogyDate.day === b.date.genealogyDate.day &&
            a.date.genealogyDate.year === b.date.genealogyDate.year) {
          if (a.name !== "Født" && b.name !== "Født") {
            return a.name.localeCompare(b.name);
          }
        }
        return 0;
      });
      for (const event of yearNode.events) {
        event.position = side;
        // Toggle the side for the next event
        side = side === EventBoxSide.Right ? EventBoxSide.Left : EventBoxSide.Right;
      }
    }

    yearLoop:
    for (let yearValue in eventsByYear) {
      let eventNode: TimelineEventModel[] = [];
      let yearNode = YearGraphics.get(eventsByYear[yearValue].year, config.year);
      // get event graphics and add to unit
      for (let eventValue in eventsByYear[yearValue].events) {
        let eventData = eventsByYear[yearValue].events[eventValue];
        let currentEventElement = EventsGraphics.get(eventData.position, eventData.id, eventData.type, eventData.name,
          eventData.place.rawData, eventData.date, eventData.media, eventData.description, config.event);
        if (!currentEventElement) {
          isEmptyEvent = true;
          break yearLoop;
        }
        eventNode.push(currentEventElement);
      }
      units.push({
        yearNode: yearNode,
        eventNode: eventNode
      });
    }

    if (isEmptyEvent || units.length == 0) {
      return null;
    }

    let vConnector = VConnectorGraphics.get(config.vConnector);
    let timeline: TimelineModel = {
      units: units,
      vConnector: vConnector
    }
    return timeline;
  }


  /**
   * @param units - timeline units
   * @param startX  - start x position of timeline
   * @param startY  - start y position of timeline
   * @param overlapPercentage - set distance between two events (default 0:one event should place when other event ends)
   * @param yearHeight - height of year node
   * @param yearWidth - width of year node
   * @param hConnectorConfig - configuration of hConnector
   */
  public static assignUnitPositions(
    units: TimelineUnitModel[],
    startX: number,
    startY: number,
    overlapPercentage: number,
    yearHeight: number,
    yearWidth: number,
    hConnectorConfig: any
  ) {
    let xPosition = startX;
    let yPosition = startY;

    units.forEach((unit) => {
      unit.yearNode.xPosition = xPosition - (yearWidth / 2); // make year image to the center
      unit.yearNode.yPosition = yPosition;

      // set year text position to the center of year image
      unit.yearNode.text.yPosition = unit.yearNode.yPosition + yearHeight / 2;
      unit.yearNode.text.xPosition = unit.yearNode.xPosition + yearWidth / 2;
      yPosition = yPosition + yearHeight;

      unit.eventNode.forEach((event) => {
        let distanceBetweenEvents = (event.height * overlapPercentage) / 100; // calculate distance between two events
        event.yPosition = yPosition + distanceBetweenEvents;
        event.hConnector.yPosition = event.yPosition + (event.height / 2) - (hConnectorConfig.height / 2); // set hConnector y position to the center of event
        // set event and hConnector positions based on eventBoxSide
        if (event.position === EventBoxSide.Left) {
          event.xPosition = xPosition - (hConnectorConfig.width + event.width);
          event.hConnector.xPosition = xPosition - hConnectorConfig.width;
          event.hConnector.offsetXPosition *= -1;
          event.offsetXPosition *= -1;
        } else {
          event.xPosition = xPosition + hConnectorConfig.width;
          event.hConnector.xPosition = xPosition;
        }
        yPosition = event.yPosition + event.height;
      });
    });
  }

  /**
   * 
   * @param connector - vConnector unit
   * @param units - timeline units
   * @param startX - start x position of timeline
   * @param startY - start y position of timeline
   * @param vConnectorWidth - width of vConnector
   * @param vConnectorHeight - height of vConnector
   * @param yearHeight - height of year node
   * @param hConnectorHeight - height of hConnector
   */
  public static assignVConnectorPositions(
    connector: TimelineVConnectorModel,
    units: TimelineUnitModel[],
    startX: number,
    startY: number,
    vConnectorWidth: number,
    vConnectorHeight: number,
    yearHeight: number,
    hConnectorHeight: number
  ) {
    connector.xPosition = startX - (vConnectorWidth / 2) + connector.offsetXPosition;
    connector.yPosition = startY + (yearHeight / 2) + connector.offsetYPosition;

    let lastHConnectorY = this.getLastPosition(units);
    let totalHeight = lastHConnectorY - connector.yPosition + (hConnectorHeight / 2);

    connector.numberOfVConnectors = Math.ceil(totalHeight / vConnectorHeight);
    connector.maskHeight = totalHeight;
    connector.maskWidth = vConnectorWidth;
  }

  /**
  * Get the y position of the last hConnector in the timelineUnits array.
  * @param timelineUnits An array of TimelineUnitModel objects.
  * @returns The y position of the last hConnector.
  */
  public static getLastPosition(timelineUnits: TimelineUnitModel[]): number {
    const lastUnit = timelineUnits[timelineUnits.length - 1];
    const lastEvent = lastUnit.eventNode[lastUnit.eventNode.length - 1];
    const lastHConnector = lastEvent.hConnector;
    return lastHConnector.yPosition;
  }
}

