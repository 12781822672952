export class ArtisticGraphics{

    commonUrl;
    config;
    constructor(commonUrl, config){
        this.commonUrl = commonUrl;
        this.config = config;
    }
    
    getChartData(node){
        let profileImageUrl = this.getProfileImageUrl(node.item.profileImageUrl, node.item.gender)
        let leavesImageUrl = this.getNodeLeavesImageUrl(node.level)
        let factor = this.getScalingFactor(node.level)
        return {
            "id":node.item.id,
            "profileImageUrl": profileImageUrl,
            "nodeLeavesImageUrl":leavesImageUrl,
            "x":node.x,
            "y":node.y,
            "scalingFactor": factor,
            };
    }

    // get profile image url: return gender based image if profileImageUrl is not available 
    private getProfileImageUrl(imageUrl, gender){
        if (imageUrl =="" || imageUrl == undefined || imageUrl == null){
            return this.commonUrl  + `images/icons/${gender}.svg` ;
        }
        return imageUrl;
    }

    // return random node leaves image url according to the level
    private getNodeLeavesImageUrl(level){
        let leavesImageList = this.config.nodes.leaves[level]
        // select random image from the list
        let image = leavesImageList[Math.floor(Math.random()*leavesImageList.length)];
        return this.commonUrl +"images/" + this.config.base.assetUrl + `leaves/level${level}/${image}` ;
    }

    // get the scaling factor of unit 
    private getScalingFactor(level){
        let scalingFactor = ((0.85)**level).toFixed(3)
        return scalingFactor;
    }
}

