import { FTDate } from "../FTDate";

export class FamilyProjectDetailsModel{
    familyProjectId:string;
    people:number;
    families:number;
    places:number;
    sources:number;
    citations:number;
    multimedias:number;
    events:number;
    treeSize:string;
    lastUpdate:Date;

    getSize(){
        //return ((+this.treeSize)/(1024*1024)).toFixed(2); //MegaBytes 462,132
        if (+this.treeSize<1024)
            return Math.round(+this.treeSize) + " B";
        if (+this.treeSize<1024*1024)
            return Math.round(+this.treeSize/1024) + " KB";
        else if (+this.treeSize<1024*1024*1024)
            return Math.round(+this.treeSize/(1024*1024)) + " MB";
        else
            return Math.round(+this.treeSize/(1024*1024*1024)) + " GB";
    }
}