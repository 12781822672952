
    <div class="toolip-content" style="width: 225px;">
        <div class="tooltip-row">
            <span class="tooltip-heading-text" style="margin-top: 2px !important">
                {{data.name}}
            </span>                
            <span class="tooltip-content-text" style="margin-top: 2px !important">
                {{data.value}}
            </span>     
        </div>
    </div>
