import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { EditorsComponent } from './editors/editors.component';
import { SubheaderMainComponent } from './subheader-main/subheader-main.component';
import { FamilyProjectsComponent } from './family-projects/family-projects.component';
import { TranslateModule } from '@ngx-translate/core';
import { FamilyBreadcrumbComponent } from './family-breadcrumb/family-breadcrumb.component';



@NgModule({
  declarations: [BreadcrumbsComponent, EditorsComponent, SubheaderMainComponent, FamilyProjectsComponent, FamilyBreadcrumbComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot()
  ],
  exports: [SubheaderMainComponent]
})
export class SubheaderModule { }
