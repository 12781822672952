import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StringLengthLimiter } from 'src/app/common/helpers/string-length-limiter';
import { NotifierEvents, TreeLevel } from 'src/app/common/enums/enums';
import { RootMemberMapper } from 'src/app/common/mappers/RootMemberMapper';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { StringLimiter } from 'src/app/common/helpers/string-limiter';

@Component({
  selector: 'g[app-level6labels]',
  templateUrl: './level6labels.component.html',
  styleUrls: ['./level6labels.component.scss']
})
export class Level6labelsComponent implements OnInit {
  limitLength: number = 26;

  @Input() label: any;
  @Input() i: number;
  @Input() rootMember: any;
  @Output() openInputFormBoxWithData = new EventEmitter();
  splitDisplayName : any;

  constructor(public stringLimit: StringLengthLimiter,
    private notifierService: NotifierV2Service,
    private rootMemberMapper: RootMemberMapper,
    public stringLimiter: StringLimiter) {
  }

  breakText = function (text: String, line: number) {
    return this.stringLimit.breakText(text, line, this.limitLength);
  }

  ngOnInit(): void {
    this.setStringLimit();
  }

  expandWheel(content) {
    content.expanded = !content.expanded;
  }

  addParent(index, label) {
    this.openInputFormBoxWithData.emit({ index, label });
  }

  changeRootPerson(label) {
    var rootMember = this.rootMemberMapper.map(label);
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, rootMember);
  }

  setStringLimit(){
    this.splitDisplayName =  this.stringLimiter.splitString(this.label.displayName, TreeLevel.Four)
  }

}
