import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';
import { IndividualEventBase } from 'src/app/common/models/individual/individual-event-base';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { FamilyIndividual } from 'src/app/components/familyeditor/models/family-individual';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
import { subscribeOn } from 'rxjs/operators';

import { ChildRealtionshipViewModel } from 'src/app/components/familyeditor/models/child-relationship-view-model';
import { FamilyViewModel } from 'src/app/components/familyeditor/models/family-view-model';
import { ChildRelationshipType } from 'src/app/common/enums/enums';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';

@Component({
  selector: 'app-relationship',
  templateUrl: './relationship.component.html',
  styleUrls: ['./relationship.component.scss']
})
export class RelationshipComponent extends DialogBaseComponent implements OnInit {

   // Variable declaration
  title:string;
  profileImageUrl:string;
  displayName:string;
  relationshipType: string;
  isDefault: boolean;
  showBloodlineDefaultOption: boolean = false; 
  showAdoptedDefaultOption:boolean = false;
  showFosterDefaultOption:boolean = false;
  showSpecialDefaultOption:boolean = false;
  defaultView: boolean = true;

  //Objects declaration
  childRelationshipViewModel: ChildRealtionshipViewModel;
  individualInfo:IndividualEventBase;
  selectedChild:FamilyIndividual;
  parentFamilies: FamilyViewModel[]; 

  constructor(private ds:DialogService, 
    private ref1:ChangeDetectorRef,
    private loadingService: LoadingService,
    private individualApiService: IndividualApiService,
    private familyApiService: FamilyApiService,
    private translateHandler: TranslateHandler,) {
    super(ds, ref1);
  }

  ngOnInit(): void {  
    this.individualInfo = new IndividualEventBase();
    this.childRelationshipViewModel = new ChildRealtionshipViewModel();
    this.selectedChild = new FamilyIndividual();
    this.getIndividualBaseInfo(this.data.individualInfo.individualId);
    this.getParentsFamiliesInfo(this.data.individualInfo.individualId);
    this.title = this.data.title;
    this.profileImageUrl =  this.data.individualInfo.profileImageUrl;
    this.displayName =  this.data.individualInfo.displayName;
  }

  getIndividualBaseInfo(memberId: number){ 
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.individualApiService.getIndividualBaseInfo(memberId).subscribe((response: ResponseModel<IndividualEventBase>)=> {
      this.individualInfo = response.data;     
    }, (err) => {
      throw new CustomError("RealtionshipComponent => getIndividualBaseInfo() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  getParentsFamiliesInfo(memberId: number){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.familyApiService.getParentsFamiliesInfo(memberId).subscribe((response: ResponseModel<FamilyViewModel[]>)=> {
      this.parentFamilies = response.data;
      this.mapUI(response.data);
    }, (err) => {
        throw new CustomError("RealtionshipComponent => getParentsFamiliesInfo() : " + err, 911, false);
    }).add(()=> {
      this.loadingService.hide(processId);
    });
  }

  mapUI(parentFamilies: FamilyViewModel[]){ 
    // identifying families of the child
    let biologicalFamily = parentFamilies?.find(p => p.relationshipType === ChildRelationshipType.bloodline);
    let adoptiveFamily = parentFamilies?.find(p => p.relationshipType === ChildRelationshipType.adapted);
    let fosterFamily = parentFamilies?.find(p => p.relationshipType === ChildRelationshipType.foster);
    let specialFamily = parentFamilies?.find(p => p.relationshipType === ChildRelationshipType.special);    

    // preparing family names based on identified families
    this.childRelationshipViewModel.biologicalFamilyName = biologicalFamily != null ? this.translateHandler.translate('lbl_parent_names', [(biologicalFamily.individual.displayName !== null ? biologicalFamily.individual.displayName : ""), (biologicalFamily.spouse.displayName !== null ? biologicalFamily.spouse.displayName : "")]) : "";
    this.childRelationshipViewModel.adoptiveFamilyName = adoptiveFamily != null ? this.translateHandler.translate('lbl_parent_names', [(adoptiveFamily.individual.displayName !== null ? adoptiveFamily.individual.displayName : ""), (adoptiveFamily.spouse.displayName !== null ? adoptiveFamily.spouse.displayName : "")]) : "";
    this.childRelationshipViewModel.fosterFamilyName = fosterFamily != null ? this.translateHandler.translate('lbl_parent_names', [(fosterFamily.individual.displayName !== null ? fosterFamily.individual.displayName : ""), (fosterFamily.spouse.displayName !== null ? fosterFamily.spouse.displayName : "")]) : "";
    this.childRelationshipViewModel.specialFamilyName = specialFamily != null ? this.translateHandler.translate('lbl_parent_names', [(specialFamily.individual.displayName !== null ? specialFamily.individual.displayName : ""), (specialFamily.spouse.displayName !== null ? specialFamily.spouse.displayName : "")]) : "";
  
    this.defaultView = true;
  }

  //Select relationship for selected child with family
  selectRelationship(){
    this.selectedChild.birthEvent = this.individualInfo?.birthEvent;
    this.selectedChild.relationShipType = this.relationshipType;
    this.selectedChild.isDefault = this.isDefault;    
    this.complete({ data: this.selectedChild });    
  }

  cancel(){
    this.close();
  }

  onItemChange(event){
    //get relationship type as user selection
    this.relationshipType = event.target.value;  
    this.showAdoptedDefaultOption =  this.relationshipType === 'adapted' ? true : false;
    this.showBloodlineDefaultOption = this.relationshipType === 'bloodline' ? true: false;
    this.showFosterDefaultOption = this.relationshipType === 'foster' ? true : false;
    this.showSpecialDefaultOption = this.relationshipType === 'special' ? true : false;
    this.defaultView = false;
  } 
}
