import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { DropMenuAction, PopupViewMode, ResourceType } from 'src/app/common/enums/enums';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { PlaceBase } from 'src/app/common/models/place/place-base';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { PlaceAPIService } from 'src/app/services/API/place-api.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';
import { MediaGalleryItem } from '../../media-gallery/models/media-gallery-item';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit {

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();

  palces: PlaceBase[];
  mediaItems: MediaGalleryItem[];
  searchPhrase: string = "";
  isDeleted : boolean;
  isStandAlone: boolean;

  constructor(
    private dailogSevice: DialogService,
    private placeApiService: PlaceAPIService,
    private loadingService: LoadingService,
    private messageDialogService: MessageDialogService,
    private translateHandler: TranslateHandler) {

  }

  ngOnInit(): void {
    this.mediaItems = new Array<MediaGalleryItem>();
    this.isStandAlone =  this.data.isStandAlone;
    console.log("Data input " , this.data);
    this.getPlaceList();
    this.createDropMenu(null);
  }

  addPlace() {
    let selectedplace = this.mediaItems.find(s => s.isSelected == true);
    let place = this.palces?.find(p => p.id === selectedplace?.reference ?? 0) ?? { id: 0 };
    this.onCompleted.next({ data: place });
    this.onCompleted.unsubscribe();
  }
  cancel() {
    !this.isDeleted ? this.onCompleted.next({}) : this.onCompleted.next({data : new PlaceBase()});
    this.onCompleted.unsubscribe();
  }

  getPlaceList() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);

    this.placeApiService.getPlaceList(1, 1).subscribe((response: ResponseModel<PlaceBase[]>) => {
      this.palces = response.data;
      // Map places to mediaItems
      this.mediaItems = this.palces.map(i => ({ id: 0, reference: i.id, caption: i.rawData, url: i?.mediaUrl ?? "/assets/images/place-default.png", isSelected: (i.id == this.data.id),isEditEnable: (i.id == this.data.id), dropMenu: this.createDropMenu(i) }));
      this.mediaItems.sort(this.compareSelected);
    }, (err) => {
      throw new CustomError("DateHelper => FormatDate() : " + err, 911, false);
    }).add(()=>{     
      this.loadingService.hide(processId);
    });
  }

  compareSelected(a: MediaGalleryItem, b: MediaGalleryItem) {
    if (a.isSelected == true) {
      return -1;
    }
    if (b.isSelected == true) {
      return 1;
    }
    return 0;
  }

  createDropMenu(ref: PlaceBase) {
    let dropMenu = new DropMenu();
    dropMenu.type = 1;
    dropMenu.ref = ref;
    dropMenu.menuItems = [<DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Delete, isEnabled: true, isVisible: true }
    ];
    return dropMenu;
  }

  setEnablity() {
    let isEnabled = this.mediaItems?.find(s => s.isSelected == true)?.isSelected ?? false;
    return isEnabled;
  } 

  dropMenuAction(event: any) {
    if (event.action == DropMenuAction.Delete) {
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      this.placeApiService.getReferenceToPlace(event.reference.id).subscribe((response: ResponseModel<number>) => {      
        var message = this.translateHandler.translate("cnf_delete_place_confirmation_message") + this.translateHandler.translate("lnk_events",[response.data.toString()])
        this.messageDialogService.openDeleteConfirmation(message).subscribe((res: boolean) => {         
          if (res) {
            this.deletePlace(event.reference.id);
          }
        })
      }, (err) => {
        throw new CustomError("PlaceComponent => getReferenceToPlace() : " + err, 911, false);
      }).add(()=>{
        this.loadingService.hide(processId);
      });
    }
    if (event.action == DropMenuAction.Edit) {
      this.data.reference = {Id:event.reference.id , rawData :event.reference.rawData};
      this.data.viewMode = PopupViewMode.EditorView;
    }
  }

  deletePlace(placeId: number) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.placeApiService.deletePlace(placeId).subscribe((response: ResponseModel<boolean>) => {
      if (response.data) {
        let deleteObj = this.mediaItems.find(s => s.reference == placeId);
        this.mediaItems.splice(this.mediaItems.indexOf(deleteObj), 1);
        if (this.data.id == placeId) {
         this.isDeleted = true;
        }
      }
    }, (err) => {
      throw new CustomError("PlaceComponent => DeletePlace() : " + err, 911, false);
    }).add(()=>{
      this.loadingService.hide(processId);
    });
  } 

  
}
