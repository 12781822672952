import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { BaseComponent } from "../../BaseReport/base/base.component";
import { SunChartSectorData, SunChartFunSector } from "./sun-chart-sector-data";
import { SunChartSector } from "./sun-chart-sector";
import { SunChartUtil } from "./sun-chart-util";
import { SunChartGraphic } from "./sun-chart-graphic";
import { SunChartText } from "./sun-chart-text";
import { ApiBaseService } from "src/app/services/api-base.service";
import { ResponseModel } from "src/app/common/models/responseModel";
import { LoadingService } from "src/app/services/UI/loading.service";
import { GuidGenerator } from "src/app/common/helpers/guid-generator";
import { MultimediaTypes, NotifierEvents, ReferenceType, EditorMode } from 'src/app/common/enums/enums';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { ReportFont } from '../../common/reportfont';
import { IndividualmanagementService } from 'src/app/components/common/individualmanagement/services/individualmanagement.service';
import { RootMember } from 'src/app/common/models/RootMember';
import { NotifierV2Service } from "src/app/services/notifier-v2.service";
import { IndividualReportViewModel } from '../../models/individual-report-view-model';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { DateHelper } from "src/app/common/helpers/date-helper";
import { ReportOptions } from "../../common/reportOptions/reportOptions";
import { ReportFieldOption } from "../../common/reportOptions/reportFieldOption";
import { Subscription } from "rxjs";
import { IndividualApiService } from "src/app/services/API/individual-api.service";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { MessageDialogService } from "src/app/components/common/message-dialog/services/message-dialog.service";
import { ChildBase } from "src/app/common/models/child/child-base";

@Component({
  selector: "app-sunchart",
  // selector: "[appDynamicReport]",
  templateUrl: "./sunchart.component.html",
  styleUrls: ["./sunchart.component.scss"],
})
export class Sunchart extends BaseComponent implements OnInit, OnDestroy {


  expectedUpdates = [NotifierEvents.RootMemberChanged];

  baseGrid = {
    visibility: "visible", // hidden - visible
    X: { x1: 0, y1: 0, x2: 0, y2: 0 },
    Y: { x1: 0, y1: 0, x2: 0, y2: 0 },
  };

  // Level 0 start point - different from other levels
  chartLevel0 = { id: 0, x: 0, y: 0, image: null, text: [], profileImageId: 0, parentFamilyId: 0, gender: null };
  // Level 1 - onwards
  chartLevels = [];
  // Graphics for each level
  chartGraphics = [];
  // General chart text
  chartText = [];
  helpers = [];
  // To on/off sector lines and path text lines
  isShowBaseLines = false;

  defaultAncestorLevels = 5;
  heightVal = "calc(100vh - 95px)";   // remove the sub headers
  widthtVal = "100vw";
  refreshParent: any;
  multimediaSubscription: any;
  timeTag: string = "";
  defaultHeight = 0;
  heightFix = 0;


  //Object Declaration
  private font: ReportFont;
  reportOptions: ReportOptions;
  individualList: IndividualReportViewModel[];

  private changeRootMemberSubscription: Subscription;

  constructor(
    private sunchartHostElement: ElementRef,
    private apibaseservice: ApiBaseService,
    private loadingService: LoadingService,
    private individualmanagementService: IndividualmanagementService,
    protected notifierService: NotifierV2Service,
    protected dialogService: DialogService,
    private dateHelper: DateHelper,
    protected individualApiService: IndividualApiService,
    protected translateHandler: TranslateHandler,
    protected messageDialogService: MessageDialogService
  ) {
    super(sunchartHostElement, dialogService, notifierService, translateHandler, messageDialogService, individualApiService);
    this.font = new ReportFont();
  }

  ngOnInit(): void {
    // run after the config is loaded from the report base
    if (this.config) {
      this.chartBasics = {
        name: this.config.name,
        assetUrl: this.commonUrl,
        title1: "",
        title2: "",
      };

      this.rootMember = this.notifierService.getCurrentRootMember();
      this.initialzeSunchartReportData(this.defaultAncestorLevels);

      this.individualmanagementService.onMessage().subscribe(() => {
        this.initialzeSunchartReportData(this.defaultAncestorLevels, null);
      })

      this.changeRootMemberSubscription = this.rootMemberChanges$.subscribe((result: boolean) => {
        if (result) {
          this.initialzeSunchartReportData(this.defaultAncestorLevels, null);
        }
      });
    }
  }

  notify() {
    this.hideTooltip();
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.initialzeSunchartReportData(this.defaultAncestorLevels);
  }

  onItemError(event: any) {
    let errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";
    event.target.href = errorImg;
  }

  initReportOptions() {
    this.reportOptions = new ReportOptions();
    this.reportOptions.reportName = "lbl_sunchart";
    this.reportOptions.fieldOptions.push(new ReportFieldOption("title", "title", "input", [], this.reportTitle));
    this.reportOptions.fieldOptions.push(new ReportFieldOption("generations", "algorithm", "combobox", ["1", "2", "3", "4", "5", "6", "7", "8", "9"], this.defaultAncestorLevels));
  }

  onOptionsValueChanged(changedData: ReportOptions) {
    this.mapConfig(changedData);
    this.defaultAncestorLevels = this.config.options.generations;
    this.initialzeSunchartReportData(this.defaultAncestorLevels, this.config.options.title);
  }

  initialzeSunchartReportData(ancestorLevels, customTitle = null) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    // send http request only if the session data is available   
    this.individualApiService
      .getAllAscendantsList(ancestorLevels, this.rootMember.Id)
      .subscribe(
        (response: ResponseModel<Array<IndividualReportViewModel>>) => {

          // Level 0 start point - different from other levels
          this.chartLevel0 = { id: 0, x: 0, y: 0, image: null, text: [], profileImageId: 0, parentFamilyId: 0, gender: null };
          // Level 1 - onwards
          this.chartLevels = [];
          // Graphics for each level
          this.chartGraphics = [];
          // General chart text
          this.chartText = [];
          this.helpers = [];

          let generationList = new Array<Array<SunChartSectorData>>();

          this.individualList = response.data;
          var lastindex = 0

          for (let index = 0; index < this.defaultAncestorLevels; index++) {
            let memberListOnLevel = Math.pow(2, index);

            let memberListongeneration = new Array<SunChartSectorData>();

            for (let x = lastindex; x < lastindex + memberListOnLevel; x++) {
              const individualMember = this.individualList[x];
              let memberProfileImageUrl = this.getProfileImageUrl(individualMember)
              individualMember.profileImageUrl = memberProfileImageUrl;
              individualMember.dateRangeStr = this.getDateRangeText(individualMember);
              let rootMemberSectorData = new SunChartSectorData(individualMember);
              memberListongeneration.push(rootMemberSectorData);

              if (x == (this.individualList.length - 1)) {
                break;
              }
            }

            lastindex = lastindex + memberListOnLevel;
            generationList.push(memberListongeneration);
          }
          //this.heightVal = ancestorLevels >= 6 ? "135vh" :  ((ancestorLevels == 5)|| (ancestorLevels == 4)) ? "120vh" : "100vh";
          // this.defaultHeight = this.reportHeightMap[this.defaultAncestorLevels];
          //debugger;
          this.defaultHeight = this.config.levels[this.defaultAncestorLevels].reportHeight;
          //Bind response to sunchartdata 
          if (response.data.length == 0) {
            throw new CustomError("Report data is not loaded", 1604, false)
          }
          else {
            SunChartSectorData.data = generationList;
            this.reportTitle = customTitle == null ? this.translateHandler.translate('lbl_ancestors_of', [this.rootMember.DisplayName]) : customTitle;
            this.reportInit();
            this.reportZoomToExtend()

          }
          this.loadingService.hide(processId);
        }
      )
      .add(() => {
        this.loadingService.hide(processId);
      });
  }

  //get profile image 
  getProfileImageUrl(individual: IndividualReportViewModel) {
    return individual?.profileImageUrl ?? (individual?.gender != "\u0000" ? this.chartBasics.assetUrl + "images/" + individual?.gender + ".png" : null);
  }

  getDateRangeText(individual: IndividualReportViewModel) {
    let birthYear = '';
    let deathYear = '';

    if (individual.birthEvent && individual.birthEvent.date) {
      let birthDate = this.dateHelper.validateAndFormat(individual.birthEvent.date?.genealogyDate?.originalStr);
      birthYear = (birthDate.genealogyDate?.year !== undefined) ? birthDate.genealogyDate?.year + '' : '';
    }

    if (individual.deathEvent && individual.deathEvent.date) {
      let deathDate = this.dateHelper.validateAndFormat(individual.deathEvent.date?.genealogyDate?.originalStr);
      deathYear = (deathDate.genealogyDate?.year !== undefined) ? deathDate.genealogyDate?.year + '' : '';
    }

    return this.concatDateRageText(birthYear, deathYear);
  }

  concatDateRageText(birthYear, deathYear) {
    if (birthYear.length > 0 && deathYear.length > 0) {
      return birthYear + ' - ' + deathYear;
    }
    else if (birthYear.length > 0) {
      return this.translateHandler.translate('event_birth') + ' (' + birthYear + ')';
    }
    else if (deathYear.length > 0) {
      return this.translateHandler.translate('event_death') + ' (' + deathYear + ')';
    }

    return '';
  }

  ngOnDestroy(): void {
    // unsubscribe on destroy
    this.hideTooltip();
    this.apibaseservice.sessionDataSubject.unsubscribe();
    if (this.changeRootMemberSubscription) {
      this.changeRootMemberSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  /**
   * initialize report
   */
  reportInit() {
    // Iniatialize font with different sizes
    // Norse   
    //debugger;
    this.font.init(this.config.font);

    //Calculate base grid
    // this the base grid for x and y axis, all angles defined are relative to this
    // cordinate system at (0,0) translated based on the base width and height
    this.baseGrid.X = {
      x1: -this.config.base.w / 2,
      y1: 0,
      x2: this.config.base.w / 2,
      y2: 0,
    };
    this.baseGrid.Y = {
      x1: 0,
      y1: -this.config.base.h / 2,
      x2: 0,
      y2: this.config.base.h / 2,
    };

    // Level 0
    this.chartLevel0.id = SunChartSectorData.data[0][0].id;
    this.chartLevel0.x = 0;
    this.chartLevel0.y = 0;
    this.chartLevel0.image = {
      r: this.config.levels[0].image.r,
      offsetX: 0,
      offsetY: this.config.levels[0].image.offsetY,
      imgurl: SunChartSectorData.data[0][0].profileImage,
    };
    this.chartLevel0.profileImageId = SunChartSectorData.data[0][0].profileImageId;
    this.chartLevel0.parentFamilyId = SunChartSectorData.data[0][0].parentFamilyId;
    this.chartLevel0.gender = SunChartSectorData.data[0][0].gender;
    // Level 0 graphics
    this.chartGraphics.push(
      SunChartGraphic.getGraphics(
        this.config.levels[0].graphics,
        this.chartBasics.assetUrl
      )
    );
    //this.addGraphic(this.config.levels[0].graphics);
    // Level 0 title
    var name = SunChartSectorData.get(0, 0).displayName;
    var tmp = new SunChartText(name, 2, {
      normalText1: this.config.levels[0].sText1,
      normalText2: this.config.levels[0].sText2,
    }, this.font);
    this.chartLevel0.text = tmp.generate();

    // Base graphics elements // background images etc
    if (this.config.base.graphics) {
      this.config.base.graphics.forEach((graphicConfig) => {
        this.chartGraphics.push(
          SunChartGraphic.getGraphics(graphicConfig, this.chartBasics.assetUrl)
        );
      });
    }

    // Process from level 1
    for (var x = 1; x < this.defaultAncestorLevels; x++) {
      // chart graphics goes seperate - according to the level
      if (!SunChartSectorData.isLevelEmpty(x)) {
        this.chartGraphics.push(
          SunChartGraphic.getGraphics(
            this.config.levels[x].graphics,
            this.chartBasics.assetUrl
          )
        );
      }

      var sectorAngle =
        (360 - this.config.base.openAngle) / this.config.levels[x].count;
      var sectorRadius =
        SunChartUtil.sumPRev(this.config.levels, x - 1) +
        this.config.levels[x].levelMargin;
      var chartLines = SunChartGraphic.getSectorLine("chartlines", {
        levels: this.config.levels,
        levelId: x,
        angle: sectorAngle,
      });

      // Fill all levels
      for (var y = 0; y < this.config.levels[x].count; y++) {

        // Get the data and break according to config
        let isLeafNode: boolean = false;
        var data = SunChartSectorData.get(x, y);
        var parentData = SunChartSectorData.get(x - 1, Math.floor(y / 2));

        // Set a empty object, without checking in all the places
        if (data == null) data = new SunChartSectorData(null);

        // add function nodes for adding new members
        if (SunChartSectorData.isLeafNode(x, y)) {
          isLeafNode = true;
          let addImage = this.commonUrl + "images/Add.png"
          if (y % 2) {
            data = new SunChartFunSector(this.createLeafNodeMember(0, this.translateHandler.translate('lbl_add_mother'), addImage), 0);
          } else {
            data = new SunChartFunSector(this.createLeafNodeMember(-1, this.translateHandler.translate('lbl_add_father'), addImage), -1);
          }
          SunChartSectorData.data[x][y] = data;
        }

        var temp = new SunChartSector(
          x,
          this.config.levels[x].count,
          y,
          sectorRadius,
          sectorAngle,
          this.config.base.axisAdjustment,
          this.config.levels[x],
          data, this.font, isLeafNode
        );
        var sectorInfo = temp.generate();

        this.chartLevels.push({
          id: data.id,
          parentId: parentData.id,
          parentFamilyId: data.parentFamilyId,
          profileImageId: data.profileImageId,
          levelId: x,
          sectorId: y,
          path: chartLines.path,
          texts: sectorInfo.texts,
          transform:
            "rotate(" +
            (this.config.base.axisAdjustment + sectorAngle * y) +
            " 0 0)",
          image: sectorInfo.imageData,
          fnimage: sectorInfo.imageFn
        });
      } //end for

    }  
    this.calculateHeightByLevel();   
    this.initReportOptions();
    this.showReport = true;
  }

  calculateHeightByLevel() {
    const levelCount = this.chartLevels.length;
    let scaleFactorLookup;
    
    //setup scale factor for Number of generations
    if (this.config.name === 'sunchart') {
      scaleFactorLookup = [
        { threshold: 30,  scaleFactor: 1 },
        { threshold: 62,  scaleFactor: 2.5 },
        { threshold: 126, scaleFactor: 4.5 },
        { threshold: 254, scaleFactor: 3.5 },
      ];  
    } else {
      scaleFactorLookup = [
        { threshold: 30,  scaleFactor: 0.5 },
        { threshold: 62,  scaleFactor: 1.0 },
        { threshold: 126, scaleFactor: 0.6 },
        { threshold: 254, scaleFactor: 0.5 },
        { threshold: 510, scaleFactor: 0.3 },
      ];
    }
    //select particular scaleFactor
    let scaleFactor = scaleFactorLookup.find(entry => levelCount <= entry.threshold)?.scaleFactor || 2.5;

    this.heightFix = levelCount * scaleFactor;
  }

  createLeafNodeMember(id, name, imageUrl): IndividualReportViewModel {
    let leafNodeMember = new IndividualReportViewModel();
    leafNodeMember.id = id;
    leafNodeMember.displayName = name;
    leafNodeMember.dateRangeStr = "";
    leafNodeMember.profileImageUrl = imageUrl;
    return leafNodeMember;
  }

  /**
     *  handle click event of sector
    */
  async clickNode(eventXPosition: number, eventYPosition: number, id: number, childId: number, sectorId: number, levelId: number) {
    if (id === 0 || id === -1) {
      const parentGender = sectorId % 2 === 0 ? "M" : "F";
      const child = levelId !== 1 ? this.chartLevels.find(c => c.id === childId) : this.chartLevel0;
      const childName = (!child.text) ? child.texts[0].text : child.text[0].text;

      let childObj = new ChildBase();
      childObj = {
        id: child.id,
        name: childName,
        familyId: child.parentFamilyId
      };

      try {
        const response = await this.addParent(parentGender, childObj);
        if (response) {
          if (response?.isMemberNavigate) {
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, response.parent);
            this.openEditor(response.parent.id, EditorMode.IndividualEditor);
          } else {
            this.initialzeSunchartReportData(this.defaultAncestorLevels, null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    else {
      let selectedMember = this.individualList.find(t => t.id == id);
      this.rootMember = this.createRootMember(selectedMember);
      this.openToolTip(eventXPosition, eventYPosition, id);
    }
  }

  createRootMember(data: any): RootMember {
    let newRootMember = new RootMember()
    newRootMember.Id = data.rootMemberId == null ? data.id : data.rootMemberId;
    newRootMember.FirstName = data.givenName;
    newRootMember.LastName = data.surname;
    newRootMember.Gender = data.gender;
    newRootMember.DisplayName = data.displayName;
    return newRootMember;
  }

  /**
   *  Open multimedia panel for change profile image
   */
  addImage(memberId: any, profileImageId: any, isRootMember: boolean, sectorId: any) {
    let memberInfo = SunChartSectorData.data.map(t => t.find(x => x.id == memberId)).filter(y => y !== undefined)[0];
    this.dialogService.setHalf().open("Multimedia", {
      // Set id to 0 if StandAlone
      id: profileImageId,
      reference: {
        id: memberId, // Individual Id
        type: ReferenceType.Individual // Individual type
      },
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_pictures").toLowerCase(),
      memberInfo.displayName]) ?? "",
      mediaType: [MultimediaTypes.PHOTO],
      viewMode: 1, // Gallery or editor view
      editMediaId: 0, // MediaId which supposes to be edited
      isStandAlone: false
    }
    )
      .subscribe(response => {
        if (response.data) {
          let mediaBasic: Multimedia = null;
          let gender = !isRootMember ? (sectorId % 2 == 0 ? "M" : "F") : this.chartLevel0.gender;
          let defaultImageUrl = this.chartBasics.assetUrl + "images/" + gender + ".png"

          response.data.deleteMedias.forEach(element => {
            !isRootMember ? this.chartLevels.find(t => t.id == memberId).image.imgurl = defaultImageUrl : this.chartLevel0.image.imgurl = defaultImageUrl;
            // Next image as primary one     
            mediaBasic = response.data.nextImage;
          });

          if (response.data.newMedia) {
            mediaBasic = new Multimedia();
            mediaBasic = response.data.newMedia;
          }

          if (mediaBasic != null) {
            let processId = GuidGenerator.generate();
            this.loadingService.show(processId);
            mediaBasic.isPrimary = true;
            mediaBasic.isNonProfile = false;
            //Update profile Image
            this.individualApiService.updateProFileImage(memberId, mediaBasic).subscribe((dataResponse: any) => {
              //updated image url and id for non-root member and root member
              if (!isRootMember) {
                this.chartLevels.find(t => t.id == memberId).image.imgurl = mediaBasic.mediaUrl + "&timeTag=" + new Date().getTime();
                this.chartLevels.find(t => t.id == memberId).profileImageId = mediaBasic.id;
              }
              else {
                this.chartLevel0.image.imgurl = mediaBasic.mediaUrl + "&timeTag=" + new Date().getTime();
                this.chartLevel0.profileImageId = mediaBasic.id;
              }
            }, (err) => {
              throw new CustomError("SunchartComponent => addImage() : " + err, 911, false);
            }).add(() => {
              this.loadingService.hide(processId);
            });
          }
        }
      })
  }

}
