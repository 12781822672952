import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction, DropMenuType } from 'src/app/common/enums/enums';
import { MultimediaBase } from 'src/app/common/models/multimedia/multimedia-base';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { MediaGalleryItem } from '../../media-gallery/models/media-gallery-item';

@Component({
  selector: 'app-witnesses',
  templateUrl: './witnesses.component.html',
  styleUrls: ['./witnesses.component.scss']
})
export class WitnessesComponent implements OnInit {

  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  
  multimedia : MultimediaBase[];
  mediaItems : MediaGalleryItem[] = [];  
  searchPhrase : string ="";
  
  constructor() { }

  ngOnInit(): void {
    const itemMenu = new DropMenu();
    itemMenu.type = DropMenuType.Inverted;
    itemMenu.menuItems = [ { action:DropMenuAction.Edit, isEnabled:true,isVisible:true },{ action:DropMenuAction.Delete, isEnabled:true,isVisible:true }  ]
    var cloneDropMenu = { ...itemMenu };
    
    
    this.multimedia = [ 
      { id:1, caption:"Image caption 1", fileName:"abcd.jpg", description:"Descr", date:null, tag:null, place:null, mediaType:1, cropInfo:null, isPrimary:false},
      { id:2, caption:"Image caption 2", fileName:"abcd.jpg", description:"Descr", date:null, tag:null, place:null, mediaType:1, cropInfo:null, isPrimary:false},
      { id:3, caption:"Image caption 3", fileName:"abcd.jpg", description:"Descr", date:null, tag:null, place:null, mediaType:1, cropInfo:null, isPrimary:false},
      { id:4, caption:"Image caption 4", fileName:"abcd.jpg", description:"Descr", date:null, tag:null, place:null, mediaType:1, cropInfo:null, isPrimary:false},
      { id:5, caption:"Image caption 5", fileName:"abcd.jpg", description:"Descr", date:null, tag:null, place:null, mediaType:1, cropInfo:null, isPrimary:false},
    ]
    
    this.mediaItems = this.multimedia.map( i => ({id:0, reference: i.id,caption: i.caption, url:"/assets/images/place-default.png", isSelected:false,isEditEnable:false, dropMenu:{ ...itemMenu } }));  
  }

  add(){
    this.onCompleted.next({});
    this.onCompleted.unsubscribe();
  }

  cancel(){
    this.onCompleted.next({});
    this.onCompleted.unsubscribe();
  }
}
