<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">Add image of stein inge haaland</span>
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>Name</label>
                <input type="text">
            </div>                      
        </div>        
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>Address</label>
                <input type="text">
            </div>                      
        </div>        
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>Note</label>
                <input type="text">
            </div>                      
        </div>        
    </div>
    <div class="footer">
        <button class="ft-btn ft-btn-inactive active" (click)="save()">Save</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">Cancel</button>
    </div>
</div>