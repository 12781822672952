import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiBaseService } from '../api-base.service';
import { EventComponentModel } from 'src/app/components/common/events/models/event-component-model';

@Injectable({
  providedIn: 'root'
})
export class EventApiService {

  constructor(private apiBaseService: ApiBaseService) { }

  getEvent<T>(eventId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/' + eventId, null, null);
  }

  getEvents<T>(referenceId: number, referenceType: number): Observable<T> {
    return this.apiBaseService.get('/api/event/' + referenceId + '/' + referenceType, null, null);
  }

  getEventWitness<T>(eventId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/' + eventId + '/witness', null, null);
  }

  getEventMedia<T>(eventId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/' + eventId + '/multimedia', null, null);
  }

  getEventSources<T>(eventId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/' + eventId + '/sources', null, null);
  }

  
  getEventIndividuals<T>(eventId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/' + eventId + '/individuals', null, null);
  }


  getEventTypeList<T>(referenceType: number): Observable<T> {
    return this.apiBaseService.get('/api/event/types/' + referenceType, null, null);
  }

  updateEvents<T>(eventComponentModel: EventComponentModel): Observable<T> {
    return this.apiBaseService.update('/api/event' ,null,eventComponentModel);
  }

  createEvent<T>(eventComponentModel: EventComponentModel): Observable<T> {
    return this.apiBaseService.create('/api/event' ,null,eventComponentModel);
  }

  deleteEvent<T>(eventId: number): Observable<any> {
    return this.apiBaseService.delete('/api/event/'+ eventId ,null,eventId);
  }

  getEventForPlace<T>(placeId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/place/' + placeId + '/place-events', null, null);
  }

  getEventForSourceById<T>(sourceId: number): Observable<T> {
    return this.apiBaseService.get('/api/event/source/' + sourceId + '/source-events', null, null);
  }
}
