import { __extends } from 'tslib';
import { GenealogyDateMode } from '../../enums/enums';
import { GenealogyDateBaseModel } from '../../models/FTDate';
import { GeneologyDatePatterns } from './genealogy-date-patterns';

export class GenealogyDate extends GenealogyDateBaseModel {

    constructor(originalStr, culture, yearStr?, monthStr?, dayStr?, matchMode?, unKnownStr?) {
        super();
        this.culture = culture;
        this.originalStr = originalStr;
        this.matchMode = matchMode;

        this.isValidDate = false;
        if (matchMode != null && matchMode == GenealogyDateMode.full) {
            this.validateFullDate(yearStr, monthStr, dayStr);
        }
        if (matchMode != null && matchMode == GenealogyDateMode.partial) {
            this.validatePartialDate(yearStr, monthStr, dayStr, unKnownStr);
        }
    }

    public getDate = (): Date => {
        if (!this.isValidDate) {
            return null;
        }
        var d = new Date(this.year, this.month - 1, this.day)
        return d;
    }

    public setApprox(approxStr) {
        this.isApprox = true;
        this.approxStr = approxStr;
    }

    // Validate the data and fill information
    private validateFullDate(year, month, day) {
        var yearN = parseInt(year)
        var monthN = parseInt(month) - 1;  // -1 for js zero index month
        var dayN = parseInt(day);

        if (isNaN(month)) {
            monthN = GeneologyDatePatterns.months[this.culture].indexOf(month.toLowerCase().substring(0, 3));
        }
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setMilliseconds(0);
        d.setFullYear(yearN, monthN, dayN);

        if (d.getFullYear() == yearN && d.getMonth() == monthN && d.getDate() == dayN) {
            var today = new Date();
            // Check if future date ? then invalid
            if (d.getTime().valueOf() > today.getTime().valueOf()) {
                return;
            }
            this.year = yearN;
            this.month = monthN + 1;        // back to normal month index (+1)
            this.day = dayN;
            this.isValidDate = true;
        }
        return;
    }

    // Fill only the data that is there - not a valid date
    private validatePartialDate(year, month, day, unknown) {

        if (year) {
            var yearN = parseInt(year)
            // Set year
            this.year = yearN;
        }

        if (month) {
            var monthN = parseInt(month) - 1;  // -1 for js zero index month
            if (isNaN(month)) {
                monthN = GeneologyDatePatterns.months[this.culture].indexOf(month.toLowerCase().substring(0, 3));
            }

            // Check if month number valid
            if (monthN >= 0 && monthN <= 11) {
                this.month = monthN + 1;
            }
        }

        if (day) {
            var dayN = parseInt(day);
            if (dayN >= 1 && dayN <= 31) {
                this.day = dayN;
            }
        }

        // check more than 2 partial matchs
        if (!year && month && day) {
            if (!(this.month && this.day)) {
                // clear out if both of them are not filled
                this.month = undefined;
                this.day = undefined;
            } else {
                // check the month/day general validity
                var dayMax = GeneologyDatePatterns.months[this.month - 1]; // zero index
                if (this.day > dayMax) {
                    this.month = undefined;
                    this.day = undefined;
                }
            }
        }

        if (month && year && !day) {
            if (!(this.month && this.year)) {
                // clear out if both of them are not filled
                this.month = undefined;
                this.year = undefined;
            } else {
                // cehck month year general validity
                // cant be a future date
                var today = new Date();
                if (this.year > today.getFullYear() ||
                    this.year == today.getFullYear() && this.month > today.getMonth() + 1) {
                    this.month = undefined;
                    this.year = undefined;
                }
            }
        }

        if (unknown && !isNaN(unknown)) {
            this.unknown = parseInt(unknown);
        }

    }

    // public toString = (): string => {
    //     return `GenDate (ori: ${this.originalStr},y=${this.year},m=${this.month},d=${this.day},u=${this.unknow},a=${this.approxStr} )`;
    // }
}