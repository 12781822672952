import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SourceAPIService {
    constructor(private apiBaseService: ApiBaseService) { }

    getSourceList<T>(size: number, page: number): Observable<T> {
        return this.apiBaseService.get("/api/source", [], null);
    }
    
    getSourceById<T>(sourceId: number): Observable<T> {
        return this.apiBaseService.get("/api/source/"+sourceId, [], null);
    }

    getReferenceCountToSource<T>(sourceId : number): Observable<T> {
        return this.apiBaseService.get("/api/source/" + sourceId + "/references", [], null);
    }
    
    deleteSource<T>(sourceId: number): Observable<any> {
        return this.apiBaseService.delete('/api/source/'+ sourceId ,null,sourceId);
    }

    createSource(data: any): Observable<any> {
        return this.apiBaseService.create('/api/source', null, data);
    }
    updateSource(data: any): Observable<any> {
        return this.apiBaseService.update('/api/source/', null, data);
    }
    
}