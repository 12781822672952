export class TreeNode<T> 
{   
    // Postition parameters
    x  : number;
    y  : number;
    mod: number;
    level:number;

    // Tree and data parameters
    item    : T;
    parent  : TreeNode<T>;
    children: TreeNode<T>[];

    constructor(data: T, parent: TreeNode<T>) {
        this.item = data;
        this.parent = parent;  
        this.children = [];
    }

    // is leaf node?
    isLeaf(): boolean {
        return this.children.length === 0;
    }

    // Check if left node
    isLeftMost(): boolean {
        if (this.parent === null) {
            return true;
        } else {
            return this.parent.children[0] === this;
        }
    }
    
    // Check if right
    isRightMost(): boolean {
        if (this.parent === null) {
            return true;
        } else {
            return this.parent.children[this.parent.children.length - 1] === this;
        }
    }

    getPreviousSibling(): TreeNode<T> {
        if (this.parent === null || this.isLeftMost()) {
            return null;
        } else {
            let index = this.parent.children.indexOf(this);
            return this.parent.children[index - 1];
        }
    }

    getNextSibling(): TreeNode<T> {
        if (this.parent === null || this.isRightMost()) {
            return null;
        } else {
            let index = this.parent.children.indexOf(this);
            return this.parent.children[index + 1];
        }
    }
    
    getLeftMostSibling(): TreeNode<T> {
        if (this.parent === null) {
            return null;
        } else if (this.isLeftMost()) {
            return this;
        } else {
            return this.parent.children[0];
        }
    }

    getLeftMostChild(): TreeNode<T> {
        if (this.children.length === 0) {
            return null;
        } else {
            return this.children[0];
        }
    }

    getRightMostChild(): TreeNode<T> {
        if (this.children.length === 0) {
            return null;
        } else {
            return this.children[this.children.length - 1];
        }
    }
    
    get toString(): string {
        return this.item.toString();
    }
}