import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class StringFormatter {
    capitalizeFirstLetter(textToModify: string) {
        return textToModify.charAt(0).toUpperCase() + textToModify.slice(1);
    }
}