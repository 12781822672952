import { IndividualNameViewModel } from '../IndividualNameViewModel';
import { IndividualNameModel } from './individual-name-model';

export class IndividualRequestModel {
    id: number;
    memberName: string;
    gender: string;
    relatedFamilyId: number;
    relatedMemberId: number;
    relatedMemberGender: string;
    individualName: IndividualNameModel;
    firstName:string;
    lastName:string;
  }