import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportengine-template',
  templateUrl: './reportengine-template.component.html',
  styleUrls: ['./reportengine-template.component.scss']
})
export class ReportengineTemplateComponent implements OnInit {

  showLeftPanel: boolean = true;
  showFanChart: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.showFanChart = this.router.url.toString().includes("fanchartjs");
  }

}
