<div class="maincomp">
    <div class="container-fluid">
        <div class="row no-gutters">
            <div class="treeEditorView no-gutters">
                <app-treeeditorview  (isExpandingWheelActive)="isExpandingWheelActive($event)" [editedLabelImage]="editedLabelImage" (showMain)="showMain($event)" ></app-treeeditorview>
            </div>
        </div>
        <!-- <div class="row align-items-start top-layer" [ngClass] = "{ 'element-fade' : isExpandingWheelOn }">
            <app-breakcrumb></app-breakcrumb>
        </div> -->
    </div>
</div>
