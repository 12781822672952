<svg id="svgViewableArea" preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute"
  [attr.height]="config.reportArea.h"
  [attr.width]="config.reportArea.w"
  class="svgfull reportCommon"
  *ngIf="true"
  >
  <!-- Group for Report Area -->

  <!-- Report title --> 
  <text [attr.x]="config.base.reportTitle.x" [attr.y]="config.base.reportTitle.y" [attr.font-size]="config.base.reportTitle.size" [attr.class]="config.base.reportTitle.style" text-anchor="middle">
    {{reportTitle}}
  </text>  
  <g [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'"
     id="reportArea"
     [attr.transform]="reportTranslationObj.translateReportAttribute">
    <!-- Background Image -->
    <image [attr.href]="commonUrl + 'images/Background.png'"
           [attr.x]="0"
           [attr.y]="0"
           [attr.height]="config.base.svg.h"
           [attr.width]="config.base.svg.w" />
  </g>
</svg>
