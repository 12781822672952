import { Component, Input, OnInit } from '@angular/core';
import { DateHelper } from 'src/app/common/helpers/date-helper';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { FamilyProjectArchiveBaseModel } from 'src/app/common/models/familyProjectArchive/family-project-archive-base-model';

@Component({
  selector: 'app-archive-log-list',
  templateUrl: './archive-log-list.component.html',
  styleUrls: ['./archive-log-list.component.scss']
})
export class ArchiveLogListComponent implements OnInit {
  
  @Input() item: FamilyProjectArchiveBaseModel;
  isLogOpened :boolean;
  logUpdatedDate:string;
  projectLogArray: string[] = [];
  constructor(private translateHandler: TranslateHandler,
              private dateHelper: DateHelper) { }
 
  ngOnInit(): void {
    this.item = new FamilyProjectArchiveBaseModel();
    this.isLogOpened = false;
  }

  ngOnChanges(){
    //this.item = new FamilyProjectArchiveBaseModel();
    this.isLogOpened = false;
    if(this.item!=null){
      if(this.item.projectLog!=null) this.item.projectLog = this.translateLog(this.item.projectLog);
      this.mapUI(this.item);
    }
  }
  toggleItemLog(){
    this.isLogOpened = !this.isLogOpened;
  }

  getItemLength(){
    return this.projectLogArray?.length;
  }

  mapUI(item: FamilyProjectArchiveBaseModel){
    debugger;
    if(item.updatedDate != null){
      this.logUpdatedDate = this.translateHandler.translate("lbl_file_created_on") +" "+ this.dateHelper.validateAndFormat(item.updatedDate.toString()).formattedText;
    }    
    if(item.projectLog != null){
      this.projectLogArray = item.projectLog.split(/\r?\\n/);
    }
  }

  translateLog(projectLog:string){
    
    var logString = projectLog;
    /*
    // check all occurrences and replace with the translation
    logString = logString.replace(/Initialized/g,this.translateHandler.translate("lbl_process_started"));
    logString = logString.replace(/Exporting/g,this.translateHandler.translate("lbl_exporting"));
    logString = logString.replace(/individuals/g,this.translateHandler.translate("lbl_people").toLowerCase());
    logString = logString.replace(/families/g,this.translateHandler.translate("lbl_families").toLowerCase());
    logString = logString.replace(/events/g,this.translateHandler.translate("lbl_events").toLowerCase());
    logString = logString.replace(/places/g,this.translateHandler.translate("lbl_places").toLowerCase());
    logString = logString.replace(/source citations/g,this.translateHandler.translate("lbl_citations").toLowerCase());
    logString = logString.replace(/sources/g,this.translateHandler.translate("lbl_sources").toLowerCase());
    logString = logString.replace(/Completed/g,this.translateHandler.translate("lbl_process_taskcompleted"));
    logString = logString.replace(/and/g,this.translateHandler.translate("lbl_and"));
  */    
    return logString;
  }
}
