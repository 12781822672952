<div class="ft-form">
    <div class="content">
      <div class="ft-form-row">
        <span class="title">{{data.title}}</span>
      </div>
      <div class="ft-form-row">
        <div class="ft-form-row col1-v100">
          <div class="ft-form-input">
            <div class="icon-input-container">
              <input class ="icon-input" placeholder="{{'ph_search_place' | translate}}"
                type="text" [(ngModel)]="searchPhrase" >
              <div class="icon-input-wrap">                      
                  <img src="/assets/images/icons/search.svg"  width= "30px" height="30px">                      
              </div>                  
            </div>
          </div>
        </div>            
      </div>         
      <app-media-gallery style="height:calc(100% - 100px)" [items]="mediaItems" [searchPhrase]="searchPhrase" [isStandAlone]="isStandAlone" (action)="dropMenuAction($event)"></app-media-gallery>          
    </div>
    <div class="footer">    
      <button *ngIf="data.options.isAddBtn" class = "ft-btn" [ngClass]="{'ft-btn-active':setEnablity(),'ft-btn-inactive':!setEnablity()}" [disabled]="!setEnablity()" (click)="addPlace()">{{"lbl_add" | translate}}</button>
      <button *ngIf="data.options.isCloseBtn" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
  </div>