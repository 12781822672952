import { Component, OnInit } from '@angular/core';
import { EditorMode } from 'src/app/common/enums/enums';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { ProjectRefService } from 'src/app/services/project-ref.service';

@Component({
  selector: 'app-editors',
  templateUrl: './editors.component.html',
  styleUrls: ['./editors.component.scss']
})
export class EditorsComponent implements OnInit {

  constructor(private notifierService   : NotifierV2Service,
              private projectRefService : ProjectRefService) {
    
   }

  ngOnInit(): void {
  }

  getEditorModes():typeof EditorMode{
    return EditorMode;
  }

  getCurrentEditor() 
  {
    return this.notifierService.getCurrentEditor();    
  }

  isDisabled(){
    //return true;
    return !this.projectRefService.isCurrentProjectEditable();
  }

  openEditor(editorMode:EditorMode){
    let ref:EditorReferenceInfo = {
      id : (editorMode === EditorMode.FamilyEditor) ? this.notifierService.getCurrentRootFamilyId() : this.notifierService.getCurrentRootMemberId(),      
      isBreadcrumbUpdate: true
    }
    this.notifierService.openEditor(editorMode, ref);
  }
}
