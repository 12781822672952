<div class="maindiv">
  <div class="background">
    <img src="assets/images/Background- s.png" style="height: 100%;width: 100%;" />
  </div>

  <!-- report engine buttons - start -->
  <div [ngClass]="
    true ? 'zoom-in-button' : 'zoom-in-button-disable'
  " (click)="zoom(true)">
    <span class="toolTip">{{"lbl_zoomin" | translate}}</span>
  </div>

  <div [ngClass]="
    true ? 'zoom-out-button' : 'zoom-out-button-disable'
  " (click)="zoom(false)">
    <span class="toolTip">{{"lbl_zoomout" | translate}}</span>
  </div>

  <div [ngClass]="
    true ? 'center-button' : 'center-button-disable'
  " (click)="isCenterEnabled ? center() : null">
    <span class="toolTip">{{"lbl_center" | translate}}</span>
  </div>

  <div [ngClass]="themeContentlist?.length>0 ? 'dropdown' : 'dropdown-disable'">
    <div class="dropdown-content">
      <div *ngFor="let theme of themeContentlist" (click)="selectTheme(theme.id)">
        <a style="cursor: pointer !important">{{ theme.name | translate }}</a>
      </div>
    </div>
  </div>

  <!--div class="refresh-button" (click)="reset()">
    <span class="toolTip">{{"lbl_reset" | translate}}</span>
  </div-->

  <div class="zoom-to-extend-button" (click)="zoomToExtend()">
    <span class="toolTip">{{"lbl_zoomToExtend" | translate}}</span>
  </div>

  <!-- Disabled, until report manager enables -->
  <div *ngIf="true" class="publish" (click)="publishReport()">
    <span class="toolTip">{{"lbl_publish" | translate}}</span>
  </div>
  <div class="reportoption-button" (click)="openReportPopup()">
    <span class="toolTip">{{"lbl_options" | translate}}</span>
  </div>
  <!-- End -->

  <div *ngIf="zoomlevel" class="zoomlevel">zoom level:{{ currentZoomLevel }}%</div>
  <div id="domReport">

    <ng-template appDynamicReport></ng-template>

  </div>
</div>