import { EventBase } from 'src/app/common/models/event/event-base';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { FTDate } from '../../../common/models/FTDate';
import { MultiMediaBasicInfo } from '../../../common/models/MultiMediaBasicInfo';
import { FamilyBase } from './family-base';
import { FamilyIndividual } from './family-individual';

//#region "Family"


// Todo: colons and equals aligned (use extension on VS Code)
export class FamilyComponentModel extends FamilyBase {
    husband: FamilyIndividual = null;
    wife: FamilyIndividual = null;
    multimedia: Multimedia[];
}

export class DefaultFamilyRequest {
    familyId: number;
}

//#endregion "Family"

//#region "Source"

// While Showing the events related to Source in editor, (UI: 7) show extra infos of event + citation
export class SourceBase {
    id: number;
    title: string;
    sourceMediaUrl: string;
    // Que: Period: string ?? Que: What's the calculation of this?
    // Que: URL ? Not in the model but in the source interface.
}

export class Source extends SourceBase {
    type: number;
    publication: string;
    author: string;
    repository: string;
    notes: string;
    transcript: string;
    sourceType: number;
    mediaIds: number[];
    sourceCitationIds: number;
    date: FTDate;
    placeId: number;
}

//#endregion "Source"

//#region "SourceCitation"
export class SourceCitationBase {
    id: number;
    eventId: number;
    sourceId: number;
    sourceImageUrl: string;
    sourceName: string; // No direct field with Name.
}

export class SourceCitation extends SourceCitationBase {
    type: number;
    comment: string;
    volume: string;
    quality: string;
    formattedText: string;
    url: string;
    // MMId: string ? Multimedia Id?
}

//#endregion "SourceCitations"

//#region "Witness"
export class WitnessBase {
    id: number;
    name: string;
}

export class Witness extends WitnessBase {
    address: string;
    note: string;
}

//#endregion "Witness"

