import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MembershipApiService } from './API/membership-api.service';
import { AuthenticationService } from './authentication.service';
import { MembershipExpiryModel } from '../common/models/MemberAccess/MembershipExpiryModel';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  membershipExpiration: MembershipExpiryModel = new MembershipExpiryModel();

  constructor(private membershipapiservice: MembershipApiService, private authenticationService: AuthenticationService) { }

  public async init(){
    try {
      const customerId = this.authenticationService.getUser().CustomerId;
      this.membershipExpiration = await this.membershipapiservice.getMembershipExpiration(customerId).toPromise();
    } catch (e) {
      this.membershipExpiration = null;
    }
  }

  public getMembershipExpirationObj(): MembershipExpiryModel {
    return this.membershipExpiration;
  }

  public getRemainingDays(): number {
    return this.membershipExpiration.remainingDays;
  }

  public getExpireDate(): Date {
    return this.membershipExpiration.expireDate;
  }

  public isMembershipExpired(): boolean {
    const remainingDays = this.getRemainingDays();
    return remainingDays < 0;
  }
}
