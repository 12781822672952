import { Injectable } from '@angular/core';
import { PlaceBase } from 'src/app/common/models/place/place-base';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { PlaceAPIService } from 'src/app/services/API/place-api.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceInputService {

  private placeList: any;

  constructor(private placeApiService: PlaceAPIService) { }

  loadPlaceList() {
    this.placeApiService.getPlaceList(1, 1).subscribe((response: ResponseModel<PlaceBase>) => {
      this.placeList = response.data;
    });
  }

  getPlaces() {
    return this.placeList;
  }

}
