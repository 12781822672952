import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction } from 'src/app/common/enums/enums';
import { MediaGalleryItem } from '../models/media-gallery-item';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';

@Component({
  selector: 'app-media-gallery-list',
  templateUrl: './media-gallery-list.component.html',
  styleUrls: ['./media-gallery-list.component.scss']
})
export class MediaGalleryListComponent implements OnInit {
  @Input() items: MediaGalleryItem[] = [];
  @Input() searchPhrase : string = "";
  @Output() action = new EventEmitter<{action: DropMenuAction, reference: any}>();

  selectedItem : MediaGalleryItem = new MediaGalleryItem();
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";


  constructor() { }

  ngOnInit(): void {
    console.log("Media List",this.items)
  }

  selectItem(item:MediaGalleryItem){
    if (this.selectedItem.reference != item.reference){
      //clear selection
      this.items.map( i => i.isSelected = false);
    }
    this.selectedItem = item;
    item.isSelected = !item.isSelected;    
  }

  getSortedMediaItems(){     
    // Todo: commented sorting function by PO request
    // return this.items.filter( i => i.caption.toLowerCase().includes(this.searchPhrase.toLowerCase())).sort(this.compareSelected);
    //Implement search tag function (optional use if require)
    //console.log(">>>>>>>>>>>>>>> Individual list "+this.items.length);
    return this.items.filter( i => i.caption && (i?.caption?.toLowerCase().includes(this.searchPhrase.toLowerCase()) || (i?.searchTag?.toLowerCase() ?? "").includes(this.searchPhrase.toLowerCase())));
  }

  //use when item loading error
  onItemError(event: any) {
    event.target.src = this.errorImg;
  }

  clickedAction($event) {
    if ($event.action === DropMenuAction.Download) {
      window.open($event.reference.reportUrl, "_blank");
    }
    console.log($event);
    this.action.emit($event);
  }  

  trackMedia(index,item){
    return item ? item.isDisabled : undefined;
  }

}
