import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TooltipService } from 'src/app/services/UI/tooltip.service';
import { TooltipHeadPosition } from '../../tooltip/models/tooltip-head-position';
import { EventDetailMediaComponent } from '../event-tips/event-detail-media/event-detail-media.component';
import { EventDetailNoteComponent } from '../event-tips/event-detail-note/event-detail-note.component';
import { EventDetailSourceComponent } from '../event-tips/event-detail-source/event-detail-source.component';
import { EventDetailWitnessComponent } from '../event-tips/event-detail-witness/event-detail-witness.component';;
import { EventComponentModel } from '../models/event-component-model';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { EventApiService } from 'src/app/services/API/event-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { Witness } from 'src/app/components/familyeditor/models/family-component-model';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { MultiMediaBasicInfo } from 'src/app/common/models/MultiMediaBasicInfo';
import { EventSourceViewModel } from '../../source/models/eventSourceViewModel';
import { EventViewBase } from '../models/event-view-base';
import { EditorMode, ReferenceType } from 'src/app/common/enums/enums';
import { AgeCalculator } from 'src/app/common/helpers/Family/age-calculator-helper';
import { EventBase } from 'src/app/common/models/event/event-base';
import { FTDate } from 'src/app/common/models/FTDate';
import { eventNames } from 'process';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { StringFormatter } from 'src/app/common/helpers/string-formatter';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { EventDetailIndividualComponent } from '../event-tips/event-detail-individual/event-detail-individual.component';
import { DateHelper } from 'src/app/common/helpers/date-helper';
import { PreferencesService } from '../../../../services/preferences.service';


@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})

/* ViewChild with ngIf will not work - element not created.. using [hidden] parameter
https://stackoverflow.com/questions/55610047/angular6-viewchild-undefined-with-ngif
*/

export class EventListComponent implements OnInit {

  //TODO: Input and outputs
  @ViewChild('eventListContainer') eventContainer: ElementRef;
  @ViewChild('eventitemContainer') eventItemContainer: ElementRef;
  @ViewChild('eventdetailsContainer') eventdetailsContainer: ElementRef;

  @Input() data: any;
  @Input() eventList: EventViewBase[];
  @Output() editEvent = new EventEmitter<number>();
  @Output() refreshEventList = new EventEmitter<boolean>();
  @Input() eventConfig: any;
  @Input() timeStamp: any;
  eventWitness: Witness[];
  evenMultimedia: MultiMediaBasicInfo[];
  evenSources: SourceCitationViewModel[];
  eventIndividuals: IndividualViewBase[];
  errorImg: string = "/assets/images/icons/event.svg";
  tooltipPositionSwapRatio: number = 0.8;
  midTooltipPositionSwapRatio: number = 0.9;
  referenceType: ReferenceType;
  individualBirthday: FTDate;
  disableForm: boolean = false;
  dateHelper: DateHelper;


  constructor(private tooltipService: TooltipService,
    private parentCommunicatorService: ParentCommunicatorService,
    private translateHandler: TranslateHandler,
    private eventApiService: EventApiService,
    private ageCalculator: AgeCalculator,
    private loadingService: LoadingService,
    private stringFormatter: StringFormatter,
    private messageDialogService: MessageDialogService,
    private preferenceService: PreferencesService) {
    this.individualBirthday = new FTDate();
    this.dateHelper = new DateHelper(preferenceService);
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    // this.disableForm = this.data.isEditorDirty;
    if (this.data && this.data.id) {
      this.referenceType = this.eventConfig?.referenceType;
      this.individualBirthday = new FTDate();
      if (this.referenceType == ReferenceType.Individual) {
        this.setIndividualBirthdate();
      }
    }
  }

  ngDoCheck(): void {
    this.disableForm = this.data.isEditorDirty;
  }

  onImageLoadingError(event: any) {
    event.target.src = this.errorImg;
  }

  openEventEditor(event) {
    this.editEvent.emit(event);
  }

  delete(event: EventViewBase) {
    var message = this.getDeleteConfirmationMessage(event);
    //Show delete confirmation message
    this.messageDialogService.openDeleteConfirmation(message).subscribe((res: boolean) => {
      if (res) {
        this.deleteSelectedEvent(event);
      }
    }, (err) => {
      throw new CustomError("EventListComponent => delete() : " + err, 911, false);
    }).add(() => {
    });
  }

  //delete selected event
  deleteSelectedEvent(event: EventViewBase) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.eventApiService.deleteEvent(event.id).subscribe((response: ResponseModel<boolean>) => {
      if (response.data) {
        this.refreshEventList.emit(response.data);
      }
    }, (err) => {
      throw new CustomError("EventListComponent => deleteEvent() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  getDeleteConfirmationMessage(event: EventViewBase) {
    var eventName = this.translateHandler.translate('event_' + event.name.toLowerCase()).toLowerCase() + " " + this.translateHandler.translate("lbl_event").toLowerCase()
    var sourceRef = event.noOfSources > 0 ? "(" + event.noOfSources + ") " + this.translateHandler.translate("lbl_source").toLowerCase() + "," : "";
    var mediaRef = event.noOfMultimedias > 0 ? "(" + event.noOfMultimedias + ") " + this.translateHandler.translate("lbl_multimedia").toLowerCase() + "," : "";
    var witnessRef = event.noOfWitnesses > 0 ? "(" + event.noOfWitnesses + ") " + this.translateHandler.translate("lbl_witness").toLowerCase() : "";
    var referenceText = (event.noOfSources == 0 && event.noOfMultimedias == 0 && event.noOfWitnesses == 0) ? this.translateHandler.translate("lbl_no_reference").toLowerCase() : sourceRef + ' ' + mediaRef + ' ' + witnessRef;

    return this.translateHandler.translate('cnf_delete_confirmation_message', [eventName, referenceText]);
  }


  showNotImplemented() {
    this.parentCommunicatorService.send(ParentMessageType.ShowInformation, "not_implemented");
  }

  capitalizeFirstLetter(text: string) {
    return this.stringFormatter.capitalizeFirstLetter(text.toLowerCase());
  }
  // Calculate age of individual when event happend
  getAgeData(event: EventBase) {
    return this.ageCalculator.calculate(event, this.individualBirthday);
  }

  //set individual birth date
  setIndividualBirthdate() {
    let birthEvent = (this.eventList != null && this.eventList.length > 0) ? this.eventList.filter(s => s.type == ReferenceType.Individual && s.name == "BIRTH" && s.isDirectEvent == true) : null;
    this.individualBirthday = (birthEvent != null && birthEvent.length > 0) ? birthEvent[0].date : null;
  }

  getImageByRefType(event: EventBase) {
    return event.type == 1 ? "/assets/images/icons/member.svg" : event.type == 2 ? "assets/images/icons/family-dark.svg" : "";
  }

  showToolTipNotes(event: any, note: any) {
    this.setSelectedOption(event.target);
    // load data
    let listBound = this.eventContainer.nativeElement.getBoundingClientRect();
    //check whether tooltip position should swap (top/bottom)
    let isHeadPositionChanged = (listBound.height * this.tooltipPositionSwapRatio) < event.y;
    let data = {
      name: this.translateHandler.translate("lbl_note"),
      value: note
    };
    this.tooltipService.show(event, isHeadPositionChanged ? TooltipHeadPosition.Bottom : TooltipHeadPosition.Top, "white", EventDetailNoteComponent, data);
  }

  showToolTipDescription(event: any, description: any) {
    this.setSelectedOption(event.target);
    let listBound = this.eventContainer.nativeElement.getBoundingClientRect();
    //check whether tooltip position should swap (top/bottom)
    let isHeadPositionChanged = (listBound.height * this.tooltipPositionSwapRatio) < event.y;
    let data = {
      name: this.translateHandler.translate("lbl_description"),
      value: description
    };
    this.tooltipService.show(event, isHeadPositionChanged ? TooltipHeadPosition.Bottom : TooltipHeadPosition.Top, "white", EventDetailNoteComponent, data);

  }

  showToolTipWitness(event: any, eventId: number) {
    this.setSelectedOption(event.target);
    let listBound = this.eventContainer.nativeElement.getBoundingClientRect();
    //check whether tooltip position should swap (top/bottom)
    let isHeadPositionChanged = (listBound.height * this.midTooltipPositionSwapRatio) < event.y;
    //Get event witness details for selected event
    this.eventApiService.getEventWitness(eventId).subscribe((response: ResponseModel<Witness[]>) => {
      this.eventWitness = response.data;
      let data = {
        name: this.translateHandler.translate("lbl_witness"),
        value: response.data
      };
      this.tooltipService.show(event, isHeadPositionChanged ? TooltipHeadPosition.Bottom : TooltipHeadPosition.Top, "white", EventDetailWitnessComponent, data);
    }, (err) => {
      throw new CustomError("EventList => GetEventWitnesses() : " + err, 911, false);
    }).add(() => {

    });
  }

  showToolTipMultimedia(event: any, eventId: number) {
    this.setSelectedOption(event.target);
    let listBound = this.eventContainer.nativeElement.getBoundingClientRect();
    //check whether tooltip position should swap (top/bottom)
    let isHeadPositionChanged = (listBound.height * this.midTooltipPositionSwapRatio) < event.y;
    //Get event multimedia details for selected event
    this.eventApiService.getEventMedia(eventId).subscribe((response: ResponseModel<MultiMediaBasicInfo[]>) => {
      this.evenMultimedia = response.data;
      let data = {
        name: "",
        value: response.data
      };
      this.tooltipService.show(event, isHeadPositionChanged ? TooltipHeadPosition.Bottom : TooltipHeadPosition.Top, "white", EventDetailMediaComponent, data);
    }, (err) => {
      throw new CustomError("EventList => GetEventWitnesses() : " + err, 911, false);
    }).add(() => {

    });
  }

  showToolTipSources(event: any, eventId: number) {
    this.setSelectedOption(event.target);
    let listBound = this.eventContainer.nativeElement.getBoundingClientRect();
    //check whether tooltip position should swap (top/bottom)
    let isHeadPositionChanged = (listBound.height * this.tooltipPositionSwapRatio) < event.y;
    //Get event sources details for selected event  
    this.eventApiService.getEventSources(eventId).subscribe((response: ResponseModel<SourceCitationViewModel[]>) => {
      this.evenSources = response.data;
      let data = {
        name: "",
        value: response.data
      };
      this.tooltipService.show(event, isHeadPositionChanged ? TooltipHeadPosition.Bottom : TooltipHeadPosition.Top, "white", EventDetailSourceComponent, data);
    }, (err) => {
      throw new CustomError("EventList => GetEventSources() : " + err, 911, false);
    }).add(() => {
    });
  }

  showToolTipIndividuals(event: any, eventId: number) {
    this.setSelectedOption(event.target);
    let listBound = this.eventContainer.nativeElement.getBoundingClientRect();
    //check whether tooltip position should swap (top/bottom)
    let isHeadPositionChanged = (listBound.height * this.midTooltipPositionSwapRatio) < event.y;
    //Get event individuals details for selected event
    this.eventApiService.getEventIndividuals(eventId).subscribe((response: ResponseModel<IndividualViewBase[]>) => {
      this.eventIndividuals = response.data;
      let data = {
        name: "",
        value: response.data
      };
      this.tooltipService.show(event, isHeadPositionChanged ? TooltipHeadPosition.Bottom : TooltipHeadPosition.Top, "white", EventDetailIndividualComponent, data);
    }, (err) => {
      throw new CustomError("EventList => GetEventIndividuals() : " + err, 911, false);
    }).add(() => {

    });
  }


  onContainerScroll($event: any) {
    this.hideToolTip();
    this.removeAllselectedOptions();
  }

  // TODO : Check browser support? element.classList

  setSelectedOption(element) {
    this.removeAllselectedOptions();
    element.classList.add('option-selected');
  }

  removeAllselectedOptions() {
    var selectedOptionsElements = document.getElementsByClassName('option-selected');
    if (selectedOptionsElements.length > 0) {
      selectedOptionsElements[0].classList.remove('option-selected');
    }
  }

  //@HostListener('scroll', ['$event']) // for scroll events of the current element
  onScroll(event: any) {
    this.hideToolTip();
    this.removeAllselectedOptions();
  }

  // TODO: This is called for normal button events have to stop it
  hideToolTip() {
    this.tooltipService.hide();
    //if (this.child)
    //  this.child.hide();
  }

  getTimeStampedImageUrl(mediaUrl: any) {
    return (mediaUrl != null && mediaUrl != undefined) ? mediaUrl + '&v=' + this.timeStamp : null;
  }

  getFormattedDate(date: string) {
    return this.dateHelper.validateAndFormat(date).formattedText;
  }
}
