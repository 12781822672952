import { GenealogyDate } from './genealogy-date';

export class GenealogyDateRange {
    originalStr: string;
    from: GenealogyDate;
    to: GenealogyDate;
    rangeType: number; // 1 one year 1971/72 2- normal range
    rangeStr: string; // from/between

    constructor(originalStr, from, to, rangeType, rangeStr) {
        this.originalStr = originalStr;
        this.from = from;
        this.to = to;
        this.rangeType = rangeType;
        this.rangeStr = rangeStr;
    }

    // public toString = (): string => {
    //     return `GenRange (ori:${this.originalStr}, from: ${this.from},to=${this.to}, type=${this.rangeType}, rangeStr=${this.rangeStr})`;
    // }
}