import { Component, OnInit } from '@angular/core';
import { TooltipContentBaseComponent } from '../../../tooltip/tooltip-content-base/tooltip-content-base.component';
import { EventSourceViewModel } from '../../../source/models/eventSourceViewModel';
import { MultimediaTypes } from 'src/app/common/enums/enums';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';

@Component({
  selector: 'app-event-detail-source',
  templateUrl: './event-detail-source.component.html',
  styleUrls: ['./event-detail-source.component.scss','../../events-main/events-main.component.scss']
})
export class EventDetailSourceComponent extends TooltipContentBaseComponent implements OnInit {

  public data: any;
  eventSources: SourceCitationViewModel[];
  currentIndex  : number = 0;
  currentSourceRecord: SourceCitationViewModel;
  
  ngOnInit(): void {
    this.eventSources = new Array<SourceCitationViewModel>();
    this.eventSources = this.data.value;
    this.currentSourceRecord = new SourceCitationViewModel();
    this.currentSourceRecord= this.eventSources[this.currentIndex];
  }

  //Get source primary media url and set default image
  getSourceMediaUrl(){
    let url = (this.currentSourceRecord?.sourceBase?.mediaUrl != null) ? this.currentSourceRecord?.sourceBase?.mediaUrl : "assets/images/icons/source.svg";
    return url;
  }

  slideLeft(){
    this.currentIndex--;
    if ( this.currentIndex < 0 ){
      this.currentIndex = this.eventSources.length -1;
    }  
    this.currentSourceRecord = this.eventSources[this.currentIndex];  
  }

  slideRight(){
    this.currentIndex++;
    if ( this.currentIndex >= this.eventSources.length ){
      this.currentIndex = 0;
    }    
    this.currentSourceRecord = this.eventSources[this.currentIndex];
  }

}
