import { BranchHelper } from "../../common/reportAlgorithm/branch-helper";
import { TreeNode } from "../../common/reportAlgorithm/tree-node";
import { ArtisticGraphics } from "./artistic-graphics";
import { ArtisticTreeBase } from "./artistic-tree-base";
import { ArtisticTreeNode } from "./artistic-tree-node";

export class ArtisticFractal extends ArtisticTreeBase{

    getNodes() {
        let nodeList  = [];
        this.initializeLevel(this.rootNode, 0);
        this.generateFractalNodes(this.rootNode, this.reportBase.svg.w/2, this.implConfig.base.initialY, this.implConfig.base.initialLevel, 0);
        this.createNodeList(nodeList,this.rootNode);
        let uiNodeList = this.getUiNodes(nodeList)
        return uiNodeList;
    }

    getBranches() {
        let branchList = []
        let branchUrl = this.commonUrl + "images/" + this.implConfig.base.assetUrl + "branches"
        let branchHelper = new BranchHelper(branchUrl, this.implConfig.branches)
        branchHelper.calculateBranches(this.rootNode, branchList)
        return branchList;
    }

    getUiNodes(nodeList) {
        let graphicNodes = []
        let graphicHelper =  new ArtisticGraphics(this.commonUrl, this.implConfig)
        for (let node of nodeList){
          let element = graphicHelper.getChartData(node);
          graphicNodes.push(element);
        }
        return graphicNodes;
    }

    generateFractalNodes(node: TreeNode<ArtisticTreeNode>, startX, startY, len, angle) {

        node.x = startX + len*Math.sin(angle*Math.PI/180);
        let y = startY + len*Math.cos(angle*Math.PI/180);
        node.y = startY - (y - startY);
    
        let angleConfig = this.implConfig.base.angleConfig;
    
      for (let i = 0; i < node.children.length; i++){
            this.generateFractalNodes(node.children[i], node.x, node.y, len*0.9, i*angleConfig- angleConfig/2* (node.children.length-1) + angle); 
      }
    
    }

    // recursive function to get the nodeList when root node is given
    private createNodeList(nodeList, rootNode){
        for (let i = 0; i < rootNode.children.length; i++) {
            this.createNodeList(nodeList,rootNode.children[i]);
        }
        nodeList.push(rootNode)
    }


    //Assign initial levels to the nodes| Used in graphic nodes
    private initializeLevel<T>(node: TreeNode<T> , level:number)
    {
        node.level = level;
        node.children.forEach(child=>{
            this.initializeLevel(child, level + 1);
        })  
    }

}