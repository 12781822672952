import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditorMode, FamilyArchiveActions, FamilyArchiveStatus, OriginalSourceType } from 'src/app/common/enums/enums';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { FamilyProjectBaseModel } from 'src/app/common/models/familyproject/family-project-base-model';
import { FamilyProjectRequestModel } from 'src/app/common/models/familyproject/family-project-request-model';
import { FamilyProjectArchiveBaseModel } from 'src/app/common/models/familyProjectArchive/family-project-archive-base-model';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { FamilyProjectArchiveApiService } from 'src/app/services/API/family-project-archive-api.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { interval, Observable, timer } from 'rxjs';
import { delay, take, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-family-project-import-option',
  templateUrl: './family-project-import-option.component.html',
  styleUrls: ['./family-project-import-option.component.scss']
})
export class FamilyProjectImportOptionComponent implements OnInit {
  
  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();
  @Output() onImportStarted = new EventEmitter<any>();

  // File upload readed
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('dropzone') dropZone : ElementRef; //For dropzone div element
  
  // Current selected project
  selectedFamilyProject: FamilyProjectBaseModel;
  familyProjectName : string ="";
  isProjectNameDisabled : boolean = false;

  familyProjectList : FamilyProjectBaseModel[] = [];
  logTitle :string;
  selectedImportType:string="";

  //File upload related
  fileErrorMessage :string = "";
  uploadFile;

  // Check if all operations are completed (import completed) - loading screen
  isImporting:boolean;
  isImportSuccess:boolean;
  isShowImportError:boolean;

  constructor(private projectRefService     : ProjectRefService,
              private translateHandler      : TranslateHandler,
              private familyProjectService  : FamilyProjectApiService,
              private archiveService        : FamilyProjectArchiveApiService,
              private notifierService       : NotifierV2Service,
              private dialogService         : DialogService,
              ) {    
  }

  ngOnInit(): void {    
    this.familyProjectList = this.projectRefService.getProjectList();       
    this.isImporting = false;
    this.isShowImportError = false;
    
    if ( this.data.selectedProjectId){
      this.selectedFamilyProject = this.familyProjectList.find( p => p.familyProjectId == this.data.selectedProjectId );
      if ( this.selectedFamilyProject ){
        this.familyProjectName = this.selectedFamilyProject.projectName
        this.isProjectNameDisabled = true;
      }        
    }
    //this.selectedFamilyProject = this.familyProjectList.find( p => p.projectName.toLowerCase() == projectNameInput.toLowerCase() )
    //Test the timer
    // check every 2 mins for 3 times
    // exit when sucess
    // exit when done 2x3
    // capture finally
    
    
  }

  selectProject(event){
    let projectNameInput:string = event.target.value;
    this.selectedFamilyProject = this.familyProjectList.find( p => p.projectName.toLowerCase() == projectNameInput.toLowerCase() )
    this.fillLastArchive();
    this.setDirty();
  }
  
  fillLastArchive(){
    this.logTitle = this.translateHandler.translate("manage_tree.import.lbl_file_log_title" , [ this.selectedImportType.toLowerCase()]);
    this.setDirty();
  }

  // Load via input button
  clickFileInput() {
    this.fileInput.nativeElement.click();
  }
  // Stop default behaviour - load on browser
  dragOver($event){
    // change background color
    $event.preventDefault();
    $event.stopPropagation();
  }

  // Load via drag and drop
  filesDropped($event){
    $event.preventDefault();
    $event.stopPropagation();
     // Drag drop
    if ($event.dataTransfer && $event.dataTransfer.files) {
      var filesToUpload = $event.dataTransfer.files;    
      console.log(filesToUpload)  
      this.fileValidate(filesToUpload);
    }    
  }
  
  // Load via openDialog box
  fileUploaded($event){
    //File upload
    if ($event.target && $event.target.files) {
      var filesToUpload = $event.target.files;
      console.log(filesToUpload)  
      this.fileValidate(filesToUpload);
    }
  }
  /*
  File -----------------------
    lastModified: 1615272051647
    lastModifiedDate: Tue Mar 09 2021 12:10:51 GMT+0530 (India Standard Time) {}
    name: "scaned20210309_12105167.pdf"
    size: 259553
    type: "application/pdf"
    webkitRelativePath: ""
  */

  length: 1
  fileValidate(files){
    this.fileErrorMessage = "";

    if ( files.length == 0 ){
      this.fileErrorMessage = this.translateHandler.translate("upload_content.err_file_invalid");
      return;
    }

    // Get the first file
    let uploadedFile = files[0];
    
    // File size greated than 5mb ?
    if (  uploadedFile.size > 5 * 1024 * 1024 ){
      this.fileErrorMessage = this.translateHandler.translate("upload_content.err_file_too_large");
      return;
    }

    if ( this.getFileType(uploadedFile.name) != "ged"){
      this.fileErrorMessage = this.translateHandler.translate("upload_content.err_file_not_supported");      
      return;
    }   
    
    this.uploadFile = uploadedFile;
    this.setDirty();
  }

  //TODO: Duplicated in media 
  getFileType(fileName){    
    var lastDotIndex = fileName.lastIndexOf('.')
    if ( lastDotIndex < 0 ){
      return null;
    }
    return fileName.substring(lastDotIndex + 1).toLowerCase();
  }

  
  acceptMediaExtension :string = ".ged";
  isFormDirty:boolean;

  setDirty(){    
    this.isImporting    = false;
    this.isImportSuccess = false;
    this.isShowImportError = false;    
    this.isFormDirty = this.fileErrorMessage == "" && this.familyProjectName.trim() != "" && this.selectedImportType != "" && this.uploadFile.size!=0;
  }

  async import(){
    this.isImporting = true;
    this.onImportStarted.emit({data:"import_started"})
    
    //if project not exists -> create new
    let familyProjectId:string;
    
    if ( !this.selectedFamilyProject ){
      let familyProject:FamilyProjectRequestModel = new FamilyProjectRequestModel(); 
      familyProject.projectName         = this.familyProjectName.trim();
      familyProject.individualGivenName = "Root";
      familyProject.individualLastName  = "Member";
      familyProject.individualGender    = "M";
      //familyProject.originalSourceType  = OriginalSourceType.GEDCOM;  //TODO : Change according the type..this is hardcoded
      familyProject.originalSourceType  = this.selectedImportType;  // Make sure its the same as orginalsourcetype
      var res = await this.familyProjectService.createFamilyProject(familyProject).toPromise();
      familyProjectId = res.data;    
    }else{
      familyProjectId = this.selectedFamilyProject.familyProjectId;
    }
    
    
    let familyArchive:FamilyProjectArchiveBaseModel = new FamilyProjectArchiveBaseModel();
    familyArchive.familyProjectId  = familyProjectId; 
    familyArchive.type             = this.selectedImportType;
    familyArchive.fileName         = this.uploadFile.name;
    familyArchive.action           = FamilyArchiveActions.Import;
    familyArchive.projectLog       = null;
    familyArchive.status           = FamilyArchiveStatus.Pending;

    
    let formData = new FormData();
    formData.append('uploadedFile', this.uploadFile, this.uploadFile.name);
    formData.append('fileArchiveInfo', JSON.stringify(familyArchive));
    
    var resArch = await this.archiveService.createFamilyProjectArchive(formData).toPromise();
    let familyArchiveId = resArch.data;
    console.log("archive created done !!!"+familyArchiveId);
   
    let resArchive:FamilyProjectArchiveBaseModel;
    for (let index = 0; index < 3; index++) {
      resArchive = await this.archiveService.getFamilyProjectArchiveId<FamilyProjectArchiveBaseModel>(familyArchiveId).pipe(delay(20000)).toPromise();
      console.log(resArchive)
      if ( resArchive.status == FamilyArchiveStatus.Success ){
        break;
      }
    }
    
    if ( resArchive && (resArchive.status ==  FamilyArchiveStatus.Success)){                  
      this.isImporting      = false;
      this.isImportSuccess  = true;        
      this.dialogService.complete({ data: "import_success" });
    }else{
      this.isShowImportError = true;            
    }
  }

  showTreeEditor(){
    let ref:EditorReferenceInfo = {
      id : EditorMode.TreeEditor,      
      isBreadcrumbUpdate: true
    }

    this.notifierService.openEditor(EditorMode.TreeEditor, ref);        
        this.dialogService.complete({ data: "import_success" });
        return;
  }

  cancel(){
    this.dialogService.complete({ data: null });
  }

  continueError(){
    this.dialogService.complete({ data: "import_error" });
  }
}
