import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class SessionData {
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public EmailAddress: string;
    public OnlineId: string;
    public PurchasedProducts: string[];
    public fileName: string;
    public isAuthenticated: boolean;
    public token: string;
    public encEmail: string;
    public redirectionUrl: string;
    public logOutUrl: string;
    public rootMemberId: number;
    public keyMemberId: number;
    public mainDomain: string;
    public rootpersonFirstName: string;
    public rootpersonLastName: string;
}