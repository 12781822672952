<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_tree_settings" | translate}}</div>
    </div>

    <div class="tab-container app-dark-bg">
        <div class="tabs">
            <a *ngIf="isShowMode(1)" [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{"lbl_add_family_tree"|translate}}</a>
            <a *ngIf="isShowMode(2)" [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">{{"lbl_import"|translate}}</a>
        </div>
        <div class="tab-forms app-light-bg">                
            <app-add-new-family-project *ngIf="getViewMode() === 1" [data]="data"></app-add-new-family-project>
            <app-family-project-import-option *ngIf="getViewMode() === 2" [data]="data" (onImportStarted)="setLastResponse($event)"></app-family-project-import-option>
        </div>
    </div>
</div>
