import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourceMainComponent } from './source-main/source-main.component';
import { SourceEditorComponent } from './source-editor/source-editor.component';
import { SourcesComponent } from './sources/sources.component';
import { FormsModule } from '@angular/forms';
import { CommonComponentModule } from '../common.module';
import { MediaGalleryModule } from '../media-gallery/media-gallery.module';
import { ImageCarouselTempModule } from '../image-carousel-temp/image-carousel-temp.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EventsModule } from '../events/events.module';

@NgModule({
  declarations: [SourceMainComponent, SourceEditorComponent, SourcesComponent],
  imports: [
    CommonModule,
    FormsModule,             
    CommonComponentModule, 
    EventsModule,
    ImageCarouselTempModule,
    MediaGalleryModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ]
})
export class SourceModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}