import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropMenuAction } from 'src/app/common/enums/enums';
import { MediaGalleryItem } from '../models/media-gallery-item';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';

@Component({
  selector: 'app-family-project-list',
  templateUrl: './family-project-list.component.html',
  styleUrls: ['./family-project-list.component.scss']
})
export class FamilyProjectListComponent implements OnInit {
  @Input() items: MediaGalleryItem[] = [];
  @Output() action = new EventEmitter<{action: DropMenuAction, reference: any}>();

  selectedItem : MediaGalleryItem = new MediaGalleryItem();
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";
  loadingImg: string = "assets/family-project/loading.gif";


  constructor() { }

  ngOnInit(){
  }

  selectItem(item:MediaGalleryItem){
    if (this.selectedItem.reference != item.reference){
      //clear selection
      this.items.map( i => i.isSelected = false);
    }
    this.selectedItem = item;
    item.isSelected = !item.isSelected;    
  }

  getSortedMediaItems(){ 
    return this.items.filter( i => i.caption !== undefined && (i.caption.toLowerCase()|| (i?.searchTag?.toLowerCase() ?? "")));
  }

  //use when item loading error
  onItemError(event: any) {
    event.target.src = this.errorImg;
  }

  clickedAction($event) {
    if ($event.action === DropMenuAction.Download) {
      window.open($event.reference.reportUrl, "_blank");
    }
    this.action.emit($event);
  } 
  
  trackMedia(index,item){
    return item ? item.isDisabled : undefined;
  }

}
