import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCarouselTempComponent } from './image-carousel-temp.component';
import { DropMenuModule } from '../drop-menu/drop-menu.module';



@NgModule({
  declarations: [ImageCarouselTempComponent],
  imports: [
    CommonModule,
    DropMenuModule
  ],
  exports:[ImageCarouselTempComponent]
})
export class ImageCarouselTempModule { }
