export class MapReportConfigModel {
  accessToken: string;
  container: string;
  projection: string;
  zoomLevel:{
    default:number,
    stepSize:number
  };
  mapBoundary:MapBoundary;
  markerScale: number;
  dragRotate: boolean;
  startYear: number;
  defaultMapIndividualLifeTime :number;
  attributionControl: boolean;
  mapThemeConfig: MapThemeConfig;
  svgConfig: SvgConfig;
}
export class MapThemeConfig {
  defaultId?: number;
  mapThemeList: MapThemeList[];
}
export class MapThemeList {
  id: number;
  name: string;
  config: string;
}

export class SvgConfig {
  svgExtraHeight: number;
  containerClientHeight: number;
  containerHeight: number;
  mapContainerClientWidth: number;
  svgBodyMargin: string;
  customHtmlElementsPosition: string;
  canvasContainer: {
    canvasContainerPosition: string;
    top: string;
    left: string;
    overflow: string;
  }
  textElement: {
    y: string;
    textAnchor: string;
    fill: string;
    fontSize: string;
    fontWeight: string;
    visibilityHidden: string;
    visibilityVisible: string;
  }
}
export class MapBoundary{
  swLat:number;
  swLng:number;
  neLat:number;
  neLng:number;
  centerLat:number; 
  centerLng:number;

}
