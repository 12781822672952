import { BasicNode } from "../../common/reportAlgorithm/basic-node";

export class ArtisticTreeNode extends BasicNode{

    displayName: String;
    profileImageUrl: String;
    gender: String

    constructor(id,parentId,displayName, profileImageUrl, gender){
        super(id,parentId);
        this.displayName = displayName;
        this.profileImageUrl = profileImageUrl;
        this.gender = gender;
    }

    // return ArtisticTreeNode list from given data list
    static getNodes(list){
        let nodeList = []
        for(let i=0 ; i<list.length; i++){
            nodeList.push(new ArtisticTreeNode(list[i].id, list[i].parentId, list[i].displayName, list[i].profileImageUrl, list[i].gender)) 
        }
        return nodeList
    }

}

