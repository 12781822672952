import { Injectable } from '@angular/core';
import { ParentCommunicatorService } from './parent-communicator.service';
import { ParentMessageType } from '../common/models/ParentMessage';

@Injectable()
export class GlobalerrorService {

  constructor(private parentCommunicator: ParentCommunicatorService) { }

  // Open this popup any error with error code
  showErrorPopup(errorCode: string) {
    if (errorCode)
      this.parentCommunicator.send(ParentMessageType.ErrorOccured, errorCode);
  }

}
