<link rel="stylesheet" [href]="commonUrl + 'themes/common.css' | safe" />

<button *ngIf="isTest" (click)="generateTestData()">Test</button>
<button *ngIf="isTest" (click)="showCircles()">Show Circles</button>

<svg id="svgViewableArea" preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="reportTranslationObj.viewBoxReportAttribute" [attr.height]="config.reportArea.h" [attr.width]="config.reportArea.w" class="svgfull reportCommon"  *ngIf="showReport">

  <g [style.visibility]="reportTranslationObj.hide ? 'hidden' : 'visible'" id="reportArea"  [attr.transform]="reportTranslationObj.translateReportAttribute" > 

    <!-- Background Image -->
    <image  [attr.href]="commonUrl + 'images/Backgroundimage.svg'"  [attr.x]="0" [attr.y]="0" [attr.height] = "config.base.svg.h" />
    
    <!-- Grid -->
    <g *ngIf="gridShow">
        <line *ngFor="let griditem of gridLines" [attr.x1]="griditem.x1" [attr.y1]="griditem.y1"
          [attr.x2]="griditem.x2" [attr.y2]="griditem.y2" style="stroke:#868686" />     
    </g> 

    <!-- Show circular levels for circular algorithm -->
    <g *ngIf="config.options.selectedAlgo == 'circular' && circlesShow" >
      <circle *ngFor = "let level of config.circular.base.levels" cx="1250" cy="0" [attr.r]="level.levelRadius" stroke="green" stroke-width="2" fill="transparent" [attr.transform]="'translate(0 '+treeBaseConfig.rootY+')'" />
    </g>

    <!-- Report Title -->
    <text [attr.x]="config.base.svg.w/2" [attr.y]="this.config.base.svg.h - this.config.base.margin"  dominant-baseline="auto" text-anchor="middle" class="reportTitle" >
      {{ config.options.title }}
    </text>

     <!-- Tree trunk -->
    <image  [attr.href]="commonUrl + 'images/' + treeBaseConfig.assetUrl + treeBaseConfig.treeTrunk.image"  [attr.x]="treeBaseConfig.rootX" [attr.height] = "treeBaseConfig.treeTrunk.h"    [attr.y]="treeBaseConfig.rootY"   [attr.transform] =  "'translate(-'+treeBaseConfig.treeTrunk.w+' 0)'"  />

     <!-- Branches of the tree -->
     <g *ngFor="let branch of artisticBranchData" [attr.transform] =  "'translate(0 '+treeBaseConfig.rootY+')'" [attr.transform-origin]="''+branch.angle.x+' '+branch.angle.y+''">  
      <image  
        style="cursor: pointer;" 
        [attr.x]="branch.x" 
        [attr.y]="branch.y"  
        [attr.height]="branch.height"
        [attr.width]="branch.width"
        [attr.href]="branch.image" 
        preserveAspectRatio="none"
        [attr.transform] =  "'rotate('+branch.angle.angle+' '+branch.angle.x+' '+branch.angle.y+')'"
       />    
    </g>

    <!-- config.circular.branches.defaultBranchWidth -->

    <!-- Tree nodes with node graphics -->
    <g  *ngFor="let node of artisticNodeData; let i=index" [attr.transform] = "'translate(0 '+treeBaseConfig.rootY+') scale('+node.scalingFactor+' '+node.scalingFactor+')'"  [attr.transform-origin]="''+node.x+' '+node.y+''" >

      <!-- Leaves of Node -->
      <image style="cursor: pointer;" [attr.x]="node.x" [attr.y]="node.y" [attr.width]="treeBaseConfig.rootRadius *4" [attr.height]="treeBaseConfig.rootRadius *4" [attr.href]="node.nodeLeavesImageUrl" 
      [attr.transform] = "'translate(-'+treeBaseConfig.rootRadius*2+' -'+treeBaseConfig.rootRadius *2+')'" />
   
      <!-- Image of node -->
 
      <clipPath [attr.id]="'imageclip_' + i">
        <circle  shape-rendering="optimizeSpeed" [attr.r]="treeBaseConfig.rootRadius"  [attr.cx]="node.x" [attr.cy]="node.y" stroke="black"  stroke-width="0" fill="none"  />
      </clipPath>
      
      <image  class="profilePicture" style="cursor: pointer;"  
          [attr.clip-path]="'url(#imageclip_' + i  + ')'" 
          [attr.height] = "treeBaseConfig.rootRadius *2"  
          [attr.x] = "node.x - treeBaseConfig.rootRadius" 
          [attr.y] = "node.y - treeBaseConfig.rootRadius"  
          [attr.href]="node.profileImageUrl" />
      
      <!-- Image frame    -->
      <image  style="cursor: pointer;" 
          [attr.x] = "node.x - treeBaseConfig.rootRadius - 4" 
          [attr.y] = "node.y - treeBaseConfig.rootRadius -4" 
          [attr.height]="treeBaseConfig.rootRadius *2 + 8"  
          [attr.href]="commonUrl + 'images/Frame.svg'" 
         />      
    </g>

  </g>

</svg>
