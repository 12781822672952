import { Component, OnInit, SimpleChanges } from '@angular/core';
import { TooltipContentBaseComponent } from '../../../tooltip/tooltip-content-base/tooltip-content-base.component';
import { MultiMediaBasicInfo } from 'src/app/common/models/MultiMediaBasicInfo';
import { MultimediaTypes } from 'src/app/common/enums/enums';


@Component({
  selector: 'app-event-detail-media',
  templateUrl: './event-detail-media.component.html',
  styleUrls: ['./event-detail-media.component.scss', '../../events-main/events-main.component.scss']
})
export class EventDetailMediaComponent extends TooltipContentBaseComponent implements OnInit {

  public data: any;
  eventMedia: MultiMediaBasicInfo[];
  currentIndex  : number = 0;
  currentMediaRecord: MultiMediaBasicInfo;


  ngOnInit(): void {
    this.eventMedia = new Array<MultiMediaBasicInfo>();
    this.eventMedia = this.data.value;
    this.currentMediaRecord = new MultiMediaBasicInfo();   
    this.currentMediaRecord = this.eventMedia[this.currentIndex];
  }

  //Get event primary media url and set default image
  getMediaUrl(){
    let url = (this.currentMediaRecord && this.currentMediaRecord?.mediaUrl != null && this.currentMediaRecord?.mediaType == MultimediaTypes.PHOTO) ? this.currentMediaRecord?.mediaUrl : "assets/nonimage-preview/Small-Icons/small_media_type_"+ this.currentMediaRecord?.mediaType+".svg";
    return url;
  }

  slideLeft(){
    this.currentIndex--;
    if ( this.currentIndex < 0 ){
      this.currentIndex = this.eventMedia.length -1;
    }  
    this.currentMediaRecord = this.eventMedia[this.currentIndex];  
  }

  slideRight(){
    this.currentIndex++;
    if ( this.currentIndex >= this.eventMedia.length ){
      this.currentIndex = 0;
    }    
    this.currentMediaRecord = this.eventMedia[this.currentIndex];
  }

}
