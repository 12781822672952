import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { DialogBaseComponent } from './dialog-base/dialog-base.component';
import { DropMenuModule } from '../drop-menu/drop-menu.module';


@NgModule({
  declarations: [DialogComponent, DialogBaseComponent],
  imports: [
    CommonModule, DropMenuModule    
  ],
  exports : [DialogComponent]
})
export class DialogModule { }
