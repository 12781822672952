import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfirmationInputInfo } from '../../common/models/ConfirmationInputInfo';
import { ConfirmationContent } from '../../common/models/ConfirmationContent';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  showConfirmation: Subject<ConfirmationInputInfo> = new Subject();
  confirmationStatus: Subject<boolean> = new Subject();

  constructor() { }

  // Open the Dialogbox
  public OpenPopup(data: ConfirmationInputInfo): Observable<any> {
    this.showConfirmation.next(data);
    return this.confirmationStatus;
  }

  // Set given value on cancel and set visible false
  onResponse(value: any) {
    let popupInputData = new ConfirmationInputInfo();
    popupInputData.Visibility = false;
    let popupcontent = new ConfirmationContent();
    popupInputData.ConfirmationContent = popupcontent;

    this.setSelection(value);
    this.showConfirmation.next(popupInputData);
  }

  onVisibilityChanged(): Observable<any> {
    console.log("OnVisibilityChanged" + this.showConfirmation);
    return this.showConfirmation;
  }

  // Selected value update
  setSelection(value) {
    return this.confirmationStatus.next(value);
  }

}
