<div class="list-content">

  <div class="ft-form" *ngIf="getSortedMediaItems()?.length == 0 || getSortedMediaItems() == null ">    
    <div class="no-record" style="height: 100px;">
        <span>{{'err_no_reports' | translate}}</span>
    </div>
  </div>

  <div class = "list-container" *ngIf="getSortedMediaItems()?.length > 0 " >
    <div *ngFor="let item of getSortedMediaItems(); trackBy: trackMedia" class="item" [ngClass]="{'disabled' : item.isDisabled}" [ngStyle] = "{'pointer-events': (item.isDisabled)? 'none' : 'pointer'}">
        <div style="background-color: #A3CC88;" [ngStyle]="{'opacity' : (item.isDisabled )? '0.5' : '1'}">  
            <img class="item-image" [src]="item.url" loading="lazy" (error)='onItemError($event)'>
        </div>
        <div class="item-content">
            <span *ngIf="item.caption" class="label-m-grey" [ngClass]="{'text-disabled' : item.isDisabled}">{{item.caption}}</span>
            <span *ngIf="item.type" class="label-xs-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}">{{item.type}}</span>
            <span *ngIf="item.size" class="label-xs-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}">{{item.size}}</span>
        </div>
        <div class="item-options">
            <app-drop-menu *ngIf="item.dropMenu != null && (item.isEditEnable)" [menu]="item.dropMenu" (action)="clickedAction($event)"></app-drop-menu>            
        </div>
    </div>
  </div>

</div>