<div class="item no-record" *ngIf="getItemLength()==0">
    <div>
        <span>{{"lbl_no_files_exported"| translate}}</span>
    </div>
</div>
<div *ngIf="getItemLength()!=0">
        <div class="item" [ngClass]="{'item-opened' : isLogOpened}">
            <div class="item-content">
                <span class="label-m-grey text-ellipsis-1">{{item.fileName}}</span>
                <span class="label-xs-grey text-ellipsis-1">{{logUpdatedDate}}</span>
            </div>
            <div class="item-options" (click)="toggleItemLog()">
                <img class="menu-edit-icon-1" src="assets/images/icons/arrow-down-grey.svg">
            </div>
        </div>
        <div [hidden]="!isLogOpened" class="item-log">
            <span class="log-description">
                <p *ngFor="let logItem of projectLogArray">{{logItem}}</p>
            </span>
        </div>
</div>