import { Component, OnInit, ElementRef  } from '@angular/core';
import { BaseComponent } from '../../BaseReport/base/base.component';
import { LoadingService } from "src/app/services/UI/loading.service";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { MessageDialogService } from 'src/app/components/common/message-dialog/services/message-dialog.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { ReportOptions } from '../../common/reportOptions/reportOptions';
import { ReportFieldOption } from '../../common/reportOptions/reportFieldOption';

@Component({
  selector: 'app-factsheet',
  templateUrl: './factsheet.component.html',
  styleUrls: ['./factsheet.component.scss']
})
export class FactsheetComponent extends BaseComponent implements OnInit {

  reportOptions;

  constructor(
    private loadingService            : LoadingService,
    protected factSheettHostElement : ElementRef,
    protected translateHandler        : TranslateHandler,
    protected individualApiService    : IndividualApiService,
    protected messageDialogService    : MessageDialogService,
    protected notifierService         : NotifierV2Service,
    protected dialogService           : DialogService,
    ) {
    super(factSheettHostElement, dialogService, notifierService,translateHandler, messageDialogService,individualApiService); 
    }

    ngOnInit(): void { 
      this.initReport();
   
    }
    initReport(){
      this.rootMember = this.notifierService.getCurrentRootMember();
      this.reportTitle = this.config.options.title = this.translateHandler.translate("fact_sheet.lbl_title", [this.rootMember.DisplayName]);
      this.initReportOptions(); 
    }
    initReportOptions(){
      this.reportOptions            = new ReportOptions();
      this.reportOptions.reportName = "fact_sheet.lbl_name"
      this.reportOptions.fieldOptions.push(new ReportFieldOption("title" ,"title", "input", [], this.config.options.title))
    }

}
