import { Component, ElementRef, OnInit } from '@angular/core';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { BaseComponent } from '../../BaseReport/base/base.component';
import { LoadingService } from "src/app/services/UI/loading.service";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { NotifierEvents } from 'src/app/common/enums/enums';
import { TreeHelper } from '../../common/reportAlgorithm/tree-helper';
import { ArtisticTreeBase } from './artistic-tree-base';
import { ArtisticCircular } from './artistic-circular';
import { ArtisticTreeNode } from './artistic-tree-node';
import { TreeNode } from '../../common/reportAlgorithm/tree-node';
import { ArtisticSampleData } from './artistic-sample-data';
import { ArtisticFractal } from './artistic-fractal';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { ReportOptions } from '../../common/reportOptions/reportOptions';
import { ReportFieldOption } from '../../common/reportOptions/reportFieldOption';
import { MessageDialogService } from 'src/app/components/common/message-dialog/services/message-dialog.service';


@Component({
  selector: 'app-artistictree',
  templateUrl: './artistictree.component.html',
  styleUrls: ['./artistictree.component.scss']
})
export class ArtistictreeComponent extends BaseComponent implements OnInit {

  expectedUpdates = [NotifierEvents.RootMemberChanged];

  gridLines          = [];                       // x,y positions of grid
  gridShow           = false;                    // enable , disable grid
  circlesShow        = false;

  defaultHeight      = 0;                        // compulsory to center the report (bug fix)     
  defaultAncestorLevels  = 6;                        // nb of descendant levels in the tree //2023/03/20 - use this variable to get the data from ReportBase.GetLevel()
  
  artisticNodeData   = [];                       // final node locations
  artisticBranchData = [];                       // final branch locations
  
  isTest             = false;                     // test data
  
  treeBaseConfig;                                // basic tree configuration according to the algorithm
  
  reportOptions;                                 // report options 
  
  constructor(
    private loadingService            : LoadingService,
    protected artisticTreeHostElement : ElementRef,
    protected translateHandler        : TranslateHandler,
    protected individualApiService    : IndividualApiService,
    protected messageDialogService    : MessageDialogService,
    protected notifierService         : NotifierV2Service,
    protected dialogService           : DialogService,
    ) {
    super(artisticTreeHostElement, dialogService, notifierService,translateHandler,messageDialogService,individualApiService); 
  }

  ngOnInit(): void { 
        this.configGrid();
        this.initReport();        
  }

  notify() {
    this.initReport();
  }

  initReport(){
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.reportTitle = this.config.options.title = this.translateHandler.translate("artistic_tree.lbl_title", [this.rootMember.DisplayName]);
    this.initReportOptions();  
    this.initArtiticReportData();
  }

  // intialize report options by creating reportOptions object with relavant ReportFieldOption objects. 
  initReportOptions(){
    this.reportOptions            = new ReportOptions();
    this.reportOptions.reportName = "artistic_tree.lbl_name"
    this.reportOptions.fieldOptions.push(new ReportFieldOption("title" ,"title", "input", [], this.config.options.title))
    this.reportOptions.fieldOptions.push(new ReportFieldOption("selectedAlgo","algorithm", "combobox", ["circular","fractal"], this.config.options.selectedAlgo))
  }

  // Getting backend data
  initArtiticReportData(){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.treeBaseConfig = this.config[this.config.options.selectedAlgo].base;
    this.individualApiService.getDescendantsList(Number(this.rootMember.Id), this.defaultAncestorLevels)
    .subscribe(
      (response: any) => {
        this.drawTree(response.data)
        this.showReport = true  
        this.reportZoomToExtend()    
      }
    ).add(() => {
      this.loadingService.hide(processId);
    })
  }

  // Test button event : Testing purposes only
  counter = 0;
  generateTestData(){
    ArtisticSampleData.init(this.commonUrl)
  
    let testData = [
      ArtisticSampleData.generateSampleData(1,8), 
      ArtisticSampleData.generateSampleData(2,5),
      ArtisticSampleData.generateSampleData(3,4),
      ArtisticSampleData.generateSampleData(4,3),
      ArtisticSampleData.generateSampleData(5,2),
      ArtisticSampleData.generateSampleData(5,3),
      ArtisticSampleData.generateSampleData(3,5)
    ]
      
    let selectedData = testData[this.counter]
    this.drawTree(selectedData)
  
    this.counter++;
    if (this.counter == testData.length){
      this.counter=0
    }  
  }

  // test button event to show and hide the circles
  showCircles(){
    this.circlesShow = ! this.circlesShow
  }

  // configure the grid lines
  configGrid(){
    let gapX = 100;
    let gapY = 100;
    let arr  = [];
    for (var y=0; y<=this.config.base.svg.h ; y+=gapY){
      arr.push({"x1":0, "x2":this.config.base.svg.w, "y1":y, "y2": y})
    }
    for (var x=0; x<=this.config.base.svg.w; x+=gapX){
      arr.push({"x1":x, "x2":x, "y1":0, "y2": this.config.base.svg.h})
    }
    this.gridLines = arr;
  }

  /**
   * Draw tree based on the root member decendants
   * @param data from server - decendent list
   */
  drawTree(data){
    let rootNode:TreeNode<ArtisticTreeNode> = TreeHelper.generateNodesFromList("ArtisticTreeNode",data);
    let artisticImpl:ArtisticTreeBase;
    if (this.config.options.selectedAlgo == "circular"){
      artisticImpl= new ArtisticCircular(rootNode, this.config.circular , this.config.base , this.commonUrl, this.config.options.selectedRandomAlgo);   
    }
    else if (this.config.options.selectedAlgo == "fractal"){
      artisticImpl = new ArtisticFractal(rootNode, this.config.fractal , this.config.base , this.commonUrl)
    }
    this.artisticNodeData = artisticImpl.getNodes();
    this.artisticBranchData = artisticImpl.getBranches();
    console.log(this.artisticBranchData)
  }

  onOptionsValueChanged(changedData:ReportOptions){
    this.mapConfig(changedData);
    this.initArtiticReportData();
  }

}
