import { Injectable } from '@angular/core';
import { PopupInputInfo } from '../../common/models/PopupInputInfo';
import { Subject, Observable } from 'rxjs';
import { PopupResponse } from '../../common/models/PopupResponse';


export class NestedPopupService {
  showPopup: Subject<PopupInputInfo> = new Subject();
  selectItems: Subject<PopupResponse[]> = new Subject();
  popupData: PopupInputInfo;

  changeVisibility(visibility: boolean, data?: PopupInputInfo) {
    if (data) {
      this.popupData = data;
      data.visibility = visibility;
    }
    else {
      data = new PopupInputInfo();
      data.visibility = visibility;
    }

    this.showPopup.next(data);
  }

  // Set given value on cancel and set visible false
  onCancelled(value: PopupResponse) {
    this.setSelection(value);
    this.changeVisibility(false, this.popupData);
  }


  onVisibilityChanged(): Observable<PopupInputInfo> {
    return this.showPopup;
  }

  // Selected value update
  setSelection(value) {
    return this.selectItems.next(value);
  }

  // getPopupSelection() {
  //   return this.popupSelectionItemsSubject.next();
  // }

  // Open the Dialogbox
  public openPopup(data: PopupInputInfo): Observable<any> {
    console.log('NestedPopupService Open():' + data.toString());
    this.changeVisibility(true, data);
    return this.selectItems;
  }

}
