import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FTDate } from '../../../../common/models/FTDate';
import { DateHelper } from '../../../../common/helpers/date-helper';
import { PreferencesService } from '../../../../services/preferences.service';
import { TooltipService } from 'src/app/services/UI/tooltip.service';
import { TooltipHeadPosition } from '../../tooltip/models/tooltip-head-position';
import { DateTooltipComponent } from './date-tooltip/date-tooltip.component';
import { GenealogyDate } from 'src/app/common/helpers/date/genealogy-date';
import { GenealogyDateRange } from 'src/app/common/helpers/date/genealogy-date-range';

@Component({
  selector: 'app-date-input-temp',
  templateUrl: './date-input-temp.component.html',
  styleUrls: ['./date-input-temp.component.scss']
})
export class DateInputTempComponent implements OnInit {

  @Input() inDate: FTDate;
  @Output() outDate = new EventEmitter<FTDate>();
  @Input() isFieldEnabled: boolean = true;
  @Input() scrollUpdate;
  
  dateHelper: DateHelper;
  isWarning: boolean = false;
  dateTooltipIcon: string;
  dateString: string;
  //Todo: check fieldEnabled used in any other place.
  
  // Note: labels / aren't shown in this component.

  constructor(
    private preferenceService: PreferencesService, 
    private tooltipService: TooltipService,
    ) {
    this.dateHelper =  new DateHelper(preferenceService);
  }

  
  ngOnInit(): void {
    // Todo: check where to call (constructor or ngOnInit()) ask chinthaka
    this.SetTooltipIcon();
    this.scrollUpdate.subscribe(val => {
      if(val){
        this.hideToolTip()
      }       
    });
  }
  
  ngOnChanges(changes: SimpleChanges) {
    let change = changes['inDate'];
    if(change){
      if (this.inDate)
      {
        this.setDate(this.inDate.genealogyDate ? this.inDate.genealogyDate.originalStr : this.inDate.genealogyDateRange? this.inDate.genealogyDateRange.originalStr:null);
      }
      else {
        this.isWarning = false;    
        this.dateString = "";
        this.SetTooltipIcon();
      }
    }
  }
  
  setDate(date: string) {
    this.isWarning = false;
    if(date){
      let formattedDate = this.dateHelper.validateAndFormat(date);
  
      if(formattedDate){
        this.dateString = formattedDate.formattedText;
        this.isWarning = !formattedDate.isValidDate;
        this.outDate.emit(formattedDate);
      }
    }
    else
    {
      // Emit empty string if input is empty
      // Todo: clarify, send undefined or manually set "" string as follows:,
      // let formattedDate = this.dateHelper.validateAndFormat(date);
      let formattedDate = this.dateHelper.validateAndFormat("");
      this.outDate.emit(formattedDate);
      this.isWarning = false;
      this.dateString = "";
    }
    this.SetTooltipIcon();
  }

  SetTooltipIcon(){
    this.isWarning ? this.dateTooltipIcon = "/assets/images/icons/warning.svg": this.dateTooltipIcon = "/assets/images/icons/info-grey.svg";
  }

  showDateInfo($event){
    let data = { title:"lbl_notice", invalidMessage: null, info:"val_date_tooltip_content"};
    this.isWarning ? data.invalidMessage = "val_date_tooltip_validation_upper": null;
    this.tooltipService.show($event, TooltipHeadPosition.Left, "black", DateTooltipComponent, data);
  }

  onBlur() {
    // set user input date
    this.setDate(this.dateString);
    return;
  }

  hideToolTip() {
    this.tooltipService.hide();
  }
}
