export class ErrorData {
    IsError: boolean;
    StatusCode: string;
    CustomErrorMessage: string;
    ErrorResponse: HttpErrorResponseData = new HttpErrorResponseData();
}

export class HttpErrorResponseData {
    ResponseMessage: string;
    ResponseName: string;
    ResponseStatus: string;
    ResponseStatusText: string;
    ResponseUrl: string;
}